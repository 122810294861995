import React from 'react'
import { DataGrid,
 } from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
// import { withStyles } from "@mui/styles";
import {useSelector,useDispatch} from 'react-redux';
import { alpha, styled } from '@mui/material/styles';

const StyledDataGrid = styled(DataGrid)(({ theme,GridTheme,directions,hasCreate ,hasSeperatedHeader}) =>({

      width: "100% !important",
      boxShadow: "2px 5px 12px -5px rgba(0,0,0,0.75)",
      backgroundColor:GridTheme?.paperColor,
      overflow: "hidden",
      borderRadius: "5px !important",
      position:'relative',
      // top: !hasSeperatedHeader?hasCreate?'10px !important':'-50px !important':'0px !important',
      color:`${GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor} !important`,

      "& .MuiDataGrid-renderingZone": {
        maxHeight: "none !important",
      },
      "& .MuiDataGrid-cell": {
        lineHeight: "unset !important",
        maxHeight: "none !important",
        whiteSpace: "normal",
        // display:'flex !important',
        // justifyContent: 'start !important',
        color:`${GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor} !important`,
        
      },
      '& .MuiTablePagination-selectLabel':{
        color:`${GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor} !important`,
      },
      '& .MuiTablePagination-select':{
        backgroundColor:`${GridTheme?.paperColor} !important`,
        color:`${GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor} !important`,

      },
      '& .muiltr-levciy-MuiTablePagination-displayedRows':{
        color:`${GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor} !important`,
      },
      "& .MuiDataGrid-row": {
        maxHeight: "none !important"
      },
      '& .customeFooter':{
        display:'flex',
        justifyContent:'end',
        padding:'5px 10px',
        alignItems:'center',
        backgroundColor:GridTheme?.primaryColor
      },
      '& .customeFooter .CustomButtonFooter':{
        backgroundColor:GridTheme?.secondaryColor,
        borderRadius:'5px',
        padding:'10px 15px',
        color:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.oppositeSecondaryColor,
        border:'none',
        outline:'none',
      },

    ".MuiDataGrid-virtualScroller":{


    },
    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar":{
    },
    "& .MuiDataGrid-virtualScroller":{
    width: "10px"
    },

    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
    width: "10px"
    },

    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
    boxShadow: `inset 0 0 5px ${GridTheme?.primaryColor}55`,
    borderRadius: "10px",
    },


    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
    background: GridTheme?.primaryColor,
    borderRadius: "5px",
    },


    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
    background: GridTheme?.primaryColor
    },


    "& .MuiDataGrid-columnHeaderTitle ":
    { 
      // display:'flex !important',
      // justifyContent: 'end !important',
      // textAlign: 'right',
      fontWeight:" bold !important",
      overflow: "visible !important",
      color: `${GridTheme?.oppositePrimaryColor} !important`, 
      fontSize: "1rem",
    },

    "& .MuiDataGrid-columnHeaders":
    {
      // textAlign: 'right',
      backgroundColor:`${GridTheme?.primaryColor} !important`,
      '& .muirtl-1j7qk7u,& .muiltr-1j7qk7u,&.muirtl-9vkkd8 .MuiDataGrid-menuIcon,& .muiltr-9vkkd8 .MuiDataGrid-menuIcon,& .muiltr-1pe4mpk-MuiButtonBase-root-MuiIconButton-root,& .muirtl-1893gh3-MuiDataGrid-root .MuiDataGrid-menuIcon,& .muirtl-1pe4mpk-MuiButtonBase-root-MuiIconButton-root':{
        display:'none !important',
      }
    },
    "& .MuiTablePagination-displayedRows":
    {
    marginTop: "auto",
    },
    "& .MuiTablePagination-root .MuiTablePagination-selectLabel":
    {
    marginTop: "auto",
    },
    "& svg,& svg path":{
      fill:`${GridTheme?.primaryColor} `,
      color:`${GridTheme?.primaryColor} `,
      stroke:`${GridTheme?.primaryColor} `,

    },
    '& .MuiTablePagination-displayedRows muirtl-levciy-MuiTablePagination-displayedRows':{
      color:`${GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.paperTextColor}   !important`,
    },
    border:'none !important',

    '& .muirtl-uw2ren-MuiDataGrid-virtualScrollerRenderZone':{
      // width:'100% !important',
      // transform:'translate(-50%,0) !important'
    },
    

    
  })
)

  

export default StyledDataGrid