import { createSlice } from '@reduxjs/toolkit';
const directions__= localStorage.getItem('direction')? (localStorage.getItem('direction')==1?'rtl':'ltr'):'rtl'
var screenWidth= window.innerWidth
window.addEventListener('resize', ()=>{
   screenWidth=window.innerWidth
})
const GridMode=localStorage.getItem('gridMode')?localStorage.getItem('gridMode')=='true'?true:false:false;

const initialState={
    offLine:false,
    sideMenuMode:false,
    directions:directions__,
    notificationBox:false,
    SideMenuOpen:false,
    MainPaletteShow:false,
    SideMenuPaletteShow:false,
    ToolBarPaletteShow:false,
    GridPaletteShow:false,
    filterSearchBoxMode:GridMode,
    newCompanyAdded:false,
    newLanguageAdded:false,
    drawerWidth:315,
    FilterButtonHeight:55,
    currentSelectedSettingPages:1,
    attendanceCardView:{},
    forceReload:false,
    forceReloadTheme:false,
    ListOfTranslateData:{},
    ListOfTranslateDataCode:'ar',
    CopedJourneys:[],
    GlobalVariableForJourneys:[],
    listOfJourneysPlan:[],
    selectedCell:'',
    companyImage:'',
    DistrictsList:[],
    regionsList:[],
    DistrictsList_temp:[],
    RegionsList_temp:[],
    ReduxLoading:false,
    screenWidth:screenWidth,
    SearchButtonTheme:{
      search_button_color:'rgb(69 143 216)',
      clear_button_color:'rgb(234 94 94)',
      search_button_text_color:'#ffffff',
      clear_button_text_color:'#ffffff',
      save_button_color:'rgb(234 94 94)',
      save_button_text_color:'#ffffff',
      export_button_color:'rgb(234 94 94)',
      export_button_text_color:'#ffffff',
      import_button_color:'rgb(234 94 94)',
      import_button_text_color:'#ffffff',
      create_button_color:'rgb(234 94 94)',
      create_button_text_color:'#ffffff',
      map_button_color:'rgb(234 94 94)',
      map_button_text_color:'#ffffff',
      telegram_button_color:'rgb(234 94 94)',
      telegram_button_text_color:'#ffffff',
      go_back_button_color:'rgb(234 94 94)',
      go_back_button_text_color:'#ffffff',
      road_time_color:'#568bdb',
      road_time_text_color:'#ffffff',
      visit_time_color:'#ed0c2e',
      visit_time_text_color:'#ffffff',
    },
    MainTheme:{
      primaryColor:'#111827',
      oppositePrimaryColor:'#ffffff',
      secondaryColor:'#0000ff',
      oppositeSecondaryColor:'#ffffff',
      gloablTextColor:'#ffffff',
      paperColor:'#ffffff',
      paperTextColor:'#000000',
      bgColor:'#F1F5F9',
      bgTextColor:'#000000',
      light:true,
      dark:false,
      disabledGlobalText:false,
      isLinearGradient:false,
      linearGradientBgColor:'linear-gradient(46deg,rgba(250, 169, 25, 0.56) 0.42735042735042733%,rgba(250, 169, 25, 0.49) 39.31623931623932%,rgba(250, 169, 25, 0.27) 64.52991452991454%,rgba(250, 169, 25, 0.18) 97.86324786324786%)',
    },
    ToolbarTheme:{
      primaryColor:'#111827',
      oppositePrimaryColor:'#ffffff',
      secondaryColor:'#0000ff',
      oppositeSecondaryColor:'#ffffff',
      gloablTextColor:'#ffffff',
      paperColor:'#ffffff',
      paperTextColor:'#000000',
      bgColor:'#F1F5F9',
      bgTextColor:'#000000',
      light:true,
      dark:false,
      disabledGlobalText:false,
      isLinearGradient:false,
      linearGradientBgColor:'linear-gradient(46deg,rgba(250, 169, 25, 0.56) 0.42735042735042733%,rgba(250, 169, 25, 0.49) 39.31623931623932%,rgba(250, 169, 25, 0.27) 64.52991452991454%,rgba(250, 169, 25, 0.18) 97.86324786324786%)',
    },
    SideMenuTheme:{
      primaryColor:'#ffffff',
      oppositePrimaryColor:'#ffffff',
      secondaryColor:'#5CF0AB',
      oppositeSecondaryColor:'#ffffff',
      paperColor:'#ffffff',
      gloablTextColor:'#ffffff',
      paperTextColor:'#000000',
      bgColor:'#111827',
      bgTextColor:'#ffffff',
      light:false,
      dark:true,
      disabledGlobalText:false,
      isLinearGradient:false,
      linearGradientBgColor:'linear-gradient(46deg,rgba(250, 169, 25, 0.56) 0.42735042735042733%,rgba(250, 169, 25, 0.49) 39.31623931623932%,rgba(250, 169, 25, 0.27) 64.52991452991454%,rgba(250, 169, 25, 0.18) 97.86324786324786%)',
    },
    GridTheme:{
      primaryColor:'#111827',
      oppositePrimaryColor:'#ffffff',
      secondaryColor:'#0000ff',
      oppositeSecondaryColor:'#ffffff',
      gloablTextColor:'#000000',
      paperColor:'#ffffff',
      paperTextColor:'#000000',
      bgColor:'#F1F5F9',
      bgTextColor:'#000000',
      light:true,
      dark:false,
      disabledGlobalText:false,
      isLinearGradient:false,
      linearGradientBgColor:'linear-gradient(46deg,rgba(250, 169, 25, 0.56) 0.42735042735042733%,rgba(250, 169, 25, 0.49) 39.31623931623932%,rgba(250, 169, 25, 0.27) 64.52991452991454%,rgba(250, 169, 25, 0.18) 97.86324786324786%)',
    }
}
export const settingsSlice = createSlice({
  name: 'settingsData',
  initialState:initialState,
  reducers: {
    setSideMenuMode:(state,action)=>{
        state.sideMenuMode=action.payload
    },
    setDirections:(state,action)=>{
       state.directions=action.payload
    },
    setNotificationBox:(state,action)=>{
       state.notificationBox=action.payload
    },
    setSideMenuOpen:(state,action)=>{
      state.SideMenuOpen=action.payload
    },
    setMainPaletteShow:(state,action)=>{
      state.MainPaletteShow=action.payload
    },
    setSideMenuPaletteShow:(state,action)=>{
      state.SideMenuPaletteShow=action.payload
    },
    setToolBarPaletteShow:(state,action)=>{
      state.ToolBarPaletteShow=action.payload
    },
    setGridPaletteShow:(state,action)=>{
      state.GridPaletteShow=action.payload
    },
    setMainTheme:(state,action)=>{
         state.MainTheme=action.payload
    },
    setSideMenuTheme:(state,action)=>{
       state.SideMenuTheme=action.payload
    },
    setToolbarTheme:(state,action)=>{
      state.ToolbarTheme=action.payload
    },
    setGridTheme:(state,action)=>{
      state.GridTheme=action.payload
    },
    setDisabledGlobalText:(state,action)=>{
      state.disabledGlobalText=action.payload
    },
    setCurrentSelectedSettingPages:(state,action)=>{
       state.currentSelectedSettingPages=action.payload
    },
    setSearchButtonTheme:(state,action)=>{
      state.SearchButtonTheme=action.payload
    },
    setAllThemeSettings:(state,action)=>{
      state=action.payload
    },
    setForceReload:(state,action)=>{
      state.forceReload=action.payload
    },
    setListOfTranslateData:(state,action) => {
       state.ListOfTranslateData=action.payload
    },
    setListOfTranslateDataCode:(state,action)=>{
      state.ListOfTranslateDataCode=action.payload
    },
    setReduxLoading:(state,action)=>{
      state.ReduxLoading=action.payload
    },
    setForceReloadTheme:(state,action)=>{
      state.forceReloadTheme=action.payload
    },
    setDistrictsList:(state,action)=>{
      state.DistrictsList=action.payload
    },
    setRegionsList:(state,action)=>{
      state.regionsList=action.payload
    },
    setCompanyImage:(state,action)=>{
      state.companyImage=action.payload
    },
    setDistrictsList_temp:(state,action)=>{
      state.DistrictsList_temp=action.payload
    },
    setRegionsList_temp:(state,action)=>{
       state.RegionsList_temp=action.payload
    },
    setscreenWidthDispatch:(state,action)=>{
      state.screenWidth=action.payload
    },
    setGlobalVariableForJourneys:(state,action)=>{
       state.GlobalVariableForJourneys=action.payload
    },
    setSelectedCell:(state,action)=>{
        state.selectedCell=action.payload
    },
    setListOfJourneysPlan:(state,action)=>{
      state.listOfJourneysPlan=action.payload
    },
    setAttendanceCardView:(state,action)=>{
       state.attendanceCardView=action.payload
    },
    setOffLine:(state,action)=>{
     state.offLine=action.payload
    },
    setFilterSearchBoxMode:(state,action)=>{
      state.filterSearchBoxMode=action.payload
    },
    setNewCompanyAdded:(state,action)=>{
      state.newCompanyAdded=action.payload
    },
    setCopedJourneys:(state,action)=>{
      state.CopedJourneys=action.payload
    },
    setNewLanguageAdded:(state,action)=>{
      state.newLanguageAdded=action.payload
    }
  }
});

export const {
  setNewLanguageAdded,
  setCopedJourneys,
  setNewCompanyAdded,
  setFilterSearchBoxMode,
  setOffLine,
  setSideMenuMode,
  setDirections,
  setscreenWidthDispatch,
  setNotificationBox,
  setSideMenuOpen,
  setMainPaletteShow,
  setSideMenuPaletteShow,
  setToolBarPaletteShow,
  setGridPaletteShow,
  setMainTheme,
  setSideMenuTheme,
  setToolbarTheme,
  setGridTheme,
  setDisabledGlobalText,
  setCurrentSelectedSettingPages,
  setSearchButtonTheme,
  setAllThemeSettings,
  setForceReload,
  setListOfTranslateData,
  setListOfTranslateDataCode,
  setDistrictsList,
  setRegionsList,
  setReduxLoading,
  setForceReloadTheme,
  setCompanyImage,
  setDistrictsList_temp,
  setRegionsList_temp,
  setGlobalVariableForJourneys,
  setSelectedCell,
  setListOfJourneysPlan,
  setAttendanceCardView
} = settingsSlice.actions;


export const sideMenuMode = state => state.settingsData.sideMenuMode;
export const directions = state => state.settingsData.directions;
export const notificationBox= state => state.settingsData.notificationBox;
export const MainTheme=state => state.settingsData.MainTheme;
export const SideMenuTheme=state => state.settingsData.SideMenuTheme;


export default settingsSlice.reducer;
