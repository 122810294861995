import styled from 'styled-components';


export const UserSection=styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 15px  0px;
// background-color: ${props=>props.checkCon?'#29303d00':props?.bgColor+'33'};
margin: 10px 15px 0px 15px !important;
border-radius: 10px;

& img{
    width: ${props=>props.checkCon?'55px':'100px'};
    height: ${props=>props.checkCon?'55px':'100px'};
    border-radius: 50%;
    object-fit: cover;
    background-color: ${props=>props?.imgBgColor};
    cursor: pointer;
};
& p{
    color: ${props=>props?.color};
    position: relative;
    font-size: ${props=>props.checkCon?"10px":'15px'}!important;
    cursor: pointer;

};

& .textUserName{
    margin-top: 10px;
};

& .textUserEmail{
    top: -16px;
    color:${props=>props?.color+'77'};
    font-size: 14px;
    display:  ${props=>props.checkCon?"none":'inline-block'} !important;

}

`

export const UserAppheader=styled.div`
height:37px ;
width:110px;
margin-top:6px;
margin-bottom:6px;
padding:10px !important;
display:flex;
justify-content:space-between;
background-color: ${props=>props.lightOrDark=='light'?(props.bgColor+22):props.bgColor+'aa'};
&:hover{
    background-color: ${props=>props.lightOrDark=='light'?(props.bgColor+44):props.bgColor+'88'};;
};
& .imgUserAppheader{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    object-fit: scale-down;
    padding:4px,

};
& .phone{
    font-size:${props=>props.SideMenuOpen?9.5:12}px !important;
};
& p{
    position:relative;
    top:3.2px;
    // right:3px;
}



`

export const ContainerOFDrawerList=styled.div`
    // height:'100%';
    overflow-y: scroll;
    padding-bottom:30px ;
    // padding-top:100px ;
    overflow-x: hidden !important;
    // padding-top:${props=>props?.isIconActive?'180px':'0'};
    display: ${props=>props?.isIconActive?'block':'block'};
    flex-direction: column;
    justify-content: center;
    place-items: center;
    align-items: ${props=>props?.isIconActive?'center':'start'};
    '& .ListIconColorActiveModeMenu':{
        padding:${props=>props?.isIconActive?0:'auto'} !important;
        margin:${props=>props?.isIconActive?0:'auto'} !important;
        left:${props=>props?.isIconActive?0:'auto'} !important;
    };
`