import { configureStore,getDefaultMiddleware  } from '@reduxjs/toolkit';
import dailyPlanReducer from '../component/reduxStore/dailyPlanReducer';
import forceReloadReducer from '../component/reduxStore/forceReloadReducer';
import SettingsReducer from '../component/reduxStore/SettingsReducer';
import UserReducer from '../component/reduxStore/UserReducer';
import TranslateReducer from '../component/reduxStore/TranslateReducer';


export default configureStore({
  reducer: {
    dailyPlanData: dailyPlanReducer,
    forceReload: forceReloadReducer,
    settingsData:SettingsReducer,
    userData:UserReducer,
    translateData:TranslateReducer,
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  },
});
