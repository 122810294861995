import styled from "styled-components";

export const ContainerOfForm=styled.div`
   background-color:${props=>props.bgColor};
   color:${props=>props.color};
`

export const SaveButton=styled.button`
   color:${props=>props.color};
   background-color:${props=>props.bgColor};
   border-radius:10px;
   border:none;
   outline:none;
   padding:10px 25px;
   margin:5px 10px;
`;

export const CancelButton=styled.button`
   color:${props=>props.color};
   background-color:${props=>props.bgColor};
   border-radius:10px;
   border:none;
   outline:none;
   padding:10px;
   margin:5px 10px;
`;

export const Form=styled.form`
  
 & select, & input{
    bacground-color:${props=>props.color}4c;
    color:${props=>props.color};
 }
`