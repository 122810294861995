import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CustomePaper from '../../Share/Components/CustomePaper';
import Typography from '@mui/material/Typography';
import {useTranslation} from "react-i18next";
import {useSelector,useDispatch} from 'react-redux'
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import AddIcon from '@mui/icons-material/Add';
import CustomeInput from './CustomeInput';
import {
    setTranslateEnglish,
    setTranslateArabic,
} from '../../../reduxStore/TranslateReducer';
import {setReduxLoading,setForceReload} from '../../../reduxStore/SettingsReducer'
// import AddIcon from '@mui/icons-material/Add';
import Modal from '@mui/material/Modal';
import swal from 'sweetalert'
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CustomeSelectBox from '../../Share/CustomeSelectBox';
import AppsIcon from '@mui/icons-material/Apps';

import Host from "../../../../assets/js/Host";
import { toast } from "react-toastify";
import axios from "axios";
import Cookies from "universal-cookie";
import LoadingPage from '../../Share/LoadingPage';
const cookies = new Cookies();
const language = cookies.get("language") ? cookies.get("language") : "ar";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const CustomeBox=styled(Box)(({theme,MainTheme})=>({
    display:'flex',
    alignItems: 'center',
    color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
}));
const CustomeBoxModal=styled(Box)(({theme,MainTheme})=>({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    padding:'15px',
    borderRadius:5,
    backgroundColor:MainTheme?.paperColor,
    boxShadow:" 0px 9px 14px -2px rgba(0,0,0,0.75)",
    color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
    '& input':{
        color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
    
        },
        '& .MuiOutlinedInput-root':{
            borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
            color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
        },
        '& .MuiInputLabel-root':{
            // backgroundColor:`${MainTheme?.paperTextColor+'22'} !important`,
            color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
        },
        '& .MuiFormControl-root':{
          // border:`1px solid ${MainTheme?.paperTextColor} !important`,
          color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
          },
          '&:hover fieldset': {
            borderColor: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
          },
          '&.Mui-focused fieldset': {
            borderColor: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.secondaryColor,
          },
        },
        '& .MuiOutlinedInput':{
          // borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            // border: "0 none",
         },
         "& .muiltr-a4ms82-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root , & .muirtl-a4ms82-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root,& svg":{
             color:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,
         }

}));



const SettingsList =({title,sections_id,searchValue,sectionFilterType})=> {
    const {t, i18n} = useTranslation('common');
    const MainTheme=useSelector(state=>state.settingsData.MainTheme);
    const EnglishValues=useSelector(state=>state.translateData.translation_en);
    const ArabicValues=useSelector(state=>state.translateData.translation_ar);
    const [loading,setLoading]=useState(false)
    const token=cookies.get('token')
    const ListOfTranslateData=useSelector(state=> state.settingsData.ListOfTranslateData)
    const ReduxLoading=useSelector(state=> state.settingsData.ReduxLoading)
    // const dispatch=useDispatch()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const directions=useSelector(state=> state.settingsData.directions)
    const [fieldsValue,setFieldsValue]=useState({})
    const [fieldsValue_old,setFieldsValue_old]=useState({})
    const [openModal, setOpenModal] = useState(false);
    const [newKey,setNewKey]=useState('')
    const [newKeyValue_en,setNewKeyValue_en]=useState('')
    const [newKeyValue_ar,setNewKeyValue_ar]=useState('')
    const [newKeyValue,setNewKeyValue]=useState('')
    const [isAddOrEdit,setIsAddOrEdit]=useState(false)
    const [listOfKeys,setListOfKeys]=useState([])

    const [selectedKey,setSelectedKey]=useState('')
    const [selectedKeyValue,setSelectedKeyValue]=useState('')
    const [selectedValue,setSelectedValue]=useState('')
    const [selectedValueID,setSelectedValueID]=useState('')
    const forceReload = useSelector(state=> state.settingsData.ForceReload);


    const [isEditValue,setIsEditValue]=useState(false)
    const [isDeleteValue,setIsDeleteValue]=useState(false)
    const [AllSectionsDataKeys,setAllSectionsDataKeys]=useState([])

    // const [selectedKey,setSelectedKey]=useState('')

    
    const dispatch=useDispatch();
    const handleOpenModal1 = () => {
        setIsAddOrEdit(false) 
        setOpenModal(true);
        setAnchorEl(null);
    }
    const handleOpenModal2 = () => {
        setIsAddOrEdit(true)
        setIsEditValue(false)
        setIsDeleteValue(false)
        
        setOpenModal(true);
        setAnchorEl(null);
    }
    const handleOpenModal3=()=>{
        setIsEditValue(true)
        setIsDeleteValue(false)
        setIsAddOrEdit(true)

        setOpenModal(true);
        setAnchorEl(null);
    }
    const handleOpenModal4=()=>{
        setIsDeleteValue(true)
        setIsEditValue(true)
        setIsAddOrEdit(true)

        setOpenModal(true);
        setAnchorEl(null);
    }

    const handleCloseModal = () => setOpenModal(false);

    useEffect(()=>{
        if(directions=='rtl'){
            setFieldsValue(ArabicValues)
            setFieldsValue_old(ArabicValues)
        }else{
            setFieldsValue(EnglishValues)
            setFieldsValue_old(EnglishValues)
        }
    },[EnglishValues,ArabicValues,directions])

   const updateName=(name,label,has_other)=>{
    //    if(!has_other){
    //         setFieldsValue({
    //             ...fieldsValue,
    //             [sections]:{
    //             ...fieldsValue[sections],
    //             [label]:name,
    //             }
    //        })
    //     }else{
    //         setFieldsValue({
    //             ...fieldsValue,
    //             [label]:name,
    //         })
    //     }
        
   
   }

   const saveChange=()=>{
    if(directions=='rtl'){
        dispatch(setTranslateArabic(fieldsValue))
     }else{
        dispatch(setTranslateEnglish(fieldsValue))
     }
   }



   const saveAddNewKey=()=>{
    if(!newKey||!newKeyValue_ar||!newKeyValue_en||!newKeyValue){
        swal({
            title: `${t('SWEATALERT_MESSAGES.WARNNING_MESSAGE')}`,
            text: `${t('SWEATALERT_MESSAGES.ADD_NEW_KEY_AND_VALUES_message')}`,
            icon: "warning",
            button: `${t('SWEATALERT_MESSAGES.WARRNING_BUTTON')}`,
          });
          return;
    }else{
        const arraKeys = Object.keys(ListOfTranslateData);
        // const arraKeys_ar = Object.values(ArabicValues);
        
        let check=false;
        // let check=check=arraKeys_en.include(newKey);
        // check=arraKeys_ar.include(newKey)

        arraKeys?.length>0&&arraKeys?.map((itm1)=>{
              if(itm1==newKey){
                check=true;
              }
        })

        // arraKeys_ar?.length>0&&arraKeys_ar?.map((itm2)=>{
        //     if(itm2==newKey){
        //         check=true;
        //       }
        // })

        if(check){
            swal({
                title: `${t('SWEATALERT_MESSAGES.WARNNING_MESSAGE')}`,
                text: `${t('SWEATALERT_MESSAGES.ADD_NEW_KEY_AND_VALUES_EXISTS')}`,
                icon: "warning",
                button: `${t('SWEATALERT_MESSAGES.WARRNING_BUTTON')}`,
              });
              return;
        }



    }
    
    dispatch(setTranslateArabic({
        ...ArabicValues,
        [newKey]:newKeyValue_ar
    }))

    dispatch(setTranslateEnglish({
        ...EnglishValues,
        [newKey]:newKeyValue_en
    }))
    setNewKey('')
    setNewKeyValue_ar('')
    setNewKeyValue_en('')
    setOpenModal(false)
   }

   
    const getAllSectionTranslationKeys= async () => {
        setAllSectionsDataKeys([])
        try {
            dispatch(setReduxLoading(true))
            const resourcesResult = await axios({
                url: Host + "translation_section_keys_list?translation_section_id="+sections_id,
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                },
                params:{
                    language_id:localStorage.getItem('language_id'),
                }
            })
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                  if(resourcesResult?.data)
                   {
                    setAllSectionsDataKeys(resourcesResult?.data)
                   }
                } else if (resourcesResult.data.status === false) {
                }
            }
            else {
                toast.error("Error")
            }
            dispatch(setReduxLoading(false))
          
        } catch (error) {
            console.log("error",error);
            dispatch(setReduxLoading(false))
            const resourcesResult = error.response
            if (resourcesResult && resourcesResult.status === 400) {
                if (typeof resourcesResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key) => {
    
                        resourcesResult.data.data[key].map((message) => {
                            toast.error(message)
                        })
                    })
                }
                else {
    
                    toast.error(resourcesResult.data.data)
                }
    
    
            }
            else if (resourcesResult && resourcesResult.status === 401) {
    
                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else {
    
                toast.error("network error")
            }
        }
    
    };

    useEffect(()=>{
        getAllSectionTranslationKeys()
    },[sections_id,ListOfTranslateData])

    const [CustomeKeysList,setCustomeKeysList]=useState([])
    useEffect(()=>{
        let arr=[];
        if(AllSectionsDataKeys&&AllSectionsDataKeys?.length>0){
            AllSectionsDataKeys?.map((itm)=>{
                arr.push({
                   section_id:sections_id,
                   name:itm?.name,
                   id:itm?.id,
                   value_id:itm?.translation_section_values[0]?itm?.translation_section_values[0]?.id:'',
                   value_name:itm?.translation_section_values[0]?itm?.translation_section_values[0]?.value:'',
                   has_value:itm?.translation_section_values[0]?true:false,
                   language_id:localStorage.getItem('language_id')?localStorage.getItem('language_id'):itm?.translation_section_values[0]?.language_id,
                }) 
            })
            setCustomeKeysList(arr)
        }
    },[AllSectionsDataKeys])

    const renderFields=()=>{
        let result=[];
        
        if(AllSectionsDataKeys?.length>0){
            if(CustomeKeysList&&CustomeKeysList?.length>0){
            
        
                CustomeKeysList?.map((itm)=>{
                    if(checkNameExistsInSearch(itm?.name))
                    result.push(<CustomeInput key={itm?.id}  fieldName={itm?.name} value={itm?.value_name} readOnly={true} label={itm?.name} />)
                })
        }
       }
       return result;

    }

    const checkNameExistsInSearch = (key) => { 
        if(sectionFilterType!==1){
        let check=false
          let keyValue=key.toLowerCase()
          let search=searchValue.toLowerCase()
          if(searchValue)
           check=keyValue.includes(search);
          else return true;

          return check;
        }else
        return true;
    }

    const AddNewKey=async()=>{
        if(!newKey){
            swal({
                title: `${t('SWEATALERT_MESSAGES.WARNNING_MESSAGE')}`,
                text: `${t('SWEATALERT_MESSAGES.ADD_NEW_KEY_AND_VALUES_message')}`,
                icon: "warning",
                button: `${t('SWEATALERT_MESSAGES.WARRNING_BUTTON')}`,
              });
              return
        }
        try {
            dispatch(setReduxLoading(true))
            const resourcesResult = await axios({
                url: Host + "translation_section_keys",
                method: "post",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                },
                data:{
                    translation_section_id:sections_id,
                    name:newKey
                }
            })
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                  if(resourcesResult?.data)
                   {
                        toast.success("key add success")
                        getAllSectionTranslationKeys()
                        dispatch(setForceReload(true))
                        setNewKey('')
                        // handleCloseModal()
                   }
                } else if (resourcesResult.data.status === false) {
                }
            }
            else {
                toast.error("Error")
            }
            dispatch(setReduxLoading(false))
          
        } catch (error) {
            console.log("error",error);
            dispatch(setReduxLoading(false))
            const resourcesResult = error.response
            if (resourcesResult && resourcesResult.status === 400) {
                if (typeof resourcesResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key) => {
    
                        resourcesResult.data.data[key].map((message) => {
                            toast.error(message)
                        })
                    })
                }
                else {
    
                    toast.error(resourcesResult.data.data)
                }
    
    
            }
            else if (resourcesResult && resourcesResult.status === 401) {
    
                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else {
    
                toast.error("network error")
            }
        }
    }

    const UpdateNewKey=async()=>{
        if(!selectedKey||!selectedKeyValue){
            swal({
                title: `${t('SWEATALERT_MESSAGES.WARNNING_MESSAGE')}`,
                text: `${t('SWEATALERT_MESSAGES.ADD_NEW_KEY_AND_VALUES_message')}`,
                icon: "warning",
                button: `${t('SWEATALERT_MESSAGES.WARRNING_BUTTON')}`,
              });
              return
        }
        try {
            dispatch(setReduxLoading(true))
            const resourcesResult = await axios({
                url: Host + "translation_section_keys/"+selectedKey,
                method: "put",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                },
                data:{
                    translation_section_id:sections_id,
                    name:selectedKeyValue
                }
            })
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                  if(resourcesResult?.data)
                   {
                        toast.success("key add success")
                        dispatch(setForceReload(true))
                        getAllSectionTranslationKeys()
                        setNewKey('')
                        setSelectedKey('')
                        setSelectedKeyValue('')
                        // handleCloseModal()
                   }
                } else if (resourcesResult.data.status === false) {
                }
            }
            else {
                toast.error("Error")
            }
            dispatch(setReduxLoading(false))
          
        } catch (error) {
            console.log("error",error);
            dispatch(setReduxLoading(false))
            const resourcesResult = error.response
            if (resourcesResult && resourcesResult.status === 400) {
                if (typeof resourcesResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key) => {
    
                        resourcesResult.data.data[key].map((message) => {
                            toast.error(message)
                        })
                    })
                }
                else {
    
                    toast.error(resourcesResult.data.data)
                }
    
    
            }
            else if (resourcesResult && resourcesResult.status === 401) {
    
                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else {
    
                toast.error("network error")
            }
        }
    }

    const DeleteKey=async()=>{
        if(!selectedKey){
            swal({
                title: `${t('SWEATALERT_MESSAGES.WARNNING_MESSAGE')}`,
                text: `${t('SWEATALERT_MESSAGES.ADD_NEW_KEY_AND_VALUES_message')}`,
                icon: "warning",
                button: `${t('SWEATALERT_MESSAGES.WARRNING_BUTTON')}`,
              });
              return
        }
        try {
            dispatch(setReduxLoading(true))
            const resourcesResult = await axios({
                url: Host + "translation_section_keys/"+selectedKey,
                method: "delete",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                },
            })
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                  if(resourcesResult?.data)
                   {
                        toast.success("key deleted success")
                        dispatch(setForceReload(true))
                        getAllSectionTranslationKeys()
                        setNewKey('')
                        setSelectedKey('')
                        setSelectedKeyValue('')
                        // handleCloseModal()
                   }
                } else if (resourcesResult.data.status === false) {
                }
            }
            else {
                toast.error("Error")
            }
            dispatch(setReduxLoading(false))
          
        } catch (error) {
            console.log("error",error);
            dispatch(setReduxLoading(false))
            const resourcesResult = error.response
            if (resourcesResult && resourcesResult.status === 400) {
                if (typeof resourcesResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key) => {
    
                        resourcesResult.data.data[key].map((message) => {
                            toast.error(message)
                        })
                    })
                }
                else {
    
                    toast.error(resourcesResult.data.data)
                }
    
    
            }
            else if (resourcesResult && resourcesResult.status === 401) {
    
                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else {
    
                toast.error("network error")
            }
        }
    }

    const AddNewKeyValue=async()=>{
        try {
            dispatch(setReduxLoading(true))
            if(!selectedValueID){
                const resourcesResult = await axios({
                    url: Host + "translation_section_values",
                    method: "post",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data:{
                        value:newKeyValue,
                        language_id:localStorage.getItem('language_id'),
                        translation_section_key_id:selectedKey,
                    }
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                    if(resourcesResult?.data)
                    {
                        toast.success("key value add success")
                        setSelectedKey('')
                        setNewKeyValue('')
                        getAllSectionTranslationKeys()
                        dispatch(setForceReload(true))
                        // handleCloseModal()

                    }
                    } else if (resourcesResult.data.status === false) {
                    }
                }
                else {
                    toast.error("Error")
                }
            }else{
                const resourcesResult = await axios({
                    url: Host + "translation_section_values/"+selectedValueID,
                    method: "put",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data:{
                        value:newKeyValue,
                        language_id:localStorage.getItem('language_id'),
                        translation_section_key_id:selectedKey,
                    }
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                    if(resourcesResult?.data)
                    {
                        toast.success("key value add success")
                        setSelectedKey('')
                        getAllSectionTranslationKeys()
                        dispatch(setForceReload(true))

                        handleCloseModal()

                    }
                    } else if (resourcesResult.data.status === false) {
                    }
                }
                else {
                    toast.error("Error")
                }
            }
            dispatch(setReduxLoading(false))
          
        } catch (error) {
            console.log("error",error);
           dispatch(setReduxLoading(false))
            const resourcesResult = error.response
            if (resourcesResult && resourcesResult.status === 400) {
                if (typeof resourcesResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key) => {
    
                        resourcesResult.data.data[key].map((message) => {
                            toast.error(message)
                        })
                    })
                }
                else {
    
                    toast.error(resourcesResult.data.data)
                }
    
    
            }
            else if (resourcesResult && resourcesResult.status === 401) {
    
                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else {
    
                toast.error("network error")
            }
        }
    }


    useEffect(()=>{
        window.addEventListener("keydown", function(event) {
            if (event.key === "Enter"&&openModal) {
                // Enter key was hit
                // if(!isAddOrEdit){
                //     AddNewKey()
                // }else if(!isEditValue){
                //     UpdateNewKey()
                // }else if(!isDeleteValue){
                //     AddNewKeyValue()
                // }else{
                //     DeleteKey()
                // }
            }
        });
    },[])



   const colorAddKey={
    color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
   }
    return (
        <>
        <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          backgroundColor:MainTheme?.paperColor,
          color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
          '& > :not(style)': {
            // m: 1,
            width: '99%',
            height: '95%',
          },
        }}
      >
          
        {/* <CustomePaper> */}
            <CustomePaper>
                <Box sx={{display:'flex',justifyContent: 'space-between',alignItems: 'center',}}>
                    <Typography variant='h6' sx={{margin:'10px 20px',textAlign:'left'}}>
                        {
                            // t('SETTINGS.SIDEMENU_SETTINGS')
                            title
                        }
                    </Typography>
                    
                
                    <Box sx={{display:'flex',alignItems:'center' }}>
                        <IconButton
                            sx={{borderRadius:'50%',outline:'none !important',border:'none !important',
                            backgroundColor: MainTheme?.paperTextColor+'44',
                            '&:hover':{
                                backgroundColor: MainTheme?.paperTextColor+'55',
                            }
                        }}
                        id="demo-customized-button"
                        aria-controls={open ? 'demo-customized-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        variant="contained"
                        disableElevation
                        onClick={handleClick}
                        // onClick={handleOpenModal}
                        >
                            {/* <AddIcon sx={{color:colorAddKey?.color}}/> 
                            <Typography variant='h6' sx={{margin:'10px 20px',textAlign:'left',...colorAddKey}}>
                                {
                                  t('SETTINGS.SETTINGS_ADD_NEW_KEY_AND_VALUE')
                                }
                            </Typography> */}
                            <MoreVertIcon  sx={{color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,}} />
                        </IconButton>
                    </Box>

                </Box>

                <Box sx={{display:'flex',flexWrap: 'wrap',justifyContent: 'start',msWrapFlow:'column',}}>
                    {
                        renderFields()?.length>0&&renderFields()?.map((data)=>(
                            data
                        ))
                    }
                </Box>
                {/* <div style={{display:'flex',justifyContent:'end',alignItems: 'center'}}>
                  <Button sx={{margin:'10px 40px'}} variant="contained" onClick={saveChange}>{t('SETTINGS.SETTINGS_UPDATE_BUTTON')}</Button>
                </div> */}

            </CustomePaper>
        {/* </CustomePaper> */}
      

        <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{
                style: {
                    background: 'rgba(255, 255, 255, 0.1)',
                    backdropFilter: 'blur(3px)'
                }
            }}
        >
            {
            !isAddOrEdit?
                <CustomeBoxModal MainTheme={MainTheme}>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{display:'flex',justifyContent:'start'}}>
                {
                        t('SETTINGS.SETTINGS_ADD_NEW_KEY_AND_VALUE')
                }
                </Typography>
                <CustomeBox MainTheme={MainTheme}>
                    <CustomeInput customeWidth="100%" fieldName={'key'} value={newKey} setValue={setNewKey} label={t('SETTINGS.SETTINGS_ADD_NEW_KEY_NAME')} />
                    {/* <CustomeInput customeWidth="50%" fieldName={'value'} value={newKeyValue} setValue={setNewKeyValue} label={t('SETTINGS.SETTINGS_ADD_NEW_VALUE_NAME')} /> */}
                    {/* <CustomeInput customeWidth="50%" fieldName={'value'} value={newKeyValue_ar} setValue={setNewKeyValue_ar} label={t('SETTINGS.SETTINGS_ADD_NEW_VALUE_NAME_AR')} /> */}
                </CustomeBox>
                <div style={{display:'flex',justifyContent: 'start'}}>
                <Button 
                    sx={{
                        color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.oppositeSecondaryColor,
                        backgroundColor:MainTheme?.secondaryColor,
                        outline:'none !important',
                        border: 'none !important',
                        '&:hover':{
                        backgroundColor:MainTheme?.secondaryColor,
                        }
                    }}
                    onClick={AddNewKey}
                >{t('SETTINGS.SETTINGS_ADD_BUTTON')}</Button>
                </div>
                </CustomeBoxModal>
            :!isEditValue?
                <CustomeBoxModal MainTheme={MainTheme}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{display:'flex',justifyContent:'start'}}>
                    {
                        t('SETTINGS.SETTINGS_Edit_KEY_AND_VALUE')
                    }
                    </Typography>
                    <CustomeBox MainTheme={MainTheme}>
                        <CustomeSelectBox 
                            customeWidth="50%" 
                            label={t('SETTINGS.SETTINGS_ADD_NEW_KEY_NAME')} 
                            value={selectedKey} setValue={setSelectedKey}
                            data={CustomeKeysList} 
                            selectedKeyValue={selectedKeyValue}
                            setSelectedKeyValue={setSelectedKeyValue}
                            checkNameExistsInSearch={checkNameExistsInSearch}
                            hasClicked={true}  
                            hasValue={1}


                             />
                        <CustomeInput customeWidth="50%" fieldName={'value'} value={selectedKeyValue} setValue={setSelectedKeyValue} label={t('SETTINGS.SETTINGS_ADD_NEW_VALUE_NAME')} />
                    </CustomeBox>
                    <div style={{display:'flex',justifyContent: 'start'}}>
                        <Button 
                            sx={{
                                color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.oppositeSecondaryColor,
                                backgroundColor:MainTheme?.secondaryColor,
                                outline:'none !important',
                                border: 'none !important',
                                '&:hover':{
                                    backgroundColor:MainTheme?.secondaryColor,
                                }
                            }}
                            onClick={UpdateNewKey}
                        >
                         {t('SETTINGS.SETTINGS_ADD_BUTTON')}
                        </Button>
                    </div>
                </CustomeBoxModal>
            :!isDeleteValue?
                <CustomeBoxModal MainTheme={MainTheme}>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{display:'flex',justifyContent:'start'}}>
                {
                    t('SETTINGS.SETTINGS_Edit_VALUE_Key')
                }
                </Typography>
                <CustomeBox MainTheme={MainTheme}>
                    <CustomeSelectBox 
                        customeWidth="50%" 
                        label={t('SETTINGS.SETTINGS_ADD_NEW_KEY_NAME')} 
                        value={selectedKey} 
                        setValue={setSelectedKey} 
                        data={CustomeKeysList}
                        hasClicked={true}
                        hasValue={2}
                        setSelectedKeyValue={setNewKeyValue}
                        checkNameExistsInSearch={checkNameExistsInSearch}
                        setSelectedValueID={setSelectedValueID}
                    />
                    <CustomeInput customeWidth="50%" fieldName={'value'} value={newKeyValue} setValue={setNewKeyValue} label={t('SETTINGS.SETTINGS_ADD_NEW_VALUE_NAME')} />
                </CustomeBox>
                <div style={{display:'flex',justifyContent: 'start'}}>
                    <Button 
                        sx={{
                            color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.oppositeSecondaryColor,
                            backgroundColor:MainTheme?.secondaryColor,
                            outline:'none !important',
                            border: 'none !important',
                            '&:hover':{
                                backgroundColor:MainTheme?.secondaryColor,
                            }
                        }}
                        onClick={AddNewKeyValue}
                    >
                    {t('SETTINGS.SETTINGS_ADD_BUTTON')}
                    </Button>
                </div>
                </CustomeBoxModal>
            :
                <CustomeBoxModal MainTheme={MainTheme}>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{display:'flex',justifyContent:'start'}}>
                {
                    t('SETTINGS.SETTINGS_delete_NEW_KEY_SECTION_AND_VALUE')
                }
                </Typography>
                <CustomeBox MainTheme={MainTheme}>
                <CustomeSelectBox 
                      customeWidth="50%" 
                      label={t('SETTINGS.SETTINGS_ADD_NEW_KEY_NAME')} 
                      value={selectedKey} 
                      setValue={setSelectedKey} 
                      checkNameExistsInSearch={checkNameExistsInSearch}
                      data={CustomeKeysList}
                      hasClicked={false}
                      
                />
                <Box>
                    <Button 
                            sx={{
                                color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.oppositeSecondaryColor,
                                backgroundColor:MainTheme?.secondaryColor,
                                outline:'none !important',
                                border: 'none !important',
                                ml:2,
                                padding:'15px 25px',
                                '&:hover':{
                                    backgroundColor:MainTheme?.secondaryColor,
                                }
                            }}
                            onClick={DeleteKey}
                        >
                        {t('SETTINGS.SETTINGS_delete_BUTTON')}
                        </Button>
                </Box>
                
                </CustomeBox>
                {/* <div style={{display:'flex',justifyContent: 'start'}}>
                    <Button 
                        sx={{
                            color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.oppositeSecondaryColor,
                            backgroundColor:MainTheme?.secondaryColor,
                            outline:'none !important',
                            border: 'none !important',
                            '&:hover':{
                                backgroundColor:MainTheme?.secondaryColor,
                            }
                        }}
                        onClick={saveAddNewKey}
                    >
                    {t('SETTINGS.SETTINGS_ADD_BUTTON')}
                    </Button>
                </div> */}
                </CustomeBoxModal>
            }
        </Modal>

        <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
        >
            <MenuItem   onClick={handleOpenModal1}>
            <AddIcon sx={{color:colorAddKey?.color}}/> 
            {
                t('SETTINGS.SETTINGS_ADD_NEW_KEY_AND_VALUE')
            }
            </MenuItem>
            <MenuItem onClick={handleOpenModal2} >
            <EditIcon sx={{color:colorAddKey?.color}}/> 
            {
                t('SETTINGS.SETTINGS_Edit_KEY_AND_VALUE')
            }  
            </MenuItem>
            <MenuItem onClick={handleOpenModal3} >
            <EditIcon sx={{color:colorAddKey?.color}}/> 
            {
                t('SETTINGS.SETTINGS_Edit_VALUE_Key')
            }  
            </MenuItem>
            <Divider sx={{ my: 0.5 }} />
            <MenuItem onClick={handleOpenModal4} disableRipple>
            <RemoveCircleIcon sx={{color:colorAddKey?.color}}/> 
            {
                t('SETTINGS.SETTINGS_DELETE_KEY_AND_VALUE')
            }
            </MenuItem>
        </StyledMenu>
        </Box>
        </>
    )
}

export default SettingsList;
