import React,{useState,useEffect,useMemo,useCallback} from 'react';
import { styled, useTheme ,alpha} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import {useSelector,useDispatch} from 'react-redux'
import MenuSharpIcon from '@mui/icons-material/MenuSharp';
import SourceSharpIcon from '@mui/icons-material/SourceSharp';
import {useTranslation} from "react-i18next";
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import WidgetsSharpIcon from '@mui/icons-material/WidgetsSharp';
import * as IoIcons from 'react-icons/io5';
import * as AiIcons from 'react-icons/ai';
import * as BsIcons from 'react-icons/bs';
import * as MdIcons from 'react-icons/md';
import * as GiIcons from 'react-icons/gi';
import * as BiIcons from 'react-icons/bi';
import * as FaIcons from 'react-icons/fa';
import * as RiIcons from 'react-icons/ri';
import * as HiIcons from 'react-icons/hi';
import * as ImIcons from 'react-icons/im';
import * as CgIcone from 'react-icons/cg';
import * as SiIcone from 'react-icons/si';
import * as RiIcone from 'react-icons/ri';
import * as VscIcone from 'react-icons/vsc';
import * as AiIcone from 'react-icons/ai';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AddIcon from '@mui/icons-material/Add';
import Modal from '@mui/material/Modal';
import SettingsList from './Components/SettingsList'
// import IconButton from '@mui/material/IconButton';
import CustomeInput from './Components/CustomeInput';
import Button from '@mui/material/Button';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import ReportOffIcon from '@mui/icons-material/ReportOff';
import {
    setTranslateEnglish,
    setTranslateArabic,
} from '../../reduxStore/TranslateReducer';
import swal from 'sweetalert'
import ApartmentIcon from '@mui/icons-material/Apartment';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Host from "../../../assets/js/Host";
import { toast } from "react-toastify";
import axios from "axios";
import Cookies from "universal-cookie";
import GTranslateIcon from '@mui/icons-material/GTranslate';
import AppsIcon from '@mui/icons-material/Apps';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
// import Divider from '@mui/material/Divider';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CustomeSelectBox from '../Share/CustomeSelectBox';
import {setReduxLoading,setForceReload,setCurrentSelectedSettingPages} from '../../reduxStore/SettingsReducer'
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {TextField} from '@mui/material'
const cookies = new Cookies();
const language = cookies.get("language") ? cookies.get("language") : "ar";
const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open,height }) => ({
    zIndex:10,
    height:`100%`,
    overflowY:'scroll',
     /* width */
     "& ::-webkit-scrollbar":{
        width: "5px !important"
    },
    
    /* Track */
    "& ::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 5px grey",
        borderRadius: "10px"
    },
    
    /* Handle */
    "& ::-webkit-scrollbar-thumb":{
        background:"#000",
        borderRadius: "5px"
    },
    
    /* Handle on hover */
    "& ::-webkit-scrollbar-thumb:hover": {
        background: "#538785"
    },
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open,SideMenuOpen,SideMenuDrwerWidth }) => ({
    zIndex:10,
//   position:'relative',
  marginTop:95,
  width:SideMenuOpen?`calc(100% - ${SideMenuDrwerWidth}px)`:'100%',
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${SideMenuOpen?drawerWidth+SideMenuDrwerWidth:drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  zIndex:10,
  position:'relative',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const customeDrawer=styled(Drawer)(({theme})=>({
  position: 'relative',
}))

const CustomeAppBar=styled('div')(({theme,MainTheme})=>({
    width:'100% !important',
    display: 'flex',
    justifyContent:'space-between',
    alignItems:'center',
    textAlign: 'center',
    padding:'10px',
    '& svg':{
        color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
    }

}))

const CustomeWrapper=styled('div')(({theme,MainTheme,height})=>({
    backgroundColor:MainTheme?.paperColor,
    color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
    borderRadius:10,
    overflow:'hidden',
    display:'flex',
    height:`${height}px`,
    flex:'1 1 auto',
}))

const CustomeBox=styled(Box)(({theme,MainTheme})=>({
    display:'flex',
    alignItems: 'center',
    color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
}));


const CustomeBoxModal=styled(Box)(({theme,MainTheme})=>({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    padding:'15px',
    borderRadius:5,
    backgroundColor:MainTheme?.paperColor,
    boxShadow:" 0px 9px 14px -2px rgba(0,0,0,0.75)",
    color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
    '& input':{
        color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
    
        },
        '& .MuiOutlinedInput-root':{
            borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
            color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
        },
        '& .MuiInputLabel-root':{
            // backgroundColor:`${MainTheme?.paperTextColor+'22'} !important`,
            color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
        },
        '& .MuiFormControl-root':{
          // border:`1px solid ${MainTheme?.paperTextColor} !important`,
          color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
          },
          '&:hover fieldset': {
            borderColor: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
          },
          '&.Mui-focused fieldset': {
            borderColor: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.secondaryColor,
          },
        },
        '& .MuiOutlinedInput':{
          // borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            // border: "0 none",
         },

}));

const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));
  


export default function Setting() {
    const {t, i18n} = useTranslation('common');
    const token = cookies.get("token")
    const theme = useTheme();
    const [open, setOpen] = useState(true);
    const SideMenuDrwerWidth=useSelector(state=>state.settingsData.drawerWidth);
    const SideMenuOpen=useSelector(state=>state.settingsData.SideMenuOpen);
    const MainTheme=useSelector(state=>state.settingsData.MainTheme);
    const directions = useSelector(state => state.settingsData.directions)
    const SideMenuTheme = useSelector(state => state.settingsData.SideMenuTheme)
    const ToolBarTheme = useSelector(state => state.settingsData.ToolbarTheme)
    const sideMenuMode = useSelector(state => state.settingsData.sideMenuMode)
    const [isAddOrEdit,setIsAddOrEdit]=useState(false)
    const [listOfKeys,setListOfKeys]=useState([])
    const [selectedKey,setSelectedKey]=useState(null)
    const [isEditValue,setIsEditValue]=useState(false)
    const [isDeleteValue,setIsDeleteValue]=useState(false)
    const ListOfTranslateData=useSelector(state=> state.settingsData.ListOfTranslateData)
    const ListOfTranslateDataCode=useSelector(state=> state.settingsData.ListOfTranslateDataCode)

    const [searcBox_Text,setSearchBox_Text]=useState('')
    const [sectionFilterType,setSectionFilterType]=useState(1)

    const Translate_en=useSelector(state=>state.translateData.translation_en)
    const Translate_ar=useSelector(state=>state.translateData.translation_ar)
    const [localLanguage,setLocalLanguage]=useState(localStorage.getItem("code")?localStorage.getItem("code") : "ar");
    
    // useEffect(()=>{
    //     if(ListOfTranslateDataCode)){
    //        setLocalLanguage(ListOfTranslateDataCode)
    //     }
    //  },[ListOfTranslateDataCode])
//    const [Translate,setTranslate]=useState(()=>{},[])
    const Translate=useMemo(()=>{
        if(ListOfTranslateDataCode=='en'){
           return Translate_en
        }else{
           return Translate_ar
        }
   },[ListOfTranslateDataCode])
   
   const t_Local=(route)=>{
     const arr_route=route.split('.');
     const value=Translate[arr_route[0]][arr_route[1]];
     return value;
   }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const isopen = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleOpenModal1 = () => {
        setIsAddOrEdit(false) 
        setOpenModal(true);
        setAnchorEl(null);
    }
    const handleOpenModal2 = () => {
        setIsAddOrEdit(true)
        setIsEditValue(false)
        setIsDeleteValue(false)
        
        setOpenModal(true);
        setAnchorEl(null);
    }
    const handleOpenModal3=()=>{
        setIsEditValue(true)
        setIsDeleteValue(false)
        setIsAddOrEdit(true)

        setOpenModal(true);
        setAnchorEl(null);
    }
    const handleOpenModal4=()=>{
        setIsDeleteValue(true)
        setIsEditValue(true)
        setIsAddOrEdit(true)

        setOpenModal(true);
        setAnchorEl(null);
    }

    const EnglishValues=useSelector(state=>state.translateData.translation_en);
    const ArabicValues=useSelector(state=>state.translateData.translation_ar);

    // const translationStore=useSelector(state=>state.translateData);

    const currentSelectedSettingPages = useSelector(state=> state.settingsData.currentSelectedSettingPages)
    const [selected,setSelected]=useState(1)
    const dispatch=useDispatch();
    const [loading,setLoading]=useState(false)
    const [openModal, setOpenModal] = useState(false);
    const [newKey,setNewKey]=useState('')
    const [newKeyValue_en,setNewKeyValue_en]=useState('')
    const [newKeyValue_ar,setNewKeyValue_ar]=useState('')
    const [newKeyValue,setNewKeyValue]=useState('')
    const [AllSectionsData,setAllSectionsData]=useState([])
    const [AllSectionsDataKeys,setAllSectionsDataKeys]=useState([])
    const [AllSectionsDataKeysValues,setAllSectionsDataKeysValues]=useState([])

    const [newSection,setNewSection]=useState('')
    const [selectedSection,setSelectedSection]=useState('')
    const [selectedSectionValue,setSelectedSectionValue]=useState('')





    const [pagesLinksSections,setPagesLinkesSections]=useState([])
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () =>{
        setOpenModal(false);
        setIsAddOrEdit(false) 
        setIsAddOrEdit(false)
        setIsEditValue(false)
    }

    const getAllSectionTranslation= async () => {

        try {
            setLoading(true)
            const resourcesResult = await axios({
                url: Host + "translation_sections_list_short",
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                },
            })
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                  if(resourcesResult?.data)
                   {
                     setAllSectionsData(resourcesResult?.data)
                   }
                } else if (resourcesResult.data.status === false) {
                }
            }
            else {
                toast.error("Error")
            }
            setLoading(false)
          
        } catch (error) {
            console.log("error",error);
            setLoading(false)
            const resourcesResult = error.response
            if (resourcesResult && resourcesResult.status === 400) {
                if (typeof resourcesResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key) => {
    
                        resourcesResult.data.data[key].map((message) => {
                            toast.error(message)
                        })
                    })
                }
                else {
    
                    toast.error(resourcesResult.data.data)
                }
    
    
            }
            else if (resourcesResult && resourcesResult.status === 401) {
    
                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else {
    
                toast.error("network error")
            }
        }
    
    };

    useEffect(()=>{
        getAllSectionTranslation()
    },[ListOfTranslateDataCode])

   const [pagesLinksSettings,setPagesLinksSettings]=useState([])
    useEffect(()=>{
 
        let array=[]
        if(AllSectionsData&&AllSectionsData?.length>0){
            AllSectionsData?.map((data1)=>{          

                if(t_Local(`SETTINGS_SECTIONS.${data1?.name}`)&&checkNameExistsInSearch(data1?.name)){
                    array.push({
                        id: data1?.id,
                        subLinks: [],
                        name: t_Local(`SETTINGS_SECTIONS.${data1?.name}`),
                        component: <SettingsList title={t_Local(`SETTINGS_SECTIONS.${data1?.name}_SETTINGS`)} sections_id={data1?.id} searchValue={searcBox_Text} sectionFilterType={sectionFilterType}/>,
                        icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.USERS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
                            <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
                                <GTranslateIcon sx={{color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor+' !important':MainTheme?.paperTextColor+' !important','& svg':{color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor+' !important':MainTheme?.paperTextColor+' !important'}}} color={MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
                            </div>
                        </Tooltip>
                    })
                }
            })
        }
        setPagesLinksSettings(array)

    },[AllSectionsData,searcBox_Text,sectionFilterType])
    

    const checkNameExistsInSearch=(section)=>{
        if(sectionFilterType!==3){
          let check=false
          let keyOfSection=section.toLowerCase()
          let serchValue=searcBox_Text.toLowerCase()
          if(searcBox_Text)
           check=keyOfSection.includes(serchValue);
          else return true;


          return check;
        }else return true;
          
    }

  

    const renderPage=()=>{
        let page=null;
        pagesLinksSettings&&pagesLinksSettings?.length>0&&pagesLinksSettings?.map((itm)=>{
            console.log('itm',itm)
            if(currentSelectedSettingPages===itm?.id){
                page= itm?.component
            }
        })
        return page;
    }
  
    const handleDrawerOpen = () => {
      setOpen(!open);
    };
  
    const handleDrawerClose = () => {
      setOpen(false);
    };

    const colorAddKey={
        color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
    }

    const saveAddNewKey=()=>{
        if(!newKey||!newKeyValue_ar||!newKeyValue_en||!newKeyValue){
            swal({
                title: `${t('SWEATALERT_MESSAGES.WARNNING_MESSAGE')}`,
                text: `${t('SWEATALERT_MESSAGES.ADD_NEW_KEY_AND_VALUES_message')}`,
                icon: "warning",
                button: `${t('SWEATALERT_MESSAGES.WARRNING_BUTTON')}`,
              });
              return;
        }else{
            const arraKeys = Object.keys(ListOfTranslateData);
            // const arraKeys_ar = Object.values(ArabicValues);
            
            let check=false;
            // let check=check=arraKeys_en.include(newKey);
            // check=arraKeys_ar.include(newKey)

            arraKeys?.length>0&&arraKeys?.map((itm1)=>{
                  if(itm1==newKey){
                    check=true;
                  }
            })

            // arraKeys_ar?.length>0&&arraKeys_ar?.map((itm2)=>{
            //     if(itm2==newKey){
            //         check=true;
            //       }
            // })

            if(check){
                swal({
                    title: `${t('SWEATALERT_MESSAGES.WARNNING_MESSAGE')}`,
                    text: `${t('SWEATALERT_MESSAGES.ADD_NEW_KEY_AND_VALUES_EXISTS')}`,
                    icon: "warning",
                    button: `${t('SWEATALERT_MESSAGES.WARRNING_BUTTON')}`,
                  });
                  return;
            }



        }
        
        dispatch(setTranslateArabic({
            ...ArabicValues,
            [newKey]:newKeyValue_ar
        }))

        dispatch(setTranslateEnglish({
            ...EnglishValues,
            [newKey]:newKeyValue_en
        }))

        setNewKey('')
        setNewKeyValue_ar('')
        setNewKeyValue_en('')
        setOpenModal(false)
    }

    const AddNewSection=async()=>{
        if(!newSection){
            swal({
                title: `${t('SWEATALERT_MESSAGES.WARNNING_MESSAGE')}`,
                text: `${t('SWEATALERT_MESSAGES.ADD_NEW_KEY_AND_VALUES_message')}`,
                icon: "warning",
                button: `${t('SWEATALERT_MESSAGES.WARRNING_BUTTON')}`,
              });
              return
        }
        try {
            dispatch(setReduxLoading(true))
            const resourcesResult = await axios({
                url: Host + "translation_sections",
                method: "post",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                },
                data:{
                    name:newSection
                }
            })
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                  if(resourcesResult?.data)
                   {
                        toast.success("key add success")
                        getAllSectionTranslation()
                        // dispatch(setForceReload(true))
                        setNewSection('')
                        // handleCloseModal()
                   }
                } else if (resourcesResult.data.status === false) {
                }
            }
            else {
                toast.error("Error")
            }
            dispatch(setReduxLoading(false))
          
        } catch (error) {
            console.log("error",error);
            dispatch(setReduxLoading(false))
            const resourcesResult = error.response
            if (resourcesResult && resourcesResult.status === 400) {
                if (typeof resourcesResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key) => {
    
                        resourcesResult.data.data[key].map((message) => {
                            toast.error(message)
                        })
                    })
                }
                else {
    
                    toast.error(resourcesResult.data.data)
                }
    
    
            }
            else if (resourcesResult && resourcesResult.status === 401) {
    
                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else {
    
                toast.error("network error")
            }
        }
    }
    const UpdateSection=async()=>{
        if(!selectedSection|| !selectedSectionValue){
            swal({
                title: `${t('SWEATALERT_MESSAGES.WARNNING_MESSAGE')}`,
                text: `${t('SWEATALERT_MESSAGES.ADD_NEW_KEY_AND_VALUES_message')}`,
                icon: "warning",
                button: `${t('SWEATALERT_MESSAGES.WARRNING_BUTTON')}`,
              });
              return
        }
        try {
            dispatch(setReduxLoading(true))
            const resourcesResult = await axios({
                url: Host + "translation_sections/"+selectedSection,
                method: "put",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                },
                data:{
                    name:selectedSectionValue
                }
            })
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                  if(resourcesResult?.data)
                   {
                        toast.success("key add success")
                        getAllSectionTranslation()
                        // dispatch(setForceReload(true))
                        setSelectedSection('')
                        setSelectedSectionValue('')
                        // handleCloseModal()
                   }
                } else if (resourcesResult.data.status === false) {
                }
            }
            else {
                toast.error("Error")
            }
            dispatch(setReduxLoading(false))
          
        } catch (error) {
            console.log("error",error);
            dispatch(setReduxLoading(false))
            const resourcesResult = error.response
            if (resourcesResult && resourcesResult.status === 400) {
                if (typeof resourcesResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key) => {
    
                        resourcesResult.data.data[key].map((message) => {
                            toast.error(message)
                        })
                    })
                }
                else {
    
                    toast.error(resourcesResult.data.data)
                }
    
    
            }
            else if (resourcesResult && resourcesResult.status === 401) {
    
                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else {
    
                toast.error("network error")
            }
        }
    }
    const DeleteSection=async()=>{
        if(!selectedSection){
            swal({
                title: `${t('SWEATALERT_MESSAGES.WARNNING_MESSAGE')}`,
                text: `${t('SWEATALERT_MESSAGES.ADD_NEW_KEY_AND_VALUES_message')}`,
                icon: "warning",
                button: `${t('SWEATALERT_MESSAGES.WARRNING_BUTTON')}`,
              });
              return
        }
        try {
            dispatch(setReduxLoading(true))
            const resourcesResult = await axios({
                url: Host + "translation_sections/"+selectedSection,
                method: "delete",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                }
            })
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                  if(resourcesResult?.data)
                   {
                        toast.success("key add success")
                        getAllSectionTranslation()
                        // dispatch(setForceReload(true))
                        setSelectedSection('')
                        setSelectedSectionValue('')
                        // handleCloseModal()
                   }
                } else if (resourcesResult.data.status === false) {
                }
            }
            else {
                toast.error("Error")
            }
            dispatch(setReduxLoading(false))
          
        } catch (error) {
            console.log("error",error);
            dispatch(setReduxLoading(false))
            const resourcesResult = error.response
            if (resourcesResult && resourcesResult.status === 400) {
                if (typeof resourcesResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key) => {
    
                        resourcesResult.data.data[key].map((message) => {
                            toast.error(message)
                        })
                    })
                }
                else {
    
                    toast.error(resourcesResult.data.data)
                }
    
    
            }
            else if (resourcesResult && resourcesResult.status === 401) {
    
                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else {
    
                toast.error("network error")
            }
        }
    }

    
    useEffect(()=>{
        window.addEventListener("keydown", function(event) {
            // if (event.key === "Enter"&&openModal) {
            //     // Enter key was hit
            //     if(!isAddOrEdit){
            //         AddNewSection()
            //     }else if(!isEditValue){
            //         UpdateSection()
            //     }else if(!isDeleteValue){
            //         // AddNewKeyValue()
            //     }else{
            //         DeleteSection()
            //     }
            // }
        });
    },[])


  

  return (
    <Box >
      <CssBaseline />
      
      <CustomeAppBar MainTheme={MainTheme}>
        <IconButton
             color="inherit"
             aria-label="open drawer"
             onClick={handleDrawerOpen}
             edge="start"
             sx={{
                border: 'none !important',
                outline: 'none !important',
                '&:hover':{
                    // backgroundColor: MainTheme?.paperTextColor+'55',
                }
             }}
            >
           <MenuSharpIcon onClick={handleDrawerOpen}
            sx={{
             outline:'none',
             border:'none',
             fontSize:'30px',
             opacity:0.89
            }}
            />
        </IconButton>
        <div style={{display:'flex',alignItems:'center'}}>
        
          {/* <SourceSharpIcon sx={{margin:'0 10px',fontSize:'30px'}}/>
          <span style={{fontSize:'23px',color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor}}>{t('SETTINGS.MANDOOBEEN_SETTINGS')}</span> */}
         <Box sx={{
            width:'100%',
            backgroundColor:MainTheme?.paperColor,
            color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
            padding:'3px 0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius:'5px',
            boxShadow:'0 0 7px -2px',
          
             '& .muirtl-1a1fmpi-MuiInputBase-root-MuiInput-root:before,& .muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root:before':{
              borderColor:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,
              outlineColor:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,
            },
            '& .muirtl-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root:before,& .muiltr-1ptx2yq-MuiInputBase-root-MuiInput-root:before,& .muiltr-1a1fmpi-MuiInputBase-root-MuiInput-root:before,& .muiltr-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root:before,& .muirtl-1a1fmpi-MuiInputBase-root-MuiInput-root:hover:before, &.muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root:hover:before':{
              borderColor:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,
              outlineColor:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,
            },
            '& .muirtl-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select':{
              display:'flex',
              justifyContent: 'start',
            },
            '&.muirtl-iqd1hh-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon,& .muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root,& .muirtl-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root,& .muiltr-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root,& svg,& span,& p,& .muiltr-1ptx2yq-MuiInputBase-root-MuiInput-root':{
               color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor+' !important',
            },
            '& .muirtl-iqd1hh-MuiButtonBase-root-MuiChip-root,& .muiltr-iqd1hh-MuiButtonBase-root-MuiChip-root':{
              backgroundColor:MainTheme?.secondaryColor+'44 !important'
            },
            '& .muiltr-iqd1hh-MuiButtonBase-root-MuiChip-root svg,& .muirtl-iqd1hh-MuiButtonBase-root-MuiChip-root svg,& .muiltr-wpkdf9-MuiModal-root-MuiDialog-root svg':{
              color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor+' !important',
            },
            
      
            '& .muirtl-1yf1g2m::before,& .muiltr-1yf1g2m::before,& .muiltr-1vv4lmi::before,& .muirtl-1vv4lmi::before':{
              borderColor:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,
              outlineColor:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,
            },
            '& .muiltr-m91o2i-MuiFormControlLabel-root .MuiFormControlLabel-label,& .muirtl-m91o2i-MuiFormControlLabel-root .MuiFormControlLabel-label,& .muiltr-19d186v-MuiFormControlLabel-root .MuiFormControlLabel-label , & .muirtl-19d186v-MuiFormControlLabel-root .MuiFormControlLabel-label , & input,& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.muiltr-yi9xwg .MuiAutocomplete-inputRoot,& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.muirtl-yi9xwg .MuiAutocomplete-inputRoot ,& .muiltr-1vv4lmi,& .muirtl-1vv4lmi,& .muirtl-o1tw8u .MuiFormControlLabel-label,& .muiltr-o1tw8u .MuiFormControlLabel-label,& .muirtl-3m5ia .MuiInput-root .MuiInput-input,& .muiltr-3m5ia .MuiInput-root .MuiInput-input,& span':{
              color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor+' !important',
            },
            '& .muirtl-khgl9y .MuiFormControlLabel-label,& .muiltr-khgl9y .MuiFormControlLabel-label,& .muiltr-19d186v-MuiFormControlLabel-root .MuiFormControlLabel-label , & .muirtl-19d186v-MuiFormControlLabel-root .MuiFormControlLabel-label ,& input,& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.muiltr-yi9xwg .MuiAutocomplete-inputRoot,& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.muirtl-yi9xwg .MuiAutocomplete-inputRoot ,& .muiltr-1vv4lmi,& .muirtl-1vv4lmi,& .muirtl-o1tw8u .MuiFormControlLabel-label,& .muiltr-o1tw8u .MuiFormControlLabel-label,& .muirtl-3m5ia .MuiInput-root .MuiInput-input,& .muiltr-3m5ia .MuiInput-root .MuiInput-input,& span':{
              color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor+' !important',
            },
            '& input':{
                color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
            
                },
                '& .MuiOutlinedInput-root':{
                    borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
                    color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                },
                '& .MuiInputLabel-root':{
                    // backgroundColor:`${MainTheme?.paperTextColor+'22'} !important`,
                    color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                },
                '& .MuiFormControl-root':{
                  // border:`1px solid ${MainTheme?.paperTextColor} !important`,
                  color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                  },
                  '&:hover fieldset': {
                    borderColor: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.secondaryColor,
                  },
                },
                '& .MuiOutlinedInput':{
                  // borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
                },
                "& .MuiOutlinedInput-notchedOutline": {
                    // border: "0 none",
                 },
                '& .muirtl-1a1fmpi-MuiInputBase-root-MuiInput-root:before,& .muiltr-1a1fmpi-MuiInputBase-root-MuiInput-root:before':{
                    borderColor: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.secondaryColor,
                 }

        
            }}>
              <Box sx={{width:'200px',margin:'0 10px'}}>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="demo-simple-select-standard-label">{t('SETTINGS.SELECT_FILTER_BY')}</InputLabel>
                        <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={sectionFilterType}
                        onChange={(e)=>{
                            setSectionFilterType(e.target.value)
                        }}
                        label={t('SETTINGS.SELECT_FILTER_BY')}
                        >
                            <MenuItem value={1}>Section</MenuItem>
                            <MenuItem value={2}>all</MenuItem>
                            <MenuItem value={3}>Key</MenuItem>

                        </Select>
                    </FormControl>
              </Box>
              <Box  sx={{margin:'0 10px'}}>
                 <TextField 
                     focused={true}
                     id="standard-basic" 
                     label={t('SETTINGS.SEARCH_BOX')} 
                     variant="standard" 
                     value={searcBox_Text}
                     onChange={(e)=>{
                        setSearchBox_Text(e.target.value)
                     }}
                />
              </Box>
         </Box>


        </div>
        <Box sx={{display:'flex',alignItems:'center' }}>
            <IconButton
             sx={{borderRadius:'50%',outline:'none !important',border:'none !important',
             backgroundColor: MainTheme?.paperTextColor+'44',
              '&:hover':{
                backgroundColor: MainTheme?.paperTextColor+'55',
              }
            }}
              onClick={handleClick}
             >
                {/* <AddIcon sx={{color:colorAddKey?.color}}/> 
                <Typography variant='h6' sx={{margin:'10px 20px',textAlign:'left',...colorAddKey}}>
                    {
                    t('SETTINGS.SETTINGS_ADD_NEW_KEY_SECTION_AND_VALUE')
                    }
                </Typography> */}
                <AppsIcon  sx={{color:colorAddKey?.color}}/>
            </IconButton>
        </Box>
      </CustomeAppBar>
      <CustomeWrapper MainTheme={MainTheme} height={window.innerHeight-250}>
        <Drawer
            sx={{
            zIndex:1,
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                position:'relative',
                zIndex:1,
                height:'100% !important',
                overflowY:'scroll',
               /* width */
                "& ::-webkit-scrollbar":{
                    width: "5px"
                },
                
                /* Track */
                "& ::-webkit-scrollbar-track": {
                    boxShadow: "inset 0 0 5px grey",
                    borderRadius: "10px"
                },
                
                /* Handle */
                "& ::-webkit-scrollbar-thumb":{
                    background:"#4c7c7a",
                    borderRadius: "5px"
                },
                
                /* Handle on hover */
                "& ::-webkit-scrollbar-thumb:hover": {
                    background: "#538785"
                },
                // left:SideMenuOpen?SideMenuDrwerWidth:0,
                // top:150,
                width: drawerWidth,
                boxSizing: 'border-box',
                backgroundColor:MainTheme?.paperColor,
                color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                // marginLeft:10,
            },
            }}
            drawerWidth={drawerWidth}
            variant="persistent"
            anchor="left"
            open={open}
        >
            {/* <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
            </DrawerHeader> */}
            <List>
                {pagesLinksSettings.map((itmMenu, index) => (
                    <ListItem
                      sx={{
                        '&:hover':{
                            backgroundColor: MainTheme?.paperTextColor+'55',
                          }
                      }}
                     key={itmMenu?.id} disablePadding onClick={()=>dispatch(setCurrentSelectedSettingPages(itmMenu?.id))}>
                        <ListItemButton>
                            {
                                itmMenu?.icon
                            }
                            <ListItemText primary={itmMenu?.name} sx={{margin:'5px 10px'}} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
           
        </Drawer>
        <Main drawerWidth={drawerWidth} open={open}  >
             {
                renderPage()
             }
        </Main>
      </CustomeWrapper>

      <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{
                style: {
                    background: 'rgba(255, 255, 255, 0.1)',
                    backdropFilter: 'blur(3px)'
                }
            }}
        >
            {
            !isAddOrEdit?
                <CustomeBoxModal MainTheme={MainTheme}>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{display:'flex',justifyContent:'start'}}>
                {
                        t('SETTINGS.SETTINGS_ADD_NEW_KEY_SECTION_AND_VALUE')
                }
                </Typography>
                <CustomeBox MainTheme={MainTheme}>
                    <CustomeInput 
                          customeWidth="100%" 
                          fieldName={'key'} 
                          value={newSection} 
                          setValue={setNewSection} 
                          label={t('SETTINGS.SETTINGS_ADD_NEW_KEY_NAME')} 
                    />
                </CustomeBox>
                <div style={{display:'flex',justifyContent: 'start'}}>
                <Button 
                    sx={{
                        color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.oppositeSecondaryColor,
                        backgroundColor:MainTheme?.secondaryColor,
                        outline:'none !important',
                        border: 'none !important',
                        '&:hover':{
                        backgroundColor:MainTheme?.secondaryColor,
                        }
                    }}
                    onClick={AddNewSection}
                >{t('SETTINGS.SETTINGS_ADD_BUTTON')}</Button>
                </div>
                </CustomeBoxModal>
            :!isEditValue?
                <CustomeBoxModal MainTheme={MainTheme}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{display:'flex',justifyContent:'start'}}>
                    {
                        t('SETTINGS.SETTINGS_edit_NEW_KEY_SECTION_AND_VALUE')
                    }
                    </Typography>
                    <CustomeBox MainTheme={MainTheme}>
                     <CustomeSelectBox 
                             customeWidth="50%" 
                             label={t('SETTINGS.SETTINGS_ADD_NEW_KEY_NAME')} 
                             value={selectedSection} 
                             setValue={setSelectedSection} 
                             data={AllSectionsData} 
                             checkNameExistsInSearch={checkNameExistsInSearch}
                             selectedKeyValue={selectedSectionValue}
                             setSelectedKeyValue={setSelectedSectionValue}
                             hasClicked={true}  
                             hasValue={3}
                        />
                     <CustomeInput customeWidth="50%" fieldName={'value'} value={selectedSectionValue} setValue={setSelectedSectionValue} label={t('SETTINGS.SETTINGS_ADD_NEW_VALUE_NAME')} />
                    </CustomeBox>
                    <div style={{display:'flex',justifyContent: 'start'}}>
                        <Button 
                            sx={{
                                color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.oppositeSecondaryColor,
                                backgroundColor:MainTheme?.secondaryColor,
                                outline:'none !important',
                                border: 'none !important',
                                '&:hover':{
                                    backgroundColor:MainTheme?.secondaryColor,
                                }
                            }}
                            onClick={UpdateSection}
                        >
                         {t('SETTINGS.SETTINGS_ADD_BUTTON')}
                        </Button>
                    </div>
                </CustomeBoxModal>
            :!isDeleteValue?
                <CustomeBoxModal MainTheme={MainTheme}>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{display:'flex',justifyContent:'start'}}>
                {
                    t('SETTINGS.SETTINGS_edit_value_of_SECTION_key')
                }
                </Typography>
                <CustomeBox MainTheme={MainTheme}>
                <CustomeSelectBox customeWidth="50%" label={t('SETTINGS.SETTINGS_ADD_NEW_KEY_NAME')} value={selectedKey} setValue={listOfKeys} />
                {/* <CustomeInput customeWidth="50%" fieldName={'key'} value={newKey} setValue={setNewKey} label={t('SETTINGS.SETTINGS_ADD_NEW_KEY_NAME')} /> */}
                <CustomeInput customeWidth="50%" fieldName={'value'} value={newKeyValue} setValue={setNewKeyValue} label={t('SETTINGS.SETTINGS_ADD_NEW_VALUE_NAME')} />
                {/* <CustomeInput customeWidth="50%" fieldName={'value'} value={newKeyValue_ar} setValue={setNewKeyValue_ar} label={t('SETTINGS.SETTINGS_ADD_NEW_VALUE_NAME_AR')} /> */}
                </CustomeBox>
                <div style={{display:'flex',justifyContent: 'start'}}>
                    <Button 
                        sx={{
                            color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.oppositeSecondaryColor,
                            backgroundColor:MainTheme?.secondaryColor,
                            outline:'none !important',
                            border: 'none !important',
                            '&:hover':{
                                backgroundColor:MainTheme?.secondaryColor,
                            }
                        }}
                        onClick={saveAddNewKey}
                    >
                    {t('SETTINGS.SETTINGS_ADD_BUTTON')}
                    </Button>
                </div>
                </CustomeBoxModal>
            :
            <CustomeBoxModal MainTheme={MainTheme}>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{display:'flex',justifyContent:'start'}}>
                {
                    t('SETTINGS.SETTINGS_delete_NEW_KEY_SECTION_AND_VALUE')
                }
                </Typography>
                <CustomeBox MainTheme={MainTheme}>
                   <CustomeSelectBox 
                         customeWidth="50%" 
                         label={t('SETTINGS.SETTINGS_ADD_NEW_KEY_NAME')} 
                         value={selectedSection} 
                         setValue={setSelectedSection} 
                         data={AllSectionsData} 
                         checkNameExistsInSearch={checkNameExistsInSearch}
                         hasClicked={false}  
                         
                         />
                   <Box>
                    <Button 
                            sx={{
                                color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.oppositeSecondaryColor,
                                backgroundColor:MainTheme?.secondaryColor,
                                outline:'none !important',
                                border: 'none !important',
                                ml:2,
                                padding:'15px 25px',
                                '&:hover':{
                                    backgroundColor:MainTheme?.secondaryColor,
                                }
                            }}
                            onClick={DeleteSection}
                        >
                        {t('SETTINGS.SETTINGS_delete_BUTTON')}
                        </Button>
                   </Box>
                   
                </CustomeBox>
                {/* <div style={{display:'flex',justifyContent: 'start'}}>
                    <Button 
                        sx={{
                            color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.oppositeSecondaryColor,
                            backgroundColor:MainTheme?.secondaryColor,
                            outline:'none !important',
                            border: 'none !important',
                            '&:hover':{
                                backgroundColor:MainTheme?.secondaryColor,
                            }
                        }}
                        onClick={saveAddNewKey}
                    >
                    {t('SETTINGS.SETTINGS_ADD_BUTTON')}
                    </Button>
                </div> */}
            </CustomeBoxModal>
            
            }
      </Modal>


        <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
            }}
            anchorEl={anchorEl}
            open={isopen}
            onClose={handleClose}
        >
            <MenuItem   onClick={handleOpenModal1}>
            <AddIcon sx={{color:colorAddKey?.color}}/> 
            {
                t('SETTINGS.SETTINGS_ADD_NEW_KEY_SECTION_AND_VALUE')
            }
            </MenuItem>
            <MenuItem onClick={handleOpenModal2} >
            <EditIcon sx={{color:colorAddKey?.color}}/> 
            {
                t('SETTINGS.SETTINGS_edit_NEW_KEY_SECTION_AND_VALUE')
            }  
            </MenuItem>
            <MenuItem onClick={handleOpenModal3} disabled>
            <EditIcon sx={{color:colorAddKey?.color}}/> 
            {
                t('SETTINGS.SETTINGS_edit_value_of_SECTION_key')
            }  
            </MenuItem>
            <Divider sx={{ my: 0.5 }} />
            <MenuItem onClick={handleOpenModal4} disableRipple>
            <RemoveCircleIcon sx={{color:colorAddKey?.color}}/> 
            {
                t('SETTINGS.SETTINGS_delete_NEW_KEY_SECTION_AND_VALUE')
            }
            </MenuItem>
        </StyledMenu>
    </Box>
  )
}
