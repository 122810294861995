import React, { useEffect,useState } from 'react'

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { AiFillCloseCircle } from 'react-icons/ai'
import { Box, IconButton, Typography } from '@mui/material';
import DialogForm from '../Share/DialogForm';
import GoogleMap from '../GoogleMap/GoogleMap';
import {useSelector,useDispatch} from 'react-redux'
import {useTranslation} from "react-i18next";
import { useGlobalContext } from '../../../context'
import { toast } from 'react-toastify';
import moment from 'moment'

export default function PointsMapDialog(props) {
  const descriptionElementRef = React.useRef(null);
  const directions= useSelector(state=> state.settingsData.directions)
  const MainTheme= useSelector(state=> state.settingsData.MainTheme)
  const [t, i18n] = useTranslation('common');
  const {selectedUsers,visitType,minDistance, maxDistance,fromToDate, GetRegionOfPoints,getRegionOfPoint, loading, pointsStatuses, pointStatus, setPointStatus, exportToCSV, points, district, region, districts, regions, ownerName, shopName, clearPointsAdvanceSearchFields, setRegion, setDistrict, setShopName, setOwnerName, fetchData, deleteObject } = useGlobalContext()
  const [mapPoints, setMapPoints] = useState([])
  const [mapVisits, setMapVisits] = useState([])
  const [mapMarkers, setMapMarkers] = useState([])
  const [polygons, setPolygons] = useState([])
  const [listOfMarkers,setListOfMakrkers]=useState([])
  const mapFunction=async () => {
    const fetchedData = await fetchData('ALL_POINTS', 'points', {
        page_size: props?.pointsTotal,
        shop_name: shopName,
        owner_name: ownerName,
        district: district.id,
        region: region.id,
        point_type_id: pointStatus.map((status) => status.id),
    })
    mapObjects(fetchedData)
}

  const mapObjects = async (object) => {
    const bgcolors=['#ff0000', '#00ff00', '#0000ff', '#ff00ff', '#ffff00', '#00ffff']
    if (object.data.length > 3000) {
        return (
            toast.error(`Too many points to display!`)
        );
    }
    let arr=[];
    let arr_2=[]
    setMapPoints(object?.data && object?.data?.length > 0 ? object?.data?.map(point => {
      
        return {
            ...point,
        }
    }) : [])
    // console.log('sadasdasdasdadasdasdasd',object?.data)
    object?.data && object?.data?.length > 0&& object?.data?.map(point => {
        let bgColor__itm=bgcolors[Math.floor(Math.random() * 6)]


        let checkExistsRegion=false;
        arr&&arr?.length>0&&arr?.map((itm)=>{
           if(itm?.region_id==point?.region?.id)
            checkExistsRegion=true;
        })
        if(!checkExistsRegion){
          arr.push({
              strokeColor: point?.region?.color,
              fillColor: point?.region?.color+'44',
              name: point?.region?.name,
              region_id:point?.region?.id,
              paths: point?.region?.region_geofences?.map(geofence => {
                  return{
                          lat: geofence.latitude,
                          lng: geofence.longitude,
                          id: geofence?.id
                      }
              })
          })
        }

        point?.region?.region_geofences?.map(geofence => {
          arr_2.push({
            lat: geofence.latitude,
            lng: geofence.longitude,
            id: geofence?.id
          })
      })
      
    })

    // console.log('asasdasdaada',arr)
    // // if(object)
    setPolygons(arr);
    setListOfMakrkers(arr_2)
    // setshowPointsMapDialog(true)
    // GetRegionOfPoints('GET_REGIONS_OF_POINT','point_geofence/'+object?.id)
  
  }


  const mapFunctionVisitReport=async () => {
        
  const fetchedData = await fetchData('ALL_VISITS', 'visits_map_report', {
        page_size: props?.visitsReportTotal,
        shop_name: shopName,
        owner_name: ownerName,
        district: district.id,
        region: region.id,
        visit_type_id: visitType.map((type) => type.id),
        creation_date_from: moment(fromToDate[0])?.format('YYYY-MM-DD'),
        creation_date_to: moment(fromToDate[1])?.format('YYYY-MM-DD'),
        min_distance: maxDistance,
        max_distance: minDistance,
        collectors:selectedUsers? selectedUsers?.map(user=>user?.id):null
    })
    mapObjectsVisitReoprts(fetchedData)
  }

  const mapObjectsVisitReoprts = async (object) => {

    if (object.length > 3000) {
        return (
            toast.error(`Too many visits to display!`)
        );
    }
    

    if(object?.length>0)
    {
        let markers = []
        object?.map((mapPoint, index) => {

            mapPoint?.visits?.map((visit, indexVisit) => {
                    markers.push(
                    {
                        id:visit?.id,
                        name:"End",
                        label:"E" + (index + 1) + "-" + (indexVisit + 1),
                        latitude:visit?.end_latitude,
                        longitude:visit?.end_longitude,
                        owner_name:visit?.created_by?.full_name,
                        specialColor:"#FF00FF",
                        point_type:{
                            name:""
                        }
                    })
                    markers.push(
                    {
                        id:visit?.id*-1,
                        name:"Start",
                        label:"S" + (index + 1) + "-" + (indexVisit + 1),
                        latitude:visit?.start_latitude,
                        longitude:visit?.start_longitude,
                        owner_name:visit?.created_by?.full_name,
                        specialColor:"#00FFFF",
                        point_type:{
                            name:""
                        }
                    })
                    
            })
            markers.push(
            {
                ...mapPoint?.point,
                label: "P" + (index + 1)
            })
        })

        setMapMarkers(markers)
        
    }
    // setMapVisits(object)
    // setshowVisitsMapDialog(true)

  }
  
 
  useEffect(()=>{
    if(!props?.inVisitPage&&!props?.hasVisitReport)
      mapFunction()

    if(props?.hasVisitReport)
      mapFunctionVisitReport()

  },[props?.hasVisitReport,props?.inVisitPage])

  useEffect(()=>{
    fetchData('REGIONS','regions_list')
  },[props?.showAllRegionsOnMaps])

  useEffect(()=>{
    if(regions&&regions?.length>0&&props?.showAllRegionsOnMaps){
     console.log('regionssadasdas=>',regions)
     let arr=[]
     let arr2=[]
     regions?.map(region => {
      arr.push({
             name: region?.name,
             paths: region?.region_geofences?.map((geoFence) => {
                 return {
                     lat: geoFence?.latitude,
                     lng: geoFence?.longitude,
                     id: geoFence?.id

                 }
             }),
             strokeColor: region?.color,
             fillColor: region?.color,
      })

     })

    //  region?.region_geofences?.map(geofence => {
    //     arr_2.push({
    //       lat: geofence.latitude,
    //       lng: geofence.longitude,
    //       id: geofence?.id
    //     })
    //  })

     setPolygons(arr)

    }
  },[regions])



  return (
    <DialogForm
      open={props.showPointsMapDialog}
      close={props.setshowPointsMapDialog}
      loaderActive={loading||props?.loading}
      headerChildren={(
        <>
           <IconButton aria-label={"Close"} onClick={() => { props.setshowPointsMapDialog(false) }} style={{ position: 'absolute', top: '0px', left: '6px', fontSize: '37px', }}>
            <AiFillCloseCircle style={{ color:MainTheme?.paperTextColor,outline:'none !imoort',border:"none !import" }} />
          </IconButton>

          <Typography component={'h1'} variant={'h4'} style={{marginTop:'8px',marginBottom:'10px', color: MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor, width: '100%', textAlign: 'center' }}>
              {props?.title}
          </Typography>
        </>
      )}
      hasHeader={true}
      CustomeWidth='80%'
      CustomeHeight="100%"
    >


        <DialogContentText
          id="scroll-dialog-description"
          sx={{
            height: '100%',
            direction: 'ltr',
          }}
          ref={descriptionElementRef}
          tabIndex={-1}
        >

          
          <Box style={{
            padding: '10px',
            paddingTop: '20px',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
      
          

            {props.showPointsMapDialog && !props?.loading &&
              <GoogleMap 
              markers={props?.inVisitPage?props?.points:props?.hasVisitReport?mapMarkers:mapPoints}
              polygon={props?.polygon} 
              polygons={!props?.showAllRegionsOnMaps?!props?.inVisitPage&&!props?.hasVisitReport?polygons:props?.polygons:polygons}
              allowScroolZooming={props?.allowScroolZooming}
              isPointAllShow={props?.isPointAllShow?true:props?.showAllRegionsOnMaps?true:false}
              zoomedOut={props?.zoomedOut?true:false}
              />
            }
          

          </Box>




        </DialogContentText>

    </DialogForm>

  )
}
