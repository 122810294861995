
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import CompanyForm from './CompanyForm';
import IconButton from "@mui/material/IconButton";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import Tooltip from '@mui/material/Tooltip';
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useSelector } from 'react-redux'
import FilterBox from '../Share/FilterBox';
import "../../../assets/css/DG.css"
import "../../../assets/css/AccordionStyle.css"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import { useGlobalContext } from '../../../context'
import Grid from '../Share/Grid'
import SearchField1 from '../GeneralComponents/SearchField1';
import helpers from '../../../assets/js/helper';
import { Avatar } from '@mui/material';
import {useTranslation} from "react-i18next";
import BasicDatePickerV2 from '../GeneralComponents/BasicDatePickerV2';
import SearchFieldV2 from '../GeneralComponents/SearchFieldV2'
import CustomeFilter from '../Share/CustomeFilter'

function Companies() {
    const directions = useSelector(state => state.settingsData.directions)
    const {t, i18n} = useTranslation('common');

    const Language=React.useMemo(()=>{
        if(directions!=='rtl'){
          return require('../../Settings/Translate_en.json').COMPANIES
        }else{
          return require('../../Settings/Translate_ar.json').COMPANIES
        }
    },[directions])

    const [pageSize, setPageSize] = useState(15);
    const [pageNumber, setpageNumber] = useState(1);
    const [pointsTotal, setPointsTotal] = useState(0)
    const [pointsNumberOfPages, setPointsNumberOfPages] = useState(0)
    const { loading, data, exportToCSV, district, company, districts, companies, clearPointsAdvanceSearchFields, setCompany, setDistrict, fetchData, deleteObject } = useGlobalContext()
    const [rows, setRow] = useState([])
    const filterSearchBoxMode = useSelector(state=> state.settingsData.filterSearchBoxMode)

    const [showCompanyForm, setshowCompanyForm] = useState(false);
    const [selectedCompany, setselectedCompany] = useState(null);
    const [accExpansion, setAccExpansion] = useState(false)
    const [companyName, setCompanyName] = useState("")
    const FilterButtonHeight= useSelector(state => state.settingsData.FilterButtonHeight)
    const screenWidthRedux=useSelector(state=>state.settingsData.screenWidth)

    const SearchButtonTheme=useSelector(state=> state.settingsData.SearchButtonTheme);
    const MainTheme= useSelector(state=> state.settingsData.MainTheme)

    const handlePageChange = (newPage) => {
        setpageNumber(newPage + 1)
    }

    const handleExpansion = (expansion) => setAccExpansion(expansion)

    const advanceSearch = async (type = "BROWSING") => {
        const fetchedData = await fetchData('DATA', 'companies', {
            page: type === "SEARCH" ? 1 : pageNumber,
            page_size: pageSize,
            district: district.id,
            company_name: companyName,
        });
        return fetchedData
    }
    const updateObject = async (object) => {
        setselectedCompany(object)
        setshowCompanyForm(true)
    };

    const createObject = async (object) => {
        setselectedCompany(null)
        setshowCompanyForm(true)
    };


    const handleSetshowCompanyForm = (openStatus, needsReload) => {
        if (needsReload)
            advanceSearch()
        setshowCompanyForm(openStatus)
    };
    

    useEffect(() => {
        advanceSearch()
    }, [pageSize, pageNumber])

    useEffect(() => {
        if (data && data.data && data.data.length > 0) {
            setRow(data.data);
            setPointsTotal(data.total)
            setPointsNumberOfPages(data.last_page)
        }else{
            setRow([]);
            setPointsTotal(0)
            setPointsNumberOfPages(0)
        }
    }, [data])

    const columns = [
        {
            field: 'id', headerName: t('COMPANY.ID'), flex: .1,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions == 'rtl' ? 'alignColumnRight' : 'alignColumnLeft'}`}>
                    {rows?.row?.id}
                </div>
            )
        },
        {
            
            field: 'image', headerName: t('COMPANY.IMAGE') , flex: 1,
            renderCell:(rows)=>{
                const imageSrc=helpers.getImageUrl(rows?.row?.image?.image_url)
               
                return (
                    <Avatar
                            src={imageSrc}
                            name={rows?.row?.company_name}
                            size={40}
                            sx={{
                                backgroundColor:MainTheme?.paperTextColor+'99',
                                color:MainTheme?.paperTextColor,
                            }}
                        />
                )
            }
        },
        {
            field: 'company_name', headerName: t('COMPANY.COMPANY_NAME'), flex: 1.5,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions == 'rtl' ? 'alignColumnRight' : 'alignColumnLeft'}`}>
                    {rows?.row?.company_name}
                </div>
            )
        },

        {
            field: 'number_of_users', headerName: t('COMPANY.NUMBER_OF_USERS'), flex: 3, renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions == 'rtl' ? 'alignColumnRight' : 'alignColumnLeft'}`}>
                    {rows?.row?.number_of_users}
                </div>
            )
        },
        {
            field: 'price', headerName: t('COMPANY.PRICE') , flex: 3, renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions == 'rtl' ? 'alignColumnRight' : 'alignColumnLeft'}`}>
                    {rows?.row?.price}
                </div>
            )
        },
        {
            field: 'billing_frequency.period_name', headerName: t('COMPANY.PERIOD_NAME'), flex: 3, renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions == 'rtl' ? 'alignColumnRight' : 'alignColumnLeft'}`}>
                    {rows?.row?.billing_frequency?.period_name}
                </div>
            )
        },
        {
            field: 'billing_frequency.discount_percentage', headerName: t('COMPANY.DISCOUNT_PERCENTAGE'), flex: 3, renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions == 'rtl' ? 'alignColumnRight' : 'alignColumnLeft'}`}>
                    {rows?.row?.billing_frequency?.discount_percentage}
                </div>
            )
        },
        {
            field: 'actions', flex: 2,
            headerName: t('COMPANY.ACTIONS') ,
            renderCell: (rows) => (
                <strong className={`${directions == 'rtl' ? 'alignColumnRight' : 'alignColumnLeft'}`}>
                    <IconButton
                        style={{ marginLeft: 15 }}
                        aria-label="edit"
                        size="small"
                        onClick={() => {
                            updateObject(rows.row)
                        }}
                    >
                        <Tooltip title="Edit">
                            <ModeEditOutlineRoundedIcon style={{ fill: "#111827" }} />
                        </Tooltip>
                    </IconButton>
                </strong>
            ),
        },
    ]

    const ExportFunction=async () => {
        const fetchedData = await fetchData('DATA', 'companies', {
            page_size: pointsTotal,
            district: district?.id,
            company: company?.id,
        });

        exportToCSV(fetchedData.data, 'companies')
    }
    const createFunction=()=>{
        createObject()
    }
    const mapFunction=async () => {
            // const fetchedData = await fetchData('ALL_POINTS', 'points', {
            //     page_size: pointsTotal,
            //     shop_name: shopName,
            //     owner_name: ownerName,
            //     district: district.id,
            //     region: region.id,
            //     point_type_id: pointStatus.map((status) => status.id),
            // })
            // mapObjects(fetchedData)
     }
    const importFunction=()=>{
        // importObjects()
    }

    return (
        <>
            {showCompanyForm ?
                <CompanyForm company={selectedCompany} showCompanyForm={showCompanyForm} setshowCompanyForm={handleSetshowCompanyForm} />
                : null}
            {
              !filterSearchBoxMode?
               <FilterBox 
                exportFunction={ExportFunction}
                createFunction={createFunction}
                // mapFunction={mapFunction}
                // importFunction={importFunction}
                hasExport={true} 
                hasCreate={true} 
                hasMaps={false} 
                hasImport={false}
                resultLength={pointsTotal}
                customeHeight="140px"
                >
       
                            
                <SearchField1 customeWidth={screenWidthRedux<=1100?'98%':"50%"} customeMargin="10px" state={companyName} setState={setCompanyName} fieldLabel={t('GLOBAL.SEARCH_COMPANY_NAME')} />
                <Box display="flex" sx={{ justifyContent:'end',width:'100%' }}>
                   <Box display="flex" >
                            <Button
                                variant="contained"
                                spacing={2} 
                                sx={{ flexGrow: 1, margin: 1,backgroundColor:SearchButtonTheme?.search_button_color,color:SearchButtonTheme?.search_button_text_color,
                                    '&:hover':{
                                        backgroundColor:SearchButtonTheme?.search_button_color+'88',
                                    },
                                    height:FilterButtonHeight
                                }}
                                className="iconeFilterSearch"
                                onClick={() => advanceSearch("SEARCH")} >
                                {t('GLOBAL.SEARCH_SEARCH_BUTTON')}
                            </Button>
                            <Button variant="outlined"
                                spacing={2}
                                sx={{
                                        flexGrow: 1, margin: 1,
                                        // backgroundColor:SearchButtonTheme?.clear_button_color,
                                        color:SearchButtonTheme?.clear_button_text_color,
                                    '&:hover':{
                                        backgroundColor:`${SearchButtonTheme?.clear_button_color}   !important`,
                                    },
                                    height:FilterButtonHeight
                                }}
                                className="iconeFilterClear"
                                color="secondary"
                                onClick={clearPointsAdvanceSearchFields} >
                                {t('GLOBAL.SEARCH_CLEAR_BUTTON')}
                            </Button>
                    </Box>
                </Box>

               </FilterBox>:null
             }

            <Box 
             sx={{
                display:'flex',
                justifyContent: 'start',
                alignItems: 'start',
                width:'100%',
             }}
            >
                <Box
                    sx={{
                    width:'100%',
                    }}
                >
                    <Grid
                        rows={rows}
                        columns={columns}
                        rowTotals={pointsTotal}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        pageNumber={pageNumber}
                        setPageNumber={setpageNumber}
                        loading={loading}
                        handlePageChange={handlePageChange}
                        NumberOfPage={pointsNumberOfPages}
                        directions={directions}
                        hasCreate={false}
                        createObject={null}
                        title_en=""
                        title_ar=""
                    />
                </Box>

                {
                    filterSearchBoxMode?
                    <CustomeFilter
                        exportFunction={ExportFunction}
                        createFunction={createFunction}
                        // mapFunction={mapFunction}
                        // importFunction={importFunction}
                        hasExport={true} 
                        hasCreate={true} 
                        hasMaps={false} 
                        hasImport={false}
                        resultLength={pointsTotal}
                    //    customeHeight={screenWidth<=1100?'460px':"350px"}
                    >
                        <Box
                            sx={{
                                width:'100%',
                                height:'400px',
                                minHeight:'400px',
                                maxHeight:'400px',
                                overflowY:'scroll',
                                overflowX:'hidden',
                                padding:'10px',
                                marginTop:'10px',
                                paddingTop:'20px',
                                // boxShadow:'0 0 7px -5px #000 inset'
                            }}
                        >
                         <SearchFieldV2 customeWidth={screenWidthRedux<=1100?'100%':"100%"}  state={companyName} setState={setCompanyName} fieldLabel={t('GLOBAL.SEARCH_COMPANY_NAME')} />
                        
                        </Box>
                        <Box sx={{display:'flex',justifyContent: 'end',alignItems: 'center',width:'100%'}}>
                            <Box display="flex" >
                                    <Button
                                        variant="contained"
                                        // spacing={2} 
                                        sx={{ 
                                            // flexGrow: 1,
                                            margin: 1,
                                            width:'80px !important',
                                            minWidth:'80px !important',
                                            maxWidth:'80px !important',
                                            backgroundColor:SearchButtonTheme?.search_button_color,color:SearchButtonTheme?.search_button_text_color,
                                            '&:hover':{
                                                backgroundColor:SearchButtonTheme?.search_button_color+'88',
                                            },
                                            height:FilterButtonHeight-10
                                        }}
                                        className="iconeFilterSearch"
                                        onClick={() => advanceSearch("SEARCH")} >
                                        {t('GLOBAL.SEARCH_SEARCH_BUTTON')}
                                    </Button>
                                    <Button variant="outlined"
                                        // spacing={2}
                                        sx={{
                                            width:'50% !important',
                                                // flexGrow: 1, 
                                                minWidth:'80px !important',
                                                maxWidth:'80px !important',
                                                margin: 1,
                                                // backgroundColor:SearchButtonTheme?.clear_button_color,
                                                color:SearchButtonTheme?.clear_button_text_color,
                                            '&:hover':{
                                                backgroundColor:`${SearchButtonTheme?.clear_button_color}   !important`,
                                            },
                                            height:FilterButtonHeight-10
                                        }}
                                        className="iconeFilterClear"
                                        color="secondary"
                                        onClick={clearPointsAdvanceSearchFields} >
                                        {t('GLOBAL.SEARCH_CLEAR_BUTTON')}
                                    </Button>
                            </Box>
                        </Box>
                    </CustomeFilter>:null
                }
            </Box>


           <br />
           <br />
           <br />
           <br />
        </>
    );
}

export default Companies;


// <Box display="flex" >
                
// <Button display="flex"
//     startIcon={<FileDownloadIcon />}
//     variant="contained"
//     sx={{ marginRight: 1, marginBottom: accExpansion ? "0" : "20px" }}
//     onClick={async () => {

//         const fetchedData = await fetchData('DATA', 'companies', {
//             page_size: pointsTotal,
//             district: district.id,
//             company: company.id,
//         });

//         exportToCSV(fetchedData.data, 'companies')
//     }
//     }>
//     {Language?.EXPORT}
// </Button>

// <Button display="flex"
//     startIcon={<AddCircleRoundedIcon />}
//     variant="contained"
//     sx={{ marginRight: 1, marginBottom: accExpansion ? "0" : "20px" }}
//     onClick={() => { createObject() }}>
//     {Language?.CREATE}
// </Button>

// <Accordion display="flex" sx={{ flexGrow: 4 }}
//     onChange={(event, expansion) => handleExpansion(expansion)}>
//     <AccordionSummary
//         expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
//         aria-controls="panel1a-content"
//         id="panel1a-header"
//     >
//         <Typography>{Language?.ADVANCE_SEARCH}</Typography>
//     </AccordionSummary>
//     <AccordionDetails>
//         <Box component="span" display="flex" bgcolor="white" flexDirection="column" sx={{ borderRadius: 1 }}>
//             <Box sx={{ m: 1 }} component="span" display="flex" bgcolor="white" flexDirection="row" >
//                 <Box display="flex" sx={{ flexGrow: 2 }}>
                
//                 <SearchField1 state={companyName} setState={setCompanyName} fieldLabel={Language?.COMPANY_NAME} />
                    
//                 </Box>
//                 <Box display="flex" sx={{ flexGrow: 1 }}>
//                     <Button
//                         variant="contained"
//                         spacing={2} sx={{ flexGrow: 1, marginLeft: 1, marginRight: 1, height: 55 }}
//                         onClick={advanceSearch} >
//                         {Language?.SEARCH}
//                     </Button>
//                     <Button variant="contained"
//                         spacing={2}
//                         sx={{ flexGrow: 1, margin: 0, height: 55 }}
//                         color="secondary"
//                         onClick={clearPointsAdvanceSearchFields} >
//                         {Language?.CLEAR}
//                     </Button>
//                 </Box>
//             </Box>
//         </Box>
//     </AccordionDetails>
// </Accordion>
// </Box>