 const
//  host="http://127.0.0.1:8000/api/"
// host="http://mandoobeen.iraq-gps.com/api/public/api/"
host='http://demo.mandoobeen.com/api/public/api/'
// host='https://admin.mandoobeen.com/api/public/api/'

 const FrontEndDomain= "http://demo.mandoobeen.com/"
// const FrontEndDomain= "https://admin.mandoobeen.com/"
//  const FrontEndDomain="http://127.0.0.1:8000"
export const ImagePath='http://demo.mandoobeen.com/api/public/'
// export const ImagePath='https://admin.mandoobeen.com/api/public/'

// export const ImagePath='http://127.0.0.1:8000/'



 export default host;

 export {FrontEndDomain};
