// import * as React from 'react';
import React, { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import Cookies from "universal-cookie";
import axios from "axios";
import Host from "../../../assets/js/Host";
import {useTranslation} from "react-i18next";

const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export default function AutoComplete(props) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchText,setsearchText] = useState('');
  const [t, i18n] = useTranslation('common');
  const token = cookies.get("token")
  
  const [value, setValue] = useState(options.length>0? options[0]:null);
  const [inputValue, setInputValue] = useState('');

  const loading = open && options.length === 0;


  const getResources = async () => {
    try {
      
        setOptions([])
        const resourcesResult = await axios({
            url: Host + "points_autocomplete",
            method: "get",
            headers: {
                "Authorization": `Bearer ${token}`,
                Accept: "application/json",
                'X-localization': language,
            },
            params:{
                search_text:searchText
            }
        })
        if (resourcesResult) {
            if (resourcesResult.status === 200) {
                const resources = resourcesResult.data;

                // setOptions([])
                setOptions(resources)
                
            } else if (resourcesResult.data.status === false) {

            }
        }
        else {
            toast.error("Error")
        }
        
        } catch (error) {
            
            toast.error("Network Error")
        }
    };

  useEffect(() => {
    let active = true;

    if(loading)
     getResources() // For demo purposes.

   

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    if (searchText.length>0) {
        getResources()
    }
  }, [searchText]);

  console.log(props.selectedPoint);
  return (
    <Autocomplete
        value={props.selectedPoint}
        onChange={(event, newValue) => {
        //   setValue(newValue);
          props.setselectedPoint(newValue)
        }}
        defaultValue={props.selectedPoint}
        inputValue={searchText}
        onInputChange={async (event, newInputValue) => {
            setsearchText(newInputValue);
        }}
        id="asynchronous-demo"
        sx={{ width: props?.isFullWidth?'100%':300 ,...(props?.styleAutoComplete)}}
        open={open}
        onOpen={() => {
        setOpen(true);
        }}
        onClose={() => {
        setOpen(false);
        }}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        options={options}
        loading={loading}
        renderInput={(params) => (
        <TextField
            variant="standard"
            onChange={(e)=>{console.log(e);}}
            {...params}
            label={t('SURVEY.FORMADD_SELECT_POINT')}
            InputProps={{
            ...params.InputProps,
            endAdornment: (
                <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
                </React.Fragment>
            ),
            }}
        />
        )}
    />
  );
}


