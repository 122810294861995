import React, { useState, useEffect } from 'react'

import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import LanguageIcon from '@mui/icons-material/Language';
import { useSelector, useDispatch } from 'react-redux'
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import './Notifications.css'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';

import Iraq from '../../../assets/img/iraq.png'
import UnitedState from '../../../assets/img/united-states.png'

import helpers from '../../../assets/js/helper';
// import { Avatar } from '@mui/material';

import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from "react-i18next";

import { setSideMenuMode, sideMenuMode, setDirections ,setCompanyImage} from '../../reduxStore/SettingsReducer'
import Cookies from "universal-cookie";
import axios from "axios";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';

import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
// import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';


const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"


const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme, ToolBarTheme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    backgroundColor: ToolBarTheme?.paperColor,
    color: ToolBarTheme?.disabledGlobalText ? ToolBarTheme?.gloablTextColor : ToolBarTheme?.paperTextColor,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
      '& .activelanguage': {
        backgroundColor: `${ToolBarTheme?.paperTextColor + '55'} !important`,
      },

    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },


    },
    '& .itmlist:hover': {
      backgroundColor: ToolBarTheme?.paperTextColor + '55',
    }

  },
}));

function generate(element) {
  return [0, 1, 2].map((value) =>
    React.cloneElement(element, {
      key: value,
    }),
  );
}

const Demo = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

export default function Company({ ToolBarTheme }) {
  const [t, i18n] = useTranslation('common');

  const directions__ = useSelector(state => state.settingsData.directions)
  const ToolbarTheme = useSelector(state => state.settingsData.ToolbarTheme)
  const SideMenuOpen = useSelector(state => state.settingsData.SideMenuOpen)
  const newCompanyAdded=useSelector(state => state.settingsData.newCompanyAdded)

  const [screenWidth,setScreenWidth]=useState(window.innerWidth)
  const dispatch = useDispatch();
  const [directions, setDirectionNow] = useState(directions__)
  const token = cookies.get("token")
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [searchValue,setSearchValue]=useState('');
  const [loading, setLoading] = useState(false)
  const [companies, setCompanies] = useState([])
  const [companies_2, setCompanies_2] = useState([])
  const [selectedCompany, setSelectedCompany] = useState({
    id: "",
    name: t('APPBAR.TOOLTIP_SELECT_A_COMPANY'),
    img:''
  })


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = async (company) => {
    console.log('company request',company)
    // try {
      setLoading(true)
      const resourcesResult = await axios({
        url: Host + "users/update_logged_in_company",
        method: "patch",
        headers: {
          "Authorization": `Bearer ${token}`,
          Accept: "application/json",
          'X-localization': language,
        },
        data: {
          updated_logged_in_company_id: company?.id
        }
      })
      if (resourcesResult) {
         console.log('1-1')
          
        if (resourcesResult.status === 200) {
          const resources = resourcesResult.data;
          
          let selectedCompanytemp = {
            id: "",
            name: t('APPBAR.TOOLTIP_SELECT_A_COMPANY'),
            img:''
          }
          try {
            // cookies.set('companyImage',resources?.image?.image_url)
            if(company?.image)
             cookies.set('companyImage',company?.image?.image_url)
            else
            console.log('imageUrlCompany','')
            
            // console.log('imageUrlCompany',company?.image_url)

            const filteredCompanies = companies?.filter((item) => item?.id === resources?.logged_in_company_id)
            if (filteredCompanies?.length > 0) {
              selectedCompanytemp = filteredCompanies[0]
            }
          } catch (error) {
            selectedCompanytemp = {
              id: "",
              name: t('APPBAR.TOOLTIP_SELECT_A_COMPANY')
            }

          }
          setSelectedCompany({
            id: selectedCompanytemp?.id,
            name: selectedCompanytemp?.name,
            // img:selectedCompanytemp?.image?.img,
          })
          cookies.set("logged_in_company_id",selectedCompanytemp?.id)

          window?.location?.reload()


        } else if (resourcesResult.data.status === false) {
          setSelectedCompany({
            id: "",
            name: t('APPBAR.TOOLTIP_SELECT_A_COMPANY')
          })
        }
      }
      else {
        console.log('1-2')

        setSelectedCompany({
          id: "",
          name: t('APPBAR.TOOLTIP_SELECT_A_COMPANY')
        })
        toast.error("Error")
      }


      setLoading(false)
    // } catch (error) {
    //   console.log("error",error);
    //   setLoading(false)
    //   setSelectedCompany({
    //     id: "",
    //     name: t('APPBAR.TOOLTIP_SELECT_A_COMPANY')
    //   })
    //   toast.error("Network Error")
    // }
    setAnchorEl(null);
  };

  const handleCloseTwo = () => {
    setAnchorEl(null);
  }

  useEffect(() => {
    setDirectionNow(directions__)
  }, [directions__])

  useEffect(() => {
    getResources()
    window.addEventListener('resize', () => {
      setScreenWidth(window.innerWidth);
    });
    return () => {

    }
  }, [newCompanyAdded])

  const getResources = async () => {
    try {
      setLoading(true)
      var resourcesResult = await axios({
        url: Host + "allow_access_companies_list",
        method: "get",
        headers: {
          "Authorization": `Bearer ${token}`,
          Accept: "application/json",
          'X-localization': language,
        }
      })
      if (resourcesResult) {
        if (resourcesResult.status === 200) {
          const resources = resourcesResult.data;
          let selectedCompanytemp = {
            id: "",
            name: t('APPBAR.TOOLTIP_SELECT_A_COMPANY')
          }
          try {
            
            const filteredCompanies = resources?.filter((item) =>{
              if(item?.id == cookies.get("logged_in_company_id"))
              {
                return true
              }
              return false
            })
            if (filteredCompanies?.length > 0) {
              
              selectedCompanytemp = filteredCompanies[0]
            }
          } catch (error) {
            selectedCompanytemp = {
              id: "",
              name: t('APPBAR.TOOLTIP_SELECT_A_COMPANY')
            }
          }
          console.log('image=>',selectedCompanytemp?.image_url)
          setSelectedCompany({
            id: selectedCompanytemp?.id,
            name: selectedCompanytemp?.name,
            img: selectedCompanytemp?.image?.image_url,
          })
          setCompanies(resources);
          setCompanies_2(resources);


        } else if (resourcesResult.data.status === false) {
          setCompanies([]);
        }
      }
      else {
        setCompanies([]);
        toast.error("Error")
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setCompanies([]);
      toast.error("Network Error")
    }
  };

  const [selectedCompanyImage,setSelectedCompanyImage]=useState(helpers.getImageUrl(selectedCompany?.img))
  useEffect(()=>{
    let imageSrc="";
    if(selectedCompany?.img)
       imageSrc=helpers.getImageUrl(selectedCompany?.img)
    else
     imageSrc='https://i.pinimg.com/originals/51/10/19/51101970f30856c14fe25bbfbaae2817.png'

    setSelectedCompanyImage(imageSrc)
  },[selectedCompany])
 

  const getImage=(company)=>{
    let imageSrc="";
    if(company?.image?.image_url)
       imageSrc=helpers.getImageUrl(company?.image?.image_url)
    else
     imageSrc='https://i.pinimg.com/originals/51/10/19/51101970f30856c14fe25bbfbaae2817.png'
    
     return imageSrc;

  }

  const ShowAccordingToScreenWidth=()=>{
       let show=true;
        if(SideMenuOpen){
           if(screenWidth<=800){
            show=false;
           }
        }else{
          if(screenWidth<=600){
            show=false;
           }
        }
       return show
  }
  useEffect(()=>{
    if(searchValue)
    setCompanies_2(companies.filter(itm=>itm?.name?.includes(searchValue)))
    else
    setCompanies_2(companies)
  },[searchValue])

  return (
    <Box sx={{marginLeft:'5px'}}>

      <Tooltip id="demo-customized-button" onClick={() => handleClick()} arrow title={t('APPBAR.TOOLTIP_SELECT_A_COMPANY')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="bottom">
          <IconButton className='Notification'
            size="small"
            sx={{ borderRadius: 0.5,marginRight:"7px",marginTop:'-3px', padding:directions=='rtl'?'11px 4px ':'11px 9px', height: '20px',backgroundColor:ToolbarTheme?.paperTextColor+'33' }}
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            {
            // ShowAccordingToScreenWidth()?
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              marginRight:'5px'
            }}>
              <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center'
              }}>

                <p style={{
                  whiteSpace: 'nowrap',
                  fontSize: companies?.length>0?"17px":'14.4px',
                  textAlign: 'center',
                  marginTop: '15px',
                  color:ToolBarTheme?.disabledGlobalText?ToolBarTheme?.gloablTextColor:ToolBarTheme?.paperTextColor,
                  marginRight: directions == 'rtl' ? 5 : -5
                }}>
                  {selectedCompany?.name}
                </p>
              </Box>
            </Box>
            // :null
            }
            
            {/* <img src={selectedCompanyImage} style={{margin:"0 2px",width:'25px',height:'25px',borderRadius:'50%',objectFit:'contained'}}/> */}
           
            {/* <Avatar
                src={selectedCompanyImage}
                name={selectedCompany?.name}
                size={10}
                sx={{
                    margin:'0 2px',
                    backgroundColor:ToolbarTheme?.paperTextColor+'99',
                    color:ToolbarTheme?.paperTextColor,
                    width:'30px',
                    height:'30px',
                    "& img":{
                      backgroundRepeat: 'no-repeat !important',
                    },
                }}
            /> */}


          </IconButton>
      </Tooltip>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        ToolBarTheme={ToolBarTheme}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseTwo}
        sx={{
          marginTop:'4.5px !important',
          '& .muiltr-jlzedu-MuiModal-root-MuiPopover-root-MuiMenu-root .MuiPaper-root,& .muirtl-5zuvbx-MuiModal-root-MuiPopover-root-MuiMenu-root .MuiPaper-root':{
            width:'400px !important',
            height:'400px !important',
          },
      
      }}
      >
        <Box
         sx={{
          padding:'2px 4px',
          '& .MuiOutlinedInput-root':{
            borderColor:`1px solid ${ToolbarTheme?.paperTextColor} !important`,
            color:ToolbarTheme?.disabledGlobalText?ToolbarTheme.gloablTextColor:ToolbarTheme?.paperTextColor,
        },
        '& .MuiInputLabel-root,& p,& span,& input':{
            // backgroundColor:`${ToolbarTheme?.paperTextColor+'22'} !important`,
            color:ToolbarTheme?.disabledGlobalText?ToolbarTheme.gloablTextColor:ToolbarTheme?.paperTextColor,
        },
        '& .MuiFormControl-root':{
          // border:`1px solid ${ToolbarTheme?.paperTextColor} !important`,
          color:ToolbarTheme?.disabledGlobalText?ToolbarTheme.gloablTextColor:ToolbarTheme?.paperTextColor,
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: ToolbarTheme?.disabledGlobalText?ToolbarTheme.gloablTextColor:ToolbarTheme?.paperTextColor,
          },
          '&:hover fieldset': {
            borderColor: ToolbarTheme?.disabledGlobalText?ToolbarTheme.gloablTextColor:ToolbarTheme?.paperTextColor,
          },
          '&.Mui-focused fieldset': {
            borderColor: ToolbarTheme?.disabledGlobalText?ToolbarTheme.gloablTextColor:ToolbarTheme?.secondaryColor,
          },
        },
        '& .MuiOutlinedInput':{
          // borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            // border: "0 none",
         },
         '& .muiltr-1yf1g2m::before,& .muirtl-1yf1g2m::before,& .muirtl-1a1fmpi-MuiInputBase-root-MuiInput-root:before,& .muiltr-1a1fmpi-MuiInputBase-root-MuiInput-root:before':{
            borderColor: ToolbarTheme?.disabledGlobalText?ToolbarTheme.gloablTextColor:ToolbarTheme?.secondaryColor,
         }
          }}
        >
           <TextField fullWidth id="outlined-basic" label={t('APPBAR.SEARCH_PUPOP_TITLE')} variant="standard" onChange={(e)=>setSearchValue(e.target.value)} />
        </Box>
        <List sx={{
          width:'300px !important',
          height:'400px !important',
          overflowY:'scroll'
          }}>
        {companies_2?.map((company, index) => {
          return (
              <ListItem
                 key={"company_" + company?.id + "_" + index}  
                 onClick={() => handleClose(company)}
                 sx={{cursor:'pointer'}}
                 className={`itmlist ${company?.id === selectedCompany?.id ? 'activelanguage ' : ''}`}
              >
                {/* <ListItemAvatar>
                <Avatar
                      src={getImage(company)}
                      name={selectedCompany?.name}
                      size={25}
                      sx={{
                          backgroundColor:ToolbarTheme?.paperTextColor+'99',
                          color:ToolbarTheme?.paperTextColor,
                      }}
                  />
                </ListItemAvatar> */}
                
                <ListItemText
                  primary={company?.name}
                  secondary={''}
                />
             
              </ListItem>
          )
        })}
        </List>
      </StyledMenu>
    </Box>
  )
}
