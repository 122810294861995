import React,{useState,useEffect,useMemo} from 'react'
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector, useDispatch } from 'react-redux'
import {
  setSideMenuMode,
  sideMenuMode,
  setDirections,
  setMainPaletteShow,
  setSideMenuPaletteShow,
  setToolBarPaletteShow,
  setGridPaletteShow,
  setSearchButtonTheme,
  setForceReloadTheme,
  setFilterSearchBoxMode
} from '../../reduxStore/SettingsReducer'
import helpers from '../../../assets/js/helper'

import './Settings.css'
import CloseIcon from '@mui/icons-material/Close';
import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import Switch from '@mui/material/Switch';
import {
  SIdeMenuLaout,
  ThemeLayout,
  FiltersLayout,
  DashboardLayout,
  SettingHeader,
  SettingsCloseIcons,
  PubleButton,
  GridLayout
} from './StyledComponent'
import {useTranslation} from "react-i18next";

import SideMenuPalette from '../../../assets/img/SideMenu-Palette.PNG'
import ToolbarPalette from '../../../assets/img/Toolbar-Palette.PNG'
import MainPalette from '../../../assets/img/Main-Palette.PNG'
import GirdPalette from '../../../assets/img/grid-Palette.PNG'
import swal from 'sweetalert';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Host from "../../../assets/js/Host";
import { toast } from "react-toastify";
import axios from "axios";
import Cookies from "universal-cookie";
// import {useTranslation} from "react-i18next";
import debounce from 'lodash.debounce'

import ThemePalette from '../Share/ThemePalette'
const cookies = new Cookies();
const language = cookies.get("language") ? cookies.get("language") : "ar";

const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: pink[600],
      '&:hover': {
        backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: pink[600],
    },
}));
  
const label = { inputProps: { 'aria-label': 'Switch demo' } };

  
export default function SideSettings({setOpenSideSettings,openSideSettings}) {
    const {t, i18n} = useTranslation('common');
    const token=cookies.get('token')
    const timeout = React.useRef(null);

    const dispatch = useDispatch()
    const sideMenuMode = useSelector(state=> state.settingsData.sideMenuMode)
    const directions = useSelector(state=> state.settingsData.directions)
    const MainTheme=useSelector(state=> state.settingsData.MainTheme);
    const SearchButtonTheme=useSelector(state=> state.settingsData.SearchButtonTheme);
    const [ThemesListSections,setThemesListSections]=useState([])
    const [ThemesListSectionsKeys,setThemesListSectionsKeys]=useState([])

    const [searchButtonsSectionId,setSearchButtonsSectionsID]=useState(1)
    const [searchButtonsSectionData,setSearchButtonsSectionsData]=useState([])
    const forceReloadTheme=useSelector(state=> state.settingsData.forceReloadTheme)
    const filterSearchBoxMode = useSelector(state=> state.settingsData.filterSearchBoxMode)
    const [loading,setLoading]=useState(false)

    const roles = useMemo(() => {
      let roles = []
  
      try {
  
        roles = cookies.get("roles")
        // roles = roles?.map(role => role?.id)
      } catch (error) {
  
  
      }
      return roles
    })

    const [settingsSideDirections,setSettingsSideDirections]=useState('right')

    useEffect(()=>{
      console.log('direction=>',directions)
      if(directions=='rtl'){
        setSettingsSideDirections('left')
      }else{
        setSettingsSideDirections('right')
      }
    },[directions])
    
     //get all themes sections from backend....
    const getAllThemesForSearchButtons= async () => {

      try {
          setLoading(true)
          const resourcesResult = await axios({
              url: Host + "theme_section_keys_list?theme_section_id="+searchButtonsSectionId,
              method: "get",
              headers: {
                  "Authorization": `Bearer ${token}`,
                  Accept: "application/json",
                  'X-localization': language,
              },
          })
          if (resourcesResult) {
              if (resourcesResult.status === 200) {
                if(resourcesResult?.data)
                 {
                  setSearchButtonsSectionsData(resourcesResult?.data)
                  // localStorage.setItem('SearchButtonTheme',JSON.stringify(resourcesResult?.data))
                 }
              } else if (resourcesResult.data.status === false) {
              }
          }
          else {
              toast.error("Error")
          }
          setLoading(false)
        
      } catch (error) {
          console.log("error",error);
          setLoading(false)
          const resourcesResult = error.response
          if (resourcesResult && resourcesResult.status === 400) {
              if (typeof resourcesResult.data.data === "object") {
                  const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                  errorMessagesKeys.map((key) => {
  
                      resourcesResult.data.data[key].map((message) => {
                          toast.error(message)
                      })
                  })
              }
              else {
  
                  toast.error(resourcesResult.data.data)
              }
  
  
          }
          else if (resourcesResult && resourcesResult.status === 401) {
  
              toast.error(resourcesResult.data.data)
              cookies.remove("token");
              window.location.href = "/"
          }
          else {
  
              toast.error("network error")
          }
      }
  
    };
  
    useEffect(()=>{
      getAllThemesForSearchButtons()
      localStorage.setItem('section_id',2)

    },[])


    const checkRoleForSHowTheme=()=>{
      let check=roles&&roles?.length>0?roles?.includes(22):false
      if(check){
        return(
          <>
           {/* first layout of sideMenu ...  */}
      
           <ThemeLayout color={MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor} bgColor={MainTheme?.paperColor} titleSection={t('SETTINGS.THEME_LAYOUT')} leftProps={directions !='rtl' ?3:63}>
            <List
                sx={{ width: '100%', maxWidth: 360, bgcolor: 'transparent',paddingTop:2,paddingBottom:2,color:'black' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                className="ListOfThemes"
            >
                <ListItemButton className="listItemImgContainer" disableRipple onClick={()=>openMainPalette()}>
                      <img src={MainPalette} alt="" className="ListItemImage" />
                      <p>{t('SETTINGS.THEME_LAYOUT_MAIN_PALETTE')}</p>
                </ListItemButton>

                <ListItemButton className="listItemImgContainer" disableRipple onClick={()=>openToolbarPalette()}>
                      <img src={ToolbarPalette} alt="" className="ListItemImage" />
                      <p>{t('SETTINGS.THEME_LAYOUT_TOOLBAR_PALETTE')}</p>
                </ListItemButton>

                <ListItemButton className="listItemImgContainer" disableRipple onClick={()=>openSideMenuPalette()}>
                      <img src={SideMenuPalette} alt="" className="ListItemImage" />
                      <p>{t('SETTINGS.THEME_LAYOUT_SIDEMENU_PALETTE')}</p>
                </ListItemButton>

                <ListItemButton className="listItemImgContainer" disableRipple onClick={()=>openGridPalette()}>
                      <img src={GirdPalette} alt="" className="ListItemImage" />
                      <p>{t('SETTINGS.THEME_LAYOUT_GRID_PALETTE')}</p>
                </ListItemButton>


            </List>
           </ThemeLayout>

          {/* first layout of sideMenu ...  */}
          <FiltersLayout directions={directions} color={MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor} bgColor={MainTheme?.paperColor} titleSection={t('SETTINGS.SEARCH_FILTER_LAYOUT')} leftProps={directions !='rtl' ?3:50}>
          <List
              sx={{ 
                width: '100%',
                maxWidth: 360, 
                bgcolor: 'transparent',
                paddingTop:2,
                paddingBottom:2,
                color:'black',
                display:'flex',
                justifyContent: 'center !important',
                flexWrap: 'wrap',
                
              }}
              component="nav"
              aria-labelledby="nested-list-subheader"
              // className="ListOfThemes"
          >
             <ListItemButton className="listItemImgContainer" textColor={MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor} disableRipple >
                    <PubleButton color="#fff" bgColor={SearchButtonTheme?.save_button_color} onClick={(e)=>e.target.querySelector('input').click()}>
                       <input value={SearchButtonTheme?.save_button_color} onChange={(e)=>setThemeForSearchButtons('save_button_color','save_button_text_color',e.target.value)} type="color" style={{visibility:'hidden',zIndex:1000 ,width:'100%',height:'100%'}}  />
                    </PubleButton>
                    <p style={{color:MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor}}>{t('SETTINGS.SEARCH_FILTER_SAVE_BUTTON')}</p>
              </ListItemButton>

              <ListItemButton className="listItemImgContainer" textColor={MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor} disableRipple >
                    <PubleButton color="#fff" bgColor={SearchButtonTheme?.search_button_color} onClick={(e)=>e.target.querySelector('input').click()}>
                       <input  value={SearchButtonTheme?.search_button_color} onChange={(e)=>setThemeForSearchButtons('search_button_color','search_button_text_color',e.target.value)} type="color" style={{visibility:'hidden',zIndex:1000 ,width:'100%',height:'100%'}}  />
                    </PubleButton>
                    <p style={{color:MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor}}>{t('SETTINGS.SEARCH_FILTER_SEARCH_BUTTON')}</p>
              </ListItemButton>

              <ListItemButton className="listItemImgContainer" textColor={MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor} disableRipple >
                    <PubleButton color="#fff" bgColor={SearchButtonTheme?.clear_button_color} onClick={(e)=>e.target.querySelector('input').click()}>
                       <input  value={SearchButtonTheme?.clear_button_color} onChange={(e)=>setThemeForSearchButtons('clear_button_color','clear_button_text_color',e.target.value)} type="color" style={{visibility:'hidden',zIndex:1000 ,width:'100%',height:'100%'}}  />
                    </PubleButton>
                    <p style={{color:MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor}}>{t('SETTINGS.SEARCH_FILTER_CLEAR_BUTTON')}</p>

              </ListItemButton>

              <ListItemButton className="listItemImgContainer" textColor={MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor} disableRipple >
                    <PubleButton color="#fff" bgColor={SearchButtonTheme?.export_button_color} onClick={(e)=>e.target.querySelector('input').click()}>
                       <input  value={SearchButtonTheme?.export_button_color} onChange={(e)=>setThemeForSearchButtons('export_button_color','export_button_text_color',e.target.value)} type="color" style={{visibility:'hidden',zIndex:1000 ,width:'100%',height:'100%'}}  />
                    </PubleButton>
                    <p style={{color:MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor}}>{t('SETTINGS.SEARCH_FILTER_EXPORT_BUTTON')}</p>

              </ListItemButton>

              <ListItemButton className="listItemImgContainer" textColor={MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor} disableRipple >
                   <PubleButton color="#fff" bgColor={SearchButtonTheme?.import_button_color} onClick={(e)=>e.target.querySelector('input').click()}>
                       <input  value={SearchButtonTheme?.import_button_color} onChange={(e)=>setThemeForSearchButtons('import_button_color','import_button_text_color',e.target.value)} type="color" style={{visibility:'hidden',zIndex:1000 ,width:'100%',height:'100%'}}  />
                    </PubleButton>
                    <p style={{color:MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor}}>{t('SETTINGS.SEARCH_FILTER_IMPORT_BUTTON')}</p>

              </ListItemButton>

              <ListItemButton className="listItemImgContainer" textColor={MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor} disableRipple >
                    <PubleButton color="#fff" bgColor={SearchButtonTheme?.create_button_color} onClick={(e)=>e.target.querySelector('input').click()}>
                       <input  value={SearchButtonTheme?.create_button_color} onChange={(e)=>setThemeForSearchButtons('create_button_color','create_button_text_color',e.target.value)} type="color" style={{visibility:'hidden',zIndex:1000 ,width:'100%',height:'100%'}}  />
                    </PubleButton>
                    <p style={{color:MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor}}>{t('SETTINGS.SEARCH_FILTER_CREATE_BUTTON')}</p>

              </ListItemButton>

              <ListItemButton className="listItemImgContainer" textColor={MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor} disableRipple >
                    <PubleButton color="#fff" bgColor={SearchButtonTheme?.map_button_color} onClick={(e)=>e.target.querySelector('input').click()}>
                       <input  value={SearchButtonTheme?.map_button_color} onChange={(e)=>setThemeForSearchButtons('map_button_color','map_button_text_color',e.target.value)} type="color" style={{visibility:'hidden',zIndex:1000 ,width:'100%',height:'100%'}}  />
                    </PubleButton>
                    <p style={{color:MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor}}>{t('SETTINGS.SEARCH_FILTER_MAP_BUTTON')}</p>

              </ListItemButton>

              <ListItemButton className="listItemImgContainer" textColor={MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor} disableRipple >
                    <PubleButton color="#fff" bgColor={SearchButtonTheme?.telegram_button_color} onClick={(e)=>e.target.querySelector('input').click()}>
                       <input  value={SearchButtonTheme?.telegram_button_color} onChange={(e)=>setThemeForSearchButtons('telegram_button_color','telegram_button_text_color',e.target.value)} type="color" style={{visibility:'hidden',zIndex:1000 ,width:'100%',height:'100%'}}  />
                    </PubleButton>
                    <p style={{color:MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor}}>{t('SETTINGS.SEARCH_FILTER_TELEGRAM_BUTTON')}</p>
              </ListItemButton>

              <ListItemButton className="listItemImgContainer" textColor={MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor} disableRipple >
                    <PubleButton color="#fff" bgColor={SearchButtonTheme?.go_back_button_color} onClick={(e)=>e.target.querySelector('input').click()}>
                       <input  value={SearchButtonTheme?.go_back_button_color} onChange={(e)=>setThemeForSearchButtons('go_back_button_color','go_back_button_text_color',e.target.value)} type="color" style={{visibility:'hidden',zIndex:1000 ,width:'100%',height:'100%'}}  />
                    </PubleButton>
                    <p style={{color:MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor}}>{t('SETTINGS.ERROR_PAGE_BUTTON')}</p>
              </ListItemButton>

             

          


          </List>
          </FiltersLayout>

          <DashboardLayout directions={directions} color={MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor} bgColor={MainTheme?.paperColor} titleSection={t('SETTINGS.DASHBOARD_LAYOUT')} leftProps={directions !='rtl' ?3:50}>
          <List
              sx={{ 
                width: '100%',
                maxWidth: 360, 
                bgcolor: 'transparent',
                paddingTop:2,
                paddingBottom:2,
                color:'black',
                display:'flex',
                justifyContent: 'center !important',
                flexWrap: 'wrap',
                
              }}
              component="nav"
              aria-labelledby="nested-list-subheader"
              // className="ListOfThemes"
          >
              <ListItemButton className="listItemImgContainer" textColor={MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor} disableRipple >
                    <PubleButton color="#fff" bgColor={SearchButtonTheme?.road_time_color} onClick={(e)=>e.target.querySelector('input').click()}>
                       <input  value={SearchButtonTheme?.road_time_color} onChange={(e)=>setThemeForSearchButtons('road_time_color','road_time_text_color',e.target.value)} type="color" style={{visibility:'hidden',zIndex:1000 ,width:'100%',height:'100%'}}  />
                    </PubleButton>
                    <p style={{color:MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor}}>{t('SETTINGS.DASHBOARD_ROAD_TIME_COLOR')}</p>
              </ListItemButton>

              <ListItemButton className="listItemImgContainer" textColor={MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor} disableRipple >
                    <PubleButton color="#fff" bgColor={SearchButtonTheme?.visit_time_color} onClick={(e)=>e.target.querySelector('input').click()}>
                       <input  value={SearchButtonTheme?.visit_time_color} onChange={(e)=>setThemeForSearchButtons('visit_time_color','visit_time_text_color',e.target.value)} type="color" style={{visibility:'hidden',zIndex:1000 ,width:'100%',height:'100%'}}  />
                    </PubleButton>
                    <p style={{color:MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor}}>{t('SETTINGS.DASHBOARD_VISIT_TIME_COLOR')}</p>
              </ListItemButton>
          </List>
          </DashboardLayout>
          
          </>
        )
      }
      return null
    }

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    
    const openMainPalette=()=>{
       dispatch(setMainPaletteShow(true))
       localStorage.setItem('palette_title',t('SETTINGS.THEME_LAYOUT_MAIN_PALETTE'))
       localStorage.setItem('section_id',2)
        
    }

    const openSideMenuPalette=()=>{
      dispatch(setSideMenuPaletteShow(true))
      localStorage.setItem('palette_title',t('SETTINGS.THEME_LAYOUT_SIDEMENU_PALETTE'))
      localStorage.setItem('section_id',4)

    }

    const openToolbarPalette=()=>{
      dispatch(setToolBarPaletteShow(true))
      localStorage.setItem('palette_title',t('SETTINGS.THEME_LAYOUT_TOOLBAR_PALETTE'))
      localStorage.setItem('section_id',3)

    }

    const openGridPalette=()=>{
      dispatch(setGridPaletteShow(true))
      localStorage.setItem('palette_title',t('SETTINGS.THEME_LAYOUT_GRID_PALETTE'))
      localStorage.setItem('section_id',5)
    }

    const getInfoOfRequest=(name,name2,value,value2,data)=>{
      let  arr=[]
      if(data?.length>0){
        data?.map((itm)=>{
            let obj={}
            if(itm?.name==name){
            console.log('itm1=====>',itm)

              arr.push({
                id:itm?.theme_section_values?itm?.theme_section_values[0]?.id:'',
                theme_section_key_id:itm.id,
                theme_id:itm?.theme_section_values?itm?.theme_section_values[0]?.theme_id:'',
                value:value,
              })
            }
            else if(itm?.name==name2){
              console.log('itm2=====>',itm)
                obj={
                  ...obj,
                  id:itm?.theme_section_values?itm?.theme_section_values[0]?.id:'',
                  theme_section_key_id:itm.id,
                  theme_id:itm?.theme_section_values?itm?.theme_section_values[0]?.theme_id:'',
                  value:value2,
                } 
                arr.push(obj)
            }

        })
      }
      return arr;
    }
     
    const setThemeForSearchButtons=(name,name2,value)=>{
      if (timeout.current) {
        clearTimeout(timeout?.current)
      }
     
      timeout.current=setTimeout(()=>{
        let check=helpers?.checkDarknessOrLightnessForColor(value);
        console.log(name,name2,value,searchButtonsSectionData)
        // let dataGet=JSON.parse(localStorage.getItem('SearchButtonTheme'))

        var getInf= getInfoOfRequest(name,name2,value,check?'#ffffff':'#000000',searchButtonsSectionData)
        dispatch(setSearchButtonTheme({
          ...SearchButtonTheme,
          [name]:value,
          [name2]:check?'#ffffff':'#000000'
        }))
          
          console.log(getInf)

        if(getInf?.length>0)
          StoreDataOfSearchSectionTheme(getInf)

      },500)
    }

    const StoreDataOfSearchSectionTheme= async (data) => {

      try {
          setLoading(true)
          const resourcesResult = await axios({
              url: Host + "theme_section_values/bulk",
              method: "put",
              headers: {
                  "Authorization": `Bearer ${token}`,
                  Accept: "application/json",
                  'X-localization': language,
              },
              data:{
                theme_values:data
              },
          })
          if (resourcesResult) {
              if (resourcesResult.status === 200) {
                if(resourcesResult?.data)
                 {
                  dispatch(setForceReloadTheme(!forceReloadTheme))
                  getAllThemesForSearchButtons()

                 }
              } else if (resourcesResult.data.status === false) {
              }
          }
          else {
              toast.error("Error")
          }
          setLoading(false)
        
      } catch (error) {
          console.log("error",error);
          setLoading(false)
          const resourcesResult = error.response
          if (resourcesResult && resourcesResult.status === 400) {
              if (typeof resourcesResult.data.data === "object") {
                  const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                  errorMessagesKeys.map((key) => {
  
                      resourcesResult.data.data[key].map((message) => {
                          toast.error(message)
                      })
                  })
              }
              else {
  
                  toast.error(resourcesResult.data.data)
              }
  
  
          }
          else if (resourcesResult && resourcesResult.status === 401) {
  
              toast.error(resourcesResult.data.data)
              cookies.remove("token");
              window.location.href = "/"
          }
          else {
  
              toast.error("network error")
          }
      }
  
    };
  

    const list = (anchor) => (
      <Box
        sx={{ 
          backgroundColor:MainTheme?.paperColor,color:MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor, 
          width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 380 ,
          height:'100%'
        }}
        className='SideSettings'
        role="presentation"
      //   onClick={()=>setOpenSideSettings(false)}
      //   onKeyDown={()=>setOpenSideSettings(false)}
      >
        {/* header of sideMenu settings  */}
        <SettingHeader>{t('SETTINGS.TITLE')}</SettingHeader>

        {/* first layout of sideMenu ...  */}
        <SIdeMenuLaout color={MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor} secondaryColor={MainTheme?.secondaryColor} bgColor={MainTheme?.paperColor} titleSection={t('SETTINGS.SIDEMENU_LAYOUT')} leftProps={directions !='rtl' ?3:50}>
          <List
              sx={{ width: '100%', maxWidth: 360, bgcolor: 'transparent',paddingTop:2,paddingBottom:2,color:MainTheme?.paperTextColor }}
              component="nav"
              aria-labelledby="nested-list-subheader"
              
          >

              <ListItemButton className="listItem">
                  <ListItemText primary={t('SETTINGS.SIDEMENU_ICONMODE')} className="ColorText" />
                  
                  <FormControlLabel
                      control={
                          <Switch {...label}  color="warning" checked={sideMenuMode} className="switchColor" onChange={(e)=>{
                              dispatch(setSideMenuMode(e.target.checked))
                              // console.log(e.target.checked)
                          }} />
                      }
                      label="Icon"
                      sx={{position:'relative',top:4}}
                      className='ColorText'
                  />
              </ListItemButton>
{/* 
              <ListItemButton className="listItem">
                  <ListItemText primary={t('SETTINGS.SIDEMENU_DIRECTION')} className="ColorText textSideMenuSetting" />
                  
                  <FormControlLabel
                      control={
                          <Switch {...label}  color="warning" checked={directions=='rtl'?true:false} className="switchColor" onChange={(e)=>{
                              dispatch(setDirections(e.target.checked?'rtl':'ltr'))

                              // if(e.target.checked)
                              //   i18n.changeLanguage('ar')
                              // else
                              //   i18n.changeLanguage('en')

                              console.log(e.target.checked)
                              // if(e.target.checked)
                              //   document.dir = 'rtl';
                              // else 
                              //    document.dir = 'ltr';
                              
                            }} />
                      }
                      label="RTL"
                      sx={{position:'relative',top:4}}
                      className='ColorText'
                  />
              </ListItemButton> */}

          </List>
        </SIdeMenuLaout>

        {/* first layout of sideMenu ...  */}
        <GridLayout color={MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor} secondaryColor={MainTheme?.secondaryColor} bgColor={MainTheme?.paperColor} titleSection={t('SETTINGS.GRID_LAYOUT')} leftProps={directions !='rtl' ?3:63}>
          <List
              sx={{ width: '100%', maxWidth: 360, bgcolor: 'transparent',paddingTop:2,paddingBottom:2,color:MainTheme?.paperTextColor }}
              component="nav"
              aria-labelledby="nested-list-subheader"
              
          >
              <ListItemButton className="listItem">
                  <ListItemText primary={t('SETTINGS.GRID_MODE')} className="ColorText" />
                  
                  <FormControlLabel
                      control={
                          <Switch {...label}  color="warning" checked={filterSearchBoxMode} className="switchColor" onChange={(e)=>{
                              dispatch(setFilterSearchBoxMode(e.target.checked))
                              localStorage.setItem('gridMode',e.target.checked)
                              // console.log(e.target.checked)
                          }} />
                      }
                      label={t('SETTINGS.GRID_CEHCK_LABEL')}
                      sx={{position:'relative',top:4}}
                      className='ColorText'
                  />
              </ListItemButton>

          </List>
        </GridLayout>

          {checkRoleForSHowTheme()}

        <br/>
        <br/>
        <br/>
        <br/>

      </Box>
    );



   const getAllThemesSectionsKeys= async () => {

    try {
        setLoading(true)
        const resourcesResult = await axios({
            url: Host + "theme_section_keys_list",
            method: "get",
            headers: {
                "Authorization": `Bearer ${token}`,
                Accept: "application/json",
                'X-localization': language,
            },
        })
        if (resourcesResult) {
            if (resourcesResult.status === 200) {
              if(resourcesResult?.data)
               {
                setThemesListSections(resourcesResult?.data)
               }
            } else if (resourcesResult.data.status === false) {
            }
        }
        else {
            toast.error("Error")
        }
        setLoading(false)
      
    } catch (error) {
        console.log("error",error);
        setLoading(false)
        const resourcesResult = error.response
        if (resourcesResult && resourcesResult.status === 400) {
            if (typeof resourcesResult.data.data === "object") {
                const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                errorMessagesKeys.map((key) => {

                    resourcesResult.data.data[key].map((message) => {
                        toast.error(message)
                    })
                })
            }
            else {

                toast.error(resourcesResult.data.data)
            }


        }
        else if (resourcesResult && resourcesResult.status === 401) {

            toast.error(resourcesResult.data.data)
            cookies.remove("token");
            window.location.href = "/"
        }
        else {

            toast.error("network error")
        }
    }
   };
   


  return (
    <div className='SideSettings2' style={{backgroundColor:MainTheme?.paperColor,color:MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor}}>
        <SwipeableDrawer
          anchor={'right'}
          open={openSideSettings}
          onClose={()=>setOpenSideSettings(false)}
          // SlideProps={{
          //   direction: directions=='rtl'?'left':'left'
          // }}
          sx={{
            '& .muirtl-1160xiw-MuiPaper-root-MuiDrawer-paper,& .muiltr-1160xiw-MuiPaper-root-MuiDrawer-paper,& .muiltr-1ab2xsx,& .muirtl-1ab2xsx':{
              backgroundColor:MainTheme?.paperColor,
              color:MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor
            }
          }}
   
        >
          <SettingsCloseIcons color={MainTheme?.paperTextColor} directions={directions} onClick={()=>setOpenSideSettings(false)}>
              <CloseIcon  className="icon"/>
          </SettingsCloseIcons>
          {list(settingsSideDirections)}
        </SwipeableDrawer>
        <ThemePalette/>
    </div>
  )
}
