
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import RegionForm from './RegionForm';
import RegionImportForm from './RegionImportForm';
import IconButton from "@mui/material/IconButton";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import Tooltip from '@mui/material/Tooltip';
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PublishRoundedIcon from '@mui/icons-material/PublishRounded';
import {useSelector,useDispatch} from 'react-redux'
import DropDownGrid from '../Share/DropDownGrid';
import {MenuItem,ListItemIcon} from '@mui/material'

import "../../../assets/css/DG.css"
import "../../../assets/css/AccordionStyle.css"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import {useTranslation} from "react-i18next";
import FilterBox from '../Share/FilterBox';
import Autocomplete from '@mui/material/Autocomplete';
import red from '@mui/material/colors/red';
import Pagination from '../Share/Pagination'
import { useGlobalContext } from '../../../context'
import PointsMapDialog from '../Points/PointsMapDialog';
import Grid from '../Share/Grid'
import BasicDatePickerV2 from '../GeneralComponents/BasicDatePickerV2';
import SearchFieldV2 from '../GeneralComponents/SearchFieldV2'
import CustomeFilter from '../Share/CustomeFilter'


function Regions() {
    const directions = useSelector(state => state.settingsData.directions)
    const GridTheme =useSelector(state=>state.settingsData.GridTheme)
    const FilterButtonHeight= useSelector(state=> state.settingsData.FilterButtonHeight);
    const [t, i18n] = useTranslation('common');
    const filterSearchBoxMode = useSelector(state=> state.settingsData.filterSearchBoxMode)
    
    const [pageSize, setPageSize] = useState(5);
    const [pageNumber, setpageNumber] = useState(1);
    const [pointsTotal, setPointsTotal] = useState(0)
    const [pointsNumberOfPages, setPointsNumberOfPages] = useState(0)
    const { loading, data, pointsStatuses, pointStatus, setPointStatus, exportToCSV, allPoints, points, district, region, districts, regions, ownerName, shopName, clearPointsAdvanceSearchFields, setRegion, setDistrict, setShopName, setOwnerName, fetchData, deleteObject } = useGlobalContext()
    const [rows, setRow] = useState([])
    const SearchButtonTheme=useSelector(state=> state.settingsData.SearchButtonTheme);

    const [showRegionForm, setshowRegionForm] = useState(false);
    const [showRegionImportForm, setshowRegionImportForm] = useState(false);
    const [showRegionMapDialog, setshowRegionMapDialog] = useState(false)
    const [selectedRegion, setselectedRegion] = useState(null);
    const [accExpansion, setAccExpansion] = useState(false)
    const screenWidthRedux=useSelector(state=>state.settingsData.screenWidth)
    const [updateGrid,setUpdateGrid]=useState(false)
    const [showAllRegions,setShowAllRegions]=useState(false)
    const [polygon, setPolygon] = useState({
        paths: [],
        strokeColor: "#FF0000",
        fillColor: "#FF0000"
    })
    const handlePageChange = (newPage) => {
        setpageNumber(newPage + 1)
    }

    const handleExpansion = (expansion) => setAccExpansion(expansion)

    const advanceSearch = async (type = "BROWSING") => {
        const fetchedData = await fetchData('DATA', 'regions', {
            page: type === "SEARCH" ? 1 : pageNumber,
            page_size: pageSize,
            district: district.id,
            region: region.id,
        });

        if(type=='add')
        {
            setpageNumber(fetchedData?.last_page)
        }
     
        return fetchedData
    }
    const updateObject = async (object) => {
        setselectedRegion(object)
        setshowRegionForm(true)
    };

    const createObject = async (object) => {
        setselectedRegion(null)
        setshowRegionForm(true)
    };

    const importObjects = async () => {
        setselectedRegion(null)
        setshowRegionImportForm(true)
    }
    const handleSetshowRegionForm = (openStatus, needsReload) => {
        if (needsReload)
            //   fetchData();
            advanceSearch()
        setshowRegionForm(openStatus)
    };
    const handleSetshowRegionImportForm = (openStatus, needsReload) => {
        if (needsReload)
            // fetchData();
            advanceSearch()
        setshowRegionImportForm(openStatus)
    };

    
    const mapObject = async (object) => {
         console.log('sdsfsdobjsadasd=>',object)
        setPolygon({
            ...polygon,
            name:object?.name,
            paths: object?.region_geofences?.map(geofence => {
                return{
                        lat: geofence.latitude,
                        lng: geofence.longitude
                    }
            })
        });
        setselectedRegion(object)
        setshowRegionMapDialog(true)
    }
    const handleSetshowRegionMapDialog = (openStatus, needsReload) => {
        if (needsReload)
            // fetchData();
            advanceSearch()
        setshowRegionMapDialog(openStatus)
    }

    useEffect(() => {
        fetchData('REGIONS', 'regions_list')
        fetchData('DISTRICTS', 'districts_list')
        advanceSearch()
    }, [pageSize, pageNumber])

    useEffect(() => {

        if (data && data.data && data.data.length > 0) {
            setRow(data.data);
            setPointsTotal(data.total)
            setPointsNumberOfPages(data.last_page)
         
        }else{
            setRow([]);
            setPointsTotal(0)
            setPointsNumberOfPages(0)
        }
    }, [data])

    const columns = [
        { field: 'id', headerName: t('REGION.ID'), flex: .1,
        renderCell:(rows)=>(
            <div style={{ width: '100%' }} className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>
                {rows?.row?.id}
            </div>
        )
    },
        {
            field: 'district', headerName: t('REGION.DISTRICT'), flex: 1.5,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>
                    {rows?.row?.district?.name}
                </div>
            )
        },

        { field: 'name', headerName: t('REGION.REGION'), flex: 3 ,renderCell:(rows)=>(
            <div style={{ width: '100%' }} className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>
                 {rows?.row?.name}
             </div>
        )},
        {
            field: 'actions', flex: 0.3,width:'50px',
            headerName: t('REGION.ACTIONS'),
            renderCell: (rows) => (
                <DropDownGrid>
             

                <MenuItem onClick={() => { mapObject(rows.row)}}>
                    <ListItemIcon sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin:'0px 7px 0 0'
                    }}>
                        <LocationOnRoundedIcon style={{ fill: "#5cb85c" }} />

                    </ListItemIcon>
                    {directions != 'rtl' ? "Map" : 'خريطة'}
                </MenuItem>
                <MenuItem onClick={() => {updateObject(rows.row)}}>
                    <ListItemIcon sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin:'0px 7px 0 0'
                    }}>
                        <ModeEditOutlineRoundedIcon style={{ fill: "#111827" }}  />

                    </ListItemIcon>
                    {directions != 'rtl' ? "ُEdit" : 'تعديل'}
                </MenuItem>
                <MenuItem onClick={async () => {
                    if ((await deleteObject('DATA', 'regions', rows.row)) === 200) {
                        advanceSearch("BROWSING")
                    }
                }}>
                    <ListItemIcon sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin:'0px 7px 0 0'
                    }}>
                        <DeleteRoundedIcon sx={{ color: red[500] }} />
                    </ListItemIcon>
                    {directions != 'rtl' ? "Delete" : 'حذف'}
                </MenuItem>
                </DropDownGrid>
            ),
        },
    ]

    const ExportFunction=async () => {
        const fetchedData = await fetchData('DATA', 'regions', {
            page_size: pointsTotal,
            district: district.id,
            region: region.id,
        });

        exportToCSV(fetchedData.data, 'regions')
    }
    const createFunction=()=>{
        createObject()
    }
    const mapFunction=async () => {
        // mapObject()
        setshowRegionMapDialog(true)
        setShowAllRegions(true)

     }
    const importFunction=()=>{
        importObjects()
    }

    return (
        <>
            {showRegionForm ?
                <RegionForm region={selectedRegion} showRegionForm={showRegionForm} setshowRegionForm={handleSetshowRegionForm} advanceSearch={advanceSearch} pointsNumberOfPages={pointsNumberOfPages} setpageNumber={setpageNumber}/>
                : null}
            {showRegionImportForm ?

                <RegionImportForm showRegionImportForm={showRegionImportForm} setshowRegionImportForm={handleSetshowRegionImportForm} />
                : null}
            {showRegionMapDialog ?

                <PointsMapDialog title={""+showAllRegions?t('REGION.ALL_REGIONS'):selectedRegion?.name} showAllRegionsOnMaps={showAllRegions} loading={loading} inVisitPage={true} polygon={polygon} showPointsMapDialog={showRegionMapDialog} setshowPointsMapDialog={handleSetshowRegionMapDialog} />
                : null}
            {
            !filterSearchBoxMode?
            <FilterBox 
                exportFunction={ExportFunction}
                createFunction={createFunction}
                mapFunction={mapFunction}
                importFunction={importFunction}
                hasExport={true} 
                hasCreate={true} 
                hasMaps={true} 
                hasImport={true}
                resultLength={pointsTotal}
                customeHeight="200px"
            >

    
                <Autocomplete
                    sx={{ ml: 1,width:screenWidthRedux<=1100?'98%':'48%'}}
                    fullWidth
                    options={districts}
                    getOptionLabel={(option) => option.name || ""}
                    id="disable-close-on-select"
                    value={district}
                    onChange={(e, v, r) => { setDistrict(v ? v : '') }}
                    renderInput={(params) => (
                        <TextField {...params} 
                        label={t('GLOBAL.SEARCH_DISTRICTS')}

                           variant="standard" sx={{borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}} />
                    )}
                />
                <Autocomplete
                    sx={{ ml: 2,width:screenWidthRedux<=1100?'98%':'48%' }}
                    fullWidth
                    options={regions}
                    getOptionLabel={(option) => option.name || ""}
                    id="disable-close-on-select"
                    value={region}
                    onChange={(e, v, r) => { setRegion(v ? v : '') }}

                    renderInput={(params) => (
                        <TextField {...params}
                            label={t('GLOBAL.SEARCH_REGION')}
                       variant="standard" sx={{borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}} />
                    )}
                />
                <Box sx={{display:'flex',justifyContent: 'end',alignItems: 'center',width:'100%'}}>
                         <Box display="flex" >
                            <Button
                                variant="contained"
                                spacing={2} 
                                sx={{ flexGrow: 1, margin: 1,backgroundColor:SearchButtonTheme?.search_button_color,color:SearchButtonTheme?.search_button_text_color,
                                    '&:hover':{
                                        backgroundColor:SearchButtonTheme?.search_button_color+'88',
                                    },
                                    height:FilterButtonHeight
                                }}
                                className="iconeFilterSearch"
                                onClick={() => advanceSearch()} >
                                {t('GLOBAL.SEARCH_SEARCH_BUTTON')}
                            </Button>
                            <Button variant="outlined"
                                spacing={2}
                                sx={{
                                        flexGrow: 1, margin: 1,
                                        // backgroundColor:SearchButtonTheme?.clear_button_color,
                                        color:SearchButtonTheme?.clear_button_text_color,
                                    '&:hover':{
                                        backgroundColor:`${SearchButtonTheme?.clear_button_color}   !important`,
                                    },
                                    height:FilterButtonHeight
                                }}
                                className="iconeFilterClear"
                                color="secondary"
                                onClick={clearPointsAdvanceSearchFields} >
                                {t('GLOBAL.SEARCH_CLEAR_BUTTON')}
                            </Button>
                    </Box>
                </Box>
                
            </FilterBox>:null
            }
         

           
            <Box 
             sx={{
                display:'flex',
                justifyContent: 'start',
                alignItems: 'start',
                width:'100%',
             }}
            >
                <Box
                    sx={{
                    width:'100%',
                    }}
                >
                    <Grid
                    rows={rows}
                    columns={columns}
                    rowTotals={pointsTotal}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    pageNumber={pageNumber}
                    setPageNumber={setpageNumber}
                    loading={loading}
                    handlePageChange={handlePageChange}
                    NumberOfPage={pointsNumberOfPages}
                    directions={directions}
                    hasCreate={false}
                    createObject={null}
                    title_en=""
                    title_ar=""
                    gridHeight={{
                        // height:rows?.length>5?pageSize==5?400:pageSize==10?650:950:400,
                    }}
                    /> 
                </Box>
                {
                    filterSearchBoxMode?
                    <CustomeFilter
                    exportFunction={ExportFunction}
                    createFunction={createFunction}
                    mapFunction={mapFunction}
                    importFunction={importFunction}
                    hasExport={true} 
                    hasCreate={true} 
                    hasMaps={true} 
                    hasImport={true}
                    resultLength={pointsTotal}
                    //    customeHeight={screenWidth<=1100?'460px':"350px"}
                    >
                    <Box
                     sx={{
                        width:'100%',
                        height:'400px',
                        minHeight:'400px',
                        maxHeight:'400px',
                        overflowY:'scroll',
                        overflowX:'hidden',
                        padding:'10px',
                        marginTop:'10px',
                        paddingTop:'20px',
                        // boxShadow:'0 0 7px -5px #000 inset'
                     }}
                    >
                      <Autocomplete
                            sx={{ ml: 1,width:screenWidthRedux<=1100?'100%':'100%',marginTop:"10px !important",marginBottom:'15px !important'}}
                            fullWidth
                            options={districts}
                            getOptionLabel={(option) => option.name || ""}
                            id="disable-close-on-select"
                            value={district}
                            onChange={(e, v, r) => { setDistrict(v ? v : '') }}
                            renderInput={(params) => (
                                <TextField {...params} 
                                placeholder={t('GLOBAL.SEARCH_DISTRICTS')}

                                variant="standard" sx={{borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}} />
                            )}
                        />
                        <Autocomplete
                            sx={{ ml: 1,width:screenWidthRedux<=1100?'100%':'100%',marginTop:"15px !important",marginBottom:'15px !important' }}
                            fullWidth
                            options={regions}
                            getOptionLabel={(option) => option.name || ""}
                            id="disable-close-on-select"
                            value={region}
                            onChange={(e, v, r) => { setRegion(v ? v : '') }}

                            renderInput={(params) => (
                                <TextField {...params}
                                placeholder={t('GLOBAL.SEARCH_REGION')}
                                 variant="standard" sx={{borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}} />
                            )}
                        />
                    </Box>
                    <Box sx={{display:'flex',justifyContent: 'end',alignItems: 'center',width:'100%'}}>
                        <Box display="flex" >
                                <Button
                                    variant="contained"
                                    // spacing={2} 
                                    sx={{ 
                                        // flexGrow: 1,
                                        margin: 1,
                                        width:'80px !important',
                                        minWidth:'80px !important',
                                        maxWidth:'80px !important',
                                        backgroundColor:SearchButtonTheme?.search_button_color,color:SearchButtonTheme?.search_button_text_color,
                                        '&:hover':{
                                            backgroundColor:SearchButtonTheme?.search_button_color+'88',
                                        },
                                        height:FilterButtonHeight-10
                                    }}
                                    className="iconeFilterSearch"
                                    onClick={() => advanceSearch("SEARCH")} >
                                    {t('GLOBAL.SEARCH_SEARCH_BUTTON')}
                                </Button>
                                <Button variant="outlined"
                                    // spacing={2}
                                    sx={{
                                        width:'50% !important',
                                            // flexGrow: 1, 
                                            minWidth:'80px !important',
                                            maxWidth:'80px !important',
                                            margin: 1,
                                            // backgroundColor:SearchButtonTheme?.clear_button_color,
                                            color:SearchButtonTheme?.clear_button_text_color,
                                        '&:hover':{
                                            backgroundColor:`${SearchButtonTheme?.clear_button_color}   !important`,
                                        },
                                        height:FilterButtonHeight-10
                                    }}
                                    className="iconeFilterClear"
                                    color="secondary"
                                    onClick={clearPointsAdvanceSearchFields} >
                                    {t('GLOBAL.SEARCH_CLEAR_BUTTON')}
                                </Button>
                        </Box>
                    </Box>
                    </CustomeFilter>:null
                }

            </Box>


          
        </>
    );
}

export default Regions;




// <Box display="flex" >
// <Button display="flex"
//     startIcon={<PublishRoundedIcon sx={{ color: GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositeSecondaryColor }}/>}
//     variant="contained"
//     sx={{height:FilterButtonHeight, marginRight: 1, marginBottom: accExpansion ? "0" : "20px",backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor   }}
//     onClick={async () => { importObjects() }}>
//     {t('GLOBAL.SEARCH_IMPORT_BUTTON')}
// </Button>
// <Button display="flex"
//     startIcon={<FileDownloadIcon sx={{ color: GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositeSecondaryColor }}/>}
//     variant="contained"
//     sx={{height:FilterButtonHeight, marginRight: 1, marginBottom: accExpansion ? "0" : "20px",backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor   }}
//     onClick={async () => {

//         const fetchedData = await fetchData('DATA', 'regions', {
//             page_size: pointsTotal,
//             district: district.id,
//             region: region.id,
//         });

//         exportToCSV(fetchedData.data, 'regions')
//     }
//     }>
//     {t('GLOBAL.SEARCH_EXPORT_BUTTON')}

// </Button>

// <Button display="flex"
//     startIcon={<AddCircleRoundedIcon sx={{ color: GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositeSecondaryColor }} />}
//     variant="contained"
//     sx={{ height:FilterButtonHeight,marginRight: 1, marginBottom: accExpansion ? "0" : "20px" ,backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor  }}
//     onClick={() => { createObject() }}>
//     {t('GLOBAL.SEARCH_CREATE_BUTTON')}

// </Button>

// <Accordion display="flex" sx={{ flexGrow: 4, backgroundColor:GridTheme?.primaryColor }}
//     onChange={(event, expansion) => handleExpansion(expansion)}>
//     <AccordionSummary
//         expandIcon={<ExpandMoreIcon sx={{ color: GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositePrimaryColor }} />}
//         aria-controls="panel1a-content"
//         id="panel1a-header"
//     >
//         <Typography sx={{color:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositePrimaryColor}}>{t('GLOBAL.SEARCH_TITLE')}</Typography>
//     </AccordionSummary>
//     <AccordionDetails>
//         <Box component="span" display="flex" bgcolor="white" flexDirection="column" sx={{ borderRadius: 1 }}>
//             <Box sx={{ m: 1 }} component="span" display="flex" bgcolor="white" flexDirection="row" >
//                 <Box display="flex" sx={{ flexGrow: 2 }}>
//                     <Autocomplete
//                         sx={{ ml: 1 }}
//                         fullWidth
//                         options={districts}
//                         getOptionLabel={(option) => option.name || ""}
//                         id="disable-close-on-select"
//                         value={district}
//                         onChange={(e, v, r) => { setDistrict(v ? v : '') }}
//                         renderInput={(params) => (
//                             <TextField {...params} 
//                             label={t('GLOBAL.SEARCH_DISTRICTS')}

//                              variant="outlined" />
//                         )}
//                     />
//                     <Autocomplete
//                         sx={{ ml: 1 }}
//                         fullWidth
//                         options={regions}
//                         getOptionLabel={(option) => option.name || ""}
//                         id="disable-close-on-select"
//                         value={region}
//                         onChange={(e, v, r) => { setRegion(v ? v : '') }}

//                         renderInput={(params) => (
//                             <TextField {...params}
//                              label={t('GLOBAL.SEARCH_REGION')}
//                             variant="outlined" />
//                         )}
//                     />
//                 </Box>
//                 <Box display="flex" sx={{ flexGrow: 1 }}>
//                     <Button
//                         variant="contained"
//                         spacing={2} sx={{ flexGrow: 1, marginLeft: 1, marginRight: 1, height: 55 ,backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor,
//                             '&:hover':{
//                                 backgroundColor:GridTheme?.secondaryColor+'88',
//                             }}}
//                         onClick={advanceSearch} >
//                         {t('GLOBAL.SEARCH_SEARCH_BUTTON')}
//                     </Button>
//                     <Button variant="contained"
//                         spacing={2}
//                         sx={{ flexGrow: 1, margin: 0, height: 55,backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor,
//                             '&:hover':{
//                                 backgroundColor:GridTheme?.secondaryColor+'88',
//                             } }}
//                         color="secondary"
//                         onClick={clearPointsAdvanceSearchFields} >
//                         {t('GLOBAL.SEARCH_CLEAR_BUTTON')}
//                     </Button>
//                 </Box>
//             </Box>
//         </Box>
//     </AccordionDetails>
// </Accordion>
// </Box>