import React,{useMemo,useCallback,useState,useEffect} from 'react'
import { styled, useTheme } from "@mui/material/styles";
// import Box from '@mui/material/Box';
import Box from '@mui/material/Box';
import BarCharts from './Components/BarCharts';
import AreaChart from './Components/AreaChart';
import DoughnutChart from './Components/DoughnutChart';
import DoughnutChart2 from './Components/DoughnutChart2';
import ProductivityChart from './Components/ProductivityChart';
import { useSelector } from 'react-redux';

export default function Dashboard() {
  const directions=useSelector(state=>state.settingsData.directions)
  const drawerWidth=useSelector(state=>state.settingsData.drawerWidth)
  const SideMenuOpen = useSelector(state => state.settingsData.SideMenuOpen)
  const sideMenuMode = useSelector(state => state.settingsData.sideMenuMode)

  const screenWidth=useSelector(state=>state.settingsData.screenWidth)

  const getWidthOfCharts=useMemo(()=>{
    let width=screenWidth-35;
    if(SideMenuOpen){
      width-= (drawerWidth);
    }
    if(sideMenuMode){
      width-= (85);
    }
    if(screenWidth<=1150)
    {
      return '100%'
    }
  
    return `${width}px`;
  },[SideMenuOpen,screenWidth,sideMenuMode])

  return (
    <div
      style={{
        width:getWidthOfCharts,
        margin:0,
        padding:0,
      }}
    >
    
          <ProductivityChart />

          <br />
          <br />
          <br />
          <br />

    </div>
  )
}


const DashboardWrapper=styled(Box)(({theme})=>({
  width: '100%',
  height: '100%',
  paddingBottom:'60px'
}))

const MainChart=styled(Box)(({theme})=>({
  width: '95%',
  height: '400px',
  backgroundColor: '#1E293B',
  borderRadius:'15px',
}))