import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import {styled} from '@mui/material/styles'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { AiFillCloseCircle } from 'react-icons/ai'
import { Divider, Switch, CardMedia, Box, DialogTitle, IconButton, TextField, Typography, Select, InputLabel, MenuItem, FormControl, FormControlLabel, Button } from '@mui/material';
import GoogleMap from "../GoogleMap/GoogleMap";
import { useSelector, useDispatch } from 'react-redux'
import DialogForm from "../Share/DialogForm";
import { useTranslation } from "react-i18next";
import debounce from 'lodash.debounce'
import helpers from "../../../assets/js/helper";
// import TextField from '@mui/material/TextField';
// import Box from '@mui/material/Box';


const CustomeColorSelect=styled('div')(({theme,color,bgColor})=>({
    backgroundColor:bgColor,
    color:color,
    width:'100px',
    height:'100px',
    borderRadius:'50%',
    cursor: 'pointer',
    position: 'relative',
  }))

const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"
export const RegionForm = (props) => {
    const [loading, setloading] = useState(true);
    const [districts, setdistricts] = useState([]);
    const directions = useSelector(state => state.settingsData.directions);
    const MainTheme = useSelector(state => state.settingsData.MainTheme);
    const [regionsGeofences, setRegionsGeofences] = useState([])
    const [t, i18n] = useTranslation('common');
    const token = cookies.get("token")
    const [color,setColor]=useState('#000000')
    const [object, setObject] = useState({
        id: null,
        name: null,
        // color:'#000000',
        district: null,
        district: {
            id: null,
            name: null
        },
        region_geofences: [],
    });
    const getResources = async () => {
        try {
            setloading(true)
            const resourcesResult = await axios({
                url: Host + "districts_regions_list",
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                }
            })
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                    const resources = resourcesResult.data;

                    // console.log(resources);
                    setdistricts(resources);
                    if (props && props.region && props.region.id > 0) {
                        let regionDistrict = resources?.length > 0 ? resources[0] : { regions: [] };
                        const regionDistricts = resources?.filter(district => district?.id == props?.region?.district_id)
                        if (regionDistricts?.length > 0) {
                            regionDistrict = {
                                ...regionDistricts[0],
                                regions: regionDistricts[0]?.regions?.filter(region => region?.id != props?.region?.id)
                            };
                        }

                        setObject({
                            ...object,
                            ...props.region,
                            district: {
                                ...regionDistrict
                            }
                        });
                        if(props.region?.color)
                          setColor(props.region?.color)
                    }
                    else {

                        setObject(
                            {
                                id: null,
                                name: null,

                                district: null,
                                district: {
                                    id: null,
                                    name: null
                                },
                                region_geofences: []
                            }
                        )
                    }
                    

                } else if (resourcesResult.data.status === false) {

                }
            }
            else {
                toast.error("Error")
            }
            setloading(false)
        } catch (error) {
            setloading(false)
            toast.error("Network Error")
        }
    };

    const submitForm = async () => {

        try {


            if (object.id) {

                const resourcesResult = await axios({
                    url: Host + "regions/" + object.id,
                    method: "put",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data: {...object,color:color}
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("updated ")
                        props.setshowRegionForm(false, true)
                       
                    } else if (resourcesResult.data.status === false) {
                        props.setshowRegionForm(true, false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setshowRegionForm(true, false)
                }
                setloading(false)
            }
            else {
                const resourcesResult = await axios({
                    url: Host + "regions",
                    method: "post",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data: {...object,color:color}
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("saved ")
                        props.setshowRegionForm(false, true)
                        props.advanceSearch('add')
                        // console.log('asdasdasdasnncnnn',data)
                        // if(data)
                        // props.setpageNumber(data?.last_page)
                        
                    } else if (resourcesResult.status === 400) {
                        toast.error(resourcesResult.data)
                        props.setshowRegionForm(true, false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setshowRegionForm(true, false)
                }
                setloading(false)
            }
        } catch (error) {
            props.setshowRegionForm(true, false)
            const resourcesResult = error.response
            if (resourcesResult && resourcesResult.status === 400) {
                if (typeof resourcesResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key) => {

                        resourcesResult.data.data[key].map((message) => {
                            toast.error(message)
                        })
                    })
                }
                else {

                    toast.error(resourcesResult.data.data)
                }


            }
            else if (resourcesResult && resourcesResult.status === 401) {

                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else {

                toast.error("network error")
            }
        }

    };

    const styleTextField = {
        // backgroundColor:!check?'rgb(255,255,255,0.7)':'rgb(0,0,0,0.4)',
        // color:MainTheme?.paperTextColor,
    }
    const styleSelect = {
        // backgroundColor:!check?'rgb(255,255,255,0.7)':'rgb(0,0,0,0.4)',
        // color:MainTheme?.paperTextColor,
    }


    useEffect(() => {


        if (loading)
            getResources()


        return () => {

        };
    }, [loading, props.showRegionForm]);

    var colorForBuble={}
    try{
        if(color)
        colorForBuble={
            color: !helpers.checkDarknessOrLightnessForColor(color)?'#000':'#fff',
        }
        console.log('asdasdad',object?.color)
    }catch(e){
        console.log('errorMessage',e.message)
    }

    useEffect(()=>{
     
    },[object])

    const TimerControl=React.useRef(null)
    const changeColor=debounce((e)=>{
        // e.persist()
        if(TimerControl?.current){
            clearTimeout(TimerControl?.current)
        }
            setColor(e)
            console.log(e)
           
       
    },80)

    return (
        <DialogForm
          open={props.showRegionForm}
          close={props.setshowRegionForm}
          loaderActive={loading}
          headerChildren={(
            <>
               <IconButton aria-label={"Close"} onClick={() => { props.setshowRegionForm(false) }} style={{ position: 'absolute', top: '0px', left: '6px', fontSize: '37px', }}>
                    <AiFillCloseCircle style={{ color: MainTheme?.paperTextColor ,outline:'none !important' ,border:'none !important'}} />

                </IconButton>
                <Typography component={'h1'} variant={'h4'} style={{marginTop:'10px',marginBottom:'10px' ,color: MainTheme?.disabledGlobalText ? MainTheme.gloablTextColor : MainTheme?.paperTextColor, width: '100%', textAlign: 'center' }}>
                    {props?.title ? props?.title : (object?.id ? t('REGION.FORMUPDATE_TITLE') : t('REGION.FORMADD_TITLE'))}
                </Typography>
            </>
          )}
          hasHeader={true}
          CustomeWidth="80%"
          CustomeHeight="fit-content"
        >

           

            <DialogContent dividers={false} sx={{
                padding: 0,
                overflowX: 'hidden',
                backgroundColor: 'transparent',
                height: 'fit-content',


            }}>

                {

                    <Box 
                    sx={{
                        padding: '10px',
                        paddingTop: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'auto',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        color: MainTheme?.paperTextColor,
                        '& .muiltr-a4ms82-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root,& svg,& .muirtl-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                            color: MainTheme?.disabledGlobalText ? MainTheme.gloablTextColor : MainTheme?.paperTextColor,
                        },
                        '& input': {
                            color: MainTheme?.disabledGlobalText ? MainTheme.gloablTextColor : MainTheme?.paperTextColor,

                        },
                        '& .MuiOutlinedInput-root': {
                            borderColor: `1px solid ${MainTheme?.paperTextColor} !important`,
                            color: MainTheme?.disabledGlobalText ? MainTheme.gloablTextColor : MainTheme?.paperTextColor,
                        },
                        '& .MuiInputLabel-root': {
                            // backgroundColor:`${MainTheme?.paperTextColor+'22'} !important`,
                            color: MainTheme?.disabledGlobalText ? MainTheme.gloablTextColor : MainTheme?.paperTextColor,
                        },
                        '& .MuiFormControl-root': {
                            // border:`1px solid ${MainTheme?.paperTextColor} !important`,
                            color: MainTheme?.disabledGlobalText ? MainTheme.gloablTextColor : MainTheme?.paperTextColor,
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                            },
                            '&:hover fieldset': {
                                borderColor: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: MainTheme?.secondaryColor,
                                color: MainTheme?.disabledGlobalText ? MainTheme.gloablTextColor : MainTheme?.secondaryColor,

                            },
                        },
                        '& .MuiOutlinedInput': {
                            // borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            // border: "0 none",
                        },

                    }}>
                        <Box sx={{width:'100%',display:'flex',justifyContent:'center',alignItems:'start',flexDirection:'column'}}>
                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                height: 'auto',

                                width: '100%',

                                justifyContent: 'space-evenly',
                                alignItems: 'flex-start'

                            }}>
                                <Box sx={{
                                
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    margin:'0 10px ',
                                    flex: 1

                                }}>
                                    <TextField
                                        id="outlined-basic-name"
                                        label={t('REGION.FORM_NAME')}
                                        variant="standard"
                                        placeholder={t('REGION.FORM_NAME')}
                                        sx={{ ...styleSelect }}
                                        value={object?.name}
                                        fullWidth
                                        onChange={(event) => {
                                            //console.log("name", event);
                                            setObject({ ...object, name: event.target.value });
                                        }}
                                    />
                                    {/* <TextField 
                                    id="outlined-basic" 
                                    label="Outlined" 
                                    variant="outlined" 
                                    fullWidth
                                    
                                    /> */}

                                </Box>
                                <Box sx={{

                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%',
                                    marginLeft: '10px',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    margin:'0 10px ',

                                    flex: 1

                                }}>
                                    <FormControl fullWidth sx={{ ...styleSelect }} variant="standard">
                                        <InputLabel id="district-select-label" sx={{ color: MainTheme?.paperTextColor }}>{t('REGION.FORM_DISTRICT')}</InputLabel>
                                        <Select
                                            labelId="district-select-label"
                                            id="district-select"
                                            sx={{ textAlign: 'left' }}
                                            value={object?.district}
                                            label={t('REGION.FORM_DISTRICT')}
                                            renderValue={(value) => {
                                                //console.log("value", value);
                                                if (value?.id != "" && value?.id != null) {

                                                    return `${value?.name}`
                                                }
                                                else {

                                                    return <Typography sx={{ color: MainTheme?.paperTextColor }}>{t('REGION.FORM_SELECT_DISTRICT')}</Typography>
                                                }
                                            }}
                                            onChange={(event) => {

                                                setObject({
                                                    ...object, district: {
                                                        ...event?.target?.value
                                                    }, district_id: event.target.value?.id
                                                });
                                                // setRegionsGeofences(event?.target?.value?.regions)
                                            }}
                                        >
                                            {districts?.length > 0 && districts?.map((district) => {

                                                return <MenuItem key={district?.id} value={district}>{district?.name}</MenuItem>
                                            })}


                                        </Select>
                                    </FormControl>


                                </Box>
                               

                            </Box>
                            <Box
                                sx={{
                                width: '40%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop:'10px',
                                }}
                                >
                                <CustomeColorSelect color="#fff" bgColor={color}
                                    onClick={(e)=>{
                                        if(e.target&&e.target.querySelector('input'))
                                        e.target.querySelector('input').click()
                                    }}
                                >
                                    <input 
                                    type="color" 
                                    style={{visibility: 'hidden',zIndex:2000,marginTop:'70px',marginLeft:'-70px'}}
                                    value={color}
                                    onChange={(e)=>changeColor(e.target.value)}
                                    
                                    />
                                    <p 
                                    style={{
                                        ...colorForBuble,
                                        position: 'absolute',
                                        top:"52%",
                                        left:"50%",
                                        transform:'translate(-50%, -50%)',
                                        pointerEvents: 'none',
                                    }}
                                    >{color}</p>
                                </CustomeColorSelect>
                                </Box>
                        </Box>
                        <Divider sx={{ marginTop: '30px', width: '100%' }} />
                        <Box style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            height: 'auto',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center'

                        }}>

                            <Typography component={'h1'} variant={'h6'} sx={{ color: MainTheme?.disabledGlobalText ? MainTheme.gloablTextColor : MainTheme?.paperTextColor }}>
                                {t('REGION.FORM_MESSAGE')}
                            </Typography>

                            <Typography component={'p'} variant={'p'} sx={{ color: MainTheme?.disabledGlobalText ? MainTheme.gloablTextColor : MainTheme?.paperTextColor }}>
                                {t('REGION.FORM_SUB_MESSAGE')}
                            </Typography>

                        </Box>
                        <Divider sx={{ marginTop: '30px',marginBottom: '30px', width: '100%' }} />
                        <Box style={{

                            padding: '10px',
                            paddingTop: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            height: '400px',
                            width: '100%',
                            justifyContent: 'center',

                            alignItems: 'center'

                        }}>

                            <GoogleMap
                                createPoly={true}
                                deletable={true}
                                markers={object?.region_geofences && object?.region_geofences?.length > 0 ? object?.region_geofences : []}
                                polygon={

                                    object?.region_geofences && object?.region_geofences?.length > 0 ?
                                        {
                                            name:object?.name,
                                            paths: object?.region_geofences?.map((geoFence) => {
                                                return {
                                                    lat: geoFence?.latitude,
                                                    lng: geoFence?.longitude,
                                                    id: geoFence?.id

                                                }
                                            }),
                                            strokeColor: color?color:'$ff0000',
                                            fillColor: color?color:'$ff0000'

                                        } : null
                                }
                                polygons={
                                    object?.district?.regions?.length > 0 ?
                                        object?.district?.regions?.map(region => {
                                            return {
                                                name: region?.name,
                                                paths: region?.region_geofences?.map((geoFence) => {
                                                    return {
                                                        lat: geoFence?.latitude,
                                                        lng: geoFence?.longitude,
                                                        id: geoFence?.id

                                                    }
                                                }),
                                                strokeColor: region?.color,
                                                fillColor: region?.color,

                                            }
                                        })
                                        : []
                                }
                                polygonsChanged={object?.district_id}
                                draggable={true}

                                setMarkers={(markers) => {
                                    setObject({
                                        ...object,
                                        region_geofences: markers
                                    });
                                }}
                                onClick={(value) => {
                                    let id = object?.region_geofences?.length > 0 ? Math.max(...object?.region_geofences?.map(geoFence => geoFence?.id)) + 1 : 1
                                    setObject({
                                        ...object,
                                        region_geofences: [...object?.region_geofences, {
                                            id: id,
                                            latitude: value?.lat,
                                            longitude: value.lng,
                                            label: 'g' + id
                                        }]
                                    });
                                }} />
                        </Box>
                        <Box 
                         className="buttonSendForm"
                        sx={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            height: 'auto',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center'

                        }}>
                            <Button
                                sx={{ color: MainTheme?.disabledGlobalText ? MainTheme.gloablTextColor : MainTheme?.paperTextColor }}
                                onClick={() => {
                                    submitForm()
                                }}>{t('REGION.FORM_SUBMIT_FORM')}</Button>
                        </Box>

                    </Box>
                }




            </DialogContent>

        </DialogForm>
    );
};

export default RegionForm;
