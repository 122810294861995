import React,{useState,useEffect} from 'react'
// reactstrap components
import {styled} from '@mui/material/styles'
import {Box,Button} from '@mui/material'
import {useSelector,useDispatch} from 'react-redux'
import FilterCenterFocusIcon from '@mui/icons-material/FilterCenterFocus';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import swal from 'sweetalert'
import {useTranslation} from "react-i18next";
import Host from "../../../assets/js/Host";
import {ImagePath} from '../../../assets/js/Host'
import { toast } from 'react-toastify';
import Cookies from "universal-cookie";
import axios from "axios";
import LoadingPage from '../Share/LoadingPage'
import TextField from '@mui/material/TextField';
import {
    setUserName,setUserPhone,setUserData,setUserRoleName,setUserToken,setFullName,setImage
  } from "../../reduxStore/UserReducer";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import userImage from '../../../assets/img/userImage.png'

const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"


const CustomeHeader=styled(Box)(({theme,MainTheme,SideMenuTheme})=>({
    width:'100%',
    height:'200px',
    backgroundColor:SideMenuTheme?.bgColor,
    color:MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme?.primaryTextColor,
    position:'relative',
    top:'-30px',
    borderRadius:'10px',
    zIndex:0,
}))

const CustomeWrapperContent=styled(Box)(({theme,MainTheme,screenWidth})=>({
    width:'100%',
    display: 'flex',
    flexDirection:screenWidth<=991?'column':'row',
    justifyContent: 'center',
    alignItems: 'start',
    position: 'relative',
    top:'-120px',    
    zIndex:100,
}))

const LeftSide=styled(Box)(({theme,MainTheme,screenWidth})=>({
  width:screenWidth<=991?'80%':'30%',
  height:'350px',
  borderRadius:'5px',
  backgroundColor:MainTheme?.paperColor,
  boxShadow:`1px 0px 8px 1px ${MainTheme?.paperTextColor+'33'}`,
  margin:' 10px auto',
  display: 'flex',
  flexDirection:'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding:"10px 0px",
  overflow: 'hidden',
  position: 'relative',
  "& h3":{
    fontSize:'30px',
    color:MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme?.paperTextColor,
  },
  "& p":{
    fontSize:'19px',
    color:MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme?.paperTextColor,
  },
  "& span":{
    fontSize:'16px',
    margin:'5px 30px'
  },
  '& .headerPanner':{
    position: 'absolute',
    backgroundRepeat: 'no-repeat',
    backgroundSize:'cover',
    backgroundPosition:'center',
    top: 0,
    left: 0,
    right: 0,
    height: '100px',
    backgroundImage:"url('https://demos.creative-tim.com/now-ui-dashboard-react/static/media/bg5.056eb058.jpg')"
  }

}))

const RightSide=styled(Box)(({theme,MainTheme,screenWidth})=>({
    width:screenWidth<=991?'80%':'50%',
    height:'400px',
    borderRadius:'5px',
    backgroundColor:MainTheme?.paperColor,
    boxShadow:`1px 0px 8px 1px ${MainTheme?.paperTextColor+'33'}`,
    zIndex:"2000 !important",
    margin:' 10px auto',
    padding:'10px 20px',
    position: 'relative',
    '& p':{
        fontSize:'27px',
        color:MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme?.paperTextColor,
    }

}))

const CustomeImageProfile=styled(Box)(({theme,MainTheme,img})=>({
    width:'150px',
    height:'150px',
    borderRadius:'50%',
    backgroundImage:`url('${img}')`,
    backgroundColor:MainTheme?.paperColor+'aa',
    backgroundRepeat:'no-repeat',
    backgroundSize:'cover',
    backgroundPosition:'center',
    border:'2px solid white',
    boxShadow:'0 2px 5px -4px #000',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    '& .chooiceImage':{
        position:'absolute',
        bottom:2,
        right:'12px',
        cursor:'pointer',
        backgroundColor:MainTheme?.paperColor,
        borderRadius:'50%',
        padding:'1px',
        zIndex:'2000',

    }

}))

export default function Profile() {
   const directions=useSelector(state =>state.settingsData.directions)
   const MainTheme=useSelector(state =>state.settingsData.MainTheme)
   const SideMenuTheme=useSelector(state =>state.settingsData.SideMenuTheme)

   const {t, i18n} = useTranslation('common');
   const token = cookies.get("token")
   const [loading,setLoading]=useState(false)
   const userName= useSelector(state=> state.userData.userName)
   const fullName= useSelector(state=> state.userData.fullName)
   const phone= useSelector(state=> state.userData.phone)
   const image= useSelector(state=> state.userData.image)
   const dispatch=useDispatch();
   const [showPassword,setShowPassword]=useState(false)
   const [userInfo,setUserInfo]=useState({
    name:userName,
    phone:phone,
    image:'',
    full_name:fullName,
    img_url:'',
    password:'',

   })
//    const [userImage,setUserImage]=useState('')
  
   useEffect(()=>{
    console.log('image',ImagePath+image)
    setUserInfo({
        ...userInfo,
        image: ImagePath+image,
        img_url:ImagePath+image
    })
   },[image])

  
   const [screenWidth,setScreenWidth]=useState(window.innerWidth)

   useEffect(()=>{
    let userIconeCamera=document.querySelector('.chooiceImage')
    userIconeCamera.addEventListener('click',()=>{
        userIconeCamera.parentElement.querySelector('input').click()
    })

    window.addEventListener('resize',()=>{
        setScreenWidth(window.innerWidth)
    })

   },[])
   

   const uploadImages = async (image) => {
    let imgurl=window.URL.createObjectURL(image);
       setUserInfo({
        ...userInfo,
        image: image,
        img_url:imgurl
       })
    try {
        console.log('image1',image)

        if (image&&image!==null) {
         console.log('image2',image)

            setLoading(true)
            const formData=new FormData();
                formData.append('image',image);
                formData.append('user_id',cookies.get('user_id'));
                formData.append('_method','patch');


            const resourcesResult = await axios({
                url: Host + "users/update_image",
                method: "post",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'content-type': 'multipart/form-data',
                    'X-localization': language,
                },
                data:formData,
            })
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                    toast.success("image updated sccess")
                    cookies.set("image", resourcesResult?.data?.image?.image_url);
                    dispatch(setImage(resourcesResult?.data?.image?.image_url))

                } else if (resourcesResult.data.status === false) {
                }
            }
            else {
                toast.error("Error")
            }
            setLoading(false)
        }
        else {
            swal(
                `${t('SWEATALERT_MESSAGES.WARNNING_MESSAGE')}`,
                 `${t('SWEATALERT_MESSA.IMAGE_NOT_FOUND')}`, 
                 `${t('SWEATALERT_MESSAGES.WARRNING_BUTTON')}`);

            setLoading(false)
            return
        }
    } catch (error) {
        setLoading(false)
        console.log("error",error);
        const resourcesResult = error.response
        if (resourcesResult && resourcesResult.status === 400) {
            if (typeof resourcesResult.data.data === "object") {
                const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                errorMessagesKeys.map((key) => {

                    resourcesResult.data.data[key].map((message) => {
                        toast.error(message)
                    })
                })
            }
            else {

                toast.error(resourcesResult.data.data)
            }


        }
        else if (resourcesResult && resourcesResult.status === 401) {

            toast.error(resourcesResult.data.data)
            cookies.remove("token");
            window.location.href = "/"
        }
        else {

            toast.error("network error")
        }
    }

   };


   const updateUserInfo=async () => {
    try{
        if (cookies.get('user_id')) {
            setLoading(true)
            
            const formData=new FormData();
            formData.append('user_id',cookies.get('user_id'))
            if(userInfo?.full_name)
            formData.append('full_name',userInfo?.full_name)
            if(userInfo?.phone)
            formData.append('phone',userInfo?.phone)
            if(userInfo?.name)
            formData.append('username',userInfo?.name)
            if(userInfo?.password)
            formData.append('password',userInfo?.password)

            formData.append('_method','put')

            // // formData.append('is_demo',object?.is_demo)
            // formData.append('company',object?.company)
            // formData.append('department',object?.department)
            // formData.append('department_id',object?.department?.id)
            // formData.append('company_id',object?.company?.id)

            const resourcesResult = await axios({
                url: Host + "users/" + cookies.get('user_id'),
                method: "post",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    "content-type":'multipart/form-data',
                    'X-localization': language,
                },
                data: formData
            })
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                    toast.success("updated info success ")
                    cookies.set("username", resourcesResult?.data?.username);
                    cookies.set("full_name", resourcesResult?.data?.full_name);
                    cookies.set("phone", resourcesResult?.data?.phone);

                    // cookies.set("user_id", resourcesResult?.data?.id);
                    // cookies.set("image", resourcesResult?.data?.image?.image_url);
                    dispatch(setUserName(resourcesResult?.data?.username))
                    dispatch(setFullName(resourcesResult?.data?.full_name))
                    dispatch(setUserPhone(resourcesResult?.data?.phone))
                    dispatch(setUserData(resourcesResult?.data))
                    dispatch(setUserRoleName(resourcesResult?.data?.username))
                    // dispatch(setImage(resourcesResult?.data?.image?.image_url))

                } else if (resourcesResult.data.status === false) {
                }
            }
            else {
                toast.error("Error")
            }
            setLoading(false)
        }else{
            toast.success("user id is null")
            setLoading(false)

        }
    } catch (error) {
    console.log("error",error);
    setLoading(false)
    const resourcesResult = error.response
    if (resourcesResult && resourcesResult.status === 400) {
        if (typeof resourcesResult.data.data === "object") {
            const errorMessagesKeys = Object.keys(resourcesResult.data.data);
            errorMessagesKeys.map((key) => {

                resourcesResult.data.data[key].map((message) => {
                    toast.error(message)
                })
            })
        }
        else {

            toast.error(resourcesResult.data.data)
        }


    }
    else if (resourcesResult && resourcesResult.status === 401) {

        toast.error(resourcesResult.data.data)
        cookies.remove("token");
        window.location.href = "/"
    }
    else {

        toast.error("network error")
    }
    }
   }

   const setNewUserData=(name,value)=>{
    setUserInfo({
        ...userInfo,
       [name]:value 
    })
   } 
   const reformatDisplayPhone=(phone)=>{
    let cuirrentphone=phone
    
    if(phone){
        if(phone.slice(0,1)=='+'){
            cuirrentphone=phone.slice(1)+phone.slice(0,1);
            console.log('currentphone',cuirrentphone)
        }else{
            return phone
        }
    }
    return cuirrentphone
 }


  return (
    <>
    <CustomeHeader MainTheme={MainTheme} SideMenuTheme={SideMenuTheme}/>

    <CustomeWrapperContent screenWidth={screenWidth} MainTheme={MainTheme}>
        <LeftSide screenWidth={screenWidth} MainTheme={MainTheme}>
             <div className="headerPanner"></div>
            <CustomeImageProfile  MainTheme={MainTheme} img={userInfo?.image?userInfo?.img_url:userImage}>
                <input type="file" style={{display: 'none'}} onChange={(e)=>{
                   uploadImages(e.target.files[0])
                }} />
                <PhotoCameraIcon size={30} className="chooiceImage" sx={{color:MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme?.paperTextColor}}/>
            </CustomeImageProfile>
            <h3>{fullName}</h3>
            <p>{userName}</p>

            {/* <span>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Totam aperiam natus quis odio obcaecati pariatur sunt deserunt ipsam quaerat voluptatibus?</span> */}
        </LeftSide>
        <RightSide screenWidth={screenWidth} MainTheme={MainTheme}>
            <p style={{display: 'flex',justifyContent: 'start'}}>{t('PROFILE.USER_TITLE')}</p>
            <Box 
              sx={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                flexWrap: 'wrap',
                width: '100% !important',
                '& .muiltr-a4ms82-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root,& svg,& .muirtl-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':{
                    color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                 },
                '& input':{
                    color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                
                    },
                    '& .MuiOutlinedInput-root':{
                        borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
                        color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                    },
                    '& .MuiInputLabel-root':{
                        // backgroundColor:`${MainTheme?.paperTextColor+'22'} !important`,
                        color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                    },
                    '& .MuiFormControl-root':{
                      // border:`1px solid ${MainTheme?.paperTextColor} !important`,
                      color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                      },
                      '&:hover fieldset': {
                        borderColor:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: MainTheme?.secondaryColor,
                        color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.secondaryColor,
                      },
                    },
                    '& .MuiOutlinedInput':{
                      // borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        // border: "0 none",
                     },
                     '& .muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root:before,& .muiltr-1ptx2yq-MuiInputBase-root-MuiInput-root:before':{
                        borderColor:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                     },
                     '& .muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root .Mui-focused':{
                        borderColor: MainTheme?.secondaryColor,
                        color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.secondaryColor,
                     }
              }}
              fullWidth
            >
                <Box
                    component="form"
                    sx={{
                         width: '50%',
                    }}
                    fullWidth
                    noValidate
                    autoComplete="off"
                    >
                    <TextField
                     sx={{width:'98%'}}  
                     
                     
                     value={userInfo?.full_name} 
                     onChange={(e)=>{
                        setNewUserData('full_name', e.target.value);
                     }}
                     id="outlined-basic"
                      label={t('PROFILE.FULL_NAME')} 
                      variant="standard" />
                </Box>

                <Box
                    component="form"
                    sx={{
                         width: '50%',
                    }}
                    fullWidth
                    noValidate
                    autoComplete="off"
                    >
                    <TextField sx={{width:'98%'}}
                     onChange={(e)=>{
                        setNewUserData('name', e.target.value);
                     }}
                     value={userInfo?.name} id="outlined-basic" label={t('PROFILE.USERNAME')} variant="standard" />
                </Box>

                <Box
                    component="form"
                    sx={{
                         m: 0.1,width: "100%",
                    }}
                    fullWidth
                    noValidate
                    autoComplete="off"
                    >
                    <TextField sx={{
                        width:'99%',mt:1,
                        '& input':{
                            textAlign:'left !important',

                         }
                    }}
                     onChange={(e)=>{
                        setNewUserData('phone', e.target.value);
                     }}
                    
                     value={userInfo?.phone} 
                     id="outlined-basic" 
                     inputProps={{
                        dir: "ltr",
                        textAlign:'right',
                    }}
                     label={t('PROFILE.PHONE')} 
                     variant="standard" 
                     placeholder={'+9647812346794'}
                     />
                </Box>

                <Box
                    component="form"
                    sx={{
                         m: 0.1,width: "100%",
                        //  display: "flex",
                        //  justifyContent: "space-between",
                        //  alignItems: "center",
                         position: "relative",
                    }}
                    fullWidth
                    noValidate
                    autoComplete="off"
                    >
                    <TextField 
                    sx={{width:'99%',mt:1}}
                      type={showPassword?'text':"password"}
                     onChange={(e)=>{
                        setNewUserData('password', e.target.value);
                     }}
                     
                    id="outlined-basic" 
                      value={userInfo?.password}
                    label={t('PROFILE.PASSWORD')} variant="standard" />

                    {!showPassword?<VisibilityIcon 
                     sx={{position:'absolute',
                        top:'20px',
                        right:'10px',
                        cursor:'pointer',
                        color:MainTheme?.disabledGlobalTextColor?MainTheme?.gloablTextColor:MainTheme?.paperTextColor
                    }}
                    onClick={()=>setShowPassword(true)}/>:
                    <VisibilityOffIcon  sx={{position:'absolute',
                    top:'20px',
                    right:'10px',
                    cursor:'pointer',
                    color:MainTheme?.disabledGlobalTextColor?MainTheme?.gloablTextColor:MainTheme?.paperTextColor

                }}onClick={()=>setShowPassword(false)}/>}

                </Box>

                <Box
                    component="form"
                    sx={{
                         width: "100%",
                         display: "flex",
                         justifyContent: "end",
                         mr:0.6
                    }}
                    fullWidth
                    noValidate
                    autoComplete="off"
                    >
                          <Button 
                          onClick={updateUserInfo}
                          sx={{
                            mt:2,
                            backgroundColor:MainTheme?.secondaryColor,
                            color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.oppositeSecondaryColor,
                            }} variant="contained">{t('PROFILE.UPDATE_BUTTON')}</Button>

                </Box>
            </Box>

        </RightSide>
    </CustomeWrapperContent>
  
     {
        loading?
        <LoadingPage/>
        :null
     }
    </>
  )
}
