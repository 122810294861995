import React ,{useState,useEffect} from 'react'

import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { useSelector, useDispatch } from 'react-redux'
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import './Notifications.css'
import {useTranslation} from "react-i18next";

function notificationsLabel(count) {
    if (count === 0) {
      return 'no notifications';
    }
    if (count > 99) {
      return 'more than 99 notifications';
    }
    return `${count} notifications`;
  }
export default function FullScreen({ToolBarTheme}) {
  const [fullScreenMode,setFullScreenMode]=useState(false)
  const directions = useSelector(state=> state.settingsData.directions)
  const {t, i18n} = useTranslation('common');

    function btnClicked(e){
            // if (  e.target.requestFullscreen) {
            //       e.target.requestFullscreen();
            //   } else if (  e.target.webkitRequestFullscreen) { /* Safari */
            //       e.target.webkitRequestFullscreen();
            //   } else if (  e.target.msRequestFullscreen) { /* IE11 */
            //       e.target.msRequestFullscreen();
            //   }
            // e.target.requestFullscreen();
            document.requestFullscreen()
            setFullScreenMode(true)
        
    }

    const existFromFullScreen=(e)=>{
        if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.webkitExitFullscreen) { /* Safari */
            document.webkitExitFullscreen();
          } else if (document.msExitFullscreen) { /* IE11 */
            document.msExitFullscreen();
          }

        // e.target.exitFullscreen();
        setFullScreenMode(false)
    }

  async  function fullscreen(e) {
        var isInFullScreen = (document.fullscreenElement && document.fullscreenElement !== null) ||
            (document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
            (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
            (document.msFullscreenElement && document.msFullscreenElement !== null);
    
        var docElm = e.target;
        if (!isInFullScreen) {
            if(docElm.requestFullscreen) {
                docElm.requestFullscreen();
                setFullScreenMode(true)
            }
         

        } else {
            if(document.exitFullscreen) {
                document.exitFullscreen();
                setFullScreenMode(false)
            }
           
            // document.cancelFullScreen();
            // setFullScreenMode(false)

        }
    }

    function isFullScreen() {
        return (document.fullScreenElement && document.fullScreenElement !== null) ||
          (document.msFullscreenElement && document.msFullscreenElement !== null) ||
          (document.mozFullScreen || document.webkitIsFullScreen);
      }
      
      function enterFS() {
        var page = document.documentElement
        if (page.requestFullscreen) page.requestFullscreen();
        else if (page.mozRequestFullScreen) page.mozRequestFullScreen();
        else if (page.msRequestFullscreen) page.msRequestFullscreen();
        else if (page.webkitRequestFullScreen) page.webkitRequestFullScreen();
      }
      
      function exitFS() {
        if (document.exitFullScreen) return document.exitFullScreen();
        else if (document.webkitExitFullscreen) return document.webkitExitFullscreen();
        else if (document.msExitFullscreen) return document.msExitFullscreen();
        else if (document.mozCancelFullScreen) return document.mozCancelFullScreen();
      }
      
      function toggleFS() {
        if (!isFullScreen()) {
          enterFS();
          setFullScreenMode(true)
          document.getElementById("toggle-fs").innerHTML = '<img src="/images/nofs.png">';
        } else {
          exitFS();
          setFullScreenMode(false)
          document.getElementById("toggle-fs").innerHTML = '<img src="/images/fs.png">';
        }
      }

    
    if(!fullScreenMode)
       return (
       <Tooltip arrow title={t('APPBAR.TOOLTIP_FULL_SCREEN')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="bottom">
            <IconButton  className='Notification' onClick={(e)=>toggleFS()}>
            <FullscreenIcon className='colorNotificationFullScreen' size={30} style={{color:ToolBarTheme?.disabledGlobalText?ToolBarTheme?.gloablTextColor:ToolBarTheme?.paperTextColor}}/>
            </IconButton>
        </Tooltip>
       )
    else 
       return(
       <Tooltip arrow title={t('APPBAR.TOOLTIP_EXISTS_FULL_SCREEN')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="bottom">
            <IconButton  className='Notification' onClick={(e)=>toggleFS()}>
            <FullscreenExitIcon className='colorNotificationFullScreen' size={30} style={{color:ToolBarTheme?.disabledGlobalText?ToolBarTheme?.gloablTextColor:ToolBarTheme?.paperTextColor}}/>
            </IconButton>
        </Tooltip>
       )
}
