import React, { useEffect, useState, useMemo, useCallback } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
// import ListItemText from '@mui/material/ListItemText';
// import ListItem from '@mui/material/ListItem';
// import List from '@mui/material/List';
// import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box'
import { useSelector, useDispatch } from 'react-redux'
import { useGlobalContext } from '../../../context'
import { useTranslation } from "react-i18next";
import TextField from '@mui/material/TextField';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// import {DateRangePicker,DateRange} from '@mui/lab'
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
// import React from 'react'
// import Scheduler, { Resource } from 'devextreme-react/scheduler';

// import { employees, data } from './data';
import DataCell from './DataCell';
import ResourceCell from './ResourceCell';
// import helpers from '../../../assets/js/helper'
import { setSelectedCell } from '../../reduxStore/SettingsReducer'
// import {useSelector,useDispatch} from 'react-redux'

import './Style.css'
import {
  // setDistrictsList,
  // setRegionsList,
  // setDistrictsList_temp,
  // setRegionsList_temp,
  setGlobalVariableForJourneys,
  setListOfJourneysPlan
} from '../../reduxStore/SettingsReducer'
import moment from 'moment'

// import dayjs from 'dayjs';
// import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Cookies from "universal-cookie";
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import axios from "axios";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
// import FolderIcon from '@mui/icons-material/Folder';

import { styled, alpha } from '@mui/material/styles';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem, { useTreeItem, treeItemClasses } from '@mui/lab/TreeItem';
import clsx from 'clsx';
import { result } from 'lodash';
import Grid from '@mui/material/Grid';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import { MonthPicker } from '@mui/x-date-pickers/MonthPicker';
import { YearPicker } from '@mui/x-date-pickers/YearPicker';
// import {IconButton} from '@mui/material'
// import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';
// import TreeView from '@mui/lab/TreeView';
// import Collapse from '@mui/material/Collapse';
// web.cjs is required for IE11 support
// import { useSpring, animated } from 'react-spring/web.cjs';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Calendar, DateRangePicker } from 'react-date-range';
import * as GiIcons from 'react-icons/gi';
import { addDays } from 'date-fns';
import helpers from '../../../assets/js/helper'

import Calender from './Calender';
import Scheduler, { Resource } from 'devextreme-react/scheduler';
import { CurrencyRuble } from '@mui/icons-material';

// import { employees, data } from './data.js';
// import DataCell from './DataCell.js';
// import ResourceCell from './ResourceCell.js';

const currentDate = new Date(2021, 5, 2, 11, 30);
const groups = ['employeeID'];
const views = ['month'];

// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"

export default function ViewJourney(props) {
  const directions = useSelector(state => state.settingsData.directions)
  const MainTheme = useSelector(state => state.settingsData.MainTheme)
  const token = cookies.get("token")
  const GridTheme = useSelector(state => state.settingsData.GridTheme)
  const [selectMandoob, setSelectedMandoob] = useState([])
  const { user, users, fromToDate, journey_types, visits, setUser, journies, pointsStatuses, pointStatus, setPointStatus, exportToCSV, allPoints, points, district, region, districts, regions, ownerName, shopName, clearPointsAdvanceSearchFields, setRegion, setDistrict, setShopName, setOwnerName, fetchData, deleteObject } = useGlobalContext()
  const [loading, setLoading] = useState(false)
  const [t, i18n] = useTranslation('common');
  const [listofDate, setListOfDate] = useState([])
  const [listOfJourneyFOrUser, setListOfJourneyForUser] = useState([])
  const screenWidthRedux = useSelector(state => state.settingsData.screenWidth)
  const SideMenuOpen = useSelector(state => state.settingsData.SideMenuOpen)

  const dispatch = useDispatch()
  const [cellDurationValue, setCellDurationValue] = useState(15)

  // const DistrictsList=useSelector(state=>state.settingsData.DistrictsList)
  // const regionsList=useSelector(state=>state.settingsData.regionsList)
  const [DistrictsList, setDistrictsList] = useState()
  const [regionsList, setRegionsList] = useState()


  const GlobalVariableForJourneys = useSelector(state => state.settingsData.GlobalVariableForJourneys)
  const [daySelectedID, setDaySelectedID] = useState(0)
  const [typeOfJourney, setTypeOfJourney] = useState('')
  const [fromDate_selector, setFromDate_selector] = useState(new Date())
  const [toDate_selector, setToDate_selector] = useState(new Date())
  const [pageSize, setPageSize] = useState(1000)
  const [GlobalDataOfSelectedDay, setGlobalDataOfSelectedDay] = useState([])
  const [dataForCalendar, setDataForCalendar] = useState([])
  const [currentSelectedDate, setCurrentSelectedDate] = useState(new Date())
  const selectedCell = useSelector(state => state.settingsData.selectedCell)
  const [dataForSelectedDate, setDataForSelectedDate] = useState(null);

  // const [daysOfWeek,setDaysOfWeek]=useState([
  const currentDate = React.useRef(null)
  // ])
  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  }

  useEffect(() => {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let start_date = helpers.formatDate(new Date(firstDay))
    let end_date = helpers.formatDate(new Date(lastDay))
    setFromDate_selector(start_date)
    setToDate_selector(end_date)
  }, [])


  const checkDistrictExist = (dis_id, dayID) => {
    let check = false;
    if (GlobalDataOfSelectedDay?.length > 0) {
      GlobalDataOfSelectedDay?.map((data) => {
        if (data?.number == dayID && data?.districts?.length > 0 || typeOfJourney == 'Daily') {
          check = data?.districts?.find(({ id }) => id === dis_id);
          // data?.districts&&data?.districts?.length>0&&data?.districts?.map((itm)=>{
          //     if(id==itm?.id){
          //       check=true;
          //     }
          // })
        }
      })
    }
    return check;
  }
  const checkRegionExist = (reg_id, dist_id, dayID) => {
    let check = false;
    if (GlobalDataOfSelectedDay?.length > 0) {
      GlobalDataOfSelectedDay?.map((data) => {
        if (data?.number == dayID || typeOfJourney == 'Daily') {
          data?.districts && data?.districts?.length > 0 && data?.districts?.map((itm) => {
            if (itm?.id == dist_id && itm?.regions?.length > 0) {
              check = itm?.regions?.find(({ id }) => id === reg_id);

              // itm?.regions&&itm?.regions?.length>0&&itm?.regions?.map((reg)=>{
              //     if(id==reg?.id){
              //       check=true;
              //     }
              // })
            }
          })
        }
      })
    }
    return check;
  }
  const checkGlobalExist = (dayID) => {
    let check = false;
    if (GlobalDataOfSelectedDay?.length > 0) {
      // GlobalVariableForJourneys?.map((data)=>{
      //   if(data?.number==props?.index){

      //     if(data?.districts&&data?.districts?.length>0){
      //       check=true;
      //     }
      //   }
      // })
      check = GlobalDataOfSelectedDay.find(({ number }) => number === dayID);

      if (typeOfJourney == 'Daily')
        check = true;

    }
    return check;
  }


  const getRegionsByDistrict = async (dayID) => {

    setLoading(true)
    try {
      const resourcesResult1 = await axios({
        url: Host + "regions_list",
        method: "get",
        headers: {
          "Authorization": `Bearer ${token}`,
          Accept: "application/json",
          'X-localization': language,
        },


      })
      if (resourcesResult1) {
        if (resourcesResult1.status === 200) {
          const resources1 = resourcesResult1.data;

          let arr = []
          resources1.map((district) => {

            arr.push({
              parent_id: district?.district_id,
              label: district?.name,
              value: district.id,
              name: 'region',
              check: checkRegionExist(district.id, district?.district_id, dayID),
              show: true,

            })
          })

          // dispatch(setRegionsList(arr))
          // dispatch(setRegionsList_temp(arr))
          setRegionsList(arr)

        } else if (resourcesResult1.data.status === false) {
          toast.error("Error in loading regions")

        }
      }
      else {
        toast.error("Error in regions request")

      }
    }
    catch (error) {
      console.log(error);
    }
    setLoading(false)

    //  }
  }
  const getResources = async (dayID) => {
    setLoading(true)

    try {
      const resourcesResult = await axios({
        url: Host + "districts_list",
        method: "get",
        headers: {
          "Authorization": `Bearer ${token}`,
          Accept: "application/json",
          'X-localization': language,
        }
      })
      if (resourcesResult) {
        if (resourcesResult.status === 200) {
          const resources = resourcesResult.data;
          let checkGlobal = false;
          // if(GlobalVariableForJourneys?.length>0&&){
          //   checkGlobal=true;
          // }

          const prepareResources = [
            {
              "label": "اختر الكل",
              "value": 0,
              "check": checkGlobalExist(dayID),
              "name": 'districtsAll',
              show: true,
              "children": resources.map((resource) => {
                return {
                  label: resource.name,
                  value: resource.id,
                  show: true,
                  "check": checkDistrictExist(resource.id, dayID),
                  name: "regionAll"
                }

              })
            }
          ]

          // dispatch(setDistrictsList(prepareResources))
          setDistrictsList(prepareResources)
          // dispatch(setDistrictsList_temp(prepareResources))
          console.log('districts list', prepareResources)

        } else if (resourcesResult.data.status === false) {
          toast.error("Error in loading district")
        }
      }
      else {
        toast.error("Error in regions request")
      }

      // setloading(false)
    } catch (error) {
      console.log(error);
      // setloading(false)
      toast.error("Network Error")
    }
    setLoading(false)

  };


  useEffect(() => {
    setListOfDate([
      {
        id: 1,
        value: new Date(),
      }, {
        id: 2,
        value: new Date(),
      }, {
        id: 3,
        value: new Date(),
      }
    ])
    // dispatch(setGlobalVariableForJourneys([]))
    setGlobalDataOfSelectedDay([])

  }, [])
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ]);

  const setNewDate = (date, id) => {
    let arr = [];
    listofDate && listofDate?.length > 0 && listofDate?.map((itm) => {
      if (itm?.id == id) {
        arr.push({
          ...itm,
          value: date
        })
      }
    })
    setListOfDate(arr)
  }
  useEffect(() => {
    fetchData('USERS', 'users_list')
    let ClickedItmCell = document.querySelector('.ClickedItmCell')

  }, [])
  const handleClose = () => {
    props?.setOpen(false);
  };
  useEffect(() => {
    console.log(users)
  }, [users])

  const checkIfNoMandoob = () => {
    let check = false;
    users && users?.length > 0 && users?.map((itm) => {
      if (itm?.is_mobile == 1) {
        check = true;
      }
    })
    return check;
  }
  const formatUserDataAfterGeted = (data) => {
    // {
    //   startDate: new Date(),
    //   endDate: addDays(new Date(), 7),
    //   key: 'selection'
    // },
    let arr = [];
    data && data?.length > 0 && data?.map((itm) => {
      arr.push({
        startDate: new Date(itm?.start_date),
        endDate: new Date(itm?.end_date),
        key: 'selection',

      })
    })
    setState(arr)

  }
  const getUserSelectData = async () => {

    setLoading(true)
    try {
      const result = await axios({
        url: Host + "journey_plans?user_id=" + selectMandoob,
        method: "get",
        headers: {
          "Authorization": `Bearer ${token}`,
          Accept: "application/json",
          'X-localization': language,
        },
        params: {
          page_size: pageSize,
          start_date: fromDate_selector,
          end_date: toDate_selector
        }

      })
      if (result && result.status == 200) {
        if (result?.data && result?.data?.data?.length > 0) {
          setListOfJourneyForUser(result?.data?.data)
          // localStorage.setItem('data_plan_list',JSON.stringify(result?.data?.data) );
          dispatch(setListOfJourneysPlan(result?.data?.data))

          formatUserDataAfterGeted(result?.data?.data)
          // dispatch(setGlobalVariableForJourneys([]))
          setGlobalDataOfSelectedDay([])
          console.log('user list data', result.data)
          // toast.success(result.data.data)
        } else {
          setListOfJourneyForUser([])
          dispatch(setListOfJourneysPlan([]))
          // dispatch(setGlobalVariableForJourneys([]))
          setGlobalDataOfSelectedDay([])

        }

      } else if (result.data.status === false) {
        toast.error("Error")
      }
    } catch (error) {
      const responseResult = error.response
      if (responseResult && responseResult.status === 400) {
        if (typeof responseResult.data.data === "object") {
          const errorMessagesKeys = Object.keys(responseResult.data.data);
          errorMessagesKeys.map((key) => {

            responseResult.data.data[key].map((message) => {
              toast.error(message)
            })
          })
        }
        else {
          toast.error(responseResult.data.data)
        }

      }
      else if (responseResult && responseResult.status === 401) {
        toast.error(responseResult.data.data)
        cookies.remove("token");
        window.location.href = "/"
      }
      else {
        toast.error("network error")
      }
    }
    setLoading(false)
  }
  const formatUserDataAfterGeted__ = (data) => {
    // {
    //   startDate: new Date(),
    //   endDate: addDays(new Date(), 7),
    //   key: 'selection'
    // },
    console.log('render multiple')
    let arr = [];
    setCurrentSelectedDate(new Date(data[0]?.start_date))
    data && data?.length > 0 && data?.map((itm) => {
      arr.push({
        // text:'',
        // employeeID: itm?.user?.id,
        // planId:itm?.id,
        startDate: new Date(itm?.start_date),
        endDate: new Date(itm?.end_date),
      })
    })
    setDataForCalendar(arr)

  }

  React.useEffect(() => {

    if (listOfJourneyFOrUser && listOfJourneyFOrUser?.length > 0) {
      console.log('manyRenderValue')
      formatUserDataAfterGeted__(listOfJourneyFOrUser)
    } else {
      setDataForCalendar([])
    }

  }, [listOfJourneyFOrUser])


  let data = React.useMemo(() => {
    if (selectMandoob && selectMandoob > 0) {
      getUserSelectData()
      setDataForSelectedDate(null)
    }
  }, [selectMandoob, pageSize, fromDate_selector, toDate_selector])

  const checkCopaierDate = (day, start_date, end_date) => {
    let d = Date.parse(day)
    let d2 = Date.parse(start_date)
    let d3 = Date.parse(end_date)

    return d >= d2 && d <= d3

  }

  const [selectedDailyPlanID, setSelectedDailyPlanID] = useState(0)
  // const clickedData=(date)=>{
  //   dispatch(setSelectedCell(helpers.formatDate(date?.cellData?.startDate)))
  // }
  const clickedData = (date) => {
    getDataOfJourneysForSelectedDate(date)
    setGlobalDataOfSelectedDay([])
    // dispatch(setSelectedCell(helpers.formatDate(date?.cellData?.startDate)))

    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var d = new Date(date?.cellData?.startDate);
    var dayNumber = d.getDay() + 1;
    console.log('selected date', d.getDay() + 1)
    let arr = [];
    let checkDayDateFinde = false
    listOfJourneyFOrUser && listOfJourneyFOrUser?.length > 0 && listOfJourneyFOrUser?.map((itm) => {
      console.log('enter here1')
      if (checkCopaierDate(d, itm?.start_date, itm?.end_date) && !checkDayDateFinde) {
        console.log('enter here2')
        if (itm?.journey_type && itm?.journey_type?.name == 'Weekly') {
          itm?.journey_plan_days && itm?.journey_plan_days?.length > 0 && itm?.journey_plan_days?.map((plan) => {
            if (plan?.day_number == dayNumber && !checkDayDateFinde) {
              console.log('enter here3')
              setSelectedDailyPlanID(plan?.journey_plan_id)
              setTypeOfJourney(itm?.journey_type?.name)
              getDataofSelectedOnject(plan?.journey_plan_id)
              checkDayDateFinde = true
              // // arr.push(plan?.journey_plan_id)
              setDaySelectedID(plan?.day_number)
              return;
            }
          })
        }
        else {
          if (itm?.journey_plan_days && itm?.journey_plan_days?.length > 0) {

            console.log('enter here3')
            setSelectedDailyPlanID(itm?.journey_plan_days[0]?.journey_plan_id)
            getDataofSelectedOnject(itm?.journey_plan_days[0]?.journey_plan_id)
            checkDayDateFinde = true
            setTypeOfJourney(itm?.journey_type?.name)
            // arr.push(itm?.journey_plan_days[0]?.journey_plan_id)
            setDaySelectedID(0)

          }

        }
      }
    })
    // setSelectedDailyPlanID(arr)
  }


  const getDataofSelectedOnject = async (id) => {
    try {
      setLoading(true)
      const resourcesResult = await axios({
        url: Host + "journey_plan?id=" + id,
        method: "get",
        headers: {
          "Authorization": `Bearer ${token}`,
          Accept: "application/json",
          'X-localization': language,
        },

      })
      if (resourcesResult) {
        if (resourcesResult.status === 200) {
          if (resourcesResult?.data && resourcesResult?.data?.length > 0) {
            // console.log('user',resourcesResult?.data)
            // dispatch(setGlobalVariableForJourneys(resourcesResult?.data))
            setGlobalDataOfSelectedDay(resourcesResult?.data)
            //  toast.success('journey add success')
            //  props.advanceSearch()
            //  props.setOpen(false)
          } else {
            // dispatch(setGlobalVariableForJourneys([]))
            setGlobalDataOfSelectedDay([])
            // dispatch(setDistrictsList([]))
            // dispatch(setRegionsList([]))

          }
        } else if (resourcesResult.data.status === false) {
        }
      }
      else {
        toast.error("Error")
      }
      setLoading(false)

    } catch (error) {
      console.log("error===>", error);
      setLoading(false)
      const resourcesResult = error.response
      if (resourcesResult && resourcesResult.status === 400) {
        if (typeof resourcesResult.data.data === "object") {
          const errorMessagesKeys = Object.keys(resourcesResult.data.data);
          errorMessagesKeys.map((key) => {

            resourcesResult.data.data[key].map((message) => {
              toast.error(message)
            })
          })
        }
        else {

          toast.error(resourcesResult.data.data)
        }


      }
      else if (resourcesResult && resourcesResult.status === 401) {

        toast.error(resourcesResult.data.data)
        cookies.remove("token");
        window.location.href = "/"
      }
      else {

        toast.error("network error")
      }
    }
  }

  // useEffect(()=>{
  //   if(selectedDailyPlanID&&selectedDailyPlanID>0&&typeOfJourney){
  //         getDataofSelectedOnject(selectedDailyPlanID)
  //   }
  // },[selectedDailyPlanID,typeOfJourney])

  //  const controller=React.useRef(null)
  useEffect(() => {
    if (GlobalDataOfSelectedDay && GlobalDataOfSelectedDay?.length > 0) {
      // if(controller)
      //   clearTimeout(controller)
      // controller=setTimeout(()=>{
      getResources(daySelectedID)
      getRegionsByDistrict(daySelectedID)
      // },700)
    }
  }, [GlobalDataOfSelectedDay, daySelectedID])

  const setNewDateSelectForMonth = (dateSelect) => {
    var date = new Date(dateSelect);
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let start_date = helpers.formatDate(new Date(firstDay))
    let end_date = helpers.formatDate(new Date(lastDay))
    console.log('selected_start_date', start_date)
    console.log('selected_end_date', end_date)

    setFromDate_selector(start_date)
    setToDate_selector(end_date)

  }

  const onAppointmentDeleted = (e) => {

  }
  const onAppointmentUpdated = (e) => {

  }
  const onAppointmentUpdating = (e) => {

  }
  const onAppointmentFormOpening = (data) => {
    //console.log(data)
    data.cancel = true;

    return null;

  }
  const onOptionChanged = (e) => {
    //console.log(e)
    if (e.fullName == "currentDate") {
      if (e.value != e.previousValue) {
        //setDayPlanDate(e.value)
        //console.log(e.value)

        //  setWorkingDay(e.value)
        //  setIsLoading(true)
      }
    }
    //console.log(e)
  }
  const dateChanged = (date) => {
    console.log('date changed===<>', date)
    setNewDateSelectForMonth(date)
    localStorage.setItem('currentMonth', date)

  }

  const getDataOfJourneysForSelectedDate = async (date) => {
    console.log('asdasdasdsad-->', date)
    if (selectMandoob && date) {
      try {
        setLoading(true)
        const resourcesResult = await axios({
          url: Host + "journey_plan_shift",
          method: "get",
          headers: {
            "Authorization": `Bearer ${token}`,
            Accept: "application/json",
            'X-localization': language,
          },
          params: {
            date: helpers.formatDate(new Date(date?.cellData?.startDate)),
            user_id: selectMandoob
          }

        })
        if (resourcesResult) {
          if (resourcesResult.status === 200) {
            setDataForSelectedDate(resourcesResult?.data)
            console.log('hgetdesdsadas=>', resourcesResult?.data)
          } else if (resourcesResult.data.status === false) {
          }
        }
        else {
          toast.error("Error")
        }
        setLoading(false)

      } catch (error) {
        console.log("error===>", error);
        setLoading(false)
        const resourcesResult = error.response
        if (resourcesResult && resourcesResult.status === 400) {
          if (typeof resourcesResult.data.data === "object") {
            const errorMessagesKeys = Object.keys(resourcesResult.data.data);
            errorMessagesKeys.map((key) => {

              resourcesResult.data.data[key].map((message) => {
                toast.error(message)
              })
            })
          }
          else {

            toast.error(resourcesResult.data.data)
          }
        }
        else if (resourcesResult && resourcesResult.status === 401) {
          toast.error(resourcesResult.data.data)
          cookies.remove("token");
          window.location.href = "/"
        }
        else {

          toast.error("network error")
        }
      }
    }
  }

  return (

    <Box
      sx={{
        width: '100%',
        height: 'fit-content',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        '& .MuiOutlinedInput-root': {
          borderColor: `1px solid ${MainTheme?.paperTextColor} !important`,
          color: MainTheme?.disabledGlobalText ? MainTheme.gloablTextColor : MainTheme?.paperTextColor,
        },
        '& .MuiInputLabel-root': {
          // backgroundColor:`${MainTheme?.paperTextColor+'22'} !important`,
          color: MainTheme?.disabledGlobalText ? MainTheme.gloablTextColor : MainTheme?.paperTextColor,
        },
        '& .MuiFormControl-root': {
          // border:`1px solid ${MainTheme?.paperTextColor} !important`,
          color: MainTheme?.disabledGlobalText ? MainTheme.gloablTextColor : MainTheme?.paperTextColor,
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: MainTheme?.disabledGlobalText ? MainTheme.gloablTextColor : MainTheme?.paperTextColor,
          },
          '&:hover fieldset': {
            borderColor: MainTheme?.disabledGlobalText ? MainTheme.gloablTextColor : MainTheme?.paperTextColor,
          },
          '&.Mui-focused fieldset': {
            borderColor: MainTheme?.disabledGlobalText ? MainTheme.gloablTextColor : MainTheme?.secondaryColor,
          },
        },
        '& .MuiOutlinedInput': {
          // borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
        },
        "& .MuiOutlinedInput-notchedOutline": {
          // border: "0 none",
        },
        '& .muiltr-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select,& .muiltr-hfutr2-MuiSvgIcon-root-MuiSelect-icon, & .muirtl-hfutr2-MuiSvgIcon-root-MuiSelect-icon,& .muirtl-5d5cuw .muirtl-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select': {
          color: GridTheme?.disabledGlobalText ? GridTheme.gloablTextColor : GridTheme?.paperTextColor

        },


        '& p , & span ,& .MuiInputLabel-root': {
          fontSize: "15px !important"
        },

        '& .MuiOutlinedInput-root': {
          borderColor: `1px solid ${GridTheme?.paperTextColor} !important`,
          color: GridTheme?.disabledGlobalText ? GridTheme.gloablTextColor : GridTheme?.paperTextColor,
        },
        '& .MuiInputLabel-root': {
          // backgroundColor:`${GridTheme?.paperTextColor+'22'} !important`,
          color: GridTheme?.disabledGlobalText ? GridTheme.gloablTextColor : GridTheme?.paperTextColor,
        },
        '& .MuiFormControl-root': {
          // border:`1px solid ${GridTheme?.paperTextColor} !important`,
          color: GridTheme?.disabledGlobalText ? GridTheme.gloablTextColor : GridTheme?.paperTextColor,
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: GridTheme?.disabledGlobalText ? GridTheme.gloablTextColor : GridTheme?.paperTextColor,
          },
          '&:hover fieldset': {
            borderColor: GridTheme?.disabledGlobalText ? GridTheme.gloablTextColor : GridTheme?.paperTextColor,
          },
          '&.Mui-focused fieldset': {
            borderColor: GridTheme?.disabledGlobalText ? GridTheme.gloablTextColor : GridTheme?.secondaryColor,
          },
        },
        '& .MuiOutlinedInput': {
          // borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
        },
        "& .MuiOutlinedInput-notchedOutline": {
          // border: "0 none",
        },

        '& .muirtl-1a1fmpi-MuiInputBase-root-MuiInput-root, & .muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root': {
          paddingLeft: "5px"
        },
        '& .muirtl-1a1fmpi-MuiInputBase-root-MuiInput-root:before,& .muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root:before': {
          borderColor: `${GridTheme?.disabledGlobalText ? GridTheme.gloablTextColor : GridTheme?.paperTextColor} !important`,
          outlineColor: `${GridTheme?.disabledGlobalText ? GridTheme.gloablTextColor : GridTheme?.paperTextColor} !important`,
        },
        '& .muirtl-1a1fmpi-MuiInputBase-root-MuiInput-root:hover:before, &.muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root:hover:before': {
          borderColor: `${GridTheme?.disabledGlobalText ? GridTheme.gloablTextColor : GridTheme?.paperTextColor} !important`,
          outlineColor: `${GridTheme?.disabledGlobalText ? GridTheme.gloablTextColor : GridTheme?.paperTextColor} !important`,
        },
        '& .muiltr-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root:before,& .muirtl-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root:before': {
          borderColor: `${GridTheme?.disabledGlobalText ? GridTheme.gloablTextColor : GridTheme?.paperTextColor} !important`,
          outlineColor: `${GridTheme?.disabledGlobalText ? GridTheme.gloablTextColor : GridTheme?.paperTextColor} !important`,
        }

      }}
    >
      <Box
        sx={{
          width: '96%',
          backgroundColor: GridTheme?.paperColor,
          color: GridTheme?.disabledGlobalText ? GridTheme.gloablTextColor : GridTheme?.paperTextColor,
          borderRadius: '5px',
          boxShadow: `0 0 7px 1px #000`,
          overflow: 'hidden',
        }}
      >
        {/* header here */}
        <Box
          sx={{ width: '100%', height: '60px', padding: '0 10px', backgroundColor: GridTheme?.primaryColor, display: 'flex', justifyContent: 'start', alignItems: 'center' }}
        >
          <IconButton
            onClick={() => {
              props?.setOpen()
            }}
            sx={{
              backgroundColor: GridTheme?.oppositePrimaryColor + '66',
              cursor: 'pointer',
              margin: '20px 0 !important'
            }}
          >
            <GiIcons.GiJourney sx={{ color: GridTheme?.oppositePrimaryColor }} color={GridTheme?.oppositePrimaryColor} />

          </IconButton>
          <span style={{ display: 'flex', justifyContent: 'start', margin: "0 10px", color: GridTheme?.oppositePrimaryColor }}>{t('JOURNEYS.EDIT_FORM_TITLE_PAGE')}</span>
        </Box>

        {/* search bar here ...  */}
        <Box
          sx={{
            width: '100%', marginTop: "20px", display: 'flex', alignItems: 'center', justifyContent: 'center',
            '& .muirtl-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select': {
              display: 'flex',
              justifyContent: 'start',
            },
            '& .muiltr-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.muiltr-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.muiltr-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input,& .muirtl-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.muirtl-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.muirtl-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input': {
              display: 'flex',
              justifyContent: 'start',
              color: GridTheme?.disabledGlobalText ? GridTheme.gloablTextColor : GridTheme?.paperTextColor + ' !important',
            },
            '& .muiltr-pqjvzy-MuiSvgIcon-root-MuiSelect-icon,& .muirtl-pqjvzy-MuiSvgIcon-root-MuiSelect-icon': {
              color: GridTheme?.disabledGlobalText ? GridTheme.gloablTextColor : GridTheme?.paperTextColor + ' !important',
            },
            "& .muirtl-1cccqvr": {
              display: 'flex !important',
              justifyContent: 'start',
            }

          }}
        >
          <FormControl sx={{ width: '50%', marginRight: '5px' }} variant="standard">
            <InputLabel id="demo-simple-select-label">{t('JOURNEYS.JP_SELECT_MANDOOB')}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectMandoob}
              label={t('JOURNEYS.JP_SELECT_MANDOOB')}
              onChange={(e) => {
                setSelectedMandoob(e.target.value)
              }}
            >
              {
                users && users?.length > 0 && users?.map((itm) => (
                  // itm?.is_mobile==1?
                  <MenuItem key={itm?.id} value={itm?.id}>{itm?.username}</MenuItem>
                  //  :null
                ))
              }
              {/* {
                              !checkIfNoMandoob()?<MenuItem value={0}>{t('JOURNEYS.NO_MANDOOBEEN')}</MenuItem>:null
                            } */}


            </Select>
          </FormControl>
        </Box>

        <div
          style={{ width: '100%', display: 'flex', flexDirection: !SideMenuOpen ? screenWidthRedux <= 1200 ? 'column' : 'column' : 'column', justifyContent: 'space-between', alignItems: 'start', padding: '0 10px' }}
        >

          {/* <div style={{width:screenWidthRedux<=1100?'3%':'3%'}}></div> */}
          <Box
            className="CalenderData "
            sx={{
              width: SideMenuOpen ? '100%' : screenWidthRedux <= 1200 ? "100%" : '100%',
              height: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'start',
              direction: directions,
              margin: '20px 0',
              marginRight: '30px',
              overflowX: 'scroll',
              // padding:'0 30px',
              maxWidth: '100%',
              "& .rdrDefinedRangesWrapper,& .rdrDateDisplayWrapper": {
                display: 'none !important'
              },
              '& .rdrMonth': {
                margin: '0 10px'
              },
              '& .dx-scheduler-appointment-content': {
                backgroundColor: `${MainTheme?.secondaryColor} !important`,
                color: `${MainTheme?.oppositeSecondaryColor} !important`,

                // width:'10px !important',
                // height:'40px !important',
                // borderRadius:'50% !important'
              },
              '& .dx-scheduler-cell-sizes-horizontal': {
                width: '80px !important'
              }

            }}
          >

            <Calender dataForCalendar={dataForCalendar} currentSelectedDate={currentSelectedDate} listOfJourneyFOrUser={listOfJourneyFOrUser} clickedData={clickedData} setFromDate_selector={setFromDate_selector} setToDate_selector={setToDate_selector} />



          </Box>
        </div>
      </Box>
      <Box
        sx={{
          width: '96%',
          backgroundColor: GridTheme?.paperColor,
          color: GridTheme?.disabledGlobalText ? GridTheme.gloablTextColor : GridTheme?.paperTextColor,
          borderRadius: '5px',
          boxShadow: `0 0 7px 1px #000`,
          marginTop: '20px',
          overflow: 'hidden',
          paddingTop: '20px',
        }}
      >

        {/* {
                dataForSelectedDate? */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '90%',
            boxShadow: '0 0 3px -1px #000',
            margin: '5px auto',
            padding: '10px',
            borderRadius: '5px',
          }}
        >
          <Box sx={{ width: '85%', margin: '0 10px', display: 'flex', flexDirection: 'row', justifyContent: 'start', alignItems: 'center' }}>
            <span style={{ fontWeight: 'bold', fontSize: '25px', margin: '0 10px' }}>{t('JOURNEYS.SELECTED_SHIFT_Details') + ' :'}</span>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
              {
                dataForSelectedDate && dataForSelectedDate?.shifts?.length > 0 ? dataForSelectedDate?.shifts?.map((shift) => (
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                    <span style={{ margin: '0 10px' }}>{t('SHIFTS.FORM_START_WORKING_HOURS') + " : "}{shift?.start_minutes ? helpers.convertMinutesToHourse(shift?.start_minutes, 0) : '00:00'}</span>
                    <span style={{ margin: '0 10px' }}>{t('SHIFTS.FORM_END_WORKING_HOURS') + " : "}{shift?.end_minutes ? helpers.convertMinutesToHourse(shift?.end_minutes, 0) : "00:00"}</span>
                    <span style={{ margin: '0 10px' }}>{t('SHIFTS.FORM_REQUIRED_WORKING_HOURS') + " : "}{shift?.minuts_required ? helpers.convertMinutesToHourse(shift?.minuts_required, 1) : '00'}</span>
                  </Box>)) :
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                    <span style={{ margin: '0 10px' }}>{t('SHIFTS.FORM_START_WORKING_HOURS') + " : " + '00:00'}</span>
                    <span style={{ margin: '0 10px' }}>{t('SHIFTS.FORM_END_WORKING_HOURS') + " : " + "00:00"}</span>
                    <span style={{ margin: '0 10px' }}>{t('SHIFTS.FORM_REQUIRED_WORKING_HOURS') + " : " + '00'}</span>
                  </Box>
              }
            </Box>

          </Box>
          <Box sx={{ width: '20%', display: 'flex', justifyContent: 'start', alignItems: 'center', margin: '0 10px' }}>
            <span style={{ fontWeight: 'bold', fontSize: '25px', margin: '0 5px' }}>{t('JOURNEYS.JOURNY_TYPE') + " :  "}</span>
            <span>{dataForSelectedDate?.journey_plan?.journey_type?.name ? dataForSelectedDate?.journey_plan?.journey_type?.name : '--'}</span>
          </Box>
        </Box>
        {/* :null
              } */}
        {/* show plane for selected day  */}
        <div style={{
          width: SideMenuOpen ? '100%' : screenWidthRedux <= 1200 ? "100%" : '100%',
          display: 'flex', justifyContent: 'center', alignItems: 'center'
        }}>
          <Box
            sx={{
              width: '90%',
              color: GridTheme?.disabledGlobalText ? GridTheme.gloablTextColor : GridTheme?.papertextColor,
              backgroundColor: GridTheme?.paperColor,
              // height:'300px',
              borderRadius: '5px',
              border: `3px solid ${GridTheme?.disabledGlobalText ? GridTheme.gloablTextColor + '33' : GridTheme?.paperTextColor + '33'}`,
              margin: '20px 0',
              padding: '10px',
              // height:'700px',
              minHeight: '410px',
              maxHeight: '410px',
              overflowY: 'scroll',
              paddingBottom: '10%',

            }}
          >
            <Typography variant='h6' sx={{ display: 'flex', fontWeight: 'bold', color: GridTheme?.disabledGlobalText ? GridTheme.gloablTextColor : GridTheme?.papertextColor, }}>{t('JOURNEYS.PLANSFOR_SELECTED_DATE')} {`"${selectedCell ? helpers?.formatDate(new Date(selectedCell)) : ''}"`}</Typography>
            <Box
              sx={{
                minHeight: '300px',
                paddingTop: '20px',

              }}
            >
              {
                !loading ?
                  GlobalDataOfSelectedDay && GlobalDataOfSelectedDay?.length > 0 ?
                    DistrictsList?.length > 0 && DistrictsList?.map((itm) => (
                      itm?.show && itm?.children && itm?.children?.length > 0 && itm?.children?.map((district) => (
                        district?.check && district?.show ?
                          <Box key={district?.value + district?.name}>
                            <h4 style={{ display: 'flex', justifyContent: 'start', textAlign: 'left' }}><ArrowRightAltIcon sx={{ color: GridTheme?.disabledGlobalText ? GridTheme.gloablTextColor : GridTheme?.paperTextColor, fontSize: "32px !important", margin: '0 0 0 20px' }} />{district?.label}</h4>
                            {
                              regionsList && regionsList?.length > 0 && regionsList?.map((region) => (
                                region?.parent_id == district?.value && region?.check && region?.show ?
                                  <Box key={region?.value + region?.name} sx={{ display: 'flex', justifyContent: 'start', }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                                      <ListItemButton>
                                        <ListItemIcon>
                                          <ArrowRightIcon sx={{ fontSize: '26px' }} />
                                        </ListItemIcon>
                                        <ListItemText primary={region?.label} />
                                      </ListItemButton>
                                    </Box>
                                  </Box>
                                  : null
                              ))
                            }
                          </Box>
                          : null
                      ))
                    )) :
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                        top: '60px'
                      }}
                    >
                      <p>{t('JOURNEYS.NO_PLAN_FOUND')}</p>
                    </Box>
                  :
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress sx={{ color: GridTheme?.disabledGlobalText ? GridTheme.gloablTextColor : GridTheme?.paperTextColor }} />
                  </Box>
              }
            </Box>
          </Box>
        </div>
      </Box>
      <br />
      <br />
      <br />
      <br />
      <br />
    </Box>

  )
}
