import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useGlobalContext } from '../../../context'
import { Box, Typography } from '@mui/material';
import {useTranslation} from "react-i18next";
import {useSelector,useDispatch} from 'react-redux'

export default function BasicDatePickerV2({Language,customeWidth,customeMargin,customePaddingLeft,marginMorLitileBit}) {
  const { fromToDate, setFromToDate } = useGlobalContext()
  const [value, setValue] = React.useState(null);
  const [t, i18n] = useTranslation('common');
  const GridTheme=useSelector(state=>state.settingsData.GridTheme)
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      // paddingLeft: customePaddingLeft?customePaddingLeft:'13px',
      // marginTop:customeMargin?customeMargin:0,
      mt:1.2,
      ml:0.4,
      // marginTop:3,
      // paddingRight: '5px',
      width:customeWidth
    }}>
      <LocalizationProvider dateAdapter={AdapterDateFns} sx={{width:'100%',}}>
        <DatePicker
          // placeholder={t('GLOBAL.SEARCH_FROM_DATE')}
          value={fromToDate[0]}
          inputFormat="yyyy/MM/dd"
          sx={{width:"100%"}}
          onChange={(newValue) => {
            // setValue(newValue);

            setFromToDate([newValue, fromToDate[1]])
          }}
          renderInput={(params) => <TextField
             {...params}    
             inputProps={{
              ...params.inputProps,
              placeholder:t('GLOBAL.SEARCH_FROM_DATE')
             }}
             variant="standard" sx={{width:"100%",margin:'7px 0px !important',borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}}/>}
        />
      </LocalizationProvider>
     
      <LocalizationProvider dateAdapter={AdapterDateFns} sx={{width:'100%'}}>
        <DatePicker
          placeholder={t('GLOBAL.SEARCH_TO_DATE')}
          value={fromToDate[1]}
          inputFormat="yyyy/MM/dd"
          sx={{width:"100%"}}
          onChange={(newValue) => {
            // setValue(newValue);
            setFromToDate([fromToDate[0], newValue])
          }}
          renderInput={(params) => <TextField 
            {...params} 
            inputProps={{
              ...params.inputProps,
              placeholder:t('GLOBAL.SEARCH_TO_DATE')
             }}
            variant="standard" sx={{width:"100%",margin:'7px 0px !important',borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}}/>}
        />
      </LocalizationProvider>
    </Box>

  );
}
