import React,{useState,useEffect,useMemo,useCallback} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {useSelector,useDispatch} from 'react-redux'
import swal from 'sweetalert';
import SettingsIcon from '@mui/icons-material/Settings';
import {
    setSideMenuMode,
    sideMenuMode,
    setDirections,
    setMainPaletteShow,
    setSideMenuPaletteShow,
    setToolBarPaletteShow,
    setGridPaletteShow,
    setMainTheme,
    setSideMenuTheme,
    setToolbarTheme,
    setGridTheme,
    setDisabledGlobalText,
    setForceReloadTheme

  } from '../../reduxStore/SettingsReducer'

import SettingsApplicationsRounded from '@mui/icons-material/SettingsApplicationsRounded';
import './ThemePalette.css'
import PaletteIcon from '@mui/icons-material/Palette';
import { SketchPicker,BlockPicker,HuePicker  } from 'react-color';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import helpers from '../../../assets/js/helper'


import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';

import {
    ContainerModal,
    HeaderModal,
    ContentModal,
    LeftSide,
    RightSide,
    PrimeryColorForText,
    LineForColor,
    SecondaryColorForText,
    BackgroundPaperColor,
    BackgroundColor,
    PreviewBox,
    LightOrDarkmode,
    LightMode,
    DarkMode,
    TopSectionPreview,
    BackgroundSectionPreivew,
    PaperSectionPreview,
    LeftPaperBox,
    RightPaperBox,
    FooterModal,
    SaveButton,
    CancelButton,
    PreviewSection,
    GloablTextColor,
    BoxOfControllers,
    HeaderGridTextColor,
    
} from './ThemeStyleComponent'
import { xyz } from '../Source';

import ApartmentIcon from '@mui/icons-material/Apartment';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Host from "../../../assets/js/Host";
import { toast } from "react-toastify";
import axios from "axios";
import Cookies from "universal-cookie";
import GTranslateIcon from '@mui/icons-material/GTranslate';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Input from '@mui/material/Input';
import debounce from 'lodash.debounce'
import {useTranslation} from "react-i18next";
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';

import ReactDOM from 'react-dom';
import { ColorPicker } from 'react-color-gradient-picker';
import 'react-color-gradient-picker/dist/index.css';

const cookies = new Cookies();
const language = cookies.get("language") ? cookies.get("language") : "ar";
const drawerWidth = 240;

const gradient = {
  points: [
      {
          left: 0,
          red: 0,
          green: 0,
          blue: 0,
          alpha: 1,
      },
      {
          left: 100,
          red: 255,
          green: 0,
          blue: 0,
          alpha: 1,
      },
  ],
  degree: 0,
  type: 'linear',
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme ,MainTheme}) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:MainTheme?.paperTextColor,
    backgroundColor:MainTheme?.paperColor,
    '& svg':{
      color:`${MainTheme?.paperTextColor} !important`,
    },
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
      color:MainTheme?.paperTextColor,
    },
    '& .MuiTypography-root': {
    color:MainTheme?.paperTextColor,
     
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },

  },
}));

const CustomeLightOrDarkTheme=styled(Tab)(({theme,color,bgColor})=>({
  color:`${color} !important`,
  backgroundColor:bgColor,
  flex:1,
  height:"100%",
  cursor:"pointer",
  display:"flex",
  justifyContent:"center",
  alignItems:"center",
  objectFit:"cover",
  fontWeight:"bold",
  // borderRadius:"25px",
  border:'none !important',
  outline:"none !important",
}))
const CustomeLinearGradiantColor=styled(Box)(({theme,linearGradiant})=>({
     width:'100px',
     height:'100px',
     borderRadius:'50%',
     marginLeft:'30px',
     background:linearGradiant,
     cursor:'pointer',
}))

// const GloablTextColor=styled(Input)(({theme})=>({
//   borderRadius:" 5px",
//   margin: "10px 10px;",
//   outline: `1px solid ${props=>props.color};`,
//   position: 'relative',
//   width:'200px',
//   height:'50px',
//   padding:'12px 4px',
//   backgroundColor:'transparent',
//   display:'flex',
//   justifyContent: 'center',
//   cursor:'pointer',
//   alignItems: 'center',
//   opacity:`${props=>props.disabeldSection?0.5:1}`,
//  '&:before':{
//       position: 'absolute',
//       content: 'GloablText Color',
//       backgroundColor:"${props=>props.bgColor}",
//       color:"${props=>props.color}",
//       top:"-12px",
//       left:" 10px",
//   },
// }))

export default function ThemePalette() {
  const [t, i18n] = useTranslation('common');
  const MainPaletteShow= useSelector(state=> state.settingsData.MainPaletteShow)
  const SideMenuPaletteShow= useSelector(state=> state.settingsData.SideMenuPaletteShow)
  const ToolBarPaletteShow= useSelector(state=> state.settingsData.ToolBarPaletteShow)
  const GridPaletteShow= useSelector(state=> state.settingsData.GridPaletteShow)
  const MainTheme_Store= useSelector(state=> state.settingsData.MainTheme)
  const SideMenuTheme_Store= useSelector(state=> state.settingsData.SideMenuTheme)
  const ToolbarTheme_store= useSelector(state=> state.settingsData.ToolbarTheme)
  const GridTheme_store= useSelector(state=> state.settingsData.GridTheme)
  const token=cookies.get('token')
  const [loading,setLoading]=useState(false)
  const [ThemesList,setThemesList]=useState([])
  const [ThemesListSections,setThemesListSections]=useState([])
  const [ThemesListSectionsKeys,setThemesListSectionsKeys]=useState([])
  const [ThemesListSectionsKeysValues,setThemesListSectionsKeysValues]=useState([])
  const [sectionId,setSectionId]=useState(2)
  const forceReloadTheme=useSelector(state=> state.settingsData.forceReloadTheme)
  const [openGradientKit,setOpenGradientKit]=useState(false)
  // const [colorAttrs, setColorAttrs] = useState(color);
  const [gradientAttrs, setGradientAttrs] = useState(gradient);



  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [changeTextColorWithBG,setChangeTextColorWithBG]=useState(false);
  const [changeTextColorWithPaper,setChangeTextColorWithPaper]=useState(false);

  // const [disabledGlobalTextColor,setDisabledGlobalTextColor]=useState(false);
  const directions= useSelector(state=> state.settingsData.directions)
  const [MainTheme,setMainTheme_settings]=useState(null);
  const [darkOrLightColor,setDarkOrLightColor]=useState({});

  
let data=  useMemo(()=>{
      if(MainPaletteShow==true){
        setMainTheme_settings(MainTheme_Store);
        setDarkOrLightColor(MainTheme_Store)
        setSectionId(2)
      }else if(SideMenuPaletteShow==true){
        setMainTheme_settings(SideMenuTheme_Store);
        setDarkOrLightColor(SideMenuTheme_Store)
        setSectionId(4)

      }else if(GridPaletteShow==true){
        setMainTheme_settings(GridTheme_store);
        setDarkOrLightColor(GridTheme_store)
        setSectionId(5)

      }else if(ToolBarPaletteShow==true){
        setMainTheme_settings(ToolbarTheme_store);
        setDarkOrLightColor(ToolbarTheme_store)
        setSectionId(3)

      }

  },[
    MainTheme_Store,
    SideMenuTheme_Store,
    MainPaletteShow,
    SideMenuPaletteShow,
    ToolbarTheme_store,
    ToolBarPaletteShow,
    GridPaletteShow,
    GridTheme_store,
  ])


  const [anchorEl, setAnchorEl] = React.useState(null);
  const open_settings = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose_settings = (dire) => {
    setAnchorEl(null);
  };
  // initiateSettingsFromStore();
  // useEffect()
  const timer = React.useRef(null)

  const [BgMessageColor,setBgMessageColor]=useState('')
  const [PaperMessageColor,setPaperMessageColor]=useState('')

 
  const [openPrimaryColor,setOpenPrimaryColor]=useState(false);

  const dispatch=useDispatch();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);


  const handleClose = () => {
     if(MainPaletteShow){
        dispatch(setMainPaletteShow(false))
     }else if(SideMenuPaletteShow){
        dispatch(setSideMenuPaletteShow(false))
     }else if(ToolBarPaletteShow){
        dispatch(setToolBarPaletteShow(false))
     }else if(GridPaletteShow){
        dispatch(setGridPaletteShow(false))
     }
  };

  const OpenColorPicker=(e)=>{

    var x;
    var y;

    x = e.pageX;
    y = e.pageY;


    console.log(e);
    e.target.parentElement.querySelector('.colorPicker').style.position = "absolute";
    e.target.parentElement.querySelector('.colorPicker').setAttribute('left',`left:${30}px;`);
    e.target.parentElement.querySelector('.colorPicker').top= e.target.style.top;

    e.target.parentElement.querySelector('.colorPicker').click();
     
  }

  //get all themes from backend....
  const getAllThemes= async () => {

    try {
        setLoading(true)
        const resourcesResult = await axios({
            url: Host + "themes_list",
            method: "get",
            headers: {
                "Authorization": `Bearer ${token}`,
                Accept: "application/json",
                'X-localization': language,
            },
        })
        if (resourcesResult) {
            if (resourcesResult.status === 200) {
              if(resourcesResult?.data)
               {
                setThemesList(resourcesResult?.data)
               }
            } else if (resourcesResult.data.status === false) {
            }
        }
        else {
            toast.error("Error")
        }
        setLoading(false)
      
    } catch (error) {
        console.log("error",error);
        setLoading(false)
        const resourcesResult = error.response
        if (resourcesResult && resourcesResult.status === 400) {
            if (typeof resourcesResult.data.data === "object") {
                const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                errorMessagesKeys.map((key) => {

                    resourcesResult.data.data[key].map((message) => {
                        toast.error(message)
                    })
                })
            }
            else {

                toast.error(resourcesResult.data.data)
            }


        }
        else if (resourcesResult && resourcesResult.status === 401) {

            toast.error(resourcesResult.data.data)
            cookies.remove("token");
            window.location.href = "/"
        }
        else {

            toast.error("network error")
        }
    }

  };

  const getAllThemesForSections= async () => {
    let section_id=localStorage.getItem('section_id');
   
   if(section_id){
    try {
        setLoading(true)
        const resourcesResult = await axios({
            url: Host + "theme_section_keys_list?theme_section_id="+section_id,
            method: "get",
            headers: {
                "Authorization": `Bearer ${token}`,
                Accept: "application/json",
                'X-localization': language,
            },
        })
        if (resourcesResult) {
            if (resourcesResult.status === 200) {
              if(resourcesResult?.data)
               {
                setThemesListSectionsKeys(resourcesResult?.data)
               }
            } else if (resourcesResult.data.status === false) {
            }
        }
        else {
            toast.error("Error")
        }
        setLoading(false)
      
    } catch (error) {
        console.log("error",error);
        setLoading(false)
        const resourcesResult = error.response
        if (resourcesResult && resourcesResult.status === 400) {
            if (typeof resourcesResult.data.data === "object") {
                const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                errorMessagesKeys.map((key) => {

                    resourcesResult.data.data[key].map((message) => {
                        toast.error(message)
                    })
                })
            }
            else {

                toast.error(resourcesResult.data.data)
            }


        }
        else if (resourcesResult && resourcesResult.status === 401) {

            toast.error(resourcesResult.data.data)
            cookies.remove("token");
            window.location.href = "/"
        }
        else {

            toast.error("network error")
        }
    }
  }

  };

  useEffect(()=>{
    getAllThemes();
    getAllThemesForSections()
  },[
    MainPaletteShow,
    SideMenuPaletteShow,
    ToolBarPaletteShow,
    GridPaletteShow,
  ])
  const [ColorValue,setColorValue]=useState('')
  const [ColorValueKey,setColorValueKey]=useState('')

  const TImerController=React.useRef(null)
  const setThemeOfSelectedPalette=debounce((e,nameKey)=>{
      //  e.persist();
      // const { name, value } = e.target
      let oppositePrimaryColor=MainTheme?.oppositePrimaryColor;
      let oppositeSecondaryColor=MainTheme?.oppositeSecondaryColor;
      let bgTextColor=MainTheme?.bgTextColor;
      let paperTextColor=MainTheme?.paperTextColor;
      // let gloablTextColor=MainTheme?.gloablTextColor;

      // if(TImerController.current){
      //   clearTimeout(TImerController.current)
      // }

      // TImerController.current=setTimeout(()=>{
      //   setColorValue(e)
      //   setColorValueKey(nameKey)
      // },500);
    

      // let gloablTextColor=MainTheme?.gloablTextColor;
      let check=helpers?.checkDarknessOrLightnessForColor(e);
      // setBgMessageColor('')
      // setPaperMessageColor('');

      if(nameKey ==='primaryColor'){
         if(!GridPaletteShow&&!MainPaletteShow)
          oppositePrimaryColor=check?'#ffffff':'#000000';
      }else if(nameKey=== 'secondaryColor'){
          oppositeSecondaryColor=check?'#ffffff':'#000000';
      }
      if(!GridPaletteShow&&!MainPaletteShow){
        setMainTheme_settings({
          ...MainTheme,
          [nameKey]:e,
          oppositePrimaryColor:oppositePrimaryColor,
          oppositeSecondaryColor:oppositeSecondaryColor,
        })
      }else{
        setMainTheme_settings({
          ...MainTheme,
          [nameKey]:e,
          // oppositePrimaryColor:oppositePrimaryColor,
          oppositeSecondaryColor:oppositeSecondaryColor,
        })
      }

      // if(nameKey=== 'paperColor'){
      //     if(check&&MainTheme?.light){
      //       setPaperMessageColor(directions=='rtl'?'يجب ان يكون اللون ابيض':'must be light  color');
      //     }else if(!check&&MainTheme?.dark){
      //       setPaperMessageColor(directions=='rtl'?'يجب ان يكون اللون اسود':'must be dark color');
      //     }
      // }else if(nameKey=== 'bgColor'){
      //   if(check&&MainTheme?.light){
      //     setBgMessageColor(directions=='rtl'?'يجب ان يكون اللون ابيض':'must be light  color');
      //   }else if(!check&&MainTheme?.dark){
      //     setBgMessageColor(directions=='rtl'?'يجب ان يكون اللون اسود':'must be dark color');
      //   }
      // }
      
      // if(!GridPaletteShow){

      // }else{
      //   setMainTheme_settings({
      //     ...MainTheme,
      //     [name]:e.target.value,
      //     oppositeSecondaryColor:oppositeSecondaryColor,
      //     gloablTextColor:gloablTextColor,
      //   })
      // }
  },80)


  const choiceDarkOrLightTheme=(Boolean,name1,name2)=>{
    let bgTextColor=MainTheme?.bgTextColor;
    let paperTextColor=MainTheme?.paperTextColor;
    let paperColor=MainTheme?.paperColor;
    let bgColor=MainTheme?.bgColor;

     if(name1=='light'){
      bgTextColor="#000000";
      paperTextColor="#000000";
      paperColor="#ffffff";
      bgColor="#ffffff";
     }else{
      bgTextColor="#ffffff";
      paperTextColor="#ffffff";
      paperColor="#000000";
      bgColor="#000000";
     }

    // setMainTheme_settings({
    //   ...MainTheme,
    //   [name1]:Boolean,
    //   [name2]:false,
    //   bgTextColor:bgTextColor,
    //   paperTextColor:paperTextColor,
    //   paperColor:paperColor,
    //   bgColor:bgColor,

    // })
  if(MainPaletteShow){
    dispatch(setMainTheme({
      ...MainTheme,
      [name1]:Boolean,
      [name2]:false,
      bgTextColor:bgTextColor,
      paperTextColor:paperTextColor,
      paperColor:paperColor,
      bgColor:bgColor,
    }))
  }else if(SideMenuPaletteShow){
    dispatch(setSideMenuTheme({
      ...MainTheme,
      [name1]:Boolean,
      [name2]:false,
      bgTextColor:bgTextColor,
      paperTextColor:paperTextColor,
      paperColor:paperColor,
      bgColor:bgColor,
    }))
  }else if(GridPaletteShow){
    dispatch(setGridTheme({
      ...MainTheme,
      [name1]:Boolean,
      [name2]:false,
      bgTextColor:bgTextColor,
      paperTextColor:paperTextColor,
      paperColor:paperColor,
      bgColor:bgColor,
    }))
  }else if(ToolBarPaletteShow){
    dispatch(setToolbarTheme({
      ...MainTheme,
      [name1]:Boolean,
      [name2]:false,
      bgTextColor:bgTextColor,
      paperTextColor:paperTextColor,
      paperColor:paperColor,
      bgColor:bgColor,
    }))
  }
    
  }
  
  const getDataFormat=()=>{
    let arr=[];
    ThemesListSectionsKeys&&ThemesListSectionsKeys?.length>0&&ThemesListSectionsKeys?.map((itm)=>{
         
        if(itm?.name=='primaryColor'){
          arr.push({
            id:itm?.theme_section_values?itm?.theme_section_values[0]?.id:'',
            theme_section_key_id:itm.id,
            theme_id:itm?.theme_section_values?itm?.theme_section_values[0]?.theme_id:'',
            value:MainTheme?.primaryColor,
          })
        }else if(itm?.name=='oppositePrimaryColor'){
          arr.push({
            id:itm?.theme_section_values?itm?.theme_section_values[0]?.id:'',
            theme_section_key_id:itm.id,
            theme_id:itm?.theme_section_values?itm?.theme_section_values[0]?.theme_id:'',
            value:MainTheme?.oppositePrimaryColor,
          })
        }else if(itm?.name=='secondaryColor'){
          arr.push({
            id:itm?.theme_section_values?itm?.theme_section_values[0]?.id:'',
            theme_section_key_id:itm.id,
            theme_id:itm?.theme_section_values?itm?.theme_section_values[0]?.theme_id:'',
            value:MainTheme?.secondaryColor,
          })
        }else if(itm?.name=='secondaryColor'){
          arr.push({
            id:itm?.theme_section_values?itm?.theme_section_values[0]?.id:'',
            theme_section_key_id:itm.id,
            theme_id:itm?.theme_section_values?itm?.theme_section_values[0]?.theme_id:'',
            value:MainTheme?.oppositeSecondaryColor,
          })
        }else if(itm?.name=='gloablTextColor'){
          arr.push({
            id:itm?.theme_section_values?itm?.theme_section_values[0]?.id:'',
            theme_section_key_id:itm.id,
            theme_id:itm?.theme_section_values?itm?.theme_section_values[0]?.theme_id:'',
            value:MainTheme?.gloablTextColor,
          })
        }else if(itm?.name=='paperColor'){
          arr.push({
            id:itm?.theme_section_values?itm?.theme_section_values[0]?.id:'',
            theme_section_key_id:itm.id,
            theme_id:itm?.theme_section_values?itm?.theme_section_values[0]?.theme_id:'',
            value:MainTheme?.paperColor,
          })
        }else if(itm?.name=='paperTextColor'){
          arr.push({
            id:itm?.theme_section_values?itm?.theme_section_values[0]?.id:'',
            theme_section_key_id:itm.id,
            theme_id:itm?.theme_section_values?itm?.theme_section_values[0]?.theme_id:'',
            value:MainTheme?.paperTextColor,
          })
        }else if(itm?.name=='bgColor'){
          arr.push({
            id:itm?.theme_section_values?itm?.theme_section_values[0]?.id:'',
            theme_section_key_id:itm.id,
            theme_id:itm?.theme_section_values?itm?.theme_section_values[0]?.theme_id:'',
            value:MainTheme?.bgColor,
          })
        }else if(itm?.name=='bgTextColor'){
          arr.push({
            id:itm?.theme_section_values?itm?.theme_section_values[0]?.id:'',
            theme_section_key_id:itm.id,
            theme_id:itm?.theme_section_values?itm?.theme_section_values[0]?.theme_id:'',
            value:MainTheme?.bgTextColor,
          })
        }else if(itm?.name=='disabledGlobalText'){
          arr.push({
            id:itm?.theme_section_values?itm?.theme_section_values[0]?.id:'',
            theme_section_key_id:itm.id,
            theme_id:itm?.theme_section_values?itm?.theme_section_values[0]?.theme_id:'',
            value:`${MainTheme?.disabledGlobalText}`,
          })
        }else if(itm?.name=='light'){
          arr.push({
            id:itm?.theme_section_values?itm?.theme_section_values[0]?.id:'',
            theme_section_key_id:itm.id,
            theme_id:itm?.theme_section_values?itm?.theme_section_values[0]?.theme_id:'',
            value:`${MainTheme?.light}`,
          })
        }else if(itm?.name=='dark'){
          arr.push({
            id:itm?.theme_section_values?itm?.theme_section_values[0]?.id:'',
            theme_section_key_id:itm.id,
            theme_id:itm?.theme_section_values?itm?.theme_section_values[0]?.theme_id:'',
            value:`${MainTheme?.dark}`,
          })
        }else if(itm?.name=='isLinearGradient'){
          arr.push({
            id:itm?.theme_section_values?itm?.theme_section_values[0]?.id:'',
            theme_section_key_id:itm.id,
            theme_id:itm?.theme_section_values?itm?.theme_section_values[0]?.theme_id:'',
            value:`${MainTheme?.isLinearGradient}`,
          })
        }else if(itm?.name=='linearGradientBgColor'){
          arr.push({
            id:itm?.theme_section_values?itm?.theme_section_values[0]?.id:'',
            theme_section_key_id:itm.id,
            theme_id:itm?.theme_section_values?itm?.theme_section_values[0]?.theme_id:'',
            value:`${MainTheme?.linearGradientBgColor}`,
          })
        }
       
    })
    return arr;
  }
  const saveSettingsTheme=()=>{
    console.log('done',MainTheme);
    if(!PaperMessageColor&&!BgMessageColor){
      if(MainPaletteShow){
        dispatch(setMainTheme(MainTheme))
      }else if(SideMenuPaletteShow){
        dispatch(setSideMenuTheme(MainTheme))
      }else if(GridPaletteShow){
        dispatch(setGridTheme(MainTheme))
      }else if(ToolBarPaletteShow){
        dispatch(setToolbarTheme(MainTheme))
      }
    }
    let data=getDataFormat()
    if(data?.length>0)
     StoreDataOfSearchSectionTheme(data)

    handleClose()
  }



  const disabledGlobalTextColor_set=(e)=>{
    // setDisabledGlobalTextColor(e.target.checked)
    if(GridPaletteShow){
      dispatch(setGridTheme({
        ...MainTheme,
        disabledGlobalText:e.target.checked
      }))
    }else if(ToolBarPaletteShow){
      dispatch(setToolbarTheme({
        ...MainTheme,
        disabledGlobalText:e.target.checked
      }))
    }else if(MainPaletteShow){
      dispatch(setMainTheme({
        ...MainTheme,
        disabledGlobalText:e.target.checked
      }))
    }else if(SideMenuPaletteShow){
      dispatch(setSideMenuTheme({
        ...MainTheme,
        disabledGlobalText:e.target.checked
      }))
    }
  }

  const getFirstWord=(value)=>{
    let name=value
      name=name?.split(' ');
      name=name?.length>0?name[0]:''
      return name;
  }


  const StoreDataOfSearchSectionTheme= async (data) => {

    try {
        setLoading(true)
        const resourcesResult = await axios({
            url: Host + "theme_section_values/bulk",
            method: "put",
            headers: {
                "Authorization": `Bearer ${token}`,
                Accept: "application/json",
                'X-localization': language,
            },
            data:{
              theme_values:data
            },
        })
        if (resourcesResult) {
            if (resourcesResult.status === 200) {
              if(resourcesResult?.data)
               {
                dispatch(setForceReloadTheme(!forceReloadTheme))
                getAllThemesForSections()

               }
            } else if (resourcesResult.data.status === false) {
            }
        }
        else {
            toast.error("Error")
        }
        setLoading(false)
      
    } catch (error) {
        console.log("error",error);
        setLoading(false)
        const resourcesResult = error.response
        if (resourcesResult && resourcesResult.status === 400) {
            if (typeof resourcesResult.data.data === "object") {
                const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                errorMessagesKeys.map((key) => {

                    resourcesResult.data.data[key].map((message) => {
                        toast.error(message)
                    })
                })
            }
            else {

                toast.error(resourcesResult.data.data)
            }


        }
        else if (resourcesResult && resourcesResult.status === 401) {

            toast.error(resourcesResult.data.data)
            cookies.remove("token");
            window.location.href = "/"
        }
        else {

            toast.error("network error")
        }
    }

  };

  const changeBgType=(status)=>{
        handleClose_settings()
        setMainTheme_settings({
          ...MainTheme,
          isLinearGradient:status,
        })
  }

  const onChangeGradientColor = debounce((gradientAttrs) => {
    console.log('gradientColorjkkkk=>',gradientAttrs)
    setGradientAttrs(gradientAttrs);
    if(gradientAttrs&&gradientAttrs?.style)
      setMainTheme_settings({
        ...MainTheme,
        linearGradientBgColor:gradientAttrs?.style,
      })
  },500);


  return (
    <div style={{position:'relative'}}>

          


        <Modal
            open={MainPaletteShow||SideMenuPaletteShow||ToolBarPaletteShow||GridPaletteShow}
            onClose={()=>handleClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{display: "flex",justifyContent: "center",alignItems: "center",border:'none',backgroundColor:'transparent'}}
        >
            <ContainerModal bgColor={MainTheme_Store?.paperColor} color={MainTheme_Store?.paperTextColor}>
                <HeaderModal bgColor={MainTheme_Store?.secondaryColor} color={MainTheme_Store?.oppositeSecondaryColor}>
                        <div>
                          <PaletteIcon className="iconePlate" bgColor={MainTheme_Store?.primaryColor} color={MainTheme_Store?.oppositePrimaryColor}/>
                          <span>Edit Palette ({localStorage.getItem('palette_title')})</span>
                        </div>
                        {
                        /* {
                         //GridPaletteShow||ToolBarPaletteShow||MainPaletteShow||SideMenuPaletteShow?
                         <SettingsIcon className="settings" onClick={handleClick}/>
                         :null
                        } */
                        }
                        {/* {
                          GridPaletteShow||ToolBarPaletteShow||MainPaletteShow||SideMenuPaletteShow?
                          <StyledMenu
                          MainTheme={MainTheme_Store}
                          id="demo-customized-menu"
                          MenuListProps={{
                            'aria-labelledby': 'demo-customized-button',
                          }}
                          anchorEl={anchorEl}
                          open={open_settings}
                          onClose={handleClose_settings}
                        >
                        
                          <MenuItem sx={{color: MainTheme_Store?.paperTextColor}} onClick={()=>handleClose_settings()} disableRipple className={`${directions!=='rtl'?'':'languageNormal arabicDirection'}`}>
                          
                            <FormControlLabel
                              style={{color: MainTheme_Store?.paperTextColor}}
                              labelStyle={{color: MainTheme_Store?.paperTextColor}}
                              label="Allow Global Text Color"
                              control={
                                <Checkbox
                                  labelStyle={{color: MainTheme_Store?.paperTextColor}}
                                  checked={MainTheme?.disabledGlobalText}
                                  onChange={disabledGlobalTextColor_set}
                                  sx={{color: MainTheme_Store?.paperTextColor,}}
                                />
                              }
                            />
                          
                          </MenuItem>

                          </StyledMenu>
                        :null
                        } */}
                </HeaderModal>
               {
                openGradientKit?
                <Box
                 sx={{
                  width: '100vw',
                  height: '100vh',
                  position:"fixed",
                  zIndex:50000,
                  left:'0',
                  top:'0',
                  bottom:'0',
                  right:'0',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backdropFilter:'blur(5px)'
                 }}
                 
                >
                  <Box
                   sx={{
                    width:'fit-content',  
                    height:'fit-content',
                    boxShadow:'0 0 7px -1px #000',
                    padding:'0 !important',
                    margin:'0 !important',
                    '& .ui-color-picker':{
                      margin:'0 !important',
                    },
                    zIndex:50002,

                   }}
                  >
                    <ColorPicker
                        onStartChange={onChangeGradientColor}
                        onChange={onChangeGradientColor}
                        onEndChange={onChangeGradientColor}
                        gradient={gradientAttrs}
                        isGradient
                        
                    />
                  </Box>

                  <Box
                    sx={{
                      position: 'absolute',
                      top:0,
                      left:0,
                      right:0,
                      bottom:0,
                      width:'100vw',
                      height:'100vh',
                      zIndex:50001,

                      
                    }}
                    onClick={()=>{
                      setOpenGradientKit(false)
                     }}
                  ></Box>
                 
                </Box>
                :null
               }
               
                {/* <LightOrDarkmode directions={directions}>
                    <LightMode directions={directions} bgColor={darkOrLightColor.light?MainTheme_Store?.secondaryColor:"#ffffff"} color={darkOrLightColor.light?MainTheme_Store?.oppositeSecondaryColor:"#4F46E5"} onClick={()=>choiceDarkOrLightTheme(true,'light','dark')} >Light</LightMode>
                    <DarkMode directions={directions}  bgColor={darkOrLightColor.dark?MainTheme_Store?.secondaryColor:"#ffffff"} color={darkOrLightColor.dark?MainTheme_Store?.oppositeSecondaryColor:"#4F46E5"} onClick={()=>choiceDarkOrLightTheme(true,'dark','light')}>Dark</DarkMode>
                
                </LightOrDarkmode> */}
                
                    {/* <Box
                      sx={{
                        // flexGrow: 1,
                        // maxWidth: { xs: 320, sm: 480 },
                        width:'250px',
                        bgcolor: 'background.paper',
                        borderRadius:"30px",
                        position:"relative",
                        top:"57px",
                        objectFit:"cover",
                        left:`${directions=='rtl'?'94px':'94px'}`,
                        overflow:'hidden',
                        display:'flex',
                        alignItems:'center',
                        border:"1px solid #4F46E5",
                        padding:0,
                        '& .muiltr-1aquho2-MuiTabs-indicator,& .muirtl-1aquho2-MuiTabs-indicator':{
                          backgroundColor:'#ffffff !important',
                          display:'none !important',
                        }
                      }}
                    >
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons
                        aria-label="visible arrows tabs example"
                        sx={{
                          width:'100%',
                          [`& .${tabsClasses.scrollButtons}`]: {
                            '&.Mui-disabled': { opacity: 0.3 },
                          },
                          color:'#000000',
                        }}
                      >{
                        ThemesList&&ThemesList?.length>0&&ThemesList?.map((theme)=>(
                          
                            theme?.name=='Dark theme'?
                              <CustomeLightOrDarkTheme label={getFirstWord(theme?.name)} directions={directions}  bgColor={darkOrLightColor.dark?MainTheme_Store?.secondaryColor:"#ffffff"} color={darkOrLightColor.dark?MainTheme_Store?.oppositeSecondaryColor:"#4F46E5"} onClick={()=>choiceDarkOrLightTheme(true,'dark','light')} />
                            :theme?.name=='Light theme'?
                              <CustomeLightOrDarkTheme label={getFirstWord(theme?.name)}  directions={directions} bgColor={darkOrLightColor.light?MainTheme_Store?.secondaryColor:"#ffffff"} color={darkOrLightColor.light?MainTheme_Store?.oppositeSecondaryColor:"#4F46E5"} onClick={()=>choiceDarkOrLightTheme(true,'light','dark')} />
                            :null
                        ))
                      }
                       
                  
                      </Tabs>
                    </Box> */}
                
                <ContentModal>
                         
                       <LeftSide>
                             {
                              GridPaletteShow||ToolBarPaletteShow||MainPaletteShow||SideMenuPaletteShow?
                               <GloablTextColor
                                type="color" 
                                bgColor={MainTheme_Store?.paperColor}
                                color={MainTheme_Store?.disabledGlobalText?MainTheme_Store?.gloablTextColor:MainTheme_Store?.paperTextColor}
                                value={MainTheme?.gloablTextColor||"#fff"}
                                onChange={(event)=>{setThemeOfSelectedPalette(event?.target?.value,'gloablTextColor')}}
                                disabled={!MainTheme?.disabledGlobalText}
                                disabeldSection={!MainTheme?.disabledGlobalText}
                               />

                               
                              :null
                             }
                            <PrimeryColorForText 
                               type="color" 
                               bgColor={MainTheme_Store?.paperColor}
                               color={MainTheme_Store?.disabledGlobalText?MainTheme_Store?.gloablTextColor:MainTheme_Store?.paperTextColor}
                               value={MainTheme?.primaryColor||"#fff"}
                               onChange={(e)=>{setThemeOfSelectedPalette(e.target.value,'primaryColor')}}
                            />

                            {
                              GridPaletteShow||MainPaletteShow?
                              <HeaderGridTextColor 
                                type="color" 
                                bgColor={MainTheme_Store?.paperColor}
                                color={MainTheme_Store?.disabledGlobalText?MainTheme_Store?.gloablTextColor:MainTheme_Store?.paperTextColor}
                                value={MainTheme?.oppositePrimaryColor||"#fff"}
                                onChange={(e)=>{setThemeOfSelectedPalette(e.target.value,'oppositePrimaryColor')}}
                                colorSide={true}
                               />
                              :null
                            }
                         

                            <SecondaryColorForText
                               type="color" 
                               bgColor={MainTheme_Store?.paperColor}
                               color={MainTheme_Store?.disabledGlobalText?MainTheme_Store?.gloablTextColor:MainTheme_Store?.paperTextColor}
                               value={MainTheme?.secondaryColor||"#fff"}
                               onChange={(e)=>{setThemeOfSelectedPalette(e.target.value,'secondaryColor')}}
                            />
                            <div>
                                  <BackgroundPaperColor 
                                      type="color" 
                                      bgColor={MainTheme_Store?.paperColor}
                                      color={MainTheme_Store?.disabledGlobalText?MainTheme_Store?.gloablTextColor:MainTheme_Store?.paperTextColor}
                                      value={MainTheme?.paperColor||"#fff"}
                                      borderColor={PaperMessageColor?"#f00":MainTheme_Store?.disabledGlobalText?MainTheme_Store?.gloablTextColor:MainTheme_Store?.paperTextColor}
                                      messageError={PaperMessageColor}
                                    onChange={(e)=>{setThemeOfSelectedPalette(e.target.value,'paperColor')}}
                                  />
                                  <p style={{color:'#f00',position:'relative',top:'-5px',display:'flex',justifyContent:directions=='rtl'?'start':"start",}}>{PaperMessageColor}</p>
                            </div>
                            <div
                             style={{
                              width:'100%',
                              display:'flex',
                              justifyContent:'start',
                              flexDirection: 'row-reverse',
                              alignItems: 'center',
                             }}
                            >
                                {
                                  MainPaletteShow?
                                  <Tooltip title={t('GLOBAL.CHANGE_BGACKGROUND_COLOR_MODE')} placement="bottom">
                                      <Button
                                    id="basic-button"
                                    aria-controls={open_settings ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open_settings ? 'true' : undefined}
                                    onClick={handleClick}
                                    sx={{
                                      margin:'0 10px'
                                    }}
                                  >
                                    <AutoAwesomeMosaicIcon sx={{color:MainTheme?.secondaryColor+' !important',fill:MainTheme?.secondaryColor+' !important'}}/>
                                  </Button>
                                  </Tooltip>:null
                                }
                                 {
                                  !MainTheme?.isLinearGradient?
                                  <BackgroundColor 
                                    type="color" 
                                    bgColor={MainTheme_Store?.paperColor}
                                    color={MainTheme_Store?.disabledGlobalText?MainTheme_Store?.gloablTextColor:MainTheme_Store?.paperTextColor}
                                    value={MainTheme?.bgColor||"#fff"}
                                    borderColor={BgMessageColor?"#f00":MainTheme_Store?.disabledGlobalText?MainTheme_Store?.gloablTextColor:MainTheme_Store?.paperTextColor}
                                    messageError={BgMessageColor}
                                    onChange={(e)=>{setThemeOfSelectedPalette(e.target.value,'bgColor')}}
                                  />
                                  // <p style={{color:'#f00',position:'relative',top:'-5px',justifyContent:directions=='rtl'?'start':"start",}}>{BgMessageColor}</p>
                                  :
                                  <CustomeLinearGradiantColor 
                                  linearGradiant={MainTheme?.linearGradientBgColor}
                                  onClick={()=>{
                                    setOpenGradientKit(true)
                                  }}
                                  ></CustomeLinearGradiantColor>
                                  }
                                  
                                  
                                  <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open_settings}
                                    onClose={handleClose_settings}
                                    MenuListProps={{
                                      'aria-labelledby': 'basic-button',
                                    }}
                                  >
                                    <MenuItem onClick={()=>changeBgType(false)}>{t('GLOBAL.LINEAR')}</MenuItem>
                                    <MenuItem onClick={()=>changeBgType(true)}>{t('GLOBAL.LINEAR_GRADIANT')}</MenuItem>
                                  </Menu>
                          
                          
                          
                            </div>
                       </LeftSide>

                       <RightSide>
                            <PreviewSection  color={MainTheme_Store?.paperTextColor}>Preview</PreviewSection>
                           <PreviewBox bgColor={MainTheme_Store?.paperColor} color={MainTheme_Store?.paperTextColor}>
                                <TopSectionPreview bgColor={MainTheme?.primaryColor} color={MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme?.oppositePrimaryColor}>
                                      <p>Header (Primary)</p>
                                </TopSectionPreview>
                                <BackgroundSectionPreivew bgColor={MainTheme?.bgColor} color={MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme?.bgTextColor} >
                                     <p style={{position: 'absolute',bottom:'1%',left:'3%'}}>background</p>
                                </BackgroundSectionPreivew>
                                <PaperSectionPreview >
                                       <LeftPaperBox bgColor={MainTheme?.paperColor} color={MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme?.paperTextColor}>
                                          <p>paper</p>
                                       </LeftPaperBox >
                                       <RightPaperBox bgColor={MainTheme?.secondaryColor} color={MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme?.oppositeSecondaryColor}>s</RightPaperBox>
                                </PaperSectionPreview>
                           </PreviewBox>

                       </RightSide>
                </ContentModal>

                <FooterModal>
                    <CancelButton directions={directions} bgColor={MainTheme_Store?.secondaryColor} color={MainTheme_Store?.oppositeSecondaryColor} onClick={handleClose}>
                            <span>Cancel</span>
                    </CancelButton>
                    <SaveButton directions={directions} disabled={PaperMessageColor||BgMessageColor?true:false} bgColor={MainTheme_Store?.secondaryColor} color={MainTheme_Store?.oppositeSecondaryColor} onClick={()=>saveSettingsTheme()}>
                        <span>Save</span>
                    </SaveButton>
                    
                </FooterModal>

            </ContainerModal>
        </Modal>
      </div>
  );
}