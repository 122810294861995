import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useTranslation} from "react-i18next";


export default function CustomeSelectBox({checkNameExistsInSearch,label,value,setValue,data,customeWidth,setSelectedValueID,hasValue,selectedKeyValue,setSelectedKeyValue,hasClicked}) {
  const {t, i18n} = useTranslation('common');

    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setValue(event.target.value);
    };
    const handleClick=(name,id)=>{
      if(hasClicked){
        if(hasValue&&hasValue==1)
          setSelectedKeyValue(name)
        else if(hasValue&&hasValue==2){
          setSelectedKeyValue(name)
          setSelectedValueID(id)
        }
        else if(hasValue&&hasValue==3){
          setSelectedKeyValue(name)
        }
       }
      
    }

  return (
    <Box sx={{ minWidth: customeWidth }}>
      <FormControl fullWidth variant="standard">
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={label}
          onChange={handleChange}
          sx={{
            textAlign:'left',
          }}
        >
            {
                data&&data?.length>0?data?.map((itm)=>{
                  if(checkNameExistsInSearch(itm?.name)){
                    return <MenuItem value={itm?.id} onClick={()=>{
                      if(hasValue&&hasValue==1)
                      handleClick(itm?.name)
                      else if(hasValue&&hasValue==2){
                         handleClick(itm?.value_name,itm?.value_id)
                      } else if(hasValue&&hasValue==3){
                        handleClick(itm?.name)
                     }

                    }}>{itm?.name}</MenuItem>
                  }
                }):
                <span>{t('SETTINGS.NO_DATA')}</span>
            }
        </Select>
      </FormControl>
    </Box>
  )
}
