import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Pane, Dialog } from 'evergreen-ui';
import axios from "axios";
import Select from "react-select";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import DialogForm from '../Share/DialogForm'
import FooterBottons from "../Share/FooterBottons";
import "react-datepicker/dist/react-datepicker.css";
import { Table } from "react-bootstrap";
import {useSelector,useDispatch} from 'react-redux'

import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { point } from "leaflet";
import { useGlobalContext } from '../../../context'
import {useTranslation} from "react-i18next";


const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"
export const UserDailyPlanForm = (props) => {
    const {loading,setRegions,userDailyPlans,setPoints,setDistricts,user,setUserDailyPlans,pointsStatuses,pointStatus,setPointStatus,exportToCSV,allPoints,points,district,region,districts,regions,ownerName,shopName,clearPointsAdvanceSearchFields,setRegion,setDistrict,setShopName,setOwnerName,fetchData,deleteObject} = useGlobalContext()
    // const [loading, setloading] = useState(true);
    // const [districts, setdistricts] = useState([]);
    // const [regions, setregions] = useState([]);
    const [regionsPoints, setregionsPoints] = useState([]);
    const token = cookies.get("token")
    // const [points, setpoints] = useState([]);
    const [districtRegionTree,setDistrictRegionTree]=useState([])
    const [startDate, setStartDate] = useState(props.object.plan_date?new Date(props.object.plan_date): new Date());
    const MainTheme =useSelector(state=>state.settingsData.MainTheme)
    const GridTheme =useSelector(state=>state.settingsData.GridTheme)
    const directions =useSelector(state=>state.settingsData.directions)

    const [t, i18n] = useTranslation('common');
   
    const [districtsState,setdistrictsState] =useState({
        checked: [],
        expanded: [],
    });
    const [regionsState,setregionsState] =useState({
        checked: [],
        expanded: [],
    });
    const [pointsState,setpointsState] =useState({
        checked: [],
        expanded: [],
    });
    

    const submitForm = async () => {
        
        try {                    
            if (props?.object?.update) {

                const resourcesResult = await axios({
                    url: Host + "user_daily_plans",
                    method: "put",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data: {
                        points_ids:props?.object?.points_ids,
                        old_points_ids:props?.oldObject?.points_ids,
                        plan_date:props?.object?.plan_date,
                        user_id:props?.object?.user_id
                    }
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("updated ")
                        props.setshowUserDailyPlanForm(false,true)
                    } else if (resourcesResult.data.status === false) {
                        props.setshowUserDailyPlanForm(true,false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setshowUserDailyPlanForm(true,false)
                }
            }
            else {
                const resourcesResult = await axios({
                    url: Host + "user_daily_plans",
                    method: "post",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data: props.object
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("saved ")
                        props.setshowUserDailyPlanForm(false,true)
                    } else if (resourcesResult.status === 400) {
                        toast.error(resourcesResult.data)
                        props.setshowUserDailyPlanForm(true,false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setshowUserDailyPlanForm(true,false)
                }                
            }
        } catch (error) {
            console.log(error);
            props.setshowUserDailyPlanForm(true,false)
            const resourcesResult=error.response  
            console.log('resourcesResult');  
            console.log(resourcesResult);          
            if (resourcesResult && resourcesResult.status === 400) {
                if(typeof resourcesResult.data.data === "object")
                {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key)=>{
                    
                        resourcesResult.data.data[key].map((message)=>{
                            toast.error(message)
                        })
                    })
                }
                else{
                    
                    toast.error(resourcesResult.data.data)
                }
                
                
            }
            else if(resourcesResult && resourcesResult.status === 401) {
             
                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else
            {
              
                toast.error("network error")
            }
        }
        
    };

    const getRegionsByDistrict=async(district_ids)=>{
      
       
        try
        {
            const resourcesResult1 = await axios({
                url: Host + "district_regions",
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                },
                params:{
                    district_ids:district_ids
                }

            })
            if (resourcesResult1) {
                if (resourcesResult1.status === 200) {
                    const resources1 = resourcesResult1.data;

                    
                    setRegions(resources1.map((district)=>{
                        return {
                            label:"اختر كل "+district.name,
                            value:"district_id_"+district.id,
                            children:district.regions.map((region)=>{
                                return {
                                    label:region.name,
                                    value:region.id
                                }
                            })
                        }
                    }));
                    
                } else if (resourcesResult1.data.status === false) {
                    toast.error("Error in loading regions")
                    setRegions([]);
                    setregionsPoints([]);
                    setPoints([])                    
                }
            }
            else {
                toast.error("Error in regions request")
                setRegions([]);
                setregionsPoints([]);
                setPoints([])
            }
        }
        catch(error)
        {
           console.log(error); 
           setRegions([]);
           setregionsPoints([]);
           setPoints([]);
        }
    }
    const getPointsByRegion=async(region_ids)=>{
        
        try
        {
            const resourcesResult1 = await axios({
                url: Host + "region_points",
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                },
                params:{
                    region_ids:region_ids
                }

            })
            
            console.log('getpoints')
            console.log(resourcesResult1)    

            if (resourcesResult1) {
                if (resourcesResult1.status === 200) {
                    const resources1 = resourcesResult1.data;

                    var checkedIds=[]
                    setregionsPoints(resources1)
                    setPoints(resources1.map((region)=>{
                        return {
                            label:"اختر كل "+region.name,
                            value:"region_id_"+region.id,
                            children:region.points.map((point)=>{
                                checkedIds.push(point.id)
                                return {
                                    label:point.name,
                                    value:point.id
                                }
                            })
                        }
                    }));
                    setpointsState({
                        ...pointsState,
                        checked:checkedIds
                    })
                    props.setobject({
                        ...props.object,
                        points_ids:checkedIds

                    })
                    
                } else if (resourcesResult1.data.status === false) {
                    toast.error("Error in loading regions")
                    setPoints([]);
                }
            }
            else {
                toast.error("Error in regions request")
                setPoints([]);
            }
        }
        catch(error)
        {
           console.log(error); 
           setPoints([]);
        }
    }
    
    const getResources = async () => {
        try {                        
            const resourcesResult = await axios({
                url: Host + "districts_list",
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                }
            })
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                    const resources = resourcesResult.data;                    
                    const prepareResources=[
                        {
                            "label": "اختر الكل",
                            "value": 0,
                            "children": resources.map((resource)=>{
                                return {
                                    label:resource.name,
                                    value:resource.id,
                                }
                                
                            })
                        }
                    ]                    

                    if(props.object.update)
                    {
                        console.log('districts')
                        console.log(prepareResources)

                        setDistricts(prepareResources);
                        updateAllCheckboxes();
                    }
                    else{
                        setDistricts(prepareResources);
                    }
                    
                    
                    
                } else if (resourcesResult.data.status === false) {
                    toast.error("Error in loading district")
                }
            }
            else {
                toast.error("Error in regions request")
            }
           
            // setloading(false)
        } catch (error) {
            console.log(error);
            // setloading(false)
            toast.error("Network Error")
        }
    };

    const updateAllCheckboxes = async () => {
        try {
            // setloading(true)
            const resourcesResult = await axios({
                url: Host + "user_daily_plan_info",
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                },
                params:{
                    user_id:props?.object?.user_id,
                    plan_date:props?.object?.plan_date,

                }
            })
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                    const resources = resourcesResult.data;

                    await getRegionsByDistrict(resources.districts)
                    await getPointsByRegion(resources.regions)

                    setdistrictsState({
                        ...districtsState,
                        checked:resources.districts
                    })
                    setregionsState({
                        ...regionsState,
                        checked:resources.regions
                    })
                    setpointsState({
                        ...pointsState,
                        checked:resources.points
                    })
                    
                } else if (resourcesResult.data.status === false) {
                    toast.error("Error in loading district")
                }
            }
            else {
                toast.error("Error in regions request")
            }
           
            // setloading(false)
        } catch (error) {
            console.log(error);
            // setloading(false)
            toast.error("Network Error")
        }
    };

    useEffect(() => {
        // if(loading)
            getResources()        
    }, [loading,props.showUserDailyPlanForm]);


    return (
        
        <DialogForm
           open={props.showUserDailyPlanForm}
           loaderActive={loading}
           close={props.setshowUserDailyPlanForm}
           CustomeWidth="70%"
           CustomeHeight="fit-content"
            isShown={props.showUserDailyPlanForm}
            onCloseComplete={() => props.setshowUserDailyPlanForm(false)}
            hasHeader={true}
            title={"Select daily plan for "+user.full_name}
            shouldCloseOnOverlayClick={false}
            confirmLabel="Save"
            width={"80%"}
            cancelLabel="Cancel"
            onConfirm={() => {submitForm()}}
                >
                {loading ? <div>loading...</div> :
                <div>
                    <div style={{display:'flex',justifyContent: 'center',alignItems: 'center',width:'100%'}}>
                        <p style={{fontSize:'27px',color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor}}>{t('JOURNEYS.USERDAILYPLAN_FORMADD_TITLE')+user.full_name}</p>
                    </div>

                    
                    <div className="form-group" style={{display:'flex',justifyContent:'start',alignItems: 'center'}}>
                        <label htmlFor="date" style={{color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,margin:'0 5px'}} >{t('JOURNEYS.USERDAILYPLAN_FORMADD_SELECT_DATE')}</label>
                        <DatePicker
                           style={{
                              backgroundColor:MainTheme?.paperColor+'55',
                              border:`1px solid ${MainTheme?.paperTextColor}`,
                              color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                              '& input':{
                                color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                              }
                           }}
                            // disabled={props?.object?.update?true:false}
                            readOnly={props?.object?.update?true:false}
                            selected={startDate}
                            onChange={(date) => {
                                setStartDate(date)
                                props.setobject({
                                    ...props.object,
                                    plan_date:date,
                                })
                            }}
                        />
                    </div>
                    {pointsState.checked.length>0?
                    <Table>
                    <thead>
                        <tr style={{backgroundColor:MainTheme?.paperColor+'66'}}>
                            <th style={{width:'33%',color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,textAlign:directions=='rtl'?'right':'left'}}>{t('JOURNEYS.USERDAILYPLAN_FORMADD_SELECTED_REGIONS')}</th>
                            <th style={{width:'33%',color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,textAlign:directions=='rtl'?'right':'left'}}>{t('JOURNEYS.USERDAILYPLAN_FORMADD_TOTAL_SELECTED_POINTS')}</th>
                        </tr>
                    </thead>
                    <tbody style={{color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor}}>
                        {regionsPoints.map((region)=>{
                            
                            if(regionsState.checked.includes(region.id+"") || regionsState.checked.includes(region.id))
                            {                                
                                var totalSelectedPointPerRegion=region.points.filter((point)=>{
                                  
                                    return pointsState.checked.includes(point.id) || pointsState.checked.includes(point.id+"") 
                                }).length 
                                return (
                                    <tr>
                                        <td style={{textAlign:directions=='rtl'?'right':'left'}}>
                                            {region.name}
                                        </td>
                                        <td style={{textAlign:directions=='rtl'?'right':'left'}}>
                                            {totalSelectedPointPerRegion}
                                        </td>
                                    </tr>
                                )
                            }
                        })}
                       
                       <tr>
                           <td style={{textAlign:directions=='rtl'?'right':'left'}}>
                               {t('JOURNEYS.USERDAILYPLAN_FORMADD_GRAND_TOTAL_POINT')}
                           </td>
                           <td style={{textAlign:directions=='rtl'?'right':'left'}}>
                               {pointsState.checked.length}
                           </td>
                       </tr>
                    </tbody>
                            
                        
                    </Table>
                    :null}

                    <form style={{maxHeight:'400px',overflowY:'scroll'}}>
                        
                        <Table>
                            <thead>
                                <tr style={{backgroundColor:MainTheme?.paperColor+'66'}}>
                                    <th style={{width:'33%',color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,textAlign:directions=='rtl'?'right':'left'}}>{t('JOURNEYS.USERDAILYPLAN_FORMADD_DISTIRCTS')}</th>
                                    <th style={{width:'33%',color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,textAlign:directions=='rtl'?'right':'left'}}>{t('JOURNEYS.USERDAILYPLAN_FORMADD_REGIONS')}</th>
                                    <th style={{width:'33%',color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,textAlign:directions=='rtl'?'right':'left'}}>{t('JOURNEYS.USERDAILYPLAN_FORMADD_POINTS')}</th>
                                </tr>
                            </thead>
                            <tbody style={{color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor}}>
                                
                                <tr>
                                    <td style={{height:200,scrollBehavior:'auto',textAlign:directions=='rtl'?'right':'left'}}>
                                        <CheckboxTree
                                            nodes={districts}
                                            checked={districtsState.checked}
                                            expanded={districtsState.expanded}
                                            onCheck={(checked) => {
                                                if(checked.length==0)
                                                {
                                                    setregionsState({
                                                        checked:[],
                                                        expanded:[]
                                                    })
                                                    setpointsState({
                                                        checked:[],
                                                        expanded:[]
                                                    })
                                                    props.setobject({
                                                        ...props.object,
                                                        points_ids:[]
                                
                                                    })
                                                }
                                                getRegionsByDistrict(checked)
                                                setdistrictsState({ 
                                                ...districtsState,
                                                checked:checked
                                                })
                                            }}
                                            onExpand={expanded => setdistrictsState({ 
                                                ...districtsState,
                                                expanded:expanded
                                            })}
                                            style={{
                                                color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor
                                            }}
                                        />
                                    </td>
                                    <td style={{maxHeight:'300px', overflow:'auto',textAlign:directions=='rtl'?'right':'left'}} >
                                        <CheckboxTree
                                            nodes={regions}
                                            checked={regionsState.checked}
                                            expanded={regionsState.expanded}
                                            onCheck={(checked) =>{
                                                if(checked.length===0)
                                                {
                                                    
                                                    setpointsState({
                                                        checked:[],
                                                        expanded:[]
                                                    })
                                                    props.setobject({
                                                        ...props.object,
                                                        points_ids:[],
                                                    })
                                                }
                                                getPointsByRegion(checked)
                                                setregionsState({ 
                                                    ...regionsState,
                                                    checked:checked
                                                })
                                            } }
                                            onExpand={expanded => setregionsState({ 
                                                ...regionsState,
                                                expanded:expanded
                                            })}
                                            style={{
                                                color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor
                                            }}
                                        />
                                    </td>
                                    <td style={{maxHeight:'300px', overflow:'auto',textAlign:directions=='rtl'?'right':'left'}} >
                                    <CheckboxTree
                                        nodes={points}
                                        checked={pointsState.checked}
                                        expanded={pointsState.expanded}
                                        onCheck={checked => {
                                            setpointsState({ 
                                                ...pointsState,
                                                checked:checked
                                            })
                                            props.setobject({
                                                ...props.object,
                                                points_ids:checked,
                                            })
                                        }}
                                        onExpand={expanded => setpointsState({ 
                                            ...pointsState,
                                            expanded:expanded
                                        })}
                                        style={{
                                            color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor
                                        }}
                                    />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    
                    </form>
                    
                </div>
                }
            <FooterBottons close={props.setshowUserDailyPlanForm} submitForm={submitForm}/>
        </DialogForm>

    );
};
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'blue',

    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
        width: '100%',
        border: '1px solid #ababab',
        borderRadius: 5,
        padding: 0,
        display: 'flex'
    }),
    container: () => ({

        width: '80%',
        position: 'relative',
        boxSizing: 'border-box',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}

export default UserDailyPlanForm;
