import React, { useState, useEffect, useMemo, useCallback } from 'react'
import './SideNavBar.css'
import { styled, useTheme } from '@mui/material/styles';
// import { makeStyles } from '@mui/styles'
import imageLogo from '../../../assets/img/mandoob_img_small_v2.png'
import imageLogoForActiveMode from '../../../assets/img/M_logo.png'
import PeopleSharpIcon from '@mui/icons-material/PeopleSharp';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import { Link, NavLink } from 'react-router-dom'
import clsx from 'clsx';
import SettingsIcon from '@mui/icons-material/Settings';
import LanguageIcon from '@mui/icons-material/Language';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Cookies from 'universal-cookie';
import SideSettings from '../Settings/SideSettings';
import { setSideMenuMode,setNotificationBox,setSideMenuOpen,setscreenWidthDispatch,setForceReload} from '../../reduxStore/SettingsReducer'
import { useSelector, useDispatch } from 'react-redux'
import Notification from './Notification';
import FullScreen from './FullScreen';
import Language from './Language'
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
// import { FullScreen, useFullScreenHandle } from "react-full-screen";
import PieChartIcon from '@mui/icons-material/PieChart';
import PieChartOutlineIcon from '@mui/icons-material/PieChartOutline';
import LogoVideo from '../../../assets/videos/logo.mp4'
import * as IoIcons from 'react-icons/io5';
import * as AiIcons from 'react-icons/ai';
import * as BsIcons from 'react-icons/bs';
import * as MdIcons from 'react-icons/md';
import * as GiIcons from 'react-icons/gi';
import * as BiIcons from 'react-icons/bi';
import * as FaIcons from 'react-icons/fa';
import * as RiIcons from 'react-icons/ri';
import * as HiIcons from 'react-icons/hi';
import * as ImIcons from 'react-icons/im';
import * as CgIcone from 'react-icons/cg';
import * as SiIcone from 'react-icons/si';
import * as RiIcone from 'react-icons/ri';
import * as VscIcone from 'react-icons/vsc';
import UserProfileAppBar from './UserProfileAppBar'
import Users from '../Users/Users';
import FieldList from '../FieldLists/FieldLists';
import VisitsReport from '../VisitsReport/VisitsReport';
import AttendanceReport from '../AttendanceReport/AttendanceReport';
import FollowupReport from '../FollowupReport/FollowupReport';
import Departments from '../Departments/Departments';
import Districts from '../Districts/Districts';
import Visits from '../Visits/Visits';
import Regions from '../Regions/Regions';
import Surveys from '../Surveys/Surveys';
import Journeys from '../Journeys/Journeys';
import Roles from '../Roles/Roles';
import PointTypes from '../PointTypes/PointTypes';
import Points from '../Points/Points';
import PointRequests from '../PointRequests/PointRequests';
import Dashboard from '../Dashboard/Dashboard';
import NotificationBox from './NotificationBox';
import {UserSection,ContainerOFDrawerList} from './StyledComponents/SideMenuStyleComponent'
import Companies from '../Companies/Companies';
import FieldListValue from '../FieldListValues/FieldListValues'
import SurveyBuild from '../Surveys/SurveyBuild';
import UserDailyPlans from '../UsersDailyPlans/UserDailyPlans';
import {useTranslation} from "react-i18next";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Setting from '../Settings/Setting'
import Company from './Company';
import Logout from '@mui/icons-material/Logout';
import {useNavigate} from 'react-router-dom'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationPage from '../Notification/NotificationPage'
import GroupAddRoundedIcon from '@mui/icons-material/GroupAddRounded';
import Group from '../Group/Group'
import Profile from '../Profile/Profile'
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import {ImagePath} from '../../../assets/js/Host'
import { Image } from 'react-bootstrap';
import LoadingPage from './LoadingPage';
import FollowUpStatus from '../FollowUpStatus/FollowUpStatus';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ViewJourney from '../UsersDailyPlans/ViewJourney';
import EarbudsIcon from '@mui/icons-material/Earbuds';
import SummeryCards from '../AttendanceReport/SummeryCards';
import Shift from '../Shifts/Shift'
import AddRoadIcon from '@mui/icons-material/AddRoad';
import axios from "axios";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import Languages from '../Languages/Languages'
import Classification from '../Classifications/Classifications';
import OwnerType from '../OwnerTypes/OwnerTypes';
import Category from '../Categories/Categories';
import LocationCategory from '../LocationCategories/LocationCategories';
import userImage from '../../../assets/img/userImage.png'

const cookies = new Cookies();
const language = cookies.get("language") ? cookies.get("language") : "ar"


// const drawerWidth = 310;


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open, sideMenuMode, directions,drawerWidth }) => ({


    flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),


    // flexGrow: 1,
    // padding: theme.spacing(3),
    // transition: theme.transitions.create('margin', {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
    // marginLeft: `-${drawerWidth}px`,
    // ...(open && {
    //   transition: theme.transitions.create('margin', {
    //     easing: theme.transitions.easing.easeOut,
    //     duration: theme.transitions.duration.enteringScreen,
    //   }),
    //   marginLeft: 0,
    // }),

    ...(!open && sideMenuMode && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
    // ...(!open && directions == 'rtl' && {
    //   transition: theme.transitions.create('margin', {
    //     easing: theme.transitions.easing.easeOut,
    //     duration: theme.transitions.duration.enteringScreen,
    //   }),
    //   marginLeft: 0,
    //   // position: 'relative',
    //   // left :70
    // }),
    // ...(!open && !sideMenuMode &&directions == 'rtl' && {
    //   transition: theme.transitions.create('margin', {
    //     easing: theme.transitions.easing.easeOut,
    //     duration: theme.transitions.duration.enteringScreen,
    //   }),
    //   width:'100% !important',
    //   // position: 'relative',
    //   // left :70
    // }),

  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, sideMenuMode, directions,ToolBarTheme,drawerWidth }) => ({
   backgroundColor:ToolBarTheme?.paperColor,
   color:ToolBarTheme?.disabledGlobalText?ToolBarTheme?.gloablTextColor:ToolBarTheme?.paperTextColor,
    transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  // ...(open && directions == 'rtl' && {
  //   marginRight: `${drawerWidth}px`,
  //   width: `calc(100% - ${drawerWidth}px)`,
  //   transition: theme.transitions.create(['margin', 'width'], {
  //     easing: theme.transitions.easing.easeOut,
  //     duration: theme.transitions.duration.enteringScreen,
  //   }),
  //   width: `calc(100% - ${drawerWidth}px)`,
  //   // marginLeft: `-${drawerWidth}px`,
  // }),

  ...(!open && sideMenuMode && {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    width: `calc(100% - ${70}px)`,

  }),
  // ...(!open && sideMenuMode && directions == 'ltr' && {
  //   transition: theme.transitions.create(['margin', 'width'], {
  //     easing: theme.transitions.easing.easeOut,
  //     duration: theme.transitions.duration.enteringScreen,
  //   }),
  //   width: `calc(100% - ${85}px)`,
  //   marginleft: `${85}px`,


  // }),




}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
  height: 85
}));

const CustomeDrawer=styled(Drawer)(({theme,SideMenuTheme})=>({
  backgroundColor: `${SideMenuTheme?.bgColor} !important`,
}))

export const pagesLinks = [
  {
    id: 1,
    to: '/Dashboard',
    subLinks: [],
    name_en: 'Dashboard',
    name_ar: 'لوحة التحكم',
    component: <Dashboard />,


  },
  {
    id: 2,
    to: '/Users',
    subLinks: [],
    name_en: 'Users',
    name_ar: 'المستخدمين',
    component: <Users />,

  },
  {
    id: 2,
    to: '/user_daily_plans',
    subLinks: [],
    name_en: 'Users',
    name_ar: 'المستخدمين',
    component: <UserDailyPlans />,

  },
  
  {
    id: 3,
    to: '/Visits',
    subLinks: [],
    name_en: 'Visits',
    name_ar: 'الزيارات',
    component: <Visits />,

  },
  {
    id: 4,
    to: '/Points',
    subLinks: [],
    name_en: 'Points',
    name_ar: 'النقاط',
    component: <Points />,

  },
  {
    id: 5,
    to: '/PointRequests',
    subLinks: [],
    name_en: 'Points Requests',
    name_ar: 'طلبات النقاط',
    component: <PointRequests />,


  },
  {
    id: 6,
    to: '/Regions',
    subLinks: [],
    name_en: 'Regions',
    name_ar: 'المناطق',
    component: <Regions />,


  },
  {
    id: 7,
    to: '/Surveys',
    subLinks: [],
    name_en: 'Surveys',
    name_ar: 'الاستبيان',
    component: <Surveys />,


  },
  {
    id: 8,
    to: '/Journeys',
    subLinks: [],
    name_en: 'Journeys',
    name_ar: 'الرحلات',
    component: <Journeys />,

  },
  {
    id: 9,
    to: '/Roles',
    subLinks: [],
    name_en: 'Roles',
    name_ar: 'الصلاحيات',
    component: <Roles />,

  },
  {
    id: 10,
    to: '/Departments',
    subLinks: [],
    name_en: 'Departments',
    name_ar: 'الاقسام',
    component: <Departments />,

  },
  {
    id: 11,
    to: '/Districts',
    subLinks: [],
    name_en: 'Districts',
    name_ar: 'المناطق',
    component: <Districts />,

  },
  {
    id: 12,
    to: '/field_lists',
    subLinks: [],
    name_en: 'Field  Lists',
    name_ar: 'قائمة الحقول',
    component: <FieldList />,

  },

  {
    id: 13,
    to: '/PointStatus',
    subLinks: [],
    name_en: 'Point Status',
    name_ar: 'حالة النقاط',
    component: <PointTypes />,

  },
  {
    id: 10,
    to: '/classifications',
    subLinks: [],
    name_en: 'Classifications',
    name_ar: 'الاصناف',
    component: <Classification />,

  },
  {
    id: 10,
    to: '/location_categories',
    subLinks: [],
    name_en: 'Location Categories',
    name_ar: 'الاصناف',
    component: <LocationCategory />,

  },
  {
    id: 10,
    to: '/categories',
    subLinks: [],
    name_en: 'Categories',
    name_ar: 'الفئات',
    component: <Category />,

  },
  {
    id: 10,
    to: '/owner_types',
    subLinks: [],
    name_en: 'owner_types',
    name_ar: 'انواع المالك',
    component: <OwnerType />,

  },

  {
    id: 14,
    to: '/visits_report',
    subLinks: [],
    name_en: 'Visits Report',
    name_ar: 'تقرير الزيارات',
    component: <VisitsReport />,

  },
  {
    id: 15,
    to: '/attendance_report',
    subLinks: [],
    name_en: 'Attendance Report',
    name_ar: 'تقرير الحضور',
    component: <AttendanceReport />,

  },
  // {
  //   id: 15,
  //   to: '/attendance_report/:search',
  //   subLinks: [],
  //   name_en: 'Attendance Report',
  //   name_ar: 'تقرير الحضور',
  //   component: <AttendanceReport />,

  // },
  {
    id: 16,
    to: '/followup_report',
    subLinks: [],
    name_en: 'flowUp Report',
    name_ar: 'تقرير المتابعة',
    component: <FollowupReport />,

  },
  {
    id: 7,
    to: '/build_survey',
    subLinks: [],
    name_en: 'buils survey',
    name_ar: 'المستخدمين',
    component: <SurveyBuild />,

  },
  {
    id: 12,
    to: '/field_list_values',
    subLinks: [],
    name_en: 'buils survey',
    name_ar: 'المستخدمين',
    component: <FieldListValue />,
  },
  {
    id: 17,
    to: '/companies',
    subLinks: [],
    name_en: 'Companies',
    name_ar: 'الشركات',
    component: <Companies />,
  },
  {
    id: 21,
    to: '/Notification',
    subLinks: [],
    name_en: 'Notification',
    name_ar: 'الاشعارات',
    component: <NotificationPage />,
  },
  {
    id: 23,
    to: '/Group',
    subLinks: [],
    name_en: 'Groups',
    name_ar: 'المجاميع',
    component: <Group />,
  },
  {
    id: 4000,
    to: '/profile',
    subLinks: [],
    name_en: 'Profile',
    name_ar: 'الملف الشخصي',
    component: <Profile />,
  },
  {
    id: 24,
    to: '/FollowUpStatus',
    subLinks: [],
    name_en: 'Follow Up Status',
    name_ar: 'حالات المتابعة',
    component: <FollowUpStatus />,
  },
  {
    id: 8,
    to: '/AddJourneys',
    subLinks: [],
    name_en: 'journey',
    name_ar: 'رحلة',
    component: <Journeys />,
  },
  {
    id: 25,
    to: '/ViewJourneys',
    subLinks: [],
    name_en: 'journey',
    name_ar: 'الرحلة',
    component: <ViewJourney />,
  },
  {
    id: 8,
    to: '/Shifts',
    subLinks: [],
    name_en: 'Shifts',
    name_ar: 'الوجبات',
    component: <Shift />,
  },
  {
    id: 15,
    to: '/AttendanceSummery',
    subLinks: [],
    name_en: 'AttendanceSummery',
    name_ar: 'ملخص الحضور',
    component: <SummeryCards />,
  },
  {
    id: 20,
    to: '/settings',
    subLinks: [],
    name_en: 'Companies',
    name_ar: 'الشركات',
    component: <Setting />,
  },

  {
    id: 25,
    to: '/language',
    subLinks: [],
    name_en: 'language',
    name_ar: 'اللغات',
    component: <Languages />,
  },

]






const SideNavBar = ({ renderPage, propsValue }) => {
  const {t, i18n} = useTranslation('common');
  const token = cookies.get("token")
  const [loading,setLoading]=useState(false)
  const [fullScreenMode,setFullScreenMode]=useState(false)
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openSideSettings, setOpenSideSettings] = useState(false);
  const sideMenuMode = useSelector(state => state.settingsData.sideMenuMode)
  const drawerWidth = useSelector(state => state.settingsData.drawerWidth)
  const image= useSelector(state=> state.userData.image)
  const ListOfTranslateDataCode=useSelector(state=> state.settingsData.ListOfTranslateDataCode)
  const [screenWidth,setScreenWidth]=useState(window.innerWidth)
  
  const directions = useSelector(state => state.settingsData.directions)
  const MainTheme = useSelector(state => state.settingsData.MainTheme)
  const SideMenuTheme = useSelector(state => state.settingsData.SideMenuTheme)
  const ToolBarTheme = useSelector(state => state.settingsData.ToolbarTheme)
  const TranslationStore=useSelector(state => state.translateData)
  const ReduxLoading =useSelector(state => state.settingsData.ReduxLoading)
  const [isHover, setIsHover] = useState(false);
  const navigate=useNavigate();

  const dispatch=useDispatch();
  const userName_= useSelector(state => state?.userData?.userName)
  const userPhoneNumber_ = useSelector(state => state?.userData?.phone)

  const [userName,setUserName]=useState(userName_)
  const [userPhoneNumber,setuserPhoneNumber]=useState(userPhoneNumber_)

  useEffect(() => {
    window.addEventListener('resize', () => {
      setScreenWidth(window.innerWidth);
      dispatch(setscreenWidthDispatch(window.innerWidth))
    });
  }, [])

  useEffect(()=>{
    setUserName(userName_)
  },[userName_])

  useEffect(()=>{
    setuserPhoneNumber(userPhoneNumber_)
  },[userPhoneNumber_])

  useEffect(()=>{
    dispatch(setSideMenuOpen(open))
  },[open])

  useEffect(()=>{
    
  },[])

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(!open);

  };


 
  const roles = useMemo(() => {
    let roles = []
     
    try {

      roles = cookies.get("roles")
      roles.push(4000)//profile id ^-^.
      // roles = roles?.map(role => role?.id)
    } catch (error) {


    }
    return roles
  })

  const Styles = {
    AppbarIconOpen: {
      color: 'black !important',
      fontSize: '11px !important',
      padding: '10px !important',
      border: 'none !important',
      outline: 'none !important',
    },
    DrawerHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      // marginBottom: '10px',
    },
    DrawerIconClose: {
      color: `${SideMenuTheme?.secondaryColor} !important`,
      fontSize: '11px !important',
      padding: '10px !important',
      border: 'none !important',
      outline: 'none !important',
      '&:hover':{
        backgroundColor:SideMenuTheme?.secondaryColor+'33'
      }

    },
    ImageLogoIcon: {
      width: sideMenuMode && !open ? '100%' : "80%",
      height: sideMenuMode && !open ? '57px' : '40px',
      position: 'relative',
      left: directions == 'rtl' && !open && sideMenuMode ? '2px' : sideMenuMode && !open ? '-7px' : '0px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    },
    ImagesLogoStyles: {
      width: "100%",
      height: '100%',
      // objectFit: 'cover',
      backgroundSize: "cover",
      aspectRatio: '100% / 100%',
    },
    IconListStyle: {
      color: '#fff',
    },
    DividerLine:{
      scrollMarginBottom: "10px",
      backgroundColor: `${SideMenuTheme?.bgTextColor+'33'}`,
      display:'block',
      padding:" 1px 0px",
      margin: "15px 17px 5px 17px ",
      borderRadius:"10px",
      width:!open?'60%':'89%',
    }

  }

  const directionLinks = [
    {
      id: 1,
      to: '/Dashboard',
      subLinks: [],
      name: t('SIDEMENU.DASHBOARD'),
      // name_ar: 'لوحة التحكم',
      component: <Dashboard />,
      icon: <Tooltip arrow title={open ? "" :  t('SIDEMENU.DASHBOARD')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
        <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
          <MdIcons.MdDashboard style={{color:SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor}}  size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
        </div>
      </Tooltip>,

    },
    {
      id: 2,
      to: '/Users',
      subLinks: [],
      name:  t('SIDEMENU.USERS'),
      // name_ar: 'المستخدمين',
      component: <Users />,
      icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.USERS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
        <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
          <FaIcons.FaUsers color={SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
        </div>
      </Tooltip>
    },
    {
      id: 3,
      to: '/Visits',
      subLinks: [],
      name:  t('SIDEMENU.VISITS'),
      // name_ar: 'الزيارات',
      component: <Visits />,
      icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.VISITS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
        <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
          <FaIcons.FaRegHandshake color={SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
        </div>
      </Tooltip>
    },
    {
      id: 4,
      to: '/Points',
      subLinks: [],
      name:  t('SIDEMENU.POINTS'),
      // name_ar: 'النقاط',
      component: <Points />,
      icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.POINTS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
        <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
          <CgIcone.CgEditBlackPoint color={SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
        </div>
      </Tooltip>
    },
    {
      id: 5,
      to: '/PointRequests',
      subLinks: [],
      name:  t('SIDEMENU.POINTS_REQUESTS'),
      // name_ar: 'طلبات النقاط',
      component: <PointRequests />,
      icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.POINTS_REQUESTS') } TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
        <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
          <SiIcone.SiSitepoint color={SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
        </div>
      </Tooltip>

    },
    {
      id: 6,
      to: '/Regions',
      subLinks: [],
      name:  t('SIDEMENU.REGIONS'),
      // name_ar: 'المناطق',
      component: <Regions />,
      icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.REGIONS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
        <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
          <RiIcons.RiTreasureMapLine color={SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
        </div>
      </Tooltip>

    },
    {
      id: 7,
      to: '/Surveys',
      subLinks: [],
      name:  t('SIDEMENU.SURVEYS'),
      // name_ar: 'الاستبيان',
      component: <Surveys />,
      icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.SURVEYS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
        <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
          <RiIcons.RiSurveyLine color={SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
        </div>
      </Tooltip>

    },

    {
      id: 17,
      to: '/companies',
      subLinks: [],
      name: t('SIDEMENU.COMPANIES'),
      // name_ar: 'الشركات',
      component: <Companies />,
      icon: <Tooltip arrow title={open ? "" :  t('SIDEMENU.COMPANIES')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
        <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
          <IoIcons.IoBusiness color={SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
        </div>
      </Tooltip>
    },
    {
      id: 21,
      to: '/Notification',
      subLinks: [],
      name: t('SIDEMENU.NOTIFICATIONS'),
      // name_ar: 'الشركات',
      component: <Companies />,
      icon: <Tooltip arrow title={open ? "" :  t('SIDEMENU.NOTIFICATIONS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
        <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
          <NotificationsActiveIcon sx={{color:SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor}} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
        </div>
      </Tooltip>
    },
    {
      id:4000,
      to: '/profile',
      subLinks: [],
      name: t('SIDEMENU.PROFILE'),
      // name_ar: 'الشركات',
      component: <Profile />,
      icon: <Tooltip arrow title={open ? "" :  t('SIDEMENU.PROFILE')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
        <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
          <PermIdentityIcon sx={{color:SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor}} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
        </div>
      </Tooltip>
    },
    {
      id: 20,
      to: '/settings',
      subLinks: [],
      name: t('SIDEMENU.SETTINGS'),
      // name_ar: 'الشركات',
      component: <Setting />,
      icon: <Tooltip arrow title={open ? "" :  t('SIDEMENU.SETTINGS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
        <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
          <SettingsOutlinedIcon sx={{color:SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor}} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
        </div>
      </Tooltip>
    },
    {
      id: 8,
      to: '/Journeys',
      subLinks: [
        {
          id: 8,
          to: '/AddJourneys',
          subLinks: [],
          name: t('SIDEMENU.ADD_JOURNEYS'),
          // name_ar: 'الصلاحيات',
          component: <Journeys />,
          icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.ADD_JOURNEYS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
            <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
              <AddCircleIcon sx={{color:SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor}} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
            </div>
          </Tooltip>
        },
        {
          id: 25,
          to: '/ViewJourneys',
          subLinks: [],
          name: t('SIDEMENU.EDIT_JOURNEYS'),
          // name_ar: 'الاقسام',
          component: <ViewJourney />,
          icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.EDIT_JOURNEYS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
            <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
              <BorderColorIcon sx={{color:SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor}} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
            </div>
          </Tooltip>
        }

      ],
      name:  t('SIDEMENU.JOURNEYS'),
      // name_ar: 'الرئيسية',
      icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.JOURNEYS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
        <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
          <GiIcons.GiJourney color={SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open ? 'ListIconColorActiveModeMenu2' : ''}`} />
        </div>
      </Tooltip>
    },
    {
      id: 1000,
      to: '/masters',
      subLinks: [
        {
          id: 9,
          to: '/Roles',
          subLinks: [],
          name: t('SIDEMENU.ROLES'),
          // name_ar: 'الصلاحيات',
          component: <Roles />,
          icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.ROLES')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
            <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
              <RiIcons.RiShieldUserLine color={SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
            </div>
          </Tooltip>
        },
        {
          id: 10,
          to: '/Departments',
          subLinks: [],
          name: t('SIDEMENU.DEPARTMENTS'),
          // name_ar: 'الاقسام',
          component: <Departments />,
          icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.DEPARTMENTS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
            <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
              <MdIcons.MdOutlineCategory color={SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
            </div>
          </Tooltip>
        },
        {
          id: 11,
          to: '/Districts',
          subLinks: [],
          name: t('SIDEMENU.DISTRICTS'),
          // name_ar: 'المناطق',
          component: <Districts />,
          icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.DISTRICTS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
            <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
              <RiIcone.RiPinDistanceFill color={SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor}  size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
            </div>
          </Tooltip>
        },
        {
          id: 12,
          to: '/field_lists',
          subLinks: [],
          name: t('SIDEMENU.FIELD_LISTS'),
          // name_ar: 'قائمة الحقول',
          component: <FieldList />,
          icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.FIELD_LISTS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
            <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
              <BiIcons.BiWorld color={SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
            </div>
          </Tooltip>
        },

        {
          id: 13,
          to: '/PointStatus',
          subLinks: [],
          name: t('SIDEMENU.POINT_STATUS'),
          // name_ar: 'حالة النقاط',
          component: <PointTypes />,
          icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.POINT_STATUS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
            <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
              <AiIcons.AiOutlineTag color={SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
            </div>
          </Tooltip>
        },
        {
          id: 23,
          to: '/Group',
          subLinks: [],
          name: t('SIDEMENU.GROUPS'),
          // name_ar: 'الشركات',
          component: <Group />,
          icon: <Tooltip arrow title={open ? "" :  t('SIDEMENU.NOTIFICATIONS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
            <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
              <GroupAddRoundedIcon sx={{color:SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor}} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
            </div>
          </Tooltip>
        },
        {
          id: 24,
          to: '/FollowUpStatus',
          subLinks: [],
          name: t('SIDEMENU.FOLLOW_UP_STATUS'),
          // name_ar: 'الشركات',
          component: <FollowUpStatus />,
          icon: <Tooltip arrow title={open ? "" :  t('SIDEMENU.FOLLOW_UP_STATUS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
            <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
              <FollowTheSignsIcon sx={{color:SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor}} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
            </div>
          </Tooltip>
        },
        {
          id: 23,
          to: '/Shifts',
          subLinks: [],
          name: t('SIDEMENU.SHIFTS'),
          // name_ar: 'الشركات',
          component: <Shift />,
          icon: <Tooltip arrow title={open ? "" :  t('SIDEMENU.SHIFTS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
            <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
              <AddRoadIcon sx={{color:SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor}} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
            </div>
          </Tooltip>
        
        },
        {
          id: 23,
          to: '/language',
          subLinks: [],
          name: t('SIDEMENU.LANGUAGE'),
          // name_ar: 'الشركات',
          component: <Languages />,
          icon: <Tooltip arrow title={open ? "" :  t('SIDEMENU.LANGUAGE')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
            <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
              <LanguageIcon sx={{color:SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor}} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
            </div>
          </Tooltip>
        
        },
        {
          id: 10,
          to: '/classifications',
          subLinks: [],
          name: t('SIDEMENU.CLASSIFICATIONS'),
          // name_ar: 'الاقسام',
          component: <Classification />,
          icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.CLASSIFICATIONS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
            <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
              <MdIcons.MdOutlineCategory color={SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
            </div>
          </Tooltip>
        },
        {
          id: 10,
          to: '/location_categories',
          subLinks: [],
          name: t('SIDEMENU.LOCATION_CATEGORIES'),
          // name_ar: 'الاقسام',
          component: <LocationCategory />,
          icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.LOCATION_CATEGORIES')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
            <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
              <MdIcons.MdOutlineCategory color={SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
            </div>
          </Tooltip>
        },
        {
          id: 10,
          to: '/categories',
          subLinks: [],
          name: t('SIDEMENU.CATEGORIES'),
          // name_ar: 'الاقسام',
          component: <Category />,
          icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.CATEGORIES')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
            <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
              <MdIcons.MdOutlineCategory color={SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
            </div>
          </Tooltip>
        },
        {
          id: 10,
          to: '/owner_types',
          subLinks: [],
          name: t('SIDEMENU.OWNER_TYPES'),
          // name_ar: 'الاقسام',
          component: <OwnerType />,
          icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.OWNER_TYPES')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
            <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
              <MdIcons.MdOutlineCategory color={SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
            </div>
          </Tooltip>
        },
      ],
      name:  t('SIDEMENU.MASTERS'),
      // name_ar: 'الرئيسية',
      icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.MASTERS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
        <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
          <IoIcons.IoAlbumsOutline color={SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open ? 'ListIconColorActiveModeMenu2' : ''}`} />
        </div>
      </Tooltip>
    },
    {
      id: 1001,
      to: '/report',
      subLinks: [
        {
          id: 14,
          to: '/visits_report',
          subLinks: [],
          name: t('SIDEMENU.VISITS_REPORT'),
          // name_ar: 'تقرير الزيارات',
          component: <VisitsReport />,
          icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.VISITS_REPORT')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
            <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
              <RiIcons.RiPinDistanceFill color={SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
            </div>
          </Tooltip>
        },
        {
          id: 16,
          to: '/followup_report',
          subLinks: [],
          name: t('SIDEMENU.FLOWUP_REPORT'),
          // name_ar: 'تقرير المتابعة',
          component: <FollowupReport />,
          icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.FLOWUP_REPORT')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
            <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
              <RiIcons.RiChatFollowUpFill color={SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
            </div>
          </Tooltip>
        },
        {
          id: 15,
          to: '/attendance_report',
          subLinks: [
            {
              id: 15,
              to: '/attendance_report',
              name: t('SIDEMENU.ATTENDANCE_REPORT'),
              // name_ar: 'تقرير الحضور',
              component: <AttendanceReport />,
              icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.ATTENDANCE_REPORT')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
                <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
                  <ImIcons.ImAddressBook color={SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor} size={sideMenuMode && !open ? 30 : 20} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
                </div>
              </Tooltip>
            },
            {
              id: 15,
              name: t('SIDEMENU.ATTENDANCE_SUMMERY_REPORT'),
              to: '/AttendanceSummery',
              component: <SummeryCards />,
              icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.ATTENDANCE_SUMMERY_REPORT')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
                <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
                  <EarbudsIcon sx={{color:SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor}} size={sideMenuMode && !open ? 30 : 20} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
                </div>
              </Tooltip>
            }
          ],
          name: t('SIDEMENU.ATTENDANCE_REPORT'),
          // name_ar: 'تقرير الحضور',
          component: <AttendanceReport />,
          icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.ATTENDANCE_REPORT')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
            <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
              <ImIcons.ImAddressBook color={SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open && directions == 'ltr' ? 'ListIconColorActiveModeMenu' : sideMenuMode && !open && directions == 'rtl' ? 'ListIconColorActiveModeMenuRTL' : ''}`} />
            </div>
          </Tooltip>
        },
      ],
      name: t('SIDEMENU.REPORT'),
      // name_ar: 'التقارير',

      icon: <Tooltip arrow title={open ? "" : t('SIDEMENU.REPORT')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
        <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
          <VscIcone.VscReport color={SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor} size={sideMenuMode && !open ? 30 : 25} className={`ListIconColor ${sideMenuMode && !open ? 'ListIconColorActiveModeMenu2' : ''}`} />
        </div>
      </Tooltip>
    }
   
  ]

  const getWidth = () => {
    
    let width = drawerWidth;
    if (sideMenuMode && open) {
      width = drawerWidth;
    } else if (sideMenuMode && !open) {
      width = 85;
    }

    return width
  }

  const getShowIcon = () => {
    let show = open;
    if (sideMenuMode && open) {
      show = true;
    }
    else if (sideMenuMode && !open) {
      show = false;
    }
   
    return show
  }

  const handleMouseEnter = () => {
    setIsHover(true);
 };
 const handleMouseLeave = () => {
    setIsHover(false);
 };

  //render here menu item ....
  const renderMenuItem = (data) => {
    let hasRole = false
    if (data?.subLinks?.length > 0) {
     
      data?.subLinks?.map((pageLink) => {
       
        if (roles?.includes(pageLink?.id)) {
          

          hasRole = true
        }
      })
    }
    else {
      hasRole = roles?.includes(data?.id)
    }
    if (data?.subLinks?.length > 0 && hasRole) {
      return (
        <Accordion key={data?.id+data?.to} sx={{backgroundColor: `${SideMenuTheme?.primaryColor+'33'} !important`}} className={`collapsedItem ${sideMenuMode && !open ? 'collapsedItemActiveMode' : ''}`} >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className="expandIcon" style={{color:SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor, display: sideMenuMode && !open ? "none" : "inline-block", paddingTop: 0, paddingBottom: 0 }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={() => {
              if (sideMenuMode && !open)
                setOpen(true)
            }}
          >
            <Typography className={`collapsedItemIconAndName ${sideMenuMode && !open ? 'collapsedItemIconAndNameActiveMode' : ''}`}>
              {/* <ListItemIcon style={{...(Styles?.IconListStyle)}}> */}
              {data?.icon}
              {/* </ListItemIcon> */}
              {
                sideMenuMode && open ?
                  <ListItemText primary={<Typography type="body2" style={{fontWeight:'bold',fontSize:'14px',  color: SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor }}>{ data?.name}</Typography>} style={{ marginLeft: 10 }} className={`${directions == 'rtl' ? 'RTLSIDEMENU' : ''}`} />
                  : !sideMenuMode ? <ListItemText primary={<Typography type="body2" style={{ fontWeight:'bold',fontSize:'14px', color: SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor }}>{ data?.name}</Typography> } style={{ marginLeft: 10 }} className={`${directions == 'rtl' ? 'RTLSIDEMENU' : ''}`} /> : null
              }
            </Typography>
          </AccordionSummary>
          {
            open ?
              <AccordionDetails>

                {
                  data?.subLinks?.map((subData) => (
                    roles?.includes(subData?.id) &&subData?.subLinks?.length > 0?
                     <Accordion key={subData?.id+subData?.to} sx={{backgroundColor: `${SideMenuTheme?.primaryColor+'33'} !important`,marginTop:'4px !important'}} className={`collapsedItem collapsedItemForSub${sideMenuMode && !open ? 'collapsedItemActiveMode' : ''}`} >
                         <AccordionSummary
                            expandIcon={<ExpandMoreIcon className="expandIcon" style={{color:SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor, display: sideMenuMode && !open ? "none" : "inline-block", paddingTop: 0, paddingBottom: 0 }} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            onClick={() => {
                              if (sideMenuMode && !open)
                                setOpen(true)
                            }}
                          >
                            <Typography className={`collapsedItemIconAndName ${sideMenuMode && !open ? 'collapsedItemIconAndNameActiveMode' : ''}`}>
                              {/* <ListItemIcon style={{...(Styles?.IconListStyle)}}> */}
                              {subData?.icon}
                              {/* </ListItemIcon> */}
                              {
                                sideMenuMode && open ?
                                  <ListItemText primary={<Typography type="body2" style={{  color: SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor }}>{ subData?.name}</Typography>} style={{ marginLeft: 10 }} className={`${directions == 'rtl' ? 'RTLSIDEMENU' : ''}`} />
                                  : !sideMenuMode ? <ListItemText primary={<Typography type="body2" style={{  color: SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor }}>{ subData?.name}</Typography> } style={{ marginLeft: 10 }} className={`${directions == 'rtl' ? 'RTLSIDEMENU' : ''}`} /> : null
                              }
                            </Typography>
                          </AccordionSummary>
                          {
                            open ?
                              <AccordionDetails>

                                {
                                  subData?.subLinks?.map((subSubData) => (
                                    roles?.includes(subData?.id) &&
                                    <Link to={subSubData?.to}   key={subSubData?.id+subSubData?.to}>
                                        <ListItem  style={{ marginTop: 1, marginBottom: 1, paddingTop: 0, paddingBottom: 0 }}>
                                          <ListItemButton
                                            sx={{
                                              '&:hover':{
                                                backgroundColor:SideMenuTheme?.bgTextColor+'33'
                                              },
                                              width:'100%',
                                              backgroundColor:window.location.pathname==subSubData?.to?SideMenuTheme?.bgTextColor+'33':'transparent',
                                            }}
                                          className={`ListMenuItem ${sideMenuMode && !open ? 'ListMenuItemActiveMode' : ''}`}>
                                            {subSubData?.icon}
                                            {
                                              sideMenuMode && open ?
                                                <ListItemText primary={<Typography type="body2" style={{ fontSize:'13px', color: SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor }}>{subSubData?.name}</Typography> } style={{ marginLeft: 5 }} className={`${directions == 'rtl' ? 'RTLSIDEMENU' : ''}`} />
                                                : !sideMenuMode ? <ListItemText primary={<Typography type="body2" style={{ fontSize:'13px', color: SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor }}>{subSubData?.name}</Typography> } style={{ marginLeft: 10 }} className={`${directions == 'rtl' ? 'RTLSIDEMENU' : ''}`} /> : null
                                            }
                                          </ListItemButton>
                                        </ListItem>
                                      </Link>
                                  ))
                                }
                              </AccordionDetails>
                              :null
                          }
                     </Accordion>
                    :roles?.includes(subData?.id)?<Link to={subData?.to}   key={subData?.id+subData?.to} >
                      <ListItem  style={{ marginTop: '4px', marginBottom: 1, paddingTop: 0, paddingBottom: 0 }}>
                        <ListItemButton
                          sx={{
                            '&:hover':{
                              backgroundColor:SideMenuTheme?.bgTextColor+'33'
                            },
                            backgroundColor:window.location.pathname==subData?.to?SideMenuTheme?.bgTextColor+'33':'transparent',
                          }}
                        className={`ListMenuItem ${sideMenuMode && !open ? 'ListMenuItemActiveMode' : ''}`}>
                          {subData?.icon}
                          {
                            sideMenuMode && open ?
                              <ListItemText primary={<Typography type="body2" style={{fontSize:'14px', color: SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor }}>{subData?.name}</Typography> } style={{ marginLeft: 5 }} className={`${directions == 'rtl' ? 'RTLSIDEMENU' : ''}`} />
                              : !sideMenuMode ? <ListItemText primary={<Typography type="body2" style={{ fontSize:'14px', color: SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor }}>{subData?.name}</Typography> } style={{ marginLeft: 10 }} className={`${directions == 'rtl' ? 'RTLSIDEMENU' : ''}`} /> : null
                          }
                        </ListItemButton>
                      </ListItem>
                    </Link>:null
                  ))
                }


              </AccordionDetails> : null
          }
        </Accordion>
      )
    }
    else if (hasRole) {
      return (
        <Link to={data?.to}   key={data?.id+data?.to}>
          <ListItem  style={{ marginTop: '2px', marginBottom: '2px', paddingTop: 0, paddingBottom: 0, }}>
            <ListItemButton
            sx={{
              '&:hover':{
                backgroundColor:SideMenuTheme?.bgTextColor+'33'
              },
              backgroundColor:window.location.pathname==data?.to?SideMenuTheme?.bgTextColor+'33':'transparent',
              paddingLeft: sideMenuMode && !open?0:-16,
              // left:-2,
              borderRadius:sideMenuMode && !open?"50%":3,
              width:sideMenuMode && !open?50:'auto',
              height:sideMenuMode && !open?43:'auto',
              padding:sideMenuMode && !open?0:'auto',
              margin:sideMenuMode && !open?0:'auto',

              // placeItems:'center',
              justifyContent: 'center',
              alignItems: 'center',
              display:'flex',
              cursor: 'pointer',
              zIndex:1000,
           
            }}
            className={` ${sideMenuMode && !open && directions == 'rtl' ? 'ListMenuItemActiveModeRTL' : sideMenuMode && !open ? 'ListMenuItemActiveMode' : 'ListMenuItem'}`}>
              {data?.icon}
              {
                sideMenuMode && open ?
                  <ListItemText primary={<Typography type="body2" style={{ fontWeight:'bold',fontSize:'14px', color: SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor }}>{ data?.name}</Typography>} style={{ marginLeft: directions == 'rtl' ? 0 : 10, }} className={`${directions == 'rtl' ? 'RTLSIDEMENU' : ''}`} />
                  : !sideMenuMode ? <ListItemText primary={<Typography type="body2" style={{ fontWeight:'bold',fontSize:'14px', color: SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor }}>{ data?.name}</Typography>} style={{ marginLeft: directions == 'rtl' ? 0 : 10 }} className={`${directions == 'rtl' ? 'RTLSIDEMENU' : ''}`} /> : null}
            </ListItemButton>
          </ListItem>
        </Link>
      )

    }

  }

  const goTOProfile=()=>{
    navigate('/profile')
  }



  const Logout=async()=>{
    try {
      setLoading(true)
      dispatch(setForceReload(true))
      const resourcesResult = await axios({
          url: Host + "logout",
          method: "post",
          headers: {
              "Content-Type": "multipart/form-data",
              "Authorization": `Bearer ${token}`,
              Accept: "application/json",
              'X-localization': language,
          },
      })
      
      if (resourcesResult) {
          if (resourcesResult.status === 200) {
              toast.success(resourcesResult.data.data)
              cookies.remove("token");
              localStorage.removeItem("direction");
              localStorage.removeItem("code");
              localStorage.removeItem("localDirections");
              localStorage.removeItem("localCode");
              localStorage.removeItem("language_id");
              // window.location.href='/'
              navigate('/')
          } else if (resourcesResult.data.status === false) {
          }
      }
      else {
          toast.error("Error")
      }
      setLoading(false)
      dispatch(setForceReload(false))

    
  } catch (error) {
      setLoading(false)
      dispatch(setForceReload(false))
      const resourcesResult=error.response            
      if (resourcesResult && resourcesResult.status === 400) {
          
          if(typeof resourcesResult.data.data === "object")
          {
              const errorMessagesKeys = Object.keys(resourcesResult.data.data);
              errorMessagesKeys.map((key)=>{
              
                  resourcesResult.data.data[key].map((message)=>{
                      toast.error(message)
                  })
              })
          }
          else{
              
              toast.error(resourcesResult.data.data)
          }
          
          
      }
      else if(resourcesResult && resourcesResult.status === 401) {
       
          toast.error(resourcesResult.data.data)
          cookies.remove("token");
          window.location.href = "/"
      }
      else
      {
        
          toast.error("network error")
      }
  }

  }

  const getPathNameTitle=()=>{
    let name='';
  
    directionLinks&&directionLinks?.length>0&&directionLinks?.map((itm)=>{
          if(itm?.subLinks?.length>0){
            itm?.subLinks?.map((subItm)=>{
                  if(subItm?.subLinks?.length>0){
                    subItm?.subLinks?.map((subItm2)=>{
                        if(subItm2?.subLinks?.length){

                        }else{
                         if(window.location.pathname==subItm2?.to)
                          name=subItm2?.name;
                        }
                    })
                  }else{
                    if(window.location.pathname==subItm?.to)
                     name=subItm?.name;
                  }
            })
          }else{
            if(window.location.pathname==itm?.to)
               name=itm?.name;
          }
    })
    return name;
  }

  return (
    <Box className="SideMenuBar" sx={{ display: 'flex', background: MainTheme?.isLinearGradient?MainTheme?.linearGradientBgColor:MainTheme?.bgColor,color:MainTheme?.bgTextColor, height: '100%', width: '100%',overflowY:"scroll", }}>

      <CssBaseline />



      {/* app bar here ...  */}
      <AppBar drawerWidth={drawerWidth} position="fixed" ToolBarTheme={ToolBarTheme} dir={directions=='rtl'?'rtl':'ltr'} open={open} directions={directions} sideMenuMode={sideMenuMode} >
        <div className="appBar">
          <div
          
            style={{ left: directions == 'rtl' ? 10 : 0,display :'flex',flexDirection:'row',alignItems:'center',height:'100%',width:'50%'}}
          >
            {!getShowIcon() ?
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ ml: 2.5, ...(Styles?.AppbarIconOpen) }}

                className="AppbarIconOpenHover"
              >
                <MenuIcon   style={{color:ToolBarTheme?.disabledGlobalText?ToolBarTheme?.gloablTextColor:ToolBarTheme?.paperTextColor}}/>
              </IconButton>
              : null
            }
            <Tooltip arrow title={t('APPBAR.TOOLTIP_ANALYTICS_DASHBOARD')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="bottom" style={{margin:directions == 'rtl' ?'0 10px 0 5px':'0 5px'}}>
                <NavLink to="/Dashboard">
                  <PieChartOutlineIcon className="analyticsColorIcon" style={{fontSize:'19px',color:ToolBarTheme?.disabledGlobalText?ToolBarTheme?.gloablTextColor:ToolBarTheme?.paperTextColor}}/>
                </NavLink>
            </Tooltip>

            {/* <Tooltip arrow title={t('APPBAR.TOOLTIP_USERS')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="bottom" style={{margin:directions == 'rtl' ?'0 5px':'0 5px'}}>
                <NavLink to="/Users">
                      <PeopleOutlineIcon className="analyticsColorIcon"  style={{color:ToolBarTheme?.disabledGlobalText?ToolBarTheme?.gloablTextColor:ToolBarTheme?.paperTextColor}}/>
                </NavLink>
            </Tooltip> */}
            <Company ToolBarTheme={ToolBarTheme} />

          </div>
          <div style={{fontWeight:"bold",width:'fit-content',minWidth:'300px',display:'flex',justifyContent:'center',alignItems:'center'}}>{getPathNameTitle()}</div>

          <div className="lefOrRightSideofAbbBar">
            <Language ToolBarTheme={ToolBarTheme}/>
            <FullScreen ToolBarTheme={ToolBarTheme} fullScreenMode={fullScreenMode} setFullScreenMode={setFullScreenMode}/>
            <Notification ToolBarTheme={ToolBarTheme}/>
            <UserProfileAppBar ToolBarTheme={ToolBarTheme} Logout={Logout}/>
          </div>
        </div>
      </AppBar>
      {/* end section */}




      {/* side menu here ...  */}
      <Drawer
        SideMenuTheme={SideMenuTheme}
        className="draweSideMenu"
        sx={{
          width: getWidth(),
          // backgroundColor: `${SideMenuTheme?.bgColor} !important`,
          '& .MuiDrawer-paper': {
            width: getWidth(),
            boxSizing: 'border-box',
            // display:'flex',
            // flexDirection: 'column',
            backgroundColor: `${SideMenuTheme?.bgColor} !important`,
            // // alignItems: 'center',
            // justifyContent: 'center',
          },  
         
          
        }}

        variant={!sideMenuMode ? "persistent" : 'permanent'}
        open={open}
      >
        <DrawerHeader style={{ ...(Styles?.DrawerHeader) }}>
          <div style={{
            ...(Styles?.ImageLogoIcon)
          }}>
            <img src={sideMenuMode && !open ? imageLogoForActiveMode : imageLogo} alt="" style={{ ...(Styles?.ImagesLogoStyles) }} />
            {/* <p className='LogoText'>Mandoob</p> */}
          </div>
          {
            open ?
              <IconButton
                onClick={handleDrawerClose}
                sx={{
                  ...(Styles?.DrawerIconClose),

                }}
                // className="DrawerIconCloseHover"

              >
                {theme.direction === 'ltr' ? <MenuIcon /> : <MenuIcon />}
              </IconButton>
              : null
          }
        </DrawerHeader>

        <ContainerOFDrawerList className='containerOfDrawerList' isIconActive={sideMenuMode&&!open}>
            {
              sideMenuMode&&!open?
              <>
                {/* <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/> */}
              </>:
              null
            }
          <UserSection sideMenuMode={sideMenuMode} open={open} imgBgColor={SideMenuTheme?.secondaryColor} color={SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.oppositePrimaryColor} bgColor={SideMenuTheme?.primaryColor} checkCon={sideMenuMode && !open} className={`${sideMenuMode && !open ? 'iconModeActive' : ''}`}>
            <img className={``} onClick={()=>{goTOProfile()}} src={image?ImagePath+image:userImage} alt="" />
            <p className={`textUserName`} onClick={()=>{goTOProfile()}}>{cookies.get('full_name')?cookies.get('full_name'):''}</p>
            {/* <p className={`textUserEmail`}>{cookies.get('phone')?cookies.get('phone'):''}</p> */}
          </UserSection>

          {
            directionLinks?.map((data) => {
              return renderMenuItem(data)
            })
          }


          <Divider  sx={{...Styles.DividerLine}} />


          <Link onClick={() => Logout()} to="/"  >
            <ListItem style={{ marginTop: 1, marginBottom: 1, paddingTop: 0, paddingBottom: 0 }}>
              <ListItemButton sx={{
                '&:hover':{
                  backgroundColor:SideMenuTheme?.bgTextColor+'33',
                },
                // backgroundColor:window.location.pathname==data?.to?SideMenuTheme?.bgTextColor+'33':'transparent',
                paddingLeft: sideMenuMode && !open?0:-16,
                // left:-2,
                borderRadius:sideMenuMode && !open?"50% !important":3,
                width:sideMenuMode && !open?50:'auto',
                height:sideMenuMode && !open?43:'auto',
                padding:sideMenuMode && !open?0:'auto',
                margin:sideMenuMode && !open?0:'auto',
                placeItems:'center',
                justifyContent: 'center',
                alignItems: 'center',
                display:'flex',
                cursor: 'pointer',
                
              }}
                 className={`ListMenuItem ${sideMenuMode && !open && directions == 'rtl' ? 'ListMenuItemActiveModeRTL' : sideMenuMode && !open ? 'ListMenuItemActiveMode' : ''}`}>
                <Tooltip arrow title={open ? "" : t('SIDEMENU.SIGN_OUT')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="right">
                  <div style={{ width: '30px', height: "25px", justifyContent: 'center', alignItems: 'center' }}>
                    <IoIcons.IoLogOutOutline 
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    color={SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor} size={sideMenuMode && !open ? 30 : 25} 
                    style={{ position: 'relative', left: 0}} />
                  </div>
                </Tooltip>
                {
                  open ?
                    <ListItemText  primary={<Typography type="body2" style={{ color: SideMenuTheme?.disabledGlobalText?SideMenuTheme?.gloablTextColor:SideMenuTheme?.bgTextColor }}>{t('SIDEMENU.SIGN_OUT')}</Typography>} className={`${directions == 'rtl' ? 'RTLSIDEMENU' : ''}`} />
                    : null
                }
              </ListItemButton>
            </ListItem>
          </Link>


          {/* <div className="endListMenu">
                  <video src={LogoVideo} loog muted autoPlay></video>
              </div> */}

        </ContainerOFDrawerList>
      </Drawer>
      {/* end section  */}



      {/* main content  of pages here ...  */}
      <Main
        open={open}
        sideMenuMode={sideMenuMode}
        className='ContentOfRigtSide'
        // component="main"
        directions={directions}
        drawerWidth={drawerWidth}
        dir={directions=='rtl'?'rtl':'ltr'}
        sx={{
          marginBottom:'1%',position:'relative',top:-35,
          background: 'transparent !important',
          color:MainTheme?.bgTextColor,
          minWidth:'1150px'
          // overflowY: 'scroll',
      }}
      >
        {/* <div className={classes.drawerHeader} /> */}
        <DrawerHeader  />

        {
          renderPage(propsValue)
        }


      </Main>
      {/* end section  */}




      {/* floating settings icon here ...  */}
      <div className={`showSettings ${directions == 'rtl' ? 'rightActiveMode' : ''}`} style={{backgroundColor: MainTheme?.secondaryColor}}>
        <SettingsIcon style={{ color: MainTheme?.oppositeSecondaryColor }} className="iconSettings" onClick={() => { setOpenSideSettings(true) }} />
      </div>
      <SideSettings openSideSettings={openSideSettings} setOpenSideSettings={setOpenSideSettings} />
      {/* end section  */}


      {/* notifications section here ...  */}
       <NotificationBox/>
      {/* end sections  */}

      {
         ReduxLoading?
         <LoadingPage/>
         :null
        }

    </Box>
  )
}


export default React.memo(SideNavBar);

