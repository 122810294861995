import React from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {useTranslation} from "react-i18next";
import {useSelector,useDispatch} from 'react-redux'
import { styled, useTheme } from '@mui/material/styles';

const StyledInput=styled(Box)(({theme,MainTheme,customeWidth})=>({
    width: customeWidth,
    maxWidth: '100%',
    color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
    margin:'15px 10px',
    backgroundColor:MainTheme?.paperColor,
    '& input':{
    color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,

    },
    '& .MuiOutlinedInput-root':{
        borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
        color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
    },
    '& .MuiInputLabel-root':{
        // backgroundColor:`${MainTheme?.paperTextColor+'22'} !important`,
        color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
    },
    '& .MuiFormControl-root':{
      // border:`1px solid ${MainTheme?.paperTextColor} !important`,
      color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
      },
      '&:hover fieldset': {
        borderColor: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.secondaryColor,
      },
    },
    '& .MuiOutlinedInput':{
      // borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
    },
    "& .MuiOutlinedInput-notchedOutline": {
        // border: "0 none",
     },
    '& .muirtl-1a1fmpi-MuiInputBase-root-MuiInput-root:before,& .muiltr-1a1fmpi-MuiInputBase-root-MuiInput-root:before':{
        borderColor: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.secondaryColor,
     }
}));
export default function CustomeInput({value,setValue,label,customeWidth,readOnly}) {
    const {t, i18n} = useTranslation('common');
    const MainTheme=useSelector(state=>state.settingsData.MainTheme);
  
  return (
    <StyledInput MainTheme={MainTheme} customeWidth={customeWidth?customeWidth:'31%'}>
      <TextField
       fullWidth 
       inputProps={
        { readOnly: readOnly, }
      }
       label={label}
       id="fullWidth" 
       value={value}
       onChange={(e)=>{
          setValue(e.target.value)
       }}
       variant="standard"
      />
    </StyledInput>
  )
}
