
import React, { useEffect, useState } from 'react';
// import Pagination from '@mui/material/Pagination';

import Cookies from "universal-cookie";
import axios from "axios";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
// import UserForm from './UserForm';
// import UserRoleForm from './UserRoleForm';

import IconButton from "@mui/material/IconButton";
// import Tooltip from '@mui/material/Tooltip';
import "../../../assets/css/DG.css"
import "../../../assets/css/AccordionStyle.css"
import { useSelector } from 'react-redux'
import red from '@mui/material/colors/red';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {useNavigate} from 'react-router-dom'
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import * as GrIcone from 'react-icons/gr'
import { useTranslation } from "react-i18next";
import DropDownGrid from '../Share/DropDownGrid'
import { MenuItem ,ListItemIcon, Typography} from '@mui/material';
// import NotificationForm from './NotificationForm';
import Grid from '../Share/Grid'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
// import NotificationsUsers from './NotificationsUsers';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import GroupForm from './GroupForm';
import helpers from '../../../assets/js/helper';


const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"
let roles = []

try {

    roles = cookies.get("roles")
} catch (error) {

}

export default function Group() {

    const { t, i18n } = useTranslation('common');
    const directions = useSelector(state => state.settingsData.directions)
    const GridTheme = useSelector(state => state.settingsData.GridTheme)
    const token = cookies.get("token")
    // const { users,groups,notification_types, pointsStatuses, pointStatus, setPointStatus, exportToCSV, points, district, region, districts, regions, ownerName, shopName, clearPointsAdvanceSearchFields, setRegion, setDistrict, setShopName, setOwnerName, fetchData, deleteObject } = useGlobalContext()
  
    const [loading, setloading] = useState(false);
    const [state, setData] = useState({
        groups: ''
    });
  
    const navigate = useNavigate();
  
    const Language = React.useMemo(() => {
        if (directions !== 'rtl') {
            return require('../../Settings/Translate_en.json').USERS
        } else {
            return require('../../Settings/Translate_ar.json').USERS
        }
    }, [directions])
  
  
    const [groupTotals, setGroupTotals] = useState(0)
    const [groupNumberOfPage, setGroupNumberOfPage] = useState(0)
    const [showGroup,setShowGroup]=useState(false)
    const [selectedGroup,setSelectedGroup]=useState({})
    const [showNotificationUsers,setShowNotificationUsers]=useState(false)
    const [pageSize, setPageSize] = useState(5);
    const [pageNumber, setpageNumber] = useState(1);
    const [rows, setRow] = useState([])
  
    const columns = [
        {
            field: 'id', headerName: t("GROUPS.ID"), flex:.1,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} >
                    {rows?.row?.id}
                </div>
            )
        },
        {
            field: 'name', headerName: t("GROUPS.NAME"),flex:1,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions == 'rtl' ? 'alignColumnRight' : 'alignColumnLeft'}`}>
                    {rows?.row?.name}
                </div>
            )
        },
        {
            field: 'color', headerName: t('GROUPS.COLOR'),flex:1.5,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions == 'rtl' ? 'alignColumnRight' : 'alignColumnLeft'}`}>
                    
                    <div 
                      style={{
                        width:'40%',
                        height:'30px',
                        borderRadius:'10px',
                        margin:'10px 5px',
                        backgroundColor:rows?.row?.color,
                        color:!helpers?.checkDarknessOrLightnessForColor(rows?.row?.color)?'#000':'#fff',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                            position: 'relative',
                            top:'0px',
                            right:directions=='rtl'?'-40%':'-40%',
                            color:!helpers?.checkDarknessOrLightnessForColor(rows?.row?.color)?'#000':'#fff ',
                        }}
                      >{rows?.row?.color}</Typography>
                    </div>
                </div>
            )
        },
     
  
        {
            field: 'actions',flex:.1,
            headerName: t("USERS.ACTIONS"),
            renderCell: (rows) => (
                <DropDownGrid>
                   
                    <MenuItem onClick={() => { updateObject(rows?.row) }} >
                        <ListItemIcon sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            margin:'0px 7px 0 0'

                        }}>
                            <EditIcon style={{ fill: "#111827" }} />
                        </ListItemIcon>
                        {directions != 'rtl' ? "Edit" : 'تعديل'}
                    </MenuItem>

                    <MenuItem onClick={() => { deleteObject(rows?.row) }}>
                        <ListItemIcon sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            margin:'0px 7px 0 0'
                        }}>
                            <DeleteIcon sx={{ color: red[500] }} />
                        </ListItemIcon>
                        {directions != 'rtl' ? "Delete" : 'حذف'}
                    </MenuItem>
                       
                </DropDownGrid>
            ),
        },
    ]
  
    const handlePageChange = (newPage) => {
        setpageNumber(newPage + 1)
    }
  
    const fetchData = async (params) => {
        setloading(true)
        try {
            const result = await axios({
                url: Host + "groups",
                params: {
                    page: pageNumber,
                    page_size: pageSize,
                    ...params
                },
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                },
                params: {
                    page: pageNumber,
                    page_size: pageSize,
                }
  
  
            })
            if (result && result.status == 200) {
                const groups = result.data;
                setData({
                  groups: groups
                });
  
  
  
            } else if (result.data.status === false) {
                toast.error("Error")
            }
        } catch (error) {
            const responseResult = error.response
            if (responseResult && responseResult.status === 400) {
                if (typeof responseResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key) => {
  
                        responseResult.data.data[key].map((message) => {
                            toast.error(message)
                        })
                    })
                }
                else {
                    toast.error(responseResult.data.data)
                }
  
            }
            else if (responseResult && responseResult.status === 401) {
                toast.error(responseResult.data.data)
                cookies.remove("token");
                // window.location.href = "/"
                navigate('/');
            }
            else {
                toast.error("network error")
            }
        }
        setloading(false)
  
    };
    const updateObject = async (object) => {
        setSelectedGroup(object)
        setShowGroup(true)
    };
    // const showRoles = (object) => {
    //     setselectedUser(object)
    //     setShowNotificationForm(true)
    // };
  
    // const deleteObject = async (object) => {
    //     if (!window.confirm("Delete the item?")) {
    //         return
    //     }
    //     setloading(true)
    //     try {
    //         const result = await axios({
    //             url: Host + "users/" + object.id,
    //             method: "delete",
    //             headers: {
    //                 "Authorization": `Bearer ${token}`,
    //                 Accept: "application/json",
    //                 'X-localization': language,
    //             }
  
    //         })
    //         if (result && result.status == 200) {
    //             toast.error(result.data.data)
    //             fetchData();
    //         } else if (result.data.status === false) {
    //             toast.error("Error")
    //         }
    //     } catch (error) {
    //         const responseResult = error.response
    //         if (responseResult && responseResult.status === 400) {
    //             if (typeof responseResult.data.data === "object") {
    //                 const errorMessagesKeys = Object.keys(responseResult.data.data);
    //                 errorMessagesKeys.map((key) => {
  
    //                     responseResult.data.data[key].map((message) => {
    //                         toast.error(message)
    //                     })
    //                 })
    //             }
    //             else {
    //                 toast.error(responseResult.data.data)
    //             }
  
    //         }
    //         else if (responseResult && responseResult.status === 401) {
    //             toast.error(responseResult.data.data)
    //             cookies.remove("token");
    //             // window.location.href = "/"
    //             navigate('/');
  
    //         }
    //         else {
    //             toast.error("network error")
    //         }
    //     }
    //     setloading(false)
    // };
    const createObject = async (object) => {
        setSelectedGroup(null)
        setShowGroup(true)
    };
  
  
  
    useEffect(() => {
        // if(token)
        fetchData();
    }, [pageNumber, pageSize])
  
    useEffect(() => {
        if (state && state.groups && state.groups && state.groups?.data?.length > 0) {
            setRow(state?.groups?.data);
            setGroupTotals(state?.groups?.total)
            setGroupNumberOfPage(state?.groups?.last_page)
        }else{
            setRow([]);
            setGroupTotals(0)
            setGroupNumberOfPage(0)
        }
    }, [state.groups])
  
    const openNotificationUsers=(data)=>{
      setSelectedGroup(data)
      setShowGroup(true)
    }
    const closeNotificationUsers=()=>{
      setShowNotificationUsers(false)
    }

    const deleteObject = async (object) => {
        if (!window.confirm("Delete the item?")) {
            return
        }
        setloading(true)
        try {
            const result=await axios({
                url: Host + "groups/"+object.id,
                method: "delete",
                headers: {
                "Authorization" : `Bearer ${token}`,
                    Accept:"application/json",
                    'X-localization':language,
                }
        
            })
            if(result && result.status == 200)
            {
                toast.error(result.data.data)
                fetchData();
            } else if (result.data.status === false) {
                toast.error("Error")
            }
        } catch (error) {
            const responseResult=error.response            
            if (responseResult && responseResult.status === 400) {
                if(typeof responseResult.data.data === "object")
                {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key)=>{
                    
                        responseResult.data.data[key].map((message)=>{
                            toast.error(message)
                        })
                    })
                }
                else{
                    toast.error(responseResult.data.data)
                }
                
            }
            else if(responseResult && responseResult.status === 401) {
                toast.error(responseResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else
            {
                toast.error("network error")
            }
        }
        setloading(false)
    };

  return (
     <>
            {
             showGroup ?
              <GroupForm Group={selectedGroup} fetchData={fetchData} showGroup={showGroup} setShowGroup={setShowGroup} />
              : null
            }
            
            {/* <NotificationsUsers open={showNotificationUsers} Notification={selectedNotification} handleClose={closeNotificationUsers} fetchData={fetchData} showNotificationForm={showNotificationForm} setShowNotificationForm={setShowNotificationForm}/> */}
        


          <Grid
              rows={rows}
              columns={columns}
              rowTotals={groupTotals}
              pageSize={pageSize}
              setPageSize={setPageSize}
              pageNumber={pageNumber}
              setPageNumber={setpageNumber}
              loading={loading}
              handlePageChange={handlePageChange}
              NumberOfPage={groupNumberOfPage}
              directions={directions}
              hasCreate={true}
              createObject={createObject}
              title={t('GROUPS.TITLE')}
              //   title_ar="قائمة المستخدمين"
              hasFooter={false}
              gridHeight={{
                //   height: rows?.length > 5 ? pageSize == 5 ? 400 : pageSize == 10 ? 650 : 950 : 400,
              }}
          //   paginationType="client"

          />
      </>
  )
}
