import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import './LoadingPage.css'
import {useSelector,useDispatch} from 'react-redux'
import imgLogoM from '../../../assets/img/M_logo.png'



const ContainerOfLoading=styled('div')(({theme,localLoading,MainTheme,directions})=>({
  position:localLoading?'absolute':'fixed',
  left:0,
  right:0,
  bottom:0,
  top:0,
  width:'100%',
  height:'100%',
  display:'flex',
  justifyContent: 'center',
  alignItems: 'center',
  placeItems: 'center',
  zIndex:6000,
  backdropFilter:'blur(5px)',
  backgroundColor:'rgba(10,25,41,0.8)',
  '& .loader':{
   position:'relative',
   right:localStorage.getItem('direction')&&localStorage.getItem('direction')==0?'-30px':'30px',
   top:'10px',
     //  margin:'auto',
   // transform:'translate(50px, -70px)',
  },
  '& #container ':{
   position: 'relative',
   width: '300px',
   transform: localStorage.getItem('direction')&&localStorage.getItem('direction')==0?'translate(25%,-50%)':'translate(25%,-50%)',
   '& #outer':{
    top: '19px',
    left: localStorage.getItem('direction')&&localStorage.getItem('direction')==0?'0':'152px',
   },
   '& #middle':{
     top: localStorage.getItem('direction')&&localStorage.getItem('direction')==0?"32px;":'32px',
     left: localStorage.getItem('direction')&&localStorage.getItem('direction')==0?'10px;':'13px', 
   },
   '& .imgLogo':{
     top: localStorage.getItem('direction')&&localStorage.getItem('direction')==0?'49px;':'49px;',
     right: localStorage.getItem('direction')&&localStorage.getItem('direction')==0?'33px;':'0%;',
     left: localStorage.getItem('direction')&&localStorage.getItem('direction')==0?'25px !important;':'33px !important',
   }
  }
}))

export default function LoadingPage(props) {
  const MainTheme=useSelector(state=>state.settingsData.MainTheme)
  const directions=useSelector(state=>state.settingsData.directions)

  return (
    <ContainerOfLoading className="LoadingPage " MainTheme={MainTheme} directions={directions} localLoading={props?.localLoading?true:false}>
             {/* <div className="wrapperLoading"> */}
                 {/* <div className="loader loader-3"></div> */}
            {/* </div> */}
            <div id="container">
                <div id="outer">
                </div>
                <div id="middle">
                </div> 
                <div id="inner">
                  
                </div>
                <img  src={imgLogoM}  className="imgLogo"/>
            </div>
    
    </ContainerOfLoading>
  );
}

