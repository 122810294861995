import React,{useState,useEffect} from 'react'
import {setOffLine} from '../component/reduxStore/SettingsReducer'
import {useSelector,useDispatch} from 'react-redux'
import '../App.css'
import 'animate.css';
import {useTranslation} from "react-i18next";

export default function (ComposedComponent) {
 function NetworkDetector (props) {
    const offLine=useSelector(state=>state.settingsData.offLine)
  const dispatch=useDispatch();
  const {t, i18n} = useTranslation('common');

  useEffect(()=>{
    handleConnectionChange()
    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);
  },[])

  useEffect(()=>{
  
    return () => {
      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    }
  },[])


 const handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      const webPing = setInterval(
        () => {
          fetch('//google.com', {
            mode: 'no-cors',
            })
          .then(() => {
            dispatch(setOffLine(false))
            // this.setState({ isDisconnected: false }, () => {
            return clearInterval(webPing)
            // });
          }).catch(() => {
            // this.setState({ isDisconnected: true }) 
            dispatch(setOffLine(true))

          })
        }, 2000);
      return;
    }

    dispatch(setOffLine(true))
     return;
  }

  const checkInternetConnections=()=>{
      if(offLine){
        return offLine;
      }else if(!offLine){

        if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
          var check=false;
          check=setTimeout(()=>{
             return  false
           },4000)
           return check;
        } else {
         return false;
        }

      
      }
  }

  return (
    <div>
    { offLine ? (<div className={`${!offLine?'greenColor':''} internet-error animate__animated ${offLine?'animate__zoomIn':'animate__zoomOut'}`}>
        <p style={{position:'relative',top:'20px'}}>
          {
           localStorage.getItem('code')&&localStorage.getItem('code')=='ar'?'لقد فقدت الاتصال بالإنترنت':'You lost Internet connections'
          }
        </p>
      </div>):null
    }
    <ComposedComponent {...props} />
  </div>
  )
}
  return NetworkDetector
}
