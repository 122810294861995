import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Pane, Dialog } from 'evergreen-ui';
import axios from "axios";
import Select from "react-select";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import {TextField,Box} from '@mui/material'
import {useSelector,useDIspatch} from 'react-redux'
import DialogForm from "../Share/DialogForm";
import {
    ContainerOfForm,
    SaveButton,
    CancelButton,
    Form
} from '../Users/StyledComponents';
import FooterBottons from '../Share/FooterBottons'
import {useTranslation} from "react-i18next";
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import helpers from '../../../assets/js/helper'
import moment from 'moment';
import Slider from '@mui/material/Slider';
const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"
export const ShiftForm = (props) => {
    const [loading, setLoading] = useState(true);
    const directions =useSelector(state=>state.settingsData.directions);
    const MainTheme =useSelector(state=>state.settingsData.MainTheme);
    const [t, i18n] = useTranslation('common');
    const token = cookies.get("token")
    const [object, setobject] = useState({
        id: null,
        name: null,
    });
    const [startWorkingHour,setStartWorkingHour]=useState(new Date())
    const [endWorkingHour,setEndWorkingHour]=useState(new Date())
    const [requiredWorkingHour,setRequiredWorkingHour]=useState(0)

    const [startTimeSeconds,setStartTimeSeconds]=useState(0)
    const [endTimeSeconds,setEndTimeSeconds]=useState(0)
    const [StartTimeMessageError,setStartTimeMessageError]=useState('')
    const [EndTimeMessageError,setEndTimeMessageError]=useState('')


    const refomrmatMinutesToHours=(min,number)=>{
        if(number==0)
        {
            let hourse=Math.floor(min/60)
            let minutes=Math.floor(min % 60)
            // let dateNow= new Date();
            let dateNow=helpers.formatDate(new Date())
            dateNow+=' '+hourse+':'+minutes+":00"
            return new Date(dateNow)
        }else if(number==1){
           let hourse=Math.floor(min/60)
            console.log('asdasdasdasdasdfasd',hourse)
             return hourse;
        }
    }
   
    useEffect(() => {
        if(props&& props.shift)
        {
            console.log(refomrmatMinutesToHours(props.shift?.start_minutes,0))
            console.log(refomrmatMinutesToHours(props.shift?.end_minutes,0))

            setStartTimeSeconds(props.shift?.start_minutes)
            setEndTimeSeconds(props.shift?.end_minutes)
            setStartWorkingHour(refomrmatMinutesToHours(props.shift?.start_minutes,0))
            setEndWorkingHour(refomrmatMinutesToHours(props.shift?.end_minutes,0))
            setRequiredWorkingHour(Number(props.shift?.minuts_required))
            console.log('asdasdasdasdasdfasd',props.shift?.minuts_required)

        }
        else{
            setStartWorkingHour(new Date())
            setEndWorkingHour(new Date)
            setRequiredWorkingHour(0)
        }


    }, [props]);
    
     useEffect(()=>{
        if( !props.shift){
           let TimeInSeconds_start=formatTimeToMinutesSingle(new Date())
           let TimeInSeconds_end=formatTimeToMinutesSingle(new Date())
           setStartTimeSeconds(TimeInSeconds_start)
           setEndTimeSeconds(TimeInSeconds_end)
        }
     },[])

    const check=helpers.checkDarknessOrLightnessForColor(MainTheme?.paperTextColor)

    const styleTextField={
        // backgroundColor:!check?'rgb(255,255,255,0.7)':'rgb(0,0,0,0.4)',
        // color:MainTheme?.paperTextColor,
    }

    const styleSelect={
        // backgroundColor:!check?'rgb(255,255,255,0.7)':'rgb(0,0,0,0.4)',
        // color:MainTheme?.paperTextColor,
    }

    const formatTimeToMinutes=()=>{
      let startTime=moment(startWorkingHour).format('HH:mm');
      let endTime=moment(endWorkingHour).format('HH:mm');
      let workingTime=requiredWorkingHour

      var timePartsStartTime = startTime.split(":");
      var timePartsEndTime = endTime.split(":");
    //   var timePartsWorkTime = workingTime.split(":");
      
      let MinuteStartTime= Number(Math.floor(timePartsStartTime[0]) * 60) + Number(timePartsStartTime[1]);
      let MinuteEndTime= Number(Math.floor(timePartsEndTime[0]) * 60) + Number(timePartsEndTime[1]);
      let MinuteRequiredTime= Number(Math.floor(requiredWorkingHour * 60));

      console.log('start=>',MinuteStartTime)
      console.log('end=>',MinuteEndTime)
      console.log('required=>',MinuteRequiredTime)

      return {MinuteStartTime,MinuteEndTime,MinuteRequiredTime};

    }
    const formatTimeToMinutesSingle=(date)=>{
        let startTime=moment(date).format('HH:mm');
        
  
        var timePartsStartTime = startTime.split(":");
      //   var timePartsWorkTime = workingTime.split(":");
        
        let MinuteStartTime= Number(timePartsStartTime[0] * 60) + Number(timePartsStartTime[1]);
        return MinuteStartTime
  
    }

    //store then form info here...
    const submitForm=async()=>{
        // const {MinuteStartTime,MinuteEndTime,MinuteRequiredTime}=formatTimeToMinutes()
        try {
            setLoading(true)
            if(props&& props.shift&& props.shift.id>0){
                const resourcesResult = await axios({
                    url: Host + "shifts/"+props.shift.id,
                    method: "put",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    // params:{
                    //     shift_id:props.shift.id
                    // },
                    data:{
                        minuts_required:parseInt(requiredWorkingHour),
                        end_minutes:parseInt(endTimeSeconds),
                        start_minutes:parseInt(startTimeSeconds),
                    }
        
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                      toast.success(directions=='rtl'?'تم تحديث بنجاح':'data updated successfully')
                      props.setShowShiftForm(false,true)
                      
                    } else if (resourcesResult.data.status === false) {
                    toast.success(directions=='rtl'?'هنالك خطء غير معروف':'unknown error')

                    }
                }
                else {
                    toast.error("Error")
                }
            }else {
                const resourcesResult = await axios({
                    url: Host + "shifts",
                    method: "post",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data:{
                        minuts_required:parseInt(requiredWorkingHour),
                        end_minutes:parseInt(endTimeSeconds),
                        start_minutes:parseInt(startTimeSeconds),
                    }
        
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                    
                       toast.success(directions=='rtl'?'تم الانشاء بنجاح':'data created successfully')
                       props.setShowShiftForm(false,true)
                    } else if (resourcesResult.data.status === false) {
                        toast.success(directions=='rtl'?'هنالك خطء غير معروف':'unknown error')
                    }
                }
                else {
                    toast.error("Error")
                }
            }
            setLoading(false)
          
          } catch (error) {
              console.log("error===>",error);
              setLoading(false)
              const resourcesResult = error.response
              if (resourcesResult && resourcesResult.status === 400) {
                  if (typeof resourcesResult.data.data === "object") {
                      const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                      errorMessagesKeys.map((key) => {
      
                          resourcesResult.data.data[key].map((message) => {
                              toast.error(message)
                          })
                      })
                  }
                  else {
      
                      toast.error(resourcesResult.data.data)
                  }
      
      
              }
              else if (resourcesResult && resourcesResult.status === 401) {
      
                  toast.error(resourcesResult.data.data)
                  cookies.remove("token");
                  window.location.href = "/"
              }
              else {
      
                  toast.error("network error")
              }
          }
    }

    const setStartDateTime=(datetime)=>{
        let date=new Date(datetime)
        console.log('timeStart',endTimeSeconds)
        let TimeInMinutes=formatTimeToMinutesSingle(date)
        setStartWorkingHour(date)
        console.log('timeStart',TimeInMinutes)

        setStartTimeSeconds(TimeInMinutes)
        if(TimeInMinutes>endTimeSeconds){
          console.log('timeStart__',true)

            setStartTimeMessageError(t('SHIFTS.STARTTIME_MUSET_BE_LESS_FROM_END_TIME'))
        }else{
          console.log('timeStart__',false)

            setStartTimeMessageError('')
        }
    }
    const setEndDateTime=(datetime)=>{
        let date=new Date(datetime)

        let TimeInMinutes=formatTimeToMinutesSingle(date)
        setEndWorkingHour(date)
        setEndTimeSeconds(TimeInMinutes)
        if(TimeInMinutes<startTimeSeconds){
            setEndTimeMessageError(t('SHIFTS.ENDTIME_MUSET_BE_GREATER_FROM_START_TIME'))
        }else{
            setEndTimeMessageError('')
        }
    }
    const setRequiredTimeChanged=(e)=>{
        console.log('sliderValue',e.target.value)
        setRequiredWorkingHour(e.target.value)
    }

    useEffect(()=>{
        if(startTimeSeconds&&endTimeSeconds){
              let differences=Math.floor((endTimeSeconds-startTimeSeconds)/60)
              setRequiredWorkingHour(differences)
        }
    },[startTimeSeconds, endTimeSeconds])

    return (
        <DialogForm
           open={props.setShowShiftForm}
           close={props.setShowShiftForm}
           CustomeWidth='60%'
           CustomeHeight="fit-content"
        >
            <form >
                <Box
                  sx={{
                    '& .muiltr-a4ms82-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root,& svg,& .muirtl-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':{
                        color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                     },
                    '& input':{
                        color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                    
                        },
                        '& .MuiOutlinedInput-root':{
                            borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
                            color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                        },
                        '& .MuiInputLabel-root':{
                            // backgroundColor:`${MainTheme?.paperTextColor+'22'} !important`,
                            color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                        },
                        '& .MuiFormControl-root':{
                          // border:`1px solid ${MainTheme?.paperTextColor} !important`,
                          color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                          },
                          '&:hover fieldset': {
                            borderColor:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: MainTheme?.secondaryColor,
                            color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.secondaryColor,

                          },
                        },
                        '& .MuiOutlinedInput':{
                          // borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            // border: "0 none",
                         },
                         '& .muirtl-bitr9f-MuiSlider-root':{
                            borderradius: '21px !important',
                            boxsizing: 'content-box !important',
                            display:' inline-block !important',
                            position: 'relative !important',
                            cursor: 'pointer !important',
                            touchAction: 'none !important',
                            color: '#1976d2  !important',
                            WebkitTapHighlightColor: 'transparent !important',
                            height: '11px !important',
                            width: {lg:'44.9%',md:'98%',sm:'98%',xs:'98%'},
                            padding: '13px 0 !important',
                            // margin: '12px; 1px !important',
                            top:'30px !important'

                        },
                  }}
                >
                
                <div className="form-group">
                    

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box sx={{
                        display:'flex',justifyContent:'start',alignItems:'center',
                        flexFlow: 'row wrap',
                        }}>
                        {/* <TextField
                            id="name"
                            
                            sx={{margin:'10px',width:{lg:'45%',md:'98%',sm:'98%',xs:'98%'},}}
                            label={t('ROLES.FORMADD_NAME')}
                            value={object.name}
                            onChange={event => { setobject({ ...object, name: event.target.value }); }}
                            variant="standard"
                        /> */}
                        
                        <TimePicker
                        ampm={false}
                        label={t('SHIFTS.FORM_START_WORKING_HOURS')}
                        inputFormat="HH:mm"
                        value={startWorkingHour}
                        // minutesStep={startTimeSeconds}
                        
                        onChange={setStartDateTime}
                        renderInput={(params) => <TextField
                                {...params} sx={{width:{lg:'45%',md:'98%',sm:'98%',xs:'98%'},margin:'10px'}} 
                                variant="standard" 
                                error={StartTimeMessageError?.length>0?true:false}
                                helperText={StartTimeMessageError}
                             />}
                        />
                        <TimePicker
                        ampm={false}
                        inputFormat="HH:mm"
                        label={t('SHIFTS.FORM_END_WORKING_HOURS')}
                        // minutesStep={endTimeSeconds}
                        value={endWorkingHour}
                        onChange={setEndDateTime}
                        renderInput={(params) => <TextField 
                                {...params} 
                                sx={{width:{lg:'45%',md:'98%',sm:'98%',xs:'98%'},margin:'10px'}} 
                                variant="standard" 
                                error={EndTimeMessageError?.length>0?true:false}
                                helperText={EndTimeMessageError}
                            />}
                        />
                        {/* <TimePicker
                        ampm={false}
                        inputFormat="HH:MM"
                        label={t('SHIFTS.REQUIRED_WORKING_HOURS')}
                        value={requiredWorkingHour}
                          onChange={(date)=>{
                            setRequiredWorkingHour(date)
                        }}
                        renderInput={(params) => <TextField {...params} sx={{width:{lg:'45%',md:'98%',sm:'98%',xs:'98%'},margin:'10px'}} variant="standard" />}
                        /> */}
                        <br />
                        <br />

                        <Slider 
                            sx={{
                                margin:'10px 22px 10px 10px !important',
                                width:{lg:'42%',md:'98%',sm:'98%',xs:'98%'},
                                padding:'10px',
                                marginTop:'40px !important',
                            }} 
                            defaultValue={requiredWorkingHour} 
                            value={requiredWorkingHour}
                            onChange={setRequiredTimeChanged}
                            aria-label="Default" marks={true} 
                            valueLabelDisplay="on" 
                            
                        />

                         {/* <TextField
                            id="required_workin_hourse"
                            sx={{margin:'10px',width:{lg:'45%',md:'98%',sm:'98%',xs:'98%'},}}
                            label={t('SHIFTS.FORM_REQUIRED_WORKING_HOURS')}
                            value={requiredWorkingHour}
                            type="number"
                            typeof="number"
                            onChange={setRequiredTimeChanged}
                            variant="standard"
                        /> */}
                    
                    </Box>
                    </LocalizationProvider>




                </div>
                    
                </Box>
            </form>

         
            <FooterBottons close={props.setShowShiftForm} submitForm={submitForm} />
       </DialogForm>

    );
};
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'blue',

    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
        width: '100%',
        border: '1px solid #ababab',
        borderRadius: 5,
        padding: 0,
        display: 'flex'
    }),
    container: () => ({

        width: '80%',
        position: 'relative',
        boxSizing: 'border-box',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}

export default ShiftForm;
