import React from 'react'
import {useSelector,useDispatch} from 'react-redux'
import {useTranslation} from "react-i18next";

import {
    ContainerOfForm,
    SaveButton,
    CancelButton,
    Form
} from '../Users/StyledComponents';


export default function FooterBottons({close,submitForm,hiddenSave}) {
    const directions =useSelector(state=> state.settingsData.directions);
    const MainTheme =useSelector(state=> state.settingsData.MainTheme);
    const {t, i18n} = useTranslation('common');

  return (
    <div style={{display:"flex",justifyContent: 'end'}} >
            <CancelButton color={MainTheme?.oppositeSecondaryColor} bgColor={MainTheme?.secondaryColor} onClick={()=>close(false)}>{t('GLOBAL.POPUP_CANCEL_BUTTON')}</CancelButton>
            {
            !hiddenSave?
            <SaveButton onClick={()=>submitForm()} color={MainTheme?.oppositeSecondaryColor} bgColor={MainTheme?.secondaryColor} className>{t('GLOBAL.POPUP_SAVE_BUTTON')}</SaveButton>
            :null
           }
    </div>
  )
}
