
import React, { useEffect, useState } from 'react';
import VisitForm from './VisitForm';
import VisitMapDialog from './VisitMapDialog';
import VisitsMapDialog from './VisitsMapDialog';
import host from '../../../assets/js/Host';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import IconButton from "@mui/material/IconButton";
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import MapRoundedIcon from '@mui/icons-material/MapRounded';
import { toast } from 'react-toastify';
import moment from 'moment';

import Tooltip from '@mui/material/Tooltip';
import CircularIndeterminate from '../GeneralComponents/CirularProgress'
import SearchField1 from '../GeneralComponents/SearchField1'
// import ExportCSV from  '../GeneralComponents/ExportCSV'
import StyledDataGrid from '../GeneralComponents/StyledDataGrid'

import "../../../assets/css/DG.css"
import "../../../assets/css/AccordionStyle.css"

import FilterBox from '../Share/FilterBox';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import Pagination from '@mui/material/Pagination';

import { useGlobalContext } from '../../../context'
import BasicDatePicker from '../GeneralComponents/BasicDatePicker';
import PointsMapDialog from '../Points/PointsMapDialog';
import Grid from '../Share/Grid'
import {useSelector,useDispatch} from 'react-redux'
import {useTranslation} from "react-i18next";
import DropDownGrid from '../Share/DropDownGrid'
import {MenuItem,ListItemIcon} from '@mui/material'
import helpers from '../../../assets/js/helper'
import BasicDatePickerV2 from '../GeneralComponents/BasicDatePickerV2';
import SearchFieldV2 from '../GeneralComponents/SearchFieldV2'
import CustomeFilter from '../Share/CustomeFilter'

function VisitsReport() {
    const [t, i18n] = useTranslation('common');
    const [showVisitForm, setshowVisitForm] = useState(false);
    const [showVisitMapDialog, setshowVisitMapDialog] = useState(false)
    const [showVisitsMapDialog, setshowVisitsMapDialog] = useState(false)
    const directions= useSelector(state => state.settingsData.directions)
    const FilterButtonHeight= useSelector(state => state.settingsData.FilterButtonHeight)
    const GridTheme =useSelector(state=>state.settingsData.GridTheme)
    const [showVisitPreview, setshowVisitPreview] = useState(false)
    const [selectedVisit, setselectedVisit] = useState(null);
    const [pageSize, setPageSize] = useState(5);
    const [pageNumber, setpageNumber] = useState(1);
    const [visitsReportTotal, setVisitsTotal] = useState(0)
    const [visitsReportNumberOfPages, setVisitsNumberOfPages] = useState(0)
    const { loading,setSelectedUsers,selectedUsers, setUser, users, user, minDistance, maxDistance, setMinDistance, setMaxDistance, visitTypesList, fromToDate, visitType, setVisitType, exportToCSV, allVisits, visitsReport, district, region, districts, regions, ownerName, shopName, clearVisitsAdvanceSearchFields, setRegion, setDistrict, setShopName, setOwnerName, fetchData, deleteObject } = useGlobalContext()
    const MainTheme=useSelector(state=>state.settingsData.MainTheme)
    const [accExpansion, setAccExpansion] = useState(false)
    const [mapMarkers, setMapMarkers] = useState([])
    // const FilterButtonHeight= useSelector(state=> state.settingsData.FilterButtonHeight);
    const SearchButtonTheme=useSelector(state=> state.settingsData.SearchButtonTheme);
    const screenWidthRedux=useSelector(state=>state.settingsData.screenWidth)
    const filterSearchBoxMode = useSelector(state=> state.settingsData.filterSearchBoxMode)

    const handlePageChange = (newPage) => {
        setpageNumber(newPage + 1)
    }

   
    const handleExpansion = (expansion) => setAccExpansion(expansion)


    

    const handleSetshowVisitForm = (openStatus, needsReload) => {
        if (needsReload)
            // fetchData();       
            fetchData('VISITS_REPORT', 'visits_report', {
                page: pageNumber,
                page_size: pageSize,
                shop_name: shopName,
                owner_name: ownerName,
                district: district.id,
                region: region.id,
                visit_type_id: visitType.map((type) => type.id),
                min_distance: maxDistance,
                max_distance: minDistance,
                collectors:selectedUsers? selectedUsers?.map(user=>user?.id):null
            });
        setshowVisitForm(openStatus)
    };

   

    
    const mapObject = async (object) => {

        const index =0
        const indexVisit=0
        setMapMarkers([
            {
                id:-1,
                name:"End",
                label:"E" + (index + 1) + "-" + (indexVisit + 1),
                latitude:object?.end_latitude,
                longitude:object?.end_longitude,
                owner_name:object?.created_by?.full_name,
                specialColor:"#FF00FF",
                point_type:{
                    name:""
                }
            },
            {
                id:-2,
                name:"Start",
                label:"S" + (index + 1) + "-" + (indexVisit + 1),
                latitude:object?.start_latitude,
                longitude:object?.start_longitude,
                owner_name:object?.created_by?.full_name,
                specialColor:"#00FFFF",
                point_type:{
                    name:""
                }
            },
            {
                ...object?.point,
                label: "P" + (index + 1)
            }
        ])
        
        setselectedVisit(object)
        setshowVisitMapDialog(true)
    }
    const [mapVisits, setMapVisits] = useState([])

    const mapObjects = async (object) => {

        if (object.length > 3000) {
            return (
                toast.error(`Too many visits to display!`)
            );
        }
        

        if(object?.length>0)
        {
            let markers = []
            object?.map((mapPoint, index) => {

                mapPoint?.visits?.map((visit, indexVisit) => {
                    markers.push(
                        {
                            id:visit?.id,
                            name:"End",
                            label:"E" + (index + 1) + "-" + (indexVisit + 1),
                            latitude:visit?.end_latitude,
                            longitude:visit?.end_longitude,
                            owner_name:visit?.created_by?.full_name,
                            specialColor:"#FF00FF",
                            point_type:{
                                name:""
                            }
                        })
                        markers.push(
                        {
                            id:visit?.id*-1,
                            name:"Start",
                            label:"S" + (index + 1) + "-" + (indexVisit + 1),
                            latitude:visit?.start_latitude,
                            longitude:visit?.start_longitude,
                            owner_name:visit?.created_by?.full_name,
                            specialColor:"#00FFFF",
                            point_type:{
                                name:""
                            }
                        })
                        
                })
                markers.push(
                {
                    ...mapPoint?.point,
                    label: "P" + (index + 1)
                })
            })

            setMapMarkers(markers)
            
        }
        setMapVisits(object)
        setshowVisitsMapDialog(true)

    }

    const handleSetshowVisitMapDialog = (openStatus, needsReload) => {
        if (needsReload)
            advanceSearch()
        setshowVisitMapDialog(openStatus)
    }

    const handleSetshowVisitsMapDialog = (openStatus, needsReload) => {
        if (needsReload)
            advanceSearch()
        setshowVisitsMapDialog(openStatus)
    }
    

    const advanceSearch = () => {

        fetchData('VISITS_REPORT', 'visits_report', {
            page: pageNumber,
            page_size: pageSize,
            shop_name: shopName,
            owner_name: ownerName,
            district: district.id,
            region: region.id,
            visit_type_id: visitType.map((type) => type.id),
             creation_date_from: fromToDate[0]?helpers.formatDate(fromToDate[0]):helpers.formatDate(new Date()),
            creation_date_to:fromToDate[1]?helpers.formatDate(fromToDate[1]):helpers.formatDate(new Date()),
            min_distance: maxDistance,
            max_distance: minDistance,
            collectors:selectedUsers? selectedUsers?.map(user=>user?.id):null
        });
    }

    //Initial fetching for data
    useEffect(() => {
        fetchData('DISTRICTS', 'districts_list')
        fetchData('REGIONS', 'regions_list')
        fetchData('USERS', 'users_list')
        fetchData('VISIT_TYPES_LIST', 'visit_types_list')
        //to fetch visits
        advanceSearch()

    }, [pageSize, pageNumber])

    //Reformatting visits object once visits are loaded
    useEffect(() => {

        if (visitsReport && visitsReport.data && visitsReport.data.length > 0) {
            // console.log(visitsReport.data)
            const reformatVisits = visitsReport?.data;
           
            setRow(reformatVisits);
            //  console.log(visitsReport.total)
            setVisitsTotal(visitsReport.total)
            setVisitsNumberOfPages(visitsReport.last_page)
        }
        else {
            setRow([]);
            //  console.log(visitsReport.total)
            setVisitsTotal(0)
            setVisitsNumberOfPages(0)
        }
    }, [visitsReport])

    const columns= [
        { field: 'id', headerName: t('VISIT_REPORT.ID'),     
             minWidth:40,
            // maxWidth:250,
            flex: 1,
         },
        {
            field: "name",
            // width: 450,
            minWidth:150,
            // maxWidth:550,
            // flex:5,
            headerName:  t('VISIT_REPORT.POINTDETAILS'),
            renderCell: (rows) => (
                <div style={{ width: '100%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:directions=='rtl'?'end':'start' }}  className={`${directions =='rtl'?'alignColumnRight_2':'alignColumnLeft_2'}`}>
                   <span> {rows?.row?.point?.name}</span>
                   <span> {rows?.row?.point?.phone}</span>
                </div>
            )
        },
        // {
        //     field: "phone",
        //     // // width: 450,
        //     minWidth:150,
        //     // maxWidth:450,
        //     // flex:5,

        //     headerName: t('VISIT_REPORT.PHONE'),
        //     renderCell: (rows) => (
        //         <div style={{ width: 'max-content',minWidth:150,maxWidth:350}} className={`${directions =='rtl'?'alignColumnRight_2':'alignColumnLeft_2'}`}>
        //             {rows?.row?.point?.phone}
        //         </div>
        //     )
        // },
        {
            field: "owner_name",
            // width:450,
            minWidth:150,
            // maxWidth:550,
            // flex:5,
            headerName:  t('VISIT_REPORT.OWNER_NAME'),
            renderCell: (rows) => (
                <div style={{ width: 'max-content',minWidth:150,maxWidth:350}} className={`${directions =='rtl'?'alignColumnRight_2':'alignColumnLeft_2'}`}>
                    {rows?.row?.point?.owner_name}
                </div>
            )
        },
        {
            field: "region",
            // width: 450,
            minWidth:150,
            // maxWidth:550,
            // flex:4,

            headerName:  t('VISIT_REPORT.REGION'),
            renderCell: (rows) => (
                <div style={{ display:'flex',flexDirection:'column',justifyContent:'center',alignItems:directions=='rtl'?'end':'start'}} className={`${directions =='rtl'?'alignColumnRight_2':'alignColumnLeft_2'}`}>
                    <span> {rows?.row?.point?.region?.name}</span>
                    <span> {rows?.row?.point?.region?.district?.name}</span>
                </div>
            )
        },
        // {
        //     field: "district",
        //     // width: 450,
        //     minWidth:150,
        //     // maxWidth:550,
        //     // flex:4,

        //     headerName: t('VISIT_REPORT.DISTRICT'),
        //     renderCell: (rows) => (
        //         <div style={{ width: 'max-content',minWidth:150,maxWidth:350}} className={`${directions =='rtl'?'alignColumnRight_2':'alignColumnLeft_2'}`}>
        //             {rows?.row?.point?.region?.district?.name}
        //         </div>
        //     )
        // },
        {
            field: "by",
            // width: 450,
            minWidth:150,
            // maxWidth:550,
            // flex:4,
            headerName:  t('VISIT_REPORT.BY'),
            renderCell: (rows) => (
                <div style={{ width: 'max-content',minWidth:150,maxWidth:350}} className={`${directions =='rtl'?'alignColumnRight_2':'alignColumnLeft_2'}`}>
                    {rows?.row?.created_by?.full_name}
                </div>
            )
        },
        {
            field: "start_date",
            // width: 450,
            minWidth:200,
            // maxWidth:550,
            // flex:4,
            headerName:  `${t('VISIT_REPORT.START_DATE')} - ${t('VISIT_REPORT.END_DATE')}`,
            renderCell: (rows) => (
                <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:directions=='rtl'?'end':'start'}} className={`${directions =='rtl'?'alignColumnRight_2':'alignColumnLeft_2'}`}>
                    {/* {rows?.row?.start_date}<br /> */}
                    <span style={{textAlign:directions=='rtl'?'right':'left'}}> {rows?.row?.start_date}</span>
                   <span style={{textAlign:directions=='rtl'?'right':'left'}}> {rows?.row?.end_date}</span>
                </div>
            )
        },
        {
            field: "start_distance",
            // width: 450,
            minWidth:210,
            // maxWidth:550,
            // flex:4,
            headerName:  `${t('VISIT_REPORT.START_D')} - ${t('VISIT_REPORT.END_D')}`,
            renderCell: (rows) => (
                <div style={{ width: '100%', textAlign: 'center',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:directions=='rtl'?'center':'center' }} className={`${directions =='rtl'?'alignColumnRight_2':'alignColumnLeft_2'}`}>
                    <span style={{textAlign:'center'}}>{rows?.row?.start_distance ? Math.round(rows?.row?.start_distance) + " M" : "N/A"}</span>
                    <span style={{textAlign:'center'}}>{rows?.row?.end_distance ? Math.round(rows?.row?.end_distance) + " M" : "N/A"}</span>
                </div>
            )
        },
        // {
        //     field: "end_date",
        //     // width: 450,
        //     minWidth:150,
        //     // maxWidth:550,
        //     // flex:4,

        //     headerName:  t('VISIT_REPORT.END_DATE'),
        //     renderCell: (rows) => (
        //         <div style={{ width: 'max-content',minWidth:150,maxWidth:350}} className={`${directions =='rtl'?'alignColumnRight_2':'alignColumnLeft_2'}`}>
        //             {rows?.row?.end_date}<br />
        //         </div>
        //     )
        // },
        // {
        //     field: "end_distance",
        //     // width: 450,
        //     minWidth:150,
        //     // maxWidth:550,
        //     // flex:4,

        //     headerName:  t('VISIT_REPORT.END_D'),
        //     renderCell: (rows) => (
        //         <div style={{ width: '100%', textAlign: 'center' }} className={`${directions =='rtl'?'alignColumnRight_2':'alignColumnLeft_2'}`}>
        //             {rows?.row?.end_distance ? Math.round(rows?.row?.end_distance) + " M" : "N/A"}
        //         </div>
        //     )
        // },
        {
            field: "road_time",
            // width: 450,
            minWidth:150,
            // maxWidth:550,
            // flex:4,

            headerName:  t('VISIT_REPORT.ROAD_TIME'),
            renderCell: (rows) => (
                <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:directions=='rtl'?'end':'start' }} className={`${directions =='rtl'?'alignColumnRight_2':'alignColumnLeft_2'}`}>
                    <span>{rows?.row?.attendance_calculation?.road_time ? rows?.row?.attendance_calculation?.road_time : "N/A"}</span>
                    <span>{rows?.row?.attendance_calculation?.visit_total_time ? rows?.row?.attendance_calculation?.visit_total_time : "N/A"}</span>

                </div>
            )
        },
        // {
        //     field: "visit_total_time",
        //     // width: 450,
        //     minWidth:150,
        //     // maxWidth:550,
        //     // flex:4,
        //     headerName:  t('VISIT_REPORT.VISIT_TIME'),
        //     renderCell: (rows) => (
        //         <div style={{ width: 'max-content',minWidth:150,maxWidth:350}} className={`${directions =='rtl'?'alignColumnRight_2':'alignColumnLeft_2'}`}>
        //             {rows?.row?.attendance_calculation?.visit_total_time ? rows?.row?.attendance_calculation?.visit_total_time : "N/A"}
        //         </div>
        //     )
        // },

        // { field: 'phone', headerName: 'Phone' , flex: 1},        
        {
            field: 'actions',
            // width: 100,
            minWidth:150,
            // maxWidth:250,
            // flex:4,

            headerName:  t('VISIT_REPORT.ACTIONS'),
            renderCell: (rows) => (
                <DropDownGrid>
                    <MenuItem onClick={() => {  mapObject(rows.row)}}>
                        <ListItemIcon sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            margin:'0px 7px 0 0'
                        }}>
                        <LocationOnRoundedIcon style={{ fill: "#5cb85c" }} />

                        </ListItemIcon>
                        {directions != 'rtl' ? "Map" : 'خريطة'}
                    </MenuItem>
                </DropDownGrid>
            ),
        },
    ]

    const [rows, setRow] = useState([])

    const ExportFunction=async () => {
        const fetchedData = await fetchData('ALL_VISITS', 'visits_report', {
            page_size: visitsReportTotal,
            shop_name: shopName,
            owner_name: ownerName,
            district: district.id,
            region: region.id,
            creation_date_from: moment(fromToDate[0])?.format('YYYY-MM-DD'),
            creation_date_to: moment(fromToDate[1])?.format('YYYY-MM-DD'),
            min_distance: maxDistance,
            max_distance: minDistance,
            collectors:selectedUsers? selectedUsers?.map(user=>user?.id):null
        })
        const exportedData = fetchedData?.data?.map((item => {
            return {
                id: item.id,
                point_id: item?.point?.id,
                owner_name: item?.point?.owner_name,
                point_name: item?.point?.name,
                region: item?.point?.region?.name,
                district: item?.point?.region?.district?.name,
                phone: item?.point?.phone,
                created_by: item?.point?.created_by?.full_name,
                visited_by: item?.created_by?.full_name,
                start_visit_distance: item?.start_distance,
                end_visit_distance: item?.end_distance,
                start_date: item?.start_date,
                end_date: item?.end_date,
                road_time: item?.attendance_calculation?.road_time,
                visit_total_time: item?.attendance_calculation?.visit_total_time,
            }

        }))



        exportToCSV(exportedData, 'visits_report')
    }
    const createFunction=()=>{
        // createObject()
    }
    const mapFunction=async () => {
        
        // const fetchedData = await fetchData('ALL_VISITS', 'visits_map_report', {
        //     page_size: visitsReportTotal,
        //     shop_name: shopName,
        //     owner_name: ownerName,
        //     district: district.id,
        //     region: region.id,
        //     visit_type_id: visitType.map((type) => type.id),
        //     creation_date_from: moment(fromToDate[0])?.format('YYYY-MM-DD'),
        //     creation_date_to: moment(fromToDate[1])?.format('YYYY-MM-DD'),
        //     min_distance: maxDistance,
        //     max_distance: minDistance,
        //     collectors:selectedUsers? selectedUsers?.map(user=>user?.id):null
        // })
        // mapObjects(fetchedData)
        setshowVisitsMapDialog(true)

    }
    const importFunction=()=>{
        // importObjects()
    }

    return (

        <>
            {showVisitForm ?
                <VisitForm visit={selectedVisit} showVisitForm={showVisitForm} setshowVisitForm={handleSetshowVisitForm} />
                : null}
            {showVisitMapDialog ?
            
            <PointsMapDialog title={t('GLOBAL.MAPS_VIEW_VISIT_FOR_POINT')+"  "+selectedVisit?.point?.name} inVisitPage={true} loading={loading} points={mapMarkers}  showPointsMapDialog={showVisitMapDialog} setshowPointsMapDialog={handleSetshowVisitMapDialog} />
            : null}
          
            {showVisitsMapDialog ?
                <PointsMapDialog title={t('GLOBAL.MAPS_VIEW_ALL_VISIT_AND_POINT')} visitsReportTotal={visitsReportTotal} hasVisitReport={true} inVisitPage={false} loading={false} points={mapMarkers} showPointsMapDialog={showVisitsMapDialog} setshowPointsMapDialog={handleSetshowVisitsMapDialog} />
                : null}

            {
            !filterSearchBoxMode?
                <FilterBox 
                    exportFunction={ExportFunction}
                    // createFunction={createFunction}
                    mapFunction={mapFunction}
                    // importFunction={importFunction}
                    hasExport={true} 
                    hasCreate={false} 
                    hasMaps={true} 
                    hasImport={false}
                    resultLength={visitsReport?.total ? visitsReport?.total : 0}
                    customeHeight={screenWidthRedux<=1100?'520px':"350px"}
                >
                        <Autocomplete
                            sx={{ ml: 1.7,width:screenWidthRedux<=1100?'98%':'47%',mt:1 }}
                            fullWidth
                            options={districts}
                            getOptionLabel={(option) => option.name || ""}
                            id="disable-close-on-select"
                            value={district}
                            onChange={(e, v, r) => { setDistrict(v ? v : '') }}
                            renderInput={(params) => (
                                <TextField {...params} 
                                label={t('GLOBAL.SEARCH_DISTRICTS')}
                                variant="standard" sx={{borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}} />
                            )}
                        />
                        <Autocomplete
                            sx={{ ml: 1.7,width:screenWidthRedux<=1100?'98%':'47%',mt:1 }}
                            fullWidth
                            options={regions}
                            getOptionLabel={(option) => option.name || ""}
                            id="disable-close-on-select"
                            value={region}
                            onChange={(e, v, r) => { setRegion(v ? v : '') }}

                            renderInput={(params) => (
                                <TextField {...params} 
                                label={t('GLOBAL.SEARCH_REGION')}
                                variant="standard" sx={{borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}} />
                            )}
                        />

                        <SearchField1 customeWidth={screenWidthRedux<=1100?'98%':"47%"}  state={minDistance} type={'number'} setState={setMinDistance} fieldLabel={t('GLOBAL.SEARCH_MAX_DISTANCE')} />
                        <SearchField1 customeWidth={screenWidthRedux<=1100?'98%':"47%"}  state={maxDistance} type={'number'} setState={setMaxDistance} fieldLabel={t('GLOBAL.SEARCH_MIN_DISTANCE')} />

                        <Autocomplete
                            sx={{ ml: 1.7,width:screenWidthRedux<=1100?'98%':'47%',mt:1 }}
                            fullWidth
                            multiple
                            options={users}
                            getOptionLabel={(option) => option.full_name || ""}
                            id="disable-close-on-select"
                            value={selectedUsers}
                            onChange={(e,v,r) => {setSelectedUsers(v ? v:[]);}}
                            renderInput={(params) => (
                                <TextField {...params}
                                label={t('GLOBAL.SEARCH_USERS')} 
                                variant="standard" sx={{borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}} />
                            )}
                        />            
                        <BasicDatePicker customeWidth={screenWidthRedux<=1100?'99%':"47.3%"} customeMargin="-36px" customePaddingLeft="5px" marginMorLitileBit={true} />

                        <SearchField1 customeWidth={screenWidthRedux<=1100?'98%':"47%"} customeMargin="-36px"   state={shopName} setState={setShopName} fieldLabel={t('GLOBAL.SEARCH_SHOPE_NAME')} />
                        <SearchField1 customeWidth={screenWidthRedux<=1100?'98%':"47%"} customeMargin="-36px"  state={ownerName} setState={setOwnerName} fieldLabel={t('GLOBAL.SEARCH_OWNER_NAME')} />

                        <Box sx={{display:'flex',justifyContent: 'end',alignItems: 'center',width:'100%'}}>
                            <Box display="flex" >
                                <Button
                                    variant="contained"
                                    spacing={2} 
                                    sx={{ flexGrow: 1, margin: 1,backgroundColor:SearchButtonTheme?.search_button_color,color:SearchButtonTheme?.search_button_text_color,
                                        '&:hover':{
                                            backgroundColor:SearchButtonTheme?.search_button_color+'88',
                                        },
                                        height:FilterButtonHeight
                                    }}
                                    className="iconeFilterSearch"
                                    onClick={() => advanceSearch()} >
                                    {t('GLOBAL.SEARCH_SEARCH_BUTTON')}
                                </Button>
                                <Button variant="contained"
                                    spacing={2}
                                    sx={{
                                            flexGrow: 1, margin: 1,
                                            // backgroundColor:SearchButtonTheme?.clear_button_color,
                                            color:SearchButtonTheme?.clear_button_text_color,
                                            borderColor:`${SearchButtonTheme?.clear_button_color}`,
                                            outlineColor:`${SearchButtonTheme?.clear_button_color}`,
                                        '&:hover':{
                                            backgroundColor:`${SearchButtonTheme?.clear_button_color}   !important`,
                                        },
                                        height:FilterButtonHeight
                                    }}
                                    className="iconeFilterClear"
                                    color="secondary"
                                    onClick={clearVisitsAdvanceSearchFields} >
                                    {t('GLOBAL.SEARCH_CLEAR_BUTTON')}
                                </Button>
                        </Box>
                        </Box>

                </FilterBox>:null
            }
           
           <Box 
             sx={{
                display:'flex',
                justifyContent: 'start',
                alignItems: 'start',
                width:'100%',
             }}
            >
                <Box
                    sx={{
                    width:'100%',
                    }}
                >
                    <Grid
                    rows={rows}
                    columns={columns}
                    rowTotals={visitsReport?.total ? visitsReport?.total : 0}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    pageNumber={pageNumber}
                    setPageNumber={setpageNumber}
                    loading={loading}
                    handlePageChange={handlePageChange}
                    NumberOfPage={visitsReportNumberOfPages}
                    directions={directions}
                    hasCreate={false}
                    createObject={null}
                    visitReport={true}
                    title_en=""
                    title_ar=""
                    gridHeight={{
                        height:rows?.length>5?pageSize==5?400:pageSize==10?650:950:400,
                    }}
                    />
                </Box>
                {
                    filterSearchBoxMode?
                    <CustomeFilter
                        exportFunction={ExportFunction}
                        // createFunction={createFunction}
                        mapFunction={mapFunction}
                        // importFunction={importFunction}
                        hasExport={true} 
                        hasCreate={false} 
                        hasMaps={true} 
                        hasImport={false}
                        resultLength={visitsReport?.total ? visitsReport?.total : 0}
                        // customeHeight={screenWidthRedux<=1100?'520px':"350px"}
                    >
                        <Box
                            sx={{
                                width:'100%',
                                height:'100%',
                                // minHeight:'400px',
                                // maxHeight:'400px',
                                overflowY:'scroll',
                                overflowX:'hidden',
                                padding:'10px 13px',
                                marginTop:'10px',
                                // paddingTop:'20px',
                                // boxShadow:'0 0 7px -5px #000 inset'
                            }}
                        >

                            <Autocomplete
                                sx={{ml:0.5, width:screenWidthRedux<=1100?'100%':'100%',marginTop:'10px !important',marginBottom:'10px !important' }}
                                fullWidth
                                options={districts}
                                getOptionLabel={(option) => option.name || ""}
                                id="disable-close-on-select"
                                value={district}
                                onChange={(e, v, r) => { setDistrict(v ? v : '') }}
                                renderInput={(params) => (
                                    <TextField {...params} 
                                    placeholder={t('GLOBAL.SEARCH_DISTRICTS')}
                                    variant="standard" sx={{borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}} />
                                )}
                            />
                        <Autocomplete
                            sx={{ml:0.5,width:screenWidthRedux<=1100?'100%':'100%',marginTop:'10px !important',marginBottom:'10px !important'  }}
                            fullWidth
                            options={regions}
                            getOptionLabel={(option) => option.name || ""}
                            id="disable-close-on-select"
                            value={region}
                            onChange={(e, v, r) => { setRegion(v ? v : '') }}

                            renderInput={(params) => (
                                <TextField {...params} 
                                placeholder={t('GLOBAL.SEARCH_REGION')}
                                variant="standard" sx={{borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}} />
                            )}
                        />

                        <SearchFieldV2 marginLeftValue={0.5} customeWidth={screenWidthRedux<=1100?'100%':"100%"}  state={minDistance} type={'number'} setState={setMinDistance} fieldLabel={t('GLOBAL.SEARCH_MAX_DISTANCE')} />
                        <SearchFieldV2 marginLeftValue={0.5} customeWidth={screenWidthRedux<=1100?'100%':"100%"}  state={maxDistance} type={'number'} setState={setMaxDistance} fieldLabel={t('GLOBAL.SEARCH_MIN_DISTANCE')} />

                        <Autocomplete
                            sx={{ ml:0.5,width:screenWidthRedux<=1100?'100%':'100%',marginTop:'10px !important',marginBottom:'10px !important'  }}
                            fullWidth
                            multiple
                            options={users}
                            getOptionLabel={(option) => option.full_name || ""}
                            id="disable-close-on-select"
                            value={selectedUsers}
                            onChange={(e,v,r) => {setSelectedUsers(v ? v:[]);}}
                            renderInput={(params) => (
                                <TextField {...params}
                                placeholder={t('GLOBAL.SEARCH_USERS')} 
                                variant="standard" sx={{borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}} />
                            )}
                        />            
                        <BasicDatePickerV2 customeWidth={screenWidthRedux<=1100?'100%':"100%"}  />

                        <SearchFieldV2 marginLeftValue={0.5} customeWidth={screenWidthRedux<=1100?'100%':"100%"}   state={shopName} setState={setShopName} fieldLabel={t('GLOBAL.SEARCH_SHOPE_NAME')} />
                        <SearchFieldV2 marginLeftValue={0.5} customeWidth={screenWidthRedux<=1100?'100%':"100%"}  state={ownerName} setState={setOwnerName} fieldLabel={t('GLOBAL.SEARCH_OWNER_NAME')} />

                         
                        </Box>
                        <Box sx={{display:'flex',justifyContent: 'end',alignItems: 'center',width:'100%'}}>
                            <Box display="flex" >
                                    <Button
                                        variant="contained"
                                        // spacing={2} 
                                        sx={{ 
                                            // flexGrow: 1,
                                            margin: 1,
                                            width:'80px !important',
                                            minWidth:'80px !important',
                                            maxWidth:'80px !important',
                                            backgroundColor:SearchButtonTheme?.search_button_color,color:SearchButtonTheme?.search_button_text_color,
                                            '&:hover':{
                                                backgroundColor:SearchButtonTheme?.search_button_color+'88',
                                            },
                                            height:FilterButtonHeight-10
                                        }}
                                        className="iconeFilterSearch"
                                        onClick={() => advanceSearch("SEARCH")} >
                                        {t('GLOBAL.SEARCH_SEARCH_BUTTON')}
                                    </Button>
                                    <Button variant="outlined"
                                        // spacing={2}
                                        sx={{
                                            width:'50% !important',
                                                // flexGrow: 1, 
                                                minWidth:'80px !important',
                                                maxWidth:'80px !important',
                                                margin: 1,
                                                // backgroundColor:SearchButtonTheme?.clear_button_color,
                                                color:SearchButtonTheme?.clear_button_text_color,
                                            '&:hover':{
                                                backgroundColor:`${SearchButtonTheme?.clear_button_color}   !important`,
                                            },
                                            height:FilterButtonHeight-10
                                        }}
                                        className="iconeFilterClear"
                                        color="secondary"
                                        onClick={clearVisitsAdvanceSearchFields} >
                                        {t('GLOBAL.SEARCH_CLEAR_BUTTON')}
                                    </Button>
                            </Box>
                        </Box>
                    </CustomeFilter>:null
                }
            </Box>

        </>
    );
}

export default VisitsReport;



// <Box display="flex" >
// <Button display="flex"
//     startIcon={<FileDownloadIcon sx={{color:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositeSecondaryColor}}/>}
//     variant="contained"
//     sx={{height:FilterButtonHeight, marginRight: 1, marginBottom: accExpansion ? "0" : "20px",backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor  }}
//     onClick={async () => {
//         const fetchedData = await fetchData('ALL_VISITS', 'visits_report', {
//             page_size: visitsReportTotal,
//             shop_name: shopName,
//             owner_name: ownerName,
//             district: district.id,
//             region: region.id,
//             creation_date_from: moment(fromToDate[0])?.format('YYYY-MM-DD'),
//             creation_date_to: moment(fromToDate[1])?.format('YYYY-MM-DD'),
//             min_distance: maxDistance,
//             max_distance: minDistance,
//             collectors:selectedUsers? selectedUsers?.map(user=>user?.id):null
//         })
//         const exportedData = fetchedData?.data?.map((item => {
//             return {
//                 id: item.id,
//                 point_id: item?.point?.id,
//                 owner_name: item?.point?.owner_name,
//                 point_name: item?.point?.name,
//                 region: item?.point?.region?.name,
//                 district: item?.point?.region?.district?.name,
//                 phone: item?.point?.phone,
//                 created_by: item?.point?.created_by?.full_name,
//                 visited_by: item?.created_by?.full_name,
//                 start_visit_distance: item?.start_distance,
//                 end_visit_distance: item?.end_distance,
//                 start_date: item?.start_date,
//                 end_date: item?.end_date,
//                 road_time: item?.attendance_calculation?.road_time,
//                 visit_total_time: item?.attendance_calculation?.visit_total_time,
//             }

//         }))



//         exportToCSV(exportedData, 'visits_report')
//     }
//     }>
//     {t('GLOBAL.SEARCH_EXPORT_BUTTON')}
// </Button>

// {/* <Button display="flex"
//     startIcon={<AddCircleRoundedIcon />}
//     variant="contained"
//     sx={{ marginRight: 1, marginBottom: accExpansion ? "0" : "20px" }}
//     onClick={() => { createObject() }}>
//     Create
// </Button> */}

// <Button display="flex"
//     startIcon={<MapRoundedIcon sx={{color:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositeSecondaryColor}}/>}
//     variant="contained"
//     sx={{ height:FilterButtonHeight,marginRight: 1, marginBottom: accExpansion ? "0" : "20px",backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor   }}
//     onClick={async () => {
//         const fetchedData = await fetchData('ALL_VISITS', 'visits_map_report', {
//             page_size: visitsReportTotal,
//             shop_name: shopName,
//             owner_name: ownerName,
//             district: district.id,
//             region: region.id,
//             visit_type_id: visitType.map((type) => type.id),
//             creation_date_from: moment(fromToDate[0])?.format('YYYY-MM-DD'),
//             creation_date_to: moment(fromToDate[1])?.format('YYYY-MM-DD'),
//             min_distance: maxDistance,
//             max_distance: minDistance,
//             collectors:selectedUsers? selectedUsers?.map(user=>user?.id):null
//         })
//         mapObjects(fetchedData)
//     }
//     }>
//     {t('GLOBAL.SEARCH_MAP_BUTTON')}
// </Button>
// {/* <ExportCSV csvData={visitsReport.data} fileName={"test"} /> */}
// <Accordion display="flex" sx={{ flexGrow: 4,backgroundColor:GridTheme?.primaryColor }}
//     onChange={(event, expansion) => handleExpansion(expansion)}>
//     <AccordionSummary
//         expandIcon={<ExpandMoreIcon sx={{ color: GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositePrimaryColor }} />}
//         aria-controls="panel1a-content"
//         id="panel1a-header"
//     >
//         <Typography sx={{color:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositePrimaryColor}}>{t('GLOBAL.SEARCH_TITLE')}</Typography>
//     </AccordionSummary>
//     <AccordionDetails>
//         <Box component="span" display="flex" bgcolor="white" flexDirection="column" sx={{ borderRadius: 1 }}>
//             <Box sx={{ m: 1 }} component="span" display="flex" bgcolor="white" flexDirection="row" >
//                 <Box display="flex" sx={{ flexGrow: 2 }}>
//                     <Autocomplete
//                         sx={{ ml: 1 }}
//                         fullWidth
//                         options={districts}
//                         getOptionLabel={(option) => option.name || ""}
//                         id="disable-close-on-select"
//                         value={district}
//                         onChange={(e, v, r) => { setDistrict(v ? v : '') }}
//                         renderInput={(params) => (
//                             <TextField {...params} 
//                             label={t('GLOBAL.SEARCH_DISTRICTS')}
//                              variant="outlined" />
//                         )}
//                     />
//                     <Autocomplete
//                         sx={{ ml: 1 }}
//                         fullWidth
//                         options={regions}
//                         getOptionLabel={(option) => option.name || ""}
//                         id="disable-close-on-select"
//                         value={region}
//                         onChange={(e, v, r) => { setRegion(v ? v : '') }}

//                         renderInput={(params) => (
//                             <TextField {...params} 
//                             label={t('GLOBAL.SEARCH_REGION')}
//                              variant="outlined" />
//                         )}
//                     />

//                 </Box>
//                 <Box display="flex" sx={{ flexGrow: 1 }}>
//                     <Button
//                         variant="contained"
//                         spacing={2} sx={{ flexGrow: 1, marginLeft: 1, marginRight: 1, height: 55,backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor,
//                             '&:hover':{
//                                 backgroundColor:GridTheme?.secondaryColor+'88',
//                             } }}
//                         onClick={advanceSearch} >
//                         {t('GLOBAL.SEARCH_SEARCH_BUTTON')}
//                     </Button>
//                     <Button variant="contained"
//                         spacing={2}
//                         sx={{ flexGrow: 1, margin: 0, height: 55,backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor,
//                             '&:hover':{
//                                 backgroundColor:GridTheme?.secondaryColor+'88',
//                             } }}
//                         color="secondary"
//                         onClick={clearVisitsAdvanceSearchFields} >
//                         {t('GLOBAL.SEARCH_CLEAR_BUTTON')}
//                     </Button>
//                 </Box>
//             </Box>


//             <Box sx={{ m: 1 }} component="span" display="flex" bgcolor="white" flexDirection="row">
//                 <Box sx={{
//                     display: 'flex',
//                     flex: 1
//                 }}>

//                     <SearchField1 state={minDistance} type={'number'} setState={setMinDistance} fieldLabel={t('GLOBAL.SEARCH_MAX_DISTANCE')} />
//                     <SearchField1 state={maxDistance} type={'number'} setState={setMaxDistance} fieldLabel={t('GLOBAL.SEARCH_MIN_DISTANCE')} />
//                 </Box>
//                 <Box sx={{
//                     display: 'flex',
//                     flex: 1
//                 }}>
//                     <BasicDatePicker />
//                 </Box>

//                 {/* <Autocomplete
//                     sx={{ ml: 1 }}
//                     multiple
//                     fullWidth
//                     options={visitTypesList}
//                     getOptionLabel={(option) => option.name || ""}
//                     id="disable-close-on-select"
//                     value={visitType}
//                     onChange={(e, v, r) => { setVisitType(v ? v : []); }}
//                     renderInput={(params) => (
//                         <TextField {...params} label="Visit Status" variant="outlined" />
//                     )}
//                 /> */}

//             </Box>
//             <Box sx={{ m: 1 }} component="span" display="flex" bgcolor="white" flexDirection="row">
//                 <Autocomplete
//                     sx={{ ml: 1 }}
//                     fullWidth
//                     multiple
//                     options={users}
//                     getOptionLabel={(option) => option.full_name || ""}
//                     id="disable-close-on-select"
//                     value={selectedUsers}
//                     onChange={(e,v,r) => {setSelectedUsers(v ? v:[]);}}
//                     renderInput={(params) => (
//                         <TextField {...params}
//                          label={t('GLOBAL.SEARCH_USERS')} 
//                          variant="outlined" />
//                     )}
//                 />
//                 <SearchField1 state={shopName} setState={setShopName} fieldLabel={t('GLOBAL.SEARCH_SHOPE_NAME')} />
//                 <SearchField1 state={ownerName} setState={setOwnerName} fieldLabel={t('GLOBAL.SEARCH_OWNER_NAME')} />
//             </Box>
//         </Box>
//     </AccordionDetails>
// </Accordion>
// </Box>