import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Pane, Dialog } from 'evergreen-ui';
import axios from "axios";
import Select from "react-select";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import {useSelector,useDIspatch} from 'react-redux'
import DialogForm from "../Share/DialogForm";
import {
    ContainerOfForm,
    SaveButton,
    CancelButton,
    Form
} from '../Users/StyledComponents';
import {TextField,Box} from '@mui/material'

import FooterBottons from '../Share/FooterBottons'
import {useTranslation} from "react-i18next";

const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"
export const OwnerTypeForm = (props) => {
    const [t, i18n] = useTranslation('common');
    const [loading, setloading] = useState(true);
    const directions =useSelector(state=>state.settingsData.directions);
    const MainTheme =useSelector(state=>state.settingsData.MainTheme);
    const [object, setobject] = useState({
        id: null,
        name: null,
        
    });
   
    const token = cookies.get("token")

    const submitForm = async () => {
        
        try {
            
        
            if (object.id) {

                const resourcesResult = await axios({
                    url: Host + "owner_types/"+object.id,
                    method: "put",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data: object
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("updated ")
                        props.setshowOwnerTypeForm(false,true)
                    } else if (resourcesResult.data.status === false) {
                        props.setshowOwnerTypeForm(true,false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setshowOwnerTypeForm(true,false)
                }
                setloading(false)
            }
            else {
                const resourcesResult = await axios({
                    url: Host + "owner_types",
                    method: "post",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data: object
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("saved ")
                        props.setshowOwnerTypeForm(false,true)
                    } else if (resourcesResult.status === 400) {
                        toast.error(resourcesResult.data)
                        props.setshowOwnerTypeForm(true,false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setshowOwnerTypeForm(true,false)
                }
                setloading(false)
            }
        } catch (error) {
            props.setshowOwnerTypeForm(true,false)
            const resourcesResult=error.response            
            if (resourcesResult && resourcesResult.status === 400) {
                if(typeof resourcesResult.data.data === "object")
                {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key)=>{
                    
                        resourcesResult.data.data[key].map((message)=>{
                            toast.error(message)
                        })
                    })
                }
                else{
                    
                    toast.error(resourcesResult.data.data)
                }
                
                
            }
            else if(resourcesResult && resourcesResult.status === 401) {
             
                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else
            {
              
                toast.error("network error")
            }
        }
        
    };


    useEffect(() => {
        if(props&& props.ownerType&& props.ownerType.id>0)
        {
            setobject({...object,...props.ownerType});
        }
        else{
            setobject(
                {
                    id: null,
                    name: null,
                }
            )
        }

        
        return () => {

        };
    }, [props.showOwnerTypeForm]);

    const checkDarknessOrLightnessForColor=(color)=>{
        // Variables for red, green, blue values
        var r, g, b, hsp;
      
        // Check the format of the color, HEX or RGB?
        if (color.match(/^rgb/)) {
    
            // If RGB --> store the red, green, blue values in separate variables
            color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
            
            r = color[1];
            g = color[2];
            b = color[3];
        } 
        else {
            
            // If hex --> Convert it to RGB: http://gist.github.com/983661
            color = +("0x" + color.slice(1).replace( 
            color.length < 5 && /./g, '$&$&'));
    
            r = color >> 16;
            g = color >> 8 & 255;
            b = color & 255;
        }
        
        // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
        hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
        );
    
        // Using the HSP value, determine whether the color is light or dark
        if (hsp>127.5) {
    
            return false;//light
        } 
        else {
    
            return true;//dark
        }
  
    };

    const check=checkDarknessOrLightnessForColor(MainTheme?.paperTextColor)
    

    const styleTextField={
        backgroundColor:!check?'rgb(255,255,255,0.7)':'rgb(0,0,0,0.4)',
        color:MainTheme?.paperTextColor,
    }

    const styleSelect={
        backgroundColor:!check?'rgb(255,255,255,0.7)':'rgb(0,0,0,0.4)',
        color:MainTheme?.paperTextColor,
    }


    return (

        <DialogForm
        open={props.showOwnerTypeForm}
        close={props.setshowOwnerTypeForm}
        CustomeWidth='50%'
        CustomeHeight="fit-content"
        >
      
            <form >
                <Box
                 sx={{
                    '& .muiltr-a4ms82-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root,& svg,& .muirtl-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':{
                        color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                     },
                    '& input':{
                        color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                    
                        },
                        '& .MuiOutlinedInput-root':{
                            borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
                            color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                        },
                        '& .MuiInputLabel-root':{
                            // backgroundColor:`${MainTheme?.paperTextColor+'22'} !important`,
                            color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                        },
                        '& .MuiFormControl-root':{
                          // border:`1px solid ${MainTheme?.paperTextColor} !important`,
                          color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                          },
                          '&:hover fieldset': {
                            borderColor:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: MainTheme?.secondaryColor,
                            color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.secondaryColor,

                          },
                        },
                        '& .MuiOutlinedInput':{
                          // borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            // border: "0 none",
                         },
                 }}
                >
                <div className="form-group">
                   

                    <TextField
                       id="name"
                       fullWidth
                       sx={{marginTop:'10px'}}
                       label={t('OWNER_TYPES.FORM_ADD_NAME')}
                       value={object.name}
                       onChange={event => { setobject({ ...object, name: event.target.value }); }}
                       variant="standard"
                     />
                </div>
                </Box>
               
                
                
            </form>

            <FooterBottons close={props.setshowOwnerTypeForm} submitForm={submitForm} />
        </DialogForm>
    );
};
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'blue',

    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
        width: '100%',
        border: '1px solid #ababab',
        borderRadius: 5,
        padding: 0,
        display: 'flex'
    }),
    container: () => ({

        width: '80%',
        position: 'relative',
        boxSizing: 'border-box',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}

export default OwnerTypeForm;
