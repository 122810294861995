import React,{useState,useEffect,useMemo,useCallback} from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

// import DoctorModal from './DoctorModal';
import Button from '@mui/material/Button';
// import '../../../App.css';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import {useSelector,useDispatch} from 'react-redux'
import './SummeryCards.css'
import {useTranslation} from "react-i18next";
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import helpers from '../../../assets/js/helper'
import Cookies from "universal-cookie";
import axios from "axios";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '../Share/Pagination'
import {setAttendanceCardView} from '../../reduxStore/SettingsReducer'
import {useNavigate} from 'react-router-dom'

import BasicDatePickerV2 from '../GeneralComponents/BasicDatePickerV2';
import SearchFieldV2 from '../GeneralComponents/SearchFieldV2'
import CustomeFilter from '../Share/CustomeFilter'

const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"

export default function SummeryCards(props) {
    const token=cookies.get("token")
    const [open, setOpen] = React.useState(false);
    const attendanceCardView=useSelector(state=>state.settingsData.attendanceCardView)
    const FilterButtonHeight= useSelector(state => state.settingsData.FilterButtonHeight)
    const SearchButtonTheme=useSelector(state=> state.settingsData.SearchButtonTheme);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const MainTheme=useSelector(state=>state.settingsData.MainTheme)
    const screenWidthRedux=useSelector(state=>state.settingsData.screenWidth)
    const {t, i18n} = useTranslation('common');
    const [fromDate,setFromDate]=useState(new Date())
    const [toDate,setToDate]=useState(new Date())
    const [listOfUserData,setListOfUserData]=useState([])
    const [loading,setLoading]=useState(false)
    const [pageNumber, setpageNumber] = useState(1);
    const [pointsTotal, setPointsTotal] = useState(0)
    const [pointsNumberOfPages, setPointsNumberOfPages] = useState(0)
    const filterSearchBoxMode = useSelector(state=> state.settingsData.filterSearchBoxMode)
    const [numberOfDay,setNumberOfDay]=useState(0)
    const [numberOfFriday,setNumberOfFriday]=useState(0)

    const [rows, setRow] = useState([])
    const [pageSize,setPageSize]=useState(2000)
    const dispatch=useDispatch()
    const navigate = useNavigate()
    const getImageWidth=()=>{
        let width=90;
        // if(screenWidthRedux<=1200){
        //     width=150;
        //     if(screenWidthRedux<=1000){
        //        width=120;
        //        if(screenWidthRedux<=990){
        //           width=250;
        //         }
        //     }
        // }
        return width;
    }

   const getSummeryData=async()=>{
        try {
          setLoading(true)
          const resourcesResult = await axios({
              url: Host + "attendance_calculations_summary_report",
              method: "get",
              headers: {
                  "Authorization": `Bearer ${token}`,
                  Accept: "application/json",
                  'X-localization': language,
              },
              params:{
                page_size:pageSize,
                creation_date_from:helpers.formatDate(fromDate),
                creation_date_to:helpers.formatDate(toDate),
                page:pageNumber,
              }
    
          })
          if (resourcesResult) {
              if (resourcesResult.status === 200) {
               if(resourcesResult?.data&&resourcesResult?.data?.data?.length>0)
               {
                   console.log('user',resourcesResult?.data?.data)
                //    setListOfUserData(resourcesResult?.data?.data)
                   reformatResponseAccordingGroups(resourcesResult?.data?.data)
                   setPointsTotal(resourcesResult?.data?.total)
                   setPointsNumberOfPages(resourcesResult?.data?.last_page)
              
               }
              } else if (resourcesResult.data.status === false) {
              }
          }
          else {
              toast.error("Error")
          }
          setLoading(false)
        
        } catch (error) {
            console.log("error===>",error);
            setLoading(false)
            const resourcesResult = error.response
            if (resourcesResult && resourcesResult.status === 400) {
                if (typeof resourcesResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key) => {
    
                        resourcesResult.data.data[key].map((message) => {
                            toast.error(message)
                        })
                    })
                }
                else {
    
                    toast.error(resourcesResult.data.data)
                }
    
    
            }
            else if (resourcesResult && resourcesResult.status === 401) {
    
                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else {
    
                toast.error("network error")
            }
        }
   }

   const reformatResponseAccordingGroups=(data)=>{
       let arr=[];
       if(data&&data?.length>0){
            data?.map((itm)=>{
                  if(arr?.length==0){
                    if(itm?.user?.group&&itm?.user?.group?.length>0){
                        itm?.user?.group?.map((gro)=>{
                            arr.push({
                                group_name:gro?.name,
                                group_id:gro?.id,
                                users:[
                                    {
                                      ...itm
                                    }
                                ]
                            })
                        })
                    }else{
                        arr.push({
                            group_name:'No Groups',
                            group_id:0,
                            users:[
                               {
                                ...itm
                               }
                            ]
                        })
                    }
                  }else{
                    if(itm?.user?.group&&itm?.user?.group?.length>0){
                        itm?.user?.group?.map((gro)=>{
                            let check=false;
                            let temp_arr=[]
                            arr?.map((res)=>{
                                if(res.group_id==gro?.id){
                                    temp_arr.push({
                                        group_name:gro?.name,
                                        group_id:gro?.id,
                                        users:[
                                            ...res?.users,
                                             {...itm}
                                        ]
                                    })
                                    check=true;
                                }else{
                                    temp_arr.push(res)
                                }
                               
                            })
                            arr=[...temp_arr];

                            if(!check){
                                arr.push({
                                    group_name:gro?.name,
                                    group_id:gro?.id,
                                    users:[
                                       {...itm}
                                    ]
                                })
                            }

                        })
                    }else{
                        let check=false;
                        let tempArr=[]
                        arr?.map((res)=>{
                          if(res?.group_id===0){
                            check=true;
                            tempArr.push({
                                group_name:'No Groups',
                                group_id:0,
                                users:[
                                    ...res?.users,
                                   {...itm} 
                                ]
                            })
                          }else{
                            tempArr.push(res)
                          }
                        })
                         arr=[...tempArr]
                        if(!check){
                            arr.push({
                                group_name:'No Groups',
                                group_id:0,
                                users:[
                                   {...itm}
                                ]
                            })
                        }

                       
                    }
                  }
            })
       }
       console.log('userserserser',arr)
       setListOfUserData(arr)
   }

   useEffect(()=>{
      getSummeryData()
   },[
     pageNumber,
   ])

   const getImage=(user)=>{
    let imageSrc="";
    if(user?.image?.image_url)
       imageSrc=helpers.getImageUrl(user?.image?.image_url)
    else
     imageSrc='https://img.freepik.com/free-photo/delivery-concept-portrait-handsome-african-american-delivery-man-courier-pushing-hand-truck-with-stack-boxes-isolated-grey-studio-background-copy-space_1258-1246.jpg?w=2000'
    
     return imageSrc;

  }

  const setDispatchWithCardInfo=(data)=>{
    console.log('data_data1=>',new Date(fromDate))
    console.log('data_data2=>',new Date(toDate))

     let dataLis={
        from:new Date(fromDate),
        to:new Date(toDate),
        other:data
     }
    //    console.log('datadatadta',data)
    //   dispatch(setAttendanceCardView(dataLis))
      navigate('/attendance_report',{
        state:{
            dataLis:dataLis
        }
    })
    //   window.location.href='/attendance_report?userID='+data?.user?.id

  }
  
  const getNumberOfDaysAndFridayDay=useMemo(()=>{
        let today = new Date(toDate).toISOString().slice(0, 10)

        const startDate  = helpers.formatDate(fromDate);
        const endDate    = today;
        
        const diffInMs   = new Date(endDate) - new Date(startDate)
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        
        console.log('number of days',diffInDays+1)
        return diffInDays+1;
    },[fromDate,toDate])

    const countCertainDays=( days, d0, d1 )=>{
        var ndays = 1 + Math.round((d1-d0)/(24*3600*1000));
        var sum = function(a,b) {
          return a + Math.floor( (ndays+(d0.getDay()+6-b) % 7 ) / 7 ); };
        return days.reduce(sum,0);
    }

    useEffect(()=>{
        if(fromDate&&toDate){
            setNumberOfFriday(countCertainDays([5],new Date(fromDate),new Date(toDate)))
        }
    },[fromDate,toDate])
  

    return (
                 <Box sx={{   
                    position:'relative',
                    paddingTop:filterSearchBoxMode?'50px':'0',
                    top:'-30px',
                    }}>
                        {
                        !filterSearchBoxMode?
                        <Box 
                        sx={{   
                            display:'flex',
                            justifyContent:"space-between",
                            alignItems:"center",
                        }}
                        >
                            <Box
                            sx={{
                                borderRadius:'5px',
                                width:'fit-content',padding:'12px 15px',margin:'50px 0',backgroundColor:MainTheme?.paperColor,
                                boxShadow:`0 0 7px -2px #000`,
                                '& p , & span ,& .MuiInputLabel-root' :{
                                    fontSize:"15px !important"
                                },
                             
                            
                                '& .MuiOutlinedInput-root':{
                                    borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
                                    color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                                },
                                '& .MuiInputLabel-root':{
                                    // backgroundColor:`${MainTheme?.paperTextColor+'22'} !important`,
                                    color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                                },
                                '& .MuiFormControl-root,& svg,& .muirtl-nxo287-MuiInputBase-input-MuiOutlinedInput-input,& .muiltr-nxo287-MuiInputBase-input-MuiOutlinedInput-input':{
                                // border:`1px solid ${MainTheme?.paperTextColor} !important`,
                                color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                                },
                                '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                                },
                                '&:hover fieldset': {
                                    borderColor: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.secondaryColor,
                                },
                                },
                                '& .MuiOutlinedInput':{
                                // borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    // border: "0 none",
                                },
                                '& .muirtl-1a1fmpi-MuiInputBase-root-MuiInput-root, & .muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root':{
                                    paddingLeft:"5px"
                                },
                                '& .muirtl-1a1fmpi-MuiInputBase-root-MuiInput-root:before,& .muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root:before':{
                                borderColor:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,
                                outlineColor:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,
                                },
                                '& .muirtl-1a1fmpi-MuiInputBase-root-MuiInput-root:hover:before, &.muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root:hover:before':{
                                borderColor:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,
                                outlineColor:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,
                                }
                                }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Box sx={{display:'flex',justifyContent:'start',alignItems:'center'}}>
                                            <DesktopDatePicker
                                            sx={{
                                                width:'40%'

                                            }}
                                            label={t('GLOBAL.SEARCH_FROM_DATE')}
                                            inputFormat="YYYY/MM/DD"
                                            value={fromDate}
                                            onChange={(date)=>{
                                            setFromDate(date)
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="standard"/>}
                                            />
                                            <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',width:'8%',color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme.paperTextColor}}>{t('GLOBAL.SEARCH_TO_WORD')}</Box>
                                        <DesktopDatePicker
                                            sx={{
                                                width:'40%'
                                            }}
                                            label={t('GLOBAL.SEARCH_TO_DATE')}
                                            inputFormat="YYYY/MM/DD"
                                            value={toDate}
                                            onChange={(date)=>{
                                                setToDate(date)
                                            }}
                                            renderInput={(params) => <TextField {...params} variant="standard"/>}
                                            />
                                            <Box sx={{margin:'0 10px'}}>
                                                <Button 
                                                onClick={()=>{
                                                    getSummeryData()
                                                }}
                                                sx={{
                                                    padding:'15px 35px !important',
                                                    backgroundColor:MainTheme?.secondaryColor,color:MainTheme?.oppositeSecondaryColor,outline:'none !important',
                                                    border:'none !important',
                                                    '&:hover':{
                                                        backgroundColor:MainTheme?.secondaryColor  
                                                    }
                                                }}>{t('GLOBAL.SEARCH_SEARCH_BUTTON')}</Button>
                                            </Box>
                                        </Box>
                                    </LocalizationProvider>
                                    
                            </Box>
                            <Box 
                                sx={{
                                display:'flex',
                                justifyContent: 'start',
                                alignItems: 'start',
                                height:'fit-content',
                                padding:'28px 10px',
                                borderRadius:'5px',
                                boxShadow:'0 0 7px -3px #000',
                                backgroundColor:MainTheme?.paperColor,
                                width:'fit-content',
                                "& span":{
                                    color:MainTheme?.gloablTextColor,
                                    margin:'0 3px'
                                },
                                //   margin:'10px 0'
                                position:'relative',
                                top:'0px',
                                }}
                            >
                                <Box sx={{display:'flex',margin:'0 10px'}}>
                                    <span>{t('ATTENDANCE_REPORT.NUMBER_OF_DAY')}:</span>
                                    <span>{getNumberOfDaysAndFridayDay}</span>
                                </Box>
                                <Box sx={{display:'flex',margin:'0 10px'}}>
                                    <span>{t('ATTENDANCE_REPORT.NUMBER_OF_Friday')}:</span>
                                    <span>{numberOfFriday}</span>
                                </Box>
                            </Box>
                        </Box>:null
                        }
                    {
                     filterSearchBoxMode?
                        <Box 
                            sx={{
                            display:'flex',
                            justifyContent: 'start',
                            alignItems: 'start',
                            height:'fit-content',
                            padding:'10px',
                            borderRadius:'5px',
                            boxShadow:'0 0 7px -3px #000',
                            backgroundColor:MainTheme?.paperColor,
                            width:'fit-content',
                            "& span":{
                                color:MainTheme?.gloablTextColor,
                                margin:'0 3px'
                            },
                            //   margin:'10px 0'
                            position:'relative',
                            top:'-10px',
                            }}
                        >
                        <Box sx={{display:'flex',margin:'0 10px'}}>
                            <span>{t('ATTENDANCE_REPORT.NUMBER_OF_DAY')}:</span>
                            <span>{getNumberOfDaysAndFridayDay}</span>
                        </Box>
                        <Box sx={{display:'flex',margin:'0 10px'}}>
                            <span>{t('ATTENDANCE_REPORT.NUMBER_OF_Friday')}:</span>
                            <span>{numberOfFriday}</span>
                        </Box>
                        </Box>:null
                    }
                <Box 
                sx={{
                    display:'flex',
                    justifyContent: 'start',
                    alignItems: 'start',
                    width:'100%',
                }}
                >
                    <Box
                        sx={{
                        width:'100%',
                        }}
                    >
                        <div className="mohscroll" 
                        style={{
                            display: 'flex',
                            flexFlow: 'row-reverse wrap',
                            alignItems:'center',
                            justifyContent:screenWidthRedux<=991||loading?'center':'start',
                            // overflowY: 'scroll',
                            paddingTop:filterSearchBoxMode?0:'20px',
                            height:'100%',
                            width:'100%',
                            marginBottom:'100px',
                            position:'relative',
                            top:filterSearchBoxMode?0:'-30px',
                            color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme.paperTextColor
                        }}>
                          
                            {
                                !loading?
                                listOfUserData&&listOfUserData?.length>0?
                                listOfUserData?.map((gro)=>(
                                <Box sx={{width:'100%',margin:'0 0 10px 0'}} key={gro?.group_name+gro?.id}>
                                    <Box
                                      sx={{
                                        width:'100%',
                                        borderRadius:'5px',
                                        height:'60px',
                                        backgroundColor:MainTheme?.paperColor,
                                        boxShadow:'0 0 7px -3px',
                                        padding:'10px',
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:"center",
                                      }}
                                    >
                                        <span style={{fontWeight:'bold'}}>{gro?.group_name}</span>
                                    </Box>
                                    <Box 
                                     sx={{
                                        display: 'flex',
                                        flexFlow: 'row-reverse wrap',
                                        alignItems:'center',
                                        justifyContent:screenWidthRedux<=991||loading?'center':'start',
                                        marginTop:'20px'
                                     }}
                                    >
                                        {
                                        gro?.users&&gro?.users?.length>0&&gro?.users?.map((itm)=>(
                                            <Box 
                                            key={itm?.id}
                                            onClick={()=>{
                                                setDispatchWithCardInfo(itm)
                                            }}
                                            className="doctor-card-container-div" sx={{
                                                borderColor:MainTheme?.paperTextColor,
                                                backgroundColor:MainTheme?.paperColor,
                                                '& p,& h1,& h2,& h3,& h4,& h5,& h6,& p':{
                                                    color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme.paperTextColor
                                                },
                                                boxShadow:`0 0 7px -2px #000`,
                                                padding:'25px 10px',
                                                borderRadius:'5px',
                                                height:'210px',
                                                width:'266px',
                                                position:'relative',
                                                margin:'10 40px',
                                                overflow: 'hidden',
                                            }}>
                                                <div  
                                                className="cardSummeryClapPath"
                                                style={{
                                                    position: 'absolute',
                                                    padding:0,
                                                    top:0,
                                                    left:0,
                                                    right:0,
                                                    bottom:0,
                                                    zIndex:-1,
                                                    width:'110%',
                                                    height:'110%',
                                                    backgroundColor:MainTheme?.secondaryColor,
                                                }}
                                                >

                                                </div>
                                                <div style={{ minWidth: '100%', flex: 1, display: 'flex', textAlign: 'right', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'stretch' }}>
                                                    <div style={{ flex: 4, display: 'flex', textAlign: 'right', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'right', }}>

                                                        <div style={{ marginTop: 6, display: 'flex', textAlign: 'right', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <img
                                                                src={`${require('../../../assets/img/mandoob.png')}`}
                                                                alt={t('ATTENDANCE_REPORT.ATTENDANCE_USER_NAME')}
                                                                loading="lazy"
                                                                width={"20px"}
                                                                height="20px"
                                                                style={{
                                                                    margin:'0 10px',position:'relative',top:'-5px',
                                                                    filter:helpers.checkDarknessOrLightnessForColor(MainTheme?.paperColor)?'brightness(0) invert(1)':'brightness(1) invert(0)'
                                                                }}
                                                            />   
                                                            <div style={{width:'100%',position:'relative',top:'0px'}}>
                                                                <h5 style={{ width: '100%',maxWidth:'150px',marginTop:'5px',fontWeight:'bold', fontSize: '12.5px',display:'flex',justifyContent:'start',wordBreak:'break-all',wordWrap:' break-word' }}>{itm?.user?.username}</h5>
                                                                <h6 style={{ width: '100%', fontSize: '11px',display:'flex',justifyContent:'start',position:'relative',top:'-3px'  }}>{t('ATTENDANCE_REPORT.ATTENDANCE_USER_NAME')}</h6>
                                                            </div>
                                                        </div>
                                                        <div style={{ marginTop: -4, display: 'flex', textAlign: 'right', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                                                            <img
                                                                src={`${require('../../../assets/img/roadTime.png')}`}
                                                                alt={t('ATTENDANCE_REPORT.ATTENDANCE_ROAD_TIME')}
                                                                loading="lazy"
                                                                width={"20px"}
                                                                height="20px"
                                                                style={{
                                                                    filter:helpers.checkDarknessOrLightnessForColor(MainTheme?.paperColor)?'brightness(0) invert(1)':'brightness(1) invert(0)'
                                                                    ,margin:'0 10px',position:'relative',top:'-5px'}}
                                                            />
                                                            <div style={{width:'100%',position:'relative',top:'-5px'}}>
                                                                <h5 style={{ width: '100%',marginTop:'5px',fontWeight:'bold', fontSize: '12.5px',display:'flex',justifyContent:'start' }}>{itm?.road_time}</h5>
                                                                <h6 style={{ width: '100%', fontSize: '11px',display:'flex',justifyContent:'start',position:'relative',top:'-3px'  }}>{t('ATTENDANCE_REPORT.ATTENDANCE_ROAD_TIME')}</h6>
                                                            </div>


                                                        </div>
                                                        <div style={{ marginTop: -2, display: 'flex', textAlign: 'right', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                                                            <img
                                                                src={`${require('../../../assets/img/visitTime.png')}`}
                                                                alt={t('ATTENDANCE_REPORT.ATTENDANCE_VISIT_TIME')}
                                                                loading="lazy"
                                                                width={"20px"}
                                                                height="20px"
                                                                style={{
                                                                    filter:helpers.checkDarknessOrLightnessForColor(MainTheme?.paperColor)?'brightness(0) invert(1)':'brightness(1) invert(0)'
                                                                    ,margin:'0 10px',position:'relative',top:'-5px'}}
                                                            />
                                                            <div style={{width:'100%',position:'relative',top:'-5px'}}>
                                                                <h5 style={{ width: '100%',marginTop:'5px',fontWeight:'bold', fontSize: '12.5px',display:'flex',justifyContent:'start' }}>{itm?.visits_time}</h5>
                                                                <h6 style={{ width: '100%', fontSize: '11px',display:'flex',justifyContent:'start',position:'relative',top:'-3px'  }}>{t('ATTENDANCE_REPORT.ATTENDANCE_VISIT_TIME')}</h6>
                                                            </div>

                                                        </div>
                                                        <div style={{ marginTop: -1, display: 'flex', textAlign: 'right', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>

                                                            <img
                                                                src={`${require('../../../assets/img/workTime.png')}`}
                                                                alt={t('ATTENDANCE_REPORT.ATTENDANCE_TOTAL_WORK_TIME')}
                                                                loading="lazy"
                                                                width={"20px"}
                                                                height="20px"
                                                                style={{
                                                                    filter:helpers.checkDarknessOrLightnessForColor(MainTheme?.paperColor)?'brightness(0) invert(1)':'brightness(1) invert(0)'
                                                                    ,margin:'0 10px',position:'relative',top:'-5px'}}
                                                            />
                                                            <div style={{width:'100%',position:'relative',top:'-5px'}}>
                                                                <h5 style={{ width: '100%',marginTop:'5px',fontWeight:'bold', fontSize: '12.5px',display:'flex',justifyContent:'start' }}>{itm?.total_work_time}</h5>
                                                                <h6 style={{ width: '100%', fontSize: '11px',display:'flex',justifyContent:'start',position:'relative',top:'-3px'  }}>{t('ATTENDANCE_REPORT.ATTENDANCE_TOTAL_WORK_TIME')}</h6>
                                                            </div>

                                                        </div>
                                                    
                                                    </div>
                                                    <div style={{ flex: 1, display: 'flex', textAlign: 'center', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', }}>


                                                        <img
                                                            src={getImage(itm?.user)}
                                                            alt={"دكتور"}
                                                            loading="lazy"
                                                            width={`${getImageWidth()}px`}
                                                            height="95px"
                                                            className='imageForCards'
                                                            style={{
                                                                borderRadius: '5px',
                                                                // borderColor: props.color?props.color:'#707070',
                                                                // borderWidth: 3,
                                                                // borderStyle: 'solid',
                                                                margin:'0 6px ',
                                                                marginBottom: 15

                                                            }}
                                                        />
                                                        <div style={{width:'100%' ,display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
                                                                <h5 style={{ 
                                                                    width: '25px',
                                                                    height:'25px' ,
                                                                    // marginRight: '35px',
                                                                    // position:'relative',right:'15px',
                                                                    // marginTop:'5px',fontWeight:'bold', 
                                                            fontSize: '12.8px',
                                                                    display:'flex',justifyContent:'center',alignItems:'center',
                                                                    border:`1px solid ${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme.paperTextColor}`,
                                                                    borderRadius:'50%' }}>
                                                                        {itm?.working_hours_per_day}
                                                                    </h5>
                                                                <h6 style={{ 
                                                                    width: '100%', 
                                                                    // marginRight: '25px', 
                                                                    
                                                                    alignItems:'center', 
                                                                    fontSize: '11px',
                                                                    display:'flex',
                                                                    justifyContent:'center'  
                                                                }}>{t('ATTENDANCE_REPORT.ATTENDANCE_USER_WORKING_HOURS_PER_DAY')}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                            </Box>
                                        ))
                                        }
                                    </Box>
                                </Box>
                                 )):
                                 <Box>
                                    <p>{t('ATTENDANCE_REPORT.NO_SUMMERY_FOUND')}</p>
                                 </Box>
                               :
                               <Box sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                  <CircularProgress sx={{color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor}}/>
                               </Box>
                            }
                          {/* <Pagination  totalPage={pointsNumberOfPages}  count={pointsNumberOfPages} page={pageNumber} showFirstButton={true} showLastButton={true} pageNumber={pageNumber} setpageNumber={setpageNumber} /> */}

                           <br/>
                           <br/>
                           <br/>
                           <br/>
                           <br/>

                       
                        </div>
                    </Box>

                    {
                    filterSearchBoxMode?
                    <CustomeFilter
                        // exportFunction={ExportFunction}
                        // // createFunction={createFunction}
                        // // mapFunction={mapFunction}
                        // // importFunction={importFunction}
                        // hasExport={rows?.length > 0 ?true:false} 
                        // hasCreate={false} 
                        // hasMaps={false} 
                        // hasImport={false}
                        resultLength={pointsTotal}
                        // customeHeight={screenWidth<=1100?'290px':"200px"}
                    >
                        <Box
                            sx={{
                                width:'100%',
                                height:'400px',
                                minHeight:'400px',
                                maxHeight:'400px',
                                overflowY:'scroll',
                                overflowX:'hidden',
                                padding:'10px',
                                marginTop:'10px',
                                paddingTop:'20px',
                                // boxShadow:'0 0 7px -5px #000 inset'
                            }}
                        >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Box sx={{display:'flex',flexDirection:'column',justifyContent:'start',alignItems:'center'}}>
                                        <DesktopDatePicker
                                         sx={{
                                            width:'100%',
                                            marginTop:'10px !important',
                                            marginBottom:'10px !important',
                                         }}
                                        // label={t('GLOBAL.SEARCH_FROM_DATE')}
                                        inputFormat="YYYY/MM/DD"
                                        value={fromDate}
                                        onChange={(date)=>{
                                           setFromDate(date)
                                        }}
                                        renderInput={(params) => <TextField 
                                            {...params}
                                            inputProps={{
                                                ...params.inputProps,
                                                placeholder:t('GLOBAL.SEARCH_FROM_DATE')
                                            }}
                                            sx={{width:'100%',  marginTop:'10px !important',
                                            marginBottom:'10px !important',}}  variant="standard"/>}
                                        />
                                       <DesktopDatePicker
                                        sx={{
                                            width:'100%',
                                            marginTop:'10px !important',
                                            marginBottom:'10px !important',
                                         }}
                                        // label={t('GLOBAL.SEARCH_TO_DATE')}
                                        inputFormat="YYYY/MM/DD"
                                        value={toDate}
                                        onChange={(date)=>{
                                            setToDate(date)
                                        }}
                                        renderInput={(params) => <TextField 
                                            {...params} 
                                            inputProps={{
                                                ...params.inputProps,
                                                placeholder:t('GLOBAL.SEARCH_TO_DATE')
                                            }}
                                            sx={{width:'100%',  marginTop:'10px !important',
                                            marginBottom:'10px !important',}}  variant="standard"/>}
                                        />
                                      
                                    </Box>
                                </LocalizationProvider>
                        </Box>
                        <Box sx={{display:'flex',justifyContent: 'end',alignItems: 'center',width:'100%'}}>
                            <Box display="flex" >
                                    <Button
                                        variant="contained"
                                        // spacing={2} 
                                        sx={{ 
                                            // flexGrow: 1,
                                            margin: 1,
                                            width:'80px !important',
                                            minWidth:'80px !important',
                                            maxWidth:'80px !important',
                                            backgroundColor:SearchButtonTheme?.search_button_color,color:SearchButtonTheme?.search_button_text_color,
                                            '&:hover':{
                                                backgroundColor:SearchButtonTheme?.search_button_color+'88',
                                            },
                                            height:FilterButtonHeight-10
                                        }}
                                        className="iconeFilterSearch"
                                        onClick={() =>getSummeryData()} 
                                        >
                                        {t('GLOBAL.SEARCH_SEARCH_BUTTON')}
                                    </Button>
                                    <Button variant="outlined"
                                        // spacing={2}
                                        sx={{
                                            width:'50% !important',
                                                // flexGrow: 1, 
                                                minWidth:'80px !important',
                                                maxWidth:'80px !important',
                                                margin: 1,
                                                // backgroundColor:SearchButtonTheme?.clear_button_color,
                                                color:SearchButtonTheme?.clear_button_text_color,
                                            '&:hover':{
                                                backgroundColor:`${SearchButtonTheme?.clear_button_color}   !important`,
                                            },
                                            height:FilterButtonHeight-10
                                        }}
                                        className="iconeFilterClear"
                                        color="secondary"
                                        onClick={()=>{
                                            setFromDate(null)
                                            setToDate(null)
                                        }} 
                                        >
                                        {t('GLOBAL.SEARCH_CLEAR_BUTTON')}
                                    </Button>
                            </Box>
                        </Box>
                    </CustomeFilter>:null
                }

                </Box>



                </Box>
                   
    );
}




