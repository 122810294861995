
import React, { useEffect, useState } from 'react';
// import Pagination from "react-js-pagination";
import * as XLSX from 'xlsx';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import TelegramIcon from '@mui/icons-material/Telegram'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import moment from 'moment';
import { toast } from 'react-toastify';
import CircularIndeterminate from '../GeneralComponents/CirularProgress'
import StyledDataGrid from '../GeneralComponents/StyledDataGrid'
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { useGlobalContext } from '../../../context'
import FilterBox from '../Share/FilterBox';
import "../../../assets/css/DG.css"
import "../../../assets/css/AccordionStyle.css"
import BasicDatePicker from '../GeneralComponents/BasicDatePicker';
import Cookies from "universal-cookie";
import axios from "axios";
import Host from "../../../assets/js/Host";
import Grid from '../Share/Grid'
import {useSelector,useDispatch} from 'react-redux'
import {useTranslation} from "react-i18next";
import helpers from '../../../assets/js/helper'
import BasicDatePickerV2 from '../GeneralComponents/BasicDatePickerV2';
import SearchFieldV2 from '../GeneralComponents/SearchFieldV2'
import CustomeFilter from '../Share/CustomeFilter'
import {useLocation} from 'react-router-dom'
import IconButton from "@mui/material/IconButton";
import DialogForm from "../Share/DialogForm";
import { AiFillCloseCircle } from 'react-icons/ai'

const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"

export default function PreviousFollowUp(props) {
    const {t, i18n} = useTranslation('common');
    const token = cookies.get("token")
    const [pageSize, setPageSize] = useState(5);
    const [pageNumber, setpageNumber] = useState(1);
    const [pointsTotal, setPointsTotal] = useState(0)
    const [pointsNumberOfPages, setPointsNumberOfPages] = useState(0)
    const filterSearchBoxMode = useSelector(state=> state.settingsData.filterSearchBoxMode)
    const directions= useSelector(state => state.settingsData.directions)
    const GridTheme= useSelector(state => state.settingsData.GridTheme)
    const MainTheme= useSelector(state => state.settingsData.MainTheme)
    const [loading,setLoading]=useState(false)
     
    const { PreviousFollowUp,setFromToDate, fromToDate, exportToCSV, user, users, setUser, visitFollowupsReport, clearPointsAdvanceSearchFields, fetchData, exportFollowupToCSV } = useGlobalContext()
    const SearchButtonTheme=useSelector(state=> state.settingsData.SearchButtonTheme);

    const FilterButtonHeight= useSelector(state => state.settingsData.FilterButtonHeight)
    const screenWidth=useSelector(state=>state.settingsData.screenWidth)
    const [ListOfData,setListOfData]=useState([])

    const handlePageChange = (newPage) => {
        setpageNumber(newPage + 1)
    }
    const advanceSearch = async (type = "BROWSING",obj) => {
        // console.log("tracking", 6);
        // console.log("tracking user", user.id);
        // console.log("user......");
    
        const fetchedData = await fetchData('PREVIOUS_FOLLOWUP_REPORT', 'visit_followups_report', {
            page: type === "SEARCH" ? 1 : pageNumber,
            page_size: pageSize,
            // created_by: props?.created_by?.id,
            point_id:props?.selectFollowUp?.visit?.point?.id,
            // creation_date_from: obj?.creation_date_from,
            // creation_date_to:obj?.creation_date_to,
        })
        return fetchedData
        
    }
    const getData=async(id)=>{
        try {
            if(id==0)
             setLoading(true)

            const resourcesResult = await axios({
                url: Host + "visit_followups_report",
                method: "get",
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                },
                params: {
                    point_id:props?.selectFollowUp?.visit?.point?.id,
                    page:pageNumber,
                    page_size: pageSize,
                }
            })
            console.log(resourcesResult);
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                    setListOfData(resourcesResult?.data)
                } else if (resourcesResult.data.status === false) {
                    
                }
            }
            else {
                toast.error("Error")
            }
            setLoading(false)
          
        } catch (error) {
            setLoading(false)
            console.log(error);
            const resourcesResult=error.response            
            if (resourcesResult && resourcesResult.status === 400) {
                console.log(resourcesResult);
                if(typeof resourcesResult.data.data === "object")
                {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key)=>{
                    
                        resourcesResult.data.data[key].map((message)=>{
                            toast.error(message)
                        })
                    })
                }
                else{
                    
                    toast.error(resourcesResult.data.data)
                }
                
                
            }
            else if(resourcesResult && resourcesResult.status === 401) {
             
                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else
            {
              
                toast.error("network error")
            }
        }
    }

    useEffect(() => {
        fetchData('USERS', 'users_list')
        getData(1)
    }, [pageSize, pageNumber])

    useEffect(()=>{
        setLoading(true)
        getData(0)
        setLoading(false)
    },[props?.showPreviousFollow])

    //Reformatting points object once points are loaded
    useEffect(() => {

        if (ListOfData && ListOfData?.data && ListOfData?.data?.length > 0) {
            const reformatVisits = ListOfData?.data?.map((rowVisit) => {
                return {
                    ...rowVisit,
                }
            })
            setRows(reformatVisits);

            setPointsTotal(ListOfData.total)
            setPointsNumberOfPages(ListOfData.last_page)
        }
        else {
            setRows([]);
            setPointsTotal(0)
            setPointsNumberOfPages(0)
        }
    }, [ListOfData])
    
    const [rows, setRows] = useState([])
    const columns = [
        { field: 'id', headerName:t('FOLLOWUP_REPORT.ID'), minWidth:35,flex:1 },
        {
            field: 'salesman', headerName: t('FOLLOWUP_REPORT.SALESMAN'), minWidth:150,flex:1,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} >
                    {rows?.row?.visit?.created_by?.full_name}
                </div>
            )
        },
        {
            field: 'customer', headerName: t('FOLLOWUP_REPORT.CUSTOMER'), minWidth:150,flex:1,
            renderCell: (rows) => (
                <div style={{ width: '100%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:directions=='rtl'?'end':'start'}} >
                    <span>{rows?.row?.visit?.point?.owner_name}</span>
                    <span>{rows?.row?.visit?.point?.name}</span>
                    <span>{rows?.row?.visit?.point?.phone}</span>
                </div>
            )
        },
        // {
        //     field: 'phone', headerName:t('FOLLOWUP_REPORT.PHONE'), minWidth:150,flex:1,
        //     renderCell: (rows) => (
        //         <div style={{ width: '100%' }} >
        //             {rows?.row?.visit?.point?.phone}
        //         </div>)
        // },
        {
            field: 'visit_total_time', headerName:t('FOLLOWUP_REPORT.START_DATE'), minWidth:200,flex:1,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} >
                    {rows?.row?.visit?.start_date}
                </div>
            )
        },
        {
            field: 'status', headerName: t('FOLLOWUP_REPORT.STATUS'), minWidth:150,flex:1,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} >

                    {rows?.row?.followup_status?.name}
                </div>
            )
        },
        {
            field: 'note', headerName: t('FOLLOWUP_REPORT.NOTE'), minWidth:250,flex:1,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} >
                    {rows?.row?.note}
                </div>
            )
        },


    ];


  return (
    <DialogForm
          open={props?.showPreviousFollow}
          close={props?.setShowPreviousFollow}
          loaderActive={loading}
          headerChildren={(
              <>
                <IconButton aria-label={"Close"} onClick={() => { props.setShowPreviousFollow(false) }} style={{ position: 'absolute', top: '0px', left: '6px', fontSize: '37px', }}>
                    <AiFillCloseCircle style={{ color: MainTheme?.paperTextColor,outline:'none !important' ,border:'none !important' }} />
                </IconButton>
                <Typography component={'h1'} variant={'h4'} style={{marginTop:'8px',marginBottom:'10px', color: MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor, width: '100%', textAlign: 'center' }}>
                    { t('FOLLOWUP_REPORT.FORPREVIOUS_TITLE')+"  "+ props?.selectFollowUp?.visit?.point?.name}
                </Typography>
              </>
            )}
          hasHeader={true}
          CustomeWidth={'100%'}
          CustomeHeight="fit-content"
        >
            <Grid
                rows={rows}
                columns={columns}
                rowTotals={pointsTotal}
                pageSize={pageSize}
                setPageSize={setPageSize}
                pageNumber={pageNumber}
                setPageNumber={setpageNumber}
                loading={loading}
                handlePageChange={handlePageChange}
                NumberOfPage={pointsNumberOfPages}
                directions={directions}
                hasCreate={false}
                createObject={null}
                title_en=""
                title_ar=""
                gridHeight={{
                    height:rows?.length>5?pageSize==5?400:pageSize==10?650:950:400,
                }}
            />
        </DialogForm>
  )
}
