import React,{useEffect,useState,useMemo,useCallback}from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
// import ListItemText from '@mui/material/ListItemText';
// import ListItem from '@mui/material/ListItem';
// import List from '@mui/material/List';
// import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box'
import {useSelector,useDispatch} from 'react-redux'
import { useGlobalContext } from '../../../context'
import {useTranslation} from "react-i18next";
import TextField from '@mui/material/TextField';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// import {DateRangePicker,DateRange} from '@mui/lab'
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import './Style.css'
import {
  setDistrictsList,
  setRegionsList,
  setDistrictsList_temp,
  setRegionsList_temp,
  setGlobalVariableForJourneys,
  setCopedJourneys
} from '../../reduxStore/SettingsReducer'
import moment from 'moment'
import swal from 'sweetalert';

// import dayjs from 'dayjs';
// import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
// import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
// import { Table } from "react-bootstrap";
// import CheckboxTree from 'react-checkbox-tree';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Cookies from "universal-cookie";
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import axios from "axios";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
// import FolderIcon from '@mui/icons-material/Folder';

import { styled, alpha } from '@mui/material/styles';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem, { useTreeItem,treeItemClasses } from '@mui/lab/TreeItem';
import clsx from 'clsx';
import { result } from 'lodash';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import {Tooltip} from '@mui/material'
import Grid from '@mui/material/Grid';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker';
import { MonthPicker } from '@mui/x-date-pickers/MonthPicker';
import { YearPicker } from '@mui/x-date-pickers/YearPicker';

// import PropTypes from 'prop-types';
import SvgIcon from '@mui/material/SvgIcon';
import { GiReturnArrow } from 'react-icons/gi';
// import TreeView from '@mui/lab/TreeView';
// import Collapse from '@mui/material/Collapse';
// web.cjs is required for IE11 support
// import { useSpring, animated } from 'react-spring/web.cjs';

const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
  
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `full-width-tab-0${index}`,
      'aria-controls': `full-width-tabpanel-0${index}`,
    };
  }
  

export default function UserDailyPlanNewForm(props) {
    const [t, i18n] = useTranslation('common');

    // const [open, setOpen] = React.useState(false);
    const directions =useSelector(state=>state.settingsData.directions)
    const MainTheme =useSelector(state=>state.settingsData.MainTheme)
    const GridTheme =useSelector(state=>state.settingsData.GridTheme)
    const GlobalVariableForJourneys =useSelector(state=>state.settingsData.GlobalVariableForJourneys)
    // const [regionsPoints, setregionsPoints] = useState([]);
    const DistrictsList=useSelector(state=>state.settingsData.DistrictsList)
    const regionsList=useSelector(state=>state.settingsData.regionsList)
    const RegionsList_temp=useSelector(state=>state.settingsData.RegionsList_temp)
    const DistrictsList_temp=useSelector(state=>state.settingsData.DistrictsList_temp)
    // const {user, users,fromToDate,journey_types,}=useGlobalContext();
    // const handleClickOpen = () => {
    //   props?.setOpen(true);
    // };
    const dispatch=useDispatch();
    const handleClose = () => {
      props?.setOpen(false);
    };

    const theme = useTheme();
    const [value, setValue] = React.useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    useEffect(()=>{
      if(GlobalVariableForJourneys?.length>0)
       dispatch(setGlobalVariableForJourneys([]))
       if(DistrictsList?.length>0)
       dispatch(setDistrictsList([]))
       if(regionsList?.length>0)
       dispatch(setRegionsList([]))
       if(DistrictsList_temp?.length>0)
       dispatch(setDistrictsList_temp([]))
       if(RegionsList_temp?.length>0) 
       dispatch(setRegionsList_temp([]))

       dispatch(setCopedJourneys([]))


    },[])
  
    const handleChangeIndex = (index) => {
      setValue(index);
    };

    
  return (
    <Box>

      <Dialog
        fullScreen
        open={props?.open}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{
            // position: 'relative',
            backgroundColor: MainTheme?.paperColor,
            color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
            '& .muiltr-m9glnp-MuiPaper-root-MuiDialog-paper ,& .muirtl-m9glnp-MuiPaper-root-MuiDialog-paper':{
                backgroundColor: MainTheme?.paperColor,
                color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
            },
            '& p,& span,& input':{
                color:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,
            }
        }}
      >
        {/* <AppBar 
            sx={{
                 position: 'relative',
                 backgroundColor: MainTheme?.primaryColor,
                 color:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.oppositePrimaryColor} !important`,
                 '& p,& span,& svg,& input':{
                    color:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.oppositePrimaryColor} !important`,
                }
            }}
            >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1,textAlign:'left' }} variant="h6" component="div">
            {t('JOURNEYS.USERDAILYPLAN_FORMADD_TITLE')+props?.user.full_name}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              {t('GLOBAL.POPUP_SAVE_BUTTON')}
            </Button>
          </Toolbar>
        </AppBar> */}
        <Box
         sx={{
            position:'absolute',
            top:'1.2%',
            right:'2%',
            display:'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex:1000,
            // flexDirection:'row-reverse',
        }}
        >
            <Typography sx={{ ml: 2, flex: 1,textAlign:'left',color:GridTheme?.oppositePrimaryColor }} variant="h6" component="div">
              {t('JOURNEYS.USERDAILYPLAN_FORMADD_TITLE')}
            </Typography>
            <IconButton
            edge="start"
            // color="inherit"
            sx={{
                margin:'0 10px',
                cursor:'pointer',
                backgroundColor:MainTheme?.paperColor+'88 !important',
                color:`${GridTheme?.oppositePrimaryColor} !important`
            }}
            onClick={handleClose}
            aria-label="close"
            >
                <CloseIcon sx={{cursor:'pointer',color:`${GridTheme?.oppositePrimaryColor} !important`}} />
            </IconButton>

        </Box>

        <Box sx={{ bgcolor: 'transparent' , width: '100%',height:'100%' }}>
            <AppBar position="static" sx={{backgroundColor:GridTheme?.primaryColor,height:'60px',padding:'10px 0'}}>
                <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
                sx={{
                    backgroundColor:"transparent",
                    '& .muiltr-heg063-MuiTabs-flexContainer,& .muirtl-heg063-MuiTabs-flexContainer':{
                        width:directions=='rtl'?'320px !important':'250px !important',
                    },
                    '& .muiltr-11yukd5-MuiTabs-indicator,& .muirtl-11yukd5-MuiTabs-indicator':{
                        backgroundColor:MainTheme?.secondaryColor
                    }
                }}
                >
                    <Tab label={t('JOURNEYS.CREATE_JP')} sx={{width:'150px !important',outline:'none !important',border:'none !important',color:GridTheme?.oppositePrimaryColor}} />
                    {/* <Tab label={t('JOURNEYS.VIEW_JP')}  sx={{width:'150px !important',outline:'none !important',border:'none !important',color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor}} /> */}
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
                className='hiddebScroll'
                sx={{
                  overflowY:'hidden !important',
                  overflowX:'hidden !important',

                }}
            >
                <TabPanel value={value} index={0} dir={theme.direction}
                  sx={{
                    width:'100%',
                    height:'100%',
                    overflowY:'hidden !important',
                    overflowX:'hidden !important',

                  }}
                >
                  <CreateJP {...props}/>
                </TabPanel>
             
            </SwipeableViews>
        </Box>

      </Dialog>
    </Box>
  )
}



const CreateJP=(props)=>{
    const directions =useSelector(state=>state.settingsData.directions)
    const MainTheme =useSelector(state=>state.settingsData.MainTheme)
    const [t, i18n] = useTranslation('common');
    const theme = useTheme();
    const [value_week, setValue_Week] = React.useState(0);
    const token=cookies.get('token');
    const [journeyType,setJourneyType]=useState(2)
    const [journeyTypeName,setJourneyTypeName]=useState('Weekly')

    const [selectMandoob,setSelectMandoob]=useState('')
    const [fromDate,setFromDate]=useState(new Date());
    const [isReapedted,setIsReapedted]=useState(false);
    const [isReapedtedDate,setIsReapedtedDate]=useState(new Date());
    const [finalSelectedPoints,setFinalSelectedPoints]=useState([])
    const [finalSelectedDistricts,setFinalSelectedDistricts]=useState([])
    const dispatch=useDispatch()
    const DistrictsList=useSelector(state=>state.settingsData.DistrictsList)
    const regionsList=useSelector(state=>state.settingsData.regionsList)
    const GlobalVariableForJourneys =useSelector(state=>state.settingsData.GlobalVariableForJourneys)

    let date_shiftWeek = new Date();
    date_shiftWeek.setDate(date_shiftWeek.getDate() + 7);
    const [toDate,setToDate]=useState(new Date(date_shiftWeek));
    const {users,journey_types,setRegions,userDailyPlans,setPoints,setDistricts,user,setUserDailyPlans,pointsStatuses,pointStatus,setPointStatus,exportToCSV,allPoints,points,district,region,districts,regions,clearPointsAdvanceSearchFields,setRegion,setDistrict,fetchData,deleteObjectt}=useGlobalContext();
    // const [regionsPoints, setregionsPoints] = useState([]);
    const RegionsList_temp=useSelector(state=>state.settingsData.RegionsList_temp)
    const DistrictsList_temp=useSelector(state=>state.settingsData.DistrictsList_temp)
    const [loading,setLoading]=useState(false)
    const [listOfSelectedData,setListOfSelectedData]=useState([]);
    const [viewInfoOfSelect,setViewInfoOfSelect]=useState(0)
    const [dailyDate,setDailyData]=useState(new Date())
    const [districtsState,setdistrictsState] =useState({
        checked: [],
        expanded: [],
    });
    const [regionsState,setregionsState] =useState({
        checked: [],
        expanded: [],
    });
    const [pointsState,setpointsState] =useState({
        checked: [],
        expanded: [],
    });

    useEffect(()=>{

    },[
      RegionsList_temp,
      DistrictsList_temp,
    ])
    const advanceSearch = ()=>{     
      // console.log(pointStatus)
                            
     }

  


    const handleChangeIndex = (index) => {
      console.log('swiper',index)
      setValue_Week(index);
    };

    const handleChange_info = (event, newValue)=> {
      setViewInfoOfSelect(newValue);
    };
    const handleChangeIndexofInfo = (index) => {
      setViewInfoOfSelect(index);
    };

    useEffect(()=>{
      // advanceSearch()
      fetchData('USERS','users_list')   
      fetchData('JOURNEYS_TYPES','journey_types_list') 
    },[])


    useEffect(()=>{
      console.log('districts',districts)
    },[districts])


    const ContentTabShowDetails=React.useMemo(()=>{

      //  const DistrictsList=useSelector(state=>state.settingsData.DistrictsList)
      //  const regionsList=useSelector(state=>state.settingsData.regionsList)
      //  console.log(regionsList,DistrictsList)
       
      return(
        <Box sx={{width:'100%', height:'100%',minHeight: 305,maxHeight:'700px', flexGrow: 1, maxWidth: 400, position: 'relative'}}>
           asfasdasdas
          {
        
          }
           
        </Box>
        
      )
    },[regionsList,DistrictsList])
   const [screenSize,setScreenSize]=useState(window.innerWidth)

    useEffect(()=>{
      window.addEventListener("resize",()=>{
        setScreenSize(window.innerWidth)
      })
    },[])

    const checkIfNoMandoob=()=>{
      let check=false;
      users&&users?.length>0&&users?.map((itm)=>{
          if(itm?.is_mobile==1){
            check=true;
          }
      })
      return check;
    }

  function getNumberOfDays(start, end) {
    const date1 = new Date(start);
    const date2 = new Date(end);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
  }
  function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

    const formatData=()=>{
      if(!journeyType){
       alert('you must select journey type')
       return
      }else if(!selectMandoob){
        alert('you must select mandoob')
       return
      }else if(!fromDate){
        alert('you must select start date')
        return
      }else if(!toDate){
        alert('you must select end Date')
       return
      }
      // const dayCounts= Math.abs(getNumberOfDays(fromDate,toDate))
      // var array=[];
      // for(let i=0;i<dayCounts;i++){
      //   array.push(i+1)
      // }
      //  console.log(array)
 
      //  let days=[];
      //  array&&array?.length>0&&array?.map((day)=>{
        
      //     DistrictsList&&DistrictsList?.length>0&&DistrictsList?.map((itm)=>{
      //       let obj={}
      //       let arr_temp=[];
      //       itm?.children&&itm?.children?.length>0&&itm?.children?.map((dist)=>{
      //           if(dist?.check){
      //             let obj={}
      //             let arr_2=[];
      //             regionsList&&regionsList?.length>0&&regionsList?.map((reg)=>{
      //                  if(reg?.parent_id==dist?.value&&reg?.check){
      //                     obj={
      //                       id:reg?.value
      //                     }
      //                     arr_2.push(obj)
      //                  }
      //             })
      //             obj={
      //               id:dist?.value,
      //               regions:arr_2
      //             }
      //             arr_temp.push(obj)
      //           }
      //       })
      //       obj={
      //         number:day,
      //         districts:arr_temp,
      //       }
      //       days.push(obj)
      //     })
      //  })
      // if(days?.length==0){
      //   alert('you must select distrcts')
      //  return
      // }
      console.log('result array======>',GlobalVariableForJourneys)
      if(journeyTypeName=="Daily"){
        // 
       return [GlobalVariableForJourneys,{
        user_id:selectMandoob,
        journey_type_id:journeyType,
        is_repetitive:isReapedted,
        // is_repetitive_date:formatDate(isReapedtedDate),
        start_date:formatDate(dailyDate),
        end_date:isReapedted?formatDate(isReapedtedDate):formatDate(dailyDate),
       }]
      }else if(journeyTypeName=="Weekly"){
        return [GlobalVariableForJourneys,{
          start_date:formatDate(fromDate),
          end_date:formatDate(toDate),
          user_id:selectMandoob,
          journey_type_id:journeyType,
         }]
      }
   }
 
   const StoreJourneysInDataBase=async ()=>{
     let data=formatData();
     if(!data){
       return
     }

     try {
      if(props?.user?.id>0){
       setLoading(true)
       const resourcesResult = await axios({
           url: Host + "journey_plans",
           method: "put",
           headers: {
               "Authorization": `Bearer ${token}`,
               Accept: "application/json",
               'X-localization': language,
           },
           params:{
             id:props?.user?.id
           },
           data:{
               days:data[0],
               ...data[1],
           }
       })
       if (resourcesResult) {
           if (resourcesResult.status === 200) {
            if(resourcesResult?.data)
            {
                toast.success('journey add success')
                props.advanceSearch()
                dispatch(setGlobalVariableForJourneys([]))
                // dispatch(setDistrictsList([]))
                // dispatch(setDistrictsList_temp([]))
                // dispatch(setRegionsList_temp([]))

                props.setOpen(false)
            }
           } else if (resourcesResult.data.status === false) {
           }
       }
       else {
           toast.error("Error")
       }
       setLoading(false)
      }else{
        setLoading(true)
        const resourcesResult = await axios({
            url: Host + "journey_plans",
            method: "post",
            headers: {
                "Authorization": `Bearer ${token}`,
                Accept: "application/json",
                'X-localization': language,
            },
            data:{
                days:data[0],
                ...data[1],
            }
        })
        if (resourcesResult) {
            if (resourcesResult.status === 200) {
             if(resourcesResult?.data)
             {
                 toast.success('journey add success')
                 props.advanceSearch()
                 props.setOpen(false)
             }
            } else if (resourcesResult.data.status === false) {
            }
        }
        else {
            toast.error("Error")
        }
        setLoading(false)
      }
     
    } catch (error) {
        console.log("error===>",error);
        setLoading(false)
        const resourcesResult = error.response
        if (resourcesResult && resourcesResult.status === 400) {
            if (typeof resourcesResult.data.data === "object") {
                const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                errorMessagesKeys.map((key) => {
  
                    resourcesResult.data.data[key].map((message) => {
                        toast.error(message)
                    })
                })
            }
            else {
  
                toast.error(resourcesResult.data.data)
            }
  
  
        }
        else if (resourcesResult && resourcesResult.status === 401) {
  
            toast.error(resourcesResult.data.data)
            cookies.remove("token");
            window.location.href = "/"
        }
        else {
  
            toast.error("network error")
        }
    }
   }

   const getDataofSelectedOnject=async (id)=>{
    try {
      setLoading(true)
      const resourcesResult = await axios({
          url: Host + "journey_plan?id="+id,
          method: "get",
          headers: {
              "Authorization": `Bearer ${token}`,
              Accept: "application/json",
              'X-localization': language,
          },

      })
      if (resourcesResult) {
          if (resourcesResult.status === 200) {
           if(resourcesResult?.data&&resourcesResult?.data?.length>0)
           {
               console.log('user',resourcesResult?.data)
               dispatch(setGlobalVariableForJourneys(resourcesResult?.data))
              //  toast.success('journey add success')
              //  props.advanceSearch()
              //  props.setOpen(false)
           }
          } else if (resourcesResult.data.status === false) {
          }
      }
      else {
          toast.error("Error")
      }
      setLoading(false)
    
    } catch (error) {
        console.log("error===>",error);
        setLoading(false)
        const resourcesResult = error.response
        if (resourcesResult && resourcesResult.status === 400) {
            if (typeof resourcesResult.data.data === "object") {
                const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                errorMessagesKeys.map((key) => {

                    resourcesResult.data.data[key].map((message) => {
                        toast.error(message)
                    })
                })
            }
            else {

                toast.error(resourcesResult.data.data)
            }


        }
        else if (resourcesResult && resourcesResult.status === 401) {

            toast.error(resourcesResult.data.data)
            cookies.remove("token");
            window.location.href = "/"
        }
        else {

            toast.error("network error")
        }
    }
  }

  useEffect(()=>{
     console.log('user list========>',props?.user)
    if(props?.user&&props?.user?.id>0){
      getDataofSelectedOnject(props?.user?.id)
    }
    if(props?.user&&props?.user?.user)
     setSelectMandoob(props?.user?.user?.id)

    if(props?.user&&props?.user?.start_date){
      setFromDate(new Date(props?.user?.start_date))
      setDailyData(new Date(props?.user?.start_date))
    }

    if(props?.user&&props?.user?.end_date)
      setToDate(new Date(props?.user?.end_date))

    if(props?.user&&props?.user?.journey_type){
      setJourneyType(props?.user?.journey_type?.id)
      setJourneyTypeName(props?.user?.journey_type?.name)
    }
    
    if(props?.user){
      setIsReapedted(props?.user?.is_repetitive)
      // if(props?.user?.journey_plan_days)
      // setValue_Week(props?.user?.journey_plan_days?.day_number-1)
    }
    
    

  },[props?.user])

   const handleChange_Week = (event, newValue)=> {
    console.log('appbar',newValue)
    setValue_Week(newValue);
  };

    return(
        <Box
          sx={{
            width:'100%',
             display: 'flex',
             justifyContent: 'center',alignItems:'center',

            '& .MuiOutlinedInput-root':{
                borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
                color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
            },
            '& .MuiInputLabel-root':{
                // backgroundColor:`${MainTheme?.paperTextColor+'22'} !important`,
                color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
            },
            '& .MuiFormControl-root':{
              // border:`1px solid ${MainTheme?.paperTextColor} !important`,
              color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
              },
              '&:hover fieldset': {
                borderColor: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
              },
              '&.Mui-focused fieldset': {
                borderColor: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.secondaryColor,
              },
            },
            '& .MuiOutlinedInput':{
              // borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
            },
            "& .MuiOutlinedInput-notchedOutline": {
                // border: "0 none",
             },
             '& input,& span,& p,& div,svg':{
                color:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,

             },
             '& .muirtl-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root:before,& .muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root:before,& .muiltr-1ptx2yq-MuiInputBase-root-MuiInput-root:before,& .muiltr-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root:before':{
               borderColor: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.secondaryColor,

             },
             "& .muirtl-bl8e4a input,& .muirtl-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.muirtl-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.muirtl-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input":{
              display:'flex',
              justifyContent: 'start',
             }
             
          }}
        >
          <Box
           sx={{
            backgroundColor:MainTheme?.paperColor,
            boxShadow:'0 0 7px 1px #000',
            width:'99%',
            padding:'10px',
            borderRadius:'5px',
           }}
          >
            {/* filter header here  */}
              <Box 
                sx={{
                  width:'100%',
                  height:'auto',
                  borderRadius:'5px',

                  // borderTopLeftRadius:'10px',
                  // borderTopRightRadius:'10px',
                  // backgroundColor:`${MainTheme?.paperTextColor+'66'} !important`,
                  // border:`1px solid ${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,
                  padding:'15px 30px',
                  boxShadow:`0 0 7px -3px  ${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor}  !important`,
                  display:'flex',
                  justifyContent: 'start',
                  alignItems: 'center',
                  flexWrap:'wrap',
                  padding:'25px 15px',
                  "& .muirtl-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":{
                    textAlign:'left !important',
                    display: 'flex',
                    justifyContent: 'start',
                  },
                  '& input,& span,& p,& div,svg':{
                    color:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,
                 }
                }}
              >
                  {/* <TextField 
                      label={t('JOURNEYS.JP_TYPE')} 
                      color="secondary" 
                        
                      sx={{
                          width:'48%',
                          margin:'0 10px'
                      }}
                  /> */}
                  <Box
                    sx={{
                      width:screenSize<=1270?'98%':'48%',
                      margin:'2px 10px',

                    }}
                  >
                      <FormControl fullWidth variant="standard">
                          <InputLabel id="demo-simple-select-label">{t('JOURNEYS.JP_TYPE')}</InputLabel>
                          <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={journeyType}
                          label={t('JOURNEYS.JP_TYPE')}
                          onChange={(e)=>{setJourneyType(e.target.value)}}
                          sx={{
                            
                            
                          }}
                      >   
                              {
                                journey_types&&journey_types?.length>0&&journey_types.map((data)=>(
                                  <MenuItem value={data?.id} onClick={()=>{
                                    setJourneyTypeName(data?.name)
                                  }}>{data?.name}</MenuItem>

                                ))
                              }
                          </Select>
                      </FormControl>
                  </Box>

                  <Box
                    sx={{
                      width:screenSize<=1270?'98%':'48%',
                      margin:'2px 10px'

                    }}
                  >
                      <FormControl fullWidth variant="standard">
                          <InputLabel id="demo-simple-select-label">{t('JOURNEYS.JP_SELECT_MANDOOB')}</InputLabel>
                          <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={selectMandoob}
                          label={t('JOURNEYS.JP_SELECT_MANDOOB')}
                          onChange={(e)=>{setSelectMandoob(e.target.value)}}
                          >
                            {
                              users&&users?.length>0&&users?.map((data)=>(
                                // data?.is_mobile==1?
                                <MenuItem value={data?.id}>{data?.username}</MenuItem>
                                // :null
                              ))
                            }
                             {
                              !checkIfNoMandoob()?<MenuItem value={0}>{t('JOURNEYS.NO_MANDOOBEEN')}</MenuItem>:null
                            } 
                            
                          </Select>
                      </FormControl>
                  </Box>
                  {
                  journeyTypeName=='Weekly'?
                  <Box
                    sx={{
                      width:screenSize<=1270?'98%':'48%',
                      margin:'10px 10px',
                      display:'flex',
                      alignItems: 'center', 
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs} 
                    sx={{
                      display:'flex',
                      alignItems: 'center',
                      width:'100%',

                    }}>
                      <Box
                      sx={{
                        display:'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width:'100%',
                      }}
                      >
                    
                          <DesktopDatePicker
                            label={t('JOURNEYS.FROMDATE')}
                            inputFormat="YYYY/MM/DD"
                            value={fromDate}
                            // minDate={new Date()}
                            onChange={(date)=>setFromDate(date)}
                            renderInput={(params) => <TextField {...params} sx={{width:'45%'}} variant="standard"/>}
                          />
                          <Box sx={{width:'10%',display:'flex',justifyContent: 'center',alignItems: 'center'}}>{t('JOURNEYS.TO')}</Box>
                          <DesktopDatePicker
                            label={t('JOURNEYS.TODATE')}
                             inputFormat="YYYY/MM/DD"
                            value={toDate}
                            onChange={(date)=>setToDate(date)}
                            renderInput={(params) => <TextField {...params} sx={{width:'45%'}} variant="standard"/>}
                          />
                      </Box>
                    </LocalizationProvider>

                  </Box>
                  :null
                  }

                 {
                  journeyTypeName=="Daily"?
                  <Box
                  sx={{
                    display:'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width:screenSize<=1270?'98%':'48%',
                    margin:'2px 10px'

                    
                  }}
                  >
                      <LocalizationProvider dateAdapter={AdapterDayjs} 
                  sx={{
                    display:'flex',
                    alignItems: 'center',
                    width:'100%',

                  }}>
                      <DesktopDatePicker
                        label={t('JOURNEYS.JP_DAILY_DATE')}
                         inputFormat="YYYY/MM/DD"
                        value={dailyDate}
                        // minDate={toDate}
                        onChange={(date)=>{
                          setDailyData(date)}}
                        renderInput={(params) => <TextField {...params} sx={{width:'100%'}} variant="standard"/>}
                      />
                    </LocalizationProvider>
                  </Box>
                  :null
                  }

                  {
                    journeyTypeName=='Daily'?
                    <Box sx={{display: 'flex',alignItems: 'center',justifyContent: 'space-between',  width:screenSize<=1270?'98%':'48%',
                    margin:'10px 10px'
                    ,}}>
                      <FormControlLabel control={<Checkbox  value={isReapedted} onChange={
                        (e)=>{setIsReapedted(e.target.checked)}
                      } />} label={t('JOURNEYS.REPEAT')}  sx={{width:'17%'}}/>
                      <LocalizationProvider dateAdapter={AdapterDayjs} 
                      sx={{
                        display:'flex',
                        alignItems: 'center',
                        width:'100%',

                      }}>
                        {isReapedted?
                        <Box
                        sx={{
                          display:'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width:'83%',
                        }}
                        >
                            <DesktopDatePicker
                              label={t('JOURNEYS.UNTILREPEAT')}
                               inputFormat="YYYY/MM/DD"
                              value={isReapedtedDate}
                              // minDate={toDate}
                              onChange={(date)=>{
                                console.log(date)
                                setIsReapedtedDate(date)}}
                              renderInput={(params) => <TextField {...params} sx={{width:'100%'}} variant="standard"/>}
                            />
                        </Box>:null}
                      </LocalizationProvider>
                    </Box>
                  :null
                    }
              



              </Box>


              {/* tabs here  */}
              <Box 
                  sx={{display:'flex',justifyContent: 'start',flexDirection:screenSize<=1000?'column':'row',alignItems:'start',
                      overflowY:'hidden !important',
                    
                    }}>
                <Box 
                  sx={{ 
                  backgroundColor: MainTheme?.paperColor+'22' , 
                  width: screenSize<=1270?'100%':'50%',
                  height:'100%',
                  overflow:'hidden !important',
                  // overflowX:'scroll !important',

                  borderRight:screenSize<=1270?'none':`1px solid ${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor}44 !important`,
                  borderBottom:screenSize<=1270?`1px solid ${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor}44 !important`:'none',


                  '& .muiltr-m9glnp-MuiPaper-root-MuiDialog-paper ,& .muirtl-m9glnp-MuiPaper-root-MuiDialog-paper':{
                      backgroundColor: MainTheme?.paperColor+'22',
                      color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                    
                  },
                  '& p,& span,& svg,& input':{
                        color:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,
                  },
                  '& .react-swipeable-view-container':{
                    backgroundColor:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor+'22'} !important`,
                    display:'flex',
                    justifyContent: 'start',

                  }
                
                  }}
                >
                  <AppBar position="static" sx={{backgroundColor:`transparent !important`,height:'50px'}}>
                  {
                      journeyTypeName=="Weekly"?
                        <Tabs
                        value={value_week}
                        onChange={handleChange_Week}
                        indicatorColor="secondary"
                        textColor="inherit"
                        variant="fullWidth"
                        aria-label="full width "
                        sx={{
                            backgroundColor:`transparent`,
                            '& .muiltr-heg063-MuiTabs-flexContainer,& .muirtl-heg063-MuiTabs-flexContainer':{
                                width:directions=='rtl'?'320px !important':'150px !important',
                            },
                            '& .muiltr-11yukd5-MuiTabs-indicator,& .muirtl-11yukd5-MuiTabs-indicator':{
                                backgroundColor:MainTheme?.secondaryColor
                            },
                            '& .muiltr-m9glnp-MuiPaper-root-MuiDialog-paper ,& .muirtl-m9glnp-MuiPaper-root-MuiDialog-paper':{
                              backgroundColor: MainTheme?.paperColor+'22',
                              color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                            },
                            '& p,& span,& svg,& input':{
                                color:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,
                            }
                        }}
                        >
                        
                            <Tab label={t('JOURNEYS.JP_SUNDAY')} sx={{width:screenSize<=1270?'100px':'70px !important',outline:'none !important',border:'none !important'}} />
                            <Tab label={t('JOURNEYS.JP_MONDAY')}   sx={{width:screenSize<=1270?'100px':'70px !important',outline:'none !important',border:'none !important'}} />
                            <Tab label={t('JOURNEYS.JP_TUESDAY')}  sx={{width:screenSize<=1270?'100px':'70px !important',outline:'none !important',border:'none !important'}} />
                            <Tab label={t('JOURNEYS.JP_WEDNESDAY')}  sx={{width:screenSize<=1270?'100px':'70px !important',outline:'none !important',border:'none !important'}} />
                            <Tab label={t('JOURNEYS.JP_THURSDAY')}   sx={{width:screenSize<=1270?'100px':'70px !important',outline:'none !important',border:'none !important'}} />
                            <Tab label={t('JOURNEYS.JP_FRIDAY')}   sx={{width:screenSize<=1270?'100px':'70px !important',outline:'none !important',border:'none !important'}} />
                            <Tab label={t('JOURNEYS.JP_SATURDAY')}   sx={{width:screenSize<=1270?'100px':'70px !important',outline:'none !important',border:'none !important'}} />
                            
                        </Tabs>
                      :
                      <Tabs
                      value={value_week}
                      onChange={handleChange_Week}
                      indicatorColor="secondary"
                      textColor="inherit"
                      variant="fullWidth"
                      aria-label="full width "
                      sx={{
                          backgroundColor:`transparent`,
                          '& .muiltr-heg063-MuiTabs-flexContainer,& .muirtl-heg063-MuiTabs-flexContainer':{
                              width:directions=='rtl'?'320px !important':'150px !important',
                          },
                          '& .muiltr-11yukd5-MuiTabs-indicator,& .muirtl-11yukd5-MuiTabs-indicator':{
                              backgroundColor:MainTheme?.secondaryColor
                          },
                          '& .muiltr-m9glnp-MuiPaper-root-MuiDialog-paper ,& .muirtl-m9glnp-MuiPaper-root-MuiDialog-paper':{
                            backgroundColor: MainTheme?.paperColor+'22',
                            color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                          },
                          '& p,& span,& svg,& input':{
                              color:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,
                          }
                      }}
                      >
                        <Tab label={t('JOURNEYS.JP_DAILY_TAB')} {...a11yProps(0)} sx={{width:'150px !important',outline:'none !important',border:'none !important'}} />
                      </Tabs>
                  }
                  </AppBar>
                  {
                  props?.user&&props?.user?.id>0?
                  <Box
                    sx={{
                      backgroundColor:MainTheme?.paperColor+'33',
                    }}
                  >
                    {
                      GlobalVariableForJourneys&&GlobalVariableForJourneys?.length>0?
                       <ContentOfPoints title="tuesday" index={value_week+1} props={props} setFinalSelectedDistricts={setFinalSelectedDistricts} finalSelectedDistricts={finalSelectedDistricts} finalSelectedPoints={finalSelectedPoints} setFinalSelectedPoints={setFinalSelectedPoints}/>
                      :null
                    }
                      </Box>:
                  <Box
                  sx={{
                    backgroundColor:MainTheme?.paperColor+'33',
                  }}
                >
                    <ContentOfPoints title="tuesday" index={value_week+1} props={props} setFinalSelectedDistricts={setFinalSelectedDistricts} finalSelectedDistricts={finalSelectedDistricts} finalSelectedPoints={finalSelectedPoints} setFinalSelectedPoints={setFinalSelectedPoints}/>
                  </Box>
                  }
                </Box>
                <Box 
                  sx={{ 
                  backgroundColor: MainTheme?.paperColor+'22' , 
                  width: screenSize<=1270?'100%':'50%',
                  height:'100%',
                  '& .muiltr-m9glnp-MuiPaper-root-MuiDialog-paper ,& .muirtl-m9glnp-MuiPaper-root-MuiDialog-paper':{
                      backgroundColor: MainTheme?.paperColor+'22',
                      color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                  },
                  '& p,& span,& svg,& input':{
                        color:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,
                  },
                  '& .react-swipeable-view-container':{
                    backgroundColor:`${MainTheme?.paperTextColor+'22'} !important`,
                    display:'flex',
                    justifyContent: 'start',
                    alignItems: 'start',

                  }
                
                  }}
                >
                <AppBar position="static" sx={{backgroundColor:`transparent !important`,height:'50px',boxShadow:'none !important'}}>
                    <Tabs
                    value={viewInfoOfSelect}
                    onChange={handleChange_info}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    sx={{
                        backgroundColor:`transparent`,
                        '& .muiltr-heg063-MuiTabs-flexContainer,& .muirtl-heg063-MuiTabs-flexContainer':{
                            width:directions=='rtl'?'320px !important':'250px !important',
                        },
                        '& .muiltr-11yukd5-MuiTabs-indicator,& .muirtl-11yukd5-MuiTabs-indicator':{
                            backgroundColor:MainTheme?.secondaryColor
                        },
                        '& .muiltr-m9glnp-MuiPaper-root-MuiDialog-paper ,& .muirtl-m9glnp-MuiPaper-root-MuiDialog-paper':{
                          backgroundColor: MainTheme?.paperColor+'22',
                          color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                        },
                        '& p,& span,& svg,& input':{
                            color:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,
                        }
                    }}
                    >
                      <Tab label={t('JOURNEYS.JP_SHOW_DETIALS')}  sx={{width:'150px !important',outline:'none !important',border:'none !important'}} />
                    </Tabs>
                </AppBar>
                <Box
                    sx={{
                      width:'100%',
                      height:'500px !important',
                      display: 'flex',
                      justifyContent: 'start',
                      flexDirection: 'column',
                      overflowY:'scroll !important',
                      marginTop:'15px'
                    }}
                >
                    {
                          DistrictsList?.length>0&&DistrictsList?.map((itm)=>(
                            itm?.show && itm?.children&&itm?.children?.length>0&&itm?.children?.map((district)=>(
                                  district?.check&&district?.show?
                                  <Box>
                                    <h4 style={{display:'flex',justifyContent: 'start',textAlign:'left'}}><ArrowRightAltIcon sx={{color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,fontSize:"32px !important",margin:'0 0 0 20px'}}/>{district?.label}</h4>
                                    {
                                    regionsList&&regionsList?.length>0&&regionsList?.map((region)=>(
                                      region?.parent_id==district?.value&&region?.check&&region?.show?
                                      <Box sx={{display:'flex',justifyContent: 'start',}}>
                                          <Box sx={{display:'flex',justifyContent: 'start'}}>
                                            <ListItemButton>
                                              <ListItemIcon>
                                                <ArrowRightIcon sx={{fontSize:'26px'}}/>
                                              </ListItemIcon>
                                              <ListItemText primary={region?.label} />
                                            </ListItemButton>
                                          </Box>
                                      </Box>
                                      :null
                                    ))
                                  }
                                  </Box>
                                  :null
                                ))
                          ))
                    }
                </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  marginTop:'30px',
                }}
              >
                  <Button variant="contained" 
                  onClick={()=>StoreJourneysInDataBase()}
                  sx={{
                    backgroundColor: MainTheme?.secondaryColor,
                    color: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                    padding:'15px 25px',
                    marginLeft:'10px'
                  }}>{t('JOURNEYS.SAVEALL')}</Button>
                  <Button variant='contained'
                  onClick={()=>props?.setOpen(false)}
                    sx={{
                      backgroundColor: MainTheme?.secondaryColor,
                      color: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                      padding:'15px 25px',
                      marginLeft:'10px'


                    }}
                  >{t('JOURNEYS.CANCEL')}</Button>
              </Box>
            </Box>

        </Box>
    )
}



// show content of tree here.================================================

const CustomContentRoot = styled('div')(({ theme,MainTheme }) => ({
  WebkitTapHighlightColor: 'transparent',
  // backgroundColor:MainTheme?.paperColor,
  color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.papertextColor,
  // width:'400px', 
  // height:'50px',
  '&:hover, &.Mui-disabled, &.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused, &.Mui-selected:hover':
    {
      // backgroundColor: 'transparent',
    },

  [`& .MuiTreeItem-contentBar`]: {
    position: 'absolute',
    width: '100%',
    height: 24,
    
    left: 0,
    '&:hover &': {
      // backgroundColor: theme.palette.action.hover,
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-disabled &': {
      opacity: theme.palette.action.disabledOpacity,
      backgroundColor: 'transparent',
    },
    '&.Mui-focused &': {
      // backgroundColor: theme.palette.action.focus,
    },
    '&.Mui-selected &': {
      // backgroundColor: alpha(
      //   theme.palette.primary.main,
      //   theme.palette.action.selectedOpacity,
      // ),
    },
    '&.Mui-selected:hover &': {
      // backgroundColor: alpha(
      //   theme.palette.primary.main,
      //   theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity,
      // ),
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        // backgroundColor: alpha(
        //   theme.palette.primary.main,
        //   theme.palette.action.selectedOpacity,
        // ),
      },
    },
    '&.Mui-selected.Mui-focused &': {
      // backgroundColor: alpha(
      //   theme.palette.primary.main,
      //   theme.palette.action.selectedOpacity + theme.palette.action.focusOpacity,
      // ),
    },
  },
}));
const CustomContent = React.forwardRef(function CustomContent(props, ref) {
  const directions =useSelector(state=>state.settingsData.directions)
  const MainTheme =useSelector(state=>state.settingsData.MainTheme)
  const {
    className,
    classes,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId);

  const icon = expansionIcon || displayIcon;

  const handleMouseDown = (event) => {
    preventSelection(event);
  };

  const handleClick = (event) => {
    handleExpansion(event);
    handleSelection(event);
  };

  return (
    <CustomContentRoot
       MainTheme={MainTheme}
      className={clsx(className, classes.root, {
        'Mui-expanded': expanded,
        'Mui-selected': selected,
        'Mui-focused': focused,
        'Mui-disabled': disabled,
      })}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      ref={ref}
    >
      <div className="MuiTreeItem-contentBar" />
      <div className={classes.iconContainer}>{icon}</div>
      <Typography component="div" className={classes.label}>
        {label}
      </Typography>
    </CustomContentRoot>
  );
});

CustomContent.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object.isRequired,
  /**
   * className applied to the root element.
   */
  className: PropTypes.string,
  /**
   * The icon to display next to the tree node's label. Either a parent or end icon.
   */
  displayIcon: PropTypes.node,
  /**
   * The icon to display next to the tree node's label. Either an expansion or collapse icon.
   */
  expansionIcon: PropTypes.node,
  /**
   * The icon to display next to the tree node's label.
   */
  icon: PropTypes.node,
  /**
   * The tree node label.
   */
  label: PropTypes.node,
  /**
   * The id of the node.
   */
  nodeId: PropTypes.string.isRequired,
};

const CustomTreeItem = (props) => (
  <TreeItem ContentComponent={CustomContent} {...props}  sx={{width:'50%'}}/>
);

const ContentOfPoints=(props)=>{
  const directions =useSelector(state=>state.settingsData.directions)
  const MainTheme =useSelector(state=>state.settingsData.MainTheme)
  const [t, i18n] = useTranslation('common');
  const token=cookies.get('token');
  let date_shiftWeek = new Date();
  const dispatch=useDispatch()
  const [searchPoints,setSearchPoints]=useState('');
  date_shiftWeek.setDate(date_shiftWeek.getDate() + 7);
  // const {users,fromToDate,journey_types,loading,setRegions,userDailyPlans,setPoints,setDistricts,user,setUserDailyPlans,pointsStatuses,pointStatus,setPointStatus,exportToCSV,allPoints,points,district,region,districts,regions,ownerName,shopName,clearPointsAdvanceSearchFields,setRegion,setDistrict,setShopName,setOwnerName,fetchData,deleteObjectt}=useGlobalContext();
  // const [regionsPoints, setregionsPoints] = useState([]);
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  // const [regionList,setRegionsList]=useState([])
  //  const dispatch=useDispatch()
  const DistrictsList=useSelector(state=>state.settingsData.DistrictsList)
   const regionsList=useSelector(state=>state.settingsData.regionsList)
   const GlobalVariableForJourneys =useSelector(state=>state.settingsData.GlobalVariableForJourneys)

  const RegionsList_temp=useSelector(state=>state.settingsData.RegionsList_temp)
  const DistrictsList_temp=useSelector(state=>state.settingsData.DistrictsList_temp)

  const [loading,setLoading]=useState(false)
  const [districtsState,setdistrictsState] =useState({
      checked: [],
      expanded: [],
  });
  const [regionsState,setregionsState] =useState({
      checked: [],
      expanded: [],
  });
  const [pointsState,setpointsState] =useState({
      checked: [],
      expanded: [],
  });

  const checkRegionExist=(reg_id,dist_id)=>{
    let check=false;
    if(GlobalVariableForJourneys?.length>0){
      GlobalVariableForJourneys?.map((data)=>{
        if(data?.number==props?.index){
          data?.districts&&data?.districts?.length>0&&data?.districts?.map((itm)=>{
            if(itm?.id==dist_id&&itm?.regions?.length>0){
              check = itm?.regions?.find( ({ id }) => id ===reg_id );
            }
          })
        }
      })
    }
    return check;
  }

  const getRegionsByDistrict=async()=>{

      setLoading(true)
      try
      {
          const resourcesResult1 = await axios({
              url: Host + "regions_list",
              method: "get",
              headers: {
                  "Authorization": `Bearer ${token}`,
                  Accept: "application/json",
                  'X-localization': language,
              },
              // params:{
              //     district_ids:district_ids
              // }

          })
          if (resourcesResult1) {
              if (resourcesResult1.status === 200) {
                  const resources1 = resourcesResult1.data;

                  let arr=[]
                  resources1.map((district)=>{
                    // arr.push({
                          // "label":"اختر كل "+district.name,
                          // "value":district.id,
                          // district.map((region)=>{
                            //  let arr=[]
                            arr.push({
                                  parent_id:district?.district_id,
                                  label:district?.name,
                                  value:district.id,
                                  name:'region',
                                  check:checkRegionExist(district.id,district?.district_id),
                                  show:true,

                              })
                              //  arr;
                     })
                      // })
                  // });
                  // setRegions(arr)
                  dispatch(setRegionsList(arr))
                  dispatch(setRegionsList_temp(arr))
                  
                  
              } else if (resourcesResult1.data.status === false) {
                  toast.error("Error in loading regions")
                  // setRegions([]);
                  // setRegionsList([])
                  // setregionsPoints([]);
                  // dispatch(setRegionsList([]))

                  // setPoints([])                    
              }
          }
          else {
              toast.error("Error in regions request")
              // setRegions([]);
              // setregionsPoints([]);
              // dispatch(setRegionsList([]))

              // setPoints([])
          }
      }
      catch(error)
      {
        console.log(error); 
        // dispatch(setRegionsList([]))

        // setRegions([]);
        //  setregionsPoints([]);
        // setPoints([]);
      }
      setLoading(false)

    //  }
  }

//   const getRegionsByDistrict_Custome=async(district_ids)=>{
//     let check=false;
//     regions&&regions?.length>0&&regions?.map((itm)=>{
//          if(itm?.parent_id===district_ids[0]){
//            check=true;
//          } 
//     })
//   if(!check){
//     try
//     {
//         const resourcesResult1 = await axios({
//             url: Host + "district_regions",
//             method: "get",
//             headers: {
//                 "Authorization": `Bearer ${token}`,
//                 Accept: "application/json",
//                 'X-localization': language,
//             },
//             params:{
//                 district_ids:district_ids
//             }

//         })
//         if (resourcesResult1) {
//             if (resourcesResult1.status === 200) {
//                 const resources1 = resourcesResult1.data;

//                 let arr=[...regions]
//                 resources1.map((district)=>{
//                   // arr.push({
//                         // "label":"اختر كل "+district.name,
//                         // "value":district.id,
//                         district.regions.map((region)=>{
//                           //  let arr=[]
//                           arr.push({
//                                 parent_id:district?.id,
//                                 label:region?.name,
//                                 value:region.id,
//                                 name:'region',
//                                 "check":false,
//                             })
//                             //  arr;
//                         })
//                     // })
//                 });
//                 setRegions(arr)
//                 return arr;
                
//             } else if (resourcesResult1.data.status === false) {
//                 toast.error("Error in loading regions")
//                 setRegions([]);
//                 setRegionsList([])
//                 // setregionsPoints([]);
//                 setPoints([])                    
//             }
//         }
//         else {
//             toast.error("Error in regions request")
//             setRegions([]);
//             // setregionsPoints([]);
//             setPoints([])
//         }
//     }
//     catch(error)
//     {
//       console.log(error); 
//       setRegions([]);
//       //  setregionsPoints([]);
//       setPoints([]);
//     }
//   }else{
//     return regions
//   }
// }
  // const getPointsByRegion=async(region_ids)=>{
      
  //     try
  //     {
  //         const resourcesResult1 = await axios({
  //             url: Host + "region_points",
  //             method: "get",
  //             headers: {
  //                 "Authorization": `Bearer ${token}`,
  //                 Accept: "application/json",
  //                 'X-localization': language,
  //             },
  //             params:{
  //                 region_ids:region_ids
  //             }

  //         })
          
  //         console.log('getpoints')
  //         console.log(resourcesResult1)    

  //         if (resourcesResult1) {
  //             if (resourcesResult1.status === 200) {
  //                 const resources1 = resourcesResult1.data;

  //                 var checkedIds=[]
  //                 setregionsPoints(resources1)
  //                 setPoints(resources1.map((region)=>{
  //                     return {
  //                         label:"اختر كل "+region.name,
  //                         value:"region_id_"+region.id,
  //                         children:region.points.map((point)=>{
  //                             checkedIds.push(point.id)
  //                             return {
  //                                 label:point.name,
  //                                 value:point.id
  //                             }
  //                         })
  //                     }
  //                 }));
  //                 setpointsState({
  //                     ...pointsState,
  //                     checked:checkedIds
  //                 })
  //                 props.setobject({
  //                     ...props.object,
  //                     points_ids:checkedIds

  //                 })
                  
  //             } else if (resourcesResult1.data.status === false) {
  //                 toast.error("Error in loading regions")
  //                 setPoints([]);
  //             }
  //         }
  //         else {
  //             toast.error("Error in regions request")
  //             setPoints([]);
  //         }
  //     }
  //     catch(error)
  //     {
  //       console.log(error); 
  //       setPoints([]);
  //     }
  // }


  const checkDistrictExist=(dis_id)=>{
    let check=false;
    if(GlobalVariableForJourneys?.length>0){
      GlobalVariableForJourneys?.map((data)=>{
        if(data?.number==props?.index&&data?.districts?.length>0){
          check = data?.districts?.find( ({ id }) => id === dis_id );
          // data?.districts&&data?.districts?.length>0&&data?.districts?.map((itm)=>{
          //     if(id==itm?.id){
          //       check=true;
          //     }
          // })
        }
      })
    }
    return check;
  }

  const checkGlobalExist=()=>{
    let check=false;
    if(GlobalVariableForJourneys?.length>0){
      // GlobalVariableForJourneys?.map((data)=>{
      //   if(data?.number==props?.index){

      //     if(data?.districts&&data?.districts?.length>0){
      //       check=true;
      //     }
      //   }
      // })
       check = GlobalVariableForJourneys.find( ({ number }) => number === props?.index );

    }
    return check;
  }
  
  const getResources = async () => {
    setLoading(true)

      try {                        
          const resourcesResult = await axios({
              url: Host + "districts_list",
              method: "get",
              headers: {
                  "Authorization": `Bearer ${token}`,
                  Accept: "application/json",
                  'X-localization': language,
              }
          })
          if (resourcesResult) {
              if (resourcesResult.status === 200) {
                  const resources = resourcesResult.data;    
                  let checkGlobal=false;
                  // if(GlobalVariableForJourneys?.length>0&&){
                  //   checkGlobal=true;
                  // }
                  
                  const prepareResources=[
                      {
                          "label": "اختر الكل",
                          "value": 0,
                          check:checkGlobalExist(),
                          "name":'districtsAll',
                          show:true,
                          "children": resources.map((resource)=>{
                              return {
                                  label:resource.name,
                                  value:resource.id,
                                  show:true,
                                  check:checkDistrictExist(resource.id),
                                  name:"regionAll"
                              }
                              
                          })
                      }
                  ] 
                  
                  
                 
            
          //  })
                  // if(props.object.update)
                  // {
                  //     console.log('districts')
                  //     console.log(prepareResources)

                  //     setDistricts(prepareResources);
                  //     updateAllCheckboxes();
                  // }
                  // else{
                      // setDistricts(prepareResources);
                      // let finlAarr=[]
                      // let checGlobal=false;
                      // if(GlobalVariableForJourneys&&GlobalVariableForJourneys?.length>0){
                      //   let check=false
                      //   let arr=[]
                      //   GlobalVariableForJourneys?.map((day)=>{
                      //     if(day?.number==props?.index){
                      //       checGlobal=true;
                      //        day?.districts&&day?.districts?.length>0&&day?.districts?.map((itm)=>{
                      //         resourcesResult.data?.map((dis,index)=>{
                      //           if(dis?.id==itm?.id){
                      //             check=true
                      //             arr.push({
                      //               ...prepareResources[0]?.children[index],
                      //                 check:true,
                      //             })
                      //           }else{
                      //             arr.push(prepareResources[0].children[index])
                      //           }
                              
                      //         })

                      //        })
                      //     }

                      //   })
                      //   if(arr?.length>0)
                      //   finlAarr.push({
                      //     ...prepareResources[0],
                      //        check:check,
                      //        children:arr
                      //   })
                    
                      // }
                      // else if(!checGlobal){
                      
                        dispatch(setDistrictsList(prepareResources))
                        dispatch(setDistrictsList_temp(prepareResources))
                         console.log('districts list',prepareResources)
                      // // }
                      // // if(checGlobal){
                      // //   dispatch(setDistrictsList(finlAarr))
                      // //   dispatch(setDistrictsList_temp(finlAarr))
                      // }else if(finlAarr?.length>0){
                      //   dispatch(setDistrictsList(finlAarr))
                      //   dispatch(setDistrictsList_temp(finlAarr))
                      // }
                      
                      
                  // }
                  
                  
                  
              } else if (resourcesResult.data.status === false) {
                  toast.error("Error in loading district")
              }
          }
          else {
              toast.error("Error in regions request")
          }
        
          // setloading(false)
      } catch (error) {
          console.log(error);
          // setloading(false)
          toast.error("Network Error")
      }
      setLoading(false)

  };

  // useEffect(()=>{
  //   // props.setFinalSelectedPoints(regions)
  //   if(regions&&regions?.length>0)
  //   dispatch(setRegionsList(regions))

  // },[regions])

  // useEffect(()=>{
  //   // props.setFinalSelectedDistricts(districts)
  //   if(districts&&districts?.length>0)
  //   dispatch(setDistrictsList(districts))

  // },[districts])

  // const updateAllCheckboxes = async () => {
  //   try {
  //       // setloading(true)
  //       const resourcesResult = await axios({
  //           url: Host + "user_daily_plan_info",
  //           method: "get",
  //           headers: {
  //               "Authorization": `Bearer ${token}`,
  //               Accept: "application/json",
  //               'X-localization': language,
  //           },
  //           params:{
  //               user_id:props?.object?.user_id,
  //               plan_date:props?.object?.plan_date,

  //           }
  //       })
  //       if (resourcesResult) {
  //           if (resourcesResult.status === 200) {
  //               const resources = resourcesResult.data;

  //               await getRegionsByDistrict(resources.districts)
  //               await getPointsByRegion(resources.regions)

  //               setdistrictsState({
  //                   ...districtsState,
  //                   checked:resources.districts
  //               })
  //               setregionsState({
  //                   ...regionsState,
  //                   checked:resources.regions
  //               })
  //               setpointsState({
  //                   ...pointsState,
  //                   checked:resources.points
  //               })
                
  //           } else if (resourcesResult.data.status === false) {
  //               toast.error("Error in loading district")
  //           }
  //       }
  //       else {
  //           toast.error("Error in regions request")
  //       }
       
  //       // setloading(false)
  //   } catch (error) {
  //       console.log(error);
  //       // setloading(false)
  //       toast.error("Network Error")
  //   }
  // };


  useEffect(() => {
    // if(loading)
        getResources()
        getRegionsByDistrict()
        // getRegionsByDistrict()      
}, [props.open,props?.index]);


  // const submitForm = async () => {
      
  //   try {                    
  //       if (props?.object?.update) {

  //           const resourcesResult = await axios({
  //               url: Host + "user_daily_plans",
  //               method: "put",
  //               headers: {
  //                   "Authorization": `Bearer ${token}`,
  //                   Accept: "application/json",
  //                   'X-localization': language,
  //               },
  //               data: {
  //                   // points_ids:props?.object?.points_ids,
  //                   // old_points_ids:props?.oldObject?.points_ids,
  //                   // plan_date:props?.object?.plan_date,
  //                   // user_id:props?.object?.user_id
  //               }
  //           })
  //           if (resourcesResult) {
  //               if (resourcesResult.status === 200) {
  //                   toast.success("updated ")
  //                   // props.setshowUserDailyPlanForm(false,true)
  //               } else if (resourcesResult.data.status === false) {
  //                   // props.setshowUserDailyPlanForm(true,false)
  //               }
  //           }
  //           else {
  //               toast.error("Error")
  //               // props.setshowUserDailyPlanForm(true,false)
  //           }
  //       }
  //       else {
  //           const resourcesResult = await axios({
  //               url: Host + "user_daily_plans",
  //               method: "post",
  //               headers: {
  //                   "Authorization": `Bearer ${token}`,
  //                   Accept: "application/json",
  //                   'X-localization': language,
  //               },
  //               data: props.object
  //           })
  //           if (resourcesResult) {
  //               if (resourcesResult.status === 200) {
  //                   toast.success("saved ")
  //                   // props.setshowUserDailyPlanForm(false,true)
  //               } else if (resourcesResult.status === 400) {
  //                   toast.error(resourcesResult.data)
  //                   // props.setshowUserDailyPlanForm(true,false)
  //               }
  //           }
  //           else {
  //               toast.error("Error")
  //               // props.setshowUserDailyPlanForm(true,false)
  //           }                
  //       }
  //   } catch (error) {
  //       console.log(error);
  //       // props.setshowUserDailyPlanForm(true,false)
  //       const resourcesResult=error.response  
  //       console.log('resourcesResult');  
  //       console.log(resourcesResult);          
  //       if (resourcesResult && resourcesResult.status === 400) {
  //           if(typeof resourcesResult.data.data === "object")
  //           {
  //               const errorMessagesKeys = Object.keys(resourcesResult.data.data);
  //               errorMessagesKeys.map((key)=>{
                
  //                   resourcesResult.data.data[key].map((message)=>{
  //                       toast.error(message)
  //                   })
  //               })
  //           }
  //           else{
                
  //               toast.error(resourcesResult.data.data)
  //           }
            
            
  //       }
  //       else if(resourcesResult && resourcesResult.status === 401) {
         
  //           toast.error(resourcesResult.data.data)
  //           cookies.remove("token");
  //           window.location.href = "/"
  //       }
  //       else
  //       {
          
  //           toast.error("network error")
  //       }
  //   }
    
  // };
  const [searchResultArray,setSearchResultArray]=useState([])

  const handleClickCheckBox=(name,id,value,parent_id)=>{
    // if(!searchPoints&&searchPoints.length==0){
      let arr=[]
      if(name!='region'){
        DistrictsList&&DistrictsList?.length>0&&DistrictsList?.map((itm)=>{
            
              if(name=='districtsAll'){
                let obj={}
                if(itm?.value==id&&name==itm?.name){
                  
                  if(value==true){
                    // make all districs true,
                    let arrr_temp=[];
                    itm?.children&&itm?.children?.length>0&&itm?.children?.map((reg)=>{
                        arrr_temp.push({
                          ...reg,
                          check:true,
                        })
                    })

                    //make all regions true.
                    let arr_1=[];
                    regionsList&&regionsList?.length>0&&regionsList?.map((reg)=>{
                      arr_1.push({
                        ...reg,
                        check:true,
                      });
                    })
                    if(arr_1&& arr_1?.length>0)
                    // setRegions(arr_1)
                    dispatch(setRegionsList(arr_1))

                    //now it's time to make global parent true also ^-^ have a fun.
                    obj={
                      ...itm,
                      check:value,
                      children:arrr_temp
                    }
                  }if(value==false){
                    // make all districs true,
                    let arrr_temp=[];
                    itm?.children&&itm?.children?.length>0&&itm?.children?.map((reg)=>{
                        arrr_temp.push({
                          ...reg,
                          check:false,
                        })
                    })

                    //make all regions true.
                    let arr_1=[];
                    regionsList&&regionsList?.length>0&&regionsList?.map((reg)=>{
                      arr_1.push({
                        ...reg,
                        check:false,
                      });
                    })
                    if(arr_1&&arr_1?.length>0)
                    // setRegions(arr_1)
                    dispatch(setRegionsList(arr_1))

                    //now it's time to make global parent true also ^-^ have a fun.
                    obj={
                      ...itm,
                      check:value,
                      children:arrr_temp
                    }
                  }
                  
                
                }else{
                  obj=itm
                }
                arr.push(obj)
              }else if(name=='regionAll'){
                //make all regions true.
                let arr_temp=[];
                if(value==true){
                  
                  regionsList&&regionsList?.length>0&&regionsList?.map((reg)=>{
                    if(reg?.parent_id==id){
                      arr_temp.push({
                        ...reg,
                        check:true,
                      });
                    }else{
                      arr_temp.push(reg);
                    }
                  })
                  
                }else{
                  regionsList&&regionsList?.length>0&&regionsList?.map((reg)=>{
                    if(reg?.parent_id==id){
                      arr_temp.push({
                        ...reg,
                        check:false,
                      });
                    }else {
                      arr_temp.push(reg);
                    }
                  })
                }
                // setRegions(arr_temp)
                dispatch(setRegionsList(arr_temp))

                

                let arr_1=[];
                itm?.children&&itm?.children?.length>0&&itm?.children?.map((reg)=>{
                  let obj={}; 
                    if(reg?.value==id&&name==reg?.name){
                        obj={
                          ...reg,
                          check:value,
                        }
                    }else{
                      obj=reg
                    }
                  arr_1.push(obj);
                })
                arr.push({
                  ...itm,
                  children: arr_1,
                })

              }

        })
        // setDistricts(arr)
        dispatch(setDistrictsList(arr))

      }else if(name=='region'){
        let arr_1=[];
        let arr_3=[];
        DistrictsList&&DistrictsList?.length>0&&DistrictsList?.map((itm)=>{
          let arr_2=[];
          
          itm?.children&&itm?.children?.length>0&&itm?.children?.map((reg)=>{
           
            if(value){
              if(parent_id==reg?.value){
                arr_2.push({
                  ...reg,
                  check:true,
                }) 
              }else{
                arr_2.push(reg) 
              }
            }else{
              // counter_2--;
              let counter=0;
              regionsList&&regionsList?.length>0&&regionsList?.map((reg2)=>{
                if(parent_id==reg2?.parent_id){
                  if(reg2?.check) counter++;
                }
              })
              if((counter-1)==0){
                if(parent_id==reg?.value){
                  arr_2.push({
                    ...reg,
                    check:false,
                  }) 
                }else{
                  arr_2.push(reg) 
                }
              }
            }
          })
          let counter_2=0;
          arr_2&&arr_2?.length>0&&arr_2?.map((lis)=>{
              if(lis?.check) counter_2++;
          })
          let check__parent=false;
           if((counter_2)>0)
              check__parent=true
          if(arr_2?.length>0)
          arr_3.push({
            ...itm,
            check:check__parent,
            children:arr_2,
          })
        })
        
        regionsList&&regionsList?.length>0&&regionsList?.map((reg)=>{
            let obj={};
            if(reg?.value==id&&name==reg?.name){
                obj={
                  ...reg,
                  check:value,
                }
            }else{
              obj={...reg}
            }
            arr_1.push(obj);
        })
          // setRegions(arr_1)
          dispatch(setRegionsList(arr_1))
          if(arr_3?.length>0)
          dispatch(setDistrictsList(arr_3))
      }
    // }
    
    // else{
    //   // let result_1=[]
    //   // let result_2=[]
    //   let arr=[]
    //   if(name!='region'){
    //     DistrictsList_temp&&DistrictsList_temp?.length>0&&DistrictsList_temp?.map((itm)=>{
            
    //           if(name=='districtsAll'){
    //             let obj={}
    //             if(itm?.value==id&&name==itm?.name){
                  
    //               if(value==true){
    //                 // make all districs true,
    //                 let arrr_temp=[];
    //                 itm?.children&&itm?.children?.length>0&&itm?.children?.map((reg)=>{
    //                     arrr_temp.push({
    //                       ...reg,
    //                       check:true,
    //                     })
    //                 })

    //                 //make all regions true.
    //                 let arr_1=[];
    //                 RegionsList_temp&&RegionsList_temp?.length>0&&RegionsList_temp?.map((reg)=>{
    //                   arr_1.push({
    //                     ...reg,
    //                     check:true,
    //                   });
    //                 })
    //                 if(arr_1&& arr_1?.length>0)
    //                 // setRegions(arr_1)
    //                 dispatch(setRegionsList_temp(arr_1))
    //                 // result_2=arr_1;

    //                 //now it's time to make global parent true also ^-^ have a fun.
    //                 obj={
    //                   ...itm,
    //                   check:value,
    //                   children:arrr_temp
    //                 }
    //               }if(value==false){
    //                 // make all districs true,
    //                 let arrr_temp=[];
    //                 itm?.children&&itm?.children?.length>0&&itm?.children?.map((reg)=>{
    //                     arrr_temp.push({
    //                       ...reg,
    //                       check:false,
    //                     })
    //                 })

    //                 //make all regions true.
    //                 let arr_1=[];
    //                 RegionsList_temp&&RegionsList_temp?.length>0&&RegionsList_temp?.map((reg)=>{
    //                   arr_1.push({
    //                     ...reg,
    //                     check:false,
    //                   });
    //                 })
    //                 if(arr_1&&arr_1?.length>0)
    //                 // setRegions(arr_1)
    //                 dispatch(setRegionsList_temp(arr_1))
    //                 // result_2=[...arr_1]

    //                 //now it's time to make global parent true also ^-^ have a fun.
    //                 obj={
    //                   ...itm,
    //                   check:value,
    //                   children:arrr_temp
    //                 }
    //               }
                  
                
    //             }else{
    //               obj=itm
    //             }
    //             arr.push(obj)
    //           }else if(name=='regionAll'){
    //             //make all regions true.
    //             let arr_temp=[];
    //             if(value==true){
                  
    //               RegionsList_temp&&RegionsList_temp?.length>0&&RegionsList_temp?.map((reg)=>{
    //                 if(reg?.parent_id==id){
    //                   arr_temp.push({
    //                     ...reg,
    //                     check:true,
    //                   });
    //                 }else{
    //                   arr_temp.push(reg);
    //                 }
    //               })
                  
    //             }else{
    //               RegionsList_temp&&RegionsList_temp?.length>0&&RegionsList_temp?.map((reg)=>{
    //                 if(reg?.parent_id==id){
    //                   arr_temp.push({
    //                     ...reg,
    //                     check:false,
    //                   });
    //                 }else {
    //                   arr_temp.push(reg);
    //                 }
    //               })
    //             }
    //             // setRegions(arr_temp)
    //             dispatch(setRegionsList_temp(arr_temp))
    //             // result_2=[...arr_temp];

                

    //             let arr_1=[];
    //             itm?.children&&itm?.children?.length>0&&itm?.children?.map((reg)=>{
    //               let obj={}; 
    //                 if(reg?.value==id&&name==reg?.name){
    //                     obj={
    //                       ...reg,
    //                       check:value,
    //                     }
    //                 }else{
    //                   obj=reg
    //                 }
    //               arr_1.push(obj);
    //             })
    //             arr.push({
    //               ...itm,
    //               children: arr_1,
    //             })

    //           }

    //     })
    //     // setDistricts(arr)
    //     dispatch(setDistrictsList_temp(arr))
    //     // result_1=arr

    //   }else if(name=='region'){
    //     let arr_1=[];
    //     let arr_3=[];
    //     DistrictsList_temp&&DistrictsList_temp?.length>0&&DistrictsList_temp?.map((itm)=>{
    //       let arr_2=[];
          
    //       itm?.children&&itm?.children?.length>0&&itm?.children?.map((reg)=>{
           
    //         if(value){
    //           if(parent_id==reg?.value){
    //             arr_2.push({
    //               ...reg,
    //               check:true,
    //             }) 
    //           }else{
    //             arr_2.push(reg) 
    //           }
    //         }else{
    //           // counter_2--;
    //           let counter=0;
    //           RegionsList_temp&&RegionsList_temp?.length>0&&RegionsList_temp?.map((reg2)=>{
    //             if(parent_id==reg2?.parent_id){
    //               if(reg2?.check) counter++;
    //             }
    //           })
    //           if((counter-1)==0){
    //             if(parent_id==reg?.value){
    //               arr_2.push({
    //                 ...reg,
    //                 check:false,
    //               }) 
    //             }else{
    //               arr_2.push(reg) 
    //             }
    //           }
    //         }
    //       })
    //       let counter_2=0;
    //       arr_2&&arr_2?.length>0&&arr_2?.map((lis)=>{
    //           if(lis?.check) counter_2++;
    //       })
    //       let check__parent=false;
    //        if((counter_2)>0)
    //           check__parent=true
    //       if(arr_2?.length>0)
    //       arr_3.push({
    //         ...itm,
    //         check:check__parent,
    //         children:arr_2,
    //       })
    //     })
        
    //     RegionsList_temp&&RegionsList_temp?.length>0&&RegionsList_temp?.map((reg)=>{
    //         let obj={};
    //         if(reg?.value==id&&name==reg?.name){
    //             obj={
    //               ...reg,
    //               check:value,
    //             }
    //         }else{
    //           obj={...reg}
    //         }
    //         arr_1.push(obj);
    //     })
    //       // setRegions(arr_1)
    //       dispatch(setRegionsList_temp(arr_1))
    //       // result_2=[...arr_1]
    //       if(arr_3?.length>0)
    //       dispatch(setDistrictsList_temp(arr_3))
    //         // result_1=[...arr_3]
    //   }
    //   // setSearchResultArray(result_1,result_2)
    // }

  }

 



  const timerController=React.useRef(null)
  const setSearchChange=(value)=>{
    if(timerController?.current){
      clearTimeout(timerController.current)
    }
    setSearchPoints(value)
    searchHandlerChange(value)
  }
  const searchHandlerChange=(searchPoints)=>{
      if(searchPoints){
        let result=[];
        let result2=[];
        let check_2__=false
        regionsList&&regionsList?.length>0&&regionsList?.map((reg)=>{
          let check=reg?.label?.includes(searchPoints)
            if(check){
              result2.push({
                ...reg,
                show:true,
              })
              check_2__=true;
            }else{
              result2.push({
                ...reg,
                show:false,
              })
            }
        })
        DistrictsList&&DistrictsList?.length>0&&DistrictsList?.map((itm)=>{
          let check=itm?.label?.includes(searchPoints)
          if(!check){
            let arr_1=[];
            let check_2=false;
            itm?.children&&itm?.children?.length>0&&itm?.children?.map((dist)=>{
              let check=dist?.label?.includes(searchPoints)
              if(check){
                check_2=true;
                arr_1.push({
                  ...dist,
                  show:true,
                })
                result2=regionsList
              }else{
                let check_ex=false;
                regionsList&&regionsList?.length>0&&regionsList?.map((reg)=>{
                  if(!check_ex&&reg?.parent_id==dist?.value) 
                  check_ex=reg?.label?.includes(searchPoints);
                })

               
                  arr_1.push({
                    ...dist,
                    show:check_ex,
                  })
                
              } 
            })
            result.push({
              ...itm,
              children: arr_1?.length>0?arr_1:[],
              show:true,
            })
          }else{
            result.push({
              ...itm,
              show:true,
            })
          }

        })
        
        // setSearchResultArray([result,result2])
        dispatch(setRegionsList(result2))
        dispatch(setDistrictsList(result))
      }else{
        let arr=[]
        DistrictsList&&DistrictsList?.length>0&&DistrictsList?.map((itm)=>{
            let arr2=[]
            itm?.children&&itm.children.length>0&&itm.children?.map((dist)=>{
              arr2.push({
                ...dist,
                show:true,
              }) 
            })
            arr.push({
              ...itm,
              show:true,
              children:arr2,
            }) 
        })
        let arr3=[]
        regionsList&&regionsList?.length>0&&regionsList?.map((reg)=>{
          arr3.push({
            ...reg,
            show:true,
          })
        })
        dispatch(setDistrictsList(arr));
        dispatch(setRegionsList(arr3));

      }
  }

  const getDataAfterFormated=(daynumber)=>{
    let days=[];
    if(GlobalVariableForJourneys?.length==0&&daynumber==1){
           
          DistrictsList&&DistrictsList?.length>0&&DistrictsList?.map((itm)=>{
            let obj={}
            let arr_temp=[];
            itm?.children&&itm?.children?.length>0&&itm?.children?.map((dist)=>{
                if(dist?.check){
                  let obj={}
                  let arr_2=[];
                  regionsList&&regionsList?.length>0&&regionsList?.map((reg)=>{
                        if(reg?.parent_id==dist?.value&&reg?.check){
                          obj={
                            id:reg?.value
                          }
                          arr_2.push(obj)
                        }
                  })
                  obj={
                    id:dist?.value,
                    regions:arr_2
                  }
                  arr_temp.push(obj)
                }
            })
            obj={
              number:daynumber,
              districts:arr_temp,
            }
            days.push(obj)
          })
    }else if(GlobalVariableForJourneys?.length==0&&daynumber>1){
        DistrictsList&&DistrictsList?.length>0&&DistrictsList?.map((itm)=>{
          let obj={}
          let arr_temp=[];
          itm?.children&&itm?.children?.length>0&&itm?.children?.map((dist)=>{
              if(dist?.check){
                let obj={}
                let arr_2=[];
                regionsList&&regionsList?.length>0&&regionsList?.map((reg)=>{
                      if(reg?.parent_id==dist?.value&&reg?.check){
                        obj={
                          id:reg?.value
                        }
                        arr_2.push(obj)
                      }
                })
                obj={
                  id:dist?.value,
                  regions:arr_2
                }
                arr_temp.push(obj)
              }
          })
          obj={
            number:daynumber,
            districts:arr_temp,
          }
          days.push(obj)
        })
    }else{
            let chec_____=false;
            
            GlobalVariableForJourneys&&GlobalVariableForJourneys?.length>0&&GlobalVariableForJourneys?.map((day)=>{
              if(day?.number==daynumber){
                chec_____=true;
                  DistrictsList&&DistrictsList?.length>0&&DistrictsList?.map((itm)=>{
                    let obj={}
                    let arr_temp=[];
                    itm?.children&&itm?.children?.length>0&&itm?.children?.map((dist)=>{
                        if(dist?.check){
                          let obj={}
                          let arr_2=[];
                          regionsList&&regionsList?.length>0&&regionsList?.map((reg)=>{
                                if(reg?.parent_id==dist?.value&&reg?.check){
                                  obj={
                                    id:reg?.value
                                  }
                                  arr_2.push(obj)
                                }
                          })
                          obj={
                            id:dist?.value,
                            regions:arr_2
                          }
                          arr_temp.push(obj)
                        }
                    })
                    obj={
                      number:day?.number,
                      districts:arr_temp,
                    }
                    days.push(obj)
                  })
              }else{
                 days.push(day)
              }
            })
            if(!chec_____)
              DistrictsList&&DistrictsList?.length>0&&DistrictsList?.map((itm)=>{
                let obj={}
                let arr_temp=[];
                itm?.children&&itm?.children?.length>0&&itm?.children?.map((dist)=>{
                    if(dist?.check){
                      let obj={}
                      let arr_2=[];
                      regionsList&&regionsList?.length>0&&regionsList?.map((reg)=>{
                            if(reg?.parent_id==dist?.value&&reg?.check){
                              obj={
                                id:reg?.value
                              }
                              arr_2.push(obj)
                            }
                      })
                      obj={
                        id:dist?.value,
                        regions:arr_2
                      }
                      arr_temp.push(obj)
                    }
                })
                obj={
                  number:props?.index,
                  districts:arr_temp,
                }
                days.push(obj)
            })
         
    
    }
      if(days?.length>0){
        let arr=[];
        days?.map((itm)=>{
            if(itm?.districts?.length>0){
              arr.push(itm)
            }
        })
        dispatch(setGlobalVariableForJourneys(arr))
      }
   }

  useEffect(()=>{
      if(DistrictsList?.length>0&&regionsList?.length>0)
         getDataAfterFormated(props?.index) 
  },[DistrictsList,regionsList])

  const checkHasValueSelected=()=>{
    // return false
    var check=false;
    DistrictsList&&DistrictsList?.length>0&&DistrictsList?.map((districts)=>{
      if(districts?.check){
        check=true
        return check
      }
      districts?.children&&districts?.children?.length>0&&districts?.children?.map((region)=>{
          if(region?.check){
            check=true
            return check
          }
        regionsList&&regionsList?.length>0&&regionsList?.map((reg)=>{
           if(reg?.parent_id==region?.value){
            if(reg?.check){
              check=true;
              return check
            }
           }
        })
      })
    })
    return check;
  }
  const CopedJourneys=useSelector(state=>state.settingsData.CopedJourneys)
  const CopyJourneyForDay=()=>{
    let check= checkHasValueSelected()
    if(!check){
      console.log('enterherehere===>',1)
      // alert(t('JOURNEYS.MUST_SELECT_PLAN'))
      toast.warning(t('JOURNEYS.MUST_SELECT_PLAN'))
      // swal({
      //   title: `${t('SWEATALERT_MESSAGES.WARNNING_MESSAGE')}`,
      //   text: `${t('JOURNEYS.MUST_SELECT_PLAN')}`,
      //   icon: "warning",
      //   button:`${t('SWEATALERT_MESSAGES.WARRNING_BUTTON')}`
      // });
      return;
    }

    dispatch(setCopedJourneys([DistrictsList,regionsList]))
     
  }
  const PastJourneyForDay=()=>{
       if(CopedJourneys&&CopedJourneys[0]&&CopedJourneys[1]){
          setLoading(true)
          dispatch(setDistrictsList(CopedJourneys[0]))
          dispatch(setRegionsList(CopedJourneys[1]))
          setTimeout(()=>{
            setLoading(false)
          },100)
       }
  }
  
  const hasCopy=()=>{
    let check=false;
    if(CopedJourneys&&CopedJourneys[0]&&CopedJourneys[1]){
      check=true;
    }
    return check;
  }

  return (
    <Box
     sx={{
       backgroundColor:MainTheme?.paperColor,
      // overflowY:'scroll !important',
      width:'100%',
      position:'relative',
      '& .MuiTreeItem-root,& .MuiTreeItem-root ~ & .MuiTreeItem-root:nth-child(1)':{
        // backgroundColor:`${MainTheme?.paperColor+99} !important`,
        color:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,
        //  height:'50px'
        width:`100% !important`,
        margin:'0px',
        // overflow:'hidden !important',

       },
       '& .MuiTreeItem-content':{
        // backgroundColor:`${MainTheme?.paperColor}77 !important`,
        color:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,
        //  height:'50px'
        width:`60% !important`,
        margin:'5px',
        borderRadius:'10px',
        // boxShadow: "0px 0px 5px 0.01px rgba(0,0,0,0.75)",
        // overflow:'hidden !important',
       },
       '& .muiltr-1d2agok-MuiTreeItem-content.Mui-selected':{
         backgroundColor:'transparent !important',
       },
       '& .muiltr-1d2agok-MuiTreeItem-content:hover':{
        backgroundColor:'transparent !important',
      }
     }}
    >
      <TreeView
        aria-label="icon expansion"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        sx={{ 
          height:'500px !important',
          width:'100% !important',
          display: 'flex',
          justifyContent: 'start',
          // overflowX:'hidden !important',
          overflowY:'scroll !important',
          margin:0,
          padding:0,
          flexDirection: 'column',
        }}
      >
      <Box
         sx={{
          display:'flex',
          justifyContent: 'start',
          alignItems: 'center',
          width:'100% !important',
          margin:'10px 0'
         }}
       >
          <TextField id="outlined-basic" value={searchPoints} onChange={(e)=>{setSearchChange(e.target.value)}} label={t('JOURNEYS.JP_SEARCH_POINTS')} variant="standard" sx={{width:'61%'}} />
      </Box>
      {
        !loading?
        !searchPoints?
        <Box>
        {
          DistrictsList&&DistrictsList?.length>0?DistrictsList?.map((districts)=>(
            
            <CustomTreeItem nodeId={districts?.value} key={districts?.value}
              expanded={districts?.check}
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5,margin:'0 0 0 -10px' }}>
                  <Box  color="inherit"  >
                     <Checkbox {...label}  checked={districts?.check}  onChange={(e)=>handleClickCheckBox('districtsAll',districts?.value,e.target.checked,0)}/>
                  </Box>
                  <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1,display:'flex',justifyContent:'start' }}>
                    {districts?.label}
                  </Typography>
                
                </Box>
              }
            > 
              {
                districts?.children&&districts?.children?.length>0?districts?.children?.map((region)=>(
                  <CustomTreeItem nodeId={districts?.value+'.'+region?.value}
                   key={districts?.value+'.'+region?.value} 
                   expanded={region?.check}
                   label={
                    <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5,margin:'0 0 0 -10px' }} >
                      <Box  color="inherit"  >
                         <Checkbox {...label}  checked={region?.check} onChange={(e)=>handleClickCheckBox('regionAll',region?.value,e.target.checked,0)} />
                      </Box>
                      <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1,display:'flex',justifyContent:'start' }}>
                        {region?.label}
                      </Typography>
                    
                    </Box>
                  }>
                    {
                      regionsList&&regionsList?.length>0?regionsList?.map((reg)=>(
                            reg?.parent_id==region?.value?
                              <CustomTreeItem key={districts?.value+'.'+region?.value+'.'+reg?.value} 
                              nodeId={districts?.value+'.'+region?.value+'.'+reg?.value}
                              expanded={reg?.check}

                              label={
                                <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5,margin:'0 0 0 -10px' }}>
                                  <Box  color="inherit"  >
                                    <Checkbox {...label}  checked={reg?.check} onChange={(e)=>handleClickCheckBox('region',reg?.value,e.target.checked,reg?.parent_id)}/>
                                  </Box>
                                  <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1,display:'flex',justifyContent:'start' }}>
                                    {reg?.label}
                                  </Typography>
                                
                                </Box>
                              } />
                              :null
                          )):
                          <Box sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <CircularProgress sx={{color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor}}/>
                          </Box>
                  
                    }
                  </CustomTreeItem>
                )):
                <Box sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                  <CircularProgress sx={{color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor}}/>
                </Box>

              }

              </CustomTreeItem>
          )):
          <Box sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            <CircularProgress sx={{color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor}}/>
          </Box>
        }
        </Box>
        :
        <Box>
        {
          DistrictsList&&DistrictsList?.length>0?DistrictsList?.map((districts)=>(
            districts?.show?
            <CustomTreeItem nodeId={districts?.value} key={districts?.value}
            // label="Applications" 
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5,margin:'0 0 0 -10px' }}>
                  <Box  color="inherit"  >
                      <Checkbox {...label}  checked={districts?.check}  onChange={(e)=>handleClickCheckBox('districtsAll',districts?.value,e.target.checked,0)}/>
                  </Box>
                  <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1,display:'flex',justifyContent:'start' }}>
                    {districts?.label}
                  </Typography>
                
                </Box>
              }
            > 
              {
                districts?.children&&districts?.children?.length>0?districts?.children?.map((region)=>(
                  region?.show?
                  <CustomTreeItem nodeId={districts?.value+'.'+region?.value} key={districts?.value+'.'+region?.value}  label={
                    <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5,margin:'0 0 0 -10px' }} >
                      <Box  color="inherit"  >
                          <Checkbox {...label}  checked={region?.check} onChange={(e)=>handleClickCheckBox('regionAll',region?.value,e.target.checked,0)} />
                      </Box>
                      <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1,display:'flex',justifyContent:'start' }}>
                        {region?.label}
                      </Typography>
                    
                    </Box>
                  }>
                    {
                      regionsList&&regionsList?.length>0?regionsList?.map((reg)=>(
                        reg?.show?(
                            reg?.parent_id==region?.value?
                              <CustomTreeItem key={districts?.value+'.'+region?.value+'.'+reg?.value} nodeId={districts?.value+'.'+region?.value+'.'+reg?.value}  label={
                                <Box sx={{ display: 'flex', alignItems: 'center', p: 0.5,margin:'0 0 0 -10px' }}>
                                  <Box  color="inherit"  >
                                    <Checkbox {...label}  checked={reg?.check} onChange={(e)=>handleClickCheckBox('region',reg?.value,e.target.checked,reg?.parent_id)}/>
                                  </Box>
                                  <Typography variant="body2" sx={{ fontWeight: 'inherit', flexGrow: 1,display:'flex',justifyContent:'start' }}>
                                    {reg?.label}
                                  </Typography>
                                
                                </Box>
                              } />
                              :null
                              ):null
                          )):
                          <Box sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                            <CircularProgress sx={{color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor}}/>
                          </Box>
                  
                    }
                  </CustomTreeItem>:null
                  
                  
                )):
                <Box sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                  <CircularProgress sx={{color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor}}/>
                </Box>

              }

              </CustomTreeItem>:null
          )):
          <Box sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            <CircularProgress sx={{color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor}}/>
          </Box>
        }
        </Box>
        :
        <Box sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>
            <CircularProgress sx={{color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor}}/>
        </Box>
      }
        
      </TreeView>

      <Box sx={{position:'absolute',top:'30%',right:'10%',zIndex:2000}}>
        <Tooltip title={t('TOOLTIP.COPY')}>
          <Button 
            onClick={CopyJourneyForDay}
            // disabled={!checkHasValueSelected()?true:false}
          sx={{
            backgroundColor:MainTheme?.secondaryColor,margin:'0 5px',
            
            boxShadow:'0 0 7px -2px',
            '&:hover':{
              backgroundColor:MainTheme?.secondaryColor
            }
            }}
          >
            <ContentCopyIcon sx={{color:MainTheme?.oppositeSecondaryColor+' !important'}}/>
          </Button>
        </Tooltip>
        <Tooltip title={t('TOOLTIP.PAST')}>
          <Button 
            onClick={PastJourneyForDay}
            disabled={!hasCopy()?true:false}
            className={`${hasCopy()?'animationCopay':''}`}
          sx={{
            backgroundColor:MainTheme?.secondaryColor,margin:'0 5px',
            boxShadow:'0 0 7px -2px',
            '&:hover':{
              backgroundColor:MainTheme?.secondaryColor
            }
          }}>
            <CopyAllIcon sx={{color:MainTheme?.oppositeSecondaryColor+' !important'}}/>
          </Button>
        </Tooltip>
      </Box> 

    </Box>


  )
}









