import React from 'react'
import Scheduler, { Resource } from 'devextreme-react/scheduler';

// import { employees, data } from './data';
import DataCell from './DataCell';
import ResourceCell from './ResourceCell';
import helpers from '../../../assets/js/helper'
import {setSelectedCell} from '../../reduxStore/SettingsReducer'
import {useSelector,useDispatch} from 'react-redux'
export const employees = [{
    text: 'John Heart',
    id: 1,
    color: '#56ca85',
    avatar: 'images/gym/coach-man.png',
    age: 27,
    discipline: 'ABS, Fitball, StepFit',
  }, {
    text: 'Sandra Johnson',
    id: 2,
    color: '#ff9747',
    avatar: 'images/gym/coach-woman.png',
    age: 25,
    discipline: 'ABS, Fitball, StepFit',
  }];

  

const groups = ['employeeID'];
const views = ['month'];
export default function Calender(props) {
    const dispatch=useDispatch()
    const  [currentDate,setCurrentDate] = React.useState(new Date())
    const [data,setData] = React.useState([{
          text: '',
          employeeID: 2,
          planId:0,
          startDate: new Date(),
          endDate: new Date(),
        },
      ])
    // const GlobalVariableForJourneys =useSelector(state=>state.settingsData.GlobalVariableForJourneys)

    // const checkuserHasData=()=>{
    //     let check=false;
    //     GlobalVariableForJourneys&&GlobalVariableForJourneys?.length>0&&GlobalVariableForJourneys?.map((itm)=>{
    //           if(itm?.districts?.length>0){
    //             check=true;
    //           }
    //     })
    //     return check;
    //   }
    const formatUserDataAfterGeted=(data)=>{
        // {
        //   startDate: new Date(),
        //   endDate: addDays(new Date(), 7),
        //   key: 'selection'
        // },
          let arr=[];
          setCurrentDate(new Date(data[0]?.start_date))
          data&&data?.length>0&&data?.map((itm)=>{
            arr.push({
                text:'',
                employeeID: itm?.user?.id,
                planId:itm?.id,
                startDate: new Date(itm?.start_date),
                endDate: new Date(itm?.end_date),
            })
          })
          setData(arr)
  
    }

   

  const setNewDateSelectForMonth=(dateSelect)=>{
    var date = new Date(dateSelect);
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let start_date=helpers.formatDate(new Date(firstDay))
    let end_date=helpers.formatDate(new Date(lastDay))
    console.log('selected_start_date',start_date)
    console.log('selected_end_date',end_date)

    props.setFromDate_selector(start_date)
    props.setToDate_selector(end_date)

  }
  const CellCliked=(date)=>{

    console.log('many clicled')
    dispatch(setSelectedCell(helpers.formatDate(date?.cellData?.startDate)))
    
    props.clickedData(date)
  }
  const dateChanged=(date)=>{
    console.log('date changed===<>',date)
    setNewDateSelectForMonth(date)
    localStorage.setItem('currentMonth',date)
    
  }
   
  // var controllRed=React.useMemo(null)
  return (
    <Scheduler
    timeZone="Asia/Baghdad"
    dataSource={props?.dataForCalendar}
    dataCellComponent={DataCell}
    // resourceCellComponent={ResourceCell}
    // onAppointmentRendered={ResourceCell}
    // appointmentComponent={ResourceCell}
    // groups={groups}
   
    views={views}
    defaultCurrentView="month"
    defaultCurrentDate={props?.currentSelectedDate}
    height={410}
    width={560}
    css={{
      width:'100%',
    }}
    allowAdding={false}
    showAllDayPanel={true}
    firstDayOfWeek={1}
    startDayHour={8}
    endDayHour={18}
    onCellClick={CellCliked}
    onCurrentDateChange={dateChanged}
  
  >
    {/* <Resource
      label="Employee"
      fieldExpr="employeeID"
      dataSource={employees}
      allowMultiple={false}
    /> */}
  </Scheduler>
  )
}
