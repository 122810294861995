import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {useSelector,useDispatch} from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import LoadingPage from './LoadingPage'
import './Loader.css'
export default function DialogForm(props) {
    const directions=useSelector(state=> state.settingsData.directions);
    const MainTheme=useSelector(state=> state.settingsData.MainTheme);
    const catchedElement=React.useRef(null)
    try{
  
          let catchFooterOfGrid=document.querySelector('.MuiDataGrid-footerContainer');
          if(catchFooterOfGrid){
            catchFooterOfGrid.setAttribute('style',`direction:${directions} !important`)
            if(directions=='rtl')
            catchFooterOfGrid.classList.add('isRtlDirections')
          }
  
          let catchFooterOfGrid_isRTL=document.querySelector('.muirtl-139dwd1 .muirtl-1b34haf-MuiDataGrid-footerContainer');
          if(catchFooterOfGrid_isRTL){
            catchFooterOfGrid_isRTL.setAttribute('style',`direction:${directions} !important`)
            if(directions=='rtl')
            catchFooterOfGrid_isRTL.classList.add('isRtlDirections')
          }
          
    }catch(e){
      console.log(e?.message)
    }


  return (
    <Dialog
        open={props.open}
        onClose={() => props.close(false)}
        fullWidth={true}
        maxWidth={props?.userShift?'xl':'lg'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{
            color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
            '& .buttonSendForm button':{
                backgroundColor:MainTheme?.secondaryColor,
                color:MainTheme?.oppositeSecondaryColor,
                marginTop:'10px',
                marginBottom:'10px',
            },
            '& p , & span ,& .MuiInputLabel-root' :{
                fontSize:"15px !important"
            },
        
            '& .MuiOutlinedInput-root':{
                borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
                color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
            },
            '& .MuiInputLabel-root,svg':{
                // backgroundColor:`${MainTheme?.paperTextColor+'22'} !important`,
                color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor+' !important',
            },
            '& .muiltr-yf8vq0-MuiSelect-nativeInput,& .muirtl-1kr4871-MuiAutocomplete-root .MuiInput-root .MuiInput-input,& .muiltr-1kr4871-MuiAutocomplete-root .MuiInput-root .MuiInput-input,& .MuiFormControl-root,& .muirtl-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select':{
              // border:`1px solid ${MainTheme?.paperTextColor} !important`,
              color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor+' !important',
            },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor+' !important',
              },
              '&:hover fieldset': {
                borderColor: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor+' !important',
              },
              '&.Mui-focused fieldset': {
                borderColor: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.secondaryColor+' !important',
              },
            },
            '& .MuiOutlinedInput':{
              // borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
            },
            "& .MuiOutlinedInput-notchedOutline": {
                // border: "0 none",
             },
          
             '& .muirtl-1a1fmpi-MuiInputBase-root-MuiInput-root, & .muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root':{
                paddingLeft:"5px"
             },
             '& .muirtl-1a1fmpi-MuiInputBase-root-MuiInput-root:before,& .muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root:before':{
              borderColor:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,
              outlineColor:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,
            },
            '& .muiltr-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root:before,& .muirtl-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root:before,& .muiltr-n8ou7j-MuiInputBase-root-MuiInput-root-MuiSelect-root:before, & .muiltr-1mgqgpf-MuiInputBase-root-MuiInput-root-MuiSelect-root:before,& .muiltr-1a1fmpi-MuiInputBase-root-MuiInput-root:before,& .muirtl-n8ou7j-MuiInputBase-root-MuiInput-root-MuiSelect-root:before,& .muirtl-1mgqgpf-MuiInputBase-root-MuiInput-root-MuiSelect-root:before,& .muirtl-1a1fmpi-MuiInputBase-root-MuiInput-root:hover:before, &.muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root:hover:before':{
              borderColor:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,
              outlineColor:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,
            },
            '& .muirtl-1gq5zws-MuiDialogContent-root,& .muiltr-1gq5zws-MuiDialogContent-root':{
              overflowY:'hidden !important',
            },
            '& .muiltr-tbdx1t-MuiDivider-root, & .muirtl-tbdx1t-MuiDivider-root':{
              borderColor:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,
            },
            '& .muirtl-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.muirtl-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.muirtl-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input':{
              display: 'flex !important',
            },
            '& .muiltr-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.muiltr-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.muiltr-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input':{
              color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor+' !important',
            },
            '& .muiltr-1r3sywh-MuiButtonBase-root-MuiMenuItem-root,& li':{
              color:'#000000 !important',
            },
            '& .muirtl-1p56vt8, & .muiltr-1p56vt8,& .muiltr-bsm1zj-MuiDialogContent-root, & .muirtl-bsm1zj-MuiDialogContent-root,& .muirtl-oaose3,& .muiltr-oaose3':{
              overflow:'hidden !important',
            },
            "& .muirtl-1vv4lmi::before, & .muiltr-1vv4lmi::before,& .muirtl-1h9o68y::before,& .muiltr-1h9o68y::before,& .muirtl-1cayd89::before,& .muiltr-1cayd89::before,& .muirtl-27oy9e,& .muiltr-27oy9e,& .muirtl-ysslwc,& .muiltr-ysslwc,& hr,& .muirtl-1c6rg,& .muiltr-1c6rg,&.muirtl-51wiha::before,& .muiltr-51wiha::before,& .muirtl-1yf1g2m::before,& .muiltr-1yf1g2m::before,& .muirtl-1h9o68y::before,& .muiltr-1h9o68y::before":{
              borderColor:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,
              outlineColor:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,
            },
            '& .muirtl-1cccqvr.muirtl-1cccqvr.muirtl-1cccqvr,& .muiltr-1cccqvr.muiltr-1cccqvr.muiltr-1cccqvr':{
              color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor+' !important',
              display:'flex ',
              justifyContent:'start',
            },
            '& .muirtl-u2hlyw .muirtl-1b34haf-MuiDataGrid-footerContainer,& .muirtl-u2hlyw .muirtl-rtrcn9-MuiTablePagination-root:last-child,& .muirtl-u2hlyw .MuiTablePagination-actions':{
               direction:directions=='rtl'?'ltr':'ltr',
            }
          
        }}
        PaperProps={{
            style: {
                width:props?.CustomeWidth?props?.CustomeWidth:'100%',
                height: props.CustomeHeight?props.CustomeHeight:'100%',
                borderRadius: 0,
                backgroundColor: MainTheme?.paperColor,
                color: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                boxShadow: '#00000055 0px 0px 17px -4px',
                borderRadius: '10px',
                padding:'10px 20px',
                display:"flex",
                padding:'0 !important',
                // flexDirection:'column',
                // alignItems: 'start',
            },
        }}
        BackdropProps={{
            style: {
                background: 'rgba(255, 255, 255, 0.1)',
                backdropFilter: 'blur(3px)'
            }
        }}
        
        bgColor={MainTheme?.paperColor} color={MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} 
    
    >
        <Box
         sx={{
            position:'relative',
            width:'100%',height:'100%',
            overflowY:props?.loaderActive?'hidden':'scroll',
            width:'100%',
            margin:props?.loaderActive?0:'10px 0 3px 0 !important',
            // paddingLeft:props?.loaderActive?0:'20px',
            paddingBottom:props?.loaderActive?0:'15px',
            '& .muirtl-1sumxir-MuiFormLabel-root-MuiInputLabel-root':{
                color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
            },
            '& .muiltr-1r3sywh-MuiButtonBase-root-MuiMenuItem-root':{
              color:'#000000 !important',
            }
         }}
        >   {
                props?.loaderActive?
                <LoadingPage localLoading={true}/>:<>
                {
                props?.hasHeader?
                <Box
                    sx={{
                        position: "-webkit-sticky", /* Safari */
                        position: "sticky",
                         top:0,
                         left:'-23px',
                         right:0,
                         zIndex:1000,
                         width:'100%',
                         display: "flex",
                         justifyContent: "start",
                         flexDirection:'column',
                         padding:'0px 16px 15px 16px',
                        //  marginLeft:'-40px',
                        //  paddingLeft:'40px',
                        boxShadow:`0 1px 5px -3px ${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor}`,
                         backgroundColor: MainTheme?.paperColor,
                         color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                         '& svg':{
                            color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                             fill:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,

                         },
                    }}
                > 
                  
                  {props?.headerChildren}
                  {/* <div style={{height:1,width:'100%',backgroundColor:'#bcbcbc',position:'relative',margin:'15px'}}></div> */}
                </Box>:null
                }
                <Box sx={{
                  width:'100%',padding:'20px',height:'100%',color:MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme?.paperTextColor,
                    '& p,$ span,$ h1,& h2 ,& h3 ,& h4, & h5 , & h6,& .muirtl-5lbw0b-MuiTypography-root,& .muiltr-5lbw0b-MuiTypography-root':{
                      color:MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme?.paperTextColor+' !important'
                    },
                    '& .muiltr-1r3sywh-MuiButtonBase-root-MuiMenuItem-root':{
                      color:'#000000 !important',
                    }

                  }}>
                   {props?.children}
                </Box>
                </>
            }
      </Box>
    </Dialog>
  )
}
