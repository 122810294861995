import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { AiFillCloseCircle } from "react-icons/ai";
import DialogContent from "@mui/material/DialogContent";
import {
  Divider,
  Switch,
  CardMedia,
  Box,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  FormControlLabel,
  Button,
  Avatar,
} from "@mui/material";
import axios from "axios";
import { styled, useTheme } from "@mui/material/styles";

import Host from "../../../assets/js/Host";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import {
  ContainerOfForm,
  SaveButton,
  CancelButton,
  Form,
} from "./StyledComponents";
import FooterBottons from "../Share/FooterBottons";
import { flexbox } from "@mui/system";
import DialogForm from "../Share/DialogForm";
import helpers from "../../../assets/js/helper";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useTranslation } from "react-i18next";

const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar";

const CustomeSelect = styled(Select)(({ theme, open, MainTheme }) => ({
  //  backgroundColor:MainTheme?.paperTextColor+'88',
  color: MainTheme?.disabledGlobalText
    ? MainTheme?.gloablTextColor
    : MainTheme?.paperTextColor,
  "& div ul": {
    color: `${MainTheme?.paperTextColor}`,
    backgroundColor: `${MainTheme?.paperColor + "33"}`,
  },
}));

const CustomeImageRapper = styled(Box)(({ theme, MainTheme }) => ({
  width: "200px",
  height: "200px",
  borderRadius: "50%",
  position: "relative",
  cursor: "pointer",
  overflow: "hidden",
  backgroundColor: "grey",
  "& img": {
    backgroundSize: "cover",
    objectFit: "cover",
    backgroundPosition: "center center",
    width: "100%",
    height: "100%",
    borderRadius: "50%",
    pointerEvents: "none",
  },
  "& input": {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    visibility: "hidden",
  },
  "& .icone": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    pointerEvents: "none",
  },
}));

// const CustomeUserImg=styled()()

export const UserForm = (props) => {
  const [loading, setloading] = useState(true);
  const token = cookies.get("token");
  const [companies, setCompanies] = useState([]);
  const [departments, setDepartments] = useState([]);
  const directions = useSelector((state) => state.settingsData.directions);
  const MainTheme = useSelector((state) => state.settingsData.MainTheme);
  const { t, i18n } = useTranslation("common");
  const [userImage, setUserImage] = useState(null);
  const [demoUrlUserImage, setDemoUrlUserImage] = useState("");

  const [object, setobject] = useState({
    id: null,
    full_name: null,
    phone: null,
    username: null,
    password: null,
    is_demo: null,
    company: {
      id: null,
      name: null,
    },
    department: {
      id: null,
      name: null,
    },
    // image:'',
  });
  const getResources = async () => {
    try {
      setloading(true);
      const resourcesResult = await axios({
        url: Host + "departments",
        method: "get",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "X-localization": language,
        },
      });
      if (resourcesResult) {
        if (resourcesResult.status === 200) {
          // const resources = resourcesResult.data;
          const resources = resourcesResult?.data?.data;
          setDepartments(resources);
          if (props && props.user && props.user.id > 0) {
            setobject({ ...object, ...props.user });
            let imageuser = helpers.getImageUrl(props.user?.image?.image_url);
            setDemoUrlUserImage(imageuser);
          } else {
            console.log("hi");
            setobject({
              id: null,
              full_name: null,
              phone: null,
              username: null,
              password: null,
              is_demo: null,
              company: {
                id: null,
                name: null,
              },
              department: {
                id: null,
                name: null,
              },
            });
          }
        } else if (resourcesResult.data.status === false) {
        }
      } else {
        toast.error("Error");
      }
      const resourcesResult1 = await axios({
        url: Host + "companies_list",
        method: "get",
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "X-localization": language,
        },
      });
      if (resourcesResult1) {
        if (resourcesResult1.status === 200) {
          const resources = resourcesResult1?.data;
          setCompanies(resources);
          if (props && props.user && props.user.id > 0) {
            setobject({ ...object, ...props.user });
          } else {
            console.log("hi");
            setobject({
              id: null,
              full_name: null,
              phone: null,
              username: null,
              password: null,
              is_demo: null,
              company: {
                id: null,
                name: null,
              },
              department: {
                id: null,
                name: null,
              },
            });
          }
        } else if (resourcesResult1.data.status === false) {
        }
      } else {
        toast.error("Error");
      }
      setloading(false);
    } catch (error) {
        const responseResult = error.response
        if(responseResult && responseResult.status !== 401){
            toast.error("Network Error");
        }
      console.log("Network Error", error?.message);
      setloading(false);
     
    }
  };

  const submitForm = async () => {
    try {
      const formData = new FormData();
      if (object?.id) formData.append("id", object?.id);
      if (object?.full_name) formData.append("full_name", object?.full_name);
      if (object?.phone) formData.append("phone", object?.phone);
      if (object?.username) formData.append("username", object?.username);
      if (object?.password) formData.append("password", object?.password);
      // formData.append('is_demo',object?.is_demo)
      if (object?.company) formData.append("company", object?.company);
      if (object?.department) formData.append("department", object?.department);
      if (object?.department?.id)
        formData.append("department_id", object?.department?.id);
      if (object?.company?.id)
        formData.append("company_id", object?.company?.id);
      if (userImage) formData.append("image", userImage);

      if (object.id) {
        formData.append("_method", "put");
        const resourcesResult = await axios({
          url: Host + "users/" + object.id,
          method: "post",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "content-type": "multipart/form-data",
            "X-localization": language,
          },
          data: formData,
        });
        if (resourcesResult) {
          if (resourcesResult.status === 200) {
            toast.success("updated ");
            props.setShowUserForm(false, true);
          } else if (resourcesResult.data.status === false) {
            props.setShowUserForm(true, false);
          }
        } else {
          toast.error("Error");
          props.setShowUserForm(true, false);
        }
        setloading(false);
      } else {
        const resourcesResult = await axios({
          url: Host + "register",
          method: "post",
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "X-localization": language,
          },
          data: formData,
        });
        if (resourcesResult) {
          if (resourcesResult.status === 200) {
            toast.success("saved ");
            props.setShowUserForm(false, true);
          } else if (resourcesResult.status === 400) {
            toast.error(resourcesResult.data);
            props.setShowUserForm(true, false);
          }
        } else {
          toast.error("Error");
          props.setShowUserForm(true, false);
        }
        setloading(false);
      }
    } catch (error) {
      console.log("error", error);
      props.setShowUserForm(true, false);
      const resourcesResult = error.response;
      if (resourcesResult && resourcesResult.status === 400) {
        if (typeof resourcesResult.data.data === "object") {
          const errorMessagesKeys = Object.keys(resourcesResult.data.data);
          errorMessagesKeys.map((key) => {
            resourcesResult.data.data[key].map((message) => {
              toast.error(message);
            });
          });
        } else {
          toast.error(resourcesResult.data.data);
        }
      } else if (resourcesResult && resourcesResult.status === 401) {
        toast.error(resourcesResult.data.data);
        cookies.remove("token");
        window.location.href = "/";
      } else {
        toast.error("network error");
      }
    }
  };

  useEffect(() => {
    console.log(props);

    if (loading) getResources();

    return () => {};
  }, [loading, props.showUserForm]);

  const styles = {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
  };
  const check = helpers.checkDarknessOrLightnessForColor(
    MainTheme?.paperTextColor
  );

  const styleSelect = {
    // backgroundColor: !check ? 'rgb(255,255,255,0.7)' : 'rgb(0,0,0,0.4)',
    // color: MainTheme?.paperTextColor,
  };

  const styleSpecificSelect = {};

  const reformatDisplayPhone = (phone) => {
    let cuirrentphone = phone;

    if (phone && phone?.length >= 13) {
      // if(phone.slice(0,1)=='+'){
      cuirrentphone = phone.split("").reverse().join("");
      console.log("currentphone", cuirrentphone);
      // }else{
      //     return phone
      // }
    }
    return cuirrentphone;
  };

  const SetImageForUser = (file) => {
    let imgurl = window.URL.createObjectURL(file);
    setUserImage(file);
    setDemoUrlUserImage(imgurl);
  };

  return (
    <DialogForm
      open={props.showUserForm}
      close={props.setShowUserForm}
      CustomeWidth="60%"
      CustomeHeight="fit-content"
      loaderActive={loading}
      headerChildren={
        <>
          <IconButton
            aria-label={"Close"}
            onClick={() => {
              props.setShowUserForm(false);
            }}
            sx={{
              position: "absolute",
              top: "0px",
              left: "6px",
              fontSize: "37px",
            }}
          >
            <AiFillCloseCircle
              sx={{
                color: MainTheme?.paperTextColor,
                outline: "none !important",
                border: "none !important",
              }}
            />
          </IconButton>
          <Typography
            component={"h1"}
            variant={"h4"}
            style={{
              color: MainTheme?.disabledGlobalText
                ? MainTheme.gloablTextColor
                : MainTheme?.paperTextColor,
              width: "100%",
              textAlign: "center",
            }}
          >
            {props?.title
              ? props?.title
              : object?.id
              ? t("USERS.USER_EDIT_FORM")
              : t("USERS.USER_ADD_FORM")}
          </Typography>
        </>
      }
      hasHeader={true}
    >
      <DialogContent
        dividers={false}
        sx={{
          padding: 0,
          overflowX: "hidden",
          backgroundColor: "transparent",
          height: "100%",
          position: "sticky !important",
        }}
      >
        {
          <Box
            style={{
              padding: "10px",
              paddingTop: "20px",
              display: "flex",
              flexDirection: "column",
              height: "auto",
              justifyContent: "center",
              alignItems: "flex-start",
              color: MainTheme?.disabledGlobalText
                ? MainTheme.gloablTextColor
                : MainTheme?.paperTextColor,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  height: "auto",
                  width: "100%",
                  justifyContent: "space-evenly",
                  alignItems: "flex-start",
                  "& .muiltr-a4ms82-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root,& svg,& .muirtl-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                    {
                      color: MainTheme?.disabledGlobalText
                        ? MainTheme.gloablTextColor
                        : MainTheme?.paperTextColor,
                    },
                  "& input": {
                    color: MainTheme?.disabledGlobalText
                      ? MainTheme.gloablTextColor
                      : MainTheme?.paperTextColor,
                  },
                  "& .MuiOutlinedInput-root": {
                    borderColor: `1px solid ${MainTheme?.paperTextColor} !important`,
                    color: MainTheme?.disabledGlobalText
                      ? MainTheme.gloablTextColor
                      : MainTheme?.paperTextColor,
                  },
                  "& .MuiInputLabel-root": {
                    // backgroundColor:`${MainTheme?.paperTextColor+'22'} !important`,
                    color: MainTheme?.disabledGlobalText
                      ? MainTheme.gloablTextColor
                      : MainTheme?.paperTextColor,
                  },
                  "& .MuiFormControl-root": {
                    // border:`1px solid ${MainTheme?.paperTextColor} !important`,
                    color: MainTheme?.disabledGlobalText
                      ? MainTheme.gloablTextColor
                      : MainTheme?.paperTextColor,
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: MainTheme?.disabledGlobalText
                        ? MainTheme.gloablTextColor
                        : MainTheme?.paperTextColor,
                    },
                    "&:hover fieldset": {
                      borderColor: MainTheme?.disabledGlobalText
                        ? MainTheme.gloablTextColor
                        : MainTheme?.paperTextColor,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: MainTheme?.secondaryColor,
                      color: MainTheme?.disabledGlobalText
                        ? MainTheme.gloablTextColor
                        : MainTheme?.secondaryColor,
                    },
                  },
                  "& .MuiOutlinedInput": {
                    // borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    // border: "0 none",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flex: 1,
                    margin: "0 10px",
                  }}
                >
                  <TextField
                    id="outlined-basic-full_name"
                    label={t("USERS.FORM_FULLNAME")}
                    variant="standard"
                    placeholder={t("USERS.FORM_FULLNAME")}
                    sx={{ ...styleSelect }}
                    value={object?.full_name}
                    fullWidth
                    onChange={(event) => {
                      setobject({ ...object, full_name: event.target.value });
                    }}
                  />
                  <TextField
                    id="outlined-basic-phone"
                    label={t("USERS.FORM_PHONE")}
                    variant="standard"
                    placeholder={"+9647812346794"}
                    sx={{
                      ...styleSelect,
                      marginTop: "10px",
                      directions: "ltr",
                      unicodeBidi: "embed",
                      textAlign: "right !important",
                      "& input": {
                        textAlign: "left !important",
                      },
                    }}
                    value={object?.phone}
                    inputProps={{
                      dir: "ltr",
                      textAlign: "right",
                    }}
                    fullWidth
                    onChange={(event) => {
                      setobject({ ...object, phone: event.target.value });
                    }}
                  />
                  <FormControl
                    variant="standard"
                    fullWidth
                    sx={{ ...styleSelect, marginTop: "10px" }}
                  >
                    <InputLabel
                      id="department-select-label"
                      sx={{ color: MainTheme?.paperColor }}
                    >
                      {t("USERS.FORM_DEPARTMENT")}
                    </InputLabel>
                    <CustomeSelect
                      MainTheme={MainTheme}
                      labelId="department-select-label"
                      id="department-select"
                      sx={{ textAlign: "left" }}
                      value={object?.department}
                      label={t("USERS.FORM_DEPARTMENT")}
                      renderValue={(value) => {
                        if (value?.id != "" && value?.id != null) {
                          return `${value?.name}`;
                        } else {
                          return (
                            <Typography
                              sx={{
                                color: MainTheme?.disabledGlobalText
                                  ? MainTheme.gloablTextColor
                                  : MainTheme?.paperTextColor,
                              }}
                            >
                              {t("USERS.FORM_SELECT_DEPARTMENT")}
                            </Typography>
                          );
                        }
                      }}
                      onChange={(event) => {
                        setobject({
                          ...object,
                          department: {
                            ...event?.target?.value,
                          },
                        });
                      }}
                    >
                      {departments?.length > 0 &&
                        departments?.map((department) => {
                          return (
                            <MenuItem key={department?.id} value={department}>
                              {department?.name}
                            </MenuItem>
                          );
                        })}
                    </CustomeSelect>
                  </FormControl>

                  {/* <FormControlLabel control={
                                        <Switch
                                            checked={object?.is_demo}
                                            onChange={(event) => {
                                                const target = event.target;

                                                if (target.checked) {
                                                    setobject({ ...object, is_demo: 1 });
                                                }
                                                else {
                                                    setobject({ ...object, is_demo: 0 });
                                                }
                                            }}
                                            inputProps={{ 'aria-label': 'Is Demo' }}

                                        />
                                    } label={<Typography sx={{ color: MainTheme?.paperTextColor }}>Is Demo</Typography>} /> */}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    marginLeft: "10px",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    flex: 1,
                    margin: "0 10px",
                  }}
                >
                  <TextField
                    id="outlined-basic-username"
                    label={t("USERS.FORM_USERNAME")}
                    variant="standard"
                    placeholder={t("USERS.FORM_USERNAME")}
                    sx={{ ...styleSelect }}
                    value={object?.username}
                    fullWidth
                    onChange={(event) => {
                      setobject({ ...object, username: event.target.value });
                    }}
                  />
                  <TextField
                    id="outlined-basic-password"
                    label={t("USERS.FORM_PASSWORD")}
                    variant="standard"
                    placeholder={t("USERS.FORM_PASSWORD")}
                    sx={{ ...styleSelect, marginTop: "10px" }}
                    value={object?.password}
                    type="password"
                    fullWidth
                    onChange={(event) => {
                      setobject({ ...object, password: event.target.value });
                    }}
                  />
                  <FormControl
                    variant="standard"
                    fullWidth
                    sx={{ ...styleSelect, marginTop: "10px" }}
                  >
                    <InputLabel
                      id="company-select-label"
                      sx={{ color: MainTheme?.paperColor }}
                    >
                      {t("USERS.FORM_COMPANY")}
                    </InputLabel>
                    <Select
                      labelId="company-select-label"
                      id="company-select"
                      sx={{ textAlign: "left" }}
                      value={object?.company}
                      label={t("USERS.FORM_COMPANY")}
                      renderValue={(value) => {
                        if (value?.id != "" && value?.id != null) {
                          return `${value?.company_name}`;
                        } else {
                          return (
                            <Typography
                              sx={{
                                color: MainTheme?.disabledGlobalText
                                  ? MainTheme.gloablTextColor
                                  : MainTheme?.paperTextColor,
                              }}
                            >
                              {t("USERS.FORM_SELECT_COMPANY")}
                            </Typography>
                          );
                        }
                      }}
                      onChange={(event) => {
                        setobject({
                          ...object,
                          company: {
                            ...event?.target?.value,
                          },
                        });
                      }}
                    >
                      {companies?.length > 0 &&
                        companies?.map((company) => {
                          return (
                            <MenuItem key={company?.id} value={company}>
                              {company?.company_name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CustomeImageRapper
                  MainTheme={MainTheme}
                  onClick={(e) => {
                    e.target.querySelector("input").click();
                  }}
                >
                  <img src={demoUrlUserImage} />
                  <input
                    type="file"
                    accept="image/x-png,image/gif,image/jpeg"
                    onChange={(e) => SetImageForUser(e.target.files[0])}
                  />
                  <CameraAltIcon className="icone" />
                </CustomeImageRapper>
              </Box>
            </Box>
            <Divider sx={{ marginTop: "60px", width: "100%" }} />
            <Box
              className="buttonSendForm"
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                height: "auto",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                sx={{
                  color: MainTheme?.disabledGlobalText
                    ? MainTheme.gloablTextColor
                    : MainTheme?.paperTextColor,
                }}
                onClick={() => {
                  submitForm();
                }}
              >
                {t("USERS.FORM_SUBMIT")}
              </Button>
            </Box>
          </Box>
        }
      </DialogContent>
    </DialogForm>
  );
};
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "red" : "blue",
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: "100%",
    border: "1px solid #ababab",
    borderRadius: 5,
    padding: 0,
    display: "flex",
  }),
  container: () => ({
    width: "80%",
    position: "relative",
    boxSizing: "border-box",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

export default UserForm;
