import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useGlobalContext } from '../../../context'
import { Box, Typography } from '@mui/material';
import {useTranslation} from "react-i18next";
import {useSelector,useDispatch} from 'react-redux'

export default function BasicDatePicker({Language,customeWidth,customeMargin,customeMarginTop,customePaddingLeft,marginMorLitileBit}) {
  const { fromToDate, setFromToDate } = useGlobalContext()
  const [value, setValue] = React.useState(null);
  const [t, i18n] = useTranslation('common');
  const GridTheme=useSelector(state=>state.settingsData.GridTheme)
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: customePaddingLeft?customePaddingLeft:'13px',
      // marginTop:customeMargin?customeMargin:0,
      mt:customeMarginTop?customeMarginTop:1.2,
      // ml:1,
      // marginTop:3,
      // paddingRight: '5px',
      width:customeWidth
    }}>
      <LocalizationProvider dateAdapter={AdapterDateFns} sx={{width:'48%'}}>
        <DatePicker
          label={t('GLOBAL.SEARCH_FROM_DATE')}
          value={fromToDate[0]}
          inputFormat="yyyy/MM/dd"
          sx={{width:"100%"}}
          onChange={(newValue) => {
            // setValue(newValue);

            setFromToDate([newValue, fromToDate[1]])
          }}
          renderInput={(params) => <TextField {...params}  variant="standard" sx={{width:"100%",borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}}/>}
        />
      </LocalizationProvider>
      <Typography sx={{
        color: '#000000',
        width:"28%",
        display:'flex',
        justifyContent: 'center', alignItems: 'center',
      }}>
        {t('GLOBAL.SEARCH_TO_WORD')}
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDateFns} sx={{width:'48%'}}>
        <DatePicker
          label={t('GLOBAL.SEARCH_TO_DATE')}
          value={fromToDate[1]}
          inputFormat="yyyy/MM/dd"
          sx={{width:"100%"}}
          onChange={(newValue) => {
            // setValue(newValue);
            setFromToDate([fromToDate[0], newValue])
          }}
          renderInput={(params) => <TextField {...params}  variant="standard" sx={{width:"100%",borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}}/>}
        />
      </LocalizationProvider>
    </Box>

  );
}
