
import React, {useEffect,useState} from 'react';
// import Pagination from "react-js-pagination";
import Cookies from "universal-cookie";
import axios from "axios";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import UserDailyPlanForm from './UserDailyPlanForm';
import IconButton from "@mui/material/IconButton";
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import Tooltip from '@mui/material/Tooltip';
import CircularIndeterminate from  '../GeneralComponents/CirularProgress'
import StyledDataGrid from '../GeneralComponents/StyledDataGrid'
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import red from '@mui/material/colors/red';
import Typography from '@mui/material/Typography';
import Grid from '../Share/Grid';
import {useSelector,useDispatch} from 'react-redux'
import { useGlobalContext } from '../../../context'
import {useTranslation} from "react-i18next";
import DropDownGrid from '../Share/DropDownGrid';
import {MenuItem,ListItemIcon} from '@mui/material'
import UserDailyPlanNewForm from './UserDailyPlanNewForm'
// const cookies = new Cookies();
// const token = cookies.get("token")
// const language = cookies.get("language")?cookies.get("language"):"ar"
const urlParams = new URLSearchParams(window.location.search);
const userId = urlParams.get('id');

function UserDailyPlans() {
    const [selectedPoint, setselectedPoint] = useState(null);
    const [pageSize, setPageSize] = useState(5);    
    const [pageNumber, setpageNumber] = useState(1);
    const [pointsTotal, setPointsTotal] = useState(0)
    const [pointsNumberOfPages, setPointsNumberOfPages] = useState(0)
    const directions =useSelector(state=>state.settingsData.directions)
    const MainTheme =useSelector(state=>state.settingsData.MainTheme)
    const GridTheme =useSelector(state=>state.settingsData.GridTheme)
    const [t, i18n] = useTranslation('common');


    const {loading,userDailyPlans,user,setUser,setUserDailyPlans,pointsStatuses,pointStatus,setPointStatus,exportToCSV,allPoints,points,district,region,districts,regions,ownerName,shopName,clearPointsAdvanceSearchFields,setRegion,setDistrict,setShopName,setOwnerName,fetchData,deleteObject} = useGlobalContext()
    // const [user,setuser] = useState(null);
    const [rows,setRow] = useState([])  
    const [userDailyPlan,setUserDailyPlan] = useState({
        plan_date:new Date(),
        user_id:userId,
        points_ids:[],
    });
    const [oldUserDailyPlan,setOldUserDailyPlan] = useState({
        plan_date:new Date(),
        user_id:userId,
        points_ids:[],
    });
    // const [loading, setloading] = useState(false);
    const [state, setData] = useState({
        userDailyPlans: ''
    });
    const [showUserDailyPlanForm, setshowUserDailyPlanForm] = useState(false);
    const [planDate,setplanDate] = useState(null);
    const [pointId,setpointId] = useState(null);
    const [itemPerPage,setitemPerPage] = useState(null);
    const [newForm,setNewForm]=useState(false)
    const advanceSearch = ()=>{     
        // console.log(pointStatus)
        fetchData('USER_DAILY_PLAN','user_daily_plans',{
            page:pageNumber,
            page_size:pageSize,
            user_id:userId,
            plan_date:planDate,
            point_id:pointId,
        });                        
    }

    // const fetchData = async (pageNumber = 1) => {
    //     setloading(true)
    //     try {
            
    //         var data={
    //             page:pageNumber,
    //         }
    //         if(userId)
    //         {
    //             data={
    //                 ...data,
    //                 user_id:userId,
    //             }
    //         }
    //         if(itemPerPage)
    //         {
    //             data={
    //                 ...data,
    //                 item_per_page:itemPerPage,
    //             }
    //         }
    //         if(planDate)
    //         {
    //             data={
    //                 ...data,
    //                 plan_date:planDate,
    //             }
    //         }
    //         if(pointId)
    //         {
    //             data={
    //                 ...data,
    //                 point_id:pointId,
    //             }
    //         }
    //         const result=await axios({
    //             url: Host + "user_daily_plans",
    //             method: "get",
    //             headers: {
    //             "Authorization" : `Bearer ${token}`,
    //                 Accept:"application/json",
    //                 'X-localization':language,
    //             },
    //             params:data
    //         })
    //         if(result && result.status == 200)
    //         {
    //             const userDailyPlans=result.data;

    //             const tempData={
    //                 ...userDailyPlans,
    //                 data:userDailyPlans?.data.map((data)=>{
    //                     const pointIdsString=data.points_ids.split(',');

    //                     return {
    //                         ...data,
    //                         user_id:userId,
    //                         points_ids:pointIdsString
    //                     }
    //                 })
    //             }
                
                
    //             setData({
    //                 userDailyPlans:tempData 
    //             });
    //         } else if (result.data.status === false) {
    //             toast.error("Error")
    //         }
    //     } catch (error) {
    //         const responseResult=error.response            
    //         if (responseResult && responseResult.status === 400) {
    //             if(typeof responseResult.data.data === "object")
    //             {
    //                 const errorMessagesKeys = Object.keys(responseResult.data.data);
    //                 errorMessagesKeys.map((key)=>{
                    
    //                     responseResult.data.data[key].map((message)=>{
    //                         toast.error(message)
    //                     })
    //                 })
    //             }
    //             else{
    //                 toast.error(responseResult.data.data)
    //             }
                
    //         }
    //         else if(responseResult && responseResult.status === 401) {
    //             toast.error(responseResult.data.data)
    //             cookies.remove("token");
    //             window.location.href = "/"
    //         }
    //         else
    //         {
    //             toast.error("network error")
    //         }
    //     }
    //     setloading(false)
                   
    // };

    // const fetchUserData = async () => {
    //     setloading(true)
    //     try {
            

    //         var data={
    //             id:userId,
    //         }
            
    //         const result=await axios({
    //             url: Host + "user",
    //             method: "get",
    //             headers: {
    //             "Authorization" : `Bearer ${token}`,
    //                 Accept:"application/json",
    //                 'X-localization':language,
    //             },
    //             params:data
    //         })
    //         if(result && result.status == 200)
    //         {
    //             const user=result.data ;
                
    //             setuser(user);
    //         } else if (result.data.status === false) {
    //             toast.error("Error")
    //         }
    //     } catch (error) {
    //         const responseResult=error.response            
    //         if (responseResult && responseResult.status === 400) {
    //             if(typeof responseResult.data.data === "object")
    //             {
    //                 const errorMessagesKeys = Object.keys(responseResult.data.data);
    //                 errorMessagesKeys.map((key)=>{
                    
    //                     responseResult.data.data[key].map((message)=>{
    //                         toast.error(message)
    //                     })
    //                 })
    //             }
    //             else{
    //                 toast.error(responseResult.data.data)
    //             }
                
    //         }
    //         else if(responseResult && responseResult.status === 401) {
    //             toast.error(responseResult.data.data)
    //             cookies.remove("token");
    //             window.location.href = "/"
    //         }
    //         else
    //         {
    //             toast.error("network error")
    //         }
    //     }
    //     setloading(false)
                   
    // };
    const updateObject = async (object) => {
      
        setUserDailyPlan({
            ...userDailyPlan,
            update:true,
            ...object
        })
        setOldUserDailyPlan({
            update:true,
            ...object
        })
       
        setshowUserDailyPlanForm(true)
    };
    // const deleteObject = async (object) => {
    //     if (!window.confirm("Delete the item?")) {
    //         return
    //     }
    //     setloading(true)
    //     try {
    //         const result=await axios({
    //             url: Host + "user_daily_plans/"+object.id,
    //             method: "delete",
    //             headers: {
    //             "Authorization" : `Bearer ${token}`,
    //                 Accept:"application/json",
    //                 'X-localization':language,
    //             }
        
    //         })
    //         if(result && result.status == 200)
    //         {
    //             toast.error(result.data.data)
    //             fetchData();
    //         } else if (result.data.status === false) {
    //             toast.error("Error")
    //         }
    //     } catch (error) {
    //         const responseResult=error.response            
    //         if (responseResult && responseResult.status === 400) {
    //             if(typeof responseResult.data.data === "object")
    //             {
    //                 const errorMessagesKeys = Object.keys(responseResult.data.data);
    //                 errorMessagesKeys.map((key)=>{
                    
    //                     responseResult.data.data[key].map((message)=>{
    //                         toast.error(message)
    //                     })
    //                 })
    //             }
    //             else{
    //                 toast.error(responseResult.data.data)
    //             }
                
    //         }
    //         else if(responseResult && responseResult.status === 401) {
    //             toast.error(responseResult.data.data)
    //             cookies.remove("token");
    //             window.location.href = "/"
    //         }
    //         else
    //         {
    //             toast.error("network error")
    //         }
    //     }
    //     setloading(false)
    // };
    
    const createObject = async (object) => {
        setUserDailyPlan({
            ...userDailyPlan,
            update:false,
            user_id:userId,
            ...object
        })
        setshowUserDailyPlanForm(true)
    };

    const handleSetshowUserDailyPlanForm = (openStatus,needsReload) => {
      if(needsReload)
      advanceSearch()
    //   fetchData();
      setshowUserDailyPlanForm(openStatus)
    };

    const handlePageChange = (newPage) => {        
        setpageNumber(newPage+1)  
      }
    
    useEffect( () => {        
        fetchData('USER','user',{id:userId})                        
        advanceSearch()
    
    }, [pageSize,pageNumber])

    //Reformatting points object once points are loaded
    useEffect(() => {
        
        if (userDailyPlans && userDailyPlans.data && userDailyPlans.data.length > 0) {            
            const   reformatPoints  = {...userDailyPlans,
            data:userDailyPlans?.data.map((data,key)=>{

                const pointIdsString=data.points_ids.split(',');                

                return {
                    ...data,
                    user_id:userId,
                    id:key,
                    points_ids:pointIdsString
                }
            })
            } 
            console.log('reformatPoints')                                                  
            console.log(reformatPoints)

            setRow(reformatPoints.data);                
            setPointsTotal(userDailyPlans.total)    
            setPointsNumberOfPages(userDailyPlans.last_page)                     
        }
    }, [userDailyPlans])

    // useEffect(() => {
    //     fetchUserData();
    //     fetchData();        
    // }, [])
    const columns = [
        { field: 'id', headerName: t('JOURNEYS.USERDAILYPLAN_ID') , flex: .1},
        { field: 'plan_date', headerName: t('JOURNEYS.USERDAILYPLAN_PLAN_DATE')  , flex: .5},
        { field: 'total_points', headerName: t('JOURNEYS.USERDAILYPLAN_TOTAL')  , flex: 3},
        
        { field: 'actions',flex: 0.1,width:'50px',
            headerName:t('JOURNEYS.USERDAILYPLAN_ACTIONS') ,
            renderCell: (rows) => (
                <DropDownGrid>
             
                <MenuItem onClick={() => { updateObject(rows.row)}}>
                    <ListItemIcon sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin:'0px 7px 0 0'
                    }}>
                        <ModeEditOutlineRoundedIcon style={{fill: "#deb12c"}} />
                    </ListItemIcon>
                    {directions != 'rtl' ? "Edit" : 'تعديل'}
                </MenuItem>
                <MenuItem onClick={() => { deleteObject('USER_DAILY_PLAN','user_daily_plans',rows.row)}}>
                    <ListItemIcon sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin:'0px 7px 0 0'
                    }}>
                         <DeleteRoundedIcon sx={{ color: red[500] }} />
                    </ListItemIcon>
                    {directions != 'rtl' ? "Delete" : 'حذف'}
                </MenuItem>
              </DropDownGrid>
            ),
        },        
      ];
    
    return (
        <>
            {showUserDailyPlanForm?
                <UserDailyPlanForm user={user} oldObject={oldUserDailyPlan} object={userDailyPlan} setobject={setUserDailyPlan}  showUserDailyPlanForm={showUserDailyPlanForm} setshowUserDailyPlanForm={handleSetshowUserDailyPlanForm} />
            :null}
              
           <Button onClick={()=>{setNewForm(true)}}>Open New Form</Button>
           <UserDailyPlanNewForm open={newForm} setOpen={setNewForm}  user={user} oldObject={oldUserDailyPlan} object={userDailyPlan} setobject={setUserDailyPlan}  showUserDailyPlanForm={showUserDailyPlanForm} setshowUserDailyPlanForm={handleSetshowUserDailyPlanForm}/>
           


        

                <Grid
              rows={rows}
              columns={columns}
              rowTotals={points?.total ? points?.total : 0}
              pageSize={pageSize}
              setPageSize={setPageSize}
              pageNumber={pageNumber}
              setPageNumber={setpageNumber}
              loading={loading}
              handlePageChange={handlePageChange}
              NumberOfPage={pointsNumberOfPages}
              directions={directions}
              hasCreate={true}
              createObject={createObject}
              title={t('JOURNEYS.USERDAILYPLAN_TITLE')+' - '+user?.full_name}
            //   title_ar={'قائمة خطط المستخدم اليومية - '+user?.full_name}
              gridHeight={{
                height:rows?.length>5?pageSize==5?400:pageSize==10?650:950:400,
              }}
            /> 

         
            {/* <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">User Daily Plans List - {user?.full_name} <Button style={{float:'right'}} onClick={()=>{createObject()}}>create</Button></div>
                            <div className="card-body">
                            <table>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Date</th>
                                        <th>Total Points</th>
                                        <th>edit</th>
                                        <th>delete</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {   
                                    state?.userDailyPlans?.data ? 
                                        state?.userDailyPlans?.data?.map((object,index) => (
                                                <tr key={index +1}>
                                                    <td>{index+1}</td>
                                                    <td>{object?.plan_date}</td>
                                                    <td>{object?.total_points}</td>
                                                    <td><Button onClick={()=>{
                                                      
                                                        updateObject(object)}}>update</Button></td>
                                                    <td><Button style={{backgroundColor:'red',color:'white'}} 
                                                    // onClick={()=>{deleteObject(object)}}>
                                                        onClick={()=>{deleteObject('USER_DAILY_PLAN','user_daily_plans',rows.row)}}>
                                                            delete
                                                        </Button></td>
                                                    
                                                </tr>
                                        )) : "Loading..."
                                }
                                </tbody>
                            </table>
                                <div>
                                    <Pagination
                                        activePage={state?.userDailyPlans?.current_page ? state?.userDailyPlans?.current_page : 0}
                                        itemsCountPerPage={state?.userDailyPlans?.per_page ? state?.userDailyPlans?.per_page : 0 }
                                        totalItemsCount={state?.userDailyPlans?.total ? state?.userDailyPlans?.total : 0}
                                        onChange={(pageNumber) => {
                                            fetchData(pageNumber)
                                        }}
                                        pageRangeDisplayed={8}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        firstPageText="First Page"
                                        lastPageText="Last Lage"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default UserDailyPlans;


