import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { AiFillCloseCircle } from 'react-icons/ai'
import DialogContent from '@mui/material/DialogContent';
import { Divider, Switch, CardMedia, Box, DialogTitle, IconButton, TextField, Typography, Select, InputLabel, MenuItem, FormControl, FormControlLabel, Button, Avatar } from '@mui/material';
import axios from "axios";
import { styled,alpha, useTheme } from '@mui/material/styles';

import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux'
import {
    ContainerOfForm,
    SaveButton,
    CancelButton,
    Form
} from '../Users/StyledComponents';
import FooterBottons from "../Share/FooterBottons";
import { flexbox } from "@mui/system";
import DialogForm from "../Share/DialogForm";
import helpers from "../../../assets/js/helper";
import Autocomplete from '@mui/material/Autocomplete';

import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
// import Switch from '@mui/material/Switch';
import debounce from 'lodash.debounce'

import { useGlobalContext } from '../../../context'

import {useTranslation} from "react-i18next";

const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"
const label = { inputProps: { 'aria-label': 'Switch demo' } };
const CustomeSelect = styled(Select)(({ theme, open,MainTheme, }) => ({
    //  backgroundColor:MainTheme?.paperTextColor+'88',
     color:MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme?.paperTextColor,
     '& div ul':{
        color:`${MainTheme?.paperTextColor}`,
        backgroundColor:`${MainTheme?.paperColor+'33'}`,
      }
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CustomeColorSelect=styled('div')(({theme,color,bgColor})=>({
    backgroundColor:bgColor,
    color:color,
    width:'100px',
    height:'100px',
    borderRadius:'50%',
    cursor: 'pointer',
    position: 'relative',
  }))

export default function GroupForm(props) {

    const { loading,users,groups,notification_types, pointsStatuses, pointStatus, setPointStatus, exportToCSV, points, district, region, districts, regions, ownerName, shopName, clearPointsAdvanceSearchFields, setRegion, setDistrict, setShopName, setOwnerName, fetchData, deleteObject } = useGlobalContext()

    const [loading2, setloading] = useState(false);
    const token = cookies.get("token")
    const [companies, setCompanies] = useState([])
    const [departments, setDepartments] = useState([]);
    const directions =useSelector(state=> state.settingsData.directions);
    const MainTheme =useSelector(state=> state.settingsData.MainTheme);
    const {t, i18n} = useTranslation('common');
    const [selectedUsers,setSelectedusers]=useState([])
    const [selectedGroups,setSelectedGroups]=useState([])

    useEffect(()=>{
        fetchData('USERS', 'users_list')
        fetchData('GROUPS', 'groups_list')
        fetchData('NOTIFICATION_TYPES', 'notification_types_list')
    },[])
    


    const [object, setobject] = useState({
        id:'',
        name:'',
        color:'#000000',
    });


    const submitForm = async () => {

        try {

            if (object?.id) {
                const resourcesResult = await axios({
                    url: Host + "groups/"+object.id,
                    method: "post",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data: {
                        ...object,
                        _method:'put',
                    },
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("group updated sccess")
                        props?.fetchData()
                        props.setShowGroup(false, true)
                    } else if (resourcesResult.data.status === false) {
                        props.setShowGroup(true, false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setShowGroup(true, false)
                }
                setloading(false)
            }
            else {
                const resourcesResult = await axios({
                    url: Host + "groups",
                    method: "post",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data: object,
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("group created succcess")
                        props.setShowGroup(false, true)
                        props?.fetchData()
                    } else if (resourcesResult.data.status === false) {
                        props.setShowGroup(true, false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setShowGroup(true, false)
                }
                setloading(false)
            }
        } catch (error) {
            console.log("error",error);
            props.setShowGroup(true, false)
            const resourcesResult = error.response
            if (resourcesResult && resourcesResult.status === 400) {
                if (typeof resourcesResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key) => {

                        resourcesResult.data.data[key].map((message) => {
                            toast.error(message)
                        })
                    })
                }
                else {

                    toast.error(resourcesResult.data.data)
                }


            }
            else if (resourcesResult && resourcesResult.status === 401) {

                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else {

                toast.error("network error")
            }
        }

    };


    useEffect(() => {
       if(props&&props?.Group&&props?.Group?.id>0){
    
        setobject({
           ...object,
            id:props?.Group?.id,
            name:props?.Group?.name,
            color:props?.Group?.color,
        })

       }
    }, [loading2,props?.Group]);


    const styles = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
    }
    // const check = helpers.checkDarknessOrLightnessForColor(object?.color)

    const styleSelect = {
        // backgroundColor: !check ? 'rgb(255,255,255,0.7)' : 'rgb(0,0,0,0.4)',
        // color: MainTheme?.paperTextColor,
    }
    const colorForBuble=
    {
        color: !helpers.checkDarknessOrLightnessForColor(object?.color)?'#000':'#fff',
    }
    
    const styleSpecificSelect={
       
    }
    const TimerControl=React.useRef(null)
    const changeColor=debounce((e)=>{
        // e.persist()
        if(TimerControl?.current){
            clearTimeout(TimerControl?.current)
        }
        // TimerControl.current=setTimeout(()=>{
            console.log(e)
            setobject({
                ...object,
                color:e,
            })
        // },80)
       
    },80)

  return (
      <DialogForm
        open={props?.showGroup}
        close={props?.setShowGroup}
        CustomeWidth='60%'
        CustomeHeight="fit-content"
        loaderActive={loading}
        headerChildren={(
            <>
                <IconButton aria-label={"Close"} onClick={() => { props.setShowGroup(false) }} style={{ position: 'absolute', top: '-10px', left: '6px', fontSize: '37px', }}>
                    <AiFillCloseCircle style={{ color: MainTheme?.paperTextColor,outline:'none !important' ,border:'none !important'}} />
                </IconButton>
                <Typography component={'h1'} variant={'h4'} style={{ margin:'10px 0',color: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor, width: '100%', textAlign: 'center' }}>
                    {props?.title ? props?.title : (object?.id ? t('GROUPS.FORM_EDIT_TITLE'): t('GROUPS.FORM_ADD_TITLE'))}
                </Typography>
            </>
          )}
          hasHeader={true}
 
    >


        <DialogContent dividers={false} 
            sx={{
                padding: 0,
                overflowX: 'hidden',
                backgroundColor: 'transparent',
                height: '100%',


            }}
        >

            {
                <Box 
                    sx={{
                        padding: '10px',
                        paddingTop: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'auto',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        color: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor

                    }}
                >
                    <Box 
                        sx={{

                            display: 'flex',
                            flexDirection: 'row',
                            height: 'auto',
                            width: '100%',
                            justifyContent: 'space-evenly',
                            alignItems: 'flex-start',
                            '& .muiltr-a4ms82-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root,& svg,& .muirtl-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':{
                                color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                            },
                            '& input':{
                                color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                            
                                },
                                '& .MuiOutlinedInput-root':{
                                    borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
                                    color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                                },
                                '& .MuiInputLabel-root':{
                                    // backgroundColor:`${MainTheme?.paperTextColor+'22'} !important`,
                                    color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                                },
                                '& .MuiFormControl-root':{
                                // border:`1px solid ${MainTheme?.paperTextColor} !important`,
                                color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                                },
                                '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                                },
                                '&:hover fieldset': {
                                    borderColor:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: MainTheme?.secondaryColor,
                                    color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.secondaryColor,

                                },
                                },
                                '& .MuiOutlinedInput':{
                                // borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    // border: "0 none",
                                },

                        }}
                    >
                        <Box 
                            sx={{

                                display: 'flex',
                                flexDirection: 'row',
                                height: '100%',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                flex: 1

                            }}
                        >
                            <TextField
                                id="outlined-basic-full_name"
                                label={t('GROUPS.FORM_NAME')}
                                variant="standard"
                                placeholder={t('GROUPS.FORM_NAME')}
                                sx={{ ...styleSelect,
                                  width: '60%',
                                }}
                                value={object?.name}
                                fullWidth
                                onChange={(event) => {
                                    setobject({ ...object, name: event.target.value });
                                }}
                            />

                            <Box
                             sx={{
                              width: '40%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                             }}
                            >
                               <CustomeColorSelect color="#fff" bgColor={object?.color}
                                onClick={(e)=>{
                                    if(e.target&&e.target.querySelector('input'))
                                     e.target.querySelector('input').click()
                                }}
                               >
                                   <input 
                                   type="color" 
                                   style={{visibility: 'hidden',zIndex:2000,marginTop:'70px',marginLeft:'-70px'}}
                                   value={object?.color}
                                   onChange={(e)=>changeColor(e.target.value)}
                                   
                                   />
                                   <p 
                                   style={{
                                    ...colorForBuble,
                                    position: 'absolute',
                                    top:"52%",
                                    left:"50%",
                                    transform:'translate(-50%, -50%)',
                                    pointerEvents: 'none',
                                   }}
                                   >{object?.color}</p>
                               </CustomeColorSelect>
                            </Box>

                            {/* <TextField
                                id="outlined-basic-phone"
                                multiple
                                label={t('NOTIFICATIONS.FORM_NOTIFICATION_BODY')}
                                variant="outlined"
                                placeholder={t('NOTIFICATIONS.FORM_NOTIFICATION_BODY')}
                                sx={{ ...styleSelect, marginTop: '10px' }}
                                value={object?.body}
                                fullWidth
                                onChange={(event) => {
                                    setobject({ ...object, body: event.target.value });
                                }}
                            /> */}
                          

                        </Box>
                    </Box>
                    <Divider sx={{ marginTop: '10px', width: '100%' }} />
                    <Box 
                    className="buttonSendForm"
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        height: 'auto',
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                    

                    }}>
                        <Button sx={{ color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,}} onClick={() => {
                            submitForm()
                        }}>{t('GROUPS.FORM_SUBMIT')}</Button>
                    </Box>
                </Box>
            }
        </DialogContent>

      </DialogForm>
  )
  

  const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'blue',

    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
        width: '100%',
        border: '1px solid #ababab',
        borderRadius: 5,
        padding: 0,
        display: 'flex'
    }),
    container: () => ({

        width: '80%',
        position: 'relative',
        boxSizing: 'border-box',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
  } 
}
