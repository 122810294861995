import React from 'react'
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import {useDispatch,useSelector} from 'react-redux'
// import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';

export default function SearchFieldV2(props) {
    const GridTheme=useSelector(state => state.settingsData.GridTheme)
  return (
    <FormControl  variant="outlined" sx={{ml:props?.marginLeftValue?props?.marginLeftValue:1,width:props?.customeWidth?props?.customeWidth:'30%',mt:1.2}} >
        {/* <InputLabel >{props.fieldLabel}</InputLabel> */}
            <TextField id="standard-basic" 
               placeholder={props.fieldLabel} variant="standard"
                // id="outlined-adornment-shopname"
                type={props?.type?props?.type:'text'}
                value={props.state}
                sx={{borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}}
                onChange={(e) => props.setState(e.target.value)}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="clear"
                            onClick={() =>  props.setState('')}
                            edge="end"
                            >        
                            <ClearIcon />          
                        </IconButton>
                    </InputAdornment>
                }
                // label="Shop Name"
        />
    </FormControl>
  )
}
