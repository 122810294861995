import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import axios from "axios";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';

import DialogContent from '@mui/material/DialogContent';
import { AiFillCloseCircle } from 'react-icons/ai'
import { Divider, Switch, CardMedia, Box, DialogTitle, IconButton, TextField, Typography, Select, InputLabel, MenuItem, FormControl, FormControlLabel, Button, Avatar } from '@mui/material';
import {useTranslation} from "react-i18next";
import {useSelector,useDispatch} from 'react-redux'
import {setNewCompanyAdded} from '../../reduxStore/SettingsReducer'
import DialogForm from "../Share/DialogForm";
import helpers from "../../../assets/js/helper";

const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"
export const CompanyForm = (props) => {
    const [loading, setloading] = useState(true);
    const [billingFrequencies, setBillingFrequncies] = useState([]);
    const [companies, setCompanies] = useState([])
    const directions = useSelector(state => state.settingsData.directions);
    const MainTheme = useSelector(state => state.settingsData.MainTheme);
    const newCompanyAdded=useSelector(state => state.settingsData.newCompanyAdded)
    const [imageSource, setImageSource] = useState(null)
    const {t, i18n} = useTranslation('common');
    const token = cookies.get("token")
    const dispatch=useDispatch()
    const [imageOpen,setImageOpen]=useState(false)
    const [currentImage,setCurrentImage]=useState('')

    const [object, setobject] = useState({
        id: null,
        number_of_users: null,
        allow_demo: false,
        trial_period: null,
        price: null,
        number_of_allowed_demo_user_per_month: null,
        active_status: false,
        is_real:false,
        company_name: null,
        image: null,
        parent: {
            id: null,
            company_name: null,
        },
        billing_frequency: {
            id: null,
            period_name: null,
            number_of_months: null,
            discount_percentage: null
        }
    });
    const getResources = async () => {
        try {
            setloading(true)
            const resourcesResult = await axios({
                url: Host + "billing_frequencies_list",
                method: "get",
                
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                }
            })
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                    const resources = resourcesResult.data;

                    // console.log(resources);
                    setBillingFrequncies(resources);
                    if (props && props.company && props.company.id > 0) {
                        setobject({ ...object, ...props.company });
                    }
                    else {

                        setobject(
                            {
                                id: null,
                                number_of_users: null,
                                allow_demo: null,
                                trial_period: null,
                                price: null,
                                number_of_allowed_demo_user_per_month: null,
                                active_status: null,
                                is_real:null,
                                company_name: null,
                                image: null,
                                parent: {
                                    id: null,
                                    company_name: null,
                                },
                                billing_frequency: {
                                    id: null,
                                    period_name: null,
                                    number_of_months: null,
                                    discount_percentage: null
                                }
                            }
                        )
                    }
                } else if (resourcesResult.data.status === false) {

                }
            }
            else {
                toast.error("Error")
            }
            const resourcesResult2 = await axios({
                url: Host + "companies_list",
                method: "get",
                params:{
                    ...(props?.company?.id && {
                        company_id:props?.company?.id
                    })
                },
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                }
            })
            if (resourcesResult2) {
                if (resourcesResult2.status === 200) {
                    const resources2 = resourcesResult2.data;
                    setCompanies(resources2);
                    if (props && props.company && props.company.id > 0) {
                        setobject({ ...object, ...props.company });
                    }
                    else {

                        setobject(
                            {
                                id: null,
                                number_of_users: null,
                                allow_demo: null,
                                trial_period: null,
                                price: null,
                                number_of_allowed_demo_user_per_month: null,
                                active_status: null,
                                is_real:null,
                                company_name: null,
                                image: null,
                                parent: {
                                    id: null,
                                    company_name: null
                                },
                                billing_frequency: {
                                    id: null,
                                    period_name: null,
                                    number_of_months: null,
                                    discount_percentage: null
                                }
                            }
                        )
                    }
                } else if (resourcesResult2.data.status === false) {

                }
            }
            else {
                toast.error("Error")
            }
            setloading(false)
        } catch (error) {
            setloading(false)
            toast.error("Network Error")
        }
    };

    const submitForm = async () => {
        
        try {
            const fraudDetection = (parseInt(object?.number_of_allowed_demo_user_per_month)+1)* parseInt(object?.trial_period)
            if(fraudDetection>=30)
            {
                toast.error("Number of allowed demo user per month with this trial period will make it possible for fraud activities from the client ")
                return 
            }
            let formdata = new FormData();

            formdata.append("company_name", object?.company_name)
            formdata.append("number_of_users", object?.number_of_users)
            formdata.append("allow_demo", object?.allow_demo?1:0)
            formdata.append("trial_period", object?.trial_period)
            formdata.append("price", object?.price)

            formdata.append("number_of_allowed_demo_user_per_month", object?.number_of_allowed_demo_user_per_month)
            object?.billing_frequency?.id && formdata.append("billing_frequency_id", object?.billing_frequency?.id)
            object?.parent?.id && formdata.append("parent_id", object?.parent?.id)
            !object?.image?.id && formdata.append("image", object?.image)
            formdata.append("active_status", object?.active_status?1:0)
            formdata.append("is_real", object?.is_real?1:0)
 
            console.log("object?.image", object?.image);
            if (object.id) {
                formdata.append("_method", "PUT");
                const resourcesResult = await axios({
                    url: Host + "companies/" + object.id,
                    method: "post",
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data: formdata
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("updated ")
                        props.setshowCompanyForm(false, true)
                        dispatch(setNewCompanyAdded(!newCompanyAdded))
                    } else if (resourcesResult.data.status === false) {
                        props.setshowCompanyForm(true, false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setshowCompanyForm(true, false)
                }
                setloading(false)
            }
            else {
                const resourcesResult = await axios({
                    url: Host + "companies",
                    method: "post",
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data: formdata
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("saved ")
                        props.setshowCompanyForm(false, true)
                        dispatch(setNewCompanyAdded(!newCompanyAdded))
                    } else if (resourcesResult.status === 400) {
                        toast.error(resourcesResult.data)
                        props.setshowCompanyForm(true, false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setshowCompanyForm(true, false)
                }
                setloading(false)
            }
        } catch (error) {
            props.setshowCompanyForm(true, false)
            const resourcesResult = error.response
            if (resourcesResult && resourcesResult.status === 400) {
                if (typeof resourcesResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key) => {

                        resourcesResult.data.data[key].map((message) => {
                            toast.error(message)
                        })
                    })
                }
                else {

                    toast.error(resourcesResult.data.data)
                }


            }
            else if (resourcesResult && resourcesResult.status === 401) {

                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else {

                toast.error("network error")
            }
        }

    };

    const check =helpers.checkDarknessOrLightnessForColor(MainTheme?.paperTextColor)

    useEffect(() => {


        if (loading)
            getResources()


        return () => {

        };
    }, [loading, props.showCompanyForm]);

    const styleTextField = {
        backgroundColor: !check ? 'rgb(255,255,255,0.7)' : 'rgb(0,0,0,0.4)',
        color: MainTheme?.paperTextColor,
    }

    const styleSelect = {
        // backgroundColor: !check ? 'rgb(255,255,255,0.7)' : 'rgb(0,0,0,0.4)',
        // color:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,
        '& p,& span,& input,& svg,& .MuiOutlinedInput-root':{
          color:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,
        },
        '& li':{
            color:'#000000 !important',
            // color:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperColor} !important`,
        },
        '& .MuiOutlinedInput-root':{
            borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
            color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
        },
        '& .MuiInputLabel-root':{
            // backgroundColor:`${MainTheme?.paperTextColor+'22'} !important`,
            color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
        },
        '& .MuiFormControl-root':{
          // border:`1px solid ${MainTheme?.paperTextColor} !important`,
          color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
          },
          '&:hover fieldset': {
            borderColor: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
          },
          '&.Mui-focused fieldset': {
            borderColor: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.secondaryColor,
          },
        },
        '& .MuiOutlinedInput':{
          // borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
        },
        // "& .muiltr-1r3sywh-MuiButtonBase-root-MuiMenuItem-root": {
        //     color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
        // },
        '& .MuiPaper-root .MuiList-root .muiltr-1r3sywh-MuiButtonBase-root-MuiMenuItem-root, & .MuiPaper-root .MuiList-root .MuiMenuItem-root':{
            color:`#000000 !important`,
        },
        // '& .muiltr-1r3sywh-MuiButtonBase-root-MuiMenuItem-root':{
        //     backgroundColor: `${MainTheme?.paperColor}66 !important`,
        //     color:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,
        // },
        '& .muiltr-1r3sywh-MuiButtonBase-root-MuiMenuItem-root':{
            color:'#000000 !important',
          },
          margin:'10px 0 !important'
    }


  


    return (
        <DialogForm
            open={props.showCompanyForm}
            close={props.setshowCompanyForm}
            loaderActive={loading}
            headerChildren={(
                <>
                    <IconButton aria-label={"Close"} onClick={() => { props.setshowCompanyForm(false) }} sx={{ position: 'absolute', top: '0px', left: '6px', fontSize: '37px', }}>
                      <AiFillCloseCircle sx={{ color: MainTheme?.paperTextColor,outline:'none !important',border:'none !important' }} />
                    </IconButton>
                    <Typography component={'h1'} variant={'h4'} style={{marginTop:'10px',marginBottom:'10px', width: '100%', textAlign: 'center' }}>
                        {props?.title ? props?.title : (object?.id ? t('COMPANY.FORMEDIT_TITLE') : t('COMPANY.FORMADD_TITLE') )}
                    </Typography>
                </>
              )}
              hasHeader={true}
              CustomeWidth="76%"
              CustomeHeight="fit-content"
        >

        <Box
          sx={{
            position:'fixed',
            top:'0',
            left:'0',
            // transform:'translate(-50%,-50%)',
            zIndex:4000,
            boxShadow:'0 0 20px 6px #00000055',
            display: imageOpen?'flex':'none',
            backdropFilter:'blur(5px)',
            width:'100%',
            height:'100%',
            justifyContent: 'center',
            alignItems: 'center',
            transition:'all 1s ease-in-out '

          }}
          onClick={()=>{
            // setImageOpen(false)
          }}
        >
          <img 
            src={currentImage} 
            style={{
                width:'600px',
                height:'600px',
                transform:imageOpen?'scale(1)':'scale(0)',
                transition:'all 1s ease-in-out '

            }}
          />
          <AiFillCloseCircle
            style={{
                position:'absolute',
                top:'7%',
                right:'20%',
                fontSize:'35px',
                cursor:'pointer'
            }}
            onClick={()=>{
               setImageOpen(false)
            }}
          />
        </Box>

            <DialogContent dividers={false} sx={{
                padding: 0,
                overflowX: 'hidden',
                backgroundColor: 'transparent',
                height: '100%',


            }}>

                {loading ? <>Loading...</> :

                    <Box style={{
                        padding: '10px',
                        paddingTop: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'auto',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        color: MainTheme?.paperTextColor

                    }}>
                        <Box style={{

                            display: 'flex',
                            flexDirection: 'row',
                            height: 'auto',
                            width: '100%',
                            justifyContent: 'space-evenly',
                            alignItems: 'flex-start'

                        }}>
                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                margin:'0 10px',
                                flex: 1

                            }}>
                                <TextField
                                    id="outlined-basic-company_name" 
                                    label={t('COMPANY.FORM_COMPANY_NAME')}
                                     variant="standard"
                                    placeholder={t('COMPANY.FORM_COMPANY_NAME')}
                                    sx={{ ...styleSelect }}
                                    value={object?.company_name}
                                    fullWidth
                                    onChange={(event) => {
                                        setobject({ ...object, company_name: event.target.value });
                                    }}
                                />
                                <TextField
                                    id="outlined-basic-number_of_users"
                                     label={t('COMPANY.FORM_NUMBER_OF_USERS')} 
                                     variant="standard"
                                    placeholder={t('COMPANY.FORM_NUMBER_OF_USERS')} 
                                    sx={{ ...styleSelect, marginTop: '10px' }}
                                    value={object?.number_of_users}
                                    fullWidth
                                    onChange={(event) => {
                                        setobject({ ...object, number_of_users: event.target.value });
                                    }}
                                />

                                <TextField
                                    id="outlined-basic-trial_period" 
                                    label={t('COMPANY.FORM_TRIAL_PERIOD')} 
                                     variant="standard"
                                    placeholder={t('COMPANY.FORM_TRIAL_PERIOD')} 
                                    sx={{ ...styleSelect, marginTop: '10px' }}
                                    value={object?.trial_period}
                                    fullWidth
                                    onChange={(event) => {
                                        setobject({ ...object, trial_period: event.target.value });
                                    }}
                                />
                                <TextField
                                    id="outlined-basic-price" 
                                    label={t('COMPANY.FORM_PRICE')}  
                                    variant="standard"
                                    placeholder={t('COMPANY.FORM_PRICE')} 
                                    sx={{ ...styleSelect, marginTop: '10px' }}
                                    value={object?.price}
                                    fullWidth
                                    onChange={(event) => {
                                        setobject({ ...object, price: event.target.value });
                                    }}
                                />

                                <TextField
                                    id="outlined-basic-number_of_allowed_demo_user_per_month"
                                    label={t('COMPANY.FORM_NUMBER_OF_ALLOW_DEMO_USER_PER_MONTH')} 
                                    variant="standard"
                                    placeholder={t('COMPANY.FORM_NUMBER_OF_ALLOW_DEMO_USER_PER_MONTH')} 
                                    sx={{ ...styleSelect, marginTop: '10px' }}
                                    value={object?.number_of_allowed_demo_user_per_month}
                                    fullWidth
                                    onChange={(event) => {
                                        setobject({ ...object, number_of_allowed_demo_user_per_month: event.target.value });
                                    }}
                                />
                            </Box>
                            <Box sx={{

                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                marginLeft: '10px',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                margin:'0 10px',
                                flex: 1

                            }}>
                                <FormControl fullWidth sx={{ ...styleSelect }} variant="standard"
                                  
                                >
                                    <InputLabel id="billing_frequency-select-label" sx={{color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor }}>
                                        {t('COMPANY.FORM_BILLING_FREQUENCY')}
                                    </InputLabel>
                                    <Select
                                        labelId="billing_frequency-select-label"
                                        id="billing_frequency-select"
                                        sx={{ textAlign: 'left', }}
                                        value={object?.billing_frequency}
                                        label={t('COMPANY.FORM_BILLING_FREQUENCY')}
                                        renderValue={(value) => {
                                            //console.log("value", value);
                                            if (value?.id != "" && value?.id != null) {

                                                return <Typography sx={{color:'#000 !important' }} className="listitmColor">{value?.period_name}</Typography>
                                            }
                                            else {

                                                return <Typography sx={{color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor }}>{t('COMPANY.FORM_SELECT_ABILLING_FREQUENCY')}</Typography>
                                            }
                                        }}
                                        onChange={(event) => {

                                            setobject({
                                                ...object, billing_frequency: {
                                                    ...event?.target?.value
                                                },
                                            });
                                        }}
                                    >
                                        {billingFrequencies?.length > 0 && billingFrequencies?.map((billingFrequency) => {

                                            return <MenuItem key={billingFrequency?.id} sx={{ color:'#000000' }} value={billingFrequency}>{billingFrequency?.period_name}</MenuItem>
                                        })}


                                    </Select>
                                </FormControl>
                                <FormControl fullWidth sx={{ ...styleSelect, marginTop: '10px' }} variant="standard">
                                    <InputLabel id="companies-select-label" sx={{ color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor }}>{t('COMPANY.FORM_PARENT_COMPANY')}</InputLabel>
                                    <Select
                                        labelId="companies-select-label"
                                        id="companies-select"
                                        sx={{ textAlign: 'left' }}
                                        value={object?.parent}
                                        label={t('COMPANY.FORM_PARENT_COMPANY')}
                                        renderValue={(value) => {
                                            //console.log("value", value);
                                            if (value?.id != "" && value?.id != null) {

                                                return `${value?.company_name}`
                                            }
                                            else {

                                                return <Typography sx={{ color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor }}>{t('COMPANY.FORM_SELECT_COMPANY')}</Typography>
                                            }
                                        }}
                                        onChange={(event) => {

                                            setobject({
                                                ...object, parent: {
                                                    ...event?.target?.value
                                                },
                                            });
                                        }}
                                    >
                                        {companies?.length > 0 && companies?.map((company) => {
                                            if(object?.id != company?.id)
                                            return <MenuItem key={company?.id} sx={{ color:"#000" }} value={company}>{company?.company_name}</MenuItem>
                                        })}


                                    </Select>
                                </FormControl>
                                <Box sx={{
                                         width:'100%',
                                         display:'flex',
                                         flexDirection:'column',
                                         justifyContent: 'start',
                                         alignItems: 'start !important',
                                         "& span":{
                                            color: `${MainTheme?.secondaryColor} !important`
                                        },
                                        "& .MuiSwitch-thumb":{
                                            color: `${MainTheme?.secondaryColor} !important;`
                                        },
                                        "& .MuiSwitch-track":{
                                            backgroundColor: `${MainTheme?.secondaryColor}66 !important,`
                                        },
                                      "  & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track ":{
                                            backgroundColor: `${MainTheme?.secondaryColor}bb !important,`
                                        },
                                }}>
                                    <FormControlLabel fullWidth control={
                                        <Switch
                                            checked={object?.allow_demo}
                                            onChange={(event) => {
                                                const target = event.target;

                                                if (target.checked) {
                                                    setobject({ ...object, allow_demo: 1 });
                                                }
                                                else {
                                                    setobject({ ...object, allow_demo: 0 });
                                                }
                                            }}
                                            inputProps={{ 'aria-label': t('COMPANY.FORM_ALLOW_DEMO') }}

                                        />
                                    } label={<Typography sx={{ color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor }}>{t('COMPANY.FORM_ALLOW_DEMO')}</Typography>} />
                                    <FormControlLabel fullWidth control={
                                        <Switch
                                        
                                            checked={object?.active_status}
                                            onChange={(event) => {
                                                const target = event.target;

                                                if (target.checked) {
                                                    setobject({ ...object, active_status: 1 });
                                                }
                                                else {
                                                    setobject({ ...object, active_status: 0 });
                                                }
                                            }}
                                            inputProps={{ 'aria-label': t('COMPANY.FORM_ACTIVE_STATUS') }}

                                        />
                                    } label={<Typography sx={{ color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor }}>{t('COMPANY.FORM_ACTIVE_STATUS')}</Typography>} />
                                    <FormControlLabel fullWidth control={
                                        <Switch
                                            checked={object?.is_real}
                                            onChange={(event) => {
                                                const target = event.target;

                                                if (target.checked) {
                                                    setobject({ ...object, is_real: 1 });
                                                }
                                                else {
                                                    setobject({ ...object, is_real: 0 });
                                                }
                                            }}
                                            inputProps={{ 'aria-label': t('COMPANY.FORM_IS_REAL_COMPANY') }}

                                        />
                                    } label={<Typography sx={{ color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor }}>{t('COMPANY.FORM_IS_REAL_COMPANY')}</Typography>} />
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    height: '60px',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',

                                    " & span": {
                                        color: MainTheme?.secondaryColor
                                    },
                                    "& .MuiSwitch-track": {
                                        backgroundColor: MainTheme?.secondaryColor + '66'
                                    },
                                    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                                        backgroundColor: MainTheme?.secondaryColor + 'ee'
                                    },

                                }}>
                                    <Button variant="contained" component="label" style={{ backgroundColor: MainTheme?.secondaryColor, color: MainTheme?.oppositeSecondaryColor }}>
                                        {t('COMPANY.FORM_LOGO')}
                                        <input hidden accept="image/*" onChange={(event) => {
                                            let files = []
                                            for (let i = 0, numFiles = event?.target?.files.length; i < numFiles; i++) {
                                                const file = event?.target?.files[i];
                                                files.push(file)
                                                // …
                                            }
                                            if (event?.target?.files?.length > 0) {
                                                const reader = new FileReader()
                                                reader.onload = ev => {
                                                    setImageSource(ev.target.result)
                                                }
                                                reader.readAsDataURL(files[0])
                                                setobject({
                                                    ...object,
                                                    image: files[0]
                                                })
                                            }
                                        }} multiple={false} type="file" />
                                    </Button>

                                    {imageSource ?
                                        <CardMedia
                                            component="img"
                                            alt=""
                                            sx={{
                                                objectFit: 'contain',
                                                height: '50px',
                                            }}
                                            onClick={()=>{
                                                setCurrentImage(imageSource)
                                                setImageOpen(true)
                                             }}
                                            image={imageSource}
                                        /> :
                                        <Typography sx={{ color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor }}>
                                           {t('COMPANY.FORM_NO_LOGO_SELECTED')}
                                        </Typography>
                                    }
                                    {object?.image?.id &&  <Avatar
                                         onClick={()=>{
                                            setCurrentImage(helpers.getImageUrl(object?.image?.image_url))
                                            setImageOpen(true)
                                         }}
                                        src={ helpers.getImageUrl(object?.image?.image_url)}
                                        name={object?.company_name}
                                        size={40}
                                        sx={{
                                            backgroundColor: MainTheme?.paperTextColor + '99',
                                            color: MainTheme?.paperTextColor,
                                        }}
                                    />}

                                </Box>
                            </Box>
                        </Box>
                        <Divider sx={{ marginTop: '10px', width: '100%' }} />


                        <Box 
                        className="buttonSendForm"
                        sx={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'row',
                            height: 'auto',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center'

                        }}>
                            <Button 
                            sx={{
                                color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor
                            }}
                            onClick={() => {
                                submitForm()
                            }}>{t('COMPANY.FORM_SUBMIT')}</Button>
                        </Box>

                    </Box>
                }




            </DialogContent>

        </DialogForm>
    );
};

export default CompanyForm;
