import React,{useState,useEffect} from 'react'
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector, useDispatch } from 'react-redux'
import {setSideMenuMode,sideMenuMode,setDirections,setNotificationBox} from '../../reduxStore/SettingsReducer'
import '../Settings/Settings.css'
import CloseIcon from '@mui/icons-material/Close';
import { alpha, styled } from '@mui/material/styles';
import { grey, pink } from '@mui/material/colors';
import Switch from '@mui/material/Switch';
import StarIcon from '@mui/icons-material/Star';
import {useTranslation} from "react-i18next";

import {
  StyledNotificationBox,
  SettingsCloseIcons2,
  NoNotification,
  NotificationItem,
  LeftSide,
  HeaderSection,
  RightSide,
  IconCloseNotificationItem

} from '../Share/StyledComponents/NotificationBox';

const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: pink[600],
      '&:hover': {
        backgroundColor: alpha(pink[600], theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: pink[600],
    },
}));
  
  const label = { inputProps: { 'aria-label': 'Switch demo' } };


  

export default function NotificationBox() {
    const dispatch = useDispatch()
    const sideMenuMode = useSelector(state=> state.settingsData.sideMenuMode)
    const directions = useSelector(state=> state.settingsData.directions)
    const MainTheme = useSelector(state=> state.settingsData.MainTheme)
    const {t, i18n} = useTranslation('common');

    const notificationBox = useSelector(state=> state.settingsData.notificationBox)

    const [settingsSideDirections,setSettingsSideDirections]=useState('right')
    const [NotificationArry,setNotificationArry]=useState([
        {
            id:1,
            icone:<StarIcon className="subLeftSide"/>,
            title:'Daily challenges',
            description:'Your submission has been accepted',
            image:null,
        },
        {
          id:2,
          icone:<StarIcon className="subLeftSide"/>,
          title:'Daily challenges',
          description:'Your submission has been accepted',
          image:null,
        },
        {
          id:3,
          icone:<StarIcon className="subLeftSide"/>,
          title:'Daily challenges',
          description:'Your submission has been accepted',
          image:null,
        },
        {
          id:4,
          icone:<StarIcon className="subLeftSide"/>,
          title:'Daily challenges',
          description:'Your submission has been accepted',
          image:null,
      },
      {
        id:5,
        icone:<StarIcon className="subLeftSide"/>,
        title:'Daily challenges',
        description:'Your submission has been accepted',
        image:null,
      },
      {
        id:6,
        icone:<StarIcon className="subLeftSide"/>,
        title:'Daily challenges',
        description:'Your submission has been accepted',
        image:null,
      }
    ])
  
    useEffect(()=>{
      console.log('direction=>',directions)
      if(directions=='rtl'){
        setSettingsSideDirections('left')
      }else{
        setSettingsSideDirections('right')
      }
    },[directions])

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const removeNotificationItem=(id)=>{
        setNotificationArry(NotificationArry.filter(item=>item?.id !=id))
    }
    const removeAllNotification=()=>{
        setNotificationArry([])
    }

    const renderingMNotification=()=>{
      return  NotificationArry&&NotificationArry?.length>0&&NotificationArry?.map((notification)=>(
            <NotificationItem key={notification?.id} bgColor={MainTheme?.paperColor} color={MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} directions={directions=='rtl' ? true : false}>
              <LeftSide color={MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} iconColor={MainTheme?.secondaryColor}>
                  <div >
                      {notification?.image?
                      <img src={notification.image} alt="" className="imgNotification"/>
                      :
                        notification?.icone
                      }
                </div>
              </LeftSide>
              <RightSide color={MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} directions={directions=='rtl' ? true : false}>
                  <p>{notification?.title}</p>
                  <p>{notification?.description}</p>
              </RightSide>
              <IconCloseNotificationItem bgColor={MainTheme?.paperColor} color={MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor}>
                  <div onClick={()=>removeNotificationItem(notification?.id)}>
                      <CloseIcon style={{fontSize:'18px'}} className="icon" />
                  </div>
              </IconCloseNotificationItem>
            </NotificationItem>
        ))
    }
    
    const list = (anchor) => (
        <Box
        sx={{ 
          backgroundColor:MainTheme?.bgColor, 
          width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 320 ,
          overflowY: 'scroll',
          position:'relative' 
        }}
        className='SideSettings'
        role="presentation"

        //   onClick={()=>setOpenSideSettings(false)}
        //   onKeyDown={()=>setOpenSideSettings(false)}
        >
        {/* header of sideMenu settings  */}
    

        

        {/* notification container */}
        {
          NotificationArry?.length>0?
          renderingMNotification()
          :
          <NoNotification color={MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor}>
             <p>{t('NOTIFICATIONS.NO_NOTIFICATIONS')}</p>
          </NoNotification>
        }

   



        </Box>
    );

    const closeNotification=()=>{
        dispatch(setNotificationBox(false))
    }

    



  return (
    <StyledNotificationBox bgColor={MainTheme?.paperColor} color={MainTheme?.bgTextColor}>
        <SwipeableDrawer
        anchor={'right'}
        open={notificationBox}
        onClose={()=>closeNotification()}
           sx={{
            '& .muirtl-1160xiw-MuiPaper-root-MuiDrawer-paper,& .muirtl-1ab2xsx,& .muiltr-1ab2xsx':{
              backgroundColor:MainTheme?.bgColor +' !important',
            }
           }}
        // SlideProps={{
        //   direction: directions=='rtl'?'right':'left'
        // }}
           // onOpen={setOpenSideSettings(true)}
           
        >
         <SettingsCloseIcons2  bgColor={MainTheme?.bgColor} color={MainTheme?.bgTextColor}>
            <div className="iconCloseNotificationBox" onClick={()=>closeNotification(false)}>
               <CloseIcon   className="FloatingIcon"/>
            </div>
         </SettingsCloseIcons2>

         {
            NotificationArry?.length>0?
            <HeaderSection color={MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor}>
                <h3 className="">{t('NOTIFICATIONS.TITLE')}</h3>
                <p onClick={()=>removeAllNotification()}>{t('NOTIFICATIONS.DISMISSION_ALL_NOTIFICATIONS')}</p>
            </HeaderSection>
            :null
        }

        {list(settingsSideDirections)}
        </SwipeableDrawer>
    </StyledNotificationBox>
  )
}
