import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Pane, Dialog,FilePicker } from 'evergreen-ui';
import axios from "axios";
import Select from "react-select";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import {useSelector,useDispatch} from 'react-redux'
import DialogForm from '../Share/DialogForm'
import FooterBottons from "../Share/FooterBottons";
import InputFile from "../Share/InputFile";
import {useTranslation} from "react-i18next";
import {Box,Button} from '@mui/material'
import PointSampleFile from '../../../assets/csv/point_csv_template.csv'
import swal from 'sweetalert';
const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"
export const PointImportForm = (props) => {
    const [loading, setloading] = useState(false);
    const [object,setobject] = useState(null)
    const MainTheme=useSelector(state=>state.settingsData.MainTheme);
    const directions=useSelector(state=>state.settingsData.directions);
    const [t, i18n] = useTranslation('common');
    const token = cookies.get("token")
    

    const submitForm = async (file) => {
        
        try {
            if(!file)
            {
                toast.error("please select a file")
                return;
            }
            setloading(true)
            let formdata = new FormData();

            formdata.append("uploaded_excel_file", file)
            console.log(formdata);
                const resourcesResult = await axios({
                    url: Host + "import_points_excel",
                    method: "post",
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data: formdata
                })
                console.log(resourcesResult);
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success(resourcesResult.data.data)
                        props.setshowPointImportForm(false,true)
                    } else if (resourcesResult.data.status === false) {
                        props.setshowPointImportForm(true,false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setshowPointImportForm(true,false)
                }
                setloading(false)
          
        } catch (error) {
            setloading(false)
            console.log(error);
            props.setshowPointImportForm(true,false)
            const resourcesResult=error.response            
            if (resourcesResult && resourcesResult.status === 400) {
                console.log(resourcesResult);
                if(typeof resourcesResult.data.data === "object")
                {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key)=>{
                    
                        resourcesResult.data.data[key].map((message)=>{
                            toast.error(message)
                        })
                    })
                }
                else{
                    
                    // toast.error(resourcesResult.data.data)
                    swal({
                        title:`${t('SWEATALERT_MESSAGES.WARNNING_MESSAGE')}`, 
                        text:`${resourcesResult.data.data}`,
                        button: `${t('SWEATALERT_MESSAGES.WARRNING_BUTTON')}`,
                        icon:'warning',
                    })

                }
                
                
            }
            else if(resourcesResult && resourcesResult.status === 401) {
             
                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else
            {
              
                toast.error("network error")
            }
        }
        
    };


    const onFileChange = (file)=>{
            setobject(file)
    }
    useEffect(() => {
        
      

        
        return () => {

        };
    }, [loading,props.showPointImportForm]);

    const downLoadFile=(data)=>{
        var blob = new Blob([data]);
        var url = window.URL.createObjectURL(blob);
        window.open(url);
    }

    return (
        <DialogForm
            open={props.showPointImportForm}
            close={props.setshowPointImportForm}
            CustomeWidth="50%"
            CustomeHeight="fit-content"
            loaderActive={loading}
        >
            {/* <h4>Import Points</h4> */}
            {
            // loading ? <div>loading ...</div> :
            // <form >
            //     <div className="form-group">
            //         <label htmlFor="file">Select CSV file </label>
            //         <FilePicker onChange={(e)=>onFileChange(e)} name="file" width={350} height={24} />
            //     </div>
                
            // </form>
            }
            <InputFile object={object} setObject={setobject} submitForm={submitForm} loading={loading} onFileChange={onFileChange} title={t('GLOBAL.IMPORT_FORM_TITLE')} subTitle={t('GLOBAL.IMPORT_FORM_MESSAGES')}/>
            <Box 
               sx={{display:'flex',justifyContent: 'end',alignItems:'center'}}
            >
                <a href={PointSampleFile} target="_blank" download>
                <Button variant="Sample"  sx={{
                    padding:'10px 7px',
                    borderRadius:'10px',
                    textTransform:'lowercase',
                    backgroundColor:MainTheme?.secondaryColor,color:MainTheme?.oppositeSecondaryColor,
                    '&:hover':{
                      backgroundColor:MainTheme?.secondaryColor,color:MainTheme?.oppositeSecondaryColor+'33',
                    },
                }}>{t('GLOBAL.IMPORT_POINT_SAMPLE')}</Button>
                </a>

               
               <FooterBottons close={props.setshowPointImportForm} submitForm={submitForm} hiddenSave={true} />
            </Box>
        </DialogForm>
    );
};


export default PointImportForm;
