import React ,{useState,useEffect} from 'react'

import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import LanguageIcon from '@mui/icons-material/Language';
import { useSelector, useDispatch } from 'react-redux'
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import './Notifications.css'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';

import Iraq from '../../../assets/img/iraq.png'
import UnitedState from '../../../assets/img/united-states.png'
import unitedKingdom from '../../../assets/img/united-kingdom.png'

import Host from "../../../assets/js/Host";
import { toast } from "react-toastify";
import axios from "axios";
import Cookies from "universal-cookie";

import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {useTranslation} from "react-i18next";
import {ImagePath} from '../../../assets/js/Host'
import {
  setSideMenuMode,
  sideMenuMode,
  setDirections,
  setListOfTranslateData,
  setListOfTranslateDataCode,
  setReduxLoading
} from '../../reduxStore/SettingsReducer'
const cookies = new Cookies();
const language = cookies.get("language") ? cookies.get("language") : "ar";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme,ToolBarTheme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    backgroundColor: ToolBarTheme?.paperColor,
    color:ToolBarTheme?.disabledGlobalText?ToolBarTheme?.gloablTextColor:ToolBarTheme?.paperTextColor,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
      '& .activelanguage':{
        backgroundColor: `${ToolBarTheme?.paperTextColor+'55'} !important`,
      },
      
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
      
     
    },
    '& .itmlist:hover':{
      backgroundColor: ToolBarTheme?.paperTextColor+'55',
    }
   
  },
}));

export default function Language({ToolBarTheme}) {
   const directions__= useSelector(state=> state.settingsData.directions)
   const dispatch=useDispatch();
   const [directions,setDirectionNow]=useState(directions__)
   const [t, i18n] = useTranslation('common');
   const token= cookies.get("token")
   const [loading,setLoading]=useState(false)
   const [anchorEl, setAnchorEl] = React.useState(null);
   const open = Boolean(anchorEl);
   const [language,setLanguage]=useState();
   const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const newLanguageAdded=useSelector(state=>state.settingsData.newLanguageAdded)

  const handleClose = (code,direction,id) => {
    i18n.changeLanguage(code)
    dispatch(setListOfTranslateDataCode(code))
    dispatch(setReduxLoading(true))

    localStorage.setItem('code',code)
    localStorage.setItem('direction',direction)
    localStorage.setItem('language_id',id)

    if(direction==1)
         dispatch(setDirections('rtl'))
    else
        dispatch(setDirections('ltr'))



    // else if(direction=='rtl')
    // i18n.changeLanguage('ar')

    setAnchorEl(null);
  };

  const handleCloseTwo=()=>{
    setAnchorEl(null);
      
  }
  
  useEffect(()=>{
    setDirectionNow(directions__)
  },[directions__])

  const getAllLanguages = async () => {

    try {
        setLoading(true)
        const resourcesResult = await axios({
            url: Host + "languages_list",
            method: "get",
            headers: {
                "Authorization": `Bearer ${token}`,
                Accept: "application/json",
                'X-localization': language,
            },
        })
        if (resourcesResult) {
            if (resourcesResult.status === 200) {
                // toast.success("updated ")
                setLanguage(resourcesResult?.data)
            } else if (resourcesResult.data.status === false) {
            }
        }
        else {
            toast.error("Error")
        }
        setLoading(false)
      
    } catch (error) {
        console.log("error",error);
        setLoading(false)
        const resourcesResult = error.response
        if (resourcesResult && resourcesResult.status === 400) {
            if (typeof resourcesResult.data.data === "object") {
                const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                errorMessagesKeys.map((key) => {

                    resourcesResult.data.data[key].map((message) => {
                        toast.error(message)
                    })
                })
            }
            else {

                toast.error(resourcesResult.data.data)
            }


        }
        else if (resourcesResult && resourcesResult.status === 401) {

            toast.error(resourcesResult.data.data)
            cookies.remove("token");
            window.location.href = "/"
        }
        else {

            toast.error("network error")
        }
    }

  };

  useEffect(()=>{
    getAllLanguages()
  },[newLanguageAdded])




  return (
    <div>
     
      <Tooltip  id="demo-customized-button" onClick={()=>handleClick()} arrow title={t('APPBAR.TOOLTIP_LANGUAGE')} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="bottom">
        <IconButton  className='Notification' 
        size="small"
        sx={{ borderRadius:2,height:'40px' }}
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        >
          {
            language&&language?.length>0?
          <div className="selectedLanguage">
            {
              localStorage.getItem('code')&&localStorage.getItem('direction')?
              language&&language?.map((lang)=>(
              lang?.code==localStorage.getItem('code')&&lang?.rtl==1?
               <div key={lang?.code+lang?.rtl+lang?.id} className="subSelected">
                  <img alt="" src={ImagePath+lang?.image?.image_url} style={{width:'25px',height:'25px',margin:'0 10px 0 0'}}/>
                    <span style={{marginRight:directions=='rtl'?5:0,color:ToolBarTheme?.disabledGlobalText?ToolBarTheme?.gloablTextColor:ToolBarTheme?.paperTextColor,textTransform:'uppercase'}}>{lang?.code}</span>
               </div>:lang?.code==localStorage.getItem('code')?
               <div key={lang?.code+lang?.rtl+lang?.id} className="subSelected">
                 <img alt="" src={ImagePath+lang?.image?.image_url} style={{width:'25px',height:'25px',margin:'0 10px 0 0'}}/>
                  <span style={{marginRight:directions=='rtl'?5:0,color:ToolBarTheme?.disabledGlobalText?ToolBarTheme?.gloablTextColor:ToolBarTheme?.paperTextColor,textTransform:'uppercase'}}>{lang?.code}</span>
               </div>:null ))
               :(
                language?.length>0&&language[0]?.rtl==1?
                <div  className="subSelected">
                   <img alt="" src={ImagePath+language[0]?.image?.image_url} style={{width:'25px',height:'25px',margin:'0 10px 0 0'}}/>
                     <span style={{marginRight:directions=='rtl'?5:0,color:ToolBarTheme?.disabledGlobalText?ToolBarTheme?.gloablTextColor:ToolBarTheme?.paperTextColor,textTransform:'uppercase'}}>{language[0]?.code}</span>
                </div>:
                <div  className="subSelected">
                  <img alt="" src={ImagePath+language[0]?.image?.image_url} style={{width:'25px',height:'25px',margin:'0 10px 0 0'}}/>
                   <span style={{marginRight:directions=='rtl'?5:0,color:ToolBarTheme?.disabledGlobalText?ToolBarTheme?.gloablTextColor:ToolBarTheme?.paperTextColor,textTransform:'uppercase'}}>{language[0]?.code}</span>
                </div>
               )
             
            }
          </div>:
           <p style={{marginTop:'15px'}}>no language</p>
          }
          
            {/* <LanguageIcon className='colorNotificationLanguage' size={30}  style={{color:ToolBarTheme?.disabledGlobalText?ToolBarTheme?.gloablTextColor:ToolBarTheme?.paperTextColor}}/> */}
          
            </IconButton>
      </Tooltip>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        ToolBarTheme={ToolBarTheme}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseTwo}
      >
        {
         
          language&&language?.map((lang)=>(
            localStorage.getItem('code')&&localStorage.getItem('direction')?
            localStorage.getItem('direction')!=1?
            <MenuItem key={lang?.code+lang?.rtl+lang?.id} onClick={()=>handleClose(lang?.code,lang?.rtl,lang?.id)}  disableRipple className={`itmlist ${localStorage.getItem('code')==lang?.code?'activelanguage ':'languageNormal'}`}>
              <img alt="" src={ImagePath+lang?.image?.image_url} style={{width:'25px',height:'25px',margin:'0 10px 0 0'}}/>
              <span style={{position:'relative',top:'-3px'}}>{lang?.name}</span>
            </MenuItem>:
            <MenuItem key={lang?.code+lang?.rtl+lang?.id} sx={{display: 'flex',justifyContent: 'space-evenly',alignItems: 'center'}} onClick={()=>handleClose(lang?.code,lang?.rtl,lang?.id)} disableRipple className={`itmlist ${localStorage.getItem('code')==lang?.code?'activelanguage ':'languageNormal'} arabicDirection`}>
              <span style={{margin:'0 10px',position:'relative',top:'-3px'}}> {lang?.name}</span>
              <img alt="" src={ImagePath+lang?.image?.image_url} style={{width:'25px',height:'25px',margin:'0 0 0 0'}}/>
            </MenuItem>:
            language[0]?.rtl!==1?
            <MenuItem key={lang?.code+lang?.rtl+lang?.id} onClick={()=>handleClose(lang?.code,lang?.rtl,lang?.id)} disableRipple className={`itmlist ${language[0]?.rtl==lang?.code?'activelanguage ':'languageNormal '}`}>
              <img alt="" src={ImagePath+lang?.image?.image_url} style={{width:'25px',height:'25px',margin:'0 10px 0 0'}}/>
              <span style={{position:'relative',top:'-3px'}}>{lang?.name}</span>
            </MenuItem>:
            <MenuItem key={lang?.code+lang?.rtl+lang?.id} sx={{display: 'flex',justifyContent: 'space-evenly',alignItems: 'center'}} onClick={()=>handleClose(lang?.code,lang?.rtl,lang?.id)} disableRipple className={`itmlist ${language[0]?.rtl==lang?.code?'activelanguage ':'languageNormal'} arabicDirection`}>
              <span style={{margin:'0 10px',position:'relative',top:'-3px'}}> {lang?.name}</span>
              <img alt="" src={ImagePath+lang?.image?.image_url} style={{width:'25px',height:'25px',margin:'0 0 0 0'}}/>
            </MenuItem>
          ))
        }

        {/* {
        directions!=='rtl'?
        <MenuItem onClick={()=>handleClose('rtl')} disableRipple className={`itmlist ${directions=='rtl'?'activelanguage arabicDirection':'languageNormal'}`}>
           <img src={Iraq} style={{width:'25px',height:'25px',margin:'0 10px 0 0'}}/>
           عربي
        </MenuItem>:
        <MenuItem onClick={()=>handleClose('rtl')} disableRipple className={`itmlist ${directions=='rtl'?'activelanguage arabicDirection':'languageNormal'}`}>
           <span style={{margin:'0 10px'}}>عربي</span>
           <img src={Iraq} style={{width:'25px',height:'25px',margin:'0 0 0 0'}}/>
        </MenuItem>
        } */}
      </StyledMenu>
    </div>
  )
}
