
import React, {useEffect,useState} from 'react';
// import Pagination from "react-js-pagination";
import Cookies from "universal-cookie";
import axios from "axios";
import Host,{FrontEndDomain} from "../../../assets/js/Host";
import { toast } from 'react-toastify';
// import { Button } from 'react-bootstrap';
import Button from '@mui/material/Button';
import StyledDataGrid from '../GeneralComponents/StyledDataGrid'
import JourneyForm from './JourneyForm';
import JourneyMapDialog from './JourneyMapDialog';
import CircularIndeterminate from  '../GeneralComponents/CirularProgress'
import Tooltip from '@mui/material/Tooltip';
import IconButton from "@mui/material/IconButton";
// import Pagination from '@mui/material/Pagination';
import Pagination from '../Share/Pagination'
import {useSelector,useDispatch} from 'react-redux'
import red from '@mui/material/colors/red';

import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import Box from '@mui/material/Box';
import { useGlobalContext } from '../../../context'
import Grid from '../Share/Grid'
import {useTranslation} from "react-i18next";
import DropDownGrid from '../Share/DropDownGrid';
import FilterBox from '../Share/FilterBox';
import {MenuItem,ListItemIcon} from '@mui/material'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import SearchField1 from '../GeneralComponents/SearchField1'
import BasicDatePicker from '../GeneralComponents/BasicDatePicker';
import { Checkbox, FormControlLabel } from '@mui/material';
import PointsMapDialog from '../Points/PointsMapDialog';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import UserDailyPlanNewForm from '../UsersDailyPlans/UserDailyPlanNewForm'
import moment from 'moment';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import helpers from '../../../assets/js/helper'
import ViewJourney from '../UsersDailyPlans/ViewJourney';
import BasicDatePickerV2 from '../GeneralComponents/BasicDatePickerV2';
import SearchFieldV2 from '../GeneralComponents/SearchFieldV2'
import CustomeFilter from '../Share/CustomeFilter'
// const cookies = new Cookies();
// const token = cookies.get("token")
// const language = cookies.get("language")?cookies.get("language"):"ar"
function Journeys() {
    const [t, i18n] = useTranslation('common');
    const [pageSize, setPageSize] = useState(5);    
    const [pageNumber, setpageNumber] = useState(1);
    const [pointsTotal, setPointsTotal] = useState(0)
    const [pointsNumberOfPages, setPointsNumberOfPages] = useState(0)
    const {user, users,fromToDate,journey_types, visits, setUser,loading,journies,pointsStatuses,pointStatus,setPointStatus,exportToCSV,allPoints,points,district,region,districts,regions,ownerName,shopName,clearPointsAdvanceSearchFields,setRegion,setDistrict,setShopName,setOwnerName,fetchData,deleteObject} = useGlobalContext()
    const FilterButtonHeight =useSelector(state=>state.settingsData.FilterButtonHeight)
    const filterSearchBoxMode = useSelector(state=> state.settingsData.filterSearchBoxMode)

    // const [loading, setloading] = useState(false);
    const [state, setData] = useState({
        journeys: ''
    });
    const directions = useSelector(state => state.settingsData.directions)

    const [showJourneyForm, setshowJourneyForm] = useState(false);
    const [showJourneyImportForm, setshowJourneyImportForm] = useState(false);
    const [showJourneyMapDialog,setshowJourneyMapDialog] = useState(false)
    const [selectedJourney, setselectedJourney] = useState(null);
    const GridTheme = useSelector(state => state.settingsData.GridTheme)
    const sideMenuMode = useSelector(state => state.settingsData.sideMenuMode)
    // const SearchButtonTheme=useSelector(state=> state.settingsData.SearchButtonTheme);
    const screenWidthRedux=useSelector(state=>state.settingsData.screenWidth)

    const [startDate,setStartDate]=useState(new Date())
    const [EndDate,setEndDate]=useState(new Date())
    const [selectedUser,setSelectedUser]=useState('')
    const [selectedJourneysPlan,setSelectedJourneysPlans]=useState('')
    const [newForm,setNewForm]=useState(false)
    const [showViewList,setShowViewList]=useState(false)
   
    const SearchButtonTheme=useSelector(state=> state.settingsData.SearchButtonTheme);


    const Language=React.useMemo(()=>{
        if(directions!=='rtl'){
          return require('../../Settings/Translate_en.json').VISITS
        }else{
          return require('../../Settings/Translate_ar.json').VISITS
        }
    },[directions])

    const advanceSearch = ()=>{         
        fetchData('JOURNIES','journey_plans',{
            page:pageNumber,
            page_size:pageSize,
            start_date:helpers?.formatDate(startDate),
            end_date:helpers?.formatDate(EndDate) ,
            user_id:selectedUser?.id,
            journey_type_id:selectedJourneysPlan?.id
        });
        fetchData('USERS','users_list')   
        fetchData('JOURNEYS_TYPES','journey_types_list')                        

    }

    // const fetchData = async (pageNumber = 1) => {
    //     setloading(true)
    //     try {
            
    //         const result=await axios({
    //             url: Host + "users_plans",
    //             method: "get",
    //             headers: {
    //             "Authorization" : `Bearer ${token}`,
    //                 Accept:"application/json",
    //                 'X-localization':language,
    //             },
    //             params:{
    //                 page:pageNumber
    //             }
        
    //         })
    //         if(result && result.status === 200)
    //         {
    //             const journeys=result.data ;
                
    //             console.log(journeys);
    //             setData({
    //                 journeys:journeys 
    //             });
    //         } else if (result.data.status === false) {
    //             toast.error("Error")
    //         }
    //     } catch (error) {
    //         const responseResult=error.response            
    //         if (responseResult && responseResult.status === 400) {
    //             if(typeof responseResult.data.data === "object")
    //             {
    //                 const errorMessagesKeys = Object.keys(responseResult.data.data);
    //                 errorMessagesKeys.map((key)=>{
                    
    //                     responseResult.data.data[key].map((message)=>{
    //                         toast.error(message)
    //                     })
    //                 })
    //             }
    //             else{
    //                 toast.error(responseResult.data.data)
    //             }
                
    //         }
    //         else if(responseResult && responseResult.status === 401) {
    //             toast.error(responseResult.data.data)
    //             cookies.remove("token");
    //             window.location.href = "/"
    //         }
    //         else
    //         {
    //             toast.error("network error")
    //         }
    //     }
    //     setloading(false)
                   
    // };
    // const updateObject = async (object) => {
      
    //     setselectedJourney(object)
    //     setshowJourneyForm(true)
    // };
    
    
    // const deleteObject = async (object) => {
    //     if (!window.confirm("Delete the item?")) {
    //         return
    //     }
    //     setloading(true)
    //     try {
    //         const result=await axios({
    //             url: Host + "journeys/"+object.id,
    //             method: "delete",
    //             headers: {
    //             "Authorization" : `Bearer ${token}`,
    //                 Accept:"application/json",
    //                 'X-localization':language,
    //             }
        
    //         })
    //         if(result && result.status == 200)
    //         {
    //             toast.error(result.data.data)
    //             fetchData();
    //         } else if (result.data.status === false) {
    //             toast.error("Error")
    //         }
    //     } catch (error) {
    //         const responseResult=error.response            
    //         if (responseResult && responseResult.status === 400) {
    //             if(typeof responseResult.data.data === "object")
    //             {
    //                 const errorMessagesKeys = Object.keys(responseResult.data.data);
    //                 errorMessagesKeys.map((key)=>{
                    
    //                     responseResult.data.data[key].map((message)=>{
    //                         toast.error(message)
    //                     })
    //                 })
    //             }
    //             else{
    //                 toast.error(responseResult.data.data)
    //             }
                
    //         }
    //         else if(responseResult && responseResult.status === 401) {
    //             toast.error(responseResult.data.data)
    //             cookies.remove("token");
    //             window.location.href = "/"
    //         }
    //         else
    //         {
    //             toast.error("network error")
    //         }
    //     }
    //     setloading(false)
    // };
    // const activateObject = async (object) => {
    //     setloading(true)
    //     try {
    //         const result=await axios({
    //             url: Host + "journeys/activate",
    //             method: "post",
    //             headers: {
    //             "Authorization" : `Bearer ${token}`,
    //                 Accept:"application/json",
    //                 'X-localization':language,
    //             },
    //             data:{
    //                 journey_id:object.id,
    //                 active:object.active==1?0:1
    //             }
        
    //         })
    //         if(result && result.status == 200)
    //         {
    //             fetchData();
    //         } else if (result.data.status === false) {
    //             toast.error("Error")
    //         }
    //     } catch (error) {
    //         const responseResult=error.response            
    //         if (responseResult && responseResult.status === 400) {
    //             if(typeof responseResult.data.data === "object")
    //             {
    //                 const errorMessagesKeys = Object.keys(responseResult.data.data);
    //                 errorMessagesKeys.map((key)=>{
                    
    //                     responseResult.data.data[key].map((message)=>{
    //                         toast.error(message)
    //                     })
    //                 })
    //             }
    //             else{
    //                 toast.error(responseResult.data.data)
    //             }
                
    //         }
    //         else if(responseResult && responseResult.status === 401) {
    //             toast.error(responseResult.data.data)
    //             cookies.remove("token");
    //             window.location.href = "/"
    //         }
    //         else
    //         {
    //             toast.error("network error")
    //         }
    //     }
    //     setloading(false)
    // };
    // const createObject = async (object) => {
    //     setselectedJourney(null)
    //     setshowJourneyForm(true)
    // };

    // const importObjects = async ()=>{
    //     setselectedJourney(null)
    //     setshowJourneyImportForm(true)
    // }
    // const handleSetshowJourneyForm = (openStatus,needsReload) => {
    //   if(needsReload)
    //   fetchData();
    //   setshowJourneyForm(openStatus)
    // };
    // const handleSetshowJourneyImportForm = (openStatus,needsReload) => {
    //     if(needsReload)
    //     fetchData();
    //     setshowJourneyImportForm(openStatus)
    //   };

    // const mapObject= async (object)=>{
    //     setselectedJourney(object)
    //     setshowJourneyMapDialog(true)
    // }
    // const handleSetshowJourneyMapDialog = (openStatus,needsReload)=>{
    //     if(needsReload)
    //         fetchData();
    //     setshowJourneyMapDialog(openStatus)
    // }
    
    
    useEffect(() => {
        advanceSearch();
    }, [pageSize,pageNumber]) 
    
    useEffect(() => {        
        if (journies && journies.data) {                                    
            setRow(journies.data); 
            setPointsTotal(journies.total) 
            setPointsNumberOfPages(journies.last_page)                    
        }else{
            setRow([]);
            setPointsTotal(0)
            setPointsNumberOfPages(0)
        }
    }, [journies])


    
  

    // const columns_old = [
    //     { field: 'id', headerName: t('JOURNEYS.ID') , flex: .08},
    //     { field: 'full_name', headerName:  t('JOURNEYS.USERNAME') , flex: 1.5},
    //     { field: 'phone', headerName:  t('JOURNEYS.PHONE') , flex: 1.5},
    //     { field: 'daily_journey_plans_count', headerName:  t('JOURNEYS.COUNT') , flex: 1.5},
    //     { field: 'today_plan', headerName:  t('JOURNEYS.TODAY_PLAN') , flex: 1.5},                
    //     { field: 'actions',flex: .4,
    //         headerName:  t('JOURNEYS.ACTIONS'),                
    //         renderCell: (param) => (
    //             <DropDownGrid>
             

    //           <MenuItem onClick={() => {window.open(FrontEndDomain + `user_daily_plans?id=${param.row.id}`, '_blank')}}>
    //                         <ListItemIcon sx={{
    //                             display: 'flex',
    //                             justifyContent: 'center',
    //                             margin:'0px 7px 0 0'
    //                         }}>
    //                          <RemoveRedEyeRoundedIcon color="primary" />
    //                         </ListItemIcon>
    //                         {directions != 'rtl' ? "View" : 'عرض'}
    //             </MenuItem>
    //           </DropDownGrid>
    //         ),
    //     },          
    // ]; 
    
    const columns = [
        { field: 'id', headerName: t('JOURNEYS.ID') , flex: .08},
        { field: 'start_date', headerName:  t('JOURNEYS.START_DATE') , flex: 1.5},
        { field: 'end_date', headerName:  t('JOURNEYS.END_DATE') , flex: 1.5},
        { field: 'USERNAME', headerName:  t('JOURNEYS.USERNAME') , flex: 1.5,
            renderCell: (rows) => (
                <Box sx={{display:'flex',alignItems:'center',justifyContent:directions=='rtl'?'end':'start',textAlign:'center',width:'100%'}}>
                    <span>{rows?.row?.user?.username}</span>
                </Box>
             )
        },
        { field: 'journey_type', headerName:  t('JOURNEYS.JOURNEY_TYPE') , flex: 1.5,
            renderCell: (rows) => (
                <Box sx={{display:'flex',alignItems:'center',justifyContent:directions=='rtl'?'end':'start'}}>
                    <span>{rows?.row?.journey_type?.name}</span>
                </Box>
            )
        },
        { field: 'actions',flex: .4,
            headerName:  t('JOURNEYS.ACTIONS'),                
            renderCell: (param) => (
                <DropDownGrid>
             

                <MenuItem onClick={() => {
                    console.log('data for updated users daily plan',param?.row)
                    setSelectedUser(param?.row)
                    setNewForm(true)
                }}>
                    <ListItemIcon sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin:'0px 7px 0 0'
                    }}>
                        <EditIcon style={{ fill: "#111827" }} />
                    </ListItemIcon>
                    {directions != 'rtl' ? "edit" : 'تعديل'}
                </MenuItem>
                <MenuItem onClick={() => {
                    deleteObjectRow(param?.row)
                }}>
                    <ListItemIcon sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin:'0px 7px 0 0'
                    }}>
                        <DeleteIcon  sx={{ color: red[500] }}/>
                    </ListItemIcon>
                    {directions != 'rtl' ? "delete" : 'حذف'}
                </MenuItem>
              </DropDownGrid>
            ),
        },          
    ]; 
    const [rows,setRow] = useState([])    


    const ExportFunction=async () => {
        // const fetchedData = await fetchData('VISITS', 'visits', {
        //     page_size: pointsTotal,
        //     region: region.id,
        //     created_by: user.id,
        //     shop_name: shopName,
        //     creation_date_from: moment(fromToDate[0])?.format('YYYY-MM-DD'),
        //     creation_date_to: moment(fromToDate[1])?.format('YYYY-MM-DD'),
        // })
        // exportToCSV(fetchedData.data, 'visits')
    }
    const deleteObjectRow=async(data)=>{
        if ((await deleteObject('DATA', 'journey_plans', data)) === 200) {
            advanceSearch()
        }
    }
    
    const createFunction=()=>{
       setNewForm(true)
       setSelectedUser({})
    };

    const ViewFunction=()=>{
       setShowViewList(true)
    };

    const handlePageChange = (newPage) => { 
        console.log(newPage+1)       
        setpageNumber(newPage+1)  
    }
    return (
        <>   
        {
           newForm?
           <UserDailyPlanNewForm open={newForm} setOpen={setNewForm}  user={selectedUser} advanceSearch={advanceSearch}/>
           :null
        }
   
           {
            !filterSearchBoxMode?
            <FilterBox 
            // exportFunction={ExportFunction}
            createFunction={createFunction} 
            // viewFunction={ViewFunction} 

            hasExport={false}  
            hasCreate={true}  
            hasMaps={false}  
            hasImport={false}
            hasView={false}
            customeHeight={screenWidthRedux<=1100?'330px':"230px"}
            resultLength={pointsTotal}

            >
             
                    <Autocomplete
                        sx={{ ml: 1,width:screenWidthRedux<=1100?'98%':'45%',mt:1 }}
                            fullWidth
                            options={users}
                            getOptionLabel={(option) => option.username || ""}
                            id="disable-close-on-select"
                            value={selectedUser}
                            onChange={(e, v, r) => { setSelectedUser(v ? v : '') }}
                            renderInput={(params) => (
                                <TextField {...params} label={t('JOURNEYS.USERNAME')} variant="standard" sx={{borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}} />
                        )}
                    />
                    <Box
                    sx={{
                        width:screenWidthRedux<=1100?'99%':'53%',
                        mt:1,
                        ml: 1,
                    }}
                    >
                        <LocalizationProvider dateAdapter={AdapterDayjs} 
                            sx={{
                                display:'flex',
                                alignItems: 'center',
                                width:'100%',

                            }}
                        >
                            <Box
                                sx={{
                                display:'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width:'100%',
                                }}
                            >
                        
                                <DesktopDatePicker
                                label={t('JOURNEYS.FROMDATE')}
                                inputFormat="MM/DD/YYYY"
                                value={startDate}
                                
                                onChange={(date)=>setStartDate(date)}
                                renderInput={(params) => <TextField {...params}  variant="standard" sx={{width:'50%',margin:'0px 4px',borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}} />}
                                />
                                <DesktopDatePicker
                                label={t('JOURNEYS.TODATE')}
                                inputFormat="MM/DD/YYYY"
                                value={EndDate}
                                // minDate={toDate}
                                onChange={(date)=>setEndDate(date)}
                                renderInput={(params) => <TextField {...params}  variant="standard" sx={{width:'50%',margin:'0px 4px',borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}}/>}
                                />
                            </Box>
                        </LocalizationProvider>
                    </Box>

                    <Autocomplete
                        sx={{ ml: 1,width:screenWidthRedux<=1100?'98%':'45%',mt:1 }}
                        // fullWidth
                        options={journey_types}
                        getOptionLabel={(option) => option.name || ""}
                        id="disable-close-on-select"
                        value={selectedJourneysPlan}
                        onChange={(e, v, r) => { setSelectedJourneysPlans(v ? v : ''); }}
                        renderInput={(params) => (
                            <TextField {...params} label={t('JOURNEYS.JOURNEY_TYPE')} variant="standard" sx={{ width:'100%',borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}} />
                        )}
                    />
       
                <Box display="flex" sx={{ 
                  justifyContent:'end',width:'100%'
                 }}>
                   <Box display="flex" >
                            <Button
                                variant="contained"
                                spacing={2} 
                                sx={{ 
                                    flexGrow: 1, 
                                    margin: 1,
                                    backgroundColor:SearchButtonTheme?.search_button_color,
                                    color:SearchButtonTheme?.search_button_text_color,
                                    '&:hover':{
                                        backgroundColor:SearchButtonTheme?.search_button_color+'88',
                                    },
                                    height:FilterButtonHeight,
                                }}
                                className="iconeFilterSearch"
                                onClick={() => advanceSearch()} >
                                {t('GLOBAL.SEARCH_SEARCH_BUTTON')}
                            </Button>
                            <Button variant="contained"
                                spacing={2}
                                sx={{
                                    flexGrow: 1, margin: 1,
                                    // backgroundColor:SearchButtonTheme?.clear_button_color,
                                    color:SearchButtonTheme?.clear_button_text_color,
                                    '&:hover':{
                                        backgroundColor:`${SearchButtonTheme?.clear_button_color}   !important`,
                                    },
                                    height:FilterButtonHeight
                                }}
                                className="iconeFilterClear"
                                onClick={clearPointsAdvanceSearchFields} >
                                {t('GLOBAL.SEARCH_CLEAR_BUTTON')}
                            </Button>
                    </Box>
                </Box>
            
            </FilterBox>:null  
            }
            

            <Box 
             sx={{
                display:'flex',
                justifyContent: 'start',
                alignItems: 'start',
                width:'100%',
             }}
            >
                <Box
                    sx={{
                    width:'100%',
                    }}
                >
                    <Grid
                    rows={rows}
                    columns={columns}
                    rowTotals={pointsTotal}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    pageNumber={pageNumber}
                    setPageNumber={setpageNumber}
                    loading={loading}
                    handlePageChange={handlePageChange}
                    NumberOfPage={pointsNumberOfPages}
                    directions={directions}
                    hasCreate={false}
                    createObject={null}
                    title_en=""
                    title_ar=""
                    gridHeight={{
                        height:rows?.length>5?pageSize==5?400:pageSize==10?650:950:400,
                    }}
                    />  
                </Box>
                {
                    filterSearchBoxMode?
                    <CustomeFilter
                        // exportFunction={ExportFunction}
                        createFunction={createFunction} 
                        // viewFunction={ViewFunction} 
                        hasExport={false}  
                        hasCreate={true}  
                        hasMaps={false}  
                        hasImport={false}
                        hasView={false}
                        resultLength={pointsTotal}
                    //    customeHeight={screenWidth<=1100?'460px':"350px"}
                    >
                        <Box
                            sx={{
                                width:'100%',
                                height:'400px',
                                minHeight:'400px',
                                maxHeight:'400px',
                                overflowY:'scroll',
                                overflowX:'hidden',
                                padding:'10px',
                                marginTop:'10px',
                                paddingTop:'20px',
                                // boxShadow:'0 0 7px -5px #000 inset'
                            }}
                        >
                           <Autocomplete
                                sx={{ ml: 1,width:screenWidthRedux<=1100?'100%':'100%',MarginTop:'10px !important',marginBottom:'15px !important' }}
                                    fullWidth
                                    options={users}
                                    getOptionLabel={(option) => option.username || ""}
                                    id="disable-close-on-select"
                                    value={selectedUser}
                                    onChange={(e, v, r) => { setSelectedUser(v ? v : '') }}
                                    renderInput={(params) => (
                                        <TextField {...params} placeholder={t('JOURNEYS.USERNAME')} variant="standard" sx={{borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}} />
                                )}
                            />
                            <Box
                                sx={{
                                    width:screenWidthRedux<=1100?'100%':'100%',
                                    MarginTop:'10px !important',marginBottom:'15px !important',
                                    ml: 1,
                                }}
                            >
                                <LocalizationProvider dateAdapter={AdapterDayjs} 
                                    sx={{
                                        display:'flex',
                                        alignItems: 'center',
                                        width:'100%',

                                    }}
                                >
                                    <Box
                                        sx={{
                                        display:'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        flexDirection: 'column',
                                        width:'100%',
                                        }}
                                    >
                                
                                        <DesktopDatePicker
                                        placeholder={t('JOURNEYS.FROMDATE')}
                                        inputFormat="MM/DD/YYYY"
                                        value={startDate}
                                        
                                        onChange={(date)=>setStartDate(date)}
                                        renderInput={(params) => <TextField
                                             {...params} 
                                             inputProps={{
                                                ...params.inputProps,
                                                placeholder:t('JOURNEYS.FROMDATE')
                                             }}
                                             variant="standard" sx={{width:'100%',MarginTop:'10px !important',marginBottom:'15px !important',borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}} />}
                                        />
                                        <DesktopDatePicker
                                        placeholder={t('JOURNEYS.TODATE')}
                                        inputFormat="MM/DD/YYYY"
                                        value={EndDate}
                                        // minDate={toDate}
                                        onChange={(date)=>setEndDate(date)}
                                        renderInput={(params) => <TextField 
                                            {...params}
                                            inputProps={{
                                                ...params.inputProps,
                                                placeholder:t('JOURNEYS.TODATE')
                                             }}
                                            variant="standard" sx={{width:'100%',MarginTop:'10px !important',marginBottom:'15px !important',borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}}/>}
                                        />
                                    </Box>
                                </LocalizationProvider>
                            </Box>

                            <Autocomplete
                                sx={{ ml: 1,width:screenWidthRedux<=1100?'100%':'100%',MarginTop:'10px !important',marginBottom:'15px !important' }}
                                // fullWidth
                                options={journey_types}
                                getOptionLabel={(option) => option.name || ""}
                                id="disable-close-on-select"
                                value={selectedJourneysPlan}
                                onChange={(e, v, r) => { setSelectedJourneysPlans(v ? v : ''); }}
                                renderInput={(params) => (
                                    <TextField {...params} placeholder={t('JOURNEYS.JOURNEY_TYPE')} variant="standard" sx={{ width:'100%',borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}} />
                                )}
                            />
                        </Box>
                        <Box sx={{display:'flex',justifyContent: 'end',alignItems: 'center',width:'100%'}}>
                            <Box display="flex" >
                                    <Button
                                        variant="contained"
                                        // spacing={2} 
                                        sx={{ 
                                            // flexGrow: 1,
                                            margin: 1,
                                            width:'80px !important',
                                            minWidth:'80px !important',
                                            maxWidth:'80px !important',
                                            backgroundColor:SearchButtonTheme?.search_button_color,color:SearchButtonTheme?.search_button_text_color,
                                            '&:hover':{
                                                backgroundColor:SearchButtonTheme?.search_button_color+'88',
                                            },
                                            height:FilterButtonHeight-10
                                        }}
                                        className="iconeFilterSearch"
                                        onClick={() => advanceSearch("SEARCH")} >
                                        {t('GLOBAL.SEARCH_SEARCH_BUTTON')}
                                    </Button>
                                    <Button variant="outlined"
                                        // spacing={2}
                                        sx={{
                                            width:'50% !important',
                                                // flexGrow: 1, 
                                                minWidth:'80px !important',
                                                maxWidth:'80px !important',
                                                margin: 1,
                                                // backgroundColor:SearchButtonTheme?.clear_button_color,
                                                color:SearchButtonTheme?.clear_button_text_color,
                                            '&:hover':{
                                                backgroundColor:`${SearchButtonTheme?.clear_button_color}   !important`,
                                            },
                                            height:FilterButtonHeight-10
                                        }}
                                        className="iconeFilterClear"
                                        color="secondary"
                                        onClick={clearPointsAdvanceSearchFields} >
                                        {t('GLOBAL.SEARCH_CLEAR_BUTTON')}
                                    </Button>
                            </Box>
                        </Box>
                    </CustomeFilter>:null
                }
            </Box>


            {/* <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">Users daily journey plans List </div>
                            <div className="card-body">
                            <table>
                                <thead>
                                    <tr>
                                        <th>1</th>
                                        <th>Name</th>
                                        <th>phone</th>
                                        <th>Total plans</th>
                                        <th>today plan</th>
                                        <th>view</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {   
                                    state?.journeys?.data ? 
                                        state?.journeys?.data?.map((object) => (
                                                <tr key={object?.id}>
                                                    <td>{object?.id}</td>
                                                    <td>{object?.full_name}</td>
                                                    <td>{object?.phone}</td>
                                                    <td>{object?.daily_journey_plans_count}</td>
                                                    <td>{object?.today_plan}</td>
                                                    
                                                    <td><Button onClick={()=>{
                                                        
                                                        window.open(FrontEndDomain + `user_daily_plans?id=${object?.id}`, '_blank')
                                                    }}>View</Button></td>
                                                    
                                                </tr>
                                        )) : "Loading..."
                                }
                                </tbody>
                            </table>
                                <div>
                                    <Pagination
                                        activePage={state?.journeys?.current_page ? state?.journeys?.current_page : 0}
                                        itemsCountPerPage={state?.journeys?.per_page ? state?.journeys?.per_page : 0 }
                                        totalItemsCount={state?.journeys?.total ? state?.journeys?.total : 0}
                                        onChange={(pageNumber) => {
                                            fetchData(pageNumber)
                                        }}
                                        pageRangeDisplayed={8}
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        firstPageText="First Page"
                                        lastPageText="Last Lage"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}

export default Journeys;


