import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Pane, Dialog } from "evergreen-ui";
import axios from "axios";
// import Select from "react-select";
import Host from "../../../assets/js/Host";
import DialogForm from "../Share/DialogForm";
import { Form, SaveButton, CancelButton } from "./StyledComponents";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import FooterBottons from "../Share/FooterBottons";
import { withStyles } from "@mui/material/styles";
import CustomeCheckBox from "../Share/CustomeCheckBox";
import { useTranslation } from "react-i18next";
import { useGlobalContext } from "../../../context";
import swal from "sweetalert";
import { Box, Typography, Select, Button } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import Grid from "../Share/Grid";
import FilterBox from "../Share/FilterBox";
import moment from "moment";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { AiFillCloseCircle } from "react-icons/ai";
import { IconButton } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import helpers from "../../../assets/js/helper";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import EditIcon from "@mui/icons-material/Edit";
import { Tooltip } from "@mui/material";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filter = createFilterOptions();
const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar";

const label = { inputProps: { "aria-label": "Checkbox demo" } };
export default function EditUserShift(props) {
    const {t, i18n} = useTranslation('common');
    const token=cookies.get('token')
    const {attendance_type_shifts, visitFollowups ,users,Shifts,attendance_typs,groups,notification_types, pointsStatuses, pointStatus, setPointStatus, exportToCSV, points, district, region, districts, regions, ownerName, shopName, clearPointsAdvanceSearchFields, setRegion, setDistrict, setShopName, setOwnerName, fetchData, deleteObject } = useGlobalContext()
    const SearchButtonTheme=useSelector(state=> state.settingsData.SearchButtonTheme);
    const FilterButtonHeight= useSelector(state => state.settingsData.FilterButtonHeight)
    const [loading, setloading] = useState(false);
    const MainTheme =useSelector(state=> state.settingsData.MainTheme);
    const directions =useSelector(state=> state.settingsData.directions);

    const {search_button_color,clear_button_color,search_button_text_color,clear_button_text_color} =SearchButtonTheme
    const [listOfUserShiftData, setListOfUserShiftData] = useState({
        user_id: 0,
        attendance_type_id: 0,
        shifts_ids: null,
        activation_date: new Date(),
        singleObject: {},
    });

    const getFormatMinutesToTime=(min)=>{
        let hourse=Math.floor(min/60)
        let minutes=Math.floor(min % 60)

        return hourse+':'+minutes
    }
    const handleCheckBoxChanged=(value)=>{
       if(listOfUserShiftData?.attendance_type_id==2){
            setListOfUserShiftData({
                ...listOfUserShiftData,
                shifts_ids:[...value]
            })
        }
        else{
            setListOfUserShiftData({
                ...listOfUserShiftData,
                shifts_ids:value
            })
        }
    }

    const handleArrayBeforStore=()=>{
        console.log('asdasdasdasdas',listOfUserShiftData?.shifts_ids)
      if(listOfUserShiftData&&listOfUserShiftData?.attendance_type_id!=2){
           if(listOfUserShiftData?.shifts_ids)
             return [listOfUserShiftData?.shifts_ids?.id]
             else return []
      }
       else {
          return listOfUserShiftData&&listOfUserShiftData?.shifts_ids?.length>0?listOfUserShiftData?.shifts_ids?.map((itm)=>itm?.id):[]
       }
    }

    const submitForm = async () => {
        
        try {
              if(!checkActivationDateUpdate()){
                 toast.warning(directions=='rtl'?'لا يمكنك تعديل  هذا الاسناد ':"you can't edit this assignment")
                 return
              }
        
            if (listOfUserShiftData?.user_id&&props?.userShift?.id) {                
                let formdata = new FormData();

                // formdata.append("roles", JSON.stringify(userRolesSelected));
               
                const resourcesResult = await axios({
                    url: Host + "attendance_type_shift_users/"+props?.userShift?.id,
                    method: "put",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                       
                        accept: "application/json",
                        // Accept: "application/json",
                        // "Content-Type": "multipart/form-data",
                        'X-localization': language,
                    },
                    data:{
                        ...listOfUserShiftData,
                        activation_date:moment(listOfUserShiftData?.activation_date).format('YYYY-MM-DD HH:mm:ss'),
                        shifts_ids:handleArrayBeforStore()
                    }
                    
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("updated ")
                        props.setShowEditUserShift(false)
                        props.advancedSearch()
                    } else if (resourcesResult.data.status === false) {
                        props.setShowUseAccessrGroup(true,false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setShowUseAccessrGroup(true,false)
                }
                setloading(false)
            }
            else{
                swal({
                    title: `${t('SWEATALERT_MESSAGES.WARNNING_MESSAGE')}`,
                    text: `${t('SWEATALERT_MESSAGES.USER_ID_NOT_FOUND')}`,
                    icon: "warning",
                    button: `${t('SWEATALERT_MESSAGES.WARRNING_BUTTON')}`,
                });
                return;
            }
           
        } catch (error) {
            props.setShowUseAccessrGroup(true,false)
            const resourcesResult=error.response            
            if (resourcesResult && resourcesResult.status === 400) {
                if(typeof resourcesResult.data.data === "object")
                {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key)=>{
                    
                        resourcesResult.data.data[key].map((message)=>{
                            toast.error(message)
                        })
                    })
                }
                else{
                    
                    toast.error(resourcesResult.data.data)
                }
                
                
            }
            else if(resourcesResult && resourcesResult.status === 401) {
             
                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else
            {
              
                toast.error("network error")
            }
        }
        
    };

    useEffect(()=>{
      if(props?.userShift){
         console.log('asdsadweqeqw=>',props?.userShift)
         setListOfUserShiftData({
            ...listOfUserShiftData,
            user_id: props.userShift?.user?.id,
            attendance_type_id:props?.userShift?.attendance_type?.id,
            shifts_ids:getDataofSelectedValues(props?.userShift?.shift,props?.userShift?.attendance_type?.id),
            activation_date:props?.userShift?.activation_date?new Date(props?.userShift?.activation_date):null,
         })
      }
    },[props?.userShift])

    const getDataofSelectedValues=(data,type)=>{
        if(typeof(data)=='array'&&type==2){
            let arr=[];
            data&&data?.length>0&&data?.map((itm)=>{
                arr.push(itm)
            })
            return arr
        }else if(typeof(data)!='array'&&type==2){  
            return [data]
        }else return data
    }

    const checkActivationDateUpdate=()=>{
        var oldDate = props?.userShift?.activation_date?new Date(props?.userShift?.activation_date):null;
        var newDate = new Date()

        if(!oldDate&&newDate){
              return true;
        }else if(newDate&&oldDate){
            var same = oldDate.getTime() <= newDate.getTime();
            if(same){
               return false;
            }else return true;
        }

    }

  return (
    <DialogForm
    open={props.showEditUserShift}
    close={props.setShowEditUserShift}
    CustomeWidth='98%'
    CustomeHeight="fit-content"
    loaderActive={loading}
    headerChildren={(
        // <h4 style={{...styles}}></h4>
        <>
        <IconButton aria-label={"Close"} onClick={() => { props.setShowEditUserShift(false) }} style={{ position: 'absolute', top: '0px', left: '6px', fontSize: '37px', }}>
            <AiFillCloseCircle style={{ color: MainTheme?.paperTextColor,outline:'none !important' ,border:'none !important' }} />
        </IconButton>
        <Typography component={'h1'} variant={'h4'} style={{marginTop:'8px',marginBottom:'10px', color: MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor, width: '100%', textAlign: 'center' }}>
           {t("USERS.USER_ASSIGN_SHIFT_TYPE")}
        </Typography>
      </>
   )}
   hasHeader={true}
>
    <Box
       sx={{
        display:'flex',
        justifyContent:'start',
        alignItems:'center',
        // flexDirection:'column',
        flexWrap:'wrap',
        width:'100%',
        '& .MuiOutlinedInput-root':{
            borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
            color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
        },
        '& .MuiInputLabel-root':{
            // backgroundColor:`${MainTheme?.paperTextColor+'22'} !important`,
            color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
        },
        '& .MuiFormControl-root':{
          // border:`1px solid ${MainTheme?.paperTextColor} !important`,
          color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
          },
          '&:hover fieldset': {
            borderColor: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
          },
          '&.Mui-focused fieldset': {
            borderColor: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.secondaryColor,
          },
        },
        '& .MuiOutlinedInput':{
          // borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            // border: "0 none",
         },
        
         '& .muirtl-2jbvvl-MuiButtonBase-root-MuiButton-root':{
            border:` 1px solid ${clear_button_color} !important`,
            outlineColor:`${clear_button_color} !important`,
         },
         '& .muirtl-1a1fmpi-MuiInputBase-root-MuiInput-root, & .muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root':{
            paddingLeft:"5px"
         },
         '& .muirtl-1a1fmpi-MuiInputBase-root-MuiInput-root:before,& .muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root:before':{
          borderColor:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,
          outlineColor:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,
        },
        '& .muirtl-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root:before,& .muiltr-1ptx2yq-MuiInputBase-root-MuiInput-root:before,& .muiltr-1a1fmpi-MuiInputBase-root-MuiInput-root:before,& .muiltr-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root:before,& .muirtl-1a1fmpi-MuiInputBase-root-MuiInput-root:hover:before, &.muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root:hover:before':{
          borderColor:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,
          outlineColor:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,
        },
        '& .muirtl-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select':{
          display:'flex',
          justifyContent: 'start',
        },
        '&.muirtl-iqd1hh-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon,& .muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root,& .muirtl-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root,& .muiltr-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root,& svg,& span,& p,& .muiltr-1ptx2yq-MuiInputBase-root-MuiInput-root':{
           color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor+' !important',
        },
        '& .muirtl-iqd1hh-MuiButtonBase-root-MuiChip-root,& .muiltr-iqd1hh-MuiButtonBase-root-MuiChip-root':{
          backgroundColor:MainTheme?.secondaryColor+'44 !important'
        },
        '& .muiltr-iqd1hh-MuiButtonBase-root-MuiChip-root svg,& .muirtl-iqd1hh-MuiButtonBase-root-MuiChip-root svg,& .muiltr-wpkdf9-MuiModal-root-MuiDialog-root svg':{
          color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor+' !important',
        },
    
    
    
        '& .muirtl-1yf1g2m::before,& .muiltr-1yf1g2m::before,& .muiltr-1vv4lmi::before,& .muirtl-1vv4lmi::before,& .muirtl-1cayd89::before,& .muiltr-1cayd89::before':{
          borderColor:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,
          outlineColor:`${MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} !important`,
        },
        '& .muirtl-khgl9y .MuiFormControlLabel-label,& .muiltr-khgl9y .MuiFormControlLabel-label,& .muiltr-19d186v-MuiFormControlLabel-root .MuiFormControlLabel-label , & .muirtl-19d186v-MuiFormControlLabel-root .MuiFormControlLabel-label ,& input,& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.muiltr-yi9xwg .MuiAutocomplete-inputRoot,& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.muirtl-yi9xwg .MuiAutocomplete-inputRoot ,& .muiltr-1vv4lmi,& .muirtl-1vv4lmi,& .muirtl-o1tw8u .MuiFormControlLabel-label,& .muiltr-o1tw8u .MuiFormControlLabel-label,& .muirtl-3m5ia .MuiInput-root .MuiInput-input,& .muiltr-3m5ia .MuiInput-root .MuiInput-input,& span':{
           color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor+' !important',
        },
        
        
     
    
       }}
    >
      <Box  sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',width:'47%'}}>
        <Box
            sx={{
            width:'100%',
            mt:2,
            display: 'flex'            
            }}
        >
            <FormControl fullWidth variant="standard">
            <InputLabel id="demo-simple-select-label">
                {t("USERS.FORM_ATTENDANCE_TYPE")}
            </InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={listOfUserShiftData?.attendance_type_id}
                label={t("USERS.FORM_ATTENDANCE_TYPE")}
                onChange={(e) => {
                if (e.target.value != 2)
                    setListOfUserShiftData({
                    ...listOfUserShiftData,
                    attendance_type_id: e.target.value,
                    shifts_ids: null,
                    });
                else
                    setListOfUserShiftData({
                    ...listOfUserShiftData,
                    attendance_type_id: e.target.value,
                    shifts_ids: [],
                    });
                }}
            >
                {attendance_typs &&
                attendance_typs?.length > 0 &&
                attendance_typs?.map((itm) => (
                    <MenuItem value={itm?.id}>{itm?.name}</MenuItem>
                ))}
            </Select>
            </FormControl>
        </Box>
        <Autocomplete
            id="checkboxes-tags-demo"
            options={Shifts}
            value={
            listOfUserShiftData?.shifts_ids
                ? listOfUserShiftData?.shifts_ids
                : null
            }
            disableCloseOnSelect
            multiple={listOfUserShiftData?.attendance_type_id != 2 ? false : true}
            getOptionLabel={(option) =>
            ` ${getFormatMinutesToTime(
                option?.start_minutes
            )} -- ${getFormatMinutesToTime(option?.end_minutes)}`
            }
            renderOption={(props, option, { selected }) => (
            <li {...props}>
                <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
                />
                {` ${getFormatMinutesToTime(
                option?.start_minutes
                )} -- ${getFormatMinutesToTime(option?.end_minutes)} --${Math.floor(
                option?.end_minutes / 60
                )}H`}
            </li>
            )}
            onChange={(event, newValue) => {
            handleCheckBoxChanged(newValue);
            console.log("selectedCheckbox", newValue);
            console.log("selectedCheckbox", event.target.value);
            }}
            sx={{
            width: {
                width:'100%',

            },
            mt:2,
            //   flex:1,
            }}
            renderInput={(params) => (
            <TextField
                sx={{ width: "100%" }}
                {...params}
                label={`${t("USERS.FORM_SHIFTS")}`}
                variant="standard"
            />
            )}
        />
      </Box>
      <Box  sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',width:'47%'}}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
            label={t("USERS.FORM_ACTIVATION_TIME")}
            value={listOfUserShiftData?.activation_date}
            inputFormat="YYYY/MM/DD HH:mm:ss"
            ampm={false}
            onChange={(date) => {
                console.log('sdasdasdjjjfjjj',date)
                 setListOfUserShiftData({
                    ...listOfUserShiftData,
                    activation_date:new Date(date)
                 })
            }}
            renderInput={(params) => (
                <TextField
                {...params}
                variant="standard"
                sx={{
                    width: {
                        width:'100%'
                    },
                    mt:-6,
                    ml:4,

                }}
                />
            )}
            />
        </LocalizationProvider>
      </Box>
    </Box>

      <Box sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
        <Button
          variant="contained"
          disabled={loading}
          spacing={2}
          sx={{
            margin: 1,
            backgroundColor: SearchButtonTheme?.save_button_color,
            color: SearchButtonTheme?.save_button_text_color,
            "&:hover": {
              backgroundColor: SearchButtonTheme?.save_button_color + "88",
            },
            height: `${FilterButtonHeight}px`,
          }}
          onClick={() => {
            submitForm();
          }}
        >
          {t("USERS.FORMVIEW_SEARCH_SAVE_BUTTON")}
        </Button>
      </Box>
</DialogForm>
  );
}
