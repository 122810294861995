
import React, { useEffect, useState } from 'react';
// import Pagination from "react-js-pagination";
import * as XLSX from 'xlsx';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import TelegramIcon from '@mui/icons-material/Telegram'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import moment from 'moment';
import { toast } from 'react-toastify';
import CircularIndeterminate from '../GeneralComponents/CirularProgress'
import StyledDataGrid from '../GeneralComponents/StyledDataGrid'
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { useGlobalContext } from '../../../context'
import { styled, useTheme ,alpha} from '@mui/material/styles';
import "../../../assets/css/DG.css"
import "../../../assets/css/AccordionStyle.css"
import BasicDatePicker from '../GeneralComponents/BasicDatePicker';
import Cookies from "universal-cookie";
import axios from "axios";
import Host from "../../../assets/js/Host";
import Grid from '../Share/Grid'
import {useSelector,useDispatch} from 'react-redux'
import {useTranslation} from "react-i18next";
import FilterBox from '../Share/FilterBox';
import FlareIcon from '@mui/icons-material/Flare';
import { width } from '@mui/system';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import * as GiIcone from 'react-icons/gi';
import * as FaIcone from 'react-icons/fa';
import EngineeringIcon from '@mui/icons-material/Engineering';
import {CustomeActiveStatusCheck,CustomeActiveStatusCheckV2} from './StyledComponent'
import SummeryCards from './SummeryCards';
import {useParams,useLocation} from 'react-router-dom'
import {setAttendanceCardView} from '../../reduxStore/SettingsReducer'
import { RiDatabase2Fill } from 'react-icons/ri';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import helpers from '../../../assets/js/helper'
import BasicDatePickerV2 from '../GeneralComponents/BasicDatePickerV2';
import SearchFieldV2 from '../GeneralComponents/SearchFieldV2'
import CustomeFilter from '../Share/CustomeFilter'
import CircularProgress from '@mui/material/CircularProgress';
import userImage from '../../../assets/img/userImage.png'
const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"
const UserImage  = userImage


// const cookies = new Cookies();
// const token = cookies.get("token")
// const language = cookies.get("language")?cookies.get("language"):"ar"

const CustomeSummeryBox=styled(Box)(({theme,isFullWidth,MainTheme})=>({
    width:isFullWidth?'100%':'36%',
    display:'flex',
    flexDirection:'column',
    alignItems:'center',
    // backgroundColor:'#fff',
    // padding:'8px',
    top:'12.4px',
    borderRadius:'5px',
    margin:isFullWidth?'2px 0px 25px 0':'16px  22px 16px 3px',
    marginTop:isFullWidth?0:8.5,
    position:'relative',
    minHeight:'55px',
    height:'fit-content',
    transition:'height 1s ease',
    overflow:'hidden',
    // padding:'2px 0',
    '&:hover .CustomeSummeryDropDown':{
        // height:'230px',
    },
    boxShadow:`0px 0px 7px -2px  #000`,
    backgroundColor:'#fff',
    // top:'10px',
    // outline:'5px solid rgb(239 239 239)',
    // '&:hover .CustomeSummeryBoxRight':{
    //     display:'block',
    //     height:'200px',
    // }

}))

const CustomeSummeryBoxRight=styled(Box)(({theme})=>({
    width:'80%',
    display:'flex',
    alignItems:'start',
    flexDirection:'column',
    justifyContent: 'center',
    // backgroundColor:'#ffffff',
    // borderRadius:'10px',
    // position:'relative',
    // left:'-40px',
    padding: '3px 0',
    // display:'none',
    overflow:'hidden',
    transition:'display 1s ease-in-out',
    '& .subItem1':{
        display:'flex',
        justifyContent:'start',
        alignItems:'start',
        // flexDirection:'column-reverse',
        position:'relative',
        top:'-6px',
        left:'0px',
        fontWeight:'700',
        fontSize:'20px',
    },

    //     // border:'2px solid rgb(239 239 239)',
    //     width:'90%',
    //     padding:"5px 10px",
    //     margin:'3px 0',
    //     borderRadius:'8px',

    //     "& p":{
    //         margin:'0 5px !important',
    //         color:'#aba1a1 !important',
    //         fontSize:13
    //     },
    //     '& .result':{
    //         fontSize:'22px !important',
    //         fontWeight:'900 !important',
    //         color:'#000000 !important',
    //     }
    //  },
    //  '& .subItem:nth-child(3)':{
    //     // backgroundColor:'#61D3D3',
    //     '& p':{
    //         // color:'#fff !important',
    //     }
    //  },
   
}))
const CustomeSummeryBoxLeft=styled(Box)(({theme})=>({
    width:'20%',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    flexDirection:'column',
    overflow:'hidden',
    '& .userImage':{
        width:50,
        height:50,
        borderRadius:"50%",
        backgroundColor:'rgba(0,0,0,0.1)',
        objectFit:'cover',
        backgroundSize:'cover',
        // marginLeft:'25px'
    },
    '& p':{
        color:'#fff',
    }
}))

// const CustomeActiveStatusCheck=styled(Box)(({theme,color})=>({
//     position:'absolute',
//     top:'23px',
//     right:'24px',
//     width:'8px',
//     height:'8px',
//     backgroundColor:color,
//     borderRadius:'50%',
//     boxShadow:`1px 1px 7px 7px ${color}`,
//     display: 'flex',
//     justifyContent: 'center', alignItems: 'center', 
// }))

const CustomeSummeryDropDown=styled(List)(({theme,color,openSummery,isScreenSized})=>({
        width: '100%',
        maxWidth: '100%',
        backgroundColor: '#ffffff',
        // borderRadius:'10px',
        // marginTop:'10px',
        height:openSummery?isScreenSized?'213px':'181px':'0',
        transition:'height  0.3s ease-in',
        padding:isScreenSized?'0 8px':'0',
        // paddingBottom:'10px',
        paddingLeft:'4px',
        overflow:'hidden',
        // borderBottomLeftRadius:'10px',
        // borderBottomRightRadius:'10px',
        '& .muirtl-uls1yk-MuiDivider-root':{
            marginLeft: "20px !important",
            // paddingRight: '70px !important',
            width:' 91%'
        }
}))





function AttendanceReport() {
    const {t, i18n} = useTranslation('common');
    const token = cookies.get("token")
    const {state} =useLocation()
    // const {search}=useParams()

    const urlParams = new URLSearchParams(window.location.search);
    // const search = urlParams.get('search');
    const userID=urlParams.get('userID');
    // const {search}=useParams()
    const dispatch=useDispatch()
    const [openSummery,setOpenSummery]=useState(false);
    const [pageSize, setPageSize] = useState(5);
    const [pageNumber, setpageNumber] = useState(1);
    const [pointsTotal, setPointsTotal] = useState(0)
    const [pointsNumberOfPages, setPointsNumberOfPages] = useState(0)
    const [screenWidth,setScreenWidth]=useState(window.innerWidth)
    const [metaData, setMetaData] = useState({
        road_time: "00:00:00",
        visits_time: "00:00:00",
        total_work_time: "00:00:00",
        working_hours_per_day: 0,
        working_hours_per_day_color: "#DC3545",
        telegram_channel_id: "",
        telegram_token: "",
        user:{}
    })
    const SideMenuOpen = useSelector(state => state.settingsData.SideMenuOpen)
    const attendanceCardView=useSelector(state=>state.settingsData.attendanceCardView)
    const directions= useSelector(state => state.settingsData.directions)
    const GridTheme =useSelector(state=>state.settingsData.GridTheme)
    const MainTheme =useSelector(state=>state.settingsData.MainTheme)
    const screenWidthRedux=useSelector(state=>state.settingsData.screenWidth)
    const FilterButtonHeight= useSelector(state => state.settingsData.FilterButtonHeight)
    const SearchButtonTheme=useSelector(state=> state.settingsData.SearchButtonTheme);
    const filterSearchBoxMode = useSelector(state=> state.settingsData.filterSearchBoxMode)
    
    const { loading, setLoading, fromToDate, exportToCSV, user, users, setUser, attendanceCalculations, clearPointsAdvanceSearchFields, fetchData, exportAttendanceToCSV, setFromToDate  } = useGlobalContext()

    const [accExpansion, setAccExpansion] = useState(false)
    const [accExpansionMeta, setAccExpansionMeta] = useState(false)

    const handleExpansion = (expansion) => setAccExpansion(expansion)
    const handleExpansionMeta = (expansionMeta) => setAccExpansionMeta(expansionMeta)

    const handlePageChange = (newPage) => {
        setpageNumber(newPage + 1)
    }

    const advanceSearch = async (type = "BROWSING",id,date1,date2) => {
        console.log("tracking", 6);
        console.log("tracking user", user.id);
        console.log("user......");
        if( user?.id){
            const fetchedData = await fetchData('ATTENDANCE_CALCULATIONS', 'attendance_calculations_report', {
                page: type === "SEARCH" ? 1 : pageNumber,
                page_size: pageSize,
                created_by:user?.id,
                creation_date_from:date1?moment(date1)?.format('YYYY-MM-DD'): moment(fromToDate[0])?.format('YYYY-MM-DD'),
                creation_date_to:date2?moment(date2)?.format('YYYY-MM-DD'): moment(fromToDate[1])?.format('YYYY-MM-DD'),
            })
            if(!fetchedData||!fetchedData?.data||fetchedData?.data?.data?.length==0){
                toast.warning(directions=='rtl'?'لا توجد بيانات':'theres no data!')
            }
            return fetchedData
        }else if(id){
            const fetchedData = await fetchData('ATTENDANCE_CALCULATIONS', 'attendance_calculations_report', {
                page: type === "SEARCH" ? 1 : pageNumber,
                page_size: pageSize,
                created_by:id,
                creation_date_from:date1?moment(date1)?.format('YYYY-MM-DD'): moment(fromToDate[0])?.format('YYYY-MM-DD'),
                creation_date_to:date2?moment(date2)?.format('YYYY-MM-DD'): moment(fromToDate[1])?.format('YYYY-MM-DD'),
            })
            if(!fetchedData||!fetchedData?.data||fetchedData?.data?.data?.length==0){
                toast.warning(directions=='rtl'?'لا توجد بيانات':'theres no data!')
            }
            return fetchedData
        }
    }
    useEffect(()=>{
        // alert('cardadasdas',search)
        if(state&&state?.dataLis&&state?.dataLis?.other){
            // alert('cardadasdas',userID)
           console.log('cardadasdas',state)
            // console.log('cardadasdas',statusNavigate)
            // setOpenSummery(true)
            setMetaData({
                road_time:state?.dataLis?.other?.road_time,
                visits_time:state?.dataLis?.other?.visits_time,
                total_work_time:state?.dataLis?.other?.total_work_time,
                working_hours_per_day: state?.dataLis?.other?.working_hours_per_day,
                working_hours_per_day_color:state?.dataLis?.other?.working_hours_per_day_color,
                telegram_channel_id: state?.dataLis?.other?.telegram_channel_id,
                telegram_token:state?.dataLis?.other?.telegram_token,
                user:state?.dataLis?.other?.user
            })
           
            let date_shiftWeek = new Date();
            date_shiftWeek.setDate(date_shiftWeek.getDate()-30);

            // let data1=new Date(date_shiftWeek);
            // let date2=new Date()
            // console.log(data1,date2)
            setFromToDate([state?.dataLis?.from, fromToDate[1]])
            setFromToDate([fromToDate[0], state?.dataLis?.to])
            console.log('data__1=>',moment(state?.dataLis?.from)?.format('YYYY-MM-DD'))
            console.log('data__2=>',moment(state?.dataLis?.to)?.format('YYYY-MM-DD'))


            setUser(state?.dataLis?.other?.user?.id)
            advanceSearch('SEARCH',state?.dataLis?.other?.user?.id,state?.dataLis?.from,state?.dataLis?.to)

          
        }else{
            dispatch(setAttendanceCardView([]))

        }
    },[state])

    useEffect(()=>{
        window.addEventListener('resize',()=>{
            setScreenWidth(window.innerWidth)
        })
    },[])


    useEffect(() => {
        fetchData('USERS', 'users_list')
        advanceSearch()
    }, [pageSize, pageNumber])

    //Reformatting points object once points are loaded
    useEffect(() => {

        if (attendanceCalculations && attendanceCalculations?.data && attendanceCalculations?.data?.data?.length > 0) {
            const reformatVisits = attendanceCalculations?.data?.data.map((rowVisit) => {
                return {
                    ...rowVisit,
                }
            })
            setRows(reformatVisits);
            setMetaData({
                ...metaData,
                ...attendanceCalculations?.data?.meta_data,
            })
            setPointsTotal(attendanceCalculations.total)
            setPointsNumberOfPages(attendanceCalculations.last_page)
        }
        else {
            setRows([]);
            setMetaData({
                road_time: "00:00:00",
                visits_time: "00:00:00",
                total_work_time: "00:00:00",
                working_hours_per_day: 0,
                working_hours_per_day_color: "#DC3545"
            })
            setPointsTotal(0)
            setPointsNumberOfPages(0)
        }
    }, [attendanceCalculations])


    const [rows, setRows] = useState([])
    const columns = [
        { field: 'id',flex:0.1, headerName: t('ATTENDANCE_REPORT.ID') },
        {
            field: 'road_time', headerName: t('ATTENDANCE_REPORT.ROAD_TIME'),minWidth:150, flex:0.5,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} >
                    {rows?.row?.attendance_calculation?.road_time}
                </div>
            )
        },
        {
            field: 'start_date',flex:0.1, headerName: t('ATTENDANCE_REPORT.START_DATE'),minWidth:150, flex:0.5,
            renderCell: (rows) => {
                let splitedDate=rows.row.start_date?rows.row.start_date?.split(' '):rows.row.start_date
               return <div style={{ width: '100%' }} >
                    {
                        splitedDate&&splitedDate?.length==2?
                           <>
                             {splitedDate[0]}<br/>
                             {splitedDate[1]}
                           </>
                        :rows.row.start_date
                    }
                </div>
            }
        },
        {
            field: 'end_date', headerName: t('ATTENDANCE_REPORT.END_DATE'),minWidth:150, flex:0.7,
            renderCell: (rows) => {
                let splitedDate=rows.row.end_date?rows.row.end_date?.split(' '):rows.row.end_date

               return <div style={{ width: '100%' }} >
                
                    {
                    
                    splitedDate&&splitedDate?.length==2?
                    <>
                        {splitedDate[0]}<br/>
                        {splitedDate[1]}
                    </>:rows.row.end_date}
                </div>
            }
        },
        {
            field: 'visit_total_time', headerName: t('ATTENDANCE_REPORT.VISIT_TIME'),minWidth:150, flex:0.5,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} >
                    {rows?.row?.attendance_calculation?.visit_total_time}
                </div>
            )
        },
        {
            field: 'road_time_calculated', headerName: t('ATTENDANCE_REPORT.ROAD_CALC'),minWidth:150, flex:0.5,
            renderCell: (rows) => (
                rows?.row?.attendance_calculation?.is_valid === 1 ?
                rows?.row?.attendance_calculation?.override_road_time === 1 ?
                    <div style={{ width: '100%' }} >

                        {rows?.row?.attendance_calculation?.road_time}
                    </div>
                    :
                    <div style={{ width: '100%' }} >

                        {rows?.row?.attendance_calculation?.road_time_calculated}
                    </div>
                    : <div style={{ width: '100%' }} >
                    00:00:00
                     </div>
            )
        },
        {
            field: 'visit_total_time_calculated', headerName: t('ATTENDANCE_REPORT.VISIT_CALC'),minWidth:150, flex:0.5,
            renderCell: (rows) => (
                rows?.row?.attendance_calculation?.is_valid === 1 ?
                rows?.row?.attendance_calculation?.override_visit_time === 1 ?
                    <div style={{ width: '100%' }} >
                        {rows?.row?.attendance_calculation?.visit_total_time}
                    </div>
                    :
                    <div style={{ width: '100%' }} >
                        {rows?.row?.attendance_calculation?.visit_total_time_calculated}
                    </div>
                    :
                    <div style={{ width: '100%' }} >
                       00:00:00
                    </div>
            )
        },
        {
            field: 'override_road_time', headerName: t('ATTENDANCE_REPORT.OVERRIDE_ROAD_TIME'),minWidth:150,maxWidth:250, flex:1,
            renderCell: (rows) => (
                !calculatTimeForRoad(rows)?
                rows?.row?.attendance_calculation?.override_road_time === 1 ?
                    <Button onClick={() => { toggleOverrideRoadTime(rows?.row?.attendance_calculation?.id, rows?.row?.attendance_calculation?.override_road_time) }} style={{ padding: 5, borderRadius: 5, color: 'white', backgroundColor: "#57A773" }}>{t('ATTENDANCE_REPORT.ATTENDANCE_OVERRIDE')}</Button>
                    :
                    <Button onClick={() => { toggleOverrideRoadTime(rows?.row?.attendance_calculation?.id, rows?.row?.attendance_calculation?.override_road_time) }} style={{ padding: 5, borderRadius: 5, color: 'white', backgroundColor: "#EE6352" }}>{t('ATTENDANCE_REPORT.ATTENDANCE_NOT_OVERRIDE')}</Button>
                :null

            )
        },
        {
            field: 'override_visit_time', headerName: t('ATTENDANCE_REPORT.OVERRIDE_VISIT_TIME'), minWidth:150,maxWidth:250,flex: 1,
            renderCell: (rows) => (
                !calculatTimeForVisit(rows)?
                rows?.row?.attendance_calculation?.override_visit_time === 1 ?
                    <Button onClick={() => { toggleOverrideVisitTime(rows?.row?.attendance_calculation?.id, rows?.row?.attendance_calculation?.override_visit_time) }} style={{ padding: 5, borderRadius: 5, color: 'white', backgroundColor: "#57A773" }}>{t('ATTENDANCE_REPORT.ATTENDANCE_OVERRIDE')}</Button>
                    :
                    <Button onClick={() => { toggleOverrideVisitTime(rows?.row?.attendance_calculation?.id, rows?.row?.attendance_calculation?.override_visit_time) }} style={{ padding: 5, borderRadius: 5, color: 'white', backgroundColor: "#EE6352" }}>{t('ATTENDANCE_REPORT.ATTENDANCE_NOT_OVERRIDE')}</Button>
                :null
            )
        },
        {
            field: 'override_valid', headerName: t('ATTENDANCE_REPORT.VALID'),minWidth:150,maxWidth:250, flex: 1,
            renderCell: (rows) => (
                rows?.row?.attendance_calculation?.is_valid === 1 ?
                    <Button onClick={() => { toggleIsValidVisitTime(rows?.row?.attendance_calculation?.id, rows?.row?.attendance_calculation?.is_valid) }} style={{ padding: 5, borderRadius: 5, color: 'white', backgroundColor: "#57A773" }}>{t('ATTENDANCE_REPORT.VALID')}</Button>
                    :
                    <Button onClick={() => { toggleIsValidVisitTime(rows?.row?.attendance_calculation?.id, rows?.row?.attendance_calculation?.is_valid) }} style={{ padding: 5, borderRadius: 5, color: 'white', backgroundColor: "#EE6352" }}>{t('ATTENDANCE_REPORT.NOT_VALID')}</Button>
                )
        },

    ];

    const calculatTimeForRoad=(params)=>{
        let check=false;
        if(params?.row?.attendance_calculation){
            let arr1=params?.row?.attendance_calculation?.road_time?.split(':');
            let arr2=params?.row?.attendance_calculation?.road_time_calculated?.split(':');



            if(arr1[0]==arr2[0]){//check equal between road and road  calculated time
                // check2=true;
                if(arr1[1]==arr2[1]){
                    if(arr1[2]==arr2[2]){
                        check=true;
                    } 
                }
            }
            return check;
         }
         return check;
    }
    const calculatTimeForVisit=(params)=>{
        let check=false;
        if(params?.row?.attendance_calculation){
         
            let arr3=params?.row?.attendance_calculation?.visit_total_time?.split(':');
            let arr4=params?.row?.attendance_calculation?.visit_total_time_calculated?.split(':');

        
        

            if(arr3[0]==arr4[0]){//check equal between visit and visit total calculated time
                // check2=true;
                if(arr3[1]==arr4[1]){
                    if(arr3[2]==arr4[2]){
                        check=true;
                    } 
                }
            }
           return check;
         }
         return check;
    }

    const toggleIsValidVisitTime = async (id, value) => {
        await toggleValidObject(id, value == 1 ? 0 : 1)
    }
    const toggleValidObject = async (id, value) => {
        setLoading(true)
        try {
            const result = await axios({

                url: Host + "attendance_calculations_report/toggle_valid",
                method: "post",
                headers: {
                    "Authorization":`Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                },
                data: {
                    attendance_calculation_id: id,
                    value: value,
                }

            })
            console.log("tracking", 4);
            if (result && result.status == 200) {
                advanceSearch("SEARCH")
            } else if (result.data.status === false) {
                toast.error("Error")
            }
        } catch (error) {
            const responseResult = error.response
            if (responseResult && responseResult.status === 400) {
                if (typeof responseResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key) => {

                        responseResult.data.data[key].map((message) => {
                            toast.error(message)
                        })
                    })
                }
                else {
                    toast.error(responseResult.data.data)
                }

            }
            else if (responseResult && responseResult.status === 401) {
                toast.error(responseResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else {
                toast.error("network error")
            }
        }
        setLoading(false)
    }
    const toggleOverrideRoadTime = async (id, value) => {
        console.log("toggleOverrideRoadTime", id + value);
        await toggleObject(id, value == 1 ? 0 : 1, "road")
    }
    const toggleOverrideVisitTime = async (id, value) => {
        console.log("toggleOverrideVisitTime", id + value);
        await toggleObject(id, value == 1 ? 0 : 1, "visit")
    }
    const toggleObject = async (id, value, type) => {
        console.log("tracking", 1);
        setLoading(true)
        console.log("tracking", 2);
        try {
            console.log("tracking", 3);
            const result = await axios({

                url: Host + "attendance_calculations_report/toggle_override",
                method: "post",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                },
                data: {
                    attendance_calculation_id: id,
                    value: value,
                    type: type
                }

            })
            console.log("tracking", 4);
            if (result && result.status == 200) {
                console.log("tracking", 5);
                advanceSearch("SEARCH")
                console.log("tracking", 7);
            } else if (result.data.status === false) {
                toast.error("Error")
                console.log("tracking", 8);
            }
        } catch (error) {
            const responseResult = error.response
            if (responseResult && responseResult.status === 400) {
                if (typeof responseResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key) => {

                        responseResult.data.data[key].map((message) => {
                            toast.error(message)
                        })
                    })
                }
                else {
                    toast.error(responseResult.data.data)
                }

            }
            else if (responseResult && responseResult.status === 401) {
                toast.error(responseResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else {
                toast.error("network error")
            }
        }
        setLoading(false)
    };

    const sendTelegramMessage = (channel_id, token, ws_data, fileName) => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        var workbook = XLSX.utils.book_new()
        var ws = XLSX.utils.aoa_to_sheet(ws_data);
        XLSX.utils.book_append_sheet(workbook, ws, "sheet1");
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        var file = new Blob([excelBuffer], { type: fileType });
        file.lastModifiedDate = new Date();
        file.name = fileName + '_' + Math.floor(Date.now() / 1000) + fileExtension;
        var formData = new FormData();
        formData.append('document', file, file.name);

        var xhr = new XMLHttpRequest();
        xhr.open('POST', 'https://api.telegram.org/bot' + token + '/sendDocument?chat_id=' + channel_id, true);

        xhr.send(formData);
       
    }


    const ExportFunction=async () => {
        const fetchedData = await fetchData('ATTENDANCE_CALCULATIONS', 'attendance_calculations_report', {
            page_size: pointsTotal,
            created_by: user.id,
            creation_date_from: moment(fromToDate[0])?.format('YYYY-MM-DD'),
            creation_date_to: moment(fromToDate[1])?.format('YYYY-MM-DD'),
        })
        var visitsArray = []
        fetchedData?.data?.data?.map((visit, index) => {
            const road_time_calculated = visit?.attendance_calculation?.override_road_time === 1 ? visit?.attendance_calculation?.road_time : visit?.attendance_calculation?.road_time_calculated
            const visit_total_time_calculated = visit?.attendance_calculation?.override_visit_time === 1 ? visit?.attendance_calculation?.visit_total_time : visit?.attendance_calculation?.visit_total_time_calculated

            visitsArray?.push([
                index + 1,
                visit?.point?.name,
                visit?.start_date,
                visit?.end_date,
                visit?.attendance_calculation?.road_time,
                visit?.attendance_calculation?.visit_total_time,
                road_time_calculated,
                visit_total_time_calculated,

            ])
        })
        const summaryValues1 = [
            "",
            "",
            "",
            "",
            "",
            "",
            fetchedData?.data?.meta_data?.road_time,
            fetchedData?.data?.meta_data?.visits_time,
        ]
        const summaryValues2 = [
            "",
            "",
            "",
            "",
            "",
            "",
            fetchedData?.data?.meta_data?.total_work_time,
            fetchedData?.data?.meta_data?.working_hours_per_day,
        ]
        // exportToCSV(fetchedData?.data, 'attendance_calculations_report')
        var ws_data = [
            ["ت", "اسم النقطة", "وقت  بدء الزيارة", "وقت  انتهاء الزيارة", "وقت الطريق", "وقت الزيارة", "وقت الطريق المعتمد", "وقت الزيارة المعتمد"],
            ...visitsArray,
            // [1, "احمد", "2022-07-04 14:31:27", "2022-07-04 14:31:27", "14:31:27", "14:31:27", "14:31:27", "14:31:27"],
            ["", "", "", "", "", "", "مجموع الوقت المستغرق في الطريق", "مجموع الوقت المستغرق في الزيارات"],
            summaryValues1,
            ["", "", "", "", "", "", "مجموع وقت الدوام الكلي", "عدد الساعات المطلوبة"],
            summaryValues2,
        ];
        exportAttendanceToCSV(ws_data, 'attendance_calculations_report')
    }
    const createFunction=()=>{
        // createObject()
    }
    const mapFunction=async () => {
            // const fetchedData = await fetchData('ALL_POINTS', 'points', {
            //     page_size: pointsTotal,
            //     shop_name: shopName,
            //     owner_name: ownerName,
            //     district: district.id,
            //     region: region.id,
            //     point_type_id: pointStatus.map((status) => status.id),
            // })
            // mapObjects(fetchedData)
     }
    const importFunction=()=>{
        // importObjects()
    }
    
    const telegramFunction=async () => {
        const fetchedData = await fetchData('ATTENDANCE_CALCULATIONS', 'attendance_calculations_report', {
            page_size: pointsTotal,
            created_by: user.id,
            creation_date_from: moment(fromToDate[0])?.format('YYYY-MM-DD'),
            creation_date_to: moment(fromToDate[1])?.format('YYYY-MM-DD'),
        })
        var visitsArray = []
        fetchedData?.data?.data?.map((visit, index) => {
            const road_time_calculated = visit?.attendance_calculation?.override_road_time === 1 ? visit?.attendance_calculation?.road_time : visit?.attendance_calculation?.road_time_calculated
            const visit_total_time_calculated = visit?.attendance_calculation?.override_visit_time === 1 ? visit?.attendance_calculation?.visit_total_time : visit?.attendance_calculation?.visit_total_time_calculated

            visitsArray?.push([
                index + 1,
                visit?.point?.name,
                visit?.start_date,
                visit?.end_date,
                visit?.attendance_calculation?.road_time,
                visit?.attendance_calculation?.visit_total_time,
                road_time_calculated,
                visit_total_time_calculated,

            ])
        })
        const summaryValues1 = [
            "",
            "",
            "",
            "",
            "",
            "",
            fetchedData?.data?.meta_data?.road_time,
            fetchedData?.data?.meta_data?.visits_time,
        ]
        const summaryValues2 = [
            "",
            "",
            "",
            "",
            "",
            "",
            fetchedData?.data?.meta_data?.total_work_time,
            fetchedData?.data?.meta_data?.working_hours_per_day,
        ]
        exportToCSV(fetchedData?.data, 'attendance_calculations_report')
        var ws_data = [
            ["ت", "اسم النقطة", "وقت  بدء الزيارة", "وقت  انتهاء الزيارة", "وقت الطريق", "وقت الزيارة", "وقت الطريق المعتمد", "وقت الزيارة المعتمد"],
            ...visitsArray,
            // [1, "احمد", "2022-07-04 14:31:27", "2022-07-04 14:31:27", "14:31:27", "14:31:27", "14:31:27", "14:31:27"],
            ["", "", "", "", "", "", "مجموع الوقت المستغرق في الطريق", "مجموع الوقت المستغرق في الزيارات"],
            summaryValues1,
            ["", "", "", "", "", "", "مجموع وقت الدوام الكلي", "عدد الساعات المطلوبة"],
            summaryValues2,
        ];
        sendTelegramMessage(fetchedData?.data?.meta_data?.telegram_channel_id,fetchedData?.data?.meta_data?.telegram_token,ws_data, 'attendance_'+user?.username)
    }

    return (
        <>
            {
                !filterSearchBoxMode?
                <Box sx={{display:"flex",flexDirection:screenWidth<=1100?'column-reverse':'row',alignItems:'start',justifyContent:"center",width:"100%",}}>
                    {
                    rows?.length > 0 ?
                        <CustomeSummeryBox isFullWidth={screenWidth<=1100} MainTheme={MainTheme}>
                                {/* <p style={{position:'absolute',top:'15px',left:'25px',color:'white',zIndex:1000,fontSize:'20px'}}>{t('ATTENDANCE_REPORT.ATTENDANCE_TOTAL_SUMMARY')}</p> */}
                                <Box
                                sx={{
                                    backgroundColor:'#fff',
                                    borderTopLeftRadius:'5px',
                                    borderTopRightRadius:'5px',
                                    borderBottomLeftRadius:!openSummery?'5px':0,
                                    borderBottomRightRadius:!openSummery?'5px':0,
                                    transition:'border-radius 0.1s ease-in-out',
                                    height:!openSummery?'61px':'69px',
                                    width:'100%',
                                    display:'flex',
                                    padding:screenWidthRedux<=1100?'2px 6px':'0',
                                    justifyContent: 'start', alignItems: 'center',
                                    // borderBottom:'1px solid grey',
                                }}
                            > 
                                    <CustomeSummeryBoxLeft className="CustomeSummeryBoxLeft">
                                        <Tooltip title={metaData?.user?.username}>
                                            <img src={metaData?.user?.image?helpers?.getImage(metaData?.user,UserImage):UserImage}  className="userImage" />
                                        </Tooltip>
                                        {/* <Typography >
                                            {user?.username}
                                        </Typography> */}
                                    </CustomeSummeryBoxLeft>
                                    <CustomeSummeryBoxRight className="CustomeSummeryBoxRight">
                                    
                                        <Box className="subItem subItem1">
                                                <Typography sx={{fontWeight:700,fontSize:'15px',color:'#000'}}>
                                                {metaData?.user?.username} {t('ATTENDANCE_REPORT.ATTENDANCE_TOTAL_SUMMARY')}
                                                </Typography>
                                                {/* <Typography className="result">
                                                    {metaData?.road_time}
                                                </Typography> */}
                                        </Box>
                                        <Tooltip title={t('ATTENDANCE_REPORT.ATTENDANCE_USER_WORKING_HOURS_PER_DAY')}>
                                            <Box className="subItem subItem2" sx={{display:'flex',alignItems:'center',marginLeft:'0px'}}>
                                                
                                                    <Typography sx={{fontWeight:'500',fontSize:SideMenuOpen?'12px':'12px',color:'rgb(71,85,105)'}}>
                                                        {t('ATTENDANCE_REPORT.ATTENDANCE_USER_WORKING_HOURS_PER_DAY')} :
                                                    </Typography>
                                                    <Typography className="result" sx={{fontSize:'12px',marginLeft:'5px',color:'rgb(71,85,105)'}}>
                                                        {metaData?.working_hours_per_day}
                                                    </Typography>
                                                
                                            </Box>
                                        </Tooltip>
                                        <CustomeActiveStatusCheck color={metaData?.working_hours_per_day_color} directions={directions}>
                                            {/* <FlareIcon sx={{color:"#ffffff",fontSize:'20px'}}/> */}
                                        </CustomeActiveStatusCheck>
                                    </CustomeSummeryBoxRight>
                                    
                                </Box>
                                
                                    <Box sx={{
                                        width:' 100%;',
                                        height: openSummery?'1.001px;':'0',
                                        backgroundColor:' #d7d1d1 !important',
                                    }}></Box>
                                
                                {/* <CustomeSummeryDropDown sx={{display:'none'}}>
                                    <Box className="subItem">
                                            <Typography >
                                                {t('ATTENDANCE_REPORT.ATTENDANCE_ROAD_TIME')}
                                            </Typography>
                                            <Typography className="result">
                                                {metaData?.road_time}
                                            </Typography>
                                    </Box>
                                    <Box className="subItem">
                                            <Typography >
                                                {t('ATTENDANCE_REPORT.ATTENDANCE_VISIT_TIME')}
                                            </Typography>
                                            <Typography className="result">
                                                {metaData?.visits_time}
                                            </Typography>
                                    </Box>
                                    <Box className="subItem">
                                            <Typography >
                                                {t('ATTENDANCE_REPORT.ATTENDANCE_TOTAL_WORK_TIME')}
                                            </Typography>
                                            <Typography className="result">
                                                {metaData?.total_work_time}
                                            </Typography>
                                    </Box>
                                    <Box className="subItem">
                                            <Typography >
                                            {t('ATTENDANCE_REPORT.ATTENDANCE_USER_WORKING_HOURS_PER_DAY')}
                                            </Typography>
                                            <Typography className="result">
                                                {metaData?.working_hours_per_day}
                                            </Typography>
                                    </Box>
                                </CustomeSummeryDropDown> */}
                                <CustomeSummeryDropDown className="CustomeSummeryDropDown" openSummery={openSummery} isScreenSized={screenWidthRedux<=1100}>
                                    <ListItem sx={{padding:'2px 15px'}}>
                                        <ListItemAvatar>
                                            <Avatar size={16}>
                                                <GiIcone.GiHorizonRoad />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary= {<Typography sx={{fontWeight:'bold',fontSize:'12px',color:'black !important'}}>{metaData?.road_time}</Typography>} secondary= {<Typography sx={{fontSize:'12px',color:'grey !important'}}>{t('ATTENDANCE_REPORT.ATTENDANCE_ROAD_TIME')}</Typography>} />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                    <ListItem sx={{padding:'2px 15px'}}>
                                        <ListItemAvatar>
                                        <Avatar size={16}>
                                            <FaIcone.FaHandshake />
                                        </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={<Typography sx={{fontWeight:'bold',fontSize:'12px',color:'black !important'}}>{metaData?.visits_time}</Typography>} secondary= {<Typography sx={{fontSize:'12px',color:'grey !important'}}>{t('ATTENDANCE_REPORT.ATTENDANCE_VISIT_TIME')}</Typography>} />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                    <ListItem sx={{padding:'2px 15px'}}>
                                        <ListItemAvatar>
                                        <Avatar size={16}>
                                            <WatchLaterIcon sx={{fontSize:'20px'}}/>
                                        </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary= {<Typography sx={{fontWeight:'bold',fontSize:'12px',color:'black !important'}}>{metaData?.total_work_time}</Typography>} secondary= { <Typography sx={{fontSize:'12px',color:'grey !important'}}>{t('ATTENDANCE_REPORT.ATTENDANCE_TOTAL_WORK_TIME')}</Typography>} />
                                    </ListItem>
                                </CustomeSummeryDropDown>

                        </CustomeSummeryBox>
                        :null
                    }
                    
                    <FilterBox 
                        exportFunction={ExportFunction}
                        telegramFunction={telegramFunction}
                        // createFunction={createFunction}
                        // mapFunction={mapFunction}
                        // importFunction={importFunction}
                        hasExport={rows?.length > 0 ?true:false} 
                        hasCreate={false} 
                        hasMaps={false} 
                        hasImport={false}
                        hasTelegram={rows?.length > 0 ?true:false}
                        resultLength={pointsTotal}
                        customeHeight={screenWidthRedux<=1100?'220px':"190px"}
                        customeWidth={rows?.length > 0&& screenWidth>1100 ?"80%":'100%'}
                        openSummery={openSummery}
                        setOpenSummery={setOpenSummery}
                    >

                        <Autocomplete
                            sx={{ ml: 1,width:screenWidthRedux<=1100?'98%':'45%',mt:1 }}
                            fullWidth
                            options={users}
                            getOptionLabel={(option) => option.full_name || ""}
                            id="disable-close-on-select"
                            value={user}
                            onChange={(e, v, r) => { setUser(v ? v : ''); }}
                            renderInput={(params) => (
                                <TextField {...params} 
                                label={t('GLOBAL.SEARCH_USERS')}
                                variant="standard" sx={{borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}}/>
                            )}
                        />
                        <BasicDatePicker customeWidth={screenWidthRedux<=1100?'98%':"52.5%"} customeMargin="0px" customePaddingLeft="9px"/>

                        <Box sx={{display:'flex',justifyContent: 'end',alignItems: 'center',width:'100%'}}>
                        

                            <Box display="flex" >
                                <Button
                                    variant="contained"
                                    spacing={2} 
                                    sx={{ flexGrow: 1, margin: 1,backgroundColor:SearchButtonTheme?.search_button_color,color:SearchButtonTheme?.search_button_text_color,
                                        '&:hover':{
                                            backgroundColor:SearchButtonTheme?.search_button_color+'88',
                                        },
                                        height:FilterButtonHeight
                                    }}
                                    className="iconeFilterSearch"
                                    onClick={() => advanceSearch()} >
                                    {t('GLOBAL.SEARCH_SEARCH_BUTTON')}
                                </Button>
                                <Button variant="outlined"
                                    spacing={2}
                                    sx={{
                                        flexGrow: 1, margin: 1,
                                        // backgroundColor:SearchButtonTheme?.clear_button_color,
                                        color:SearchButtonTheme?.clear_button_text_color,
                                        '&:hover':{
                                            backgroundColor:`${SearchButtonTheme?.clear_button_color}   !important`,
                                        },
                                        height:FilterButtonHeight
                                    }}
                                    className="iconeFilterClear"
                                    color="secondary"
                                    onClick={clearPointsAdvanceSearchFields} >
                                    {t('GLOBAL.SEARCH_CLEAR_BUTTON')}
                                </Button>
                            </Box>
                        </Box>

                    
                    </FilterBox>
            
                </Box>:null
            }

            <Box 
             sx={{
                display:'flex',
                justifyContent: 'start',
                alignItems: 'start',
                width:'100%',
             }}
            >
                <Box
                    sx={{
                    width:'100%',
                    
                    }}
                >
                    {
                        filterSearchBoxMode&&rows?.length > 0 ?
                        <Box
                        sx={{
                            display:'flex',justifyContent:'space-between',
                            alignItems: 'center',
                            width:'100%',height:'60px',
                            borderRadius:'5px',
                            marginBottom:'10px',
                            backgroundColor:'#fff',
                            '& .muirtl-2s90m6-MuiAvatar-root':{
                                width:'30px !important',
                                height:'30px !important',
                            }
                        }}
                        >
                                <Box 
                                sx={{
                                    width:'28%',
                                display:'flex',alignItems:'center',
                                '& img':{
                                    width:'50px',
                                    height:'50px',
                                    borderRadius:'50%',
                                    backgroundColor:'#e6e6e6',
                                    // boxShadow:'0 0 7px -3px #000',
                                    backgroundSize:'cover',
                                    objectFit:'cover',
                                    backgroundPosition:'center center',
                                    margin:'10px'
                                },
                                
                                }}>
                            <img src={metaData?.user?.image?helpers?.getImage(metaData?.user,UserImage):UserImage} />
                            <Box>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'start',
                                        }}>
                                                <Typography sx={{fontWeight:600,fontSize:'14px',color:'#000'}}>
                                                    {
                                                    directions!='rtl'?metaData?.user?.username+" "+t('ATTENDANCE_REPORT.ATTENDANCE_TOTAL_SUMMARY'):
                                                        t('ATTENDANCE_REPORT.ATTENDANCE_TOTAL_SUMMARY')+' '+metaData?.user?.username
                                                    } 
                                                </Typography>
                                        </Box>
                                        <Tooltip title={t('ATTENDANCE_REPORT.ATTENDANCE_USER_WORKING_HOURS_PER_DAY')}>
                                            <Box className="subItem subItem2" sx={{display:'flex',alignItems:'center',marginLeft:'0px'}}>
                                                
                                                    <Typography sx={{fontWeight:'400',fontSize:'13px',color:'rgb(71,85,105)'}}>
                                                        {t('ATTENDANCE_REPORT.ATTENDANCE_USER_WORKING_HOURS_PER_DAY')} :
                                                    </Typography>
                                                    <Typography className="result" sx={{fontSize:'13px',marginLeft:'5px',color:'rgb(71,85,105)'}}>
                                                        {metaData?.working_hours_per_day}
                                                    </Typography>
                                                
                                            </Box>
                                        </Tooltip>
                                    
                            </Box>
                                </Box>
                                <Box sx={{width:'0.8px',height:'55%',backgroundColor:'#cabdbd !important'}}></Box>
                                <Box
                                    sx={{
                                    width:'20%',
                                    display:'flex',
                                    flexDirection:'row',
                                    justifyContent: 'center',
                                    alignItems:'center',
                                    "& .span1,& .span2":{
                                        fontSize:'13px !important',
                                        color:'#000 !important',
                                        margin:'0 4px',
                                    },
                                    "& .span1":{
                                        fontSize:screenWidthRedux<=1300?'10px !important':'11px !important',
                                        fontWeight:'bold',
                                    }
                                    }}
                                >
                                        <Avatar size={10}>
                                            <GiIcone.GiHorizonRoad />
                                        </Avatar>
                                        <span className="span1">{t('ATTENDANCE_REPORT.ATTENDANCE_ROAD_TIME')}</span>
                                        <span className="span2">{metaData?.road_time}</span> 
                                </Box>
                                <Box sx={{width:'0.8px',height:'55%',backgroundColor:'#cabdbd  !important'}}></Box>
                                <Box
                                sx={{
                                    display:'flex',
                                    width:'20%',
                                    flexDirection:'row',
                                    justifyContent: 'center',
                                    alignItems:'center',
                                    "& .span1,& .span2":{
                                        fontSize:'13px !important',
                                        color:'#000 !important',
                                        margin:'0 4px',


                                    },
                                    "& .span1":{
                                        fontSize:screenWidthRedux<=1300?'10px !important':'11px !important',
                                        fontWeight:'bold',


                                    }
                                    }}
                                >
                                        <Avatar size={10}>
                                                <FaIcone.FaHandshake />
                                        </Avatar>
                                        <span className="span1">{t('ATTENDANCE_REPORT.ATTENDANCE_VISIT_TIME')}</span>
                                        <span className="span2">{metaData?.visits_time}</span> 
                                </Box>
                                <Box sx={{width:'0.8px',height:'55%',backgroundColor:'#cabdbd  !important'}}></Box>
                                <Box
                                    sx={{
                                    display:'flex',
                                    width:'20%',
                                    flexDirection:'row',
                                    justifyContent: 'center',
                                    alignItems:'center',
                                    "& .span1,& .span2":{
                                        fontSize:'13px !important',
                                        color:'#000 !important',
                                        margin:'0 4px',

                                    },
                                    "& .span1":{
                                        fontSize:screenWidthRedux<=1300?'9.5px !important':'11px !important',
                                        fontWeight:'bold',

                                    }
                                    }}
                                >
                                    <Avatar size={10}>
                                        <WatchLaterIcon sx={{fontSize:'20px'}}/>
                                    </Avatar>
                                    <span className="span1">{t('ATTENDANCE_REPORT.ATTENDANCE_TOTAL_WORK_TIME')}</span>
                                    <span className="span2">{metaData?.total_work_time}</span> 
                                </Box>
                                <Box sx={{width:'0.8px',height:'55%',backgroundColor:'#cabdbd  !important'}}></Box>
                                <Box  sx={{display:'flex',justifyContent:'center',alignItems:'center',width:'3%'}}>
                                    <CustomeActiveStatusCheckV2 gridModeActive={true} color={metaData?.working_hours_per_day_color} directions={directions}>
                                                        {/* <FlareIcon sx={{color:"#ffffff",fontSize:'20px'}}/> */}
                                    </CustomeActiveStatusCheckV2>
                                </Box>
                        </Box>:null
                    }
                    <Grid
                    rows={rows}
                    columns={columns}
                    rowTotals={pointsTotal}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    pageNumber={pageNumber}
                    setPageNumber={setpageNumber}
                    loading={loading}
                    handlePageChange={handlePageChange}
                    NumberOfPage={pointsNumberOfPages}
                    directions={directions}
                    hasCreate={false}
                    createObject={null}
                    title_en=""
                    title_ar=""
                    hasRowColors={true}
                    gridHeight={{
                        height:rows?.length>5?pageSize==5?400:pageSize==10?650:950:400,
                    }}
                    />
                </Box>
                <Box>
                {
                    filterSearchBoxMode?
                    <CustomeFilter
                        exportFunction={ExportFunction}
                        telegramFunction={telegramFunction}
                        // createFunction={createFunction}
                        // mapFunction={mapFunction}
                        // importFunction={importFunction}
                        hasExport={rows?.length > 0 ?true:false} 
                        hasCreate={false} 
                        hasMaps={false} 
                        hasImport={false}
                        hasTelegram={rows?.length > 0 ?true:false}
                        resultLength={pointsTotal}
                        customeHeight={screenWidthRedux<=1100?'220px':"190px"}
                        customeWidth={rows?.length > 0&& screenWidth>1100 ?"80%":'100%'}
                        openSummery={openSummery}
                        setOpenSummery={setOpenSummery}
                    >
                        <Box
                        sx={{
                            width:'100%',
                            height:'400px',
                            minHeight:'400px',
                            maxHeight:'400px',
                            overflowY:'scroll',
                            overflowX:'hidden',
                            padding:'10px',
                            marginTop:'10px',
                            paddingTop:'20px',
                            // boxShadow:'0 0 7px -5px #000 inset'
                        }}
                        >
                            <Autocomplete
                                sx={{ ml: 0.5,width:screenWidthRedux<=1100?'100%':'100%',marginTop:'10px !important',marginBottom:'10px !important' }}
                                fullWidth
                                options={users}
                                getOptionLabel={(option) => option.full_name || ""}
                                id="disable-close-on-select"
                                value={user}
                                onChange={(e, v, r) => { setUser(v ? v : ''); }}
                                renderInput={(params) => (
                                    <TextField {...params} 
                                    placeholder={t('GLOBAL.SEARCH_USERS')}
                                    variant="standard" sx={{borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}}/>
                                )}
                            />
                            <BasicDatePickerV2 customeWidth={screenWidthRedux<=1100?'100%':"100%"}/>

                 
                          
                        </Box>
                        <Box sx={{display:'flex',justifyContent: 'end',alignItems: 'center',width:'100%'}}>
                            <Box display="flex" >
                                    <Button
                                        variant="contained"
                                        // spacing={2} 
                                        sx={{ 
                                            // flexGrow: 1,
                                            margin: 1,
                                            width:'80px !important',
                                            minWidth:'80px !important',
                                            maxWidth:'80px !important',
                                            backgroundColor:SearchButtonTheme?.search_button_color,color:SearchButtonTheme?.search_button_text_color,
                                            '&:hover':{
                                                backgroundColor:SearchButtonTheme?.search_button_color+'88',
                                            },
                                            height:FilterButtonHeight-10
                                        }}
                                        className="iconeFilterSearch"
                                        onClick={() => advanceSearch("SEARCH")} >
                                        {t('GLOBAL.SEARCH_SEARCH_BUTTON')}
                                    </Button>
                                    <Button variant="outlined"
                                        // spacing={2}
                                        sx={{
                                            width:'50% !important',
                                                // flexGrow: 1, 
                                                minWidth:'80px !important',
                                                maxWidth:'80px !important',
                                                margin: 1,
                                                // backgroundColor:SearchButtonTheme?.clear_button_color,
                                                color:SearchButtonTheme?.clear_button_text_color,
                                            '&:hover':{
                                                backgroundColor:`${SearchButtonTheme?.clear_button_color}   !important`,
                                            },
                                            height:FilterButtonHeight-10
                                        }}
                                        className="iconeFilterClear"
                                        color="secondary"
                                        onClick={clearPointsAdvanceSearchFields} >
                                        {t('GLOBAL.SEARCH_CLEAR_BUTTON')}
                                    </Button>
                            </Box>
                        </Box>
                    </CustomeFilter>:null
                }
                </Box>
            </Box>


                {/* <SummeryCards/> */}

                <br/>
                <br/>
                <br/>
            

        </>
    );
}

export default AttendanceReport;


//   <Box display="flex" >
//                     {rows?.length > 0 ?
//                         <>
//                             <Button display="flex"
//                                 startIcon={<FileDownloadIcon sx={{color:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositeSecondaryColor}}/>}
//                                 variant="contained"
//                                 sx={{ marginRight: 1, marginBottom: accExpansion ? "0" : "20px",backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor }}
//                                 onClick={async () => {
//                                     const fetchedData = await fetchData('ATTENDANCE_CALCULATIONS', 'attendance_calculations_report', {
//                                         page_size: pointsTotal,
//                                         created_by: user.id,
//                                         creation_date_from: moment(fromToDate[0])?.format('YYYY-MM-DD'),
//                                         creation_date_to: moment(fromToDate[1])?.format('YYYY-MM-DD'),
//                                     })
//                                     var visitsArray = []
//                                     fetchedData?.data?.data?.map((visit, index) => {
//                                         const road_time_calculated = visit?.attendance_calculation?.override_road_time === 1 ? visit?.attendance_calculation?.road_time : visit?.attendance_calculation?.road_time_calculated
//                                         const visit_total_time_calculated = visit?.attendance_calculation?.override_visit_time === 1 ? visit?.attendance_calculation?.visit_total_time : visit?.attendance_calculation?.visit_total_time_calculated

//                                         visitsArray?.push([
//                                             index + 1,
//                                             visit?.point?.name,
//                                             visit?.start_date,
//                                             visit?.end_date,
//                                             visit?.attendance_calculation?.road_time,
//                                             visit?.attendance_calculation?.visit_total_time,
//                                             road_time_calculated,
//                                             visit_total_time_calculated,

//                                         ])
//                                     })
//                                     const summaryValues1 = [
//                                         "",
//                                         "",
//                                         "",
//                                         "",
//                                         "",
//                                         "",
//                                         fetchedData?.data?.meta_data?.road_time,
//                                         fetchedData?.data?.meta_data?.visits_time,
//                                     ]
//                                     const summaryValues2 = [
//                                         "",
//                                         "",
//                                         "",
//                                         "",
//                                         "",
//                                         "",
//                                         fetchedData?.data?.meta_data?.total_work_time,
//                                         fetchedData?.data?.meta_data?.working_hours_per_day,
//                                     ]
//                                     // exportToCSV(fetchedData?.data, 'attendance_calculations_report')
//                                     var ws_data = [
//                                         ["ت", "اسم النقطة", "وقت  بدء الزيارة", "وقت  انتهاء الزيارة", "وقت الطريق", "وقت الزيارة", "وقت الطريق المعتمد", "وقت الزيارة المعتمد"],
//                                         ...visitsArray,
//                                         // [1, "احمد", "2022-07-04 14:31:27", "2022-07-04 14:31:27", "14:31:27", "14:31:27", "14:31:27", "14:31:27"],
//                                         ["", "", "", "", "", "", "مجموع الوقت المستغرق في الطريق", "مجموع الوقت المستغرق في الزيارات"],
//                                         summaryValues1,
//                                         ["", "", "", "", "", "", "مجموع وقت الدوام الكلي", "عدد الساعات المطلوبة"],
//                                         summaryValues2,
//                                     ];
//                                     exportAttendanceToCSV(ws_data, 'attendance_calculations_report')
//                                 }
//                                 }>
//                                 {t('GLOBAL.SEARCH_EXPORT_BUTTON')}
//                             </Button>
//                             <Button display="flex"
//                                 startIcon={<TelegramIcon sx={{color:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositeSecondaryColor}}/>}
//                                 variant="contained"
//                                 sx={{ marginRight: 1, marginBottom: accExpansion ? "0" : "20px",backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor }}
//                                 onClick={async () => {
//                                     const fetchedData = await fetchData('ATTENDANCE_CALCULATIONS', 'attendance_calculations_report', {
//                                         page_size: pointsTotal,
//                                         created_by: user.id,
//                                         creation_date_from: moment(fromToDate[0])?.format('YYYY-MM-DD'),
//                                         creation_date_to: moment(fromToDate[1])?.format('YYYY-MM-DD'),
//                                     })
//                                     var visitsArray = []
//                                     fetchedData?.data?.data?.map((visit, index) => {
//                                         const road_time_calculated = visit?.attendance_calculation?.override_road_time === 1 ? visit?.attendance_calculation?.road_time : visit?.attendance_calculation?.road_time_calculated
//                                         const visit_total_time_calculated = visit?.attendance_calculation?.override_visit_time === 1 ? visit?.attendance_calculation?.visit_total_time : visit?.attendance_calculation?.visit_total_time_calculated

//                                         visitsArray?.push([
//                                             index + 1,
//                                             visit?.point?.name,
//                                             visit?.start_date,
//                                             visit?.end_date,
//                                             visit?.attendance_calculation?.road_time,
//                                             visit?.attendance_calculation?.visit_total_time,
//                                             road_time_calculated,
//                                             visit_total_time_calculated,

//                                         ])
//                                     })
//                                     const summaryValues1 = [
//                                         "",
//                                         "",
//                                         "",
//                                         "",
//                                         "",
//                                         "",
//                                         fetchedData?.data?.meta_data?.road_time,
//                                         fetchedData?.data?.meta_data?.visits_time,
//                                     ]
//                                     const summaryValues2 = [
//                                         "",
//                                         "",
//                                         "",
//                                         "",
//                                         "",
//                                         "",
//                                         fetchedData?.data?.meta_data?.total_work_time,
//                                         fetchedData?.data?.meta_data?.working_hours_per_day,
//                                     ]
//                                     exportToCSV(fetchedData?.data, 'attendance_calculations_report')
//                                     var ws_data = [
//                                         ["ت", "اسم النقطة", "وقت  بدء الزيارة", "وقت  انتهاء الزيارة", "وقت الطريق", "وقت الزيارة", "وقت الطريق المعتمد", "وقت الزيارة المعتمد"],
//                                         ...visitsArray,
//                                         // [1, "احمد", "2022-07-04 14:31:27", "2022-07-04 14:31:27", "14:31:27", "14:31:27", "14:31:27", "14:31:27"],
//                                         ["", "", "", "", "", "", "مجموع الوقت المستغرق في الطريق", "مجموع الوقت المستغرق في الزيارات"],
//                                         summaryValues1,
//                                         ["", "", "", "", "", "", "مجموع وقت الدوام الكلي", "عدد الساعات المطلوبة"],
//                                         summaryValues2,
//                                     ];
//                                     sendTelegramMessage(fetchedData?.data?.meta_data?.telegram_channel_id,fetchedData?.data?.meta_data?.telegram_token,ws_data, 'attendance_'+user?.username)
//                                 }
//                                 }>
//                             {t('GLOBAL.SEARCH_SEND_BUTTON')}
    
//                             </Button>
//                         </>
//                         : null}
//                     <Accordion display="flex" sx={{ flexGrow: 4,backgroundColor:GridTheme?.primaryColor }}
//                         onChange={(event, expansion) => handleExpansion(expansion)}
//                     >
//                         <AccordionSummary
//                             expandIcon={<ExpandMoreIcon x={{ color: GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositePrimaryColor }} />}
//                             aria-controls="panel1a-content"
//                             id="panel1a-header"
//                         >
//                             <Typography sx={{color:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositePrimaryColor}}>{t('GLOBAL.SEARCH_TITLE')}</Typography>
//                         </AccordionSummary>
//                         <AccordionDetails >
//                             <Box component="span" display="flex" bgcolor="white" flexDirection="column" sx={{ borderRadius: 1 }} >
//                                 <Box sx={{ m: 1 }} component="span" display="flex" bgcolor="white" flexDirection="row" >

//                                     <BasicDatePicker />

//                                     <Box display="flex" sx={{ flexGrow: 1 }}>
//                                         <Button
//                                             variant="contained"
//                                             spacing={2} sx={{ flexGrow: 1, margin: 1,backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor,
//                                                 '&:hover':{
//                                                     backgroundColor:GridTheme?.secondaryColor+'88',
//                                                 }  }}
//                                             onClick={() => advanceSearch("SEARCH")} >
//                                             {t('GLOBAL.SEARCH_SEARCH_BUTTON')}
//                                         </Button>
//                                         <Button variant="contained"
//                                             spacing={2}
//                                             sx={{ flexGrow: 1, margin: 1 ,backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor,
//                                                 '&:hover':{
//                                                     backgroundColor:GridTheme?.secondaryColor+'88',
//                                                 } }}
//                                             color="secondary"
//                                             onClick={clearPointsAdvanceSearchFields} >
//                                             {t('GLOBAL.SEARCH_CLEAR_BUTTON')}
//                                         </Button>
//                                     </Box>
//                                 </Box>
//                                 <Box sx={{ m: 1 }} component="span" display="flex" bgcolor="white" flexDirection="row" >
//                                     <Autocomplete
//                                         sx={{ ml: 1 }}
//                                         fullWidth
//                                         options={users}
//                                         getOptionLabel={(option) => option.full_name || ""}
//                                         id="disable-close-on-select"
//                                         value={user}
//                                         onChange={(e, v, r) => { setUser(v ? v : ''); }}
//                                         renderInput={(params) => (
//                                             <TextField {...params} 
//                                             label={t('GLOBAL.SEARCH_USERS')}
//                                             variant="outlined" />
//                                         )}
//                                     />

//                                 </Box>
//                             </Box>
//                         </AccordionDetails>
//                     </Accordion>
//                 </Box>

//                {rows?.length > 0 ?
//                 <Box display="flex" >

//                     <Accordion display="flex" sx={{ flexGrow: 4, backgroundColor:GridTheme?.primaryColor}}
//                         onChange={(event, expansionMeta) => handleExpansionMeta(expansionMeta)}
//                     >
//                         <AccordionSummary
//                             expandIcon={<ExpandMoreIcon sx={{ color: GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositePrimaryColor }} />}
//                             aria-controls="panel1a-content"
//                             id="panel1a-header"
//                         >
//                             <Typography sx={{color:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositePrimaryColor}}>{t('ATTENDANCE_REPORT.ATTENDANCE_TOTAL_SUMMARY')}</Typography>
//                         </AccordionSummary>
//                         <AccordionDetails >
//                             <Box component="span" display="flex" bgcolor="white" flexDirection="column" sx={{ borderRadius: 1 }} >
//                                 <Box sx={{ m: 1 }} component="span" display="flex" bgcolor="white" flexDirection="row" >
//                                     <Box display="flex" sx={{ flex: 1, borderWidth: 1, borderColor: '#132a3b', marginRight: '5px', borderStyle: 'solid', borderRadius: '5px', justifyContent: 'center', alignItems: 'center', }}>
//                                         <Box display="flex" sx={{ flex: 1, justifyContent: 'center', alignItems: 'center', }}>
//                                             <Typography component={'h1'} variant='h6' sx={{ color: '#000000', textAlign: 'center' }}>
//                                             {t('ATTENDANCE_REPORT.ATTENDANCE_ROAD_TIME')}
//                                             </Typography>
//                                         </Box>
//                                         <Box display="flex" sx={{ flex: 2, justifyContent: 'center', alignItems: 'center', }}>
//                                             <Typography component={'h1'} variant='h4' sx={{ color: '#000000', textAlign: 'center' }}>
//                                                 {metaData?.road_time}
//                                             </Typography>
//                                         </Box>

//                                     </Box>
//                                     <Box display="flex" sx={{ borderWidth: 1, borderColor: '#132a3b', borderStyle: 'solid', borderRadius: '5px', flex: 1, justifyContent: 'center', alignItems: 'center', }}>
//                                         <Box display="flex" sx={{ flex: 1, justifyContent: 'center', alignItems: 'center', }}>
//                                             <Typography component={'h1'} variant='h6' sx={{ color: '#000000', textAlign: 'center' }}>
//                                             {t('ATTENDANCE_REPORT.ATTENDANCE_VISIT_TIME')}
//                                             </Typography>
//                                         </Box>
//                                         <Box display="flex" sx={{ flex: 2, justifyContent: 'center', alignItems: 'center', }}>
//                                             <Typography component={'h1'} variant='h4' sx={{ color: '#000000', textAlign: 'center' }}>
//                                                 {metaData?.visits_time}
//                                             </Typography>
//                                         </Box>

//                                     </Box>
//                                 </Box>
//                                 <Box sx={{ m: 1 }} component="span" display="flex" bgcolor="white" flexDirection="row" >
//                                     <Box display="flex" sx={{ backgroundColor: GridTheme?.secondaryColor, marginRight: '5px', borderWidth: 1, borderColor: '#132a3b', borderStyle: 'solid', borderRadius: '5px', flex: 1, justifyContent: 'center', alignItems: 'center', }}>
//                                         <Box display="flex" sx={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
//                                             <Typography component={'h1'} variant='h6' sx={{ color: GridTheme?.oppositeSecondaryColor, textAlign: 'center' }}>
//                                             {t('ATTENDANCE_REPORT.ATTENDANCE_TOTAL_WORK_TIME')}
//                                             </Typography>
//                                         </Box>
//                                         <Box display="flex" sx={{ flex: 2, justifyContent: 'center', alignItems: 'center', }}>
//                                             <Typography component={'h1'} variant='h4' sx={{ color: '#FFFFFF', textAlign: 'center' }}>
//                                                 {metaData?.total_work_time}
//                                             </Typography>
//                                         </Box>

//                                     </Box>
//                                     <Box display="flex" sx={{ borderWidth: 1, borderColor: '#132a3b', borderStyle: 'solid', borderRadius: '5px', flex: 1, justifyContent: 'center', alignItems: 'center', }}>
//                                         <Box display="flex" sx={{ flex: 1, justifyContent: 'center', alignItems: 'center', }}>
//                                             <Typography component={'h1'} variant='h6' sx={{ color: '#000000', textAlign: 'center' }}>
//                                             {t('ATTENDANCE_REPORT.ATTENDANCE_USER_WORKING_HOURS_PER_DAY')}
//                                             </Typography>
//                                         </Box>
//                                         <Box display="flex" sx={{ flex: 2, justifyContent: 'center', alignItems: 'center', }}>
//                                             <Typography component={'h1'} variant='h4' sx={{ color: '#000000', textAlign: 'center' }}>
//                                                 {metaData?.working_hours_per_day}
//                                             </Typography>
//                                         </Box>

//                                     </Box>
//                                 </Box>
//                             </Box>
//                         </AccordionDetails>
//                     </Accordion>
//                 </Box>
//                 : null}

         {/* {rows?.length > 0 ?
                <Box display="flex" >

                    <Accordion display="flex" sx={{ flexGrow: 4, backgroundColor:GridTheme?.primaryColor}}
                        onChange={(event, expansionMeta) => handleExpansionMeta(expansionMeta)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositePrimaryColor }} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography sx={{color:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositePrimaryColor}}>{t('ATTENDANCE_REPORT.ATTENDANCE_TOTAL_SUMMARY')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails >
                            <Box component="span" display="flex" bgcolor="white" flexDirection="column" sx={{ borderRadius: 1 }} >
                                <Box sx={{ m: 1 }} component="span" display="flex" bgcolor="white" flexDirection="row" >
                                    <Box display="flex" sx={{ flex: 1, borderWidth: 1, borderColor: '#132a3b', marginRight: '5px', borderStyle: 'solid', borderRadius: '5px', justifyContent: 'center', alignItems: 'center', }}>
                                        <Box display="flex" sx={{ flex: 1, justifyContent: 'center', alignItems: 'center', }}>
                                            <Typography component={'h1'} variant='h6' sx={{ color: '#000000', textAlign: 'center' }}>
                                            {t('ATTENDANCE_REPORT.ATTENDANCE_ROAD_TIME')}
                                            </Typography>
                                        </Box>
                                        <Box display="flex" sx={{ flex: 2, justifyContent: 'center', alignItems: 'center', }}>
                                            <Typography component={'h1'} variant='h4' sx={{ color: '#000000', textAlign: 'center' }}>
                                                {metaData?.road_time}
                                            </Typography>
                                        </Box>

                                    </Box>
                                    <Box display="flex" sx={{ borderWidth: 1, borderColor: '#132a3b', borderStyle: 'solid', borderRadius: '5px', flex: 1, justifyContent: 'center', alignItems: 'center', }}>
                                        <Box display="flex" sx={{ flex: 1, justifyContent: 'center', alignItems: 'center', }}>
                                            <Typography component={'h1'} variant='h6' sx={{ color: '#000000', textAlign: 'center' }}>
                                              {t('ATTENDANCE_REPORT.ATTENDANCE_VISIT_TIME')}
                                            </Typography>
                                        </Box>
                                        <Box display="flex" sx={{ flex: 2, justifyContent: 'center', alignItems: 'center', }}>
                                            <Typography component={'h1'} variant='h4' sx={{ color: '#000000', textAlign: 'center' }}>
                                                {metaData?.visits_time}
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Box>
                                <Box sx={{ m: 1 }} component="span" display="flex" bgcolor="white" flexDirection="row" >
                                    <Box display="flex" sx={{ backgroundColor: GridTheme?.secondaryColor, marginRight: '5px', borderWidth: 1, borderColor: '#132a3b', borderStyle: 'solid', borderRadius: '5px', flex: 1, justifyContent: 'center', alignItems: 'center', }}>
                                        <Box display="flex" sx={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                            <Typography component={'h1'} variant='h6' sx={{ color: GridTheme?.oppositeSecondaryColor, textAlign: 'center' }}>
                                               {t('ATTENDANCE_REPORT.ATTENDANCE_TOTAL_WORK_TIME')}
                                            </Typography>
                                        </Box>
                                        <Box display="flex" sx={{ flex: 2, justifyContent: 'center', alignItems: 'center', }}>
                                            <Typography component={'h1'} variant='h4' sx={{ color: '#FFFFFF', textAlign: 'center' }}>
                                                {metaData?.total_work_time}
                                            </Typography>
                                        </Box>

                                    </Box>
                                    <Box display="flex" sx={{ borderWidth: 1, borderColor: '#132a3b', borderStyle: 'solid', borderRadius: '5px', flex: 1, justifyContent: 'center', alignItems: 'center', }}>
                                        <Box display="flex" sx={{ flex: 1, justifyContent: 'center', alignItems: 'center', }}>
                                            <Typography component={'h1'} variant='h6' sx={{ color: '#000000', textAlign: 'center' }}>
                                            {t('ATTENDANCE_REPORT.ATTENDANCE_USER_WORKING_HOURS_PER_DAY')}
                                            </Typography>
                                        </Box>
                                        <Box display="flex" sx={{ flex: 2, justifyContent: 'center', alignItems: 'center', }}>
                                            <Typography component={'h1'} variant='h4' sx={{ color: '#000000', textAlign: 'center' }}>
                                                {metaData?.working_hours_per_day}
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Box>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            : null} */}
