
import React, { useEffect, useState } from 'react';
// import Pagination from "react-js-pagination";
import * as XLSX from 'xlsx';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import TelegramIcon from '@mui/icons-material/Telegram'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import moment from 'moment';
import { toast } from 'react-toastify';
import CircularIndeterminate from '../GeneralComponents/CirularProgress'
import StyledDataGrid from '../GeneralComponents/StyledDataGrid'
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { useGlobalContext } from '../../../context'
import FilterBox from '../Share/FilterBox';
import "../../../assets/css/DG.css"
import "../../../assets/css/AccordionStyle.css"
import BasicDatePicker from '../GeneralComponents/BasicDatePicker';
import Cookies from "universal-cookie";
import axios from "axios";
import Host from "../../../assets/js/Host";
import Grid from '../Share/Grid'
import {useSelector,useDispatch} from 'react-redux'
import {useTranslation} from "react-i18next";
import helpers from '../../../assets/js/helper'
import BasicDatePickerV2 from '../GeneralComponents/BasicDatePickerV2';
import SearchFieldV2 from '../GeneralComponents/SearchFieldV2'
import CustomeFilter from '../Share/CustomeFilter'
import {useLocation} from 'react-router-dom'
import PreviousFollowUp from './PreviousFollowUp';
import DropDownGrid from '../Share/DropDownGrid'
import {MenuItem,ListItemIcon} from '@mui/material'
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';

const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"

// const cookies = new Cookies();
// const token = cookies.get("token")
// const language = cookies.get("language")?cookies.get("language"):"ar"
function FollowupReport() {
    const {t, i18n} = useTranslation('common');
    const token = cookies.get("token")
    const [pageSize, setPageSize] = useState(5);
    const [pageNumber, setpageNumber] = useState(1);
    const [pointsTotal, setPointsTotal] = useState(0)
    const [pointsNumberOfPages, setPointsNumberOfPages] = useState(0)
    const filterSearchBoxMode = useSelector(state=> state.settingsData.filterSearchBoxMode)
    const  {state}=useLocation();

    const directions= useSelector(state => state.settingsData.directions)
    const GridTheme= useSelector(state => state.settingsData.GridTheme)
     
    const { loading,setFromToDate, setLoading, fromToDate, exportToCSV, user, users, setUser, visitFollowupsReport, clearPointsAdvanceSearchFields, fetchData, exportFollowupToCSV } = useGlobalContext()
    const SearchButtonTheme=useSelector(state=> state.settingsData.SearchButtonTheme);

    const [accExpansion, setAccExpansion] = useState(false)
    const [accExpansionMeta, setAccExpansionMeta] = useState(false)
    const FilterButtonHeight= useSelector(state => state.settingsData.FilterButtonHeight)
    const screenWidth=useSelector(state=>state.settingsData.screenWidth)

    const [showPreviousFollow,setShowPreviousFollow]=useState(false)
    const [selectFollowUp,setSelectFollowUp]=useState(null)
    const handleExpansion = (expansion) => setAccExpansion(expansion)
    const handleExpansionMeta = (expansionMeta) => setAccExpansionMeta(expansionMeta)

    const handlePageChange = (newPage) => {
        setpageNumber(newPage + 1)
    }

    const advanceSearch = async (type = "BROWSING",obj) => {
        console.log("tracking", 6);
        console.log("tracking user", user.id);
        console.log("user......");
        if(!obj){
        const fetchedData = await fetchData('VISIT_FOLLOWUPS_REPORT', 'visit_followups_report', {
            page: type === "SEARCH" ? 1 : pageNumber,
            page_size: pageSize,
            created_by: user.id,
             creation_date_from: fromToDate[0]?helpers.formatDate(fromToDate[0]):helpers.formatDate(new Date()),
            creation_date_to:fromToDate[1]?helpers.formatDate(fromToDate[1]):helpers.formatDate(new Date()),
        })
        return fetchedData
        }else{
            const fetchedData = await fetchData('VISIT_FOLLOWUPS_REPORT', 'visit_followups_report', {
                page: type === "SEARCH" ? 1 : pageNumber,
                page_size: pageSize,
                created_by: obj?.user_id,
                 creation_date_from: obj?.creation_date_from,
                creation_date_to:obj?.creation_date_to,
            })
            return fetchedData
        }
    }
    useEffect(()=>{
        // alert('cardadasdas',search)
        if(state&& state?.data){

            advanceSearch('SEARCH',{
                user_id: state?.data?.user?.id,
                creation_date_from:state?.data?.start_date,
                creation_date_to:state?.data?.end_date,
            })
            setUser({
                id:state?.data?.user?.id,
                full_name:state?.data?.user?.full_name,
            })
            console.log('asdakkdfjjsd==>',{
                id:state?.data?.user?.id,
                full_name:state?.data?.user?.full_name,
            })

            setFromToDate(new Date(state?.data?.start_date,fromToDate[1]))
            setFromToDate(fromToDate[0],new Date(state?.data?.end_date))

        }else{
            console.log('asdakkdfjjsd==>')
        }
    },[state])



    useEffect(() => {
        fetchData('USERS', 'users_list')
        advanceSearch()
    }, [pageSize, pageNumber])

    //Reformatting points object once points are loaded
    useEffect(() => {

        if (visitFollowupsReport && visitFollowupsReport?.data && visitFollowupsReport?.data?.length > 0) {
            const reformatVisits = visitFollowupsReport?.data?.map((rowVisit) => {
                return {
                    ...rowVisit,
                }
            })
            setRows(reformatVisits);

            setPointsTotal(visitFollowupsReport.total)
            setPointsNumberOfPages(visitFollowupsReport.last_page)
        }
        else {
            setRows([]);
            setPointsTotal(0)
            setPointsNumberOfPages(0)
        }
    }, [visitFollowupsReport])
    
    const [rows, setRows] = useState([])
    const columns = [
        { field: 'id', headerName:t('FOLLOWUP_REPORT.ID'), minWidth:35,flex:1 },
        {
            field: 'salesman', headerName: t('FOLLOWUP_REPORT.SALESMAN'), minWidth:150,flex:1,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} >
                    {rows?.row?.visit?.created_by?.full_name}
                </div>
            )
        },
        {
            field: 'customer', headerName: t('FOLLOWUP_REPORT.CUSTOMER'), minWidth:150,flex:1,
            renderCell: (rows) => (
                <div style={{ width: '100%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:directions=='rtl'?'end':'start'}} >
                    <span>{rows?.row?.visit?.point?.owner_name}</span>
                    <span>{rows?.row?.visit?.point?.name}</span>
                    <span>{rows?.row?.visit?.point?.phone}</span>
                </div>
            )
        },
        // {
        //     field: 'phone', headerName:t('FOLLOWUP_REPORT.PHONE'), minWidth:150,flex:1,
        //     renderCell: (rows) => (
        //         <div style={{ width: '100%' }} >
        //             {rows?.row?.visit?.point?.phone}
        //         </div>)
        // },
        {
            field: 'visit_total_time', headerName:t('FOLLOWUP_REPORT.START_DATE'), minWidth:200,flex:1,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} >
                    {rows?.row?.visit?.start_date}
                </div>
            )
        },
        {
            field: 'status', headerName: t('FOLLOWUP_REPORT.STATUS'), minWidth:150,flex:1,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} >

                    {rows?.row?.followup_status?.name}
                </div>
            )
        },
        {
            field: 'note', headerName: t('FOLLOWUP_REPORT.NOTE'), minWidth:250,flex:1,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} >
                    {rows?.row?.note}
                </div>
            )
        },
        {
            field: 'actions',
            // width: 100,
            minWidth:150,
            // maxWidth:250,
            // flex:4,

            headerName:  t('FOLLOWUP_REPORT.ACTIONS'),
            renderCell: (rows) => (
                <DropDownGrid>
                    <MenuItem onClick={() => {  ViewObject(rows.row)}}>
                        <ListItemIcon sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            margin:'0px 7px 0 0'
                        }}>
                        <FollowTheSignsIcon style={{ fill: "#111827" }} />

                        </ListItemIcon>
                        {directions != 'rtl' ? "Previous FollowUp" : 'المتابعات السابقة'}
                    </MenuItem>
                </DropDownGrid>
            ),
        },


    ];
    const ViewObject=(obj)=>{
        setSelectFollowUp(obj)
        console.log('fdgdfgdfgdfg=>',obj)
        setShowPreviousFollow(true);
    }

    const ExportFunction=async () => {
        const fetchedData = await fetchData('VISIT_FOLLOWUPS_REPORT', 'visit_followups_report', {
            page_size: pointsTotal,
            created_by: user.id,
            creation_date_from: moment(fromToDate[0])?.format('YYYY-MM-DD'),
            creation_date_to: moment(fromToDate[1])?.format('YYYY-MM-DD'),
        })
        const visitFollowupsArray = fetchedData?.data?.map((followup, index) => {

            return {
                "Salesname": followup?.visit?.created_by?.full_name,
                "Customer": followup?.visit?.point?.name,
                "Phone": followup?.visit?.point?.phone,
                "Start Date": followup?.visit?.start_date,
                "Status": followup?.followup_status?.name,
                "Note": followup?.note,
                "Date": followup?.created_at,
                "By": followup?.created_by?.full_name
            }
        })

        exportToCSV(visitFollowupsArray, 'visit_followups_report')
    }
    const createFunction=()=>{
        // createObject()
    }
    const mapFunction=async () => {
            // const fetchedData = await fetchData('ALL_POINTS', 'points', {
            //     page_size: pointsTotal,
            //     shop_name: shopName,
            //     owner_name: ownerName,
            //     district: district.id,
            //     region: region.id,
            //     point_type_id: pointStatus.map((status) => status.id),
            // })
            // mapObjects(fetchedData)
     }
    const importFunction=()=>{
        // importObjects()
    }


    return (
        <>
        {
            selectFollowUp&&showPreviousFollow?
             <PreviousFollowUp selectFollowUp={selectFollowUp} showPreviousFollow={showPreviousFollow} setShowPreviousFollow={setShowPreviousFollow} />:null
        }  
         {
                !filterSearchBoxMode?
                <FilterBox 
                    exportFunction={ExportFunction}
                    // createFunction={createFunction}
                    // mapFunction={mapFunction}
                    // importFunction={importFunction}
                    hasExport={rows?.length > 0 ?true:false} 
                    hasCreate={false} 
                    hasMaps={false} 
                    hasImport={false}
                    resultLength={pointsTotal}
                    customeHeight={screenWidth<=1100?'290px':"200px"}
                >
                            
                            <Autocomplete
                                sx={{ ml: 1,width:screenWidth<=1100?'98%':'48%',mt:1 }}
                                fullWidth
                                options={users&&users?.length>0&&users?.map((user)=>{
                                    return{
                                        id:user?.id,
                                        full_name:user?.full_name
                                    }
                                })}
                                getOptionLabel={(option) => option.full_name || ""}
                                id="disable-close-on-select"
                                value={user}
                                onChange={(e, v, r) => { setUser(v ? v : '');
                                 console.log('asdakkdfjjsd==>',v)
                            }}
                                renderInput={(params) => (
                                    <TextField {...params}
                                        label={t('GLOBAL.SEARCH_USERS')}
                                        variant="standard" sx={{borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}} />
                                )}
                            />
                            <BasicDatePicker  customeWidth={screenWidth<=1100?'98%':"50%"} customeMargin="-0px" customePaddingLeft="9px"/>
                            <Box sx={{display:'flex',justifyContent: 'end',alignItems: 'center',width:'100%'}}>
                                <Box display="flex" >
                                <Button
                                    variant="contained"
                                    spacing={2} 
                                    sx={{ flexGrow: 1, margin: 1,backgroundColor:SearchButtonTheme?.search_button_color,color:SearchButtonTheme?.search_button_text_color,
                                        '&:hover':{
                                            backgroundColor:SearchButtonTheme?.search_button_color+'88',
                                        },
                                        height:FilterButtonHeight
                                    }}
                                    className="iconeFilterSearch"
                                    onClick={() => advanceSearch("SEARCH")} >
                                    {t('GLOBAL.SEARCH_SEARCH_BUTTON')}
                                </Button>
                                <Button variant="outlined"
                                    spacing={2}
                                    sx={{
                                            flexGrow: 1, margin: 1,
                                            // backgroundColor:SearchButtonTheme?.clear_button_color,
                                            color:SearchButtonTheme?.clear_button_text_color,
                                        '&:hover':{
                                            backgroundColor:`${SearchButtonTheme?.clear_button_color}   !important`,
                                        },
                                        height:FilterButtonHeight
                                    }}
                                    className="iconeFilterClear"
                                    color="secondary"
                                    onClick={clearPointsAdvanceSearchFields} >
                                    {t('GLOBAL.SEARCH_CLEAR_BUTTON')}
                                </Button>
                        </Box>
                            </Box>
                    
                </FilterBox>:null
            }

            <Box 
             sx={{
                display:'flex',
                justifyContent: 'start',
                alignItems: 'start',
                width:'100%',
             }}
            >
                <Box
                    sx={{
                    width:'100%',
                    }}
                >
                    <Grid
                    rows={rows}
                    columns={columns}
                    rowTotals={pointsTotal}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    pageNumber={pageNumber}
                    setPageNumber={setpageNumber}
                    loading={loading}
                    handlePageChange={handlePageChange}
                    NumberOfPage={pointsNumberOfPages}
                    directions={directions}
                    hasCreate={false}
                    createObject={null}
                    title_en=""
                    title_ar=""
                    gridHeight={{
                        height:rows?.length>5?pageSize==5?400:pageSize==10?650:950:400,
                    }}
                    />
                </Box>

                {
                    filterSearchBoxMode?
                    <CustomeFilter
                        exportFunction={ExportFunction}
                        // createFunction={createFunction}
                        // mapFunction={mapFunction}
                        // importFunction={importFunction}
                        hasExport={rows?.length > 0 ?true:false} 
                        hasCreate={false} 
                        hasMaps={false} 
                        hasImport={false}
                        resultLength={pointsTotal}
                        // customeHeight={screenWidth<=1100?'290px':"200px"}
                    >
                        <Box
                            sx={{
                                width:'100%',
                                height:'400px',
                                minHeight:'400px',
                                maxHeight:'400px',
                                overflowY:'scroll',
                                overflowX:'hidden',
                                padding:'10px',
                                marginTop:'10px',
                                paddingTop:'20px',
                                // boxShadow:'0 0 7px -5px #000 inset'
                            }}
                        >
                                                
                            <Autocomplete
                                sx={{ ml: 0.5,width:screenWidth<=1100?'100%':'100%',marginTop:'10px !important',marginBottom:'10px !important' }}
                                fullWidth
                                options={users&&users?.length>0&&users?.map((user)=>{
                                    return{
                                        id:user?.id,
                                        full_name:user?.full_name
                                    }
                                })}
                                getOptionLabel={(option) => option.full_name || ""}
                                id="disable-close-on-select"
                                value={user}
                                onChange={(e, v, r) => { setUser(v ? v : ''); 
                                console.log('asdakkdfjjsd==>',v)
                             
                            }}
                                renderInput={(params) => (
                                    <TextField {...params}
                                        placeholder={t('GLOBAL.SEARCH_USERS')}
                                        variant="standard" sx={{borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}} />
                                )}
                            />
                            <BasicDatePickerV2  customeWidth={screenWidth<=1100?'100%':"100%"}  />
                        </Box>
                        <Box sx={{display:'flex',justifyContent: 'end',alignItems: 'center',width:'100%'}}>
                            <Box display="flex" >
                                    <Button
                                        variant="contained"
                                        // spacing={2} 
                                        sx={{ 
                                            // flexGrow: 1,
                                            margin: 1,
                                            width:'80px !important',
                                            minWidth:'80px !important',
                                            maxWidth:'80px !important',
                                            backgroundColor:SearchButtonTheme?.search_button_color,color:SearchButtonTheme?.search_button_text_color,
                                            '&:hover':{
                                                backgroundColor:SearchButtonTheme?.search_button_color+'88',
                                            },
                                            height:FilterButtonHeight-10
                                        }}
                                        className="iconeFilterSearch"
                                        onClick={() => advanceSearch("SEARCH")} >
                                        {t('GLOBAL.SEARCH_SEARCH_BUTTON')}
                                    </Button>
                                    <Button variant="outlined"
                                        // spacing={2}
                                        sx={{
                                            width:'50% !important',
                                                // flexGrow: 1, 
                                                minWidth:'80px !important',
                                                maxWidth:'80px !important',
                                                margin: 1,
                                                // backgroundColor:SearchButtonTheme?.clear_button_color,
                                                color:SearchButtonTheme?.clear_button_text_color,
                                            '&:hover':{
                                                backgroundColor:`${SearchButtonTheme?.clear_button_color}   !important`,
                                            },
                                            height:FilterButtonHeight-10
                                        }}
                                        className="iconeFilterClear"
                                        color="secondary"
                                        onClick={clearPointsAdvanceSearchFields} >
                                        {t('GLOBAL.SEARCH_CLEAR_BUTTON')}
                                    </Button>
                            </Box>
                        </Box>
                    </CustomeFilter>:null
                }
            </Box>

        </>
    );
}

export default FollowupReport;


