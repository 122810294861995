import React ,{useState,useEffect} from 'react'

import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import LanguageIcon from '@mui/icons-material/Language';
import { useSelector, useDispatch } from 'react-redux'
import { Redirect  } from "react-router";

import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import './Notifications.css'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';

import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import {setSideMenuMode,sideMenuMode,setDirections} from '../../reduxStore/SettingsReducer'
import LogoutIcon from '@mui/icons-material/Logout';
import Cookies from 'universal-cookie';
import LoginIcon from '@mui/icons-material/Login';
import {UserAppheader} from './StyledComponents/SideMenuStyleComponent'
import {useTranslation} from "react-i18next";
import {useNavigate} from 'react-router-dom'
import {ImagePath} from '../../../assets/js/Host'
import Skeleton from '@mui/material/Skeleton';
import { setSideMenuMode, sideMenuMode, setDirections ,setCompanyImage} from '../../reduxStore/SettingsReducer'
import axios from "axios";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import helpers from '../../../assets/js/helper'


const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"

const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme,ToolBarTheme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      backgroundColor: ToolBarTheme?.paperColor,
      color:ToolBarTheme?.disabledGlobalText?ToolBarTheme?.gloablTextColor:ToolBarTheme?.paperTextColor,
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
      '& .itmlist:hover': {
        backgroundColor:ToolBarTheme?.paperTextColor+'55',
      },
    },
  }));

export default function UserProfileAppBar({ToolBarTheme,Logout}) {
  const {t, i18n} = useTranslation('common');
  const navigate = useNavigate();
  const image= useSelector(state=> state.userData.image)
  const SideMenuOpen = useSelector(state => state.settingsData.SideMenuOpen) 
  const directions = useSelector(state=> state.settingsData.directions)
  const userName = useSelector(state => state.userData.userName)
  const userPhoneNumber = useSelector(state => state.userData.phone)
  const userRoleName = useSelector(state => state.userData.roleName)
  const companyImage=useSelector(state => state.settingsData.companyImage) 
  const token=cookies.get("token")
  const [loading,setLoading]=useState(false)
  const dispatch=useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (dire) => {
    setAnchorEl(null);
  };

  const signOut=()=>{
    Logout()
    
  }
  
  const signIn=()=>{
    // window.location.href='/'
    navigate('/')
  }

  const goToProfile=()=>{
      setAnchorEl(null);
      navigate('/profile')
  }

  const getCompany = async () => {
    // console.log('company request',company)
    // try {
      setLoading(true)
      const resourcesResult = await axios({
        url: Host + "users/update_logged_in_company",
        method: "patch",
        headers: {
          "Authorization": `Bearer ${token}`,
          Accept: "application/json",
          'X-localization': language,
        },
        data: {
          updated_logged_in_company_id: cookies.get('logged_in_company_id')?cookies.get('logged_in_company_id'):0,
        }
      })
      if (resourcesResult) {
         console.log('1-1')
          
        if (resourcesResult.status === 200) {
          const resources = resourcesResult.data;
           console.log(resourcesResult.data)
           
           
        } else if (resourcesResult.data.status === false) {
        
        }
      }
      else {
     
      }


      setLoading(false)
    // } catch (error) {
    //   console.log("error",error);
    //   setLoading(false)
    //   setSelectedCompany({
    //     id: "",
    //     name: t('APPBAR.TOOLTIP_SELECT_A_COMPANY')
    //   })
    //   toast.error("Network Error")
    // }
    setAnchorEl(null);
  };
  
  useEffect(()=>{
    getCompany()
  },[])
  const getImage=(company)=>{
    let imageSrc="";
    if(company?.image?.image_url)
       imageSrc=helpers.getImageUrl(company?.image?.image_url)
    else
     imageSrc='https://i.pinimg.com/originals/51/10/19/51101970f30856c14fe25bbfbaae2817.png'
    
     return imageSrc;

  }

  const getImageurllink=()=>{
    let imageurl=cookies.get('companyImage');
    if(imageurl&& imageurl!==null){

    }else{

    }
  }

  return (
    <React.Fragment>
        <UserAppheader bgColor={ToolBarTheme?.disabledGlobalText?ToolBarTheme?.gloablTextColor:ToolBarTheme.paperTextColor} lightOrDark={ToolBarTheme?.light?'light':"dark"} className="userAppheader" 
            aria-controls={open ? 'demo-customized-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            SideMenuOpen={SideMenuOpen}
        >
            <div className="textuserAppher">
              <p style={{color:ToolBarTheme?.disabledGlobalText?ToolBarTheme?.gloablTextColor:ToolBarTheme?.paperTextColor}}>{cookies.get('full_name')?cookies.get('full_name'):''}</p>
              {/* <p className="phone">{cookies.get('username')?cookies.get('phone'):''}</p> */}
            </div>
            <img className="imgUserAppheader" src={cookies.get('companyImage')&&cookies.get('companyImage')!==null?ImagePath+cookies.get('companyImage'):"https://cdn-icons-png.flaticon.com/512/3688/3688596.png"} alt="" />
    
        </UserAppheader>

        <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        ToolBarTheme={ToolBarTheme}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {
         cookies.get("token")?
        <MenuItem onClick={()=>goToProfile()} disableRipple className={`itmlist ${directions!=='rtl'?'':'languageNormal arabicDirection'}`}>
        {
          
          directions=='rtl'?
            <>
              {t('APPBAR.MY_PROFILE')}
              <AccountCircleIcon  style={{margin:"0 5px",color:ToolBarTheme?.disabledGlobalText?ToolBarTheme?.gloablTextColor:ToolBarTheme?.paperTextColor}}/>
            </>
            :
            <>
              <AccountCircleIcon  style={{margin:"0 5px",color:ToolBarTheme?.disabledGlobalText?ToolBarTheme?.gloablTextColor:ToolBarTheme?.paperTextColor}}/>
              {t('APPBAR.MY_PROFILE')}
            </>
           
          }
        </MenuItem>
        :null
        }


        {
         cookies.get("token")?
           <Divider sx={{ my: 0.5 }} />
          :null
        }
        {
            cookies.get("token")?
            <MenuItem onClick={()=>signOut()} disableRipple className={`itmlist ${directions!=='rtl'?'':'languageNormal arabicDirection'}`}>
            {
            directions=='rtl'?
                <>
                {t('APPBAR.SIGN_OUT')}
                <LogoutIcon style={{margin:"0 5px",color:ToolBarTheme?.disabledGlobalText?ToolBarTheme?.gloablTextColor:ToolBarTheme?.paperTextColor}}/>
                </>
                :
                <>
                <LogoutIcon style={{margin:"0 5px",color:ToolBarTheme?.disabledGlobalText?ToolBarTheme?.gloablTextColor:ToolBarTheme?.paperTextColor}}/>
                {t('APPBAR.SIGN_OUT')}
                </>
            }
            </MenuItem>
            :
            <MenuItem onClick={signIn} disableRipple className={`itmlist ${directions!=='rtl'?'':'languageNormal arabicDirection'}`}>
            {
            directions=='rtl'?
                <>
                  {t('APPBAR.SIGN_IN')}
                <LoginIcon style={{margin:"0 5px",color:ToolBarTheme?.disabledGlobalText?ToolBarTheme?.gloablTextColor:ToolBarTheme?.paperTextColor}}/>
                </>
                :
                <>
                <LoginIcon style={{margin:"0 5px",color:ToolBarTheme?.disabledGlobalText?ToolBarTheme?.gloablTextColor:ToolBarTheme?.paperTextColor}}/>
                  {t('APPBAR.SIGN_IN')}
                </>
            }
            </MenuItem>
        }

        </StyledMenu>
    </React.Fragment>
  )
}
