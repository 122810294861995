import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Pane, Dialog } from 'evergreen-ui';
import axios from "axios";
// import Select from "react-select";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import {TextField,Box} from '@mui/material'
import {useSelector,useDIspatch, useDispatch} from 'react-redux'
import DialogForm from "../Share/DialogForm";
import {
    ContainerOfForm,
    SaveButton,
    CancelButton,
    Form
} from '../Users/StyledComponents';
import FooterBottons from '../Share/FooterBottons'
import {useTranslation} from "react-i18next";
import { Divider, Switch, CardMedia, DialogTitle, IconButton,Typography, Select, InputLabel, MenuItem, FormControl, FormControlLabel, Button, Avatar } from '@mui/material';
import {styled} from '@mui/material/styles'
import helpers from "../../../assets/js/helper";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import {setNewLanguageAdded} from '../../reduxStore/SettingsReducer'

const CustomeSelect = styled(Select)(({ theme, open,MainTheme, }) => ({
    //  backgroundColor:MainTheme?.paperTextColor+'88',
     color:MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme?.paperTextColor,
     '& div ul':{
        color:`${MainTheme?.paperTextColor}`,
        backgroundColor:`${MainTheme?.paperColor+'33'}`,
      }
  }));

const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"
export const LanguageForm = (props) => {
    const [loading, setloading] = useState(true);
    const directions =useSelector(state=>state.settingsData.directions);
    const MainTheme =useSelector(state=>state.settingsData.MainTheme);
    const [t, i18n] = useTranslation('common');
    const token = cookies.get("token")
    const [object, setobject] = useState({
        id: null,
        name: null,
        code:null,
        rtl:0,
        image:null,
        imgBinaryData:null,
        demUrl:null,
    });
    const newLanguageAdded=useSelector(state=>state.settingsData.newLanguageAdded)
    const dispatch=useDispatch()
   

    const submitForm = async () => {
          if(!object?.name){
            toast.warn('name is required')
            return
          }else if(!object?.code){
            toast.warn('code of Country is required')
            return
          }
        try {

            const formData=new FormData()
            if(object?.name){
                formData.append('name',object?.name)
            }
            if(object?.code){
                formData.append('code',object?.code)
            }
            // if(object?.rtl){
                formData.append('rtl',object?.rtl)
            // }
            if(object?.imgBinaryData){
                formData.append('image',object?.imgBinaryData)
            }
            // formData.append('font_regular',"'Cairo', sans-serif")
            if (object.id) {
                formData.append('_method','put')
                const resourcesResult = await axios({
                    url: Host + "languages/"+object.id,
                    method: "post",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                        'Content-Type':'multipart/form-data'
                    },
                    data: formData
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("updated ")
                        props.setshowLanguageForm(false,true)
                        dispatch(setNewLanguageAdded(!newLanguageAdded))
                    } else if (resourcesResult.data.status === false) {
                        props.setshowLanguageForm(true,false)
                    }

                }
                else {
                    toast.error("Error")
                    props.setshowLanguageForm(true,false)
                }
                setloading(false)
            }
            else {
                const resourcesResult = await axios({
                    url: Host + "languages",
                    method: "post",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                        'Content-Type':'multipart/form-data'

                    },
                    data: formData
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("saved ")
                        props.setshowLanguageForm(false,true)
                        dispatch(setNewLanguageAdded(!newLanguageAdded))

                    } else if (resourcesResult.status === 400) {
                        toast.error(resourcesResult.data)
                        props.setshowLanguageForm(true,false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setshowLanguageForm(true,false)
                }
                setloading(false)
            }
        } catch (error) {
            props.setshowLanguageForm(true,false)
            const resourcesResult=error.response            
            if (resourcesResult && resourcesResult.status === 400) {
                if(typeof resourcesResult.data.data === "object")
                {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key)=>{
                    
                        resourcesResult.data.data[key].map((message)=>{
                            toast.error(message)
                        })
                    })
                }
                else{
                    
                    toast.error(resourcesResult.data.data)
                }
                
                
            }
            else if(resourcesResult && resourcesResult.status === 401) {
             
                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else
            {
              
                toast.error("network error")
            }
        }
        
    };


    useEffect(() => {
        if(props&& props.language&& props.language.id>0)
        {
            setobject({...object,...props.language,
                demUrl:helpers.getImageUrl(props.language?.image?.image_url)
            });
        }
        else{
            setobject(
                {
                    id: null,
                    name: null,
                }
            )
        }

        
        return () => {

        };
    }, [props.showLanguageForm]);


    const checkDarknessOrLightnessForColor=(color)=>{
        // Variables for red, green, blue values
        var r, g, b, hsp;
      
        // Check the format of the color, HEX or RGB?
        if (color.match(/^rgb/)) {
    
            // If RGB --> store the red, green, blue values in separate variables
            color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
            
            r = color[1];
            g = color[2];
            b = color[3];
        } 
        else {
            
            // If hex --> Convert it to RGB: http://gist.github.com/983661
            color = +("0x" + color.slice(1).replace( 
            color.length < 5 && /./g, '$&$&'));
    
            r = color >> 16;
            g = color >> 8 & 255;
            b = color & 255;
        }
        
        // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
        hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
        );
    
        // Using the HSP value, determine whether the color is light or dark
        if (hsp>127.5) {
    
            return false;//light
        } 
        else {
    
            return true;//dark
        }
  
    };

    const check=checkDarknessOrLightnessForColor(MainTheme?.paperTextColor)
    

    const styleTextField={
        // backgroundColor:!check?'rgb(255,255,255,0.7)':'rgb(0,0,0,0.4)',
        // color:MainTheme?.paperTextColor,
    }

    const styleSelect={
        // backgroundColor:!check?'rgb(255,255,255,0.7)':'rgb(0,0,0,0.4)',
        // color:MainTheme?.paperTextColor,
    }
    const choceImageFile=()=>{
        const imagefile=document.getElementById('ImageUpload')
        if(imagefile)imagefile.click()
    }

    const handleuploadimage=(e)=>{
     setobject({
        ...object,
        imgBinaryData:e.target.files[0],
        demUrl:window.URL.createObjectURL(e.target.files[0])
     })
    }

    return (
        <DialogForm
           open={props.showLanguageForm}
           close={props.setshowLanguageForm}
           CustomeWidth='50%'
           CustomeHeight="fit-content"
        >
            <form >
                <Box
                  sx={{
                    '& .muiltr-a4ms82-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root,& svg,& .muirtl-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':{
                        color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                     },
                    '& input':{
                        color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                    
                        },
                        '& .MuiOutlinedInput-root':{
                            borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
                            color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                        },
                        '& .MuiInputLabel-root':{
                            // backgroundColor:`${MainTheme?.paperTextColor+'22'} !important`,
                            color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                        },
                        '& .MuiFormControl-root':{
                          // border:`1px solid ${MainTheme?.paperTextColor} !important`,
                          color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                        },
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': {
                            borderColor:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                          },
                          '&:hover fieldset': {
                            borderColor:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                          },
                          '&.Mui-focused fieldset': {
                            borderColor: MainTheme?.secondaryColor,
                            color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.secondaryColor,

                          },
                        },
                        '& .MuiOutlinedInput':{
                          // borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            // border: "0 none",
                         },
                  }}
                >
                
                <div className="form-group">
                    {/* <label htmlFor="name" style={{display:'flex',justifyContent:'start'}}>{t('ROLES.FORMADD_NAME')}</label>
                    <input className="form-control" 
                        id="name"
                        value={object.name}
                        onChange={event => { setobject({ ...object, name: event.target.value }); }}
                        style={{...styleTextField}}
                     /> */}
                     <Box
                      style={{
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'space-evenly',
                        width:'100%'

                      }}
                     >
                        <TextField
                        id="name"
                        fullWidth
                        sx={{marginTop:'10px',width:'48%',margin:'10px 7px'}}
                        label={t('LANGUAGES.form_name')}
                        value={object.name}
                        onChange={event => { setobject({ ...object, name: event.target.value }); }}
                        variant="standard"
                        />
                        <TextField
                        id="code"
                        fullWidth
                        sx={{marginTop:'10px',width:'48%',margin:'10px 7px'}}
                        label={t('LANGUAGES.form_code')}
                        value={object?.code}
                        onChange={event => { setobject({ ...object, code: event.target.value }); }}
                        variant="standard"
                        />
                     </Box>
                     <Box style={{
                        display:'flex',
                        alignItems:'center',
                        justifyContent:'space-evenly',
                        width:'100%'
                     }}>
                            <FormControl variant="standard" fullWidth sx={{ ...styleSelect, marginTop: '10px',width:'50%',
                                }}>
                                <InputLabel id="department-select-label" sx={{ color: MainTheme?.paperColor }}>{t('LANGUAGES.form_rtl')}</InputLabel>
                                <CustomeSelect
                                    MainTheme={MainTheme}
                                    labelId="department-select-label"
                                    id="department-select"
                                    sx={{ textAlign: 'left', }}
                                    value={object?.rtl}
                                    label={t('LANGUAGES.form_rtl')}
                                    onChange={(event) => {
                                        setobject({
                                            ...object, 
                                             rtl:event?.target.value,
                                        });
                                    }}
                                >
                                    

                                 <MenuItem   value={1}>rtl</MenuItem>
                                 <MenuItem   value={0}>ltr</MenuItem>



                                </CustomeSelect>
                            </FormControl>
                            <Box style={{position:'relative'}}>
                                <img
                                  src={object?.demUrl?object?.demUrl:null}
                                  style={{
                                    width:'200px',
                                    height:'200px',
                                    borderRadius:'50%',
                                    backgroundColor:'lightgrey',
                                    cursor:'pointer',
                                    objectFit:'cover',
                                    backgroundPosition:'center center'
                                  }}
                                  onClick={choceImageFile}
                                />
                                <CameraAltIcon style={{position:'absolute',top:'50%',left:'50%',transform:'translate(-50%,-50%)'}}/>
                                <input type="file" id='ImageUpload' accept="image/x-png,image/gif,image/jpeg" style={{display:'none'}} onChange={handleuploadimage}/>
                            </Box>
                     </Box>
                </div>
                    
                </Box>
            </form>

         
            <FooterBottons close={props.setshowLanguageForm} submitForm={submitForm} />
       </DialogForm>

    );
};
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'blue',

    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
        width: '100%',
        border: '1px solid #ababab',
        borderRadius: 5,
        padding: 0,
        display: 'flex'
    }),
    container: () => ({

        width: '80%',
        position: 'relative',
        boxSizing: 'border-box',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}

export default LanguageForm;
