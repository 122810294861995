import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Pane, Dialog } from 'evergreen-ui';
import axios from "axios";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import OrgChartTree from "./OrgChartTree";
import helpers from "../../../../src/assets/js/helper"
import CustomTree from "./CustomTree";
import {useSelector,useDIspatch} from 'react-redux'
import DialogForm from "../Share/DialogForm";
import {useTranslation} from "react-i18next";

import {
    ContainerOfForm,
    SaveButton,
    CancelButton,
    Form
} from '../Users/StyledComponents';
import FooterBottons from '../Share/FooterBottons'

const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"
export const SurveyView = (props) => {
    const [t, i18n] = useTranslation('common');
    const [loading, setloading] = useState(true);
    const [selectedPoint, setselectedPoint] = useState({
        id:null,
        name:''
    });
    const token = cookies.get("token")
    const [surveyFields, setsurveyFields] = useState([]);
    const [object, setobject] = useState({
        id: null,
        name: null,
        point:{
            id:null,
            name:null
        },
        point_id:null,
        point_type_id: null,
        point_type:{
            id:null,
            name:null
        }
    });

    const directions =useSelector(state=>state.settingsData.directions);
    const MainTheme =useSelector(state=>state.settingsData.MainTheme);

    

    // var stringToColour = (str) => {
    //     var colour = '#EDEDED';
    //     if(str)
    //     {
    //         var hash = 0;
    //         for (var i = 0; i < str.length; i++) {
    //             hash = str.charCodeAt(i) + ((hash << 5) - hash);
    //         }
    //         colour = '#';
    //         for (var i = 0; i < 3; i++) {
    //             var value = (hash >> (i * 8)) & 0xFF;
    //             colour += ('00' + value.toString(16)).substr(-2);
    //         }
    //     }
        
    //     return colour;
    // }

    // const recursionCategory = (data) =>{
    //     var parent=[];
    //     for (let item = 0; item < data.length; item++) {
           
         
           
    //         var child= {
    //             name: data[item].name,
    //             attributes: {
    //                 id: data[item].id,
    //                 color:stringToColour(data[item].condition_value),
    //                 default_value: data[item].default_value,
    //                 is_mandatory: data[item].is_mandatory,
    //                 is_multiple_select: data[item].is_multiple_select,
    //                 has_condition:data[item].has_condition,
    //                 condition_value:data[item].condition_value,
    //                 field_type_name: data[item]["field_type"].name,
    //                 field_type_has_list: data[item]["field_type"].has_list,
    //             }
    //         }

            
    //         var children=data[item].children;
    //         var childrenMapped=null
    //         if(children!=null && children.length>0){
    //             childrenMapped = recursionCategory(children)
    //         }
    //         parent.push({
    //             ...child,
    //             children:childrenMapped
    //         })
    //     }
    //     return parent;
    // }
    const getResources = async () => {
        console.log('kljlkajdlkajdlkasjd',props?.survey)

        try {
            setloading(true)
            const resourcesResult = await axios({
                url: Host + "survey_fields?survey_id="+props?.survey?.id,
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                }
            })
            if (resourcesResult) {
                if (resourcesResult?.status&&resourcesResult?.status === 200) {
                    if(resourcesResult&&resourcesResult?.data!=null){
                        const resources = resourcesResult?.data;
                        console.log('kljlkajdlkajdlkasjd',resources)

                      console.log('kljlkajdlkajdlkasjd','hhhhhhhhhhhhh')

                        var child= {
                            name: resources?.name,
                            attributes: {
                                id: resources?.id?resources?.id:'',
                                default_value: resources?.default_value?resources?.default_value:'',
                                is_mandatory: resources?.is_mandatory? resources?.is_mandatory:'',
                                is_multiple_select: resources?.is_multiple_select?resources?.is_multiple_select:'',
                                has_condition:resources?.has_condition?resources?.has_condition:'',
                                condition_value:resources?.condition_value?resources?.condition_value:'',
                                field_type_name: resources["field_type"]&&resources["field_type"]?.name?resources["field_type"]?.name:'',
                                field_type_has_list: resources["field_type"]&&resources["field_type"]?.has_list?resources["field_type"]?.has_list:'',
                                
                            }
                        }

                        var children=resources?.children?resources?.children:[];
                        var childrenMapped=null
                        if(children!=null && children.length>0){
                            childrenMapped = helpers.recursionCategory(children)
                        }
                        const parent={
                            ...child,
                            children:childrenMapped
                        }
                        console.log(parent);
                        setsurveyFields(parent)
                    }
                    
                } else if (resourcesResult?.data&&resourcesResult?.data?.status === false) {

                }
            }
            else {
                toast.error("Error")
            }
            setloading(false)
        } catch (error) {
            console.log(error?.message);
            setloading(false)
            toast.error("Network Error")
        }
    };

    const submitForm = async () => {
        
        try {
            
        
            var objectToSend=object
            if(!object.point_id)
            {
                objectToSend={
                    id:objectToSend.id,
                    name:objectToSend.name,
                    point_type_id:objectToSend.point_type_id
                }
            }
            if(!object.point_type_id)
            {
                objectToSend={
                    id:objectToSend.id,
                    name:objectToSend.name,
                    point_id:objectToSend.point_id
                }
            }
            if (object.id) {

                const resourcesResult = await axios({
                    url: Host + "surveys/"+object.id,
                    method: "put",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data: objectToSend
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("updated ")
                        props.setshowSurveyView(false,true)
                    } else if (resourcesResult.data.status === false) {
                        props.setshowSurveyView(true,false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setshowSurveyView(true,false)
                }
                setloading(false)
            }
            else {
                const resourcesResult = await axios({
                    url: Host + "surveys",
                    method: "post",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data: objectToSend
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("saved ")
                        props.setshowSurveyView(false,true)
                    } else if (resourcesResult.status === 400) {
                        toast.error(resourcesResult.data)
                        props.setshowSurveyView(true,false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setshowSurveyView(true,false)
                }
                setloading(false)
            }
        } catch (error) {
            props.setshowSurveyView(true,false)
            const resourcesResult=error.response            
            if (resourcesResult && resourcesResult.status === 400) {
                if(typeof resourcesResult.data.data === "object")
                {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key)=>{
                    
                        resourcesResult.data.data[key].map((message)=>{
                            toast.error(message)
                        })
                    })
                }
                else{
                    
                    toast.error(resourcesResult.data.data)
                }
                
                
            }
            else if(resourcesResult && resourcesResult.status === 401) {
             
                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else
            {
              
                toast.error("network error")
            }
        }
        
    };

    const setselectedObject=(point)=>{
        setselectedPoint(point)
        setobject({
            ...object,
            point_id:point?.id,
            point:point
        })
    }

    useEffect(() => {
        
        
        if(loading)
          getResources()
        return () => {

        };
    }, [loading,props.showSurveyView]);


    console.log(props.showSurveyView);
    return (
        loading ? <></> :
        <DialogForm
          open={props.showSurveyView}
          close={props.setshowSurveyView}
          headerChildren={(
            <>
              <h4 style={{display: 'flex', justifyContent:'start'}}>{t('SURVEY.HEADER_TITLE')}</h4>
            </>
          )}
          hasHeader={true}
          CustomeWidth="80%"
          CustomeHeight="fit-content"
        >
    
            
            
            <div style={{height:"500px" ,color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor}}>
                {
                    surveyFields?
                    <CustomTree surveyFields={surveyFields} color={props?.color} bgColor={props?.bgColor}/>
                    :null
                 }
            </div>
                    
            <br />
            <br />
            <FooterBottons close={props.setshowSurveyView} submitForm={submitForm} />
                
                {/* <OrgChartTree surveyFields={surveyFields} /> */}
        </DialogForm>

    );
};
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'blue',

    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
        width: '100%',
        border: '1px solid #ababab',
        borderRadius: 5,
        padding: 0,
        display: 'flex'
    }),
    container: () => ({

        width: '80%',
        position: 'relative',
        boxSizing: 'border-box',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}

export default SurveyView;
