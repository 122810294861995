import React, { useEffect,useState,useMemo,useCallback } from 'react';
import {useTranslation} from "react-i18next";


import {
   Chart, Series ,AdaptiveLayout,CommonSeriesSettings, Legend, Export, Tooltip, Title,Size,
   CommonAxisSettings,
    ArgumentAxis,
    Label,
    ValueAxis,DevExpress
  } from 'devextreme-react/chart';
import { Box } from '@mui/material';
import {useSelector,useDispatch} from 'react-redux'


  
const  BarCharts =(props)=>{
    const MainTheme=useSelector(state=>state.settingsData.MainTheme)
    const directions=useSelector(state=>state.settingsData.directions)
    const SideMenuOpen = useSelector(state => state.settingsData.SideMenuOpen)
    const sideMenuMode = useSelector(state => state.settingsData.sideMenuMode)
    const SearchButtonTheme=useSelector(state=> state.settingsData.SearchButtonTheme);

    const drawerWidth=useSelector(state=>state.settingsData.drawerWidth)
    const screenWidth=useSelector(state=>state.settingsData.screenWidth)
    const {t, i18n} = useTranslation('common');

    const [dataSource,setDataSource]=useState([])


    useEffect(()=>{
        var arr=[];
       if(props?.productivityData){
       
          props?.productivityData?.map((itm)=>{
            
               arr.push({
                 day:itm?.date,
                 Visit_Time_Percentage:itm?.visit_time_percentage,
                 Road_Time_Percentage:itm?.road_time_percentage
               })
          })
       }
       setDataSource(arr)
    },[props?.productivityData])

    var renderOptions = {
      force: true, // forces redrawing
      animate: false // redraws the UI component without animation
    }

    const getWidthOfCharts=useMemo(()=>{
        let width=screenWidth;
        if(SideMenuOpen){
          width-= (drawerWidth+60);
        }
        if(sideMenuMode){
          width-= (85);
        }
        if(screenWidth<=1150){
          return '100%'
        }
        if(props?.numberOfDays>31){
          return '1600'
        }
        return `${width}px`;
    },[SideMenuOpen,screenWidth,props?.numberOfDays,sideMenuMode])
   
    const customizeTooltip=(arg)=>{
      return {
        text: `<strong>${arg.percentText}</strong>\n ${arg?.argument}`,
      };
    }

    return (
     
            
            <Chart 
              redrawOnResize
              renderOptions={dataSource&&dataSource?.length>0?renderOptions:[]}
              id="chart" 
              dataSource={dataSource} 
              style={{
                width:getWidthOfCharts,
                height:props?.customeHeight,
                // overflowX:'scroll',
                overflowY:'hidden',
                margin:'20px 10px 10px 10px',
              }} 
            >
              
                <Size
                    height={props?.customeHeight}
                    width={getWidthOfCharts}
                />
                <ArgumentAxis>
                <Label
                  overlappingBehavior={'rotate'}
                  displayMode="rotate"
                />
              </ArgumentAxis>
                <Legend verticalAlignment="top"
                  horizontalAlignment="center"
                  itemTextPosition="right"
                  css={{
                    width:'100%',
                  }}
                />
                <CommonSeriesSettings argumentField="day" type="stackedBar" />
                <Series
                  valueField="Visit_Time_Percentage"
                  // argumentField="day"
                  name={props?.boxTitle_1}
                  // type="bar"
                  color={SearchButtonTheme&&SearchButtonTheme?.visit_time_color?SearchButtonTheme?.visit_time_color:"#FF0000"}
                />
                <Series
                    valueField="Road_Time_Percentage"
                    // argumentField="day"
                    name={props?.boxTitle_2}
                    // type="bar"
                  color={SearchButtonTheme&&SearchButtonTheme?.road_time_color?SearchButtonTheme?.road_time_color:"#2188bf"}

                />
                <Tooltip
                  enabled={true}
                  location="edge"
                  customizeTooltip={customizeTooltip}
                />
            </Chart>
          
    );
}

export default BarCharts;