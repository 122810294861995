
import React, { useEffect, useState } from 'react';
import {styled} from '@mui/material/styles'
// import Pagination from "react-js-pagination";
import Cookies from "universal-cookie";
import axios from "axios";
import { Avatar } from "evergreen-ui"
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
// import VisitForm from './VisitForm';
// import VisitMapDialog from './VisitMapDialog';
import host from '../../../assets/js/Host';
// import VisitPreview from './VisitPreview';
// import VisitMapLocation from './VisitMapLocation';
import IconButton from "@mui/material/IconButton";
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import BasicDateRangePicker from '../GeneralComponents/DateFromTo'
import moment from 'moment';
import {useSelector,useDispatch} from 'react-redux'
import Tooltip from '@mui/material/Tooltip';
import CircularIndeterminate from '../GeneralComponents/CirularProgress'
import StyledDataGrid from '../GeneralComponents/StyledDataGrid'
import Box from '@mui/material/Box';
// import Pagination from '@mui/material/Pagination';
import { useGlobalContext } from '../../../context'
import Pagination from './Pagination';
import "../../../assets/css/DG.css"
import "../../../assets/css/AccordionStyle.css"
import SearchField1 from '../GeneralComponents/SearchField1'
import BasicDatePicker from '../GeneralComponents/BasicDatePicker';
import BasicDatePickerV2 from '../GeneralComponents/BasicDatePickerV2';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Checkbox, FormControlLabel } from '@mui/material';
import PointsMapDialog from '../Points/PointsMapDialog';
import {useTranslation} from "react-i18next";
// import FilterBox from './FilterBox';
// import Grid from '../Share/Grid';
import * as GrIcone from 'react-icons/gr'
import DropDownGrid from './DropDownGrid'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import MapRoundedIcon from '@mui/icons-material/MapRounded';
import PublishRoundedIcon from '@mui/icons-material/PublishRounded';
import { MenuItem ,ListItemIcon} from '@mui/material';
import helpers from '../../../assets/js/helper'
import AddIcon from '@mui/icons-material/Add';
import MapIcon from '@mui/icons-material/Map';

import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
// import MapIcon from "@mui/icons-material/Map";
// import FileUploadIcon from "@mui/icons-material/FileUpload";
import TelegramIcon from "@mui/icons-material/Telegram";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import MoreVertIcon from '@mui/icons-material/MoreVert';

const StyledSpeedDial = styled(SpeedDial)(({ theme,GridTheme }) => ({
    // position: 'relative',
    position: 'relative',
    // top:0,
    // left:'50px',
    // zIndex:3000,
    // display:'block',
    width:'50px',
    height:'36px',
    '& .muiltr-1i5alow-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab':{
      width:40,
      height:40,
      backgroundColor:`${GridTheme?.primaryColor}44 !important`,
  
    },
    backgroundColor:`${GridTheme?.primaryColor}44 !important`,
    padding:0,
    // '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
    //   bottom: 0,
    //   right:0,
    // },
    // '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
    //   top: 0,
    //   left: 0,
    //   backgroundColor:`${GridTheme?.primaryColor}44 !important`,
    // },
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
  }));

export default function CustomeFilter(props) {
    const {t, i18n} = useTranslation('common');
    const sideMenuMode = useSelector(state => state.settingsData.sideMenuMode)
    const SearchButtonTheme=useSelector(state=> state.settingsData.SearchButtonTheme);
    const toastId = React.useRef(null);
    const FilterButtonHeight =useSelector(state=>state.settingsData.FilterButtonHeight)
    const directions = useSelector(state => state.settingsData.directions)
    const GridTheme = useSelector(state => state.settingsData.GridTheme)
    const MainTheme = useSelector(state => state.settingsData.MainTheme)
    const [actions,setActions]=useState([])
    const [hidden, setHidden] = React.useState(false);
    const {search_button_color,clear_button_color,search_button_text_color,clear_button_text_color} =SearchButtonTheme

    useEffect(()=>{
        const emptyArray=[];
        if(props?.hasExport){
          emptyArray.push({ icon: <FileDownloadIcon onClick={props?.exportFunction} sx={{color:SearchButtonTheme?.export_button_text_color+" !important"}} />,color:SearchButtonTheme?.export_button_color, name: t('TOOLTIP.EXPORT') })
         }
         if(props?.hasCreate){
          emptyArray.push({ icon: <AddCircleOutlineIcon onClick={props?.createFunction} sx={{color:SearchButtonTheme?.create_button_text_color+" !important"}} />,color:SearchButtonTheme?.create_button_color, name: t('TOOLTIP.CREATE') })
         }
         if(props?.hasMaps){
          emptyArray.push({ icon: <MapIcon onClick={props?.mapFunction} sx={{color:SearchButtonTheme?.map_button_text_color+" !important"}} />,color:SearchButtonTheme?.map_button_color, name: t('TOOLTIP.MAP') })
         }
         if(props?.hasImport){
          emptyArray.push({ icon: <FileUploadIcon onClick={props?.importFunction} sx={{color:SearchButtonTheme?.import_button_text_color+" !important"}} />,color:SearchButtonTheme?.import_button_color, name: t('TOOLTIP.IMPORT') })
         }
         if(props?.hasTelegram){
          emptyArray.push({ icon: <TelegramIcon onClick={props?.telegramFunction} sx={{color:SearchButtonTheme?.telegram_button_text_color+" !important"}} />,color:SearchButtonTheme?.telegram_button_color, name: t('TOOLTIP.SEND_TELEGRAM')})
         }
         if(props?.hasView){
          emptyArray.push({ icon: <RemoveRedEyeIcon onClick={props?.viewFunction} sx={{color:SearchButtonTheme?.telegram_button_text_color+" !important"}} />,color:SearchButtonTheme?.telegram_button_color, name: t('TOOLTIP.VIEW') })
         }
        setActions(emptyArray);
     },[props,SearchButtonTheme])
     
  return (
    <Box
      sx={{
        width: "300px !important",
        minWidth: "300px !important",
        maxWidth: "300px !important",
        minHeight: "525px",
        maxHeight: window.innerHeight-30,

        backgroundColor: GridTheme?.paperColor,
        boxShadow: `0 0 7px -2px ${
          GridTheme?.disabledGlobalText
            ? GridTheme.gloablTextColor
            : GridTheme?.paperTextColor
        }`,
        borderRadius: "5px",
        margin: "0px 10px 0 10px",
        // paddingTop:'10px',
        // paddingBottom:'20px',

        // padding: "0 10px",
        '& p , & span ,& .MuiInputLabel-root' :{
          fontSize:"15px !important"
       },
  
      '& .MuiOutlinedInput-root':{
          borderColor:`1px solid ${GridTheme?.paperTextColor} !important`,
          color:GridTheme?.disabledGlobalText?GridTheme.gloablTextColor:GridTheme?.paperTextColor,
      },
      '& .MuiInputLabel-root':{
          // backgroundColor:`${GridTheme?.paperTextColor+'22'} !important`,
          color:GridTheme?.disabledGlobalText?GridTheme.gloablTextColor:GridTheme?.paperTextColor,
      },
      '& .MuiFormControl-root':{
        // border:`1px solid ${GridTheme?.paperTextColor} !important`,
        color:GridTheme?.disabledGlobalText?GridTheme.gloablTextColor:GridTheme?.paperTextColor,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: GridTheme?.disabledGlobalText?GridTheme.gloablTextColor:GridTheme?.paperTextColor,
        },
        '&:hover fieldset': {
          borderColor: GridTheme?.disabledGlobalText?GridTheme.gloablTextColor:GridTheme?.paperTextColor,
        },
        '&.Mui-focused fieldset': {
          borderColor: GridTheme?.disabledGlobalText?GridTheme.gloablTextColor:GridTheme?.secondaryColor,
        },
      },
      '& .MuiOutlinedInput':{
        // borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
      },
      "& .MuiOutlinedInput-notchedOutline": {
          // border: "0 none",
       },
       '& .iconeFilterSearch':{
        backgroundColor:`${search_button_color} !important`,
        color:`${search_button_text_color} !important`,
        padding:'0 40px !important',
       },
       '& .iconeFilterClear':{
        backgroundColor:`transparent `,
        borderColor:`${clear_button_color} !important`,
        outlineColor:`${clear_button_color} !important`,
        color:`${clear_button_text_color} !important`,
        padding:'0 40px !important',
        // '&:hover':{
        //    backgroundColor:`${clear_button_color} !important`,
        // },
  
       },
       '& .muirtl-2jbvvl-MuiButtonBase-root-MuiButton-root':{
          border:` 1px solid ${clear_button_color} !important`,
          outlineColor:`${clear_button_color} !important`,
       },
       '& .muirtl-1a1fmpi-MuiInputBase-root-MuiInput-root, & .muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root':{
          paddingLeft:"5px"
       },
       '& .muirtl-1a1fmpi-MuiInputBase-root-MuiInput-root:before,& .muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root:before':{
        borderColor:`${GridTheme?.disabledGlobalText?GridTheme.gloablTextColor:GridTheme?.paperTextColor} !important`,
        outlineColor:`${GridTheme?.disabledGlobalText?GridTheme.gloablTextColor:GridTheme?.paperTextColor} !important`,
      },
      '& .muirtl-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root:before,& .muiltr-1ptx2yq-MuiInputBase-root-MuiInput-root:before,& .muiltr-1a1fmpi-MuiInputBase-root-MuiInput-root:before,& .muiltr-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root:before,& .muirtl-1a1fmpi-MuiInputBase-root-MuiInput-root:hover:before, &.muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root:hover:before':{
        borderColor:`${GridTheme?.disabledGlobalText?GridTheme.gloablTextColor:GridTheme?.paperTextColor} !important`,
        outlineColor:`${GridTheme?.disabledGlobalText?GridTheme.gloablTextColor:GridTheme?.paperTextColor} !important`,
      },
      '& .muirtl-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select':{
        display:'flex',
        justifyContent: 'start',
      },
      '&.muirtl-iqd1hh-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon,& .muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root,& .muirtl-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root,& .muiltr-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root,& svg,& span,& p,& .muiltr-1ptx2yq-MuiInputBase-root-MuiInput-root':{
         color:GridTheme?.disabledGlobalText?GridTheme.gloablTextColor:GridTheme?.paperTextColor+' !important',
      },
      '& .muirtl-iqd1hh-MuiButtonBase-root-MuiChip-root,& .muiltr-iqd1hh-MuiButtonBase-root-MuiChip-root':{
        backgroundColor:GridTheme?.secondaryColor+'44 !important'
      },
      '& .muiltr-iqd1hh-MuiButtonBase-root-MuiChip-root svg,& .muirtl-iqd1hh-MuiButtonBase-root-MuiChip-root svg,& .muiltr-wpkdf9-MuiModal-root-MuiDialog-root svg':{
        color:GridTheme?.disabledGlobalText?GridTheme.gloablTextColor:GridTheme?.paperTextColor+' !important',
      },
      

      '& .muirtl-1yf1g2m::before,& .muiltr-1yf1g2m::before,& .muiltr-1vv4lmi::before,& .muirtl-1vv4lmi::before':{
        borderColor:`${GridTheme?.disabledGlobalText?GridTheme.gloablTextColor:GridTheme?.paperTextColor} !important`,
        outlineColor:`${GridTheme?.disabledGlobalText?GridTheme.gloablTextColor:GridTheme?.paperTextColor} !important`,
      },
      '& .muiltr-m91o2i-MuiFormControlLabel-root .MuiFormControlLabel-label,& .muirtl-m91o2i-MuiFormControlLabel-root .MuiFormControlLabel-label,& .muiltr-19d186v-MuiFormControlLabel-root .MuiFormControlLabel-label , & .muirtl-19d186v-MuiFormControlLabel-root .MuiFormControlLabel-label , & input,& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.muiltr-yi9xwg .MuiAutocomplete-inputRoot,& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.muirtl-yi9xwg .MuiAutocomplete-inputRoot ,& .muiltr-1vv4lmi,& .muirtl-1vv4lmi,& .muirtl-o1tw8u .MuiFormControlLabel-label,& .muiltr-o1tw8u .MuiFormControlLabel-label,& .muirtl-3m5ia .MuiInput-root .MuiInput-input,& .muiltr-3m5ia .MuiInput-root .MuiInput-input,& span':{
        color:GridTheme?.disabledGlobalText?GridTheme.gloablTextColor:GridTheme?.paperTextColor+' !important',
      },
      '& .muirtl-khgl9y .MuiFormControlLabel-label,& .muiltr-khgl9y .MuiFormControlLabel-label,& .muiltr-19d186v-MuiFormControlLabel-root .MuiFormControlLabel-label , & .muirtl-19d186v-MuiFormControlLabel-root .MuiFormControlLabel-label ,& input,& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.muiltr-yi9xwg .MuiAutocomplete-inputRoot,& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.muirtl-yi9xwg .MuiAutocomplete-inputRoot ,& .muiltr-1vv4lmi,& .muirtl-1vv4lmi,& .muirtl-o1tw8u .MuiFormControlLabel-label,& .muiltr-o1tw8u .MuiFormControlLabel-label,& .muirtl-3m5ia .MuiInput-root .MuiInput-input,& .muiltr-3m5ia .MuiInput-root .MuiInput-input,& span':{
        color:GridTheme?.disabledGlobalText?GridTheme.gloablTextColor:GridTheme?.paperTextColor+' !important',
      },
    

      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100% !important",
          position:'relative'
        //   overflow: "hidden !important",
        //   overflowX: "scroll !important",
          // marginLeft:'10px !important',
        }}
      >

           <Box 
            sx={{ 
              //   height:'100%',
              //   marginTop:'1px',
                position: 'relative',
              //   display:'flex',
              //   justifyContent:'center',
                '& .muiltr-1n0nj1r,& .muirtl-1n0nj1r,& .muiltr-1i5alow-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab,& .muiltr-118zhtq-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab':{
                  width:"37px !important",
                  height:"35px !important",
                  minHeight:'35px',
                  minWidth:'37px',
                  border:'none !important',
                  outline:'none !important',
                  backgroundColor:`${GridTheme?.primaryColor} !important`,
              },
              '& .muiltr-1n0nj1r, & .muirtl-1n0nj1r,& .muirtl-1i5alow-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab,& .muirtl-118zhtq-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab':{
                width:"37px !important",
                height:"35px !important",
                minHeight:'35px',
                minWidth:'37px',
                border:'none !important',
                outline:'none !important',
                backgroundColor:`${GridTheme?.primaryColor} !important`,
                // left:'0px !important',
                // top:'10px !important',

              },
              // '& .muirtl-6lijc5-MuiSvgIcon-root:ntc-child(1)':{
              //   backgroundColor: `${MainTheme?.primaryColor}`,
              //   width: '35px',
              //   height: '34px',
              //   borderRadius: '50%',
              //   marginLeft: '58px',
              //   padding: '5px',
              // },
              // '& .muirtl-1f047le .muirtl-118zhtq-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab':{
              //   minWidth:'35px !important',
              //   maxWidth:'35px !important',
              //   minHeight:'35px !important',
              //   maxHeight:'35px !important',
              // }
           
            }}>
              <StyledSpeedDial
                ariaLabel="SpeedDial playground example"
                hidden={hidden}
                icon={<MoreVertIcon sx={{color:GridTheme?.oppositePrimaryColor+' !important'}}/>}
                direction='right'
                sx={{
                    
                  '& #SpeedDialplaygroundexample-actions .MuiButtonBase-root:nth-child(1)':{
                    // backgroundColor:'green !important',
                    color:'#ffffff',
                    position:'relative',
                    // top:'-15px',
                    left:'-20px',
                    width:"37px !important",
                    height:"35px !important",
                    minHeight:'35px',
                    minWidth:'37px',

                  },
                  '& #SpeedDialplaygroundexample-actions .MuiButtonBase-root:nth-child(2)':{
                    // backgroundColor:'blue !important',
                    color:'#ffffff',
                    position:'relative',
                    // top:'-25px',
                    left:'-30px',
                    width:"37px !important",
                    height:"35px !important",
                    minHeight:'35px',
                    minWidth:'37px',

                  },
                  '& #SpeedDialplaygroundexample-actions .MuiButtonBase-root:nth-child(3)':{
                    // backgroundColor:'#c40c3d !important',
                    color:'#ffffff',
                    position:'relative',
                    // top:'-30px',
                    left:'-35px',
                    width:"37px !important",
                    height:"35px !important",
                    minHeight:'35px',
                    minWidth:'37px',
                  },
                  '& #SpeedDialplaygroundexample-actions .MuiButtonBase-root:nth-child(4)':{
                    // backgroundColor:'orange !important',
                    color:'#ffffff',
                    position:'relative',
                    // top:'-35px',
                    left:'-40px',
                    width:"37px !important",
                    height:"35px !important",
                    minHeight:'35px',
                    minWidth:'37px',

                  }
                }}

              >
                {actions.map((action) => (
                  <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    sx={{
                      backgroundColor:action?.color?action?.color:'#00f',
                      '&:hover':{
                        backgroundColor:action?.color?action?.color:'#0000ff',
                       },
                       
                    }}
                  />
                ))}
              </StyledSpeedDial>
            </Box>

            <Box
              sx={{
                position:'absolute',
                top:'20px',
                right:'10px !important',
                width:'fit-content',
                height:'30px',
                borderRadius:'5px',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                backgroundColor:GridTheme?.primaryColor,
                color:`${GridTheme?.oppositePrimaryColor} !important`,
                padding:'2px 10px',
              }}
            >
                <Typography  
                  variant='p' 
                  sx={{
                    fontSize:'13px !important',
                    color:`${GridTheme?.oppositePrimaryColor} !important`
                  }}
                >
                  {props?.resultLength} {t('GLOBAL.SEARCH_RESULT_OF_SEARCH')}
                </Typography>
            </Box>
             
      </Box>
        <div style={{height:'20px',width:'100%'}}></div>
          {props?.children}
    </Box>
  );
}
