import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'dailyPlanData',
  initialState: {
    value: {
      data: [],
      resourcesData: [],
      teamData: []
  },
  },
  reducers: {
    
    setDailyPlanData: (state, action) => {
      state.value =action.payload ? JSON.parse(action.payload) : {
        value: {
          data: [],
          resourcesData: [],
          teamData: []
      },
      };
    },
  },
});

export const {setDailyPlanData } = slice.actions;


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.dailyPlanData.value)`
export const selectDailyPlanData = state => state.dailyPlanData.value;

export default slice.reducer;
