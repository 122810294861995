import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { AiFillCloseCircle } from 'react-icons/ai'
import DialogContent from '@mui/material/DialogContent';
import { Divider, Switch, CardMedia, Box, DialogTitle, IconButton, TextField, Typography, Select, InputLabel, MenuItem, FormControl, FormControlLabel, Button, Avatar } from '@mui/material';
import axios from "axios";
import { styled,alpha, useTheme } from '@mui/material/styles';

import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux'
import {
    ContainerOfForm,
    SaveButton,
    CancelButton,
    Form
} from '../Users/StyledComponents';
import FooterBottons from "../Share/FooterBottons";
import { flexbox } from "@mui/system";
import DialogForm from "../Share/DialogForm";
import helpers from "../../../assets/js/helper";
import Autocomplete from '@mui/material/Autocomplete';

import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
// import Switch from '@mui/material/Switch';

import { useGlobalContext } from '../../../context'

import {useTranslation} from "react-i18next";

const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"
const label = { inputProps: { 'aria-label': 'Switch demo' } };
const CustomeSelect = styled(Select)(({ theme, open,MainTheme, }) => ({
    //  backgroundColor:MainTheme?.paperTextColor+'88',
     color:MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme?.paperTextColor,
     '& div ul':{
        color:`${MainTheme?.paperTextColor}`,
        backgroundColor:`${MainTheme?.paperColor+'33'}`,
      }
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function NotificationForm(props) {
    const { users,groups,notification_types, pointsStatuses, pointStatus, setPointStatus, exportToCSV, points, district, region, districts, regions, ownerName, shopName, clearPointsAdvanceSearchFields, setRegion, setDistrict, setShopName, setOwnerName, fetchData, deleteObject } = useGlobalContext()

    const [loading, setloading] = useState(false);
    const token = cookies.get("token")
    const [companies, setCompanies] = useState([])
    const [departments, setDepartments] = useState([]);
    const directions =useSelector(state=> state.settingsData.directions);
    const MainTheme =useSelector(state=> state.settingsData.MainTheme);
    const {t, i18n} = useTranslation('common');
    const [selectedUsers,setSelectedusers]=useState([])
    const [selectedGroups,setSelectedGroups]=useState([])
    const [groups_list,setGroup_list]=useState([])
    const [users_list,setUsers_list]=useState([])
    const screenWidth=useSelector(state=>state.settingsData.screenWidth)

    useEffect(()=>{
        fetchData('USERS', 'users_list')
        fetchData('GROUPS', 'groups_list')
        fetchData('NOTIFICATION_TYPES', 'notification_types_list')
    },[])
    
    useEffect(()=>{
        let arr=[];
        if(groups&&groups?.length>0){
           groups?.map((itm)=>{
               arr.push({
                   id:itm?.id,
                   name:itm?.name,
               })
           })
           setGroup_list(arr)
        }
    },[groups])

    useEffect(()=>{
         let arr=[];
         if(users&&users?.length>0){
            users?.map((itm)=>{
                arr.push({
                    id:itm?.id,
                    label:itm?.full_name,
                })
            })
         setUsers_list(arr)
         console.log('userSelected',arr)
        }

    },[users])

  

    const [object, setobject] = useState({
        id: null,
        title:'',
        body:'',
        data: null,
        notification_type_id: 1,
        send_push_notification: 0,
        user_ids:[],
        group_ids:[],
    });


    // const getResources = async () => {
    //     try {
    //         setloading(true)
    //         const resourcesResult = await axios({
    //             url: Host + "departments",
    //             method: "get",
    //             headers: {
    //                 "Authorization": `Bearer ${token}`,
    //                 Accept: "application/json",
    //                 'X-localization': language,
    //             }
    //         })
    //         if (resourcesResult) {
    //             if (resourcesResult.status === 200) {
    //                 // const resources = resourcesResult.data;
    //                 const resources = resourcesResult?.data?.data;
    //                 setDepartments(resources);
    //                 if (props && props.user && props.user.id > 0) {
    //                     setobject({ ...object, ...props.user });
    //                 }
    //                 else {
    //                     console.log("hi");
    //                     setobject(
    //                         {
    //                             id: null,
    //                             full_name: null,
    //                             phone: null,
    //                             username: null,
    //                             password: null,
    //                             is_demo: null,
    //                             company: {
    //                                 id: null,
    //                                 name: null,
    //                             },
    //                             department: {
    //                                 id: null,
    //                                 name: null,
    //                             }
    //                         }
    //                     )
    //                 }
    //             } else if (resourcesResult.data.status === false) {

    //             }
    //         }
    //         else {
    //             toast.error("Error")
    //         }
    //         const resourcesResult1 = await axios({
    //             url: Host + "companies_list",
    //             method: "get",
    //             headers: {
    //                 "Authorization": `Bearer ${token}`,
    //                 Accept: "application/json",
    //                 'X-localization': language,
    //             }
    //         })
    //         if (resourcesResult1) {
    //             if (resourcesResult1.status === 200) {
                    
    //                 const resources = resourcesResult1?.data;
    //                 setCompanies(resources);
    //                 if (props && props.user && props.user.id > 0) {
    //                     setobject({ ...object, ...props.user });
    //                 }
    //                 else {
    //                     console.log("hi");
    //                     setobject(
    //                         {
    //                             id: null,
    //                             full_name: null,
    //                             phone: null,
    //                             username: null,
    //                             password: null,
    //                             is_demo: null,
    //                             company: {
    //                                 id: null,
    //                                 name: null,
    //                             },
    //                             department: {
    //                                 id: null,
    //                                 name: null,
    //                             }
    //                         }
    //                     )
    //                 }
    //             } else if (resourcesResult1.data.status === false) {

    //             }
    //         }
    //         else {
    //             toast.error("Error")
    //         }
    //         setloading(false)
    //     } catch (error) {
    //         console.log("Network Error", error?.message);
    //         setloading(false)
    //         toast.error("Network Error")
    //     }
    // };

    const submitForm = async () => {
        try {

            if (object.id) {
                const resourcesResult = await axios({
                    url: Host + "notifications/"+object.id,
                    method: "post",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data: {
                        ...object,
                        _method:'put',
                    },
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("notifications updated successfully")
                        props?.fetchData()
                        props.setShowNotificationForm(false, true)
                    } else if (resourcesResult.data.status === false) {
                        props.setShowNotificationForm(true, false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setShowNotificationForm(true, false)
                }
                setloading(false)
            }
            else {
                const resourcesResult = await axios({
                    url: Host + "notifications",
                    method: "post",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data: object,
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("notifications sent successfully")
                        props.setShowNotificationForm(false, true)
                        props?.fetchData()
                    } else if (resourcesResult.data.status === false) {
                        props.setShowNotificationForm(true, false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setShowNotificationForm(true, false)
                }
                setloading(false)
            }
        } catch (error) {
            console.log("error",error);
            props.setShowNotificationForm(true, false)
            const resourcesResult = error.response
            if (resourcesResult && resourcesResult.status === 400) {
                if (typeof resourcesResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key) => {

                        resourcesResult.data.data[key].map((message) => {
                            toast.error(message)
                        })
                    })
                }
                else {

                    toast.error(resourcesResult.data.data)
                }


            }
            else if (resourcesResult && resourcesResult.status === 401) {

                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else {

                toast.error("network error")
            }
        }
    };


    useEffect(() => {
        setloading(true);
       if(props&&props?.Notification&&props?.Notification?.id>0){
        console.log('notification',props?.Notification)
        let arr1=[]
        let arr2=[]
        let arra_users_list=[]
        let arra_groups_list=[]

        // console.log('users',props?.Notification?.users)

        if(props?.Notification?.users?.length>0)
        {
            console.log('users=>id=>',props?.Notification?.users)
            let arr_group=[];
            props?.Notification?.users?.map((user)=>{
            // console.log('users=>id=>',user)
                arr1.push(user?.id)
                arra_users_list.push({
                    id: user?.id,
                    label: user?.full_name,
                })
                
                    
                user?.group&&user?.group?.length>0&&user?.group?.map((gro)=>{
                    let checkGroupExists=false;
                    arr_group&&arr_group?.length>0&&arr_group?.map((itm)=>{
                        if(itm?.id==gro?.id){
                            checkGroupExists=true;
                        }
                    })

                    if(!checkGroupExists){
                        arr2.push(gro?.id)
                        arr_group.push({
                            id:gro?.id,
                            name:gro?.name
                        })
                    }

                })
                if(!arr_group&&arr_group?.length==0){
                    user?.group&&user?.group?.length>0&&user?.group?.map((gro)=>{
                        arr_group.push({
                            id:gro?.id,
                            name:gro?.name
                        })
                    })
                }
                
               console.log('users=>id=>',user)


            })

            

            setSelectedusers(arra_users_list)
           setSelectedGroups(arr_group)

        }
        // props?.Notification?.groups&&props?.Notification?.groups?.map((group)=>{
        //     arr2.push(group?.id)
        //     arra_groups_list.push({
        //         id: group?.id,
        //         name: group?.name,
        //     })
        // })

        setobject({
            id: props?.Notification?.id,
            title:props?.Notification?.title,
            body:props?.Notification?.body,
            data: props?.Notification?.data,
            notification_type_id: props?.Notification?.notification_type_id,
            send_push_notification: props?.Notification?.send_push_notification,
            user_ids:arr1,
            group_ids:arr2,
        })
        console.log('userSelected',arra_users_list)
       }
       setloading(false);

    }, [props?.Notification]);


    const styles = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
    }
    const check = helpers.checkDarknessOrLightnessForColor(MainTheme?.paperTextColor)

    const styleSelect = {
        // backgroundColor: !check ? 'rgb(255,255,255,0.7)' : 'rgb(0,0,0,0.4)',
        // color: MainTheme?.paperTextColor,
        margin:'5px 0',
    }
    
    const styleSpecificSelect={
       
    }


  return (
    <DialogForm
        open={props.showNotificationForm}
        close={props.setShowNotificationForm}
        CustomeWidth='60%'
        CustomeHeight="fit-content"
        loaderActive={loading}
        headerChildren={(
            <>
               <IconButton aria-label={"Close"} onClick={() => { props.setShowNotificationForm(false) }} style={{ position: 'absolute', top: '0px', left: '6px', fontSize: '37px', }}>
                <AiFillCloseCircle style={{ color: MainTheme?.paperTextColor,outline:'none !important',border:'none !important' }} />
            </IconButton>
            <Typography component={'h1'} variant={'h4'} style={{margin:'10px 0', color: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor, width: '100%', textAlign: 'center' }}>
                {props?.title ? props?.title : (object?.id ? t('NOTIFICATIONS.FORM_EDIT_TITLE'): t('NOTIFICATIONS.FORM_ADD_TITLE'))}
            </Typography>
            </>
          )}
          hasHeader={true}
        

    >

        
        {!loading?
        <DialogContent dividers={false} 
            sx={{
                padding: 0,
                overflowX: 'hidden',
                backgroundColor: 'transparent',
                height: '100%',
            }}
        >

            {

                <Box 
                    sx={{
                        padding: '10px',
                        paddingTop: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        height: 'auto',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        color: MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor

                    }}
                >
                    <Box 
                        sx={{

                            display: 'flex',
                            flexDirection: screenWidth<=1100?'column':'row',
                            height: 'auto',
                            width: '100%',
                            justifyContent: 'space-evenly',
                            alignItems: 'flex-start',
                            '& .muiltr-a4ms82-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root,& svg,& .muirtl-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':{
                                color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                            },
                            '& input':{
                                color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                            
                                },
                                '& .MuiOutlinedInput-root':{
                                    borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
                                    color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                                },
                                '& .MuiInputLabel-root':{
                                    // backgroundColor:`${MainTheme?.paperTextColor+'22'} !important`,
                                    color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                                },
                                '& .MuiFormControl-root':{
                                // border:`1px solid ${MainTheme?.paperTextColor} !important`,
                                color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                                },
                                '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                                },
                                '&:hover fieldset': {
                                    borderColor:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: MainTheme?.secondaryColor,
                                    color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.secondaryColor,
                                },
                                },
                                '& .MuiOutlinedInput':{
                                // borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
                                },
                                "& .MuiOutlinedInput-notchedOutline": {
                                    // border: "0 none",
                                },
                                '& .muiltr-nq05vq-MuiAutocomplete-root .MuiAutocomplete-tag,& .muirtl-iqd1hh-MuiButtonBase-root-MuiChip-root,& .muiltr-iqd1hh-MuiButtonBase-root-MuiChip-root':{
                                    color:MainTheme?.oppositeSecondaryColor+" !important",
                                    backgroundColor:MainTheme?.secondaryColor+" !important",
                                },
                                '& .muirtl-iqd1hh-MuiButtonBase-root-MuiChip-root svg,&.muiltr-iqd1hh-MuiButtonBase-root-MuiChip-root svg':{
                                    fill:MainTheme?.oppositeSecondaryColor+" !important",
                                    color:MainTheme?.oppositeSecondaryColor+" !important",

                                },
                                '& .muiltr-2fm79c-MuiFormControl-root-MuiTextField-root .muiltr-iqd1hh-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon,& .muiltr-1esxepm-MuiFormControl-root-MuiTextField-root .muiltr-iqd1hh-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon,& .MuiChip-deleteIcon':{
                                    backgroundColor:'transparent !important',
                                    fill:MainTheme?.oppositeSecondaryColor+" !important",
                                    color:MainTheme?.oppositeSecondaryColor+" !important",
                                },
                                '& .muirtl-5vxoam,& .muiltr-5vxoam':{
                                    color:MainTheme?.oppositeSecondaryColor+" !important",
                                    backgroundColor:MainTheme?.secondaryColor+" !important",
                                }

                        }}
                    >
                        <Box 
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                width:screenWidth<=1100?'100%':'50%',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                margin:'0 10px',
                                flex: 1

                            }}
                        >
                            <TextField
                                id="outlined-basic-full_name"
                                label={t('NOTIFICATIONS.FORM_NOTIFICATION_TITLE')}
                                variant="standard"
                                placeholder={t('NOTIFICATIONS.FORM_NOTIFICATION_TITLE')}
                                sx={{ ...styleSelect,width:'100%' }}
                                value={object?.title}
                                
                                onChange={(event) => {
                                    setobject({ ...object, title: event.target.value });
                                }}
                            />
                            <TextField
                                id="outlined-basic-phone"
                                multiple
                                label={t('NOTIFICATIONS.FORM_NOTIFICATION_BODY')}
                                variant="standard"
                                placeholder={t('NOTIFICATIONS.FORM_NOTIFICATION_BODY')}
                                sx={{ ...styleSelect, marginTop: '15px',width:'100%' }}
                                value={object?.body}
                                
                                onChange={(event) => {
                                    setobject({ ...object, body: event.target.value });
                                }}
                            />
                            <FormControl fullWidth variant="standard"
                                sx={{ ...styleSelect, marginTop: '10px',width:'100%'
                                }}
                             >
                                <InputLabel id="department-select-label" sx={{ color: MainTheme?.paperColor }}>{t('NOTIFICATIONS.FORM_NOTIFICATION_NOTIFICATION_TYPE')}</InputLabel>
                                <CustomeSelect
                                    MainTheme={MainTheme}
                                    labelId="department-select-label"
                                    id="department-select"
                                    sx={{ textAlign: 'left', }}
                                    value={object?.notification_type_id}
                                    // defaultValue={object?.notification_type_id}
                                    label={t('NOTIFICATIONS.FORM_NOTIFICATION_NOTIFICATION_TYPE')}
                                    onChange={(event) => {
                                        console.log(event?.target?.value)
                                        setobject({
                                            ...object,
                                            notification_type_id:event?.target?.value
                                        });
                                    }}
                                   >
                                    {notification_types?.length > 0 && notification_types?.map((notificationtype) => {
                                    

                                        return <MenuItem key={notificationtype?.id}  value={notificationtype?.id}>{notificationtype?.name}</MenuItem>
                                    })}


                                </CustomeSelect>
                            </FormControl>

                        </Box>

                        <Box 
                            sx={{

                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                width:screenWidth<=1100?'100%':'50%',
                                marginLeft: '10px',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                margin:'0 10px',
                                flex: 1

                            }}
                        >
                             <FormControl fullWidth sx={{ ...styleSelect, marginTop: '0px' }}>
                                
                                {/* <Autocomplete
                                    multiple
                                    // fullWidth
                                    options={demousers}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.name || ""}
                                    id="disable-close-on-select"
                                    value={selectedUsers}
                                    onChange={(e, v, r) => { setSelectedusers(v ? v : []); }}
                                    renderInput={(params) => (
                                        <TextField {...params} label={t('NOTIFICATIONS.FORM_NOTIFICATION_USERS')} variant="outlined" />
                                    )}
                                /> */}
                                <Autocomplete
                                    multiple={true}
                                    id="checkboxes-tags-demo"
                                    options={users_list}
                                    disableCloseOnSelect
                                    value={selectedUsers}
                                    // defaultValue={selectedUsers}
                                    // filterSelectedOptions
                                    getOptionLabel={(option) => option?.label}
                                    renderOption={(props, option, { selected }) => (
                                       <li {...props}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option?.label}
                                        </li>
                                    )}
                                    onChange={(e,values) => {
                                        setSelectedusers(values)
                                        let arr=[]
                                        values&&values?.map((itm)=>{
                                            arr.push(itm?.id)
                                        })
                                         setobject({
                                            ...object,
                                            user_ids:arr
                                         }); 

                                    }}
                                    isOptionEqualToValue={(option, value) =>
                                        value === undefined || value === "" || option.id === value.id
                                    }

                                    // style={{ width: 500 }}
                                    renderInput={(params) => (
                                        <TextField
                                        variant="standard"
                                         {...params} 
                                         sx={{
                                            '& .muiltr-iqd1hh-MuiButtonBase-root-MuiChip-root,& .muiltr-iqd1hh-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon':{
                                               backgroundColor:MainTheme?.paperTextColor,
                                               color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperColor,
                                            },
                                        margin:'5px 0',

                                        }}
                                         label={t('NOTIFICATIONS.FORM_NOTIFICATION_USERS')} 
                                        //  placeholder={t('NOTIFICATIONS.FORM_NOTIFICATION_USERS')} 
                                         />
                                    )}
                                />

                                <Autocomplete
                                    multiple
                                    sx={{marginTop:'10px'}}
                                    id="checkboxes-tags-demo"
                                    options={groups_list}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.name}
                                    value={selectedGroups}
                                    onChange={(e, values) => { 

                                        setSelectedGroups(values)
                                        let arr=[]
                                        values&&values?.map((itm)=>{
                                            arr.push(itm?.id)
                                        })
                                         setobject({
                                            ...object,
                                            group_ids:arr
                                         });
                                    }}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option?.name}
                                        </li>
                                    )}
                                    isOptionEqualToValue={(option, value) =>
                                        value === undefined || value === "" || option.id === value.id
                                    }
                                    // style={{ width: 500 }}
                                    renderInput={(params) => (
                                        <TextField 
                                        {...params}
                                        variant="standard"
                                          sx={{
                                            '& .muiltr-iqd1hh-MuiButtonBase-root-MuiChip-root,& .muiltr-iqd1hh-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon':{
                                               backgroundColor:MainTheme?.paperTextColor,
                                               color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperColor,
                                            },
                                          margin:'5px 0',

                                        }}
                                         label={t('NOTIFICATIONS.FORM_NOTIFICATION_GROUPS')}
                                        //   placeholder={t('NOTIFICATIONS.FORM_NOTIFICATION_GROUPS')}
                                        />
                                    )}
                                />
                            </FormControl>
                            <Box
                              sx={{
                                "& .MuiSwitch-thumb":{
                                    color: MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme?.oppositeSecondaryColor,
                                },
                               " &  .MuiSwitch-track":{
                                    backgroundColor: MainTheme?.secondaryColor+'66',
                                },
                               " & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                                    backgroundColor: MainTheme?.secondaryColor+"bb !important",
                                },
                              }}
                            >
                            <FormControl
                                fullWidth 
                                sx={{ 
                                    ...styleSelect, 
                                    marginTop: '10px',
                                 

                                }}
                            >
                               <FormControlLabel 
                                 sx={{
                                    "& .MuiSwitch-thumb":{
                                        color: MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme?.oppositeSecondaryColor,
                                    },
                                   " &  .MuiSwitch-track":{
                                        backgroundColor: MainTheme?.secondaryColor+'66',
                                    },
                                   " & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                                        backgroundColor: MainTheme?.secondaryColor+"bb !important",
                                    },
                                 }}
                               control={
                               <Switch 
                               sx={{
                                "& .MuiSwitch-thumb":{
                                    color: MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme?.oppositeSecondaryColor,
                                },
                               " &  .MuiSwitch-track":{
                                    backgroundColor: MainTheme?.secondaryColor+'66',
                                },
                               " & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                                    backgroundColor: MainTheme?.secondaryColor+"bb !important",
                                },
                                '& .MuiSwitch-switchBase.Mui-checked': {
                                    color: MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme?.oppositeSecondaryColor,
                                    '&:hover': {
                                      backgroundColor: alpha(MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme?.oppositeSecondaryColor),
                                    },
                                  },
                                  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                    backgroundColor: MainTheme?.secondaryColor+"bb !important",
                                  },
                             }}
                                 checked={object?.send_push_notification==0?false:true}
                                 onChange={(e)=>{
                                     setobject({
                                        ...object,
                                        send_push_notification:e.target.checked?1:0,
                                     })
                                 }}
                                />
                               } label={t('NOTIFICATIONS.FORM_NOTIFICATION_SENT_PUSH_NOTIFICATION')} />
                            </FormControl>
                            </Box>
                        </Box>
                    </Box>
                    <Divider sx={{ marginTop: '10px', width: '100%' }} />
                    <Box 
                     className="buttonSendForm"
                    sx={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'row',
                        height: 'auto',
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                    

                    }}>
                        <Button sx={{ color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,}} onClick={() => {
                            submitForm()
                        }}>{t('USERS.FORM_SUBMIT')}</Button>
                    </Box>
                </Box>
            }
        </DialogContent>:null}

    </DialogForm>
  )

  const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'blue',

    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
        width: '100%',
        border: '1px solid #ababab',
        borderRadius: 5,
        padding: 0,
        display: 'flex'
    }),
    container: () => ({

        width: '80%',
        position: 'relative',
        boxSizing: 'border-box',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}
}
