import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Pane, Dialog } from 'evergreen-ui';
import axios from "axios";
import Select from "react-select";
import Host from "../../../assets/js/Host";
import DialogForm from "../Share/DialogForm";
import {
    Form,
    SaveButton,
    CancelButton,
} from './StyledComponents';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { toast } from 'react-toastify';
import {useSelector,useDispatch} from 'react-redux'
import FooterBottons from '../Share/FooterBottons'
import { withStyles } from '@mui/material/styles';
import CustomeCheckBox from "../Share/CustomeCheckBox";
import {useTranslation} from "react-i18next";
import { useGlobalContext } from '../../../context'
import swal from 'sweetalert';
import {Box,Typography} from '@mui/material'

import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
const filter = createFilterOptions();
const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };



export const UserAccessGroup = (props) => {
    const [value, setValue] = React.useState(null);
    const [loading, setloading] = useState(false);
    const [roles, setroles] = useState([]);
    const [userRolesSelected, setuserRolesSelected] = useState([]);
    const directions =useSelector(state=> state.settingsData.directions);
    const MainTheme =useSelector(state=> state.settingsData.MainTheme);

    const [userGroup,setUserGroup]=useState(null);
    const [userAccessGroup,setUserAccessGroup]=useState([]);

    const [accessGroupForUser,setAccessGroupsForUser]=useState([])

    const {t, i18n} = useTranslation('common');
    const token = cookies.get("token")
    const [object, setobject] = useState({});
    const { users,groups,notification_types, pointsStatuses, pointStatus, setPointStatus, exportToCSV, points, district, region, districts, regions, ownerName, shopName, clearPointsAdvanceSearchFields, setRegion, setDistrict, setShopName, setOwnerName, fetchData, deleteObject } = useGlobalContext()

    const [SelectAllCheckBox,setSelectAllCheckBox]=useState(false)


    useEffect(()=>{
        fetchData('GROUPS','groups_list')
    },[])

    useEffect(()=>{
        // console.log('user=>',props?.user?.group)

      if(props?.user&&props?.user?.access_group_users?.length>0){
        //  console.log('user=>',props?.user?.group[0])
        //  setObject(props?.user?.group[0])
        //  setObjectUserGroup(props?.user?.group[0])
        //  setUserGroup(props?.user?.group[0]?.id)
        let arr=[];
        props?.user?.access_group_users?.map((itm)=>{
          arr.push(itm?.id)
        })
        setAccessGroupsForUser(arr)
      }
    },[props?.user])

    const submitForm = async () => {
        
        try {
            
        
            if (props?.user?.id) {                
                let formdata = new FormData();

                // formdata.append("roles", JSON.stringify(userRolesSelected));
               
                const resourcesResult = await axios({
                    url: Host + "users/access_groups/"+props?.user?.id,
                    method: "post",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                       
                        accept: "application/json",
                        // Accept: "application/json",
                        // "Content-Type": "multipart/form-data",
                        'X-localization': language,
                    },
                    data:{
                        group_id:accessGroupForUser,
                        _method: 'patch',
                    }
                    
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("updated ")
                        props.setShowUseAccessrGroup(false,true)
                    } else if (resourcesResult.data.status === false) {
                        props.setShowUseAccessrGroup(true,false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setShowUseAccessrGroup(true,false)
                }
                setloading(false)
            }
            else{
                swal({
                    title: `${t('SWEATALERT_MESSAGES.WARNNING_MESSAGE')}`,
                    text: `${t('SWEATALERT_MESSAGES.USER_ID_NOT_FOUND')}`,
                    icon: "warning",
                    button: `${t('SWEATALERT_MESSAGES.WARRNING_BUTTON')}`,
                });
                return;
            }
           
        } catch (error) {
            props.setShowUseAccessrGroup(true,false)
            const resourcesResult=error.response            
            if (resourcesResult && resourcesResult.status === 400) {
                if(typeof resourcesResult.data.data === "object")
                {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key)=>{
                    
                        resourcesResult.data.data[key].map((message)=>{
                            toast.error(message)
                        })
                    })
                }
                else{
                    
                    toast.error(resourcesResult.data.data)
                }
                
                
            }
            else if(resourcesResult && resourcesResult.status === 401) {
             
                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else
            {
              
                toast.error("network error")
            }
        }
        
    };

    const handleInputChange = (event,role_id) => {
        const target = event.target;
        // console.log("ischecked",target.checked);
        if(target.checked)
        {
          
            setuserRolesSelected([...userRolesSelected,role_id])
        }
        
        else
        {
            const userRolesSelectedtemp = userRolesSelected.filter(function(item) {
                return item !== role_id
            })
            setuserRolesSelected(userRolesSelectedtemp)
        }
        

    };

    useEffect(()=>{
       if(userRolesSelected?.length==roles?.length){
        setSelectAllCheckBox(true)
       }else{
        setSelectAllCheckBox(false)

       }
    },[roles,userRolesSelected])

    const handelSelectedAllCheckBox=(e)=>{
        const target = e.target;
        setSelectAllCheckBox(target?.checked)

        if(target.checked)
        {   
            const allRolesId=[];
            roles?.map((role)=>{
                allRolesId.push(role?.id)
            })
            setuserRolesSelected(allRolesId)
        }
        
        else{
            // const userRolesSelectedtemp = userRolesSelected.filter(function(item) {
            //     return item !== role_id
            // })
            setuserRolesSelected([])
        }
    }
    
    // useEffect(() => {
    //     console.log(props.user);
       
    //     if(loading)
    //         getResources()

    //     return () => {

    //     };
    // }, [loading,props.showAccessGroup]);

    const styles={
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        '& .form-check-input':{
            backgroundColor:MainTheme?.secondaryColor,
        }
    }
    const marginLabel={
        marginLeft:'30px',
        marginRight:'30px',

    }

    useEffect(()=>{
         console.log(accessGroupForUser)
    },[accessGroupForUser])

    return (
        <DialogForm
            open={props.showAccessGroup}
            close={props.setShowUseAccessrGroup}
            CustomeWidth='50%'
            CustomeHeight="fit-content"
            loaderActive={loading}
            headerChildren={(
                <h4 style={{...styles}}>{t("USERS.USER_GROUP_ACCESS_GROUP")}</h4>
           )}
           hasHeader={true}
        >
            
            <Form  color={MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor}>

               
                
                <Box sx={{
                    marginLeft:'30px',
                      display:'flex',
                      justifyContent: 'start',
                      alignItems: 'start',
                      flexDirection:'column',
                    }}>
                    {/* <Typography sx={{fontSize:'25px'}}>{t('USERS.USER_GROUP_ACCESS_GROUP')}</Typography> */}
                    {groups.map((group)=>{
                        var isChecked=false;
                        if(accessGroupForUser.length>0 && accessGroupForUser.includes(group.id))
                        {
                            isChecked=true;
                        }
                        return (
                            <div className="form-group">
                            
                                {/* <CustomeCheckBox
                                    HandleChange={handleInputChange}
                                    data={role}
                                    IdOrName={true}
                                    selected={isChecked}
                                    labelName={role?.name}
                                /> */}

                                 <FormControlLabel 
                                  control={
                                  <Checkbox 
                                   checked={isChecked}
                                    onChange={
                                        (e)=>{
                                           if(e.target.checked){
                                             setAccessGroupsForUser((arr)=>[...arr,group?.id])
                                           }else{
                                             setAccessGroupsForUser(accessGroupForUser.filter(itm=>itm != group?.id))
                                           }
                                        }
                                    }
                                  />
                                } label={group?.name} />

                            </div>
                        );
                        
                    })}  
                </Box>   

                      
                 
            </Form>
            <FooterBottons close={props.setShowUseAccessrGroup} submitForm={submitForm} />  
        </DialogForm>

    );
};
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'blue',

    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
        width: '100%',
        border: '1px solid #ababab',
        borderRadius: 5,
        padding: 0,
        display: 'flex'
    }),
    container: () => ({

        width: '80%',
        position: 'relative',
        boxSizing: 'border-box',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}

export default UserAccessGroup;
