import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Pane, Dialog } from 'evergreen-ui';
import axios from "axios";
import Select from "react-select";
import Host from "../../../assets/js/Host";
import DialogForm from "../Share/DialogForm";
import {
    Form,
    SaveButton,
    CancelButton,
} from './StyledComponents';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { toast } from 'react-toastify';
import {useSelector,useDispatch} from 'react-redux'
import FooterBottons from '../Share/FooterBottons'
import { withStyles } from '@mui/material/styles';
import CustomeCheckBox from "../Share/CustomeCheckBox";
import {useTranslation} from "react-i18next";
import { useGlobalContext } from '../../../context'

import {Box,Typography} from '@mui/material'
import swal from 'sweetalert';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
const filter = createFilterOptions();
const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };



export const UserGroup = (props) => {
    const [value, setValue] = React.useState(null);
    // const [loading, setloading] = useState(false);
    const [roles, setroles] = useState([]);
    const [userRolesSelected, setuserRolesSelected] = useState([]);
    const directions =useSelector(state=> state.settingsData.directions);
    const MainTheme =useSelector(state=> state.settingsData.MainTheme);
    
    const [userGroup,setUserGroup]=useState(null);
    const [objectUserGroup,setObjectUserGroup]=useState({})
    const [userAccessGroup,setUserAccessGroup]=useState([]);
     const [object,setObject]=useState({})
    const {t, i18n} = useTranslation('common');
    const token = cookies.get("token")
    // const [object, setobject] = useState({
    //     id: null,
    //     full_name: null,
    //     phone: null,
    //     username: null,
    //     password:null,
    //     role_id: null,
    //     role:{
    //         id:null,
    //         name:null
    //     }
    // });
    const { loading,users,groups,notification_types, pointsStatuses, pointStatus, setPointStatus, exportToCSV, points, district, region, districts, regions, ownerName, shopName, clearPointsAdvanceSearchFields, setRegion, setDistrict, setShopName, setOwnerName, fetchData, deleteObject } = useGlobalContext()

    const [SelectAllCheckBox,setSelectAllCheckBox]=useState(false)


    useEffect(()=>{
        fetchData('GROUPS','groups_list')
    },[])

    useEffect(()=>{
        console.log('user=>',props?.user?.group)

      if(props?.user&&props?.user?.group){
         console.log('user=>',props?.user?.group[0])
         setObject(props?.user?.group[0])
         setObjectUserGroup(props?.user?.group[0])
         setUserGroup(props?.user?.group[0]?.id)
      }
    },[props?.user])


    const submitForm = async () => {
        
        try {
            
            if (props&&props?.user&&props?.user?.id>0) {                
                let formdata = new FormData();

                // formdata.append("roles", JSON.stringify(userRolesSelected));
               
                const resourcesResult = await axios({
                    url: Host + "users/groups/"+props?.user?.id,
                    method: "post",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                       
                        accept: "application/json",
                        // Accept: "application/json",
                        // "Content-Type": "multipart/form-data",
                        'X-localization': language,
                    },
                    data:{
                        group_id:userGroup,
                        _method:'patch',
                    }
                    
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("updated ")
                        props.setShowUserGroup(false,true)
                    } else if (resourcesResult.data.status === false) {
                        props.setShowUserGroup(true,false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setShowUserGroup(true,false)
                }
                // setloading(false)
            }else{
                swal({
                    title: `${t('SWEATALERT_MESSAGES.WARNNING_MESSAGE')}`,
                    text: `${t('SWEATALERT_MESSAGES.USER_ID_NOT_FOUND')}`,
                    icon: "warning",
                    button: `${t('SWEATALERT_MESSAGES.WARRNING_BUTTON')}`,
                });
                return;
            }
            
        } catch (error) {
            props.setShowUserGroup(true,false)
            const resourcesResult=error.response            
            if (resourcesResult && resourcesResult.status === 400) {
                if(typeof resourcesResult.data.data === "object")
                {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key)=>{
                    
                        resourcesResult.data.data[key].map((message)=>{
                            toast.error(message)
                        })
                    })
                }
                else{
                    
                    toast.error(resourcesResult.data.data)
                }
                
                
            }
            else if(resourcesResult && resourcesResult.status === 401) {
             
                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else
            {
              
                toast.error("network error")
            }
        }
        
    };



    const handleInputChange = (event,role_id) => {
        const target = event.target;
        // console.log("ischecked",target.checked);
        if(target.checked)
        {
          
            setuserRolesSelected([...userRolesSelected,role_id])
        }
        
        else
        {
            const userRolesSelectedtemp = userRolesSelected.filter(function(item) {
                return item !== role_id
            })
            setuserRolesSelected(userRolesSelectedtemp)
        }
        

    };

    useEffect(()=>{
       if(userRolesSelected?.length==roles?.length){
        setSelectAllCheckBox(true)
       }else{
        setSelectAllCheckBox(false)

       }
    },[roles,userRolesSelected])

    const handelSelectedAllCheckBox=(e)=>{
        const target = e.target;
        setSelectAllCheckBox(target?.checked)

        if(target.checked)
        {   
            const allRolesId=[];
            roles?.map((role)=>{
                allRolesId.push(role?.id)
            })
            setuserRolesSelected(allRolesId)
        }
        
        else{
            // const userRolesSelectedtemp = userRolesSelected.filter(function(item) {
            //     return item !== role_id
            // })
            setuserRolesSelected([])
        }
    }
    
    // useEffect(() => {
    //     // console.log(props.user);
       
    //     if(loading)
    //         // getResources()

    //     return () => {

    //     };
    // }, [loading,props.showUserGroup]);

    const styles={
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        '& .form-check-input':{
            backgroundColor:MainTheme?.secondaryColor,
        }
    }
    const marginLabel={
        marginLeft:'30px',
        marginRight:'30px',

    }

    return (
        <DialogForm
            open={props?.showUserGroup}
            close={props?.setShowUserGroup}
            CustomeWidth='50%'
            CustomeHeight="fit-content"
            loaderActive={loading}
            headerChildren={(
                <h4 style={{...styles}}>{t("USERS.USER_GROUP_TITLE")}</h4>
           )}
           hasHeader={true}
        >
            
            <Form  color={MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor}>

                <Box className="form-group" sx={{marginTop:'20px'}}>     
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        value={objectUserGroup}
                        options={groups}
                        getOptionLabel={(option) => option.name || ""}
                        onChange={(e,value)=>{
                            setObjectUserGroup(value)
                           setUserGroup(value?.id)
                        }}
                        sx={{ width: '99%' }}
                        renderInput={(params) => <TextField {...params} label={t('USERS.USER_GROUP_NAME')} variant="standard"/>}
                    />
                </Box>

                      
                 
            </Form>
            <FooterBottons close={props.setShowUserGroup} submitForm={submitForm} />  
        </DialogForm>

    );
};
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'blue',

    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
        width: '100%',
        border: '1px solid #ababab',
        borderRadius: 5,
        padding: 0,
        display: 'flex'
    }),
    container: () => ({

        width: '80%',
        position: 'relative',
        boxSizing: 'border-box',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}

export default UserGroup;
