
import React, {useEffect,useState} from 'react';
import Pagination from "react-js-pagination";
import Cookies from "universal-cookie";
import axios from "axios";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import PointTypeForm from './PointTypeForm';
import DropDownGrid from '../Share/DropDownGrid'
import {Box,MenuItem,ListItemIcon} from '@mui/material'
import { useSelector,useDispatch } from 'react-redux';
import Grid from '../Share/Grid'
import Tooltip from '@mui/material/Tooltip';
import IconButton from "@mui/material/IconButton";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import {useTranslation} from "react-i18next";


const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language")?cookies.get("language"):"ar"


function PointType() {
    const [t, i18n] = useTranslation('common');
    const token = cookies.get("token")
    const [loading, setloading] = useState(false);
    const [state, setData] = useState({
        pointTypes: ''
    });
    const [showPointTypeForm, setshowPointTypeForm] = useState(false);
    const [selectedPointType, setselectedPointType] = useState(null);

    
    const [showDepartmentForm, setshowDepartmentForm] = useState(false);
    const [selectedDepartment, setselectedDepartment] = useState(null);
    const directions =useSelector(state=>state.settingsData.directions)
    const [pageSize, setPageSize] = useState(5);
    const [pageNumber, setpageNumber] = useState(1);
    const [pointsTotal, setPointsTotal] = useState(0)
    const [pointsNumberOfPages, setPointsNumberOfPages] = useState(0)
    const [rows, setRow] = useState([])
    const columns = [
        { field: 'id', headerName: t('POINT_STATUS.ID'), flex: .1 },
        {
            field: 'pointType_name', headerName: t('POINT_STATUS.POINT_STATUS_NAME'), flex: 2,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>
                    {rows?.row?.name}
                </div>
            )
        },

        {
            field: 'actions', flex: .1,
            headerName: t('POINT_STATUS.ACTIONS'),
            renderCell: (rows) => (
                <DropDownGrid>

                <MenuItem onClick={() => { updateObject(rows.row)}}>
                    <ListItemIcon sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin:'0px 7px 0 0'
                    }}>
                       <EditIcon style={{ fill: "#111827" }} />

                    </ListItemIcon>
                    {directions != 'rtl' ? "Edit" : 'تعديل'}
                </MenuItem>
                <MenuItem onClick={() => {deleteObject(rows.row)}}>
                    <ListItemIcon sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin:'0px 7px 0 0'
                    }}>
                      <DeleteIcon style={{ fill: "#f00" }} />
                    </ListItemIcon>
                    {directions != 'rtl' ? "Delete" : 'حذف'}
                </MenuItem>

                </DropDownGrid>
            ),
        },
    ]

    const handlePageChange = (newPage) => {
        setpageNumber(newPage + 1)
    }
    useEffect(() => {        
        fetchData(pageNumber);
    }, [pageNumber,pageSize])

    useEffect(() => {
        if (state && state.pointTypes&&state.pointTypes?.data?.length > 0) {
            setRow(state?.pointTypes?.data);
            setPointsTotal(state?.pointTypes?.total)
            setPointsNumberOfPages(state?.pointTypes?.last_page)
        }else{
            setRow([]);
            setPointsTotal(0)
            setPointsNumberOfPages(0)
        }
    }, [state])

    const fetchData = async (pageNumber = 1) => {
        setloading(true)
        try {
            const result=await axios({
                url: Host + "point_types?page="+pageNumber,
                method: "get",
                headers: {
                "Authorization" : `Bearer ${token}`,
                    Accept:"application/json",
                    'X-localization':language,
                },
                params: {
                    page_size:pageSize,
                }
        
            })
            if(result && result.status == 200)
            {
                const pointTypes=result.data ;
                
                console.log(pointTypes);
                setData({
                    pointTypes:pointTypes 
                });
            } else if (result.data.status === false) {
                toast.error("Error")
            }
        } catch (error) {
            const responseResult=error.response            
            if (responseResult && responseResult.status === 400) {
                if(typeof responseResult.data.data === "object")
                {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key)=>{
                    
                        responseResult.data.data[key].map((message)=>{
                            toast.error(message)
                        })
                    })
                }
                else{
                    toast.error(responseResult.data.data)
                }
                
            }
            else if(responseResult && responseResult.status === 401) {
                toast.error(responseResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else
            {
                toast.error("network error")
            }
        }
        setloading(false)
                   
    };
    const updateObject = async (object) => {
      
        
        setselectedPointType(object)
        setshowPointTypeForm(true)
    };
    const deleteObject = async (object) => {
        if (!window.confirm("Delete the item?")) {
            return
        }
        setloading(true)
        try {
            const result=await axios({
                url: Host + "point_types/"+object.id,
                method: "delete",
                headers: {
                "Authorization" : `Bearer ${token}`,
                    Accept:"application/json",
                    'X-localization':language,
                }
        
            })
            if(result && result.status == 200)
            {
                toast.success(result.data.data)
                fetchData();
            } else if (result.data.status === false) {
                toast.error("Error")
            }
        } catch (error) {
            const responseResult=error.response            
            if (responseResult && responseResult.status === 400) {
                if(typeof responseResult.data.data === "object")
                {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key)=>{
                    
                        responseResult.data.data[key].map((message)=>{
                            toast.error(message)
                        })
                    })
                }
                else{
                    toast.error(responseResult.data.data)
                }
                
            }
            else if(responseResult && responseResult.status === 401) {
                toast.error(responseResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else
            {
                toast.error("network error")
            }
        }
        setloading(false)
    };
    
    const createObject = async (object) => {
        setselectedPointType(null)
        setshowPointTypeForm(true)
    };

    const handleSetshowPointTypeForm = (openStatus,needsReload) => {
      if(needsReload)
      fetchData();
      setshowPointTypeForm(openStatus)
    };
    
    
    useEffect(() => {
        fetchData();
    }, [])
    
    return (
        <>
            {showPointTypeForm?
                <PointTypeForm pointType={selectedPointType} showPointTypeForm={showPointTypeForm} setshowPointTypeForm={handleSetshowPointTypeForm} />
            :null}
          

            <Grid
              rows={rows}
              columns={columns}
              rowTotals={pointsTotal}
              pageSize={pageSize}
              setPageSize={setPageSize}
              pageNumber={pageNumber}
              setPageNumber={setpageNumber}
              loading={loading}
              handlePageChange={handlePageChange}
              NumberOfPage={pointsNumberOfPages}
              directions={directions}
              hasCreate={true}
              createObject={createObject}
              title={t('POINT_STATUS.TITLE')}
            //   title_ar="قائمة حالات النقاط"
              gridHeight={{
                height:rows?.length>5?pageSize==5?400:pageSize==10?650:950:400,
              }}
            />
        </>
    );
}

export default PointType;


// <div className="container">
// <div className="row justify-content-center">
//     <div className="col-md-12">
//         <div className="card">
//             <div className="card-header">PointType List <Button style={{float:'right'}} onClick={()=>{createObject()}}>create</Button></div>
//             <div className="card-body">
//             <table>
//                 <thead>
//                     <tr>
//                         <th>1</th>
//                         <th>PointType Name</th>
//                         <th>edit</th>
//                         <th>delete</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                 {   
//                     state?.pointTypes?.data ? 
//                         state?.pointTypes?.data?.map((object) => (
//                                 <tr key={object?.id}>
//                                     <td>{object?.id}</td>
//                                     <td>{object?.name}</td>
//                                     <td><Button onClick={()=>{updateObject(object)}}>update</Button></td>
//                                     <td><Button style={{backgroundColor:'red',color:'white'}} onClick={()=>{deleteObject(object)}}>delete</Button></td>
                                    
//                                 </tr>
//                         )) : "Loading..."
//                 }
//                 </tbody>
//             </table>
//                 <div>
//                     <Pagination
//                         activePage={state?.pointTypes?.current_page ? state?.pointTypes?.current_page : 0}
//                         itemsCountPerPage={state?.pointTypes?.per_page ? state?.pointTypes?.per_page : 0 }
//                         totalItemsCount={state?.pointTypes?.total ? state?.pointTypes?.total : 0}
//                         onChange={(pageNumber) => {
//                             fetchData(pageNumber)
//                         }}
//                         pageRangeDisplayed={8}
//                         itemClass="page-item"
//                         linkClass="page-link"
//                         firstPageText="First Page"
//                         lastPageText="Last Lage"
//                     />
//                 </div>
//             </div>
//         </div>
//     </div>
// </div>
// </div>