import React from 'react'
import {useSelector,useDispatch} from 'react-redux'
import helpers from '../../../assets/js/helper'
// function isWeekEnd(date) {
//     const day = date.getDay();
//     return day === 0 || day === 6;
//   }
  
//   function getCurrentTraining(date, employeeID) {
//     const result = (date + employeeID) % 3;
//     const currentTraining = `training-background-${result}`;
  
//     return currentTraining;
//   }

  
export default function DataCell(props) {
    const { data ,defaultCurrentDate,data:{planId}} = props;
    const selectedCell=useSelector(state=>state.settingsData.selectedCell)
    const GlobalVariableForJourneys =useSelector(state=>state.settingsData.GlobalVariableForJourneys)
    const MainTheme =useSelector(state=>state.settingsData.MainTheme)
    const listOfJourneysPlan =useSelector(state=>state.settingsData.listOfJourneysPlan)

    React.useEffect(()=>{
       console.log('selected-->',data)
      //  console.log('selected====>',GlobalVariableForJourneys)
    },[])
    
   const getSelectedMonth=()=>{
      let check=false;
       if(selectedCell){
         let month=new Date(selectedCell)
         let month2=new Date(data?.startDate)
         let day=month.getDate();
         if(day==data?.text&&month.getMonth()-1==month2.getMonth()-1){
            check=true;
         }
       }
       return check;
   }

   const getSelectedMonth__=()=>{
    let check=false;
     if(selectedCell){
       let month=new Date(selectedCell)
       let month2=new Date(data?.startDate)
       let day=month.getDate();
       if(day==data?.text&&month.getMonth()-1==month2.getMonth()-1){
          check=true;
       }
     }
     return check;
 }

    const checkCopaierDate=(day,start_date,end_date)=>{
      let d=helpers.formatDate(day)
        // console.log('d1-->',d)
      let d2=start_date
      // console.log('d2-->',d2)

      let d3=end_date
      // console.log('d3-->',d3)

      return d >= d2 && d <= d3 

    }
   
   const checkNoDistrictsForThisCell=()=>{
    let check=false;
    var d = new Date(data?.startDate);
    var dayNumber =d.getDay()+1;
    var date__ = d;
    
    // console.log('wdqweqwekkwkk=>',listOfJourneysPlan)
    listOfJourneysPlan&&listOfJourneysPlan?.length>0&&listOfJourneysPlan?.map((plan)=>{
        if(plan?.journey_type?.name=='Weekly'){
          if(checkCopaierDate(date__,plan?.start_date,plan?.end_date)){
            // console.log('entered here=>',data?.startDate)
            check=false;
            plan?.journey_plan_days&&plan?.journey_plan_days?.length>0&&plan?.journey_plan_days?.map((itm)=>{
              // console.log('dayday__',itm?.day_number)
              // console.log('Pqweqqweqweqweqwe==>',2)
                if(itm?.day_number==dayNumber){
                  console.log('daydaydayday',dayNumber);
                      // if(itm?.districts?.length>0){
                        check=true;
                        return check
                      // }
                }
            })
            if(check) return true
          }
        }else if(plan?.journey_type?.name==='Daily') {
          if(checkCopaierDate(date__,plan?.start_date,plan?.end_date)){
            // console.log('Pqweqqweqweqweqwe==>',1)
            check= true;
            return
          }
        }else {
          check=3
          return
        }
    })

    if(listOfJourneysPlan?.length==0){
      check=3;
    }
    return check;
   }

    return (
      <div 
        style={{
        backgroundColor:getSelectedMonth()?'rgba(255,0,0,0.55)':'white',width:'100%',height:'100%',
        zIndex:1000,
        position:getSelectedMonth()?'absolute':'static',
        top:0,
        left:0,
        right:0,
        bottom:0,
        paddingRight:'7px'
        }}
      >
          {
            checkNoDistrictsForThisCell()==true?
            <div 
              className="noDataCell"
              style={{
                color:getSelectedMonth()?'white':'inherit',
                position:'relative',
              }}
             >
              {data?.text}
            </div>
            :checkNoDistrictsForThisCell()==3?
            <div 
              style={{
                  color:getSelectedMonth()?'white':'inherit',
                  backgroundColor:`${MainTheme?.secondaryColor} !important`,
                  color:`${MainTheme?.oppositeSecondaryColor} !important`,
                  width:'100%',
                  height:'40px',
                  position: 'relative',
              }}
             >
              {data?.text}
            </div>:
            // checkNoDistrictsForThisCell()==false?
            <div 
              // className="noDataCell"
              style={{
                  color:getSelectedMonth()?'white':'inherit',
                  backgroundColor:`${MainTheme?.secondaryColor} !important`,
                  color:`${MainTheme?.oppositeSecondaryColor} !important`,
                  width:'100%',
                  height:'40px',
                  position: 'relative',
              }}
            >
              {data?.text}
            </div>
           
          }
     
      </div>
    )
}
