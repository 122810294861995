import React, { useState, useEffect } from 'react'
import { styled, useTheme, alpha } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { useSelector, useDispatch } from 'react-redux'
import MenuSharpIcon from '@mui/icons-material/MenuSharp';
import SourceSharpIcon from '@mui/icons-material/SourceSharp';
import { useTranslation } from "react-i18next";
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import WidgetsSharpIcon from '@mui/icons-material/WidgetsSharp';
import * as IoIcons from 'react-icons/io5';
import * as AiIcons from 'react-icons/ai';
import * as BsIcons from 'react-icons/bs';
import * as MdIcons from 'react-icons/md';
import * as GiIcons from 'react-icons/gi';
import * as BiIcons from 'react-icons/bi';
import * as FaIcons from 'react-icons/fa';
import * as RiIcons from 'react-icons/ri';
import * as HiIcons from 'react-icons/hi';
import * as ImIcons from 'react-icons/im';
import * as CgIcone from 'react-icons/cg';
import * as SiIcone from 'react-icons/si';
import * as RiIcone from 'react-icons/ri';
import * as VscIcone from 'react-icons/vsc';
import * as AiIcone from 'react-icons/ai';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AddIcon from '@mui/icons-material/Add';
import Modal from '@mui/material/Modal';
import { setCurrentSelectedSettingPages } from '../../reduxStore/SettingsReducer'
// import IconButton from '@mui/material/IconButton';
import CustomeInput from '../Settings/Components/CustomeInput';
import Button from '@mui/material/Button';
import ScatterPlotIcon from '@mui/icons-material/ScatterPlot';
import {
  setTranslateEnglish,
  setTranslateArabic,
} from '../../reduxStore/TranslateReducer';
import swal from 'sweetalert'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


import Cookies from "universal-cookie";
import axios from "axios";
import { Avatar } from "evergreen-ui"
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import VisitForm from '../Visits/VisitForm';
import VisitMapDialog from '../Visits/VisitMapDialog';
import VisitPreview from '../Visits/VisitPreview';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BasicDateRangePicker from '../GeneralComponents/DateFromTo'
import moment from 'moment';
import CircularIndeterminate from '../GeneralComponents/CirularProgress'
import StyledDataGrid from '../GeneralComponents/StyledDataGrid'
import { useGlobalContext } from '../../../context'
import Pagination from '../Share/Pagination';
import "../../../assets/css/DG.css"
import "../../../assets/css/AccordionStyle.css"
import SearchField1 from '../GeneralComponents/SearchField1'
import BasicDatePicker from '../GeneralComponents/BasicDatePicker';
import { Checkbox, FormControlLabel } from '@mui/material';
import PointsMapDialog from '../Points/PointsMapDialog';
import Grid from '../Share/Grid';
import * as GrIcone from 'react-icons/gr'
import Autocomplete from '@mui/material/Autocomplete';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import Menu from '@mui/material/Menu';
// import { styled, alpha } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';

import * as BiIcone from 'react-icons/bi'

import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import FileCopyIcon from '@mui/icons-material/FileCopyOutlined';
import SaveIcon from '@mui/icons-material/Save';
import PrintIcon from '@mui/icons-material/Print';
import ShareIcon from '@mui/icons-material/Share';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MapIcon from '@mui/icons-material/Map';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import TelegramIcon from '@mui/icons-material/Telegram';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';



const CustomeBox = styled(Box)(({ theme, GridTheme, MainTheme, customeWidth }) => ({
  backgroundColor: MainTheme?.bgColor,
  color: GridTheme?.disabledGlobalText ? GridTheme?.gloablTextColor : GridTheme?.oppositePrimaryColor,
  margin: '20px 0 70px 0 !important',
  // borderTopLeftRadius:10,
  // borderTopRightRadius:10,
  padding: 0,
  width: customeWidth ? customeWidth : '100%',
  // "& .muiltr-a4ms82-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root , & .muirtl-a4ms82-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root,& svg,& p,& span,& input":{
  //   color:`${GridTheme?.disabledGlobalText?GridTheme.gloablTextColor:GridTheme?.oppositePrimaryColor} !important`,
  // }
  // ,


  // margin:'auto',
}))

const CustomeHeader = styled(Box)(({ theme, GridTheme, openCollapse }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: GridTheme?.primaryColor,
  color: GridTheme?.disabledGlobalText ? GridTheme?.gloablTextColor : GridTheme?.oppositePrimaryColor,
  width: '100%',
  height: 50,
  padding: '10px',
  borderTopLeftRadius: 5,
  borderTopRightRadius: 5,
  '& .icon': {
    fontSize: 30,
    cursor: "pointer",
    transform: openCollapse ? 'rotate(180deg)' : "rotate(0deg)"
  },
  "& svg,& span,& p": {
    color: `${GridTheme?.oppositePrimaryColor} !important`,
    fill: `${GridTheme?.oppositePrimaryColor} !important`,
    // stroke:`${GridTheme?.oppositePrimaryColor} !important`,


  }

}))

const CustomeContent = styled(Box)(({
  theme, GridTheme, openCollapse,
  search_button_text_color,
  search_button_color,
  clear_button_color,
  clear_button_text_color,
  customeHeight
}) => ({
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  // flexDirection:'column',
  flexWrap: 'wrap',
  backgroundColor: GridTheme?.paperColor,
  color: GridTheme?.disabledGlobalText ? GridTheme?.gloablTextColor : GridTheme?.oppositePrimaryColor,
  width: '100%',
  overflow: 'hidden',
  height: openCollapse ? customeHeight : 0,
  padding: 0,
  margin: 0,
  padding: '0 10px',
  transition: 'height  0.3s ease-in,margin  0s ease-in-out',
  boxShadow: " 4px 11px 21px -8px rgba(0,0,0,0.75)",
  '& p , & span ,& .MuiInputLabel-root': {
    fontSize: "15px !important"
  },

  '& .MuiOutlinedInput-root': {
    borderColor: `1px solid ${GridTheme?.paperTextColor} !important`,
    color: GridTheme?.disabledGlobalText ? GridTheme.gloablTextColor : GridTheme?.paperTextColor,
  },
  '& .MuiInputLabel-root': {
    // backgroundColor:`${GridTheme?.paperTextColor+'22'} !important`,
    color: GridTheme?.disabledGlobalText ? GridTheme.gloablTextColor : GridTheme?.paperTextColor,
  },
  '& .MuiFormControl-root': {
    // border:`1px solid ${GridTheme?.paperTextColor} !important`,
    color: GridTheme?.disabledGlobalText ? GridTheme.gloablTextColor : GridTheme?.paperTextColor,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: GridTheme?.disabledGlobalText ? GridTheme.gloablTextColor : GridTheme?.paperTextColor,
    },
    '&:hover fieldset': {
      borderColor: GridTheme?.disabledGlobalText ? GridTheme.gloablTextColor : GridTheme?.paperTextColor,
    },
    '&.Mui-focused fieldset': {
      borderColor: GridTheme?.disabledGlobalText ? GridTheme.gloablTextColor : GridTheme?.secondaryColor,
    },
  },
  '& .MuiOutlinedInput': {
    // borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    // border: "0 none",
  },
  '& .iconeFilterSearch': {
    backgroundColor: `${search_button_color} !important`,
    color: `${search_button_text_color} !important`,
    padding: '0 40px !important',
  },
  '& .iconeFilterClear': {
    backgroundColor: `transparent`,
    border: `1ox solid ${clear_button_color} !important`,
    outlineColor: `${clear_button_color} !important`,
    color: `${clear_button_text_color} !important`,
    padding: '0 40px !important',
    // '&:hover':{
    //    backgroundColor:`${clear_button_color} !important`,
    // },

  },
  '& .muirtl-2jbvvl-MuiButtonBase-root-MuiButton-root': {
    border: ` 1px solid ${clear_button_color} !important`,
    outlineColor: `${clear_button_color} !important`,
  },
  '& .muirtl-1a1fmpi-MuiInputBase-root-MuiInput-root, & .muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root': {
    paddingLeft: "5px"
  },
  '& .muirtl-1a1fmpi-MuiInputBase-root-MuiInput-root:before,& .muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root:before': {
    borderColor: `${GridTheme?.disabledGlobalText ? GridTheme.gloablTextColor : GridTheme?.paperTextColor} !important`,
    outlineColor: `${GridTheme?.disabledGlobalText ? GridTheme.gloablTextColor : GridTheme?.paperTextColor} !important`,
  },
  '& .muirtl-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root:before,& .muiltr-1ptx2yq-MuiInputBase-root-MuiInput-root:before,& .muiltr-1a1fmpi-MuiInputBase-root-MuiInput-root:before,& .muiltr-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root:before,& .muirtl-1a1fmpi-MuiInputBase-root-MuiInput-root:hover:before, &.muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root:hover:before': {
    borderColor: `${GridTheme?.disabledGlobalText ? GridTheme.gloablTextColor : GridTheme?.paperTextColor} !important`,
    outlineColor: `${GridTheme?.disabledGlobalText ? GridTheme.gloablTextColor : GridTheme?.paperTextColor} !important`,
  },
  '& .muirtl-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select': {
    display: 'flex',
    justifyContent: 'start',
  },
  '&.muirtl-iqd1hh-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon,& .muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root,& .muirtl-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root,& .muiltr-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root,& svg,& span,& p,& .muiltr-1ptx2yq-MuiInputBase-root-MuiInput-root': {
    color: GridTheme?.disabledGlobalText ? GridTheme.gloablTextColor : GridTheme?.paperTextColor + ' !important',
  },
  '& .muirtl-iqd1hh-MuiButtonBase-root-MuiChip-root,& .muiltr-iqd1hh-MuiButtonBase-root-MuiChip-root': {
    backgroundColor: GridTheme?.secondaryColor + '44 !important'
  },
  '& .muiltr-iqd1hh-MuiButtonBase-root-MuiChip-root svg,& .muirtl-iqd1hh-MuiButtonBase-root-MuiChip-root svg,& .muiltr-wpkdf9-MuiModal-root-MuiDialog-root svg': {
    color: GridTheme?.disabledGlobalText ? GridTheme.gloablTextColor : GridTheme?.paperTextColor + ' !important',
  },



  '& .muirtl-1yf1g2m::before,& .muiltr-1yf1g2m::before,& .muiltr-1vv4lmi::before,& .muirtl-1vv4lmi::before,& .muirtl-1cayd89::before,& .muiltr-1cayd89::before': {
    borderColor: `${GridTheme?.disabledGlobalText ? GridTheme.gloablTextColor : GridTheme?.paperTextColor} !important`,
    outlineColor: `${GridTheme?.disabledGlobalText ? GridTheme.gloablTextColor : GridTheme?.paperTextColor} !important`,
  },
  '& .muirtl-khgl9y .MuiFormControlLabel-label,& .muiltr-khgl9y .MuiFormControlLabel-label,& .muiltr-19d186v-MuiFormControlLabel-root .MuiFormControlLabel-label , & .muirtl-19d186v-MuiFormControlLabel-root .MuiFormControlLabel-label ,& input,& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.muiltr-yi9xwg .MuiAutocomplete-inputRoot,& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.muirtl-yi9xwg .MuiAutocomplete-inputRoot ,& .muiltr-1vv4lmi,& .muirtl-1vv4lmi,& .muirtl-o1tw8u .MuiFormControlLabel-label,& .muiltr-o1tw8u .MuiFormControlLabel-label,& .muirtl-3m5ia .MuiInput-root .MuiInput-input,& .muiltr-3m5ia .MuiInput-root .MuiInput-input,& span': {
    color: GridTheme?.disabledGlobalText ? GridTheme.gloablTextColor : GridTheme?.paperTextColor + ' !important',
  },




}))

const CustomeFooter = styled(Box)(({ theme, GridTheme, openCollapse }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: GridTheme?.primaryColor,
  color: GridTheme?.disabledGlobalText ? GridTheme?.gloablTextColor : GridTheme?.oppositePrimaryColor,
  width: '100%',
  height: 13,
  padding: '5px 10px',
  borderBottomLeftRadius: 5,
  borderBottomRightRadius: 5,
  borderTop: '1px solid rgb(162 166 174)'

}))

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme, ToolBarTheme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    backgroundColor: ToolBarTheme?.paperColor,
    color: ToolBarTheme?.disabledGlobalText ? ToolBarTheme?.gloablTextColor : ToolBarTheme?.paperTextColor,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
    '& .itmlist:hover': {
      backgroundColor: ToolBarTheme?.paperTextColor + '55',
    },
  },
}));

const StyledSpeedDial = styled(SpeedDial)(({ theme, GridTheme }) => ({
  position: 'relative',
  width: 40,
  height: 40,
  
  backgroundColor: `${GridTheme?.oppositePrimaryColor}44 !important`,
  padding: 0,
  '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
    bottom: 0,
    right: 0,
  },
  '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
    top: 0,
    left: 0,
    backgroundColor: `${GridTheme?.oppositePrimaryColor}44 !important`,
  },
}));

export default function FilterBox(props) {
  // const {

  // }=props;
  const { t, i18n } = useTranslation('common');
  const theme = useTheme();
  // const [open, setOpen] = useState(true);
  const SideMenuDrwerWidth = useSelector(state => state.settingsData.drawerWidth);
  const SideMenuOpen = useSelector(state => state.settingsData.SideMenuOpen);
  const MainTheme = useSelector(state => state.settingsData.MainTheme);
  const GridTheme = useSelector(state => state.settingsData.GridTheme);

  const directions = useSelector(state => state.settingsData.directions)
  const SideMenuTheme = useSelector(state => state.settingsData.SideMenuTheme)
  const ToolBarTheme = useSelector(state => state.settingsData.ToolbarTheme)
  const sideMenuMode = useSelector(state => state.settingsData.sideMenuMode)

  const EnglishValues = useSelector(state => state.translateData.translation_en);
  const ArabicValues = useSelector(state => state.translateData.translation_ar);


  const SearchButtonTheme = useSelector(state => state.settingsData.SearchButtonTheme);
  const { search_button_color, clear_button_color, search_button_text_color, clear_button_text_color } = SearchButtonTheme

  const [openCollapse, setOpenCollapse] = useState(false)

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (dire) => {
    setAnchorEl(null);
  }
  const [hidden, setHidden] = React.useState(false);
  const handleHiddenChange = (event) => {
    setHidden(event.target.checked);
  };
  const [actions, setActions] = useState([])
  useEffect(() => {
    const emptyArray = [];
    if (props?.hasExport) {
      emptyArray.push({ icon: <FileDownloadIcon onClick={props?.exportFunction} sx={{ color: SearchButtonTheme?.export_button_text_color + " !important" }} />, color: SearchButtonTheme?.export_button_color, name: t('TOOLTIP.EXPORT') })
    }
    if (props?.hasCreate) {
      emptyArray.push({ icon: <AddCircleOutlineIcon onClick={props?.createFunction} sx={{ color: SearchButtonTheme?.create_button_text_color + " !important" }} />, color: SearchButtonTheme?.create_button_color, name: t('TOOLTIP.CREATE') })
    }
    if (props?.hasMaps) {
      emptyArray.push({ icon: <MapIcon onClick={props?.mapFunction} sx={{ color: SearchButtonTheme?.map_button_text_color + " !important" }} />, color: SearchButtonTheme?.map_button_color, name: t('TOOLTIP.MAP') })
    }
    if (props?.hasImport) {
      emptyArray.push({ icon: <FileUploadIcon onClick={props?.importFunction} sx={{ color: SearchButtonTheme?.import_button_text_color + " !important" }} />, color: SearchButtonTheme?.import_button_color, name: t('TOOLTIP.IMPORT') })
    }
    if (props?.hasTelegram) {
      emptyArray.push({ icon: <TelegramIcon onClick={props?.telegramFunction} sx={{ color: SearchButtonTheme?.telegram_button_text_color + " !important" }} />, color: SearchButtonTheme?.telegram_button_color, name: t('TOOLTIP.SEND_TELEGRAM') })
    }
    if (props?.hasView) {
      emptyArray.push({ icon: <RemoveRedEyeIcon onClick={props?.viewFunction} sx={{ color: SearchButtonTheme?.telegram_button_text_color + " !important" }} />, color: SearchButtonTheme?.telegram_button_color, name: t('TOOLTIP.VIEW') })
    }
    setActions(emptyArray);
  }, [props, SearchButtonTheme])
  // const actions = [
  //   { icon: <FileDownloadIcon />, name: 'Export' },
  //   { icon: <AddCircleOutlineIcon />, name: 'Create' },
  //   { icon: <MapIcon />, name: 'Map' },
  //   { icon: <FileUploadIcon />, name: 'Import' },
  // ];

  return (
    <CustomeBox GridTheme={GridTheme} customeWidth={props?.customeWidth}>
      <CustomeHeader GridTheme={GridTheme} openCollapse={openCollapse}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <MdIcons.MdManageSearch style={{ margin: '0px 5px', fontSize: 27, color: GridTheme?.oppositePrimaryColor }} />
          <Typography variant="h6" sx={{ color: GridTheme?.oppositePrimaryColor }}>
            {
              props?.customeTitle ? props?.customeTitle : t('GLOBAL.SEARCH_TITLE')
            }

          </Typography>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>

          <Box sx={{
            width: 80,
            height: 40,
            backgroundColor: GridTheme?.oppositePrimaryColor + 44,
            borderRadius: 33,
            display: 'flex',
            justifyContent: 'center',
            alignItems: "center",
            margin: '0 5px',
            color: `${GridTheme?.oppositePrimaryColor} !important`

          }}>
            <Typography variant='p' sx={{
              fontSize: '13px !important',
              color: `${GridTheme?.oppositePrimaryColor} !important`
            }}>
              {props?.resultLength} {t('GLOBAL.SEARCH_RESULT_OF_SEARCH')}
            </Typography>
          </Box>

          <Box
            sx={{
              position: 'relative', display: 'flex', justifyContent: 'center',
              "& .MuiSpeedDial-fab":
              {
                width: "40px !important",
                minHeight:'40px !important',
                backgroundColor: GridTheme?.oppositePrimaryColor + 44,
                color: GridTheme?.oppositePrimaryColor,
                '&:hover': {
                  backgroundColor: GridTheme?.oppositePrimaryColor + '33'
                }
              },

            }}>
            <StyledSpeedDial
              ariaLabel="SpeedDial playground example"
              hidden={hidden}
              icon={<MoreVertIcon sx={{ color: GridTheme?.oppositePrimaryColor + " !important" }} />}
              direction='down'
              sx={{
                '& #SpeedDialplaygroundexample-actions .MuiButtonBase-root:nth-child(1)': {
                  // backgroundColor:'green !important',
                  color: '#ffffff',
                  position: 'relative',
                  top: '-15px',
                },
                '& #SpeedDialplaygroundexample-actions .MuiButtonBase-root:nth-child(2)': {
                  // backgroundColor:'blue !important',
                  color: '#ffffff',
                  position: 'relative',
                  top: '-25px',
                },
                '& #SpeedDialplaygroundexample-actions .MuiButtonBase-root:nth-child(3)': {
                  // backgroundColor:'#c40c3d !important',
                  color: '#ffffff',
                  position: 'relative',
                  top: '-30px',
                },
                '& #SpeedDialplaygroundexample-actions .MuiButtonBase-root:nth-child(4)': {
                  // backgroundColor:'orange !important',
                  color: '#ffffff',
                  position: 'relative',
                  top: '-35px',
                }
              }}

            >
              {actions.map((action) => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  sx={{
                    backgroundColor: action?.color ? action?.color : '#00f',
                    '&:hover': {
                      backgroundColor: action?.color ? action?.color : '#0000ff',
                    }
                  }}
                />
              ))}
            </StyledSpeedDial>
          </Box>
          {/* <IconButton
             sx={{
                backgroundColor:GridTheme?.oppositePrimaryColor+44,
                outline:'none !important',
                border:'none !important',
                '&:hover':{
                    backgroundColor:GridTheme?.oppositePrimaryColor+'33'
                }
             }}
             onClick={handleClick}
            >
                 <MoreVertIcon sx={{color:GridTheme?.disabledGlobalText?GridTheme.gloablTextColor:GridTheme?.oppositePrimaryColor}}/>
            </IconButton> */}

          <IconButton
            sx={{
              backgroundColor: GridTheme?.oppositePrimaryColor + 44,
              color: GridTheme?.oppositePrimaryColor,
              outline: 'none !important',
              border: 'none !important',
              padding: '5px',
              margin: '0 2px 0 5px',
              '&:hover': {
                backgroundColor: GridTheme?.oppositePrimaryColor + '33'
              }
            }}

          >
            <KeyboardArrowDownIcon className="icon" sx={{ color: GridTheme?.oppositePrimaryColor }} onClick={() => {
              setOpenCollapse(!openCollapse)
              props.setOpenSummery(!props?.openSummery)
            }
            } />
          </IconButton>

        </Box>
      </CustomeHeader>
      <CustomeContent
        customeHeight={props?.customeHeight ? props?.customeHeight : '300px'}
        GridTheme={GridTheme}
        MainTheme={MainTheme}
        openCollapse={openCollapse}
        search_button_text_color={search_button_text_color}
        search_button_color={search_button_color}
        clear_button_color={clear_button_color}
        clear_button_text_color={clear_button_text_color}
      >
        {
          props?.children
        }
      </CustomeContent>
      <CustomeFooter GridTheme={GridTheme} openCollapse={openCollapse}>

      </CustomeFooter>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        ToolBarTheme={GridTheme}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem disableRipple sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button sx={{
            '&:hover': {
              backgroundColor: GridTheme?.secondaryColor + '88',
            },
            width: '70%', backgroundColor: GridTheme?.secondaryColor, color: GridTheme?.disabledGlobalText ? GridTheme?.gloablTextColor : GridTheme?.oppositeSecondaryColor
          }}>Export</Button>
        </MenuItem>
        <MenuItem disableRipple sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button sx={{
            '&:hover': {
              backgroundColor: GridTheme?.secondaryColor + '88',
            }, width: '70%', backgroundColor: GridTheme?.secondaryColor, color: GridTheme?.disabledGlobalText ? GridTheme?.gloablTextColor : GridTheme?.oppositeSecondaryColor
          }}>Create</Button>
        </MenuItem>
        <MenuItem disableRipple sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button sx={{
            '&:hover': {
              backgroundColor: GridTheme?.secondaryColor + '88',
            }, width: '70%', backgroundColor: GridTheme?.secondaryColor, color: GridTheme?.disabledGlobalText ? GridTheme?.gloablTextColor : GridTheme?.oppositeSecondaryColor
          }}>Map</Button>
        </MenuItem>
        <MenuItem disableRipple sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button sx={{
            '&:hover': {
              backgroundColor: GridTheme?.secondaryColor + '88',
            }, width: '70%', backgroundColor: GridTheme?.secondaryColor, color: GridTheme?.disabledGlobalText ? GridTheme?.gloablTextColor : GridTheme?.oppositeSecondaryColor
          }}>Import</Button>
        </MenuItem>
      </StyledMenu>

    </CustomeBox>
  )
}

