import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Pane, Dialog } from 'evergreen-ui';
import axios from "axios";
// import Select from "react-select";
import Host from "../../../assets/js/Host";
import DialogForm from "../Share/DialogForm";
import {
    Form,
    SaveButton,
    CancelButton,
} from './StyledComponents';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { toast } from 'react-toastify';
import {useSelector,useDispatch} from 'react-redux'
import FooterBottons from '../Share/FooterBottons'
import { withStyles } from '@mui/material/styles';
import CustomeCheckBox from "../Share/CustomeCheckBox";
import {useTranslation} from "react-i18next";
import { useGlobalContext } from '../../../context'
import swal from 'sweetalert';
import {Box,Typography,Select,Button} from '@mui/material'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Grid from '../Share/Grid'
import FilterBox from '../Share/FilterBox';
import moment from 'moment'
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { AiFillCloseCircle } from 'react-icons/ai'
import {IconButton} from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import helpers from "../../../assets/js/helper";
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import EditIcon from '@mui/icons-material/Edit';
import {Tooltip} from '@mui/material'
import DropDownGrid from '../Share/DropDownGrid'
import { ListItemIcon} from '@mui/material';
import red from '@mui/material/colors/red';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

import EditUserShift from "./EditUserShift";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filter = createFilterOptions();
const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const demoarr=[
    {
        id:1,
        name:'att A'
    },
    {
        id:2,
        name:'att b'
    },
    {
        id:3,
        name:'att c'
    },
]

export const UserShifts = (props) => {
    const [value, setValue] = React.useState(null);
    const [loading, setloading] = useState(false);
    const [roles, setroles] = useState([]);
    const [userRolesSelected, setuserRolesSelected] = useState([]);
    const directions =useSelector(state=> state.settingsData.directions);
    const MainTheme =useSelector(state=> state.settingsData.MainTheme);
    const {t, i18n} = useTranslation('common');
    const [pageSize, setPageSize] = useState(5);
    const [pageNumber, setpageNumber] = useState(1);
    const [pointsTotal, setPointsTotal] = useState(0)
    const [pointsNumberOfPages, setPointsNumberOfPages] = useState(0)
    const screenWidth=useSelector(state=>state.settingsData.screenWidth)
    const SearchButtonTheme=useSelector(state=> state.settingsData.SearchButtonTheme);
    const FilterButtonHeight= useSelector(state => state.settingsData.FilterButtonHeight)
    const [rows, setRow] = useState([])
    const columns = [
        { field: 'id', headerName: t('USERS.FORM_ID'), minWidth:50,flex:1, },
        {
            field: 'start_Time', headerName: t('USERS.FORM_STRAT_TIME'), minWidth:100,flex:1,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>
                    {helpers.convertMinutesToHourse(rows?.row?.shift?.start_minutes,0)}
                </div>
            )
        },
        {
            field: 'start_minutes', headerName: t('USERS.FORM_END_TIME'), minWidth:100,flex:1,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>
                    {helpers.convertMinutesToHourse(rows?.row?.shift?.end_minutes,0)}
                </div>
            )
        },
        {
            field: 'minute_required', headerName: t('USERS.FORM_REQUIRED_TIME'), minWidth:100,flex:1,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>
                    {helpers.convertMinutesToHourse(rows?.row?.shift?.minuts_required,1)}
                </div>
            )
        },
        {
            field: 'atttendance_type', headerName: t('USERS.FORM_ATTENDANCE_TYPE'), minWidth:100,flex:1,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>
                    {rows?.row?.attendance_type?.name}
                </div>
            )
        },

        {
            field: 'actions', flex: 1,
            headerName: 'Actions',
            renderCell: (rows) => (
                <DropDownGrid>

                    <MenuItem onClick={() => {UpdateUserShift(rows?.row)}}>
                        <ListItemIcon sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            margin:'0px 7px 0 0'
                        }}>
                                <EditIcon style={{ fill: "#111827" }} />
                        </ListItemIcon>
                        {directions=='rtl'?'تعديل':'Edit'}
                    </MenuItem>

                    <MenuItem onClick={async() => {  
                      deleteObject(rows?.row)
                    }}>
                            <ListItemIcon sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                margin:'0px 7px 0 0'
                            }}>
                               <DeleteRoundedIcon sx={{ color: red[500] }} />

                            </ListItemIcon>
                            {directions != 'rtl' ? "Delete" : 'حذف'}
                    </MenuItem>

                </DropDownGrid>
            ),
        },
    ]
    const [editUserShift,setEditUserShift]=useState(false)
    const [selectedUserShift,setSelectedUserSift]=useState(null)

    const [userGroup,setUserGroup]=useState(null);
    const [userAccessGroup,setUserAccessGroup]=useState([]);

    const [accessGroupForUser,setAccessGroupsForUser]=useState([])

    
    const token = cookies.get("token")
    const [object, setobject] = useState({});
    const {attendance_type_shifts, visitFollowups ,users,Shifts,attendance_typs,groups,notification_types, pointsStatuses, pointStatus, setPointStatus, exportToCSV, points, district, region, districts, regions, ownerName, shopName, clearPointsAdvanceSearchFields, setRegion, setDistrict, setShopName, setOwnerName, fetchData } = useGlobalContext()

    const [SelectAllCheckBox,setSelectAllCheckBox]=useState(false)
    const [selectedAttendanceType,setSelectedAttendanceType]=useState('')
    const [listOfUserShiftData,setListOfUserShiftData]=useState({
        user_id:0,
        attendance_type_id:0,
        shifts_ids:null,
        activation_date:new Date(),
        singleObject:{}
    })

    const UpdateUserShift=(row)=>{
        setEditUserShift(true)
        setSelectedUserSift(row)
    }
    useEffect(()=>{
        fetchData('SHIFTS','shifts_list')
        fetchData('ATTENDANCE_TYPES','attendance_types_list')
    },[])
    const advancedSearch=()=>{
        fetchData('ATTENDANCE_TYPES_SHIFTS','attendance_type_shift_users',{
            page:pageNumber,
            page_size:pageSize,
            user_id:props?.user?.id,
        })
    }
    useEffect(()=>{
        if(props?.user){
            advancedSearch()
        }
    },[props?.user,pageNumber])

    useEffect(()=>{
     
      if(props?.user){
      
        let obj={
             ...listOfUserShiftData,
             user_id:props?.user?.id,
        };
        setListOfUserShiftData(obj)
      }
    },[props?.user])

    const handleArrayBeforStore=()=>{
          console.log('asdasdasdasdas',listOfUserShiftData?.shifts_ids)
        if(listOfUserShiftData&&listOfUserShiftData?.attendance_type_id!=2){
             if(listOfUserShiftData?.shifts_ids)
               return [listOfUserShiftData?.shifts_ids?.id]
               else return []
        }
         else {
            return listOfUserShiftData&&listOfUserShiftData?.shifts_ids?.length>0?listOfUserShiftData?.shifts_ids?.map((itm)=>itm?.id):[]
         }
    }
    


    const submitForm = async () => {
        
        try {
            
        
            if (props?.user?.id) {                
                let formdata = new FormData();

                // formdata.append("roles", JSON.stringify(userRolesSelected));
               
                const resourcesResult = await axios({
                    url: Host + "users/attendance_shifts/"+props?.user?.id,
                    method: "patch",
                    headers: {
                        "Authorization": `Bearer ${token}`,
                       
                        accept: "application/json",
                        // Accept: "application/json",
                        // "Content-Type": "multipart/form-data",
                        'X-localization': language,
                    },
                    data:{
                        ...listOfUserShiftData,
                        activation_date:moment(listOfUserShiftData?.activation_date).format('YYYY-MM-DD HH:mm:ss'),
                        shifts_ids:handleArrayBeforStore()
                    }
                    
                })
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success("updated ")
                        props.setShowUseAccessrGroup(false,true)
                    } else if (resourcesResult.data.status === false) {
                        props.setShowUseAccessrGroup(true,false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setShowUseAccessrGroup(true,false)
                }
                setloading(false)
            }
            else{
                swal({
                    title: `${t('SWEATALERT_MESSAGES.WARNNING_MESSAGE')}`,
                    text: `${t('SWEATALERT_MESSAGES.USER_ID_NOT_FOUND')}`,
                    icon: "warning",
                    button: `${t('SWEATALERT_MESSAGES.WARRNING_BUTTON')}`,
                });
                return;
            }
           
        } catch (error) {
            props.setShowUseAccessrGroup(true,false)
            const resourcesResult=error.response            
            if (resourcesResult && resourcesResult.status === 400) {
                if(typeof resourcesResult.data.data === "object")
                {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key)=>{
                    
                        resourcesResult.data.data[key].map((message)=>{
                            toast.error(message)
                        })
                    })
                }
                else{
                    
                    toast.error(resourcesResult.data.data)
                }
                
                
            }
            else if(resourcesResult && resourcesResult.status === 401) {
             
                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else
            {
              
                toast.error("network error")
            }
        }
        
    };

    const deleteObject = async (object) => {
        if (!window.confirm("Delete the item?")) {
            return
        }
        try {
            if(!checkActivationDateUpdate(object)){
                toast.warning(directions=='rtl'?'لا يمكنك حذف  هذا الاسناد ':"you can't delete this assignment")
                return
             }
            setloading(true)
            const result=await axios({
                url: Host + "attendance_type_shift_users/"+object?.id,
                method: "delete",
                headers: {
                "Authorization" : `Bearer ${token}`,
                    Accept:"application/json",
                    'X-localization':language,
                }
        
            })
            if(result && result.status == 200)
            {
                toast.success(result.data.data)
                advancedSearch();
            } else if (result.data.status === false) {
                toast.error("Error")
            }
        } catch (error) {
            const responseResult=error.response            
            if (responseResult && responseResult.status === 400) {
                if(typeof responseResult.data.data === "object")
                {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key)=>{
                    
                        responseResult.data.data[key].map((message)=>{
                            toast.error(message)
                        })
                    })
                }
                else{
                    toast.error(responseResult.data.data)
                }
                
            }
            else if(responseResult && responseResult.status === 401) {
                toast.error(responseResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else
            {
                toast.error("network error")
            }
        }
        setloading(false)
    };

    const checkActivationDateUpdate=(data)=>{
        var oldDate = data?.activation_date?new Date(data?.activation_date):null;
        var newDate = new Date()

        if(!oldDate&&newDate){
              return true;
        }else if(newDate&&oldDate){
            var same = oldDate.getTime() <= newDate.getTime();
            if(same){
               return false;
            }else return true;
        }

    }

    const handleInputChange = (event,role_id) => {
        const target = event.target;
        // console.log("ischecked",target.checked);
        if(target.checked)
        {
          
            setuserRolesSelected([...userRolesSelected,role_id])
        }
        
        else
        {
            const userRolesSelectedtemp = userRolesSelected.filter(function(item) {
                return item !== role_id
            })
            setuserRolesSelected(userRolesSelectedtemp)
        }
        

    };

    useEffect(()=>{
       if(userRolesSelected?.length==roles?.length){
        setSelectAllCheckBox(true)
       }else{
        setSelectAllCheckBox(false)

       }
    },[roles,userRolesSelected])

    const handelSelectedAllCheckBox=(e)=>{
        const target = e.target;
        setSelectAllCheckBox(target?.checked)

        if(target.checked)
        {   
            const allRolesId=[];
            roles?.map((role)=>{
                allRolesId.push(role?.id)
            })
            setuserRolesSelected(allRolesId)
        }
        
        else{
            // const userRolesSelectedtemp = userRolesSelected.filter(function(item) {
            //     return item !== role_id
            // })
            setuserRolesSelected([])
        }
    }


    const styles={
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
        '& .form-check-input':{
            backgroundColor:MainTheme?.secondaryColor,
        }
    }
    const marginLabel={
        marginLeft:'30px',
        marginRight:'30px',

    }

    const getFormatMinutesToTime=(min)=>{
        let hourse=Math.floor(min/60)
        let minutes=Math.floor(min % 60)

        return hourse+':'+minutes
    }

    useEffect(()=>{
         console.log(accessGroupForUser)
    },[accessGroupForUser])

    const handleCheckBoxChanged=(value)=>{
        // let check=false;
        // if(listOfUserShiftData&&listOfUserShiftData?.shifts_ids?.length>0){
        //     listOfUserShiftData?.shifts_ids?.map((itm)=>{
        //         if(itm?.id==value?.id)
        //            check=true
        //     })
        // }
        // //    check=listOfUserShiftData?.shifts_ids?.includes(value?.id)

        // if(!check){
        //     console.log('enterhere', listOfUserShiftData?.shifts_ids)
        //     let temArray=[];
        //     listOfUserShiftData?.shifts_ids&&listOfUserShiftData?.shifts_ids?.length>0&&listOfUserShiftData?.shifts_ids?.map((itm)=>{
        //         temArray.push(itm)
        //     })
        //     temArray.push(value)
        //     setListOfUserShiftData({
        //         ...listOfUserShiftData,
        //         shifts_ids:[...temArray]
        //     })
        // }else{
        //     let temArray=[];
        //     if(listOfUserShiftData&&listOfUserShiftData?.shifts_ids?.length>0){
        //         listOfUserShiftData?.shifts_ids?.map((itm)=>{
        //             if(itm?.id!=value?.id)
        //                temArray.push(itm)
        //         })
        //     }
        //     setListOfUserShiftData({
        //         ...listOfUserShiftData,
        //         shifts_ids:[...temArray]
        //     })
        // }
       if(listOfUserShiftData?.attendance_type_id==2){
            setListOfUserShiftData({
                ...listOfUserShiftData,
                shifts_ids:[...value]
            })
        }
        else{
            setListOfUserShiftData({
                ...listOfUserShiftData,
                shifts_ids:value
            })
        }
    }

    const handlePageChange = (newPage) => {
        setpageNumber(newPage + 1)
    }

    // useEffect(() => {
    //   if(props?.user){
    //     fetchData('FOLLOWUP_STATUSES_LIST', 'followup_statuses_list')
    //     advanceSearch()
    //   }
    // }, [props.user]);

    useEffect(() => {

        if (attendance_type_shifts && attendance_type_shifts.data && attendance_type_shifts.data.length > 0) {
           
            setRow(attendance_type_shifts?.data);
            setPointsTotal(attendance_type_shifts.total)
            setPointsNumberOfPages(attendance_type_shifts.last_page)
        }
        else {
            setRow([]);
            setPointsTotal(0)
            setPointsNumberOfPages(0)
        }
    }, [attendance_type_shifts])

  

    return (
        <DialogForm
            open={props.showAccessGroup}
            close={props.setShowUseAccessrGroup}
            CustomeWidth='100%'
            CustomeHeight="fit-content"
            loaderActive={loading}
            userShift={false}
            headerChildren={(
                // <h4 style={{...styles}}></h4>
                <>
                <IconButton aria-label={"Close"} onClick={() => { props.setShowUseAccessrGroup(false) }} style={{ position: 'absolute', top: '0px', left: '6px', fontSize: '37px', }}>
                    <AiFillCloseCircle style={{ color: MainTheme?.paperTextColor,outline:'none !important' ,border:'none !important' }} />
                </IconButton>
                <Typography component={'h1'} variant={'h4'} style={{marginTop:'8px',marginBottom:'10px', color: MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme.paperTextColor, width: '100%', textAlign: 'center' }}>
                   {t("USERS.USER_ASSIGN_SHIFT_TYPE")}
                </Typography>
              </>
           )}
           hasHeader={true}
        >
             {
                editUserShift?
                    <EditUserShift advancedSearch={advancedSearch} showEditUserShift={editUserShift} setShowEditUserShift={setEditUserShift} userShift={selectedUserShift} />
                :null
             }
            <Box sx={{padding:'10px'}}>
                <FilterBox 
                    resultLength={pointsTotal} 
                    // exportFunction={null} 
                    hasExport={false}  
                    hasCreate={false}  
                    hasMaps={false}  
                    hasImport={false}
                    customeHeight={'fit-content'}
                    customeTitle={t('USERS.FILTERSEARCH_HEADER')}
                >  
            
                    <Box sx={{
                        width:{xs:'98%',sm:'98%',md:'47%',lg:'47%',xl:'47%',

                        }}}>
                        <FormControl 
                            fullWidth variant="standard">
                            <InputLabel id="demo-simple-select-label">{t('USERS.FORM_ATTENDANCE_TYPE')}</InputLabel>
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={listOfUserShiftData?.attendance_type_id}
                            label={t('USERS.FORM_ATTENDANCE_TYPE')}
                            onChange={(e)=>{ 
                                if(e.target.value!=2) 
                                setListOfUserShiftData({
                                    ...listOfUserShiftData,
                                    attendance_type_id:e.target.value,
                                    shifts_ids:null,
                                })
                                else
                                setListOfUserShiftData({
                                    ...listOfUserShiftData,
                                    attendance_type_id:e.target.value,
                                    shifts_ids:[],
                                })


                            }}
                            >
                            {
                                attendance_typs&&attendance_typs?.length>0&&attendance_typs?.map((itm)=>(
                                    <MenuItem value={itm?.id}>{itm?.name}</MenuItem>
                                ))
                            }
                            
                            </Select>
                        </FormControl>
                    </Box>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                                
                                label={t('USERS.FORM_ACTIVATION_TIME')}
                                value={listOfUserShiftData?.activation_date}
                                inputFormat="YYYY/MM/DD HH:mm:ss"
                                ampm={false}
                                onChange={(date)=>{
                                     setListOfUserShiftData({
                                        ...listOfUserShiftData,
                                        activation_date:new Date(date)
                                     })
                                }}
                                renderInput={(params) => <TextField {...params}  variant="standard" sx={{width:{xs:'98%',sm:'98%',md:'47%',lg:'47%',xl:'47%'},margin:'10px'}} />}
                            />
                    </LocalizationProvider>
                  

                    <Autocomplete
                        
                        id="checkboxes-tags-demo"
                        options={Shifts}
                        value={listOfUserShiftData?.shifts_ids?listOfUserShiftData?.shifts_ids:null}
                        disableCloseOnSelect
                        multiple={listOfUserShiftData?.attendance_type_id!=2?false:true}
                       
                        getOptionLabel={(option) =>` ${getFormatMinutesToTime(option?.start_minutes)} -- ${getFormatMinutesToTime(option?.end_minutes)}`}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {` ${getFormatMinutesToTime(option?.start_minutes)} -- ${getFormatMinutesToTime(option?.end_minutes)} --${Math.floor(option?.end_minutes/60)}H`}
                            </li>
                        )}
                        onChange={(event, newValue) => {
                            handleCheckBoxChanged(newValue)
                            console.log('selectedCheckbox',newValue)
                            console.log('selectedCheckbox',event.target.value)

                          }}
                        sx={{
                            width:{xs:'98%',sm:'98%',md:'47%',lg:'47%',xl:'47%',marginTop:0} 
                        }}
                        renderInput={(params) => (
                            <TextField sx={{width:'100%' }} {...params} label={`${t('USERS.FORM_SHIFTS')}`} variant="standard" />
                        )}
                    />

                    <Box  sx={{width:'100%',display:'flex',justifyContent:'end'}}>
                        <Button
                            variant="contained"
                            disabled={loading}
                            spacing={2} sx={{margin: 1,backgroundColor:SearchButtonTheme?.save_button_color,color:SearchButtonTheme?.save_button_text_color,
                                '&:hover':{
                                    backgroundColor:SearchButtonTheme?.save_button_color+'88',
                                } ,
                                height:`${FilterButtonHeight}px`
                            }}
                            onClick={() => {

                                submitForm()
                            }} >
                            { t('USERS.FORMVIEW_SEARCH_SAVE_BUTTON')}
                        </Button>
                    </Box>


                </FilterBox> 

        
                <Grid
                    rows={rows}
                    columns={columns}
                    rowTotals={pointsTotal}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    pageNumber={pageNumber}
                    setPageNumber={setpageNumber}
                    loading={loading}
                    handlePageChange={handlePageChange}
                    NumberOfPage={pointsNumberOfPages}
                    directions={directions}
                    reRender={props.showAccessGroup}
                    hasCreate={false}
                    createObject={null}
                    title_en=""
                    title_ar=""
                />
                {/* <FooterBottons close={props.setShowUseAccessrGroup} submitForm={submitForm} />   */}
            </Box>
        </DialogForm>

    );
};
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'blue',

    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
        width: '100%',
        border: '1px solid #ababab',
        borderRadius: 5,
        padding: 0,
        display: 'flex'
    }),
    container: () => ({

        width: '80%',
        position: 'relative',
        boxSizing: 'border-box',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}

export default UserShifts;
