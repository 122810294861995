import React,{useEffect} from 'react'
import {Wrapper,Upload} from './StyledComponents/InputFileStyledComponent';
import {useSelector,useDispatch} from 'react-redux'
import AttachmentIcon from '@mui/icons-material/Attachment';
import {useTranslation} from "react-i18next";

export default function InputFile({object,setObject,title,subTitle,onFileChange,submitForm,loading}) {
    const MainTheme=useSelector(state=>state.settingsData.MainTheme);
    const directions=useSelector(state=>state.settingsData.directions);
    const [t, i18n] = useTranslation('common');

    useEffect(()=>{
        const body = document.querySelector('body');
        const upload = document.querySelector('.upload');
        const uploadButtonText = document.querySelector('.upload-button-text');
        const uploadFilename = document.querySelector('.upload-filename');
        const fileInput = document.getElementById('file');
        fileInput.onchange = () => uploadFile(fileInput.files[0]);
        function uploadFile(file) {
            if (file) {
                onFileChange(file)
                // setObject(file.files[0])

                // Add the file name to the input and change the button to an upload button
                uploadFilename.classList.remove('inactive');
                uploadFilename.innerText = file.name;
                uploadButtonText.innerText = 'Upload';
                fileInput.remove();
                uploadButtonText.addEventListener("click", async () => {
                    upload.classList.add("uploading");

                    submitForm(file)
                    // Here you can upload the file to a database, server, or wherever you need it.
                    // You can access the uploaded file by the `file` parameter.
                    // Reset the input after a delay. For actual use, only remove the uploading class when the file is done uploading!
                    if(!loading)
                     upload.classList.remove("uploading");
                    
                });
            }
        }
    //     if(uploadButtonText.innerText == 'Upload'){
    //         uploadButtonText.addEventListener("click", async () => {
    //             submitForm()
    //             upload.classList.add("uploading");
    //             // Here you can upload the file to a database, server, or wherever you need it.
    //             // You can access the uploaded file by the `file` parameter.
    //             // Reset the input after a delay. For actual use, only remove the uploading class when the file is done uploading!
    //             if(!loading)
    //             upload.classList.remove("uploading");
                
    //         });
    //    }

        // Drop stuff
        const dropArea = document.querySelector('.drop-area');
        ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
            dropArea.addEventListener(eventName, preventDefaults, false);
        });
        function preventDefaults(e) {
            e.preventDefault();
            e.stopPropagation();
        }
        // Add dropArea bordering when dragging a file over the body
        ;
        ['dragenter', 'dragover'].forEach(eventName => {
            body.addEventListener(eventName, displayDropArea, false);
        });
        ['dragleave', 'drop'].forEach(eventName => {
            body.addEventListener(eventName, hideDropArea, false);
        });
        ['dragenter', 'dragover'].forEach(eventName => {
            dropArea.addEventListener(eventName, highlight, false);
        });
        ['dragleave', 'drop'].forEach(eventName => {
            dropArea.addEventListener(eventName, unhighlight, false);
        });
        function highlight(e) {
            if (!dropArea.classList.contains('highlight'))
                dropArea.classList.add('highlight');
        }
        function unhighlight(e) {
            dropArea.classList.remove('highlight');
        }
        function displayDropArea() {
            if (!dropArea.classList.contains('highlight'))
                dropArea.classList.add('droppable');
        }
        function hideDropArea() {
            dropArea.classList.remove('droppable');
        }
        // Handle dropped files
        dropArea.addEventListener('drop', handleDrop, false);
        function handleDrop(e) {
            let dt = e.dataTransfer;
            let files = dt.files;
            let file = files[0];
            uploadFile(file);
        }

    },[])
  return (
    <Wrapper className="wrapper">
        <h1>{title}</h1>
        <p>{subTitle}</p>
        <div className="upload-wrapper">
            <Upload className="upload drop-area" buttonBgColor={MainTheme?.secondaryColor} buttonTextColor={MainTheme?.oppositeSecondaryColor} color={MainTheme?.gloablTextColor} bgColor={MainTheme?.paperColor}>
                <div className="upload-info" >
                   <AttachmentIcon sx={{color:MainTheme?.gloablTextColor,margin:'0 5px'}} size={16}/>
                    <span className="upload-filename inactive drop-text">{t('GLOBAL.IMPORT_FORM_MESSAGE_NOT_SELECTED_FILE')}</span>
                </div>
                <button className="upload-button" >
                    <input type="file" id="file" />
                    <label for="file" className="upload-button-text">{t('GLOBAL.IMPORT_FORM_CHOOSE_FILE_BUTTON')}</label>
                </button>
                <div className="upload-hint" >{t('GLOBAL.IMPORT_FORM_UPLOAD_BUTTON')}...</div>
                <div className="upload-progress" ></div>
            </Upload>
        </div>
    </Wrapper>
  )
}
