import React, { useEffect ,useState} from 'react'
import {Pagination as CustomePagination} from '@mui/material';
import {useSelector,useDispatch} from 'react-redux'
import './Pagination.css'
import Box from '@mui/material/Box';
import {ContainerPageNumber} from './StyledComponents/GridStyledComponent'
import {useTranslation} from "react-i18next";

export default function Pagination({count,page,showFirstButton,showLastButton,pageNumber,setpageNumber,totalPage}){
    const directions = useSelector(state => state.settingsData.directions)
    const SideMenuOpen = useSelector(state => state.settingsData.SideMenuOpen)
    const GridTheme = useSelector(state => state.settingsData.GridTheme)
    const [t, i18n] = useTranslation('common');


    const [WindowWidth,setWindowWidth]=useState(window.innerWidth)

    useEffect(()=>{
      window.addEventListener('resize',()=>{
        setWindowWidth(window.innerWidth)
      })
    },[])

  return (
    <div className="containerPagination" style={{position:'relative',top:'10px'}}>
        <Box   className={`pagination ${SideMenuOpen?'menuOpen':''}`} 
        sx={{
          backgroundColor:GridTheme?.paperColor,
          '& .muirtl-1md7q8q-MuiPagination-root li button:hover,& .muirtl-13gxkbk-MuiPagination-root li button:hover,& .muirtl-lgayqv li button:hover':{
            color:GridTheme?.gloablTextColor+' !important',
          }
        }}>
            <CustomePagination
                className="PaginationHimSelf"
                color="primary"
                size={WindowWidth<=1000?'medium':WindowWidth<=991?'small':"large"}
                count={count}
                page={page}
                showFirstButton={showFirstButton}
                showLastButton={showLastButton}
                onChange={(e, p) => {
                    setpageNumber(p)
                }}
                sx={{
                  '& li button':{
                    color:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.paperTextColor,
                    backgroundColor:`${GridTheme?.paperColor}55`,
                  },
                  '& li button:hover':{
                    color:GridTheme?.paperTextColor,
                    backgroundColor:GridTheme?.paperColor,

                  },
                  '& MuiPaginationItem-textPrimary':{
                    backgroundColor:GridTheme?.paperColor+'33',
                  },
                  '& .muiltr-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected':{
                    color:GridTheme?.paperTextColor,
                    backgroundColor:GridTheme?.paperColor+'33',
                  },
                  '& .muirtl-19ld6d5-MuiPagination-root li button:hover':{
                    color:GridTheme?.gloablTextColor+' !important',
                  }

                }}
            />
            <ContainerPageNumber className="containerPageNumber" color={GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.paperTextColor} bgColor={GridTheme?.paperColor}>
                <input label={t('GLOBAL.PAGINATION_PAGE_NUMBER')} type="number" value={pageNumber} className="pageNumber" min={totalPage>0?1:0} max={totalPage} onChange={(e)=>{
                  setpageNumber(parseInt(e.target.value))
                }} />
                <span>{t('GLOBAL.PAGINATION_PAGE_NUMBER')}</span>
            </ContainerPageNumber>
        </Box>
   </div>
  )
}
