import React, {useEffect,useState} from 'react';

import PointRequestForm from './PointRequestForm';
import moment from 'moment';
import PointPreview from './PointPreview';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import IconButton from "@mui/material/IconButton";
// import PreviewRoundedIcon from '@mui/icons-material/PreviewRounded';
import SettingsApplicationsRoundedIcon from '@mui/icons-material/SettingsApplicationsRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
// import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

import Tooltip from '@mui/material/Tooltip';

import "../../../assets/css/DG.css"
import "../../../assets/css/AccordionStyle.css"
import red from '@mui/material/colors/red';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {useSelector} from 'react-redux'
import Autocomplete from '@mui/material/Autocomplete';
import DropDownGrid from '../Share/DropDownGrid';
import {MenuItem,ListItemIcon} from '@mui/material'
import { useGlobalContext } from '../../../context'
import BasicDatePicker from '../GeneralComponents/BasicDatePicker';
import PointsMapDialog from '../Points/PointsMapDialog';
import Grid from '../Share/Grid';
import {useTranslation} from "react-i18next";
import FilterBox from '../Share/FilterBox';
import helpers from '../../../assets/js/helper'
import BasicDatePickerV2 from '../GeneralComponents/BasicDatePickerV2';
import SearchFieldV2 from '../GeneralComponents/SearchFieldV2'
import CustomeFilter from '../Share/CustomeFilter'

function PointRequest() {
    const [t, i18n] = useTranslation('common');

    const [rows,setRow] = useState([])
    const [comparePoint,setcomparePoint] = useState(null)
    const directions =useSelector(state=>state.settingsData.directions)
    const MainTheme =useSelector(state=>state.settingsData.MainTheme)
    const GridTheme =useSelector(state=>state.settingsData.GridTheme)

    const FilterButtonHeight =useSelector(state=>state.settingsData.FilterButtonHeight)
    const SearchButtonTheme=useSelector(state=> state.settingsData.SearchButtonTheme);
    const screenWidthRedux=useSelector(state=>state.settingsData.screenWidth)
    const filterSearchBoxMode = useSelector(state=> state.settingsData.filterSearchBoxMode)


    const [showPointRequestForm, setshowPointRequestForm] = useState(false);
    const [showPointPreview, setshowPointPreview] = useState(false);
    const [showPointRequestMapDialog,setshowPointRequestMapDialog] = useState(false)
    const [selectedPointRequest, setselectedPointRequest] = useState(null);

    const [pageSize, setPageSize] = useState(5);    
    const [pageNumber, setpageNumber] = useState(1);
    const [pointsTotal, setPointsTotal] = useState(0)
    const [pointsNumberOfPages, setPointsNumberOfPages] = useState(0)

    const {GetRegionOfPoints,getRegionOfPoint, loading,exportToCSV,setPointsRequestsUpdate,selectedPointRequestStatus,pointRequestStatus,pointsRequestsUpdate,fromToDate,user,district,region,users,pointsRequestsStatuses,regions,ownerName,shopName,clearPointsAdvanceSearchFields,setSelectedPointRequestStatus,setFromDate,setToDate,setPointRequestStatus,setRegion,setUser,pointsRequests,fetchData,deleteObject} = useGlobalContext()        

    const [accExpansion,setAccExpansion] = useState(false)

    const [polygon, setPolygon] = useState({
        paths: [],
        strokeColor: "#FF0000",
        fillColor: "#FF0000"
    })


    const updateObject = async (object) => {
      
        await fetchData('POINT_UPDATE',"points/" + object.point_id);
        setselectedPointRequest(object)
        setshowPointRequestForm(true)
    };
    
    
    const handleExpansion = (expansion) => setAccExpansion(expansion)
    const handleSetshowPointRequestForm = (openStatus,needsReload) => { 
                   if (!openStatus)
                   {
                    setPointsRequestsUpdate({})
                   }
      setshowPointRequestForm(openStatus)
    };
    const handleSetshowPointPreview = (openStatus,needsReload) => {
        if(needsReload)
        advanceSearch()
        setshowPointPreview(openStatus)
    };

    const mapObject= async (object)=>{        
        setselectedPointRequest(object)
        if(object?.point)
        GetRegionOfPoints('GET_REGIONS_OF_POINT','point_geofence/'+object?.point?.id)
        setshowPointRequestMapDialog(true)
    }
    const handleSetshowPointRequestMapDialog = (openStatus,needsReload)=>{
        if(needsReload)
            advanceSearch()
        setshowPointRequestMapDialog(openStatus)
    }
    const advanceSearch = (type = "BROWSING")=>{  
        // console.log(type)
        // console.log(pageNumber)
        fetchData('POINTS_REQUESTS','point_requests',{
            page:type==="SEARCH" ? 1: pageNumber,            
            page_size:pageSize,            
            region:region.id,
            created_by:user.id,
            status:pointRequestStatus.map((status)=>status.id),
            creation_date_from: fromToDate[0]?helpers.formatDate(fromToDate[0]):helpers.formatDate(new Date()),
            creation_date_to:fromToDate[1]?helpers.formatDate(fromToDate[1]):helpers.formatDate(new Date()),
        });
    }
    useEffect( () => {                                      
            fetchData('REGIONS','regions_list')
            fetchData('USERS','users_list')         
            fetchData('POINTS_REQUESTS_STATUSES','point_request_statuses_list')
            advanceSearch()
    }, [pageSize,pageNumber,selectedPointRequestStatus])
    
    useEffect(() => {   
        // console.log(pointsRequests.data)
        if (pointsRequests && pointsRequests.data) {                                    
            setRow(pointsRequests.data);         
            setPointsTotal(pointsRequests.total) 
            setPointsNumberOfPages(pointsRequests.last_page)            
        }else{
            setRow([]);
            setPointsTotal(0)
            setPointsNumberOfPages(0)
        }        
    }, [pointsRequests])
    
    useEffect(() => {
        if (Object.keys(pointsRequestsUpdate).length > 0) {
            setcomparePoint(pointsRequestsUpdate)        
            setshowPointRequestForm(true)                    
        }        
    }, [pointsRequestsUpdate])
    
    const columns = [
        { field: 'id', headerName: t('POINTS_REQUESTS.ID') , flex: .08},
        { field: 'region', headerName: t('POINTS_REQUESTS.LOCATION') ,minWidth:150, flex: 1,
        renderCell: (rows) => (
            <div style={{ width: '100%' }} className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>                               
                  <span>{rows.row.region.district_id === 1 ? "كرخ  ":"رصافة  "}
                  {rows.row.region.name}</span>
            </div>
        )},
        { field: 'full_name', headerName: t('POINTS_REQUESTS.USER') , flex: 1,minWidth:100,
        renderCell: (rows) => (
            <div style={{ width: '100%' }} className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>                               
                  {rows.row.created_by.full_name}                  
            </div>
        )},
        { field: 'name', headerName:t('POINTS_REQUESTS.NAME') , flex: .75,minWidth:130},
        {
            field: "owner_name",
            flex: .8,
            headerName: t('POINTS_REQUESTS.OWNER_DETAILS'),
            minWidth:170,
            // width: 300,        
            renderCell: (rows) => (
              <div style={{ width: '100%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:directions=='rtl'?'end':'start' }} className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>                 
                  <span style={{textAlign:directions=='rtl'?'right':'left'}}>{rows.row.owner_name}</span>
                  <span style={{textAlign:directions=='rtl'?'right':'left'}}>{rows.row.phone}</span>
              </div>
            )
        },
        { field: 'created_at', headerName: t('POINTS_REQUESTS.REQUEST_DATA') ,minWidth:200, flex: 1,
            renderCell: (rows) => (
                <div className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>
                    {rows.row.created_at &&  moment(rows.row.created_at).isValid()? moment(rows.row.created_at).format("DD/MM/YYYY HH:mm:ss"):rows.row.created_at}
                </div>
            )
        },
        { field: 'point_request_status', headerName: t('POINTS_REQUESTS.STATUS') , flex:1,minWidth:170,
            renderCell: (rows) => (
                <div style={{borderRadius: 5,padding:5,color:"white", backgroundColor:rows.row.point_request_status?.color?rows.row.point_request_status?.color:"gray"}} className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>
                    {rows.row.point_request_status?.name}
                </div>
            )
        },
        { field: 'actions',flex: 1,minWidth:80,
            headerName: t('POINTS_REQUESTS.ACTIONS'),                
            renderCell: (param) => (
                <DropDownGrid>
     

                <MenuItem onClick={() => { mapObject(param.row)}}>
                    <ListItemIcon sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin:'0px 7px 0 0'
                    }}>
                        <LocationOnRoundedIcon style={{ fill: "#111827" }} />

                    </ListItemIcon>
                    {directions != 'rtl' ? "Map" : 'خريطة'}
                </MenuItem>
                <MenuItem onClick={() => { updateObject(param.row)}}>
                    <ListItemIcon sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin:'0px 7px 0 0'
                    }}>
                        <SettingsApplicationsRoundedIcon style={{ fill: "#111827" }}  />

                    </ListItemIcon>
                    {directions != 'rtl' ? "Action" : 'وظيفة'}
                </MenuItem>
                <MenuItem onClick={async () => {                                   
                        setRow(rows.filter((request)=> request.id !== param.row.id ))
                        if (await deleteObject('POINTS_REQUESTS','point_requests',param.row) ===200){                            
                            setRow(rows.filter((request)=> request.id !== param.row.id ))
                        }}}>
                    <ListItemIcon sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin:'0px 7px 0 0'
                    }}>
                        <DeleteRoundedIcon sx={{ color: red[500] }} />

                    </ListItemIcon>
                    {directions != 'rtl' ? "Delete" : 'حذف'}
                </MenuItem>
            </DropDownGrid>


            ),
        },          
    ]; 
    
    const handlePageChange = (newPage) => {        
        setpageNumber(newPage+1)  
    }

    const ExportFunction=async () => {
        const fetchedData = await   fetchData('POINTS_REQUESTS','point_requests',{
            page:pageNumber,
            page_size:pointsTotal,
            shop_name:shopName,
            owner_name:ownerName,
            district:district.id,
            region:region.id
        });
        exportToCSV(fetchedData.data,'points_requests')
    }
    const createFunction=()=>{
        // createObject()
    }
    const mapFunction=async () => {
            // const fetchedData = await fetchData('ALL_POINTS', 'points', {
            //     page_size: pointsTotal,
            //     shop_name: shopName,
            //     owner_name: ownerName,
            //     district: district.id,
            //     region: region.id,
            //     point_type_id: pointStatus.map((status) => status.id),
            // })
            // mapObjects(fetchedData)
        }
    const importFunction=()=>{
        // importObjects()
    }


    useEffect(()=>{
        if(getRegionOfPoint&&getRegionOfPoint?.region&&getRegionOfPoint?.region?.region_geofences?.length>0)
        {
             setPolygon({
              ...polygon,
              name:getRegionOfPoint?.region?.name,
              paths: getRegionOfPoint?.region?.region_geofences?.map(geofence => {
                  return{
                          lat: geofence.latitude,
                          lng: geofence.longitude
                      }
              })
            });
           
        }
           
      },[getRegionOfPoint])
    
    return (
        <>
            {showPointRequestForm&&selectedPointRequest?
                <PointRequestForm point={comparePoint} pointRequest={selectedPointRequest} showPointRequestForm={showPointRequestForm} setshowPointRequestForm={handleSetshowPointRequestForm} />
            :null}
            {showPointPreview?            
                <PointPreview pointRequest={selectedPointRequest} showPointPreview={showPointPreview} setshowPointPreview={handleSetshowPointPreview} />
            :null}
            {showPointRequestMapDialog&&polygon?.paths?.length>0 ?
            <PointsMapDialog title={t('GLOBAL.MAPS_VIEW_POINT_REQUEST')+" "+selectedPointRequest?.name} loading={loading} inVisitPage={true} polygon={polygon} points={[selectedPointRequest]} showPointsMapDialog={showPointRequestMapDialog} setshowPointsMapDialog={handleSetshowPointRequestMapDialog} />
            :null} 
            {
             !filterSearchBoxMode?
             <FilterBox 
                exportFunction={ExportFunction}
                hasExport={true} 
                hasCreate={false} 
                hasMaps={false} 
                hasImport={false}
                resultLength={pointsTotal}
                customeHeight={screenWidthRedux<=1100?'350px':'300px'}
                >           
                <Autocomplete
                    sx={{ml:1,width:screenWidthRedux<=1100?'98%':'47%',mt:1}}
                    fullWidth                            
                    options = {regions}                            
                    getOptionLabel = {(option) => option.name || ""}
                    id="disable-close-on-select"                        
                    value = {region}
                    onChange={(e,v,r) => {setRegion(v ? v:'')}}
                    renderInput={(params) => (
                    <TextField {...params} 
                    label={t('GLOBAL.SEARCH_REGION')} 

                    variant="standard" sx={{borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}} />
                    )}
                />
                <BasicDatePicker customeWidth={screenWidthRedux<=1100?'99%':'50%'}/>
                           
                <Autocomplete                                                 
                    sx={{ml:1,width:screenWidthRedux<=1100?'98%':'47%',mt:1}}
                    fullWidth                            
                    options = {users}                            
                    getOptionLabel = {(option) => option.full_name || ""}
                    id="disable-close-on-select"                        
                    value = {user}
                    onChange={(e,v,r) => {setUser(v ? v:'');}}
                    renderInput={(params) => (
                    <TextField {...params} 
                    label={t('GLOBAL.SEARCH_USERS')}
                    variant="standard" sx={{borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}} />
                    )}
                />

                <Autocomplete                                                 
                    sx={{ml:2.3,width:screenWidthRedux<=1100?'98%':'49%',mt:1}}
                    multiple
                    fullWidth
                    options = {pointsRequestsStatuses}
                    getOptionLabel = {(option) => option.name || ""}
                    id="disable-close-on-select"
                    value = {pointRequestStatus}
                    onChange={(e,v,r) => {setPointRequestStatus(v ? v:[]);}}
                    renderInput={(params) => (
                    <TextField {...params} 
                    label={t('GLOBAL.SEARCH_REQUEST_STATUS')}
                    variant="standard" sx={{borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}} />
                    )}
                />
                <Box sx={{display:'flex',justifyContent: 'end',alignItems: 'center',width:'100%'}}>
                    <Box display="flex" >
                            <Button
                                variant="contained"
                                spacing={2} 
                                sx={{ flexGrow: 1, margin: 1,backgroundColor:SearchButtonTheme?.search_button_color,color:SearchButtonTheme?.search_button_text_color,
                                    '&:hover':{
                                        backgroundColor:SearchButtonTheme?.search_button_color+'88',
                                    },
                                    height:FilterButtonHeight
                                }}
                                className="iconeFilterSearch"
                                onClick={() => advanceSearch("SEARCH")} >
                                {t('GLOBAL.SEARCH_SEARCH_BUTTON')}
                            </Button>
                            <Button variant="outlined"
                                spacing={2}
                                sx={{
                                        flexGrow: 1, margin: 1,
                                        // backgroundColor:SearchButtonTheme?.clear_button_color,
                                        color:SearchButtonTheme?.clear_button_text_color,
                                    '&:hover':{
                                        backgroundColor:`${SearchButtonTheme?.clear_button_color}   !important`,
                                    },
                                    height:FilterButtonHeight
                                }}
                                className="iconeFilterClear"
                                color="secondary"
                                onClick={clearPointsAdvanceSearchFields} >
                                {t('GLOBAL.SEARCH_CLEAR_BUTTON')}
                            </Button>
                    </Box>  
                </Box>
             </FilterBox>:null
            }

            <Box 
             sx={{
                display:'flex',
                justifyContent: 'start',
                alignItems: 'start',
                width:'100%',
             }}
            >
                <Box
                    sx={{
                    width:'100%',
                    }}
                >

                    <Grid
                    rows={rows}
                    columns={columns}
                    rowTotals={pointsTotal}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    pageNumber={pageNumber}
                    setPageNumber={setpageNumber}
                    loading={loading}
                    handlePageChange={handlePageChange}
                    NumberOfPage={pointsNumberOfPages}
                    directions={directions}
                    hasCreate={false}
                    createObject={null}
                    title_en=""
                    title_ar=""
                    gridHeight={{
                        height:rows?.length>5?pageSize==5?400:pageSize==10?650:950:400,
                    }}
                    />  
                </Box>

                {
                filterSearchBoxMode?
                <CustomeFilter
                    exportFunction={ExportFunction}
                    hasExport={true} 
                    hasCreate={false} 
                    hasMaps={false} 
                    hasImport={false}
                    resultLength={pointsTotal}
                //    customeHeight={screenWidth<=1100?'460px':"350px"}
                >
                    <Box
                     sx={{
                        width:'100%',
                        height:'400px',
                        minHeight:'400px',
                        maxHeight:'400px',
                        overflowY:'scroll',
                        overflowX:'hidden',
                        padding:'10px',
                        marginTop:'10px',
                        paddingTop:'20px',
                        // boxShadow:'0 0 7px -5px #000 inset'
                     }}
                    >
                        <Autocomplete
                            sx={{ml:1,marginTop:'10px !important',marginBottom:'15px !important',width:screenWidthRedux<=1100?'100%':'100%'}}
                            fullWidth                            
                            options = {regions}                            
                            getOptionLabel = {(option) => option.name || ""}
                            id="disable-close-on-select"                        
                            value = {region}
                            onChange={(e,v,r) => {setRegion(v ? v:'')}}
                            renderInput={(params) => (
                            <TextField {...params} 
                            placeholder={t('GLOBAL.SEARCH_REGION')} 

                            variant="standard" sx={{borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}} />
                            )}
                        />
                        <BasicDatePickerV2 customeWidth={screenWidthRedux<=1100?'100%':'100%'}/>
                            
                        <Autocomplete                                                 
                            sx={{ml:1,width:screenWidthRedux<=1100?'100%':'100%',marginTop:'10px !important',marginBottom:'15px !important'}}
                            fullWidth                            
                            options = {users}                            
                            getOptionLabel = {(option) => option.full_name || ""}
                            id="disable-close-on-select"                        
                            value = {user}
                            onChange={(e,v,r) => {setUser(v ? v:'');}}
                            renderInput={(params) => (
                            <TextField {...params} 
                            placeholder={t('GLOBAL.SEARCH_USERS')}
                            variant="standard" sx={{borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}} />
                            )}
                        />

                        <Autocomplete                                                 
                            sx={{ml:1,width:screenWidthRedux<=1100?'100%':'100%',marginTop:'10px !important',marginBottom:'15px !important'}}
                            multiple
                            fullWidth
                            options = {pointsRequestsStatuses}
                            getOptionLabel = {(option) => option.name || ""}
                            id="disable-close-on-select"
                            value = {pointRequestStatus}
                            onChange={(e,v,r) => {setPointRequestStatus(v ? v:[]);}}
                            renderInput={(params) => (
                            <TextField {...params} 
                            placeholder={t('GLOBAL.SEARCH_REQUEST_STATUS')}
                            variant="standard" sx={{borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}} />
                            )}
                        />
                    </Box>
                    <Box sx={{display:'flex',justifyContent: 'end',alignItems: 'center',width:'100%'}}>
                        <Box display="flex" >
                                <Button
                                    variant="contained"
                                    // spacing={2} 
                                    sx={{ 
                                        // flexGrow: 1,
                                        margin: 1,
                                        width:'80px !important',
                                        minWidth:'80px !important',
                                        maxWidth:'80px !important',
                                        backgroundColor:SearchButtonTheme?.search_button_color,color:SearchButtonTheme?.search_button_text_color,
                                        '&:hover':{
                                            backgroundColor:SearchButtonTheme?.search_button_color+'88',
                                        },
                                        height:FilterButtonHeight-10
                                    }}
                                    className="iconeFilterSearch"
                                    onClick={() => advanceSearch("SEARCH")} >
                                    {t('GLOBAL.SEARCH_SEARCH_BUTTON')}
                                </Button>
                                <Button variant="outlined"
                                    // spacing={2}
                                    sx={{
                                        width:'50% !important',
                                            // flexGrow: 1, 
                                            minWidth:'80px !important',
                                            maxWidth:'80px !important',
                                            margin: 1,
                                            // backgroundColor:SearchButtonTheme?.clear_button_color,
                                            color:SearchButtonTheme?.clear_button_text_color,
                                        '&:hover':{
                                            backgroundColor:`${SearchButtonTheme?.clear_button_color}   !important`,
                                        },
                                        height:FilterButtonHeight-10
                                    }}
                                    className="iconeFilterClear"
                                    color="secondary"
                                    onClick={clearPointsAdvanceSearchFields} >
                                    {t('GLOBAL.SEARCH_CLEAR_BUTTON')}
                                </Button>
                        </Box>
                    </Box>
                </CustomeFilter>:null
                }

            </Box>
            
        </>
    );
}

export default PointRequest;



// <Box display="flex" >
// <Button display = "flex"
//     startIcon={<FileDownloadIcon  sx={{ color: GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositeSecondaryColor }}/>}
//     variant="contained" 
//     sx={{height:59,marginRight: 1, marginBottom: accExpansion ? "0":"20px",backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor  }}
//     onClick = { async () => {
//                         const fetchedData = await   fetchData('POINTS_REQUESTS','point_requests',{
//                             page:pageNumber,
//                             page_size:pointsTotal,
//                             shop_name:shopName,
//                             owner_name:ownerName,
//                             district:district.id,
//                             region:region.id
//                         });
//                         exportToCSV(fetchedData.data,'points_requests')
//                     } 
//             }>
//     {t('GLOBAL.SEARCH_EXPORT_BUTTON')}
// </Button>           
// {/* <ExportCSV csvData={points.data} fileName={"test"} /> */}
// <Accordion display = "flex" sx={{ flexGrow: 4, backgroundColor:GridTheme?.primaryColor

// }} 
//     onChange={(event,expansion) => handleExpansion(expansion)}
// > 
//     <AccordionSummary 
//         expandIcon={<ExpandMoreIcon sx={{ color: GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositePrimaryColor }} />}
//         aria-controls="panel1a-content"
//         id="panel1a-header"
//         >
//         <Typography  sx={{color:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.oppositePrimaryColor}}>{t('GLOBAL.SEARCH_TITLE')}</Typography>
//     </AccordionSummary>
//     <AccordionDetails >
//         <Box component="span" display="flex" bgcolor = "white" flexDirection="column"  sx={{ borderRadius: 1 }} >
//             <Box sx={{m:1}} component="span" display="flex" bgcolor = "white" flexDirection="row" >
//                 <Box display="flex" sx={{ flexGrow: 2}}>                                    
//                     <Autocomplete
//                         sx={{ml:1}}
//                         fullWidth                            
//                         options = {regions}                            
//                         getOptionLabel = {(option) => option.name || ""}
//                         id="disable-close-on-select"                        
//                         value = {region}
//                         onChange={(e,v,r) => {setRegion(v ? v:'')}}
//                         renderInput={(params) => (
//                         <TextField {...params} 
//                         label={t('GLOBAL.SEARCH_REGION')} 

//                         variant="outlined" />
//                         )}
//                     />
//                 </Box>

                
//                 {/* <SearchField1 state={shopName} setState = {setShopName} fieldLabel='Shop Name'/>                    
//                 <SearchField1 state={ownerName} setState = {setOwnerName} fieldLabel='Owner Name'/> */}
//                 {/* <BasicDateRangePicker/> */}
//                 <BasicDatePicker/>

//                 <Box display="flex" sx={{ flexGrow: 1 }}>
//                     <Button 
//                         variant="contained" 
//                         spacing={2} sx={{ flexGrow: 1,margin: 1 ,backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor,
//                             '&:hover':{
//                                 backgroundColor:GridTheme?.secondaryColor+'88',
//                             }}}
//                         onClick = {() => advanceSearch("SEARCH")} >
//                         {t('GLOBAL.SEARCH_SEARCH_BUTTON')} 
//                     </Button>
//                     <Button variant="contained" 
//                         spacing={2} 
//                         sx={{ flexGrow: 1,margin: 1,backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor,
//                             '&:hover':{
//                                 backgroundColor:GridTheme?.secondaryColor+'88',
//                             } }} 
//                         color="secondary"
//                         onClick = {clearPointsAdvanceSearchFields} >
//                         {t('GLOBAL.SEARCH_CLEAR_BUTTON')} 

//                     </Button>                       
//                 </Box>                
//             </Box>
//             <Box sx={{m:1}} component="span" display="flex" bgcolor = "white" flexDirection="row" >                
//                 <Autocomplete                                                 
//                     sx={{ml:1}}
//                     fullWidth                            
//                     options = {users}                            
//                     getOptionLabel = {(option) => option.full_name || ""}
//                     id="disable-close-on-select"                        
//                     value = {user}
//                     onChange={(e,v,r) => {setUser(v ? v:'');}}
//                     renderInput={(params) => (
//                     <TextField {...params} 
//                     label={t('GLOBAL.SEARCH_USERS')}
//                     variant="outlined" />
//                     )}
//                 />

//                 <Autocomplete                                                 
//                     sx={{ml:1}}
//                     multiple
//                     fullWidth
//                     options = {pointsRequestsStatuses}
//                     getOptionLabel = {(option) => option.name || ""}
//                     id="disable-close-on-select"
//                     value = {pointRequestStatus}
//                     onChange={(e,v,r) => {setPointRequestStatus(v ? v:[]);}}
//                     renderInput={(params) => (
//                     <TextField {...params} 
//                     label={t('GLOBAL.SEARCH_REQUEST_STATUS')}
//                     variant="outlined" />
//                     )}
//                 />

//                 {/* <BasicDateRangePicker/> */}
//             </Box>
//         </Box>
//     </AccordionDetails>
// </Accordion>
// </Box>