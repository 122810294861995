import React, { useState } from "react";
import loading from "../../assets/js/loading.json";
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import axios from "axios";
import Cookies from "universal-cookie";
import Lottie from "lottie-react-web";
import Host from "../../assets/js/Host";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import Typography from "@mui/material/Typography";
import MandoobeenLogo from "../../assets/img/mandoob_img_big_v2.png";
import {
  setUserName,setUserPhone,setUserData,setUserRoleName,setUserToken,setFullName,setImage
} from "../reduxStore/UserReducer";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import { Navigate, useNavigate } from 'react-router-dom';

import * as FiIcone from "react-icons/fi";
import * as ImIcone from "react-icons/im";
import * as CgIcone from "react-icons/cg";
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import './Login.css'
import LoadingPage from './Share/LoadingPage'
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Iraq from '../../assets/img/iraq.png'
import UnitedState from '../../assets/img/united-states.png'
import unitedKingdom from '../../assets/img/united-kingdom.png'
import mLogo from '../../assets/img/M_logo.png'
import {setSideMenuMode,sideMenuMode,setDirections,setCompanyImage} from '../reduxStore/SettingsReducer'
import {useTranslation} from "react-i18next";
import helpers from '../../assets/js/helper'

// import {I18nextProvider} from "react-i18next";
// import i18next from "i18next";
import { useMemo } from "react";
import { useEffect } from "react";

const cookies = new Cookies();
const language = cookies.get("language") ? cookies.get("language") : "ar";

const Login = () => {
  const dispatch = useDispatch();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [localLanguage,setLocalLanguage]=useState(localStorage.getItem("localCode")?localStorage.getItem("localCode") : "ar");
  // const [directions,setDirections]=useState(localStorage.getItem("localCode")?localStorage.getItem("localCode"):'rtl')
  const [state, setState] = useState({
    data: [],
    username: "",
    password: "",
    errors: false,

    spin: false,
  });
  // const [t, i18n] = useTranslation('common');
  
  let navigate = useNavigate(); 
  const [showPassword,setShowPassword]=useState(false)
  const [loading,setLoading]=useState(false)
  const [userNameError,setUserNameError]=useState('')
  const [passwordError,setPasswordError]=useState('')
  const directions =useSelector(state=>state.settingsData.directions)
  const Translate_en=useSelector(state=>state.translateData.translation_en)
  const Translate_ar=useSelector(state=>state.translateData.translation_ar)

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  useEffect(()=>{
     if(localStorage.getItem("localCode")){
      setLocalLanguage(localStorage.getItem("localCode"))
     }else{
      localStorage.setItem("localCode",'ar')
     }
     if(localStorage.getItem("localDirections")){
      dispatch(setDirections(localStorage.getItem("localDirections")))
     }else{
      localStorage.setItem("localDirections",'rtl')
     }
  },[])

  const handleClick = (event) => {
    if(!anchorEl)
      setAnchorEl(event.currentTarget);
    else
      setAnchorEl(null);
      
  };
  const handleClose = (direction) => {
    
    dispatch(setDirections(direction))
    if(direction=='ltr')
    setLocalLanguage('en')
    else if(direction=='rtl')
    setLocalLanguage('ar')

    localStorage.setItem("localCode",direction=='rtl'?'ar':'en')
    localStorage.setItem("localDirections",direction)


    setAnchorEl(null);
  };
  const handleCloseTwo=()=>{
    setAnchorEl(null);
      
  }

  React.useEffect(() => {
    console.log(directions)
    window.addEventListener("resize", () => {
      setScreenWidth(window.innerWidth);
    });

    document.addEventListener("keypress", (e) => {
      onEnter(e)
    });
  }, []);

  const onEnter = (event) => {
    if (event.keyCode === 13) {
      document.getElementById("sign_in_div_2").click();
    }
  };
  


  const LoginUser = async (e) => {
    setUserNameError('')
    setPasswordError('')
    if (state.username.length < 3) {
        setUserNameError(t('LOGIN_PAGE.SIGNIN_USERNAME_ERROR_SHORT'));
      return toast.error(`${t('LOGIN_PAGE.SIGNIN_USERNAME_ERROR_SHORT')}`);
    } else if (state.password.length < 3) {
        setPasswordError(t('LOGIN_PAGE.SIGNIN_PASSWORD_ERROR_SHORT'));
      return toast.error(`${t('LOGIN_PAGE.SIGNIN_PASSWORD_ERROR_SHORT')}`);
    }

     setLoading(true)
    try {
      const responseResult = await axios({
        url: Host + "login",
        method: "post",
        headers: {
          // 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
          Accept: "application/json",
          "X-localization": language,
        },
        data: {
          username: state.username.includes("+964") ? null : state.username,
          phone: state.username.includes("+964") ? state.username : null,
          password: state.password,
        },
      });

      if (responseResult.data.success === true) {
        localStorage.setItem(
          "defaultSettings",
          JSON.stringify(responseResult.data.data)
        );
        var responseJson = responseResult?.data;

        let token = responseJson?.data?.token;
        let status = responseJson?.status;

        cookies.set("status", status);
        cookies.set(
          "logged_in_company_id",
          responseJson?.data?.user?.logged_in_company_id
        );
        cookies.set("username", responseJson?.data?.user?.username);
        cookies.set("full_name", responseJson?.data?.user?.full_name);
        cookies.set("user_id", responseJson?.data?.user?.id);
        cookies.set("image", responseJson?.data?.user?.image?.image_url);
        cookies.set('companyImage', responseJson?.data?.user?.company?.image?.image_url)
        
        cookies.set(
          "roles",
          responseJson?.data?.user?.roles?.length > 0
            ? responseJson?.data?.user?.roles?.map((role) => role.id)
            : []
        );
        cookies.set("phone", JSON.stringify(responseJson?.data?.user?.phone));
        console.log("image", responseJson?.data?.user?.image);
        
        dispatch(setUserName(responseJson?.data?.user?.username))
        dispatch(setFullName(responseJson?.data?.user?.full_name))
        dispatch(setUserPhone(responseJson?.data?.user?.phone))
        dispatch(setUserData(responseJson?.data?.user))
        dispatch(setUserRoleName(responseJson?.data?.user?.username))
        dispatch(setImage(responseJson?.data?.user?.image?.image_url))
        

        cookies.set("token", token);
        dispatch(setUserToken(token))
        setState({
          ...state,
          spin: false,
        });
        setLoading(false)
        // window.location.href = "/Dashboard";
        // if(cookies.get('token'))
        //   navigate('/Users')
      } else if (responseResult?.data?.status === false) {
        setLoading(false)
      }else{
        setLoading(false)
      }
      
    } catch (error) {
      const responseResult = error?.response;
      if (responseResult && responseResult?.status === 400) {
        if (typeof responseResult?.data?.data === "object") {
          const errorMessagesKeys = Object.keys(responseResult?.data?.data);
          errorMessagesKeys?.map((key) => {
            responseResult?.data?.data[key]?.map((message) => {
              toast?.error(message);
            });
          });
        } else {
          toast.error(responseResult.data.data);
        }
      } else if (responseResult && responseResult.status === 401) {
        toast.error(responseResult.data.data);
        cookies.remove("token");
      } else {
        toast.error("network error");
      }
      setState({
        ...state,
        spin: false,
      });
      setLoading(false)

    }
  };
const Translate=useMemo(()=>{
     if(localLanguage=='en'){
        return Translate_en
     }else{
        return Translate_ar
     }
},[localLanguage])

const t=(route)=>{
  const arr_route=route.split('.');
  const value=Translate[arr_route[0]][arr_route[1]];
  return value;
}

  if (cookies.get("token") !== undefined) navigate('/Dashboard');
  return (
      <LoginPage className="LoginPage">
        <CustomeLoginWrapper screenWidth={screenWidth}>
          <CustomeLeft screenWidth={screenWidth}>
            <img src={MandoobeenLogo} alt="" className="imgLogo" />
            <Box
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                width: "80%",
                top: "38px",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontSize: "28px",
                  fontWeight: "bold",
                  position: "relative",
                  top: 30,
                  display:'flex',
                  justifyContent: "start",
                }}
              >
              {t('LOGIN_PAGE.SINGIN_TITLE')}
              </Typography>

              <TextField
                sx={{
                  mt: 8,
                  width: "100%",
                  "& .muiltr-9ddj71-MuiInputBase-root-MuiOutlinedInput-root":{
                    // left:'30px'
                    borderRadius:'10px',
                  },
                  "& .muiltr-1sumxir-MuiFormLabel-root-MuiInputLabel-root":{
                    left:'15px'
                  },
                  '& .muiltr-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                    padding:'0 20px'
                  }
                }}
                error={userNameError?true:false}
                id="outlined-error-helper-text"
                label={t('LOGIN_PAGE.SIGNIN_USERNAME')}
                defaultValue=""
                value={state?.username}
                onChange={(e) => {
                  setState({
                    ...state,
                    username: e.target.value,
                  });
                  if(e.target.value?.length>=3 && userNameError){
                        setUserNameError('')
                  }else if(e.target.value?.length==0){
                      setUserNameError(t('LOGIN_PAGE.SIGNIN_USERNAME_ERROR_BLANCK'))
                  }else if(e.target.value?.length<3){
                      setUserNameError(t('LOGIN_PAGE.SIGNIN_USERNAME_ERROR_SHORT'))
                      
                  }
                }}
                helperText={userNameError}
                required
              />
              <Box sx={{position:'relative'}}>
                  <TextField
                  sx={{
                      mt: 2,
                      width: "100%",
                      "& .muiltr-9ddj71-MuiInputBase-root-MuiOutlinedInput-root":{
                        // left:'30px'
                        borderRadius:'10px',
                      },
                      "& .muiltr-1sumxir-MuiFormLabel-root-MuiInputLabel-root":{
                        left:'15px'
                      },
                      '& .muiltr-1d3z3hw-MuiOutlinedInput-notchedOutline': {
                        padding:'0 20px'
                      }
                  }}
                  error={passwordError?true:false}
                  
                  id="outlined-error-helper-text"
                  label={t('LOGIN_PAGE.SIGNIN_PASSWORD')}
                  type={showPassword ? 'text' : 'password'}
                  defaultValue=""
                  value={state?.password}
                  onChange={(e) => {
                      setState({
                      ...state,
                      password: e.target.value,
                      });

                      if(e.target.value?.length>=3 && passwordError){
                          setPasswordError('')
                      }else if(e.target.value?.length==0){
                          setPasswordError(t('LOGIN_PAGE.SIGNIN_PASSWORD_ERROR_BLANCK'))
                      }else if(e.target.value?.length<3){
                          setPasswordError(t('LOGIN_PAGE.SIGNIN_PASSWORD_ERROR_SHORT'))

                      }
                  }}
                  helperText={passwordError}
                  required
                  />
                  {
                      !showPassword?
                      <VisibilityOutlinedIcon 
                          sx={{
                            position:'absolute',
                            top:'33px',
                            color:'#958a8a',
                            right:'5%',
                            cursor:'pointer',
                          }}
                          onClick={()=>setShowPassword(true)}
                      />
                      :
                      <VisibilityOffOutlinedIcon
                          sx={{
                              position:'absolute',
                              top:'33px',
                              right:'5%',
                              color:'#958a8a',
                            cursor:'pointer',

                          }}
                          onClick={()=>setShowPassword(false)}
                      />
                  }
              </Box>
            </Box>
            <Box
              sx={{
                position: "relative",
                width: "100%",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                mt: 8,
              }}
            >
              <Button
                id="sign_in_div_2"
                variant="contained"
                disabled={!state?.username || !state?.password}
                sx={{
                  backgroundColor: "rgb(250,169,25)",
                  color: "white",
                  fontSize: 18,
                  outline: "none !important",
                  border: "none !important",
                  fontWeight: "500",
                  borderRadius: '5px',
                  width: "80%",
                  padding: "9px 2px",
                  textTransform: "uppercase",
                  "&:hover": {
                    backgroundColor: "#e19308",
                  },

                  "& span": {
                    textTransform: "lowercase",
                  },
                }}
                onClick={LoginUser}
              >
                {t('LOGIN_PAGE.SIGNIN_BUTTON')}
              </Button>
            </Box>
            {/* <Box
              sx={{
                mt: 4,
                width: "80%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <hr style={{ width: "35%" }} />
                <Typography
                  sx={{
                    color: "#958a8a",
                    fontWeight: "500",
                    width: screenWidth <= 991 ? "26" : "30%",
                    fontSize: screenWidth <= 991 ? 12 : 16,
                    textAlign:'center',
                  }}
                >
                  {t('LOGIN_PAGE.SIGNIN_OR_CONTINU_WITH')}
                </Typography>
                <hr style={{ width: "35%" }} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mt: 2,
                }}
              >
                <Box
                  sx={{
                    width: "100px",
                    border: "1px solid black",
                    borderRadius: 10,
                    display: "flex",
                    justifyContent: "center",
                    padding: "12px 0px",
                    cursor: "pointer",
                    margin: "5px 10px",
                  }}
                >
                  <FiIcone.FiFacebook color="#958a8a" />
                </Box>
                <Box
                  sx={{
                    width: "100px",
                    border: "1px solid black",
                    borderRadius: 10,
                    display: "flex",
                    justifyContent: "center",
                    padding: "12px 0px",
                    margin: "5px 10px",
                    cursor: "pointer",
                  }}
                >
                  <ImIcone.ImGoogle color="#958a8a" />
                </Box>
                <Box
                  sx={{
                    width: "100px",
                    border: "1px solid black",
                    borderRadius: 10,
                    display: "flex",
                    justifyContent: "center",
                    padding: "12px 0px",
                    margin: "5px 10px",
                    cursor: "pointer",
                  }}
                >
                  <CgIcone.CgTwitter color="#958a8a" />
                </Box>
              </Box>
            </Box> */}
          </CustomeLeft>
          <CustomeRight screenWidth={screenWidth}>
            <svg
              className="absolute inset-0 pointer-events-none"
              viewBox="0 0 960 540"
              width="100%"
              height="100%"
              preserveAspectRatio="xMidYMax slice"
              xmlns="http://www.w3.org/2000/svg"
              style={{ color: "#0367a4", position: "absolute" }}
              fill="#0367a4"
            >
              <g
                className="opacity-20 MuiBox-root muiltr-1wnrr1p"
                fill="none"
                stroke="currentColor"
                stroke-width="100"
              >
                <circle r="234" cx="196" cy="23"></circle>
                <circle r="234" cx="790" cy="491"></circle>
              </g>
            </svg>
            <svg
              style={{
                color: "#0367a4",
                position: "absolute",
                top: "-44px",
                left: "400px",
                right: -100,
              }}
              className="absolute -top-64 -right-64 opacity-20 MuiBox-root muiltr-x3mbcd"
              viewBox="0 0 220 192"
              fill="#0367a4"
            >
              <defs>
                <pattern
                  id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="220"
                height="192"
                fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
              ></rect>
            </svg>
            <Box
              sx={{
                zIndex: 1000,
                width: "100%",
                height: "100%",
                margin: "230px 0 0 60px",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  color: "#fff",
                  fontSize:
                    screenWidth <= 1600 ? (screenWidth <= 1400 ? 30 : 40) : 50,
                  fontWeight: "bold",
                  letterSpacing: 2,
                  display:'flex',
                  justifyContent: 'start',
                  textAlign: 'left',
                }}
              >
                {t('LOGIN_PAGE.WELLCOME_MESSAGE')} <br />
                {t('LOGIN_PAGE.SUBWELLCOME_MESSAGE')}
              </Typography>

              <Typography
                variant="span"
                sx={{
                  color: "rgb(210,210,210)",
                  fontSize:
                  screenWidth <= 1600 ? (screenWidth <= 1400 ? 12 : 15) : 17,
                  width:'95%',
                  position: "relative",
                  top: 30,
                  display:'flex',
                  justifyContent: 'start',
                  textAlign: 'left',
                  wordBreak:" keep-all;",
                  wordWrap:" break-word",
                  whiteSpace:'break-word',
                }}
              >
               {t('LOGIN_PAGE.INFO_MESSAGE_1')}
              {/* {directions!='rtl'?t('LOGIN_PAGE.INFO_MESSAGE_1')?.slice(0,58):t('LOGIN_PAGE.INFO_MESSAGE_1')?.slice(0,64)}<br/>
              {directions!='rtl'?t('LOGIN_PAGE.INFO_MESSAGE_1')?.slice(58,122):t('LOGIN_PAGE.INFO_MESSAGE_1')?.slice(64,129)}<br/>
              {directions!='rtl'?t('LOGIN_PAGE.INFO_MESSAGE_1')?.slice(122,195):t('LOGIN_PAGE.INFO_MESSAGE_1')?.slice(129,200)} */}

              </Typography>
              {/* <Box
                sx={{
                  display: "flex",
                  alignItems: screenWidth <= 1350 ? "start" : "center",
                  position: "relative",
                  top: "80px",
                  flexDirection: screenWidth <= 1350 ? "column" : "row",
                }}
              >
                <CustomeImageList screenWidth={screenWidth}>
                  <img
                    className="imgItme img1"
                    src="https://react-material.fusetheme.com/assets/images/avatars/male-09.jpg"
                    alt=""
                  />
                  <img
                    className="imgItme img2"
                    src="https://react-material.fusetheme.com/assets/images/avatars/female-11.jpg"
                    alt=""
                  />
                  <img
                    className="imgItme img3"
                    src="https://react-material.fusetheme.com/assets/images/avatars/female-18.jpg"
                    alt=""
                  />
                  <img
                    className="imgItme img4"
                    src="https://react-material.fusetheme.com/assets/images/avatars/male-16.jpg"
                    alt=""
                  />
                </CustomeImageList>
                <Typography
                  variant="p"
                  sx={{
                    color: "rgb(189 189 189)",
                    fontSize:
                      screenWidth <= 1600 ? (screenWidth <= 1400 ? 11 : 14) : 16,
                    position: "relative",
                    left: 5,
                    marginTop: 1,
                  }}
                >
                  {t('LOGIN_PAGE.FOOTER_MESSAGE')}
                </Typography>
              </Box> */}
            </Box>

            <img
             src={mLogo} 
             style={{
              position:'absolute',
              left:'35%',
              bottom:'15%',
              width:'150px',
              height:'150px',
              backgroundSize:'contained',
              backgroundPosition:'center center',
              objectFit: 'scale-down',

             }}
            />

          </CustomeRight>
        </CustomeLoginWrapper>

        <div className="area" >
              <ul className="circles">
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
              </ul>
        </div >
        {
          loading?
          <LoadingPage/>
          :null
        }
        
        <CustomeLanguageDrowpDown
            aria-controls={open ? 'demo-customized-menu22' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            screenWidth={screenWidth}

        >
          {
        directions!='rtl'?
        <Box sx={{display:'flex',alignItems:'center'}}>
            <img src={unitedKingdom} alt="" style={{width:'26px',height:'28px',marginLeft:directions=='rtl'?"0":'5px',marginRight:directions=='rtl'?"5px":'0'}} />
            <Typography variant="h3" sx={{ color: "black",fontSize:'15px',marginLeft:'5px' }}>
                EN
            </Typography>
            <Box
            sx={{
              position:'absolute',
              top:'10px',
              right:'10px',
              color:'black',
              display:'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor:'rgba(0,0,0,0.3)',
              width:'18.5px',
              height:'18.5px',
              borderRadius: '50%',
              cursor: 'pointer',
              padding:'2px'
          }}
            >
                  <KeyboardArrowDownIcon
                      sx={{
                          color:'white'
                      }}
                  />
            </Box>
        </Box>:
        <Box sx={{display:'flex',alignItems:'center',padding:'1px 0px 1px 2px'}}>
            <img src={Iraq} alt="" style={{width:'26px',height:'28px',marginLeft:directions=='rtl'?"0":'5px',marginRight:directions=='rtl'?"5px":'0'}} />
            <Typography variant="h3" sx={{ color: "black",fontSize:'15px',marginLeft:'5px' }}>
                AR
            </Typography>
            <Box
            sx={{
              position:'absolute',
              top:'11px !important',
              right:'10px',
              color:'black',
              display:'flex',
              justifyContent: 'center', 
              alignItems: 'center',
              backgroundColor:'rgba(0,0,0,0.3)',
              width:'20px',
              height:'20px',
              borderRadius: '50%',
              cursor: 'pointer',
          }}
            >
                  <KeyboardArrowDownIcon
                      sx={{
                          color:'white'
                      }}
                  />
            </Box>
        </Box>
        }
        </CustomeLanguageDrowpDown>

          <StyledMenu
          id="demo-customized-menu22"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          // ToolBarTheme={ToolBarTheme}
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseTwo}
        >
      
          <MenuItem onClick={()=>handleClose('ltr')} disableRipple className={`itmlist ${directions!=='rtl'?'':'languageNormal arabicDirection'}`}>
            <Box sx={{display:'flex',alignItems:'center',position:'relative'}}>
              <img src={unitedKingdom} alt="" style={{width:'30px',height:'30px',marginLeft:'5px'}} />
              <Typography variant="h3" sx={{ color: "black",fontSize:'15px',marginLeft:'5px' }}>
                  English
              </Typography>
              </Box>
          </MenuItem>

          <MenuItem onClick={()=>handleClose('rtl')} disableRipple className={`itmlist ${directions!=='rtl'?'':'languageNormal arabicDirection'}`}>
            <Box sx={{display:'flex',alignItems:'center',position:'relative'}}>
              <img src={Iraq} alt="" style={{width:'30px',height:'30px',marginLeft:'5px'}} />
              <Typography variant="h3" sx={{ color: "black",fontSize:'15px',marginLeft:'5px' }}>
                  العربية
              </Typography>
              </Box>
          </MenuItem>
        
          </StyledMenu>
      </LoginPage>
  );
};
const LoginPage = styled(Box)(({ theme }) => ({
  backgroundColor: "#e1e3e5",
  width: "100vw",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
}));
const CustomeLoginWrapper = styled(Box)(({ theme, screenWidth }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#fff",
  width: screenWidth <= 600 ? "100%" : "70%",
  height: screenWidth <= 600 ? "100%" : "80%",
  borderRadius: screenWidth<=600?0:20,
  boxShadow: "-1.5px 2px 4px -3px rgba(0,0,0,0.75)",
  overflow: "hidden",
  zIndex:1000,
  paddingTop: screenWidth <= 600 ? "30px":"0"

}));

const CustomeLeft = styled(Box)(({ theme, screenWidth }) => ({
  display: "flex",
  justifyContent: "start",
  flexDirection: "column",
  alignItems: "start",
  padding: "30px 0 0 50px",
  width: screenWidth <= 1000 ? "100%" : "50%",
  height: "100%",
  "& .imgLogo": {
    width: "300px",
    height: "40px",
    backgroundSize: "cover",
  },
}));

const CustomeRight = styled(Box)(({ theme, screenWidth }) => ({
  display: screenWidth <= 1000 ? "none" : "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "50%",
  height: "100%",
  backgroundColor: "#006CAD",
  position: "relative",
  overflow: "hidden",
}));

const CustomeImageList = styled(Box)(({ theme, screenWidth }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  overflow: "hidden",
  "& .imgItme": {
    width: screenWidth < 1600 ? "46px" : "50px",
    height: screenWidth < 1600 ? "46px" : "50px",
    borderRadius: 50,
    position: "relative",
    outline: "3px solid #006CAD",
  },
  "& .img1": {
    //   left:10,
    zIndex: 60,
  },
  "& .img2": {
    left: "-8px",
    zIndex: 50,
  },
  "& .img3": {
    left: "-15px",
    zIndex: 40,
  },
  "& .img4": {
    zIndex: 30,
    left: "-23px",
  },
}));

const CustomeLanguageDrowpDown=styled(Box)(({theme,screenWidth})=>({
      position: "absolute",
      top:7,
      left:15,
      width:'100px',
      height:'40px',
      backgroundColor:screenWidth<=600?'rgba(245,247,245,0.7)':'#FFFFFF',
      borderRadius:7,  
      display:'flex',
      // justifyContent:'center',
      alignItems: 'center',
      zIndex:2000,
}))

const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      backgroundColor: '#fff',
      color:'#000',
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
      '& .itmlist:hover': {
        backgroundColor:'rgba(255,255,255,0.5)',
      },
    },
  }));
export default Login;

// <div id='main' >
// <div id="login_main">
//     <img src={require("../../assets/img/icon_no_bg.png")} alt="img" style={{ height: "250px" }} />
//     {/* <div
//             style={{
//                 fontSize: 22,
//                 lineHeight: "40px",
//                 color: "#707070",
//                 fontWeight: "bold"
//             }}
//         >
//             Ticket System
//             </div> */}
//     <div
//         style={{
//             fontSize: 20,
//             lineHeight: "40px",
//             color: "#707070",
//             marginBottom: 30
//         }}
//     >
//     </div>
//     <div className="Sign_container" style={{ position: 'relative' }} >
//         <div className="up_field">
//             <input
//                 id="field1"
//                 placeholder="Username"
//                 value={state.username}
//                 onKeyDown={(e) => onEnter(e)}
//                 onChange={e => {
//                     setState({
//                         ...state,
//                          username: e.target.value
//                     });
//                 }}
//             />
//             <input
//                 id="field12"
//                 name="text-input-name"
//                 type="password"
//                 placeholder="Password "
//                 required
//                 value={state.password}
//                 onKeyDown={(e) => onEnter(e)}
//                 onChange={e => {
//                     setState({
//                         ...state,
//                         password: e.target.value
//                     });
//                 }}
//             />
//         </div>
//         <div className="down_field">

//             <div
//                 id="sign_in_div"
//                 onClick={async (e) => {
//                     if (state.username.length < 3) {
//                         return (
//                             toast.error(`Username is short`)
//                         );
//                     }
//                     else if (state.password.length < 3) {

//                         return (
//                             toast.error(`Password must be more than 3 char`)
//                         );
//                     }
//                     setState({
//                         ...state,
//                         spin: true
//                     });

//                     try {
//                         const responseResult = await axios({
//                             url: Host + "login",
//                             method: "post",
//                             headers: {
//                                 // 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
//                                 Accept: "application/json",
//                                 'X-localization': language,

//                             },
//                             data: {
//                                 username: state.username.includes("+964") ? null : state.username,
//                                 phone: state.username.includes("+964") ? state.username : null,
//                                 password: state.password,
//                             }

//                         })

//                         if (responseResult.data.success === true) {

//                             localStorage.setItem("defaultSettings", JSON.stringify(responseResult.data.data));
//                             var responseJson = responseResult?.data

//                             let token = responseJson?.data?.token
//                             let status = responseJson?.status

//                             cookies.set("status", status)
//                             cookies.set("logged_in_company_id", responseJson?.data?.user?.logged_in_company_id)
//                             cookies.set("username", responseJson?.data?.user?.username)
//                             cookies.set("full_name", responseJson?.data?.user?.full_name)
//                             cookies.set("user_id", responseJson?.data?.user?.id)
//                             cookies.set("roles", responseJson?.data?.user?.roles?.length>0? responseJson?.data?.user?.roles?.map((role)=>role.id):[]);
//                             cookies.set("phone", JSON.stringify(responseJson?.data?.user?.phone));
//                             console.log('roles',responseJson?.data?.user?.roles)

//                             // dispatch(setUserName(responseJson?.data?.user?.full_name))
//                             // console.log('name from login',responseJson?.data?.user?.full_name);
//                             // dispatch(setUserPhone(responseJson?.data?.user?.phone))
//                             // dispatch(setUserData(responseJson?.data?.user))
//                             // dispatch(setUserRoleName(responseJson?.data?.user?.username))

//                             cookies.set("token", token, {
//                                 path: "/",
//                                 expires: new Date(Date.now() + 60480000)
//                             });
//                             setState({
//                                 ...state,
//                                 spin: false
//                             })
//                             window.location.href = "/dashboard";
//                         } else if (responseResult?.data?.status === false) {

//                         }
//                     } catch (error) {
//                         const responseResult = error?.response
//                         if (responseResult && responseResult?.status === 400) {
//                             if (typeof responseResult?.data?.data === "object") {
//                                 const errorMessagesKeys = Object.keys(responseResult?.data?.data);
//                                 errorMessagesKeys?.map((key) => {

//                                     responseResult?.data?.data[key]?.map((message) => {
//                                         toast?.error(message)
//                                     })
//                                 })
//                             }
//                             else {
//                                 toast.error(responseResult.data.data)
//                             }

//                         }
//                         else if (responseResult && responseResult.status === 401) {
//                             toast.error(responseResult.data.data)
//                             cookies.remove("token");

//                         }
//                         else {
//                             toast.error("network error")
//                         }
//                         setState({
//                             ...state,
//                             spin: false
//                         })
//                     }
//                 }} >
//                 {state.spin === false ? (
//                     <div> Login  </div>
//                 ) : (
//                     <div>
//                         <div>loading ...</div>
//                         <div style={{ width: "100%", position: "absolute", top: '102px', right: '-7px' }}>
//                             <Lottie
//                                 options={{
//                                     animationData: loading
//                                 }}
//                                 width={300}
//                                 height={150}
//                                 position="absolute"
//                             />
//                         </div></div>
//                 )}
//             </div>

//         </div>
//     </div>
// </div>
// <div>
//     <div style={{ textAlign: 'center', color: 'rgb(112, 112, 112)', marginTop: 5, padding: 5, fontSize: 18 }} >© 2022 Jahizni . All rights reserved </div>
// </div>
// </div>
