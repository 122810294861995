import React, { useState, useContext, useReducer, useEffect } from 'react'
import Cookies from "universal-cookie";
import Host from '../src/assets/js/Host'
import { toast } from 'react-toastify';
import axios from "axios";
import reducer from './reducer'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import moment from 'moment';
import {useSelector,useDispatch} from 'react-redux'
const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"

const AppContext = React.createContext()

let now = new Date();

const initialState = {
    loading: false,
    districts: [],
    regions: [],
    points: [],
    allPoints: [],
    allVisits: [],
    pointsRequests: [],
    pointsRequestsUpdate: [],
    users: [],
    visits: [],
    visitFollowups:[],
    followupStatusesList:[],
    visitTypeslist: [],
    pointsRequestsStatuses: [],
    district: [],
    region: [],
    user: [],
    data: [],
    pointRequestStatus: [],
    getRegionOfPoint:[],
    selectedUsers: [],
    pointsStatuses: [],
    pointStatus: [],
    visitType: [],
    visitsReport: [],
    visitFollowupsReport:[],
    journies: [],
    userDailyPlans: [],
    groups:[],
    notification_types:[],
    notifications:[],
    shopName: '',
    ownerName: '',
    minDistance: '',
    maxDistance: '',
    attendance_type_shifts:[],
    journey_types:[],
    selectedPointRequestStatus: '',
    attendanceCalculations: [],
    followupStatus: '',
    visitFollowupNote: '',
    Shifts:[],
    attendance_typs:[],
    PreviousFollowUp:[],
    Departments:[],
    Companys:[],
    fromToDate: [moment(now).format('YYYY-MM-DD'), moment(now).format('YYYY-MM-DD')],
}

const AppProvider = ({ children }) => {
    // const token = cookies.get("token")
    const [state, dispatch] = useReducer(reducer, initialState)
    const token =useSelector(state=> state.userData.token);
    // console.log('token=>',token)
    const toastId = React.useRef(null);

    const setShopName = (value) => { dispatch({ type: 'SHOP_NAME', payload: value }) }
    const setLoading = (value) => { dispatch({ type: 'LOADING', payload: value }) }
    const setOwnerName = (value) => { dispatch({ type: 'OWNER_NAME', payload: value }) }
    const setMinDistance = (value) => { dispatch({ type: 'MIN_DISTANCE', payload: value }) }
    const setMaxDistance = (value) => { dispatch({ type: 'MAX_DISTANCE', payload: value }) }
    const clearPointsAdvanceSearchFields = () => {
        dispatch({ type: 'CLEAR_ADVANCE_FILTER' })
    }
    const setRegion = (value) => { dispatch({ type: 'REGION', payload: value }) }

    const setDistrict = (value) => {
        dispatch({ type: 'DISTRICT', payload: value })
    }

    const setPointsRequestsUpdate = (value) => { dispatch({ type: 'POINT_UPDATE', payload: value }) }

    const setRegions = (value) => { dispatch({ type: 'REGIONS', payload: value }) }

    const setData = (value) => { dispatch({ type: 'DATA', payload: value }) }

    const setVisits = (value) => { dispatch({ type: 'VISITS', payload: value }) }
    const setVisitFollowups = (value) => { dispatch({ type: 'VISIT_FOLLOWUPS', payload: value }) }
    const setFollowupStatusesList = (value) => { dispatch({ type: 'FOLLOWUP_STATUSES_LIST', payload: value }) }
    const setVisitTypeslist = (value) => { dispatch({ type: 'vISIT_TYPES_LIST', payload: value }) }

    const setDistricts = (value) => {
        dispatch({ type: 'DISTRICTS', payload: value })
    }
    const setUser = (value) => { dispatch({ type: 'USER', payload: value }) }

    const setPoints = (value) => { dispatch({ type: 'POINTS', payload: value }) }

    const setPointRequestStatus = (value) => { dispatch({ type: 'POINT_REQUEST_STATUSE', payload: value }) }
    const setSelectedUsers = (value) => { dispatch({ type: 'SELECTED_USERS', payload: value }) }

    const setPointStatus = (value) => { dispatch({ type: 'POINT_STATUSE', payload: value }) }

    const setVisitType = (value) => { dispatch({ type: 'VISIT_TYPE', payload: value }) }

    const setVisitsReport = (value) => { dispatch({ type: 'VISITS_REPORT', payload: value }) }
    const setVisitFollowupsReport = (value) => { dispatch({ type: 'VISIT_FOLLOWUPS_REPORT', payload: value }) }


    const setJournies = (value) => { dispatch({ type: 'JOURNIES', payload: value }) }

    const setFromToDate = (value) => { dispatch({ type: 'FROM_TO_DATE', payload: value }) }

    const setSelectedPointRequestStatus = (value) => { dispatch({ type: 'SELECTED_POINT_REQUEST_STATUS', payload: value }) }

    const setUserDailyPlans = (value) => { dispatch({ type: 'USER_DAILY_PLAN', payload: value }) }

    const setAttendanceSystem = (value) => { dispatch({ type: 'ATTENDANCE_CALCULATIONS', payload: value }) }

    const setFollowupStatus = (value) => { dispatch({ type: 'FOLLOWUP_STATUS', payload: value }) }
    const setVisitFollowupNote = (value) => { dispatch({ type: 'VISIT_FOLLOWUP_NOTE', payload: value }) }


    const deleteObject = async (dispatchType, link, object) => {
        if (!window.confirm("Delete the item?")) {
            return
        }
        dispatch({ type: 'LOADING', payload: true })
        try {
            const result = await axios({
                url: Host + link + "/" + object?.id,
                method: "delete",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                },
               
            })
            dispatch({ type: 'LOADING', payload: false })

            if (result && result.status === 200) {
                if(! toast.isActive(toastId.current)) {
                   toast.success(result.data.data)
                }
                return (result.status)
            } else if (result.data.status === false) {
                if(! toast.isActive(toastId.current)) {
                   toast.error("Error")
                }
                return ("Error")
            }

        } catch (error) {
            dispatch({ type: 'LOADING', payload: false })
            const responseResult = error.response
            if (responseResult && responseResult.status === 400) {
                if (typeof responseResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key) => {

                        responseResult.data.data[key].map((message) => {
                            if(! toast.isActive(toastId.current)) {
                              toast.error(message)
                            }
                        })
                    })
                    return ("Error")
                }
                else {
                    if(! toast.isActive(toastId.current)) {
                      toast.error(responseResult.data.data)
                    }
                    return ("Error")
                }

            }
            else if (responseResult && responseResult.status === 401) {
                if(! toast.isActive(toastId.current)) {
                  toast.error(responseResult.data.data)
                }
                cookies.remove("token");
                window.location.href = "/"
                return ("Error")
            }
            else {
                if(! toast.isActive(toastId.current)) {
                   toast.error("network error")
                }
                return ("Error")
            }
        }

    };

    const fetchData = async (dispatchType, link, params) => {
        dispatch({ type: 'LOADING', payload: true })
        try {
            let result = "";

            result = await axios({
                url: Host + link,
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                },
                params: params
            })

            if (result && result.status === 200) {
                var fetchedData = result.data;
                // console.log("VISITS_REPORT"+dispatchType,fetchedData)  

                dispatch({ type: dispatchType, payload: fetchedData })
                dispatch({ type: 'LOADING', payload: false })
                return fetchedData
            } else if (fetchedData.status === false) {
                if(! toast.isActive(toastId.current)) {
                  toast.error("Error")
                }
                dispatch({ type: dispatchType, payload: [] })
                dispatch({ type: 'LOADING', payload: false })
            }
        } catch (error) {
            dispatch({ type: dispatchType, payload: [] })
            dispatch({ type: 'LOADING', payload: false })
            const responseResult = error.response
            if (responseResult && responseResult.status === 400) {
                if (typeof responseResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key) => {
                        responseResult.data.data[key].map((message) => {
                            if(! toast.isActive(toastId.current)) {
                               toast.error(message)
                            }
                        })
                    })
                }
                else {
                    if(! toast.isActive(toastId.current)) {
                      toast.error(responseResult.data.data)
                    }
                }
            }
            else if (responseResult && responseResult.status === 401) {
                if(! toast.isActive(toastId.current)) {
                    if(link!='companies_list')
                      toast.error(responseResult.data.data)
                }
              
                if(link!='companies_list'){
                  window.location.href = "/"
                  cookies.remove("token");
                }
            }
            else {
                console.log(error)
                if(! toast.isActive(toastId.current)) {
                   toast.error("network error")
                }
            }
        }
    }
    
    const GetRegionOfPoints=async(dispatchType, link, params)=>{
        dispatch({ type: 'LOADING', payload: true })
        try {
            let result = "";

            result = await axios({
                url: Host + link,
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                },
                params: params
            })

            if (result && result.status === 200) {
                var fetchedData = result.data;
                // console.log("VISITS_REPORT"+dispatchType,fetchedData)  

                dispatch({ type: dispatchType, payload: fetchedData })
                dispatch({ type: 'LOADING', payload: false })
                return fetchedData
            } else if (fetchedData.status === false) {
                if(! toast.isActive(toastId.current)) {
                  toast.error("Error")
                }
                dispatch({ type: dispatchType, payload: [] })
                dispatch({ type: 'LOADING', payload: false })
            }
        } catch (error) {
            dispatch({ type: dispatchType, payload: [] })
            dispatch({ type: 'LOADING', payload: false })
            const responseResult = error.response
            if (responseResult && responseResult.status === 400) {
                if (typeof responseResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key) => {
                        responseResult.data.data[key].map((message) => {
                            if(! toast.isActive(toastId.current)) {
                               toast.error(message)
                            }
                        })
                    })
                }
                else {
                    if(! toast.isActive(toastId.current)) {
                       toast.error(responseResult.data.data)
                    }
                }
            }
            else if (responseResult && responseResult.status === 401) {
                if(! toast.isActive(toastId.current)) {
                   toast.error(responseResult.data.data)
                }
                cookies.remove("token");
                window.location.href = "/"
            }
            else {
                console.log(error)
                if(! toast.isActive(toastId.current)) {
                   toast.error("network error")
                }
            }
        }
    }

    const exportToCSV = (csvData, fileName) => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        if (csvData && csvData.length > 0) {
            const ws = XLSX.utils.json_to_sheet(csvData);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + '_' + Math.floor(Date.now() / 1000) + fileExtension);
        }
    }
    const exportAttendanceToCSV = (ws_data,fileName) => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        var workbook = XLSX.utils.book_new()

      
        var ws = XLSX.utils.aoa_to_sheet(ws_data);


        XLSX.utils.book_append_sheet(workbook, ws, "sheet1");
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + '_' + Math.floor(Date.now() / 1000) + fileExtension);


    }

    return (
        <AppContext.Provider
            value={{
                ...state,
                clearPointsAdvanceSearchFields,
                setRegion,
                setRegions,
                setDistrict,
                setOwnerName,
                setLoading,
                setShopName,
                setUser,
                setPointRequestStatus,
                setSelectedUsers,
                setFromToDate,
                setSelectedPointRequestStatus,
                setPointStatus,
                setVisitType,
                setVisitsReport,
                setJournies,
                setUserDailyPlans,
                setDistricts,
                setPoints,
                setVisits,
                setVisitFollowups,
                setFollowupStatusesList,
                setVisitTypeslist,
                fetchData,
                deleteObject,
                setPointsRequestsUpdate,
                exportToCSV,
                exportAttendanceToCSV,
                setMinDistance,
                setMaxDistance,
                setAttendanceSystem,
                setVisitFollowupNote,
                setFollowupStatus,
                setVisitFollowupsReport,
                GetRegionOfPoints
            }}
        >
            {children}
        </AppContext.Provider>
    )
}
export const useGlobalContext = () => {
    return useContext(AppContext)
}

export { AppContext, AppProvider }
