import React,{useEffect,useState} from 'react'
import StyledDataGrid from '../GeneralComponents/StyledDataGrid'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Pagination from '../Share/Pagination'
import {useSelector,useDispatch} from 'react-redux'
import Tooltip from '@mui/material/Tooltip';
import $ from 'jquery'
import IconButton from "@mui/material/IconButton";
import "../../../assets/css/DG.css"
import "../../../assets/css/AccordionStyle.css"
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import {useTranslation} from "react-i18next";
import moment from 'moment';

import {
  HeadingSection
} from './StyledComponents/GridStyledComponent'
// import {useTranslation} from "react-i18next";

import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  TablePagination
} from '@mui/x-data-grid';

const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    position:'relative !important',
    top:'120px !important',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));

function CustomNoRowsOverlay() {
    const directions =useSelector(state => state.settingsData.directions)
    const [t, i18n] = useTranslation('common');

    return (
      <StyledGridOverlay>
        <svg
          width="120"
          height="100"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>
            {
             t('GLOBAL.GRID_NO_DATA')
            }
        </Box>
      </StyledGridOverlay>
    );
}

function CustomNoResultOverlay() {
  const directions =useSelector(state => state.settingsData.directions)
  const [t, i18n] = useTranslation('common');

  return (
    <StyledGridOverlay
      sx={{
          display:'flex',
          justifyContent:'center',
          alignItems:'center',
          width:'100%',
          height:'100%',
          position:'relative !important',
          top:'100px !important',
      }}
    >
      <Box
       sx={{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        width:'100%',
        height:'100%',
       
       
       }}
      >
        <svg
          width="120"
          height="100"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
          sx={{
            width:'100%',
            height:'100%',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
           
          }}
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>
            {
            t('GLOBAL.GRID_NO_RESULT_DATA')
            }
        </Box>
      </Box>
    </StyledGridOverlay>
  );
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
    </GridToolbarContainer>
  );
}


export default function Grid({hasSeperatedHeader,title,paginationType,hasRowColors,visitReport,submitForm,hasFooter,rows,reRender,columns,rowTotals,pageSize,pageNumber,setPageNumber,loading,setPageSize,handlePageChange,NumberOfPage,hasCreate,createObject,title_en,title_ar,gridHeight,}) {
  const MianTheme=useSelector(state=>state.settingsData.MainTheme)
  const GridTheme=useSelector(state=>state.settingsData.GridTheme)
  const directions=useSelector(state=>state.settingsData.directions)

  const ListOfTranslateDataCode=useSelector(state=>state.settingsData.ListOfTranslateDataCode)
  const SideMenuOpen=useSelector(state=>state.settingsData.SideMenuOpen)
  const catchedElement=React.useRef(null)

  const [t, i18n] = useTranslation('common');
  // const [customeColumn,setCustomeColumn]=useState([])
  var customeColumn=[];
  try{
    if(directions=='rtl')
      customeColumn=[...columns].reverse();
    else
      customeColumn=columns
      catchedElement.current=document.querySelector('.ContainerForGrid .MuiDataGrid-virtualScroller')
      //  if(directions=='rtl'){
      //   let data=[...columns].reverse()
        // setCustomeColumn(data);
        if(rows&&rows?.length>0){
        if(catchedElement.current&&directions=='rtl'){
          catchedElement.current.scrollLeft -=1000;
          setTimeout(()=>{
          catchedElement.current.scrollLeft +=1000;
          },100)
        }
        else if(catchedElement.current)
        catchedElement.current.scrollLeft -=1000;
        }else{
          // if(catchedElement.current)
          // catchedElement.current.scrollLeft=0
        }

        let catchFooterOfGrid=document.querySelector('.MuiDataGrid-footerContainer');
        if(catchFooterOfGrid){
          catchFooterOfGrid.setAttribute('style',`direction:${directions} !important`)
          if(directions=='rtl')
          catchFooterOfGrid.classList.add('isRtlDirections')
        }

        let catchFooterOfGrid_isRTL=document.querySelector('.muirtl-139dwd1 .muirtl-1b34haf-MuiDataGrid-footerContainer');
        if(catchFooterOfGrid_isRTL){
          catchFooterOfGrid_isRTL.setAttribute('style',`direction:${directions} !important`)
          if(directions=='rtl')
          catchFooterOfGrid_isRTL.classList.add('isRtlDirections')
        }
        
  }catch(e){
    console.log(e?.message)
  }

  // const customeColumn=React.useMemo(()=>{
  //   if(directions=='rtl'){
  //    return [...columns].reverse();
  //   }else{
  //      return columns
  //   }

  // },[ListOfTranslateDataCode])

  useEffect(()=>{
    window.addEventListener("resize",()=>{
      catchedElement.current=document.querySelector('.ContainerForGrid .MuiDataGrid-virtualScroller')
      //  if(directions=='rtl'){
      //   let data=[...columns].reverse()
        // setCustomeColumn(data);
        if(rows&&rows?.length>0){
          if(catchedElement.current&&directions=='rtl'){
            catchedElement.current.scrollLeft -=1000;
            setTimeout(()=>{
            catchedElement.current.scrollLeft +=1000;
            },100)
          }
             
          else if(catchedElement.current)
          catchedElement.current.scrollLeft -=1000;
        }else{
          if(catchedElement.current)
          catchedElement.current.scrollLeft=0
        }
    });
   

  },[])

  
  useEffect(()=>{
    if(reRender){
    let catchFooterOfGrid=document.querySelector('.MuiDataGrid-footerContainer');
    if(catchFooterOfGrid){
      catchFooterOfGrid.setAttribute('style',`direction:${directions} !important`)
      if(directions=='rtl')
      catchFooterOfGrid.classList.add('.isRtlDirections')
    }

    let catchFooterOfGrid_isRTL=document.querySelector('.muirtl-1b34haf-MuiDataGrid-footerContainer');
    if(catchFooterOfGrid_isRTL){
      catchFooterOfGrid_isRTL.style.direction=directions
      if(directions=='rtl')
      catchFooterOfGrid_isRTL.classList.add('.isRtlDirections')
    }
   }

  },[reRender])

  useEffect(()=>{
     catchedElement.current=document.querySelector('.ContainerForGrid .MuiDataGrid-virtualScroller')
    //  if(directions=='rtl'){
    //   let data=[...columns].reverse()
      // setCustomeColumn(data);
      if(rows&&rows?.length>0){
        if(catchedElement.current&&directions=='rtl'){
          catchedElement.current.scrollLeft -=1000;
          setTimeout(()=>{
          catchedElement.current.scrollLeft +=1000;
          },100)

        }
        else if(catchedElement.current)
        catchedElement.current.scrollLeft -=1000;
      }else{
        if(catchedElement.current)
        catchedElement.current.scrollLeft=0
      }

    //  }else{
    //   setCustomeColumn(columns);
    //  }

  },[ListOfTranslateDataCode,SideMenuOpen])

  return (
    <Box 
    sx={{ 
      height:'calc(100% - 9em)',
      '& .overRides':{
        backgroundColor:'#f00',
      },
      '& .normal':{
        backgroundColor:'transparent',
      },
      '& .muirtl-45pmgh-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer':{
        justifyContent: 'end !important',
      },

      "& .alignColumnRight":{
        // textAlign: "left !important",
        display: "flex !important",
        justifyContent: "end !important",
      },
      "& .alignColumnLeft":{
        // textAlign: "right !important",
        display: "flex !important",
        justifyContent: "end !important",
      },
      "& .alignColumnRight_2":{
        display: "flex !important",
        justifyContent: "end !important",
        
      },
      "& .alignColumnLeft_2":{
        display: "flex !important",
        justifyContent: "end !important",
      },
      '& .muirtl-45pmgh-MuiDataGrid-root .MuiDataGrid-cell,& .muirtl-w0uvmp .muirtl-45pmgh-MuiDataGrid-root .MuiDataGrid-cell,& .muirtl-w0uvmp .MuiDataGrid-cell,& .muirtl-9yjf1d-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer,& .muirtl-11m2kbl-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer,& .muirtl-1c8rkm6-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer,& .muirtl-vvj2lg-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer':{
        display: "flex !important",
        justifyContent: "end !important",
      },
      "& .MuiDataGrid-cell div,& MuiDataGrid-columnHeaderTitleContainer,& .MuiDataGrid-cell--textLeft,& .MuiDataGrid-columnHeaderTitleContainer,& .MuiDataGrid-cell":{
        display: "flex !important",
        justifyContent: directions=='rtl' ? "end !important":'start !important ',
      },
      '& .muirtl-okt5j6-MuiDataGrid-columnHeaders,& .muirtl-g3fbqv-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer,& .muirtl-1c3s7nw-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer,& .muirtl-3zricy-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer,& .muirtl-128z45f-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer,& .muirtl-48ljh-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer':{
        display: "flex !important",
        justifyContent: "end !important",
      },
      '& .MuiDataGrid-columnHeaderTitle':{
        fontSize: '13px !important',
      },
      '& .responsiveWidth':{
        // width:'100% !important',
      },
      '& .muirtl-levciy-MuiTablePagination-displayedRows':{
        color:`${GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.paperTextColor}   !important`,
      },
      '& .muirtl-1b34haf-MuiDataGrid-footerContainer,& .muirtl-rtrcn9-MuiTablePagination-root:last-child,& .MuiTablePagination-actions':{
        // direction:directions=='rtl'?'rtl !important':'ltr !important',
        // flexDirection:directions=='rtl'?'row-reverse !important':'row !important',
        // justifyContent:directions=='rtl'?'start-rever !important':'start  !important', 
      },
      '& .muirtl-3uo5b6':{
        display:'flex',
        justifyContent:'end !important'
      },
      '& .muirtl-okt5j6-MuiDataGrid-columnHeaders':{
        left:"auto !important",
      },
      '& .muirtl-1ofhcz8 .muirtl-1b34haf-MuiDataGrid-footerContainer, .muirtl-1ofhcz8 .muirtl-rtrcn9-MuiTablePagination-root:last-child, .muirtl-1ofhcz8 .MuiTablePagination-actions':{
          direction:'rtl !important'
      },
      '&.muirtl-sk7t1g-MuiDataGrid-root svg, .muirtl-sk7t1g-MuiDataGrid-root svg path,& .muiltr-sk7t1g-MuiDataGrid-root svg, .muiltr-sk7t1g-MuiDataGrid-root svg path':{
        color:`${GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.paperTextColor}   !important`,
        fill:`${GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme.paperTextColor}   !important`,
      },
      '& .muirtl-uw2ren-MuiDataGrid-virtualScrollerRenderZone':{
        // left:'0 !important',
      }
    
   

    }}
      
      >
        {
          hasCreate ?
              <HeadingSection
                color={GridTheme?.oppositePrimaryColor} 
                bgColor={GridTheme?.primaryColor}
                buttonBgColor={GridTheme?.secondaryColor}
                buttonTxColor={GridTheme?.oppositeSecondaryColor}
                >
                      <p>{title}</p>
                      <div className="createUser">
                          <Button style={{float:'right'}}  onClick={()=>{createObject()}}>{t('GLOBAL.CREATE_HEADER_BUTTON_TEXT')}</Button>
                      </div>
              </HeadingSection>
          :null
        }
        <div style={{direction:"ltr"}} className="ContainerForGrid">
        <StyledDataGrid
            GridTheme={GridTheme}
            hasCreate={hasCreate}
            hasSeperatedHeader={hasSeperatedHeader}
            // justifyContent="end"
            rows={rows}
            container 
            columns={customeColumn}
            
            rowCount={rowTotals}
            pageSize={pageSize}
            directions={directions}
            disableExtendRowFullWidth={true}
            // hideFooterSelectedRowCount
            page={pageNumber-1}
            loading={loading}
            // getRowHeight={() => '100px'}
            hideFooterSelectedRowCount
            autoHeight={true}
            hideFooterPagination={reRender?true:false}
            onPageSizeChange={(newPageSize) => {
                setPageSize(newPageSize)
            }}
            scrollbarSize={7}
            getRowClassName={(params) => {
              if(hasRowColors&&params?.row?.attendance_calculation){
                    let arr1=params?.row?.attendance_calculation?.road_time?.split(':');
                    let arr2=params?.row?.attendance_calculation?.road_time_calculated?.split(':');
                    let arr3=params?.row?.attendance_calculation?.visit_total_time?.split(':');
                    let arr4=params?.row?.attendance_calculation?.visit_total_time_calculated?.split(':');

                    console.log(arr1[0],arr1[1],arr1[2])
                    console.log(arr2[0],arr2[1],arr2[2])
                    console.log(arr3[0],arr3[1],arr3[2])

                    let check1=false;
                    let check2=false;
                    if(arr1[0]>arr2[0]){//check defferent between road and road calculated time
                      check1=true;
                    }else if(arr1[0]==arr2[0]){
                      if(arr1[1]>arr2[1]){
                        check1=true;
                      }else if(arr1[1]==arr2[1]){
                        if(arr1[2]>arr2[2]){
                          check1=true;
                        } 
                      }
                    }

                    if(arr3[0]>arr4[0]){//check defferent between visit and visit total calculated time
                      check2=true;
                    }else if(arr3[0]==arr4[0]){
                      if(arr3[1]>arr4[1]){
                        check2=true;
                      }else if(arr3[1]==arr4[1]){
                        if(arr3[2]>arr4[2]){
                          check2=true;
                        } 
                      }
                    }


                    if(check1 || check2)
                      return `overRides `
                    else 
                      return 'normal '
              }else
                return 'normal'
            }}
            // getCellClassName={(params)=>'responsiveWidth'}
            componentsProps={{
              pagination: {
                labelRowsPerPage: t('GLOBAL.GRID_ROW_PER_PAGE')
              },
            }}
      
            components={hasFooter?{
              Footer:()=>{
                  if(hasFooter)
                  return(
                    <div style={{display:'flex',justifyContent: 'end', alignItems: 'center',height:50,backgroundColor:GridTheme?.primaryColor}}>
                       <Button style={{float:'right',backgroundColor:GridTheme?.secondaryColor,color:GridTheme?.oppositeSecondaryColor,margin:'2px 15px'}}  onClick={()=>{submitForm()}}>{t('GLOBAL.GRID_FOOTER_SAVE_BUTTON')}</Button>
                    </div>
                  )
                  
                },
              NoRowsOverlay: CustomNoRowsOverlay,
              NoResultsOverlay:CustomNoResultOverlay

            }:{
              NoRowsOverlay: CustomNoRowsOverlay,
              NoResultsOverlay:CustomNoResultOverlay
            }}
            rowsPerPageOptions={[5, 10, 20]}
            paginationMode="server"
            onPageChange={(params) => {
                setPageNumber(params+1)
                // console.log('adsasdasd====>',params)
            }}
            // onNextPageClick={(params)=>{
            //   setPageNumber(params+1)

            // }}
            // onPreviousPageClick={(params)=>{
            //   setPageNumber(params-1)
            // }} 
            
            sx={{
              height:`auto`
              // padding:'20px'
            
            }}
        />
        </div>
        <Pagination  totalPage={NumberOfPage}  count={NumberOfPage} page={pageNumber} showFirstButton={true} showLastButton={true} pageNumber={pageNumber} setpageNumber={setPageNumber} />

</Box>
  )
}
