const reducer = (state, action) => {

    switch (action.type) {
        case 'LOADING':
            return ({ ...state, loading: action.payload })
        case 'POINTS':
            return ({ ...state, points: action.payload, loading: false })
        case 'VISITS':
            return ({ ...state, visits: action.payload, loading: false })
        case 'VISIT_FOLLOWUPS':
            return ({ ...state, visitFollowups: action.payload, loading: false })
        case 'FOLLOWUP_STATUSES_LIST':
            return ({ ...state, followupStatusesList: action.payload, loading: false })
        case 'VISIT_TYPES_LIST':
            return ({ ...state, visitTypesList: action.payload, loading: false })
        case 'DATA':
            return ({ ...state, data: action.payload, loading: false })
        case 'ALL_POINTS':
            return ({ ...state, allPoints: action.payload, loading: false })
        case 'ALL_VISITS':
            return ({ ...state, allVisits: action.payload, loading: false })
        case 'DISTRICTS':
            return ({ ...state, districts: action.payload, loading: false })
        case 'REGIONS':
            return ({ ...state, regions: action.payload, loading: false })
        case 'DISTRICT':
            return ({ ...state, district: action.payload })
        case 'REGION':
            return ({ ...state, region: action.payload })
        case 'SHOP_NAME':
            return ({ ...state, shopName: action.payload })
        case 'OWNER_NAME':
            return ({ ...state, ownerName: action.payload })
        case 'MIN_DISTANCE':
            return ({ ...state, minDistance: action.payload })
        case 'MAX_DISTANCE':
            return ({ ...state, maxDistance: action.payload })
        case 'CLEAR_ADVANCE_FILTER':
            return ({ ...state, followupStatus: '', visitFollowupNote: '', district: [], region: [], minDistance: '', maxDistance: '', shopName: '', ownerName: '', user: [], pointRequestStatus: [], fromToDate: [null, null] })
        case 'POINTS_REQUESTS':
            return ({ ...state, pointsRequests: action.payload, loading: false })
        case 'POINT_UPDATE':
            return ({ ...state, pointsRequestsUpdate: action.payload, loading: false })
        case 'USERS':
            return ({ ...state, users: action.payload })
        case 'GROUPS':
            return ({ ...state, groups: action.payload })
        case 'JOURNEYS_TYPES':
            return ({ ...state, journey_types: action.payload })
        case 'NOTIFICATIONS':
             return ({ ...state, groups: action.payload })
        case 'NOTIFICATION_TYPES':
            return ({ ...state, notification_types: action.payload })
        case 'USER':
            return ({ ...state, user: action.payload })
        case 'POINTS_REQUESTS_STATUSES':
            return ({ ...state, pointsRequestsStatuses: action.payload })
        case 'POINT_REQUEST_STATUSE':
            return ({ ...state, pointRequestStatus: action.payload })
        case 'SELECTED_USERS':
            return ({ ...state, selectedUsers: action.payload })
        case 'FROM_TO_DATE':
            return ({ ...state, fromToDate: action.payload })
        case 'SELECTED_POINT_REQUEST_STATUS':
            return ({ ...state, selectedPointRequestStatus: action.payload })
        case 'POINT_STATUSE':
            return ({ ...state, pointStatus: action.payload })
        case 'VISIT_TYPE':
            return ({ ...state, visitType: action.payload })
        case 'VISITS_REPORT': 
            return ({ ...state, visitsReport: action.payload })
        case 'VISIT_FOLLOWUPS_REPORT': 
        console.log("visitFollowupsReport",action.payload);
            return ({ ...state, visitFollowupsReport: action.payload })
        case 'POINT_STATUSES':
            return ({ ...state, pointsStatuses: action.payload })
        case 'JOURNIES':
            return ({ ...state, journies: action.payload, loading: false })
        case 'USER_DAILY_PLAN':
            return ({ ...state, userDailyPlans: action.payload, loading: false })
        case 'ATTENDANCE_CALCULATIONS':
            return ({ ...state, attendanceCalculations: action.payload, loading: false })
        case 'LOADING':
            return ({ ...state, loading: action.payload })
        case 'FOLLOWUP_STATUS':
            return ({ ...state, followupStatus: action.payload })
        case 'VISIT_FOLLOWUP_NOTE':
            return ({ ...state, visitFollowupNote: action.payload })  
        case 'SHIFTS':
            return ({ ...state, Shifts: action.payload })
        case 'ATTENDANCE_TYPES':
            return ({ ...state, attendance_typs: action.payload })
        case 'ATTENDANCE_TYPES_SHIFTS':
            return ({ ...state, attendance_type_shifts: action.payload })
        case 'DEPARTMENTS':
            return ({ ...state, Departments: action.payload })
        case 'COMPANYS':
            return ({ ...state, Companys: action.payload })
        case 'PREVIOUS_FOLLOWUP_REPORT':
            return ({ ...state, PreviousFollowUp: action.payload })

        case 'GET_REGIONS_OF_POINT':
        return ({ ...state, getRegionOfPoint: action.payload })
        default:
            break;
    }
    return state
}

export default reducer