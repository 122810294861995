
const newType_id={
    status_id:1,
    status_name:"new"
}
const inprogressType_id={
    status_id:2,
    status_name:"in progress"
}

const APP_CONSTANTS={
    POINT_REQUEST_STATUS_APPROVED:3,
    POINT_REQUEST_STATUS_PARTIALLY_APPROVED:2,
    newType_id:newType_id,
    inprogressType_id:inprogressType_id,
    TECHNICIANS_DEPARTMENT_ID:10,
    ENGINEERS_DEPARTMENT_ID:9,
    CUSTOMER_CARE_TYPE:9,
    INSTALLATION_TYPE:2,
    MAP: {
        BING_API_KEY: "Amj-O4Y8f3uP55g8p-3hdTHq3aQQHaEGR2Hxm9ZjVMHYmW1gdQzyarvvEEI0scmM",//using my account wael.m@is.iq
        GOOGLE_API_KEY:"AIzaSyBrWrCrp3msCUUk8sf78eUeguFEB5zS4IQ"//using is.wenkgps@gmail.com
      }
}

export default APP_CONSTANTS;