
import React, {useEffect,useState} from 'react';
import Pagination from "react-js-pagination";
import Cookies from "universal-cookie";
import axios from "axios";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import ShiftForm from './ShiftForm';
import {useSelector,useDispatch} from 'react-redux'
import Grid from '../Share/Grid'
import DialogForm from '../Share/DialogForm';
import DropDownGrid from '../Share/DropDownGrid'
import {Box,MenuItem,ListItemIcon} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import IconButton from "@mui/material/IconButton";
import {useTranslation} from "react-i18next";
import helpers from "../../../assets/js/helper";

const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language")?cookies.get("language"):"ar"
function Shift() {
    const [t, i18n] = useTranslation('common');
    const token = cookies.get("token")
    const [loading, setloading] = useState(false);
    const [state, setData] = useState({
        shifts: ''
    });
    const [showShiftForm, setShowShiftForm] = useState(false);
    const [selectedShift, setSelectedShift] = useState(null);

    const [pageSize, setPageSize] = useState(5);
    const [pageNumber, setpageNumber] = useState(1);
    const [shiftTotal, setShiftsTotal] = useState(0)
    const [shiftsNumberOfPage, setShiftsNumberOfPage] = useState(0)
    const directions = useSelector(state => state.settingsData.directions)
    const [rows, setRow] = useState([])

    const handlePageChange = (newPage) => {
        setpageNumber(newPage + 1)
    }

    const columns = [
        { field: 'id', headerName: t('SHIFTS.ID'), flex: .1 },
     
        {
            field: 'start_minutes', headerName: t('SHIFTS.SHIFTS_STARTS_TIME'), flex: 2,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>
                    {helpers.convertMinutesToHourse(rows.row?.start_minutes,0)}
                </div>
            )
        },
        {
            field: 'end_minutes', headerName: t('SHIFTS.SHIFTS_END_TIME'), flex: 2,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>
                    {helpers.convertMinutesToHourse(rows.row?.end_minutes,0)}
                </div>
            )
        },
        {
            field: 'minuts_required', headerName: t('SHIFTS.SHIFTS_REQUIRED_TIME'), flex: 2,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>
                    {helpers.convertMinutesToHourse(rows.row?.minuts_required,1)}
                </div>
            )
        },
        {
            field: 'day_difference', headerName: t('SHIFTS.SHIFTS_DAY_DIFFERENCE'), flex: 2,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>
                    {rows.row?.day_difference}
                </div>
            )
        },
        {
            field: 'actions', flex: 0.4,
            headerName: t('SHIFTS.ACTIONS'),
            renderCell: (rows) => (
                <DropDownGrid>

                <MenuItem onClick={() => { updateObject(rows.row)}}>
                    <ListItemIcon sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin:'0px 7px 0 0'
                    }}>
                       <EditIcon style={{ fill: "#111827" }} />

                    </ListItemIcon>
                    {directions != 'rtl' ? "Edit" : 'تعديل'}
                </MenuItem>
                <MenuItem onClick={() => {deleteObject(rows.row)}}>
                    <ListItemIcon sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin:'0px 7px 0 0'
                    }}>
                      <DeleteIcon style={{ fill: "#f00" }} />
                    </ListItemIcon>
                    {directions != 'rtl' ? "Delete" : 'حذف'}
                </MenuItem>

                </DropDownGrid>
            ),
        },
    ]

  


    const fetchData = async (pageNumber = 1) => {
        setloading(true)
        try {
            const result=await axios({
                url: Host + "shifts?page="+pageNumber,
                method: "get",
                headers: {
                "Authorization" : `Bearer ${token}`,
                    Accept:"application/json",
                    'X-localization':language,
                },
                params: {
                    page_size:pageSize,
                }
        
            })
            if(result && result.status == 200)
            {
                const shifts=result.data ;
                
                console.log(shifts);
                setData({
                    shifts:shifts 
                });
            } else if (result.data.status === false) {
                toast.error("Error")
            }
        } catch (error) {
            const responseResult=error.response            
            if (responseResult && responseResult.status === 400) {
                if(typeof responseResult.data.data === "object")
                {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key)=>{
                    
                        responseResult.data.data[key].map((message)=>{
                            toast.error(message)
                        })
                    })
                }
                else{
                    toast.error(responseResult.data.data)
                }
                
            }
            else if(responseResult && responseResult.status === 401) {
                toast.error(responseResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else
            {
                toast.error("network error")
            }
        }
        setloading(false)
                   
    };
    const updateObject = async (object) => {
      
        console.log("hi");
        setSelectedShift(object)
        setShowShiftForm(true)
    };
    const deleteObject = async (object) => {
        if (!window.confirm("Delete the item?")) {
            return
        }
        setloading(true)
        try {
            const result=await axios({
                url: Host + "shifts/"+object.id,
                method: "delete",
                headers: {
                "Authorization" : `Bearer ${token}`,
                    Accept:"application/json",
                    'X-localization':language,
                },
              
        
            })
            if(result && result.status == 200)
            {
                toast.success(result.data.data)
                fetchData();
            } else if (result.data.status === false) {
                toast.error("Error")
            }
        } catch (error) {
            const responseResult=error.response            
            if (responseResult && responseResult.status === 400) {
                if(typeof responseResult.data.data === "object")
                {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key)=>{
                    
                        responseResult.data.data[key].map((message)=>{
                            toast.error(message)
                        })
                    })
                }
                else{
                    toast.error(responseResult.data.data)
                }
                
            }
            else if(responseResult && responseResult.status === 401) {
                toast.error(responseResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else
            {
                toast.error("network error")
            }
        }
        setloading(false)
    };
    
    const createObject = async (object) => {
        setSelectedShift(null)
        setShowShiftForm(true)
    };

    const handleSetshowShiftForm = (openStatus,needsReload) => {
      if(needsReload)
      fetchData(pageNumber);
      setShowShiftForm(openStatus)
    };
    
    
    useEffect(() => {        
        fetchData(pageNumber);
    }, [pageNumber,pageSize])

    useEffect(() => {
        if (state && state.shifts&&state.shifts?.data?.length > 0) {
            setRow(state?.shifts?.data);
            setShiftsTotal(state?.shifts?.total)
            setShiftsNumberOfPage(state?.shifts?.last_page)
        }else{
            setRow([]);
            setShiftsTotal(0)
            setShiftsNumberOfPage(0)
        }
    }, [state])
    
    return (
        <>
            {showShiftForm?
                <ShiftForm shift={selectedShift} showShiftForm={showShiftForm} setShowShiftForm={handleSetshowShiftForm} />
            :null}
       

            <Grid
              rows={rows}
              columns={columns}
              rowTotals={shiftTotal}
              pageSize={pageSize}
              setPageSize={setPageSize}
              pageNumber={pageNumber}
              setPageNumber={setpageNumber}
              loading={loading}
              handlePageChange={handlePageChange}
              NumberOfPage={shiftsNumberOfPage}
              directions={directions}
              hasCreate={true}
              createObject={createObject}
              title={t('SHIFTS.HEADER_TITLE')}
            //   title_ar="قائمة الصلاحيات"
              gridHeight={{
                height:rows?.length>5?pageSize==5?400:pageSize==10?650:950:400,
              }}
            />
        </>
    );
}

export default Shift;



// <div className="container">
// <div className="row justify-content-center">
//     <div className="col-md-12">
//         <div className="card">
//             <div className="card-header">Role List <Button style={{float:'right'}} onClick={()=>{createObject()}}>create</Button></div>
//             <div className="card-body">
//             <table>
//                 <thead>
//                     <tr>
//                         <th>1</th>
//                         <th>Role Name</th>
//                         <th>edit</th>
//                         <th>delete</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                 {   
//                     state?.roles?.data ? 
//                         state?.roles?.data?.map((object) => (
//                                 <tr key={object?.id}>
//                                     <td>{object?.id}</td>
//                                     <td>{object?.name}</td>
//                                     <td><Button onClick={()=>{updateObject(object)}}>update</Button></td>
//                                     <td><Button style={{backgroundColor:'red',color:'white'}} onClick={()=>{deleteObject(object)}}>delete</Button></td>
                                    
//                                 </tr>
//                         )) : "Loading..."
//                 }
//                 </tbody>
//             </table>
//                 <div>
//                     <Pagination
//                         activePage={state?.roles?.current_page ? state?.roles?.current_page : 0}
//                         itemsCountPerPage={state?.roles?.per_page ? state?.roles?.per_page : 0 }
//                         totalItemsCount={state?.roles?.total ? state?.roles?.total : 0}
//                         onChange={(pageNumber) => {
//                             fetchData(pageNumber)
//                         }}
//                         pageRangeDisplayed={8}
//                         itemClass="page-item"
//                         linkClass="page-link"
//                         firstPageText="First Page"
//                         lastPageText="Last Lage"
//                     />
//                 </div>
//             </div>
//         </div>
//     </div>
// </div>
// </div>

