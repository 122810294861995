import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React,{useMemo,useEffect,useState,useCallback,lazy,Suspense} from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Context from './assets/js/context';
import Login from './component/main/Login';
import AppBarAndContainer from './component/main/AppBarAndContainer';


import { ToastContainer } from "react-toastify";
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/test1.css';
import SideNavBar from './component/main/Share/SideNavBar';
import { Box, StyledEngineProvider } from '@mui/material';
import Cookies from "universal-cookie";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import rtlPlugin from 'stylis-plugin-rtl';
import { prefixer } from 'stylis';
import { StyleSheetManager } from 'styled-components';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { useSelector, useDispatch } from 'react-redux'
import Loading from './component/Settings/Loading'
import LoadingPage from './component/main/Share/LoadingPage'

import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
 
import common_ar from "./translations/ar/common.json";
import common_en from "./translations/en/common.json";
import {setUserToken} from './component/reduxStore/UserReducer'
import {
  setDirections,
  setSideMenuMode,
  setMainTheme,
  setSideMenuTheme,
  setToolbarTheme,
  setGridTheme,
  setSearchButtonTheme,
  setAllThemeSettings
} from './component/reduxStore/SettingsReducer'
import NotFound404 from './component/main/RedirectsPages/ErrorPage';
// import i18n from './component/main/i18next'
import Host from "./assets/js/Host";
import { toast } from "react-toastify";
import axios from "axios";
import config from "devextreme/core/config";
import NetworkDetector from './Hoc/NetworkDetector';
// const AppBarAndContainer = React.lazy(() => import('./component/main/AppBarAndContainer'));
const cookies = new Cookies();
const language = cookies.get("language") ? cookies.get("language") : "ar";


const App =({code,dataTranslate})=> {
  const [state,setState]=useState({
    data: [],
    ms: [],
    data1: [],
    Dash: [],
    check: '',
    che: 'login',
    new: '',
    inprogress: '',
    closed: '',
    approved: '',
    rejected: '',
    archived: '',
    noti: '',
    desc: '',
    commenter: '',
    body: '',
    title: "",
    SiFilter: [],
  })

  const directions = useSelector(state=> state.settingsData.directions)
  const MainTheme = useSelector(state=> state.settingsData.MainTheme);
  const GridTheme = useSelector(state=> state.settingsData.GridTheme);
  const allThemesValue=useSelector(state=>state.settingsData);
  const [translationSectionsData,setTranslationSectionsData]=useState([])
  const [translationCode,setTranslationCode]=useState('')
  const [language_id,setLanguage_id]=useState(null)
  const dispatch=useDispatch();
  const token =cookies.get('token');
  const ReduxToken=useSelector(state=>state.userData.token);
  useEffect(()=>{
    if(token){
      dispatch(setUserToken(token))
    }
  },[token])

  const Translate_en = useSelector(state=> state.translateData.translation_en)
  const Translate_ar = useSelector(state=> state.translateData.translation_ar)

  const [cacheRtl,setCacheRtl]=useState(createCache({ key: 'css' }));
  const [loading,setLoading]=useState(true)
  const [theme,setTheme]=useState(createTheme({
      direction: directions, // Both here and <body dir="rtl">
  }))

  useEffect(() => {
    if (directions=='rtl' ) {
        setCacheRtl(
          createCache({
            key: 'muirtl',
            stylisPlugins: [rtlPlugin,prefixer],
           })
        )
        document.dir='rtl';
        cookies.set('language','ar')
    } else {
      setCacheRtl(createCache({ key: 'css' }))
      document.dir='ltr';
      cookies.set('language','en')
    }

    setTheme( createTheme({
      direction: directions, // Both here and <body dir="rtl">
    }))
    
    
  }, [directions]);

  const ConfigurationDevExtreem=React.useMemo(()=>{
   return config({
      rtlEnabled: directions == 'rtl'?true:false,
      forceIsoDateParsing: false,
    });
  },[directions])

  const isrtl=React.useMemo(()=>{
    return createCache({
        key: directions=='rtl'? 'muirtl' : 'muiltr',
        stylisPlugins: directions=='rtl' ? [rtlPlugin] : [],
      });
  },[directions])

  useEffect(()=>{
    if(MainTheme?.bgColor)
    document.querySelector('body').style.backgroundColor=MainTheme?.bgColor
  },[MainTheme]);


    return (
      <CacheProvider value={isrtl}>
        <ThemeProvider theme={theme}>
            <BrowserRouter>
              <ToastContainer
                containerId="container_toast_id"
                position="top-center"
                autoClose={5000}
                hideProgressBar
                newestOnTop
                closeOnClick
                rtl
                pauseOnVisibilityChange
                draggable
                pauseOnHover
                limit={3}
                

              />
              <Context.Provider value={{
                value:state,
                action: {
                },
              }} >
                <Routes>
                  <Route  path='/' element={<Login/>} />
                  
                    
                    <Route path='/Users' element={<AppBarAndContainer/>} />
                    <Route path='/Roles' element={<AppBarAndContainer/>} />
                    <Route path='/Dashboard' element={<AppBarAndContainer/>} />
                    <Route path='/Surveys' element={<AppBarAndContainer/>} />
                    <Route path='/build_survey' element={<AppBarAndContainer/>} />
                    <Route path='/PointStatus' element={<AppBarAndContainer/>} />
                    <Route path='/PointRequests' element={<AppBarAndContainer/>} />
                    <Route path='/Points' element={<AppBarAndContainer/>} />
                    <Route path='/Visits' element={<AppBarAndContainer/>} />
                    <Route path='/Departments' element={<AppBarAndContainer/>} />
                    <Route path='/Districts' element={<AppBarAndContainer/>} />
                    <Route path='/Regions' element={<AppBarAndContainer/>} />
                    <Route path='/Journeys' element={<AppBarAndContainer/>} />
                    <Route path='/user_daily_plans' element={<AppBarAndContainer/>} />
                    <Route path='/field_lists' element={<AppBarAndContainer/>} />
                    <Route path='/field_list_values' element={<AppBarAndContainer/>} />
                    <Route path='/visits_report' element={<AppBarAndContainer/>} />
                    <Route path='/attendance_report' element={<AppBarAndContainer/>} />
                    <Route path='/followup_report' element={<AppBarAndContainer/>} />
                    <Route path='/companies' element={<AppBarAndContainer/>} />
                    <Route path='/navbar' element={<SideNavBar/>} />
                    <Route path='/settings' element={<AppBarAndContainer/>} />
                    <Route path='/Notification' element={<AppBarAndContainer/>} />
                    <Route path='/Group' element={<AppBarAndContainer/>} />
                    <Route path='/Profile' element={<AppBarAndContainer/>} />
                    <Route path='/FollowUpStatus' element={<AppBarAndContainer/>} />
                    <Route path='/AddJourneys' element={<AppBarAndContainer/>} />
                    <Route path='/ViewJourneys' element={<AppBarAndContainer/>} />
                    <Route path='/AttendanceSummery' element={<AppBarAndContainer/>} />
                    <Route path='/Shifts' element={<AppBarAndContainer/>} />
                    <Route path='/language' element={<AppBarAndContainer/>} />

                    {/* <Route path='/attendance_report/:search' element={<AppBarAndContainer/>} /> */}





                    <Route path='*' element={<AppBarAndContainer/>} />
                
                </Routes>
              </Context.Provider>
            </BrowserRouter>
        </ThemeProvider>
      </CacheProvider>
    );
}



export default App;