
import React, { useEffect, useState } from 'react';
// import Pagination from '@mui/material/Pagination';

import Cookies from "universal-cookie";
import axios from "axios";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import UserForm from './UserForm';
import UserRoleForm from './UserRoleForm';

import IconButton from "@mui/material/IconButton";
import Tooltip from '@mui/material/Tooltip';
import "../../../assets/css/DG.css"
import "../../../assets/css/AccordionStyle.css"
import { useSelector } from 'react-redux'
import red from '@mui/material/colors/red';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {useNavigate} from 'react-router-dom'

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import * as GrIcone from 'react-icons/gr'
import { useTranslation } from "react-i18next";
import DropDownGrid from '../Share/DropDownGrid'
import { MenuItem ,ListItemIcon} from '@mui/material';
import GroupAddRoundedIcon from '@mui/icons-material/GroupAddRounded';
import Grid from '../Share/Grid'
import UserGroup from './UserGroup'
import UserAccessGroup from './UserAccessGroup';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import UserShifts from './UserShifts';
import CustomeFilter from '../Share/CustomeFilter'
import SearchFieldV2 from '../GeneralComponents/SearchFieldV2';
import SearchField1 from '../GeneralComponents/SearchField1'
import BasicDatePicker from '../GeneralComponents/BasicDatePicker';
import BasicDatePickerV2 from '../GeneralComponents/BasicDatePickerV2';
import FilterBox from '../Share/FilterBox';
import { useGlobalContext } from '../../../context'
import {Box,TextField,Typography,Autocomplete,Button} from '@mui/material'

const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"
let roles = []

try {

    roles = cookies.get("roles")
} catch (error) {


}
function Users() {
    const { t, i18n } = useTranslation('common');
    const directions = useSelector(state => state.settingsData.directions)
    const GridTheme = useSelector(state => state.settingsData.GridTheme)
    const token = cookies.get("token")
    const { Companys,Departments,fetchData } = useGlobalContext()
    const SearchButtonTheme=useSelector(state=> state.settingsData.SearchButtonTheme);
    const filterSearchBoxMode = useSelector(state=> state.settingsData.filterSearchBoxMode)
    const FilterButtonHeight =useSelector(state=>state.settingsData.FilterButtonHeight)
    const screenWidth=useSelector(state=>state.settingsData.screenWidth)

    const [loading, setloading] = useState(false);
    const [state, setData] = useState({
        users: ''
    });

    const navigate = useNavigate();

    const Language = React.useMemo(() => {
        if (directions !== 'rtl') {
            return require('../../Settings/Translate_en.json').USERS
        } else {
            return require('../../Settings/Translate_ar.json').USERS
        }
    }, [directions])


    const [userTotals, setUserTotals] = useState(0)
    const [userNumberOfPage, setUserNumberOfPage] = useState(0)

    const [showUserForm, setshowUserForm] = useState(false);
    const [showUserRoleForm, setshowUserRoleForm] = useState(false);
    const [selectedUser, setselectedUser] = useState(null);
    const [showUserGroup,setShowUserGroup]=useState(false)
    const [showAccessGroup,setShowUseAccessrGroup]=useState(false)
    const [pageSize, setPageSize] = useState(5);
    const [pageNumber, setpageNumber] = useState(1);
    const [rows, setRow] = useState([])
   
    const [SearchUserName,setSearchUserName]=useState('')
    const [SearchUserFullName,setSearchUserFullName]=useState('')
    const [SearchCompanyId,setSearchCompanyId]=useState(null)
    const [SearchDepartmentId,setSearchDepartmentID]=useState(null)



   const [showAssignShiftTouser,setShowAssignShiftTouser]=useState(false)
    const columns = [
        {
            field: 'id', headerName: t("USERS.ID"),minWidth:100,flex:1,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} >
                    {rows?.row?.id}
                </div>
            )
        },
        {
            field: 'full_name', headerName: t("USERS.FULL_NAME"), minWidth:150,maxWidth:350,flex:1,
            renderCell: (rows) => (
                <div style={{ width: 'max-content',minWidth:150,maxWidth:350 }} className={`${directions == 'rtl' ? 'alignColumnRight' : 'alignColumnLeft'}`}>
                    {rows.row.full_name}
                </div>
            )
        },
        {
            field: 'username', headerName: t('USERS.USER_NAME'), minWidth:150,maxWidth:350,flex:1,
            renderCell: (rows) => (
                <div style={{ width: 'max-content',minWidth:150,maxWidth:350 }} className={`${directions == 'rtl' ? 'alignColumnRight' : 'alignColumnLeft'}`}>
                    {rows.row.username}
                </div>
            )
        },
        {
            field: 'phone', headerName: t("USERS.PHONE"), minWidth:150,maxWidth:350,flex:1,
            renderCell: (rows) => (
                <div style={{ width: 'max-content',minWidth:150,maxWidth:350 }} className={`${directions == 'rtl' ? 'alignColumnRight' : 'alignColumnLeft'}`}>
                    {rows.row.phone}
                </div>
            )
        },
        {
            field: 'company', headerName: t("USERS.COMPANY"), minWidth:150,maxWidth:350,flex:1, renderCell: (rows) => (
                <div style={{ width: 'max-content',minWidth:150,maxWidth:350 }} className={`${directions == 'rtl' ? 'alignColumnRight' : 'alignColumnLeft'}`}>
                    {rows?.row?.company?.company_name}
                </div>
            )
        },
        {
            field: 'departmentName', headerName: t("USERS.DEPARTMENT"), minWidth:150,maxWidth:350,flex:1, renderCell: (rows) => (
                <div style={{ width: 'max-content',minWidth:150,maxWidth:350 }} className={`${directions == 'rtl' ? 'alignColumnRight' : 'alignColumnLeft'}`}>
                    {rows?.row?.department?.name}
                </div>
            )
        },
        {
            field: 'active', headerName: t("USERS.ACTIVE"), minWidth:150,maxWidth:350,flex:1, renderCell: (rows) => (
                <div style={{ width: 'max-content',minWidth:150,maxWidth:350 }} className={`${directions == 'rtl' ? 'alignColumnRight' : 'alignColumnLeft'}`}>
                    {rows?.row?.active == 1 ? 'active' : 'disabled'}
                </div>
            )
        },
        {
            field: 'is_demo', headerName: t('USERS.IS_DEMO'), minWidth:150,maxWidth:350,flex:1, renderCell: (rows) => (
                <div style={{ width: 'max-content',minWidth:150,maxWidth:350 }} className={`${directions == 'rtl' ? 'alignColumnRight' : 'alignColumnLeft'}`}>
                    {rows?.row?.is_demo == 1 ? Language?.YES : Language?.NO}
                </div>
            )
        },
      

        {
            field: 'actions', minWidth:150,flex:1,width:"50px",
            headerName: t("USERS.ACTIONS"),
            renderCell: (rows) => (
                <DropDownGrid>
                    {/* <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems: 'center'}}>
                        <IconButton
                            size="small"
                            style={{ marginLeft: 0 }}
                            onClick={() => { updateObject(rows?.row) }}
                            className="btnAction"
                            sx={{ outline: 'none', border: 'none' }}
                        >
                            <Tooltip title={directions != 'rtl' ? "edit" : 'تعديل'} >
                                <EditIcon style={{ fill: "#111827" }} />
                            </Tooltip>
                        </IconButton>

                        <IconButton
                            style={{ marginLeft: 15 }}
                            aria-label="edit"
                            size="small"
                            onClick={() => { showRoles(rows?.row) }}
                            className="btnAction"
                            sx={{ outline: 'none', border: 'none', fill: GridTheme?.primaryColor }}
                        >
                            <Tooltip title={directions != 'rtl' ? "show roles" : 'عرض الصلاحيات'}>
                                <GrIcone.GrUserAdmin style={{ fill: GridTheme?.primaryColor, color: GridTheme?.primaryColor, }} />
                            </Tooltip>
                        </IconButton>

                        <IconButton
                            style={{ marginLeft: 20 }}
                            aria-label="delete"
                            size="small"
                            onClick={() => { deleteObject(rows?.row) }}
                            className="btnAction"
                            sx={{ outline: 'none', border: 'none' }}
                        >
                            <Tooltip title={directions != 'rtl' ? "delete" : 'حذف'}>
                                <DeleteIcon sx={{ color: GridTheme?.primaryColor, }} />
                            </Tooltip>
                        </IconButton>

                        {roles?.includes(parseInt(process.env.REACT_APP_ACTIVATE_USER)) &&
                            <IconButton
                                style={{ marginLeft: 20 }}
                                aria-label="Activate"
                                size="small"
                                onClick={() => { activateObject(rows?.row) }}
                                className="btnAction"
                                sx={{ outline: 'none', border: 'none' }}
                            >
                                <Tooltip title={directions != 'rtl' ? "active or disabled" : 'نشط او غير نشط'}>
                                    {
                                        rows?.row?.active == 1 ?
                                            <CheckCircleIcon sx={{ color: '#111827' }} size={24} />
                                            :
                                            <UnpublishedIcon sx={{ color: red[500] }} size={24} />

                                    }
                                </Tooltip>
                            </IconButton>
                        }
                        {roles?.includes(parseInt(process.env.REACT_APP_ACTIVATE_DEMO_USER)) &&
                            <IconButton
                                style={{ marginLeft: 20 }}
                                aria-label="Change Demo status"
                                size="small"
                                onClick={() => { activateDemoObject(rows?.row) }}
                                className="btnAction"
                                sx={{ outline: 'none', border: 'none' }}
                            >
                                <Tooltip title={directions != 'rtl' ? "active or demo" : 'فعال او تجريبي'}>
                                    {
                                        rows?.row?.is_demo == 1 ?
                                            <CheckCircleIcon sx={{ color: '#111827' }} size={24} />
                                            :
                                            <UnpublishedIcon sx={{ color: red[500] }} size={24} />
                                    }
                                </Tooltip>
                            </IconButton>
                        }


                    </div> */}
                    
                        <MenuItem onClick={() => { updateObject(rows?.row) }} >
                            <ListItemIcon sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                margin:'0px 7px 0 0'

                            }}>
                                <EditIcon style={{ fill: "#111827" }} />
                            </ListItemIcon>
                            {directions != 'rtl' ? "Edit" : 'تعديل'}
                        </MenuItem>

                        <MenuItem onClick={() => { showRoles(rows?.row) }}>
                            <ListItemIcon sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                margin:'0px 7px 0 0'
                            }}>
                                <GrIcone.GrUserAdmin style={{ fill: GridTheme?.primaryColor, color: GridTheme?.primaryColor, }} />
                            </ListItemIcon>
                            {directions != 'rtl' ? "Show Roles" : 'عرض الصلاحيات'}
                        </MenuItem>

                        <MenuItem onClick={() => { showGroup___(rows?.row) }}>
                            <ListItemIcon sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                margin:'0px 7px 0 0'
                            }}>
                                <GroupAddRoundedIcon style={{ fill: GridTheme?.primaryColor, color: GridTheme?.primaryColor, }} />
                            </ListItemIcon>
                            {directions != 'rtl' ? "Show Groups" : 'عرض المجاميع'}
                        </MenuItem>

                        <MenuItem onClick={() => { showAccessGroup__(rows?.row) }}>
                            <ListItemIcon sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                margin:'0px 7px 0 0'
                            }}>
                                <EnhancedEncryptionIcon style={{ fill: GridTheme?.primaryColor, color: GridTheme?.primaryColor, }} />
                            </ListItemIcon>
                            {directions != 'rtl' ? "Show Access Groups" : 'عرض مجاميع الوصول'}
                        </MenuItem>

                        <MenuItem onClick={() => { showAssignShiftsForUser(rows?.row) }}>
                            <ListItemIcon sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                margin:'0px 7px 0 0'
                            }}>
                                <CompareArrowsIcon style={{ fill: GridTheme?.primaryColor, color: GridTheme?.primaryColor, }} />
                            </ListItemIcon>
                            {directions != 'rtl' ? "Assign Shifts" : 'اسناد وجبات'}
                        </MenuItem>

                        {/* <MenuItem onClick={() => { showAccessCompanies(rows?.row) }}>
                            <ListItemIcon sx={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <DomainDisabledIcon sx={{ color: GridTheme?.primaryColor }} />
                            </ListItemIcon>
                            {directions != 'rtl' ? "show companies" : 'عرض الشركات'}
                        </MenuItem> */}

                        <MenuItem onClick={() => { deleteObject(rows?.row) }}>
                            <ListItemIcon sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                margin:'0px 7px 0 0'
                            }}>
                                <DeleteIcon sx={{ color: red[500] }} />
                            </ListItemIcon>
                            {directions != 'rtl' ? "Delete" : 'حذف'}
                        </MenuItem>
                        {roles?.includes(parseInt(process.env.REACT_APP_ACTIVATE_USER)) &&
                            <MenuItem onClick={() => { activateObject(rows?.row) }}>
                                <ListItemIcon sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    margin:'0px 7px 0 0'
                                }}>
                                    {
                                        rows?.row?.active == 1 ?
                                            <CheckCircleIcon sx={{ color: '#111827' }} size={24} />
                                            :
                                            <UnpublishedIcon sx={{ color: red[500] }} size={24} />

                                    }
                                </ListItemIcon>
                                {directions != 'rtl' ? "Active or Disabled" : 'نشط او غير نشط'}
                            </MenuItem>
                        }
                        {roles?.includes(parseInt(process.env.REACT_APP_ACTIVATE_DEMO_USER)) &&
                            <MenuItem onClick={() => { activateDemoObject(rows?.row) }}>
                                <ListItemIcon sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    margin:'0px 7px 0 0'
                                }}>
                                    {
                                        rows?.row?.is_demo == 1 ?
                                            <CheckCircleIcon sx={{ color: '#111827' }} size={24} />
                                            :
                                            <UnpublishedIcon sx={{ color: red[500] }} size={24} />
                                    }
                                </ListItemIcon>
                                {directions != 'rtl' ? "Active or Demo" : 'فعال او تجريبي'}
                            </MenuItem>

                        }
                </DropDownGrid>
            ),
        },
    ]

    useEffect(()=>{
        fetchData('DEPARTMENTS','departments_list')
        fetchData('COMPANYS','companies_list')
    },[])
    const handlePageChange = (newPage) => {
        setpageNumber(newPage + 1)
    }

   const showAssignShiftsForUser=(object)=>{
        setselectedUser(object)
        setShowAssignShiftTouser(true)
    }
    const fetchDataResources = async (params) => {
        setloading(true)
        console.log('userseruser==>',pageNumber)
        try {
            const result = await axios({
                url: Host + "users",
                params: {
                    ...params,
                    page: pageNumber,
                    page_size: pageSize,
                   
                },
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                },

            })
            if (result && result.status == 200) {
                const users = result.data;
                setData({
                    users: users
                });



            } else if (result.data.status === false) {
                toast.error("Error")
            }
        } catch (error) {
            const responseResult = error.response
            if (responseResult && responseResult.status === 400) {
                if (typeof responseResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key) => {

                        responseResult.data.data[key].map((message) => {
                            toast.error(message)
                        })
                    })
                }
                else {
                    toast.error(responseResult.data.data)
                }

            }
            else if (responseResult && responseResult.status === 401) {
                toast.error(responseResult.data.data)
                cookies.remove("token");
                // window.location.href = "/"
                navigate('/');
            }
            else {
                toast.error("network error")
            }
        }
        setloading(false)

    };
    const updateObject = async (object) => {

        setselectedUser(object)
        setshowUserForm(true)
    };
    const showRoles = (object) => {
        setselectedUser(object)
        setshowUserRoleForm(true)
    };
    const showGroup___=(object)=>{
        console.log('done-1');
        setselectedUser(object)
        setShowUserGroup(true)
    }

    const showAccessGroup__=(object)=>{
        setselectedUser(object)
        setShowUseAccessrGroup(true)
    }

    const deleteObject = async (object) => {
        if (!window.confirm("Delete the item?")) {
            return
        }
        setloading(true)
        try {
            const result = await axios({
                url: Host + "users/" + object.id,
                method: "delete",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                }

            })
            if (result && result.status == 200) {
                toast.error(result.data.data)
                fetchDataResources();
            } else if (result.data.status === false) {
                toast.error("Error")
            }
        } catch (error) {
            const responseResult = error.response
            if (responseResult && responseResult.status === 400) {
                if (typeof responseResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key) => {

                        responseResult.data.data[key].map((message) => {
                            toast.error(message)
                        })
                    })
                }
                else {
                    toast.error(responseResult.data.data)
                }

            }
            else if (responseResult && responseResult.status === 401) {
                toast.error(responseResult.data.data)
                cookies.remove("token");
                // window.location.href = "/"
                navigate('/');

            }
            else {
                toast.error("network error")
            }
        }
        setloading(false)
    };
    const activateObject = async (object) => {
        setloading(true)
        try {
            const result = await axios({
                url: Host + "users/activate",
                method: "post",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                },
                data: {
                    user_id: object.id,
                    active: object.active == 1 ? 0 : 1
                }

            })
            if (result && result.status == 200) {
                fetchDataResources();
            } else if (result.data.status === false) {
                toast.error("Error")
            }
        } catch (error) {
            const responseResult = error.response
            if (responseResult && responseResult.status === 400) {
                if (typeof responseResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key) => {

                        responseResult.data.data[key].map((message) => {
                            toast.error(message)
                        })
                    })
                }
                else {
                    toast.error(responseResult.data.data)
                }

            }
            else if (responseResult && responseResult.status === 401) {
                toast.error(responseResult.data.data)
                cookies.remove("token");
                // window.location.href = "/"
                navigate('/');

            }
            else {
                toast.error("network error")
            }
        }
        setloading(false)
    };
    const activateDemoObject = async (object) => {
        setloading(true)
        try {
            const result = await axios({
                url: Host + "users/activate_demo",
                method: "post",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                },
                data: {
                    user_id: object.id,
                    is_demo: object.is_demo == 1 ? 0 : 1
                }

            })
            if (result && result.status == 200) {
                fetchDataResources();
            } else if (result.data.status === false) {
                toast.error("Error")
            }
        } catch (error) {
            const responseResult = error.response
            if (responseResult && responseResult.status === 400) {
                if (typeof responseResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key) => {

                        responseResult.data.data[key].map((message) => {
                            toast.error(message)
                        })
                    })
                }
                else {
                    toast.error(responseResult.data.data)
                }

            }
            else if (responseResult && responseResult.status === 401) {
                toast.error(responseResult.data.data)
                cookies.remove("token");
                // window.location.href = "/"
                navigate('/');

            }
            else {
                toast.error("network error")
            }
        }
        setloading(false)
    };
    const createObject = async (object) => {
        setselectedUser(null)
        setshowUserForm(true)
    };

    const handleSetshowUserForm = (openStatus, needsReload) => {
        if (needsReload)
            fetchDataResources();
        setshowUserForm(openStatus)
    };

    const handleSetshowUserRoleForm = (openStatus, needsReload) => {
        if (needsReload)
            fetchDataResources();
        setshowUserRoleForm(openStatus)
    };
    const handleSetshowUserAccessGroup = (openStatus, needsReload) => {
        if (needsReload)
            fetchDataResources();
        setShowUseAccessrGroup(openStatus)
    };

    const handleSetShowAssignShiftsToUsers = (openStatus, needsReload) => {
        if (needsReload)
            fetchDataResources();
            setShowAssignShiftTouser(openStatus)
    };

    const handleSetshowUserGroup = (openStatus, needsReload) => {
        if (needsReload)
            fetchDataResources();
        setShowUserGroup(openStatus)
    };

    useEffect(() => {
        // if(token)
        fetchDataResources();
    }, [pageNumber, pageSize,token])

    useEffect(() => {
        if (state && state.users && state.users && state.users?.data?.length > 0) {
            setRow(state?.users?.data);
            setUserTotals(state?.users?.total)
            setUserNumberOfPage(state?.users?.last_page)
        }else{
            setRow([]);
            setUserTotals(0)
            setUserNumberOfPage(0)
        }
    }, [state.users])

    const createFunction=()=>{
        createObject()
    }
    const advanceSearch =()=>{
        fetchDataResources({
            username:SearchUserName,
            full_name:SearchUserFullName,
            company_id:SearchCompanyId?.id,
            department_id:SearchDepartmentId?.id
        });
    }
    const clearSearchFilter=()=>{
        setSearchUserName('')
        setSearchUserFullName('')
        setSearchCompanyId('')
        setSearchDepartmentID('')
    }

    return (
        <>
            {showUserForm ?
                <UserForm user={selectedUser} showUserForm={showUserForm} setShowUserForm={handleSetshowUserForm} />
                : null}
            {showUserRoleForm ?
                <UserRoleForm user={selectedUser} showUserRoleForm={showUserRoleForm} setshowUserRoleForm={handleSetshowUserRoleForm} />
                : null}
            {
                showUserGroup?
                <UserGroup user={selectedUser} showUserGroup={showUserGroup} setShowUserGroup={handleSetshowUserGroup} />
                :null
            }

            {
                showAccessGroup?
                <UserAccessGroup user={selectedUser} showAccessGroup={showAccessGroup} setShowUseAccessrGroup={handleSetshowUserAccessGroup} />
                :null
            }
            {
                showAssignShiftTouser?
                <UserShifts user={selectedUser} showAccessGroup={showAssignShiftTouser} setShowUseAccessrGroup={handleSetShowAssignShiftsToUsers} />
                :null
            }


           

            {
            !filterSearchBoxMode?
            <FilterBox 
                resultLength={userTotals} 
                // exportFunction={ExportFunction} 
                createFunction={createFunction}
                hasExport={false}  
                hasCreate={true}  
                hasMaps={false}  
                hasImport={false}
                customeHeight={screenWidth<=1100?'300px':"200px"}
            >
                    <SearchField1 marginLeftValue={0} customeWidth={screenWidth<=1100?'98%':"48%"}   state={SearchUserName} setState={setSearchUserName} fieldLabel={t('GLOBAL.SEARCH_USER_NAME')} />
                    <SearchField1 marginLeftValue={0} customeWidth={screenWidth<=1100?'98%':"48%"}   state={SearchUserFullName} setState={setSearchUserFullName} fieldLabel={t('GLOBAL.SEARCH_USER_FULL_NAME')} />
             
                    <Autocomplete
                        sx={{ ml: 1.5,width:screenWidth<=1100?'98%':'48%',mt:1 }}
                            fullWidth
                            options={Companys}
                            getOptionLabel={(option) => option.company_name || ""}
                            id="disable-close-on-select"
                            value={SearchCompanyId}
                            onChange={(e, v, r) => { setSearchCompanyId(v ? v : '') }}
                            renderInput={(params) => (
                                <TextField {...params} label={t('GLOBAL.SEARCH_COMPANY')} variant="standard" sx={{borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}} />
                        )}
                    />
                    <Autocomplete
                        sx={{ ml: 1.5,width:screenWidth<=1100?'98%':'48%',mt:1,paddingLeft:0 }}
                        // fullWidth
                        options={Departments}
                        getOptionLabel={(option) => option.name || ""}
                        id="disable-close-on-select"
                        value={SearchDepartmentId}
                        onChange={(e, v, r) => { setSearchDepartmentID(v ? v : ''); }}
                        renderInput={(params) => (
                            <TextField {...params} label={t('GLOBAL.SEARCH_DEPARTMENT')} variant="standard" sx={{width:'100%',borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}} />
                        )}
                    />
                 
                 
                    <Box sx={{display:'flex',justifyContent: 'end',alignItems: 'center',width:'100%'}}>
                    <Box display="flex" >
                            <Button
                                variant="contained"
                                spacing={2} 
                                sx={{ flexGrow: 1, margin: 1,backgroundColor:SearchButtonTheme?.search_button_color,color:SearchButtonTheme?.search_button_text_color,
                                    '&:hover':{
                                        backgroundColor:SearchButtonTheme?.search_button_color+'88',
                                    },
                                    height:FilterButtonHeight
                                }}
                                className="iconeFilterSearch"
                                onClick={() => advanceSearch()}
                                 >
                                {t('GLOBAL.SEARCH_SEARCH_BUTTON')}
                            </Button>
                            <Button variant="outlined"
                                spacing={2}
                                sx={{
                                        flexGrow: 1, margin: 1,
                                        // backgroundColor:SearchButtonTheme?.clear_button_color,
                                        color:SearchButtonTheme?.clear_button_text_color,
                                    '&:hover':{
                                        backgroundColor:`${SearchButtonTheme?.clear_button_color}   !important`,
                                    },
                                    height:FilterButtonHeight
                                }}
                                className="iconeFilterClear"
                                color="secondary"
                                onClick={clearSearchFilter} 
                                >
                                {t('GLOBAL.SEARCH_CLEAR_BUTTON')}
                            </Button>
                    </Box>
                   </Box>
                
            
            </FilterBox>:null
            }

           
            <Box 
             sx={{
                display:'flex',
                justifyContent: 'start',
                alignItems: 'start',
                width:'100%',
             }}
            >
                <Box
                    sx={{
                    width:'100%',
                    }}
                >
                    <Grid
                        rows={rows}
                        columns={columns}
                        rowTotals={userTotals}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        pageNumber={pageNumber}
                        setPageNumber={setpageNumber}
                        loading={loading}
                        handlePageChange={handlePageChange}
                        NumberOfPage={userNumberOfPage}
                        directions={directions}
                        hasCreate={false}
                        createObject={createObject}
                        title={t('USERS.HEADER_TITLE')}
                        //   title_ar="قائمة المستخدمين"
                        hasFooter={false}
                        gridHeight={{
                            height: rows?.length > 5 ? pageSize == 5 ? 400 : pageSize == 10 ? 650 : 950 : 400,
                        }}
                    //   paginationType="client"

                    />
                </Box>
                {
                filterSearchBoxMode?
                <CustomeFilter
                //    exportFunction={ExportFunction} 
                   createFunction={createFunction}
                   hasExport={false}  
                   hasCreate={true}  
                   hasMaps={false}  
                   hasImport={false}
                   resultLength={userTotals} 
                //    customeHeight={screenWidth<=1100?'460px':"350px"}
                >
                    <Box
                     sx={{
                        width:'100%',
                        height:'400px',
                        minHeight:'400px',
                        maxHeight:'400px',
                        overflowY:'scroll',
                        overflowX:'hidden',
                        padding:'10px',
                        marginTop:'10px',
                        paddingTop:'20px',

                        // boxShadow:'0 0 7px -5px #000 inset'
                     }}
                    >
                        <SearchFieldV2 marginLeftValue={0.5}   customeWidth={screenWidth<=1100?'100%':"100%"}   state={SearchUserName} setState={setSearchUserName} fieldLabel={t('GLOBAL.SEARCH_USER_NAME')} />
                        <SearchFieldV2 marginLeftValue={0.5}   customeWidth={screenWidth<=1100?'100%':"100%"}   state={SearchUserFullName} setState={setSearchUserFullName} fieldLabel={t('GLOBAL.SEARCH_USER_FULL_NAME')} />

                        <Autocomplete
                            sx={{ ml: 1,margin:'10px 2px !important',width:screenWidth<=1100?'100%':'100%' }}
                                fullWidth
                                options={Companys}
                                getOptionLabel={(option) => option.name || ""}
                                id="disable-close-on-select"
                                value={SearchCompanyId}
                                onChange={(e, v, r) => { setSearchCompanyId(v ? v : '') }}
                                renderInput={(params) => (
                                    <TextField {...params} placeholder={t('GLOBAL.SEARCH_COMPANY')} variant="standard" sx={{borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}} />
                            )}
                        />
                        <Autocomplete
                            sx={{ ml:0.5,mt:1.2,mb:1,width:screenWidth<=1100?'100%':'100%' }}
                            // fullWidth
                            options={Departments}
                            getOptionLabel={(option) => option.full_name || ""}
                            id="disable-close-on-select"
                            value={SearchDepartmentId}
                            onChange={(e, v, r) => { setSearchDepartmentID(v ? v : ''); }}
                            renderInput={(params) => (
                                <TextField {...params} placeholder={t('GLOBAL.SEARCH_DEPARTMENT')} variant="standard" sx={{width:'100%',borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}} />
                            )}
                        />
                     
                    </Box>
                    <Box sx={{display:'flex',justifyContent: 'end',alignItems: 'center',width:'100%'}}>
                        <Box display="flex" >
                                <Button
                                    variant="contained"
                                    // spacing={2} 
                                    sx={{ 
                                        // flexGrow: 1,
                                        margin: 1,
                                        width:'80px !important',
                                        minWidth:'80px !important',
                                        maxWidth:'80px !important',
                                        backgroundColor:SearchButtonTheme?.search_button_color,color:SearchButtonTheme?.search_button_text_color,
                                        '&:hover':{
                                            backgroundColor:SearchButtonTheme?.search_button_color+'88',
                                        },
                                        height:FilterButtonHeight-10
                                    }}
                                    className="iconeFilterSearch"
                                    onClick={() => advanceSearch()} 
                                    >
                                    {t('GLOBAL.SEARCH_SEARCH_BUTTON')}
                                </Button>
                                <Button variant="outlined"
                                    // spacing={2}
                                    sx={{
                                        width:'50% !important',
                                            // flexGrow: 1, 
                                            minWidth:'80px !important',
                                            maxWidth:'80px !important',
                                            margin: 1,
                                            // backgroundColor:SearchButtonTheme?.clear_button_color,
                                            color:SearchButtonTheme?.clear_button_text_color,
                                        '&:hover':{
                                            backgroundColor:`${SearchButtonTheme?.clear_button_color}   !important`,
                                        },
                                        height:FilterButtonHeight-10
                                    }}
                                    className="iconeFilterClear"
                                    color="secondary"
                                    onClick={clearSearchFilter}
                                     >
                                    {t('GLOBAL.SEARCH_CLEAR_BUTTON')}
                                </Button>
                        </Box>
                    </Box>
                </CustomeFilter>:null
                }
                
            </Box>




        </>
    );
}

export default Users;


