import React,{useEffect,useState} from "react";
import './ErrorPage.css'
import {Link} from 'react-router-dom'
import {styled} from '@mui/material/styles'
import { useSelector } from "react-redux";

import {useTranslation} from "react-i18next";
const ContainerRedirectPage=styled('div')(({theme,SideMenuOpen,screenWidth,SearchButtonTheme})=>({
  position: 'relative',
  top:'-60px',
  left:SideMenuOpen?screenWidth>767?'-170px':'50px':'-30px',
  // left:'-30px',
  width: '100vw',
  height:'100vh',
  '& .button_back':{
    fontFamily: "'Quicksand', sans-serif",
    fontSize: "14px",
    textDecoration: "none;",
    textTransform: "uppercase",
    background:SearchButtonTheme?.go_back_button_color,
    display: "inline-block",
    padding:" 15px 30px;",
    borderRadius: "5px;",
    color:SearchButtonTheme?.go_back_button_text_color,
    fontWeight: 700,
    marginTop:" 20px",
  },
}))

export default function ErrorPage({Code,title,RedirectPage,ErrorMessage}) {
  const SideMenuOpen= useSelector(state => state.settingsData.SideMenuOpen)
  const [screenWidth,setScreenWidth]=useState(window.innerWidth)
  const {t, i18n} = useTranslation('common');
  const SearchButtonTheme=useSelector(state=> state.settingsData.SearchButtonTheme);

  useEffect(() => {
    window.addEventListener('resize',()=>{
       setScreenWidth(window.innerWidth)
    })

  }, []);
  return (
    <ContainerRedirectPage className="containerRedirectPage" SideMenuOpen={SideMenuOpen} screenWidth={screenWidth} SearchButtonTheme={SearchButtonTheme}>
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-bg">
            <div></div>
            <div></div>
            <div></div>
          </div>
          <h1>oops!</h1>
          <h2>{ErrorMessage} {Code} : {title}</h2>
          <Link to={RedirectPage} className="button_back">{t('ERROR_PAGE.Go_BACK_BUTTON')}</Link>
        </div>
      </div>
    </ContainerRedirectPage>
  );
}
