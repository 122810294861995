import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
  name: 'forceReload',
  initialState: {
    value: false,
  },
  reducers: {
    
    setForceReload: (state, action) => {
      state.value =action.payload ? action.payload : false
    }
  },
});

export const {setForceReload } = slice.actions;


// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.forceReload.value)`
export const selectForceReload = state => state.forceReload.value;

export default slice.reducer;
