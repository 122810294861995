import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { Pane, Dialog,FilePicker } from 'evergreen-ui';
import axios from "axios";
import Select from "react-select";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import {useSelector,useDIspatch} from 'react-redux'
import InputFile from "../Share/InputFile";
import DialogForm from '../Share/DialogForm'
import FooterBottons from "../Share/FooterBottons";
import {
    ContainerOfForm,
    SaveButton,
    CancelButton,
    Form
} from '../Users/StyledComponents';
import {Box,Button} from '@mui/material'
import {useTranslation} from "react-i18next";
import RegionSampleFile from '../../../assets/csv/region_import_template.csv'
const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"
export const RegionImportForm = (props) => {
    const [loading, setloading] = useState(false);
    const [object,setobject] = useState(null)
    const [t, i18n] = useTranslation('common');
    const token = cookies.get("token")
    const MainTheme=useSelector(state=> state.settingsData.MainTheme)
    const submitForm = async (file) => {
        
        try {
            if(!file)
            {
                toast.error("please select a file")
                return;
            }
            setloading(true)
            let formdata = new FormData();

            formdata.append("uploaded_excel_file", file)
            console.log(formdata);
                const resourcesResult = await axios({
                    url: Host + "import_regions_excel",
                    method: "post",
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": `Bearer ${token}`,
                        Accept: "application/json",
                        'X-localization': language,
                    },
                    data: formdata
                })
                console.log(resourcesResult);
                if (resourcesResult) {
                    if (resourcesResult.status === 200) {
                        toast.success(resourcesResult.data.data)
                        props.setshowRegionImportForm(false,true)
                    } else if (resourcesResult.data.status === false) {
                        props.setshowRegionImportForm(true,false)
                    }
                }
                else {
                    toast.error("Error")
                    props.setshowRegionImportForm(true,false)
                }
                setloading(false)
          
        } catch (error) {
            setloading(false)
            console.log(error);
            props.setshowRegionImportForm(true,false)
            const resourcesResult=error.response            
            if (resourcesResult && resourcesResult.status === 400) {
                console.log(resourcesResult);
                if(typeof resourcesResult.data.data === "object")
                {
                    const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                    errorMessagesKeys.map((key)=>{
                    
                        resourcesResult.data.data[key].map((message)=>{
                            toast.error(message)
                        })
                    })
                }
                else{
                    
                    toast.error(resourcesResult.data.data)
                }
                
                
            }
            else if(resourcesResult && resourcesResult.status === 401) {
             
                toast.error(resourcesResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else
            {
              
                toast.error("network error")
            }
        }
        
    };


    const onFileChange = (fileList)=>{
        
        if(fileList && fileList.length>0)
        {
            setobject(fileList[0])
        }

    }
    useEffect(() => {
        
      

        
        return () => {

        };
    }, [loading,props.showRegionImportForm]);


    return (
        
        <DialogForm
            open={props.showRegionImportForm}
            close={props.setshowRegionImportForm}
            CustomeWidth="50%"
            CustomeHeight="fit-content"
        >
            {/* <h4>Import Regions With Geofence</h4> */}
            {
            // loading ? <div>loading ...</div> :
            // <form >
            //     <div className="form-group">
            //         <label htmlFor="file">Select CSV file </label>
            //         <FilePicker onChange={(e)=>onFileChange(e)} name="file" width={350} height={24} />
            //     </div>
                
            // </form>
            }
            <InputFile object={object} setObject={setobject} submitForm={submitForm} loading={loading} onFileChange={onFileChange} title={t('GLOBAL.IMPORT_FORM_TITLE_WITH_GEOFENCE')} subTitle={t('GLOBAL.IMPORT_FORM_MESSAGES')}/>
            <Box 
               sx={{display:'flex',justifyContent: 'end',alignItems:'center'}}
            >
            
                <a href={RegionSampleFile} target="_blank" download>
                    <Button variant="Sample"  sx={{
                        padding:'10px 7px',
                        borderRadius:'10px',
                        textTransform:'lowercase',
                        backgroundColor:MainTheme?.secondaryColor,color:MainTheme?.oppositeSecondaryColor,
                        '&:hover':{
                        backgroundColor:MainTheme?.secondaryColor,color:MainTheme?.oppositeSecondaryColor+'33',
                        },
                    }}>{t('GLOBAL.IMPORT_POINT_SAMPLE')}</Button>
                </a>
                <FooterBottons close={props.setshowRegionImportForm} submitForm={submitForm} hiddenSave={true} />
            </Box>
                
        </DialogForm>

    );
};
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        color: state.isSelected ? 'red' : 'blue',

    }),
    control: () => ({
        // none of react-select's styles are passed to <Control />
        width: '100%',
        border: '1px solid #ababab',
        borderRadius: 5,
        padding: 0,
        display: 'flex'
    }),
    container: () => ({

        width: '80%',
        position: 'relative',
        boxSizing: 'border-box',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    }
}

export default RegionImportForm;
