import React from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled, useTheme } from '@mui/material/styles';
import {useSelector,useDispatch} from 'react-redux'

const StyledPaper=styled(Paper)(({theme,MainTheme})=>({
    width:'100% !important',
    height:'100% !important',
    padding:'10px',
    backgroundColor:MainTheme?.paperColor,
    color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
    overflow:'hidden',
}))

const  CustomePaper=(props)=> {
    const MainTheme=useSelector(state=>state.settingsData.MainTheme);

  return (
    <StyledPaper elevation={0} MainTheme={MainTheme} >
        {
            props?.children
        }
    </StyledPaper>
  )
}

export default CustomePaper;
