import React,{useState,useEffect,useMemo,useCallback,useRef}from 'react'
import {Box,Typography,TextField,Button} from '@mui/material'
import {styled} from '@mui/material/styles'
import {useSelector,useDispatch} from 'react-redux'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import {useTranslation} from "react-i18next";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useGlobalContext } from '../../../../context'
import moment from 'moment'
import Cookies from "universal-cookie";
import axios from "axios";
import Host from "../../../../assets/js/Host";
import { toast } from 'react-toastify';
import {useNavigate} from 'react-router-dom'
import BarCharts from './BarCharts';
import DoughnutChart2 from './DoughnutChart2';
import helpers from '../../../../assets/js/helper';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"

const TopFilterSelections=styled(Box)(({theme,MainTheme})=>({

}))

const ContentOfChart=styled(Box)(({theme,MainTheme})=>({
    width:'100%',
    height:'100%',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    flex:1,
    position:'relative',
    "& .muirtl-348fk2,& svg g text,& svg g text tspan":{
        color:MainTheme?.oppositePrimaryColor+' !important',
        fill:`${MainTheme?.oppositePrimaryColor} !important`,
        stroke:`${MainTheme?.oppositePrimaryColor} !important`,

    },
    '& ,& svg g path':{
        color:'#d1cfcf !important',
        fill:`#d1cfcf !important`,
        stroke:`#d1cfcf !important`,
    },
    '& .dxc-legend':{
        transform:'scale(1.1)'
    }


}))

export default function ProductivityChart() {
    const { data,users, exportToCSV, district, company, districts, companies, clearPointsAdvanceSearchFields, setCompany, setDistrict, fetchData, deleteObject } = useGlobalContext()
    const MainTheme=useSelector(state=>state.settingsData.MainTheme)
    const SideMenuOpen = useSelector(state => state.settingsData.SideMenuOpen)
    const [forceReloadChart,setForceReloadChart]=useState(false)
    const [loading,setLoading]=useState(false)
    const {t, i18n} = useTranslation('common');
    const drawerWidth=useSelector(state=>state.settingsData.drawerWidth)
    const screenWidth=useSelector(state=>state.settingsData.screenWidth)
    const token = cookies.get("token")
    let CurrentDate = new Date();
    // date_shiftWeek.setDate(date_shiftWeek.getDate() - 30);
    const firstDay = new Date(CurrentDate.getFullYear(), CurrentDate.getMonth(), 1);
    const [fromDate, setFromDate] =useState(firstDay);
    const [toDate, setToDate] =useState(new Date());
    const [selectUser,setSelectUser]=useState(0)
    const [productivityData,setProductivityData]=useState([])
    const [userName,setUserName]=useState('')
    const navigate=useNavigate()

    
    const getResource=async(id)=>{
        setLoading(true)
        let create_by=id
       
        try {
            const result = await axios({
                url: Host + "productivity_chart",
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                },
                params: {
                    creation_date_from:helpers?.formatDate(fromDate),
                    creation_date_to:helpers?.formatDate(toDate),
                    created_by:create_by
                },
            })
            if (result && result.status == 200) {
             
                  
                    // var arr=[];
                    // if(props?.productivityDat){
                    //     props?.productivityData?.map((itm)=>{
                            
                    //         arr.push({
                    //             day:itm?.date,
                    //             Visit_Time_Percentage:itm?.visit_time_percentage,
                    //             Road_Time_Percentage:itm?.road_time_percentage
                    //         })
                    //     })
                    // }
                setProductivityData(result?.data)
                setLoading(true)
            } else if (result.data.status === false) {
                toast.error("Error")
            }
        } catch (error) {
            const responseResult = error.response
            if (responseResult && responseResult.status === 400) {
                if (typeof responseResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key) => {
  
                        responseResult.data.data[key].map((message) => {
                            toast.error(message)
                        })
                    })
                }
                else {
                    toast.error(responseResult.data.data)
                }
  
            }
            else if (responseResult && responseResult.status === 401) {
                toast.error(responseResult.data.data)
                cookies.remove("token");
                // window.location.href = "/"
                navigate('/');
            }
            else {
                toast.error("network error")
            }
        }
        setLoading(false)
    }
    const submit=async()=>{
        setLoading(true)
        let create_by=selectUser
            // if(id){
            //     create_by=id
            // }
        try {
            const result = await axios({
                url: Host + "productivity_chart",
                method: "get",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                },
                params: {
                    creation_date_from:helpers?.formatDate(fromDate),
                    creation_date_to:helpers?.formatDate(toDate),
                    created_by:create_by
                },
            })
            if (result && result.status == 200) {
             

                setProductivityData(result?.data)
                setLoading(true)
            } else if (result.data.status === false) {
                toast.error("Error")
            }
        } catch (error) {
            const responseResult = error.response
            if (responseResult && responseResult.status === 400) {
                if (typeof responseResult.data.data === "object") {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key) => {
  
                        responseResult.data.data[key].map((message) => {
                            toast.error(message)
                        })
                    })
                }
                else {
                    toast.error(responseResult.data.data)
                }
  
            }
            else if (responseResult && responseResult.status === 401) {
                toast.error(responseResult.data.data)
                cookies.remove("token");
                // window.location.href = "/"
                navigate('/');
            }
            else {
                toast.error("network error")
            }
        }
        setLoading(false)
    }
    useEffect(()=>{
        fetchData('USERS','users_list')
    },[])

    useEffect(()=>{
        if(users&&users?.length>0)
         {
            setSelectUser(users[0]?.id)
            setUserName(users[0]?.username)
            getResource(users[0]?.id);
         }
    },[users])


    const numberOfDays=useMemo(()=>{
        let today = new Date(toDate).toISOString().slice(0, 10)

        const startDate  = helpers.formatDate(fromDate);
        const endDate    = today;
        
        const diffInMs   = new Date(endDate) - new Date(startDate)
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        
        return diffInDays+1;
    },[fromDate,toDate])
 

  return (
    <Box
      sx={{
        // width:getWidthOfCharts,
        width:'100%',
      }}
    >
         <Box MainTheme={MainTheme}
            sx={{
                display:'flex',
                flexDirection:'row',
                alignItems:'center',
                justifyContent:'flex-end',
                width:'fit-content',
                padding:'10px',
                flex:0.5,
                backgroundColor:MainTheme?.primaryColor,
                color:MainTheme?.oppositePrimaryColor,
                boxShadow:'0 0 7px 1px #000',
                borderRadius:'5px',
                marginBottom:'20px',
                '& .MuiOutlinedInput-root':{
                    borderColor:`1px solid ${MainTheme?.oppositePrimaryColor} !important`,
                    color:MainTheme?.oppositePrimaryColor,
                },
                '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: MainTheme?.oppositePrimaryColor,
                },
                '&:hover fieldset': {
                    borderColor: MainTheme?.oppositePrimaryColor,
                },
                '&.Mui-focused fieldset': {
                    borderColor: MainTheme?.oppositePrimaryColor,
                },
                },
            
                '& .muirtl-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root:before,& .muirtl-1a1fmpi-MuiInputBase-root-MuiInput-root:before,& .muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root:before,& .muiltr-1ptx2yq-MuiInputBase-root-MuiInput-root:before':{
                borderColor:`${MainTheme?.oppositePrimaryColor} !important`,
                outlineColor:`${MainTheme?.oppositePrimaryColor} !important`,
                },
                '& .muiltr-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root:before,& .muirtl-1a1fmpi-MuiInputBase-root-MuiInput-root:hover:before, &.muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root:hover:before':{
                borderColor:`${MainTheme?.oppositePrimaryColor} !important`,
                outlineColor:`${MainTheme?.oppositePrimaryColor} !important`,
                },
                '& svg,& input,& p, & span,& .muiltr-1ptx2yq-MuiInputBase-root-MuiInput-root,& .muiltr-1c2i806-MuiFormLabel-root-MuiInputLabel-root,& .muirtl-1c2i806-MuiFormLabel-root-MuiInputLabel-root':{
                    color:`${MainTheme?.oppositePrimaryColor} !important`
                },
                '& .muiltr-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root,& .muirtl-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root,& .muirtl-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select':{
                    color:`${MainTheme?.oppositePrimaryColor} !important`,
                    display:'flex',
                    justifyContent:'start !important',
                },
                '& .searchButton, & .muirtl-ihk7tx-MuiButtonBase-root-MuiButton-root':{
                    backgroundColor:`${MainTheme?.secondaryColor} !important`,
                    color:`${MainTheme?.oppositeSecondaryColor} !important`,
                    padding:'5px 15px',
                },
                "& .muiltr-348fk2,& .muirtl-348fk2,& svg g text,& svg g text tspan":{
                    color:MainTheme?.oppositePrimaryColor+' !important',
                    fill:`${MainTheme?.oppositePrimaryColor} !important`,
                },
                '& .muirtl-1cccqvr.muirtl-1cccqvr.muirtl-1cccqvr':{
                    display:'flex',
                    justifyContent: 'start',
                },
                '& .muirtl-1vv4lmi::before,& .muiltr-1vv4lmi::before':{
                    borderColor:`${MainTheme?.oppositePrimaryColor} !important`,
                    outlineColor:`${MainTheme?.oppositePrimaryColor} !important`,
                },
            }}
            >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box
                        sx={{
                            display:'flex',
                            flexDirection:'row',
                            alignItems:'center',
                            justifyContent:'start',
                            margin:'10px 20px',
                        
                            '& .muirtl-1a1fmpi-MuiInputBase-root-MuiInput-root:before,& .muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root:before':{
                                borderColor:`${MainTheme?.oppositePrimaryColor} !important`,
                                outlineColor:`${MainTheme?.oppositePrimaryColor} !important`,
                            },
                            '& .muirtl-1a1fmpi-MuiInputBase-root-MuiInput-root:hover:before, &.muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root:hover:before':{
                                borderColor:`${MainTheme?.oppositePrimaryColor} !important`,
                                outlineColor:`${MainTheme?.oppositePrimaryColor} !important`,
                            },
                            "& .muirtl-348fk2,& svg g text,& svg g text tspan":{
                                color:MainTheme?.oppositePrimaryColor+' !important',
                                fill:`${MainTheme?.oppositePrimaryColor} !important`,
                            },
                            '& .muirtl-1cayd89::before,& .muiltr-1cayd89::before':{
                                borderColor:`${MainTheme?.oppositePrimaryColor} !important`,
                                outlineColor:`${MainTheme?.oppositePrimaryColor} !important`,
                            },
                            '& .muirtl-iz58su .muirtl-1cccqvr.muirtl-1cccqvr.muirtl-1cccqvr,& .muiltr-1cccqvr.muiltr-1cccqvr.muiltr-1cccqvr':{
                                color:MainTheme?.oppositePrimaryColor+' !important',
                                fill:`${MainTheme?.oppositePrimaryColor} !important`,
                            }
                        }}
                    >
                            <DesktopDatePicker
                            label={t('DASHBOARD.FROM_DATE')}
                            inputFormat="DD/MM/YYYY"
                            value={fromDate}
                            onChange={(date)=>{
                                setFromDate(date)
                            }}
                            renderInput={(params) => <TextField {...params}  variant='standard' sx={{color:MainTheme?.oppositePrimaryColor,margin:'0 15px'}}/>}
                            />
                            <Box sx={{width:'5%',position:'relative',top:'10px'}}>{t('DASHBOARD.TO')}</Box>
                            <DesktopDatePicker
                            label={t('DASHBOARD.TO_DATE')}
                            inputFormat="DD/MM/YYYY"
                            value={toDate}
                            onChange={(date)=>{
                                setToDate(date)
                            }}
                            renderInput={(params) => <TextField {...params}  variant='standard' sx={{color:MainTheme?.oppositePrimaryColor,margin:'0 15px'}} />}
                            />
                            <Box sx={{width:'30%'}}>
                                    <FormControl fullWidth  variant="standard">
                                        <InputLabel id="demo-simple-select-standard-label">{t('DASHBOARD.SELECT_USER')}</InputLabel>
                                        <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={selectUser}
                                        label={t('DASHBOARD.SELECT_USER')}
                                        onChange={(e)=>{
                                            setSelectUser(e.target.value)
                                        }} 
                                        >
                                            {
                                                users&&users?.length>0&&users?.map((itm)=>(
                                                    <MenuItem 
                                                    key={itm?.id} 
                                                    value={itm?.id}
                                                    onClick={()=>{setUserName(itm?.username)}}
                                                    >
                                                        {itm?.username}
                                                    </MenuItem>

                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                            </Box>
                            <Box
                            sx={{
                                '& .searchButton':{
                                    backgroundColor:`${MainTheme?.secodaryColor} !important`,color:`${MainTheme?.oppositeSecondaryColor} !important`
                                }
                            }}
                            >
                                <Button 
                                onClick={submit}
                                className="searchButton" variant="outline" 
                                sx={{
                                    backgroundColor:`${MainTheme?.secodaryColor} !important`,color:`${MainTheme?.oppositeSecondaryColor} !important`, marginTop:'10px',
                                    marginLeft:'20px',
                                    outline:'none !important',
                                    border:'none  !important',
                                    }}>{t('DASHBOARD.SEARCH')}</Button>
                            </Box>
                        </Box>
                </LocalizationProvider>
        </Box>
        <Box
            sx={{
                backgroundColor:MainTheme?.primaryColor,
                color:MainTheme?.oppositePrimaryColor,
                boxShadow:`0 0 7px 1px #000`,
                height:'500px',
                margin:'10px 0',
                overflowX:'scroll',
                overflowY:'hidden',
                borderRadius:'5px',
                display:'flex',
                flexDirection:'column',
                justifyContent: 'start',
                alignItems: 'start',
                marginBottom:'20px',

            
                '& .MuiOutlinedInput-root':{
                    borderColor:`1px solid ${MainTheme?.oppositePrimaryColor} !important`,
                    color:MainTheme?.oppositePrimaryColor,
                },
                '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: MainTheme?.oppositePrimaryColor,
                },
                '&:hover fieldset': {
                    borderColor: MainTheme?.oppositePrimaryColor,
                },
                '&.Mui-focused fieldset': {
                    borderColor: MainTheme?.oppositePrimaryColor,
                },
                },
            
                '& .muirtl-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root:before,& .muirtl-1a1fmpi-MuiInputBase-root-MuiInput-root:before,& .muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root:before,& .muiltr-1ptx2yq-MuiInputBase-root-MuiInput-root:before':{
                borderColor:`${MainTheme?.oppositePrimaryColor} !important`,
                outlineColor:`${MainTheme?.oppositePrimaryColor} !important`,
                },
                '& .muiltr-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root:before,& .muirtl-1a1fmpi-MuiInputBase-root-MuiInput-root:hover:before, &.muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root:hover:before':{
                borderColor:`${MainTheme?.oppositePrimaryColor} !important`,
                outlineColor:`${MainTheme?.oppositePrimaryColor} !important`,
                },
                '& svg,& input,& p, & span,& .muiltr-1ptx2yq-MuiInputBase-root-MuiInput-root,& .muiltr-1c2i806-MuiFormLabel-root-MuiInputLabel-root,& .muirtl-1c2i806-MuiFormLabel-root-MuiInputLabel-root':{
                    color:`${MainTheme?.oppositePrimaryColor} !important`
                },
                '& .muiltr-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root,& .muirtl-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root,& .muirtl-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select':{
                    color:`${MainTheme?.oppositePrimaryColor} !important`,
                    display:'flex',
                    justifyContent:'start !important',
                },
                '& .searchButton, & .muirtl-ihk7tx-MuiButtonBase-root-MuiButton-root':{
                    backgroundColor:`${MainTheme?.secondaryColor} !important`,
                    color:`${MainTheme?.oppositeSecondaryColor} !important`,
                    padding:'5px 15px',
                },
                "& .muiltr-348fk2,& .muirtl-348fk2,& svg g text,& svg g text tspan":{
                    color:MainTheme?.oppositePrimaryColor+' !important',
                    fill:`${MainTheme?.oppositePrimaryColor} !important`,
                },
                '& .muirtl-1cccqvr.muirtl-1cccqvr.muirtl-1cccqvr':{
                    display:'flex',
                    justifyContent: 'start',
                },
                '& .muirtl-1vv4lmi::before,& .muiltr-1vv4lmi::before':{
                    borderColor:`${MainTheme?.oppositePrimaryColor} !important`,
                    outlineColor:`${MainTheme?.oppositePrimaryColor} !important`,
                },

            
            }}
        >
           
            
            <ContentOfChart MainTheme={MainTheme}>
                <Box 
                sx={{
                    position:'absolute',
                    left:'20px',
                    top:'10px'
                }}
                >
                    <Typography sx={{color:MainTheme?.oppositePrimaryColor,fontSize:'21px'}}>{t('DASHBOARD.text_show_productifity_percentage')+'  "'+userName+'" '}</Typography>
                </Box>

                {/* <Box sx={{width:'100%',margin:'10px 20px'}}> */}
                <BarCharts productivityData={productivityData} customeWidth="100%" customeHeight={'450'} boxTitle_1={t('DASHBOARD.VISIT_TIME_PECENTAGE')} boxTitle_2={t('DASHBOARD.ROAD_TIME_PECENTAGE')} numberOfDays={numberOfDays}  />
                {/* </Box> */}
            </ContentOfChart>
        </Box>
        <Box 
            sx={{
            width:screenWidth<=1150?'50%':screenWidth<=1300?"fit-content":'fit-content',
            height:'430px !important',
            margin:'10px 0',
            backgroundColor:MainTheme?.primaryColor,
            overflow:'hidden',
            boxShadow:`0 0 7px 1px #000`,
            borderRadius:'5px',
        }}>
                <Box 
                sx={{width:'100%',margin:'10px 20px',height:'100%'}}>
                    <DoughnutChart2 
                        productivityData={productivityData}  
                        customeWidth="100%"
                        title={t('DASHBOARD.PRODUCTIVITY_PIE_TITLE')} 
                        subTitle={t('DASHBOARD.PRODUCTIVITY_PIE_SUBTITLE')}
                        boxTitle_1={t('DASHBOARD.TOTAL_VISIT_TIME_PECENTAGE')}
                        boxTitle_2={t('DASHBOARD.TOTAL_ROAD_TIME_PECENTAGE')}
                        userName={userName}
                    />
                </Box>
        </Box>
    </Box>
  )
}



