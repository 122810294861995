
import React, { useEffect, useState } from 'react';
// import Pagination from '@mui/material/Pagination';

import Cookies from "universal-cookie";
import axios from "axios";
import Host from "../../../assets/js/Host";
import { toast } from 'react-toastify';
// import UserForm from './UserForm';
// import UserRoleForm from './UserRoleForm';

import IconButton from "@mui/material/IconButton";
// import Tooltip from '@mui/material/Tooltip';
import "../../../assets/css/DG.css"
import "../../../assets/css/AccordionStyle.css"
import { useSelector } from 'react-redux'
import red from '@mui/material/colors/red';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {useNavigate} from 'react-router-dom'
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import * as GrIcone from 'react-icons/gr'
import { useTranslation } from "react-i18next";
import DropDownGrid from '../Share/DropDownGrid'
import { MenuItem ,ListItemIcon} from '@mui/material';
import NotificationForm from './NotificationForm';
import Grid from '../Share/Grid'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import NotificationsUsers from './NotificationsUsers';
// import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import BasicDatePickerV2 from '../GeneralComponents/BasicDatePickerV2';
import BasicDatePicker from '../GeneralComponents/BasicDatePicker';

import SearchFieldV2 from '../GeneralComponents/SearchFieldV2'
import SearchField1 from '../GeneralComponents/SearchField1'

import CustomeFilter from '../Share/CustomeFilter'
import FilterBox from '../Share/FilterBox';
import { useGlobalContext } from '../../../context'
import {Autocomplete,TextField} from '@mui/material'
import helpers from '../../../assets/js/helper';


const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"
let roles = []

try {

    roles = cookies.get("roles")
} catch (error) {

}

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

export default function NotificationPage() {
  const { t, i18n } = useTranslation('common');
  const directions = useSelector(state => state.settingsData.directions)
  const GridTheme = useSelector(state => state.settingsData.GridTheme)
  const token = cookies.get("token")
  const {fromToDate,setFromToDate,notification_types,fetchData,Companys,users,clearPointsAdvanceSearchFields} = useGlobalContext()

  const [loading, setloading] = useState(false);
  const [state, setData] = useState({
      notifications: ''
  });

  const navigate = useNavigate();

  const Language = React.useMemo(() => {
      if (directions !== 'rtl') {
          return require('../../Settings/Translate_en.json').USERS
      } else {
          return require('../../Settings/Translate_ar.json').USERS
      }
  }, [directions])

  const filterSearchBoxMode = useSelector(state=> state.settingsData.filterSearchBoxMode)
  const FilterButtonHeight= useSelector(state => state.settingsData.FilterButtonHeight)
  const screenWidthRedux=useSelector(state=>state.settingsData.screenWidth)
  const SearchButtonTheme=useSelector(state=> state.settingsData.SearchButtonTheme);

  const [notificationsTotals, setNotificationsTotals] = useState(0)
  const [notificationsNumberOfPage, setnotificationsNumberOfPage] = useState(0)
  const [showNotificationForm,setShowNotificationForm]=useState(false)
  const [selectedNotification,setSelectedNotification]=useState({})
  const [showNotificationUsers,setShowNotificationUsers]=useState(false)
  const [pageSize, setPageSize] = useState(5);
  const [pageNumber, setpageNumber] = useState(1);
  const [rows, setRow] = useState([])
  const [NotificationTitle,setNotificationTitle]=useState('')
  const [NotificationBody,setNotificationBody]=useState('');
  const [NotificationTypeID,setNotificationTypeId]=useState(null)
  const [NotificationCreatedBy,setNotificationCreatedBy]=useState(null)
  const [NotificationCompanyID,setNotificationCompanyID]=useState(null)


  useEffect(()=>{
    fetchData('NOTIFICATION_TYPES','notification_types_list')
    fetchData('COMPANYS','companies_list')
    fetchData('USERS','users_list')

  },[])
  const columns = [
      {
          field: 'id', headerName: t("NOTIFICATIONS.GRID_ID"),minWidth:50,flex:1,
          renderCell: (rows) => (
              <div style={{ width: 'max-content' }} >
                  {rows?.row?.id}
              </div>
          )
      },
      {
          field: 'title', headerName: t("NOTIFICATIONS.GRID_TITLE"),minWidth:150,maxWidth:350,flex:1,
          renderCell: (rows) => (
              <div style={{ width: 'max-content',minWidth:150,maxWidth:350 }} className={`${directions == 'rtl' ? 'alignColumnRight' : 'alignColumnLeft'}`}>
                  {rows?.row?.title}
              </div>
          )
      },
      {
          field: 'body', headerName: t('NOTIFICATIONS.GRID_BODY'),minWidth:150,maxWidth:550,flex:1,
          renderCell: (rows) => (
              <div 
                style={{
                    whiteSpace: 'wrap', 
                    width: 'max-content',
                    minWidth:150,
                    maxWidth:350,
                    display: 'flex',
                    justifyContent: 'start',
                    flexWrap:'wrap',
                    wordWrap:'break-word',
                    wordBreak:'keep-all',
                    textAlign: directions=='rtl'?'right':'left' 
                    }} 
                    className={`${directions == 'rtl' ? 'alignColumnRight' : 'alignColumnLeft'}`}
               >
                <HtmlTooltip
                    title={
                    <Box sx={{display: 'flex',justifyContent:'end !important'}}>
                        <Typography sx={{display: 'flex',justifyContent:'end !important'}}>{rows?.row?.body}</Typography>
                        
                    </Box>
                    }
                >
                    <Typography >{rows?.row?.body}</Typography>
                </HtmlTooltip>
                  
              </div>
          )
      },
      {
          field: 'send_push_notification', headerName: t("NOTIFICATIONS.GRID_SEND_PUSH_NOTIFICATION"),minWidth:150,maxWidth:350,flex:1,
          renderCell: (rows) => (
              <div style={{ width: 'max-content',minWidth:150,maxWidth:350 }} className={`${directions == 'rtl' ? 'alignColumnRight' : 'alignColumnLeft'}`}>
                  {
                  rows?.row?.send_push_notification?
                    <Box 
                      sx={{
                        display:'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // backgroundColor:'green',
                        padding:'10px 20px',
                        borderRadius:'10px',
                        '& svg,& svg path':{
                            fill:`${GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperColor} !important`,
                            color:`${GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperColor} !important`
                          }

                        }}
                      >
                        <HtmlTooltip
                            title={
                            <Box sx={{display: 'flex',justifyContent:'end'}}>
                                <Typography sx={{display: 'flex',justifyContent:'end !important'}}>{t('NOTIFICATIONS.TOOLTIP_SEND_PUSH_NOTIFICATION')}</Typography>
                                
                            </Box>
                            }
                        >
                            <NotificationsActiveIcon size={16} sx={{ fontSize:"25px !important", fill:`${GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperColor} !important`,
                                '& svg,& svg path':{
                                    fill:`${GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperColor} !important`,
                                    color:`${GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperColor} !important`
                                }
                                }}
                            />
                       </HtmlTooltip>
                    </Box>:
                    <Box
                    sx={{
                      display:'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    //   backgroundColor:Mia,
                      padding:'10px 20px',
                      borderRadius:'10px',
                      '& svg,& svg path':{
                        fill:`red`,
                        color:`red`
                      }

                      }}
                    >
                          <HtmlTooltip
                            title={
                            <Box sx={{display: 'flex',justifyContent:'end'}}>
                                <Typography sx={{display: 'flex',justifyContent:'end !important'}}>{t('NOTIFICATIONS.TOOLTIP_NOT_SEND_PUSH_NOTIFICATION')}</Typography>
                                
                            </Box>
                            }
                        >
                            <NotificationImportantIcon size={16} sx={{
                                fontSize:"25px !important",
                                fill:`${GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperColor} !important`,
                                '& svg,& svg path':{
                                    fill:`#f00`,
                                    color:`${GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperColor} !important`
                                }
                                }}
                            />
                        </HtmlTooltip>
                    </Box>

                  }
              </div>
          )
      },
      {
          field: 'notification_type', headerName: t('NOTIFICATIONS.GRID_NOTIFICATION_TYPE'),minWidth:150,maxWidth:350,flex:1, renderCell: (rows) => (
              <div style={{ width: 'max-content',minWidth:150,maxWidth:350 }} className={`${directions == 'rtl' ? 'alignColumnRight' : 'alignColumnLeft'}`}>
                  {rows?.row?.notification_type?.name}
              </div>
          )
      },
      {
          field: 'users_count', headerName: t('NOTIFICATIONS.GRID_USER_COUNT'),minWidth:150,maxWidth:350,flex:1, renderCell: (rows) => (
              <div style={{ width: 'max-content',minWidth:150,maxWidth:350 }} className={`${directions == 'rtl' ? 'alignColumnRight' : 'alignColumnLeft'}`}>
                  {rows?.row?.users_count}
              </div>
          )
      },
      {
          field: 'sent_successfully', headerName: t('NOTIFICATIONS.GRID_SEND_SUCCESSFULLY'),minWidth:150,maxWidth:350,flex:1, renderCell: (rows) => (
              <div style={{ width: 'max-content',minWidth:150,maxWidth:350 }} className={`${directions == 'rtl' ? 'alignColumnRight' : 'alignColumnLeft'}`}>
                  {rows?.row?.sent_successfully == 1 ? "sent success" : " Send failed"}
              </div>
          )
      },
     


      {
          field: 'actions',flex:.6,minWidth:80,
          headerName: t("USERS.ACTIONS"),
          renderCell: (rows) => (
              <DropDownGrid>
                 
                  
                      <MenuItem onClick={() => { updateObject(rows?.row) }} >
                          <ListItemIcon sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              margin:'0px 7px 0 0'

                          }}>
                              <EditIcon style={{ fill: "#111827" }} />
                          </ListItemIcon>
                          {directions != 'rtl' ? "Edit" : 'تعديل'}
                      </MenuItem>

                      <MenuItem onClick={() => { openNotificationUsers(rows?.row) }}>
                          <ListItemIcon sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              margin:'0px 7px 0 0'
                          }}>
                              <PeopleOutlineIcon style={{ fill: GridTheme?.primaryColor, color: GridTheme?.primaryColor, }} />
                          </ListItemIcon>
                          {directions != 'rtl' ? "Show users" : 'عرض المستلمين'}
                      </MenuItem>

                      {/* <MenuItem onClick={() => { }}>
                          <ListItemIcon sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              margin:'0px 7px 0 0'
                          }}>
                              <DeleteIcon sx={{ color: red[500] }} />
                          </ListItemIcon>
                          {directions != 'rtl' ? "Delete" : 'حذف'}
                      </MenuItem> */}
                     
              </DropDownGrid>
          ),
      },
  ]

  const handlePageChange = (newPage) => {
      setpageNumber(newPage + 1)
  }

  const fetchDataRecource = async (params) => {
      setloading(true)
      try {
          const result = await axios({
              url: Host + "notifications",
              params: {
                  page: pageNumber,
                  page_size: pageSize,
                  ...params
              },
              method: "get",
              headers: {
                  "Authorization": `Bearer ${token}`,
                  Accept: "application/json",
                  'X-localization': language,
              },
            
          })
          if (result && result.status == 200) {
              const notifications = result.data;
              setData({
                notifications: notifications
              });



          } else if (result.data.status === false) {
              toast.error("Error")
          }
      } catch (error) {
          const responseResult = error.response
          if (responseResult && responseResult.status === 400) {
              if (typeof responseResult.data.data === "object") {
                  const errorMessagesKeys = Object.keys(responseResult.data.data);
                  errorMessagesKeys.map((key) => {

                      responseResult.data.data[key].map((message) => {
                          toast.error(message)
                      })
                  })
              }
              else {
                  toast.error(responseResult.data.data)
              }

          }
          else if (responseResult && responseResult.status === 401) {
              toast.error(responseResult.data.data)
              cookies.remove("token");
              // window.location.href = "/"
              navigate('/');
          }
          else {
              toast.error("network error")
          }
      }
      setloading(false)

  };
  const updateObject = async (object) => {
      setSelectedNotification(object)
      setShowNotificationForm(true)
  };
  // const showRoles = (object) => {
  //     setselectedUser(object)
  //     setShowNotificationForm(true)
  // };

  // const deleteObject = async (object) => {
  //     if (!window.confirm("Delete the item?")) {
  //         return
  //     }
  //     setloading(true)
  //     try {
  //         const result = await axios({
  //             url: Host + "users/" + object.id,
  //             method: "delete",
  //             headers: {
  //                 "Authorization": `Bearer ${token}`,
  //                 Accept: "application/json",
  //                 'X-localization': language,
  //             }

  //         })
  //         if (result && result.status == 200) {
  //             toast.error(result.data.data)
  //             fetchDataRecource();
  //         } else if (result.data.status === false) {
  //             toast.error("Error")
  //         }
  //     } catch (error) {
  //         const responseResult = error.response
  //         if (responseResult && responseResult.status === 400) {
  //             if (typeof responseResult.data.data === "object") {
  //                 const errorMessagesKeys = Object.keys(responseResult.data.data);
  //                 errorMessagesKeys.map((key) => {

  //                     responseResult.data.data[key].map((message) => {
  //                         toast.error(message)
  //                     })
  //                 })
  //             }
  //             else {
  //                 toast.error(responseResult.data.data)
  //             }

  //         }
  //         else if (responseResult && responseResult.status === 401) {
  //             toast.error(responseResult.data.data)
  //             cookies.remove("token");
  //             // window.location.href = "/"
  //             navigate('/');

  //         }
  //         else {
  //             toast.error("network error")
  //         }
  //     }
  //     setloading(false)
  // };
  const createObject = async (object) => {
      setSelectedNotification(null)
      setShowNotificationForm(true)
  };



  useEffect(() => {
      // if(token)
      fetchDataRecource();
  }, [pageNumber, pageSize,token])

  useEffect(() => {
      if (state && state.notifications && state.notifications && state.notifications?.data?.length > 0) {
          setRow(state?.notifications?.data);
          setNotificationsTotals(state?.notifications?.total)
          setnotificationsNumberOfPage(state?.notifications?.last_page)
      }else{
        setRow([]);
        setNotificationsTotals(0)
        setnotificationsNumberOfPage(0)
    }
  }, [state.notifications])

  const openNotificationUsers=(data)=>{
    setSelectedNotification(data)
    setShowNotificationUsers(true)
  }
  const closeNotificationUsers=()=>{
    setShowNotificationUsers(false)
  }

  const createFunction=()=>{
    createObject()
  }
  const addvancSearch=()=>{
      let object={}
    fetchDataRecource({
        title:NotificationTitle?NotificationTitle:null,
        body:NotificationBody?NotificationBody:null,
        notification_type_id:NotificationTypeID?NotificationTypeID?.id:null,
        created_by:NotificationCreatedBy?NotificationCreatedBy?.id:null,
        company_id:NotificationCompanyID?NotificationCompanyID?.id:null,
        creation_date_from:helpers.formatDate(new Date(fromToDate[0]))=='1970-01-01'?null:helpers.formatDate(new Date(fromToDate[0])),
        creation_date_to:helpers.formatDate(new Date(fromToDate[1]))=='1970-01-01'?null:helpers.formatDate(new Date(fromToDate[1])),
    })
  }

  const clearFunction=()=>{
    setNotificationTitle('')
    setNotificationBody('')
    setNotificationTypeId(null)
    setNotificationCreatedBy(null)
    setNotificationCompanyID(null)
    // clearPointsAdvanceSearchFields()
    setFromToDate([null,null])

  }
  

  return (
      <>
            {
             showNotificationForm ?
              <NotificationForm Notification={selectedNotification} fetchData={fetchDataRecource} showNotificationForm={showNotificationForm} setShowNotificationForm={setShowNotificationForm} />
              : null
            }
            
            <NotificationsUsers open={showNotificationUsers} Notification={selectedNotification} handleClose={closeNotificationUsers} fetchData={fetchDataRecource} showNotificationForm={showNotificationForm} setShowNotificationForm={setShowNotificationForm}/>
        


        

{
              !filterSearchBoxMode?
               <FilterBox 
                // exportFunction={ExportFunction}
                createFunction={createFunction}
                // mapFunction={mapFunction}
                // importFunction={importFunction}
                hasExport={false} 
                hasCreate={true} 
                hasMaps={false} 
                hasImport={false}
                resultLength={notificationsTotals}
                customeHeight={screenWidthRedux<=1100?"410px":"280px"}
                >
                        <SearchField1 marginLeftValue={1} customeWidth={screenWidthRedux<=1100?'100%':"48%"}  state={NotificationTitle} setState={setNotificationTitle} fieldLabel={t('GLOBAL.Search_Notification_Title')} />
                        <SearchField1 marginLeftValue={1}  customeWidth={screenWidthRedux<=1100?'100%':"48%"}  state={NotificationBody} setState={setNotificationBody} fieldLabel={t('GLOBAL.Search_Notification_Body')} />
                        
                         <Autocomplete
                            sx={{ml:1,mt:1, width:screenWidthRedux<=1100?'100%':'48%',marginTop:'10px !important',marginBottom:'12px !important' }}
                            fullWidth
                            options={notification_types}
                            getOptionLabel={(option) => option.name || ""}
                            label={t('GLOBAL.SEARCH_Notification_type')}
                            id="disable-close-on-select"
                            value={NotificationTypeID}
                            onChange={(e, v, r) => { setNotificationTypeId(v ? v : '') }}

                            renderInput={(params) => (
                                <TextField {...params} label={t('GLOBAL.SEARCH_Notification_type')} variant="standard"  sx={{borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}}/>
                            )}
                        />
                        <Autocomplete
                            sx={{ml:1,mt:1, width:screenWidthRedux<=1100?'100%':'48%',marginTop:'10px !important',marginBottom:'12px !important' }}
                            fullWidth
                            options={Companys}
                            getOptionLabel={(option) => option.company_name || ""}
                            label={t('GLOBAL.SEARCH_Notification_Company')}
                            id="disable-close-on-select"
                            value={NotificationCreatedBy}
                            onChange={(e, v, r) => { setNotificationCreatedBy(v ? v : '') }}

                            renderInput={(params) => (
                                <TextField {...params} label={t('GLOBAL.SEARCH_Notification_Company')} variant="standard"  sx={{borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}}/>
                            )}
                        />
                        <Autocomplete
                            sx={{ml:1,mt:1.2, width:screenWidthRedux<=1100?'100%':'48%',marginTop:'10px !important',marginBottom:'12px !important' }}
                            fullWidth
                            options={users}
                            getOptionLabel={(option) => option.username || ""}
                            id="disable-close-on-select"
                            label={t('GLOBAL.SEARCH_Notification_Company')}
                            value={NotificationCompanyID}
                            onChange={(e, v, r) => { setNotificationCompanyID(v ? v : '') }}

                            renderInput={(params) => (
                                <TextField {...params} label={t('GLOBAL.SEARCH_Notification_Company')} variant="standard"  sx={{borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}}/>
                            )}
                        />
                        <BasicDatePicker customeMarginTop={screenWidthRedux<=1100?2:-0.5} customePaddingLeft='9px' customeWidth={screenWidthRedux<=1100?'100%':'48.7%'}    Language={Language}/>

                            
                {/* <SearchField1 customeWidth={screenWidthRedux<=1100?'98%':"50%"} customeMargin="10px" state={companyName} setState={setCompanyName} fieldLabel={t('GLOBAL.SEARCH_COMPANY_NAME')} /> */}
                <Box display="flex" sx={{ justifyContent:'end',width:'100%' }}>
                   <Box display="flex" >
                            <Button
                                variant="contained"
                                spacing={2} 
                                sx={{ flexGrow: 1, margin: 1,backgroundColor:SearchButtonTheme?.search_button_color,color:SearchButtonTheme?.search_button_text_color,
                                    '&:hover':{
                                        backgroundColor:SearchButtonTheme?.search_button_color+'88',
                                    },
                                    height:FilterButtonHeight
                                }}
                                className="iconeFilterSearch"
                                onClick={() => addvancSearch()} 
                                >
                                {t('GLOBAL.SEARCH_SEARCH_BUTTON')}
                            </Button>
                            <Button variant="outlined"
                                spacing={2}
                                sx={{
                                        flexGrow: 1, margin: 1,
                                        // backgroundColor:SearchButtonTheme?.clear_button_color,
                                        color:SearchButtonTheme?.clear_button_text_color,
                                    '&:hover':{
                                        backgroundColor:`${SearchButtonTheme?.clear_button_color}   !important`,
                                    },
                                    height:FilterButtonHeight
                                }}
                                className="iconeFilterClear"
                                color="secondary"
                                onClick={clearFunction}
                                 >
                                {t('GLOBAL.SEARCH_CLEAR_BUTTON')}
                            </Button>
                    </Box>
                </Box>

               </FilterBox>:null
             }

            <Box 
             sx={{
                display:'flex',
                justifyContent: 'start',
                alignItems: 'start',
                width:'100%',
             }}
            >
                <Box
                    sx={{
                    width:'100%',
                    }}
                >
                    <Grid
                        rows={rows}
                        columns={columns}
                        rowTotals={notificationsTotals}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        pageNumber={pageNumber}
                        setPageNumber={setpageNumber}
                        loading={loading}
                        handlePageChange={handlePageChange}
                        NumberOfPage={notificationsNumberOfPage}
                        directions={directions}
                        hasCreate={false}
                        createObject={createObject}
                        title={t('NOTIFICATIONS.HEADER_TITLE')}
                        //   title_ar="قائمة المستخدمين"
                        hasFooter={false}
                        gridHeight={{
                            height: rows?.length > 5 ? pageSize == 5 ? 400 : pageSize == 10 ? 650 : 950 : 400,
                        }}
                    />
                </Box>

                {
                    filterSearchBoxMode?
                    <CustomeFilter
                        // exportFunction={ExportFunction}
                        createFunction={createFunction}
                        // mapFunction={mapFunction}
                        // importFunction={importFunction}
                        hasExport={false} 
                        hasCreate={true} 
                        hasMaps={false} 
                        hasImport={false}
                        resultLength={notificationsTotals}
                    //    customeHeight={screenWidth<=1100?'460px':"350px"}
                    >
                        <Box
                            sx={{
                                width:'100%',
                                height:'400px',
                                minHeight:'400px',
                                maxHeight:'400px',
                                overflowY:'scroll',
                                overflowX:'hidden',
                                padding:'10px',
                                marginTop:'10px',
                                paddingTop:'20px',
                                // boxShadow:'0 0 7px -5px #000 inset'
                            }}
                        >
                         <SearchFieldV2 marginLeftValue={0.5} customeWidth={screenWidthRedux<=1100?'100%':"100%"}  state={NotificationTitle} setState={setNotificationTitle} fieldLabel={t('GLOBAL.Search_Notification_Title')} />
                         <SearchFieldV2 marginLeftValue={0.5}  customeWidth={screenWidthRedux<=1100?'100%':"100%"}  state={NotificationBody} setState={setNotificationBody} fieldLabel={t('GLOBAL.Search_Notification_Body')} />
                        
                         <Autocomplete
                            sx={{ml:0.5, width:screenWidthRedux<=1100?'100%':'100%',marginTop:'10px !important',marginBottom:'12px !important' }}
                            fullWidth
                            options={notification_types}
                            getOptionLabel={(option) => option.name || ""}
                            id="disable-close-on-select"
                            value={NotificationTypeID}
                            onChange={(e, v, r) => { setNotificationTypeId(v ? v : '') }}

                            renderInput={(params) => (
                                <TextField {...params} placeholder={t('GLOBAL.SEARCH_Notification_type')} variant="standard"  sx={{borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}}/>
                            )}
                        />
                        <Autocomplete
                            sx={{ml:0.5, width:screenWidthRedux<=1100?'100%':'100%',marginTop:'10px !important',marginBottom:'12px !important' }}
                            fullWidth
                            options={Companys}
                            getOptionLabel={(option) => option.company_name || ""}
                            id="disable-close-on-select"
                            value={NotificationCreatedBy}
                            onChange={(e, v, r) => { setNotificationCreatedBy(v ? v : '') }}

                            renderInput={(params) => (
                                <TextField {...params} placeholder={t('GLOBAL.SEARCH_Notification_Company')} variant="standard"  sx={{borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}}/>
                            )}
                        />
                        <Autocomplete
                            sx={{ml:0.5, width:screenWidthRedux<=1100?'100%':'100%',marginTop:'10px !important',marginBottom:'12px !important' }}
                            fullWidth
                            options={users}
                            getOptionLabel={(option) => option.username || ""}
                            id="disable-close-on-select"
                            value={NotificationCompanyID}
                            onChange={(e, v, r) => { setNotificationCompanyID(v ? v : '') }}

                            renderInput={(params) => (
                                <TextField {...params} placeholder={t('GLOBAL.SEARCH_Notification_users')} variant="standard"  sx={{borderColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor,outlineColor:GridTheme?.disabledGlobalText?GridTheme?.gloablTextColor:GridTheme?.paperTextColor}}/>
                            )}
                        />
                        <BasicDatePickerV2 customeWidth={screenWidthRedux<=1100?'100%':'100%'}    Language={Language}/>

                        </Box>

                        <Box sx={{display:'flex',justifyContent: 'end',alignItems: 'center',width:'100%'}}>
                            <Box display="flex" >
                                    <Button
                                        variant="contained"
                                        // spacing={2} 
                                        sx={{ 
                                            // flexGrow: 1,
                                            margin: 1,
                                            width:'80px !important',
                                            minWidth:'80px !important',
                                            maxWidth:'80px !important',
                                            backgroundColor:SearchButtonTheme?.search_button_color,color:SearchButtonTheme?.search_button_text_color,
                                            '&:hover':{
                                                backgroundColor:SearchButtonTheme?.search_button_color+'88',
                                            },
                                            height:FilterButtonHeight-10
                                        }}
                                        className="iconeFilterSearch"
                                        onClick={() => addvancSearch()} 
                                        >
                                        {t('GLOBAL.SEARCH_SEARCH_BUTTON')}
                                    </Button>
                                    <Button variant="outlined"
                                        // spacing={2}
                                        sx={{
                                            width:'50% !important',
                                                // flexGrow: 1, 
                                                minWidth:'80px !important',
                                                maxWidth:'80px !important',
                                                margin: 1,
                                                // backgroundColor:SearchButtonTheme?.clear_button_color,
                                                color:SearchButtonTheme?.clear_button_text_color,
                                            '&:hover':{
                                                backgroundColor:`${SearchButtonTheme?.clear_button_color}   !important`,
                                            },
                                            height:FilterButtonHeight-10
                                        }}
                                        className="iconeFilterClear"
                                        color="secondary"
                                        onClick={clearFunction}
                                         >
                                        {t('GLOBAL.SEARCH_CLEAR_BUTTON')}
                                    </Button>
                            </Box>
                        </Box>
                    </CustomeFilter>:null
                }
            </Box>
      </>
  );
}
