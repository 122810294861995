import React,{useEffect,useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useTranslation} from "react-i18next";
import {styled} from '@mui/material/styles'
import { useSelector } from 'react-redux'
import Cookies from "universal-cookie";
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import { toast } from 'react-toastify';
import axios from "axios";
import Host from "../../../assets/js/Host";
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import helpers from '../../../assets/js/helper'
import userImage from '../../../assets/img/userImage.png'

const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"

const CustomeWrapperDialog=styled(Dialog)(({theme,MainTheme})=>({
   '& h2,& .muiltr-hlj6pa-MuiDialogActions-root,& .muiltr-1t4vnk2-MuiDialogContent-root,& .muirtl-1t4vnk2-MuiDialogContent-root,& .muirtl-hlj6pa-MuiDialogActions-root':{
    backgroundColor:`${MainTheme?.paperColor} !important`,
    color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
   },
   '& .MuiBox-root ,& .muiltr-uwwqev':{
    width:'600px !important',
    height:'700px !important',
   },
   '& .muiltr-1t1j96h-MuiPaper-root-MuiDialog-paper,& .muirtl-1t1j96h-MuiPaper-root-MuiDialog-paper':{
    backgroundColor:`${MainTheme?.paperColor} !important`,
    color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
   },'& .muirtl-83ijpv-MuiTypography-root,& .muiltr-83ijpv-MuiTypography-root':{
    color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,

   }
   

}))

function generate(element) {
    return [0, 1, 2].map((value) =>
      React.cloneElement(element, {
        key: value,
      }),
    );
}
  
  const Demo = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
  }));

export default function NotificationsUsers({
  open,handleClose,Notification,
  fetchData, showNotificationForm, setShowNotificationForm
}) {
    const {t, i18n} = useTranslation('common');
    const directions = useSelector(state => state.settingsData.directions)
    const GridTheme = useSelector(state => state.settingsData.GridTheme)
    const MainTheme = useSelector(state => state.settingsData.MainTheme)
    const [loading,setloading]=useState(false)
    const [dense, setDense] = React.useState(false);
    const [secondary, setSecondary] = React.useState(false);

    const token = cookies.get("token")
    const [scroll, setScroll] = React.useState('paper');

    const handleClickOpen = (scrollType) => () => {
        // setOpen(true);
        setScroll(scrollType);
    };

    const [object,setObject]=useState({
        notification_id:null,
        user_id:null,
        _method:'patch',
    })

    useEffect(()=>{
       if(Notification&&Notification?.id>0){
        setObject({
          ...object,
          notification_id:Notification?.id,
        })
       }
    },[Notification])


  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const reSendNotifications=async(user)=>{
      try {
        if (object?.notification_id) {
            const resourcesResult = await axios({
                url: Host + "notifications/send_push_notification",
                method: "post",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    Accept: "application/json",
                    'X-localization': language,
                },
                data: {
                    ...object,
                    user_id:user?.id,
                },
            })
            if (resourcesResult) {
                if (resourcesResult.status === 200) {
                    toast.success("notifications resend successfully")
                    fetchData()
                    // setShowNotificationForm(false, true)
                } else if (resourcesResult.data.status === false) {
                    // setShowNotificationForm(true, false)
                }
            }
            else {
                toast.error("Error")
                // setShowNotificationForm(true, false)
            }
            setloading(false)
        }
       
      } catch (error) {
          console.log("error",error);
          // setShowNotificationForm(true, false)
          const resourcesResult = error.response
          if (resourcesResult && resourcesResult.status === 400) {
              if (typeof resourcesResult.data.data === "object") {
                  const errorMessagesKeys = Object.keys(resourcesResult.data.data);
                  errorMessagesKeys.map((key) => {

                      resourcesResult.data.data[key].map((message) => {
                          toast.error(message)
                      })
                  })
              }
              else {

                  toast.error(resourcesResult.data.data)
              }


          }
          else if (resourcesResult && resourcesResult.status === 401) {

              toast.error(resourcesResult.data.data)
              cookies.remove("token");
              window.location.href = "/"
          }
          else {

              toast.error("network error")
          }
      }
  }

  return (
     <CustomeWrapperDialog
        className="containerNotificationsUsers"
        MainTheme={MainTheme}
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"

      >
        <Box
          sx={{
            width:'100%',
            height:'100%',
          }}
        >
          <DialogTitle id="scroll-dialog-title"
            sx={{
              backgroundColor:'#FFF',
              display:'flex',
              justifyContent: 'start',
            }}
          >{t('NOTIFICATIONS.NOTIFICATIONS_USERS_TITLE')+ Notification?.title}</DialogTitle>
          <DialogContent dividers={scroll === 'paper'}>
          <Grid item xs={12} md={12}>
              <List dense={dense}
              
              >
                {Notification&&Notification?.users?.length>0?
                Notification?.users?.map((user)=>(
                  <ListItem
                     sx={{
                        width:'100%',
                        backgroundColor:MainTheme?.paperTextColor+'11 !important',
                        padding:'20px 20px',
                        margin:'5px 3px',
                        borderRadius:'10px',
                        '& .muiltr-13i4rnv-MuiGrid-root':{
                          backgroundColor:MainTheme?.paperTextColor+'22 !important',
                          padding:'10px 20px',
                          borderRadius:'10px'
                        }
                     }}
                    secondaryAction={
                      <Tooltip arrow title={'resend Notifications'} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} placement="bottom" onClick={()=>{
                        reSendNotifications(user)
                      }}>
                        <IconButton edge="end" aria-label="delete"
                          sx={{
                            outline:'none !important',
                            border:'none !important',
                            direction:directions=='rtl' ? 'rtl !important' : 'ltr !important',

                          }}
                        >
                          {
                            user?.notification_status&&user?.notification_status?.data_sent_status=='ok'?
                            <SendIcon size={30} sx={{
                              color:'#0f0',
                              transform:directions=='rtl'?'scale(-1)':'scale(1)'
                            }} />
                            :
                            <SendIcon size={30}  sx={{
                              color:'#f00',
                              transform:directions=='rtl'?'scale(-1)':'scale(1)'
                          }} />

                          }
                        </IconButton>
                      </Tooltip>
                    }
                  >
                    <ListItemAvatar
                     sx={{
                      '& .muiltr-8veleo-MuiAvatar-root':{
                        width:'55px !important',
                        height:'55px !important',
                        margin:'0 10px !important',
                      }
                     }}
                    >
                        <Avatar
                          alt=""
                          src={helpers?.getImage(user,userImage)}
                          size={35}
                          sx={{
                              backgroundColor:MainTheme?.paperTextColor+'44',
                             
                          }}
                        />
                    </ListItemAvatar>
                    <ListItemText
                      primary={user?.full_name}
                      secondary={ user?.phone}
                    />
                  </ListItem>
                )):
                 <p>No Users </p>
                }
              
              </List>
          </Grid>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} sx={{backgroundColor:MainTheme?.secondaryColor,color:MainTheme?.oppositeSecondaryColor}}>{t('NOTIFICATIONS.NOTIFICATIONS_USERS_CANCEL')}</Button>
          </DialogActions>
        </Box>
      </CustomeWrapperDialog>
  )
}
