import styled,{keyframes } from 'styled-components'


const glowing  = keyframes`
  from {
    box-shadow: 0 0 10px  ${props=>props.color}, 0 0 20px  ${props=>props.color}, 0 0 30px  ${props=>props.color}, 0 0 40px  ${props=>props.color}, 0 0 50px  ${props=>props.color}, 0 0 60px  ${props=>props.color}, 0 0 70px  ${props=>props.color};
  }
  to {
    box-shadow: 0 0 20px  ${props=>props.color+'99'}, 0 0 30px  ${props=>props.color+'55'}, 0 0 40px  ${props=>props.color+'11'}, 0 0 50px  ${props=>props.color+'11'}, 0 0 60px  ${props=>props.color+'11'}, 0 0 70px  ${props=>props.color+'11'}, 0 0 80px  ${props=>props.color+'11'};
  }
`

export const CustomeActiveStatusCheck=styled.div`
    position:${props=>props?.gridModeActive?'static':'absolute'};
    top:${props=>props?.gridModeActive?0:'23px'};
    right:${props=>props?.gridModeActive?'-50px':props.directions!='rtl'?" 27px":"90%"};
    width:15px;
    height:15px;
    background-color:${props=>props.color};
    border-radius:50%;
    box-shadow:0 0 10px ${props=>props.color};
    // box-shadow: 0 0 10px 50px ${props=>props.color+'22'}, 0 0 10px 5px ${props=>props.color+33}, 0 0 20px 10px ${props=>props.color}77;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-animation: ${glowing} 0.8s ease-in-out infinite alternate;
    -moz-animation: ${glowing} 0.8s ease-in-out infinite alternate;
    animation: ${glowing} 0.8s ease-in-out infinite alternate;
`

export const CustomeActiveStatusCheckV2=styled.div`
    width:15px;
    height:15px;
    background-color:${props=>props.color};
    border-radius:50%;
    box-shadow:0 0 10px ${props=>props.color};
    // box-shadow: 0 0 10px 50px ${props=>props.color+'22'}, 0 0 10px 5px ${props=>props.color+33}, 0 0 20px 10px ${props=>props.color}77;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-animation: ${glowing} 0.8s ease-in-out infinite alternate;
    -moz-animation: ${glowing} 0.8s ease-in-out infinite alternate;
    animation: ${glowing} 0.8s ease-in-out infinite alternate;
`
