import React, { useState, useRef, useEffect } from 'react';

import GoogleMapReact,{Marker} from 'google-map-react';
import {Box,Button} from '@mui/material'
import {useTranslation} from "react-i18next";
import {useSelector,useDispatch} from 'react-redux'
import { toast } from 'react-toastify';
import { xyz } from '../Source';
import Queue from 'tinyqueue'
import $ from 'jquery'
const AnyReactComponent = (props) => {

  useEffect(()=>{
    const currentPoPup=document.getElementById('infoPopup')
    if(currentPoPup){
      let tempMarkers = []
      currentPoPup.parentElement.addEventListener('click',()=>{
        if (props?.markers && props?.markers?.length > 0) {
          tempMarkers = props?.markers?.map(marker => {
  
              return {
                ...marker,
                show:false
              }
           
          })
        }
        props.setMarkers(tempMarkers)
        props.setMarkersV2(tempMarkers)
      })
    }
  },[])

  return (
    <>
      <div 
      id="infoPopup"
      style={{
        border: '5px solid ',
        borderColor: props?.marker?.specialColor ? props?.marker?.specialColor : '#f44336',
        borderRadius: 40,
        backgroundColor: 'white',
        textAlign: 'center',
        color: '#3f51b5',
        fontSize: 8,
        fontWeight: 'bold',
        padding: 4,
        position: 'absolute',
        transform: 'translate(40%, 0%)',
        display: 'inline-flex',
        whiteSpace: 'nowrap',
        cursor:'pointer',
        ...(props?.marker?.hover && {
          border: '5px solid ',
          borderColor: "#3f51b5",
          color: '#f44336'
        })
      }} >
        {props?.marker?.label}
      </div>
      {props?.marker?.show && <InfoWindow  deletable={props?.deletable} onDeleteClicked={() => props?.onDeleteClicked(props?.marker)} marker={props?.marker} />}
    </>
  )
};
const InfoWindow = (props) => {
  const [t, i18n] = useTranslation('common');
 const MainTheme = useSelector(state =>state.settingsData.MainTheme)
  const infoWindowStyle = {
    position: 'relative',
    bottom: '-30px',
    left: '80px',
    width: 220,
    backgroundColor: 'white',
    boxShadow: '0 2px 7px 1px rgba(0, 0, 0, 0.3)',
    padding: 10,
    fontSize: 14,
    zIndex: 1000000,
  };
  const copyCordinates=(e,marker)=>{
     let url='https://www.google.com/maps/search/?api=1&query='+marker?.latitude+","+marker?.longitude;
   
   
    // navigator.clipboard.writeText(url);
    window.open(url,'_blank');
    // toast.success(t('GLOBAL.COPYM_MESSAGE_TO_CLIPBOARD'));

  }
  return (
    <div style={infoWindowStyle}>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}>
        {props?.deletable && (
          <button type='button' onClick={() => {
            props?.onDeleteClicked()
          }}>Delete</button>

        )}
        {props?.marker?.label &&
          <div style={{ fontSize: 16 }}>
            {props?.marker?.label}
           

          </div>
        }
        <div style={{ fontSize: 16 }}>
          {props?.marker?.name}
          {/* {props?.marker?.longitude}
            {props?.marker?.latitude} */}
        </div>
        <div style={{ fontSize: 14 }}>
          <span style={{ color: 'grey' }}>
            {props?.marker?.owner_name}
            {' '}
          </span>

        </div>
        <div style={{ fontSize: 14, color: 'grey' }}>
          {props?.marker?.point_type?.name}
        </div>
        <Box>
            <Button 
             onClick={(e)=>{
              copyCordinates(e,props?.marker)
             }}
            sx={{
              fontSize:'12px',
              backgroundColor:MainTheme?.secondaryColor,color:MainTheme?.oppositeSecondaryColor,outline:'none !important',border:'none !important',
              '&:hover':{
                backgroundColor:MainTheme?.secondaryColor+'33'
              }
              }}>{t('GLOBAL.COPY_CORDINATES')}</Button>
        </Box>
      </div>


    </div>
  );
};

const GoogleMap = (props) => {

  const gMap = useRef();
  const gMaps = useRef();

  const [polygon, setPolygon] = useState(null)
  const [polygons, setPolygons] = useState([])
  const [polygonsChanged, setPolygonsChanged] = useState(null)
  const [isFullScreenMode,setIsFullScreenMode]=useState(false)
  const [isFullScreen,setIsFullScreen]=useState({
    position:'relative',
    width:'100%',
    height:'100%',
    top:0,
    left:0,
    bottom:0,
    right:0
  })
  const [draggable, setDraggable] = useState(true)

  const MapRef = useRef(null);

  const [markers, setMarkers] = useState(props?.markers && props?.markers?.length > 0 ? props?.markers : [])
  const [defaultProps,setDefaultProps]=useState({
    
      center: { lat: 33.33, lng: 44.44 },
      zoom: props?.zoomedOut?5:11
    
  })
  const [deleteSelectedMarker, setDeleteSelectedMarker] = useState(null)
  const [googleMapInitialized, setGoogleMapInitialized] = useState(false)

  const onChildClick = (hoverKey, childProps) => {

    let tempMarkers = []
    if (markers && markers?.length > 0) {
      tempMarkers = markers?.map(marker => {

        if (marker?.id === childProps?.marker?.id) {
          //console.log("childProps in", marker?.id);
          return {
            ...marker,
            show: !marker?.show
          }
        }
        else {
          return {
            ...marker,
            show: false
          }
        }
      })
    }

    setMarkers(tempMarkers)
  }
  const onChildMouseLeave = (hoverKey, childProps) => {
    let tempMarkers = []
    if (markers && markers?.length > 0) {
      tempMarkers = markers?.map(marker => {


        return {
          ...marker,
          hover: false

        }

      })
    }

    setMarkers(tempMarkers)
  }
  const onChildMouseEnter = (hoverKey, childProps) => {
    let tempMarkers = []
    if (markers && markers?.length > 0) {
      tempMarkers = markers?.map(marker => {

        if (marker?.id === childProps?.marker?.id) {

          return {
            ...marker,
            hover: true
          }
        }
        else {
          return {
            ...marker,
            hover: false
          }
        }
      })
    }
    setMarkers(tempMarkers)
  }
  const onChildMouseDown = (hoverKey, childProps) => {
    setDraggable(false)
  }
  const onChildMouseUp = (hoverKey, childProps) => {
    setDraggable(true)
  }
  const onChildMouseMove = (hoverKey, childProps, mouse) => {
    let tempMarkers = []
    if (markers && markers?.length > 0) {
      tempMarkers = markers?.map(marker => {

        if (marker?.id === childProps?.marker?.id) {

          return {
            ...marker,
            latitude: mouse?.lat,
            longitude: mouse?.lng
          }
        }
        else {
          return marker

        }
      })
    }
    props?.setMarkers(tempMarkers)
    setMarkers(tempMarkers)
  }

  const onClick = (clickEventValue) => {

    const opendMarker = markers?.filter((marker) => marker?.show)
    console.log("clicked", opendMarker);

    if (opendMarker?.length > 0) {
      let tempMarkers = []
      if (markers && markers?.length > 0) {
        tempMarkers = markers?.map(marker => {

            return {
              ...marker,
              show:false
            }
         
        })
      }
      // props.setMarkers(tempMarkers)
      setMarkers(tempMarkers)
    }

    // if (opendMarker?.length > 0) {
    //   return
    // }
    console.log("clicked 2");

    props?.onClick && props?.onClick(clickEventValue)
  }
  useEffect(() => {
    //console.log("polygon", props?.polygon);
    if(props?.markers?.length > 0){
      setMarkers(props?.markers)
    }
    
    return () => {

    }
  }, [props?.markers])

  // var map;
  //  var mapOptions = {
  //     zoom: 1,
  //  }


  useEffect(() => {
    // map = new window.google.maps.Map(document.getElementById('map_canvas'), mapOptions);
    if (polygon) {
      //console.log(polygon);
      polygon?.setMap(null);
      let poly = new window.google.maps.Polygon({
        paths: props?.polygon?.paths,
        strokeColor: props?.polygon?.strokeColor ? props?.polygon?.strokeColor : "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: props?.polygon?.fillColor ? props?.polygon?.fillColor : "#FF0000",
        fillOpacity: 0.35
      });
      poly.setMap(gMap?.current);
      setPolygon(poly)
    }

    return () => {

    }
  }, [props?.polygon?.paths])

  useEffect(() => {
    console.log("googleMapInitialized", googleMapInitialized);
    if (polygonsChanged != props?.polygonsChanged && googleMapInitialized) {
      console.log("googleMapInitialized in ");
      setPolygonsChanged(props?.polygonsChanged)
      // if(polygons && polygons?.length>0)
      // {
      let tempPolygons = []
      polygons?.forEach(poly => {
        poly?.setMap(null);
      })
      props?.polygons?.forEach(element => {


        let polygon = new window.google.maps.Polygon({
          paths: element.paths,
          strokeColor: element.strokeColor ? element.strokeColor : "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: element.fillColor ? element.fillColor : "#FF0000",
          fillOpacity: 0.35
        });
        // polytri.setMap(map);
        polygon.setMap(gMap?.current);
        tempPolygons?.push(polygon)

      });
      setPolygons(tempPolygons);
      // }
    }



    return () => {

    }
  }, [props?.polygonsChanged, googleMapInitialized])

  useEffect(() => {
    console.log("googleMapInitialized", googleMapInitialized);
    if (props?.isPointAllShow&& googleMapInitialized) {
      console.log("googleMapInitialized in ");
      // setPolygonsChanged(props?.polygonsChanged)
      // if(polygons && polygons?.length>0)
      // {
      let tempPolygons = []
      polygons?.forEach(poly => {
        poly?.setMap(null);
      })
      props?.polygons?.forEach(element => {
        let polygon = new window.google.maps.Polygon({
          paths: element.paths,
          strokeColor: element.strokeColor ? element.strokeColor : "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: element.fillColor ? element.fillColor : "#FF0000",
          fillOpacity: 0.35,
          text:'tesasdas'
        });
        polygon.setMap(gMap?.current);
      
        tempPolygons?.push(polygon)
      });
      setPolygons(tempPolygons);
      // }
    }



    return () => {

    }
  }, [props?.isPointAllShow, googleMapInitialized])

  useEffect(() => {
    if (deleteSelectedMarker && deleteSelectedMarker?.id > 0) {
      console.log("click delete");
      let tempMarkers = []
      if (markers && markers?.length > 0) {
        tempMarkers = markers?.filter(marker => marker?.id !== deleteSelectedMarker?.id)
      }
      props?.setMarkers(tempMarkers)
      setDeleteSelectedMarker(null)
    }

    return () => {

    }
  }, [deleteSelectedMarker])

  const onDeleteClicked = (currentMarker) => {
    setDeleteSelectedMarker(currentMarker)
  }
  function polylabel(polygon, precision, debug) {
    precision = precision || 1.0;

    // find the bounding box of the outer ring
    var minX, minY, maxX, maxY;
    for (var i = 0; i < polygon[0].length; i++) {
        var p = polygon[0][i];
        if (!i || p[0] < minX) minX = p[0];
        if (!i || p[1] < minY) minY = p[1];
        if (!i || p[0] > maxX) maxX = p[0];
        if (!i || p[1] > maxY) maxY = p[1];
    }

    var width = maxX - minX;
    var height = maxY - minY;
    var cellSize = Math.min(width, height);
    var h = cellSize / 2;

    if (cellSize === 0) {
        var degeneratePoleOfInaccessibility = [minX, minY];
        degeneratePoleOfInaccessibility.distance = 0;
        return degeneratePoleOfInaccessibility;
    }

    // a priority queue of cells in order of their "potential" (max distance to polygon)
    var cellQueue = new Queue(undefined, compareMax);

    // cover polygon with initial cells
    for (var x = minX; x < maxX; x += cellSize) {
        for (var y = minY; y < maxY; y += cellSize) {
            cellQueue.push(new Cell(x + h, y + h, h, polygon));
        }
    }

    // take centroid as the first best guess
    var bestCell = getCentroidCell(polygon);

    // second guess: bounding box centroid
    var bboxCell = new Cell(minX + width / 2, minY + height / 2, 0, polygon);
    if (bboxCell.d > bestCell.d) bestCell = bboxCell;

    var numProbes = cellQueue.length;

    while (cellQueue.length) {
        // pick the most promising cell from the queue
        var cell = cellQueue.pop();

        // update the best cell if we found a better one
        if (cell.d > bestCell.d) {
            bestCell = cell;
            if (debug) console.log('found best %f after %d probes', Math.round(1e4 * cell.d) / 1e4, numProbes);
        }

        // do not drill down further if there's no chance of a better solution
        if (cell.max - bestCell.d <= precision) continue;

        // split the cell into four cells
        h = cell.h / 2;
        cellQueue.push(new Cell(cell.x - h, cell.y - h, h, polygon));
        cellQueue.push(new Cell(cell.x + h, cell.y - h, h, polygon));
        cellQueue.push(new Cell(cell.x - h, cell.y + h, h, polygon));
        cellQueue.push(new Cell(cell.x + h, cell.y + h, h, polygon));
        numProbes += 4;
    }

    if (debug) {
        console.log('num probes: ' + numProbes);
        console.log('best distance: ' + bestCell.d);
    }

    var poleOfInaccessibility = [bestCell.x, bestCell.y];
    poleOfInaccessibility.distance = bestCell.d;
    return poleOfInaccessibility;
  }
  function Cell(x, y, h, polygon) {
    this.x = x; // cell center x
    this.y = y; // cell center y
    this.h = h; // half the cell size
    this.d = pointToPolygonDist(x, y, polygon); // distance from cell center to polygon
    this.max = this.d + this.h * Math.SQRT2; // max distance to polygon within a cell
  }
  // signed distance from point to polygon outline (negative if point is outside)
  function pointToPolygonDist(x, y, polygon) {
      var inside = false;
      var minDistSq = Infinity;

      for (var k = 0; k < polygon.length; k++) {
          var ring = polygon[k];

          for (var i = 0, len = ring.length, j = len - 1; i < len; j = i++) {
              var a = ring[i];
              var b = ring[j];

              if ((a[1] > y !== b[1] > y) &&
                  (x < (b[0] - a[0]) * (y - a[1]) / (b[1] - a[1]) + a[0])) inside = !inside;

              minDistSq = Math.min(minDistSq, getSegDistSq(x, y, a, b));
          }
      }

      return minDistSq === 0 ? 0 : (inside ? 1 : -1) * Math.sqrt(minDistSq);
  }

  // get polygon centroid
  function getCentroidCell(polygon) {
      var area = 0;
      var x = 0;
      var y = 0;
      var points = polygon[0];

      for (var i = 0, len = points.length, j = len - 1; i < len; j = i++) {
          var a = points[i];
          var b = points[j];
          var f = a[0] * b[1] - b[0] * a[1];
          x += (a[0] + b[0]) * f;
          y += (a[1] + b[1]) * f;
          area += f * 3;
      }
      if (area === 0) return new Cell(points[0][0], points[0][1], 0, polygon);
      return new Cell(x / area, y / area, 0, polygon);
  }

  // get squared distance from a point to a segment
  function getSegDistSq(px, py, a, b) {

      var x = a[0];
      var y = a[1];
      var dx = b[0] - x;
      var dy = b[1] - y;

      if (dx !== 0 || dy !== 0) {

          var t = ((px - x) * dx + (py - y) * dy) / (dx * dx + dy * dy);

          if (t > 1) {
              x = b[0];
              y = b[1];

          } else if (t > 0) {
              x += dx * t;
              y += dy * t;
          }
      }

      dx = px - x;
      dy = py - y;

      return dx * dx + dy * dy;
  }

  function compareMax(a, b) {
    return b.max - a.max;
  }
  const getTextForPolygonCoordinates=()=>{
    let result=[];
    props?.polygons&&props?.polygons?.length>0&&props?.polygons?.map((poly,index)=>{
      let x=[]
      let y=[]

      let compReslt=[];
      poly&&poly?.paths?.length>0&&poly?.paths?.map((coor)=>{
         compReslt.push([coor?.lat,coor?.lng])
      })
      let p=polylabel([compReslt], 1.0);

      console.log('asdasdlkdsjflksd=>',p)
     if(p&&p[0]&&p[1]){
        result.push(<div 
          key={index + "_" + poly?.name}
          lat={p[0]}
          lng={p[1]}
          text={poly?.name}
          style={{
            width:'60px',
            overflowWrap: 'break-word',
            wordWrap: 'break-word',
            hyphens: 'auto',
            whiteSpace: 'normal',
            zIndex:10000,
            backgroundColor:'#fff',
            color:'#f00',
            fontSize:'10px',
            display:'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius:'5px',
            boxShadow:'0 0 7px -2px #000'
          }}
        
        >
          {poly?.name}
      </div>)
     }else return <></>

    })
    return result;
  }

  const getTextForSinglePolygonCoordinates=()=>{
    if(props?.polygon&&props?.polygon?.paths?.length>0){
      let compReslt=[];
      props?.polygon?.paths?.map((poly)=>{
        compReslt.push([poly?.lat,poly?.lng])
      })
      let p=polylabel([compReslt], 1.0);
      console.log('asdasdlkdsjflksd=>',p)
        if(p&&p[0]&&p[1]){
          return <div 
          lat={p[0]}
          lng={p[1]}
          text={props?.polygon&&props?.polygon?.name?props?.polygon?.name:''}
          style={{
            width:'60px',
            overflowWrap: 'break-word',
            wordWrap: 'break-word',
            hyphens: 'auto',
            whiteSpace: 'normal',
            zIndex:10000,
            backgroundColor:'#fff',
            color:'#f00',
            fontSize:'10px',
            display:'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius:'5px',
            boxShadow:'0 0 7px -2px #000'
          }}
        
        >
          {props?.polygon&&props?.polygon?.name?props?.polygon?.name:''}
      </div>
        }else return <></>
    }
  }

useEffect(()=>{
  // const map=new window.google.maps
  // if(new window.google.maps)
  // new window.google.maps.event.addListener(MapRef?.current, "bounds_changed", mapSettleTime); 


  // function mapSettleTime() {

      
  // }


},[MapRef?.current])

const changeIngoogleMaps=(event)=>{
  if(event&&event?.size&&event?.size?.width>=window.screen.width&&event?.size?.height>=window.screen.height){
     setIsFullScreen({
      ...isFullScreen,
      position:'fixed',
      top:0,
      left:0,
     })
     setIsFullScreenMode(true)
     return
  }else{
    setIsFullScreen({
      ...isFullScreen,
      position:'relative',
      top:0,
      left:0,
     })
     setIsFullScreenMode(false)
     return
  }
}
  

  return (
    <GoogleMapReact
      ref={MapRef}
      defaultCenter={defaultProps.center}
      defaultZoom={props?.zoomedOut?3:12}
      zoom={props?.zoomedOut?3:12}
      layerTypes={[
        // 'TrafficLayer',
        'TransitLayer'
      ]}
      
      bootstrapURLKeys={{
        key: process.env.REACT_APP_GOOGLE_API_KEY,
        language: 'ar',
        region: 'iq',
        libraries: ['places'],
        // ...otherUrlParams,
      }}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => {
        gMap.current = map;
        gMaps.current = maps;
        setGoogleMapInitialized(true)

        // if (props?.polygons && props?.polygons?.length > 0) {
        //   let tempPolygons =[]
        //   props?.polygons?.forEach(element => {

        //     let polygon = new window.google.maps.Polygon({
        //       paths: element.paths,
        //       strokeColor: element.strokeColor ? element.strokeColor : "#FF0000",
        //       strokeOpacity: 0.8,
        //       strokeWeight: 2,
        //       fillColor: element.fillColor ? element.fillColor : "#FF0000",
        //       fillOpacity: 0.35
        //     });
        //     // polytri.setMap(map);
        //     polygon.setMap(map);
        //     tempPolygons?.push(polygon)
        //   });
        //   setPolygons(tempPolygons)
        // }
        if (props?.polygon?.paths?.length > 0 || props?.createPoly) {
          // //console.log("polygon object in path", props?.polygon?.paths);
          // const triangleCoords = [
          //   { lat: 33.33, lng: 44.44 },
          //   { lat: 34.34, lng: 45.55 },
          //   { lat: 44.44, lng: 55.55 },
          // ];
          // // //console.log("polygon object in triangle", triangleCoords);
          // let polytri = new window.google.maps.Polygon({
          //   paths: triangleCoords,
          //   strokeColor: props?.polygon?.strokeColor ? props?.polygon?.strokeColor : "#FF0000",
          //   strokeOpacity: 0.8,
          //   strokeWeight: 2,
          //   fillColor: props?.polygon?.fillColor ? props?.polygon?.fillColor : "#FF0000",
          //   fillOpacity: 0.35
          // });

          let poly = new window.google.maps.Polygon({
            paths: props?.polygon?.paths,
            strokeColor: props?.polygon?.strokeColor ? props?.polygon?.strokeColor : "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: props?.polygon?.fillColor ? props?.polygon?.fillColor : "#FF0000",
            fillOpacity: 0.35
          });
          // polytri.setMap(map);
          poly.setMap(map);
          setPolygon(poly)
          if (props?.polygon?.paths?.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();

            props?.polygon?.paths?.map((item, index) => {
              const location = new window.google.maps.LatLng(
                item.lat,
                item.lng
              );
              bounds.extend(location);
            });
            map.fitBounds(bounds)
          }

        }

        if (markers && markers?.length > 0) {
          const bounds = new window.google.maps.LatLngBounds();

          markers?.map((marker, index) => {
            const location = new window.google.maps.LatLng(
              marker.latitude,
              marker.longitude
            );
            bounds.extend(location);
          });
            map.fitBounds(bounds)
            // map.setZoom(15)
        }


      }}
      onChildClick={onChildClick}
      onChildMouseEnter={onChildMouseEnter}
      onChildMouseLeave={onChildMouseLeave}
      onChildMouseDown={props?.draggable && onChildMouseDown}
      onChildMouseUp={props?.draggable && onChildMouseUp}
      onChildMouseMove={props?.draggable && onChildMouseMove}
      draggable={draggable && props?.draggable}
      onChange={changeIngoogleMaps}
      onClick={onClick}
      options={{
        scrollwheel:isFullScreenMode?true: props?.allowScroolZooming?true:false,
      }}
      // polygons={props?.polygons}
      style={isFullScreen}
    >
      {markers && markers?.length > 0 && markers?.map((marker, index) => {

        return <AnyReactComponent
          key={index + "_" + marker?.name}
          lat={marker?.latitude}
          lng={marker?.longitude}
          marker={marker}
          deletable={props?.deletable}
          onDeleteClicked={onDeleteClicked}
          setMarkers={props?.setMarkers}
          setMarkersV2={setMarkers}
          text={marker?.name}
          
        />
      })}

      {
        props?.isPointAllShow&&getTextForPolygonCoordinates()?.length>0&&getTextForPolygonCoordinates()?.map((itm)=>{
           return itm
        })
      }
      {
        props?.polygon&&props?.polygon?.paths?.length>0&&getTextForSinglePolygonCoordinates()
      }
       

    </GoogleMapReact>
  );

}

export default GoogleMap;


