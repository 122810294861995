import React from "react";
import { Table } from "react-bootstrap";
import Tree from "react-d3-tree";
import { useCenteredTree } from "./helpers";
import "./styles.css";
import {useSelector,useDispatch} from 'react-redux'
import {TreeViewCustome} from './StyledComponents'
import {useTranslation} from "react-i18next";

const containerStyles = {
  width: "100%",
  // height: "100vh",
  height:"100%"
};

// Here we're using `renderCustomNodeElement` render a component that uses
// both SVG and HTML tags side-by-side.
// This is made possible by `foreignObject`, which wraps the HTML tags to
// allow for them to be injected into the SVG namespace.
const renderForeignObjectNode = ({
  nodeDatum,
  toggleNode,
  foreignObjectProps
},t,directions,color,bgColor) => (
  <g  style={{
     '& .rd3t-link':{
      stroke:color
     }
  }}>
    <circle r={15} style={{fill:color}}></circle>
    {/* `foreignObject` requires width & height to be explicitly set. */}
    <foreignObject {...foreignObjectProps}>
      <div style={{ border: `1px solid ${color}`, backgroundColor: bgColor }}>
        <h6 style={{ textAlign: "center" ,color:color}}>{nodeDatum?.name}</h6>
        <Table>
            
            <tbody>
                <tr>
                    <td style={{color:color,textAlign:directions=='rtl'?"right":'left'}}>{t('SURVEY.FORMVIEW_CONDITION')}</td>
                    <td style={{color:color,textAlign:directions=='rtl'?"right":'left'}}>{nodeDatum?.attributes?.condition_value}</td>
                </tr>
                
                <tr>
                    <td style={{color:color,textAlign:directions=='rtl'?"right":'left'}}>{t('SURVEY.FORMVIEW_TYPE')} </td>
                    <td style={{color:color,textAlign:directions=='rtl'?"right":'left'}}>{nodeDatum?.attributes?.field_type_name}</td>
                </tr>
                
                
                <tr>
                    <td style={{color:color,textAlign:directions=='rtl'?"right":'left'}}>{t('SURVEY.FORMVIEW_IS_MANDATORY')}</td>
                    <td style={{color:color,textAlign:directions=='rtl'?"right":'left'}}>{nodeDatum?.attributes?.is_mandatory}</td>
                </tr>
               
            </tbody>
        </Table>
        {nodeDatum?.children && (
          <button style={{ width: "100%",backgroundColor:bgColor,color:color }} onClick={toggleNode}>
            {nodeDatum?.__rd3t?.collapsed ? t('SURVEY.FORMVIEW_EXPAND_BUTTON') : t('SURVEY.FORMVIEW_COLLAPSED_BUTTON')}
          </button>
        )}
      </div>
    </foreignObject>
  </g>
);

export default function CustomTree(props) {
  const [translate, containerRef] = useCenteredTree();
  const nodeSize = { x: 300, y: 300 };
  const foreignObjectProps = { width: nodeSize.x+20, height: nodeSize.y , x:15 };
  const directions = useSelector(state => state.settingsData.directions)
  const MainTheme = useSelector(state => state.settingsData.MainTheme)
  const [t, i18n] = useTranslation('common');


  const checkDarknessOrLightnessForColor=(color)=>{
    // Variables for red, green, blue values
    var r, g, b, hsp;
  
    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {

        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
        
        r = color[1];
        g = color[2];
        b = color[3];
    } 
    else {
        
        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +("0x" + color.slice(1).replace( 
        color.length < 5 && /./g, '$&$&'));

        r = color >> 16;
        g = color >> 8 & 255;
        b = color & 255;
    }
    
    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b)
    );

    // Using the HSP value, determine whether the color is light or dark
    if (hsp>127.5) {

        return false;//light
    } 
    else {

        return true;//dark
    }

};

  return (
    <TreeViewCustome style={containerStyles} color={MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor}  ref={containerRef} >
      <Tree
        data={props.surveyFields}
        translate={translate}
        pathFunc="step"
        zoom={0.6}
        separation={{siblings:2,nonSiblings:2}}
        nodeSize={nodeSize}
        renderCustomNodeElement={(rd3tProps) =>
          renderForeignObjectNode({ ...rd3tProps,foreignObjectProps },t,directions,MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,!checkDarknessOrLightnessForColor(MainTheme?.paperTextColor)?'rgb(255,255,255,0.4)':'rgb(0,0,0,0.4)')
        }
        orientation="vertical"
      />
    </TreeViewCustome>
  );
}
