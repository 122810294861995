
import React, {useEffect,useState} from 'react';
// import Pagination from "react-js-pagination";
import Pagination from '../Share/Pagination'
import Cookies from "universal-cookie";
import axios from "axios";
import Host, { FrontEndDomain } from "../../../assets/js/Host";
import { toast } from 'react-toastify';
import { Button } from 'react-bootstrap';
import SurveyForm from './SurveyForm';
import SurveyView from './SurveyView';
import StyledDataGrid from '../GeneralComponents/StyledDataGrid'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {useSelector,useDispatch} from 'react-redux'
import Tooltip from '@mui/material/Tooltip';
import IconButton from "@mui/material/IconButton";
import Grid from '../Share/Grid'
import {useTranslation} from "react-i18next";
import DropDownGrid from '../Share/DropDownGrid'
import {ListItemIcon,Box,MenuItem} from '@mui/material'
import helpers from '../../../assets/js/helper';

const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language")?cookies.get("language"):"ar"
function Surveys() {
    const directions = useSelector(state => state.settingsData.directions)
    const MainTheme = useSelector(state => state.settingsData.MainTheme)
    const [t, i18n] = useTranslation('common');
    const token = cookies.get("token")

    const [loading, setloading] = useState(false);
    const [state, setData] = useState({
        surveys: ''
    });
    const [rows,setRows]=useState([])
    const [surveysToatal,setSurveysTotal]=useState(0)
    const [pageSize,setPageSize]=useState(5)
    const [pageNumber,setPageNumber]=useState(1)
    const [SurveysNumberOfPage,setSurveyNumberOfPage]=useState(0)
    const [showSurveyForm, setshowSurveyForm] = useState(false);
    const [showSurveyView, setshowSurveyView] = useState(false);
    const [selectedSurvey, setselectedSurvey] = useState(null);
   
    const columns = [
        { field: 'id', headerName: t('SURVEY.ID'), flex: .1 },
        {
            field: 'name', headerName: t('SURVEY.NAME'), flex: 2,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>
                    {rows.row?.name}
                </div>
            )
        },
        {
            field: 'point_status', headerName: t('SURVEY.POINT_STATUS'), flex: 2,
            renderCell: (rows) => (
                <div style={{ width: '100%' }} className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>
                    {rows?.row?.point_type?.name}
                </div>
            )
        },
        {
            field: 'point_name', headerName: t('SURVEY.POINT_NAME'), flex: 1.5,
            renderCell: (rows) => (
                <div style={{ width: '100%', }} className={`${directions =='rtl'?'alignColumnRight':'alignColumnLeft'}`}>
                    {rows.row?.point?.name}
                </div>
            )
        },
        {
            field: 'actions', flex: 0.3,width:'50px',
            headerName: t('SURVEY.ACTIONS'),
            renderCell: (rows) => (
                <DropDownGrid>
               
                <MenuItem onClick={() => {viewObject(rows.row)}}>
                    <ListItemIcon sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin:'0px 7px 0 0'
                    }}>
                        <RemoveRedEyeIcon style={{ fill: "#111827" }} />

                    </ListItemIcon>
                    {directions != 'rtl' ? "View" : 'عرض'}
                </MenuItem>
                <MenuItem onClick={() => {
                    updateObject(rows.row)

                    }}>
                    <ListItemIcon sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin:'0px 7px 0 0'
                    }}>
                        <EditIcon style={{ fill: "#111827" }} />

                    </ListItemIcon>
                    {directions != 'rtl' ? "Edit" : 'تعديل'}
                </MenuItem>
                <MenuItem onClick={() => {
                    deleteObject(rows.row)
                }}>
                    <ListItemIcon sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin:'0px 7px 0 0'
                    }}>
                       <DeleteIcon style={{ fill: "#f00" }} />

                    </ListItemIcon>
                    {directions != 'rtl' ? "Delete" : 'حذف'}
                </MenuItem>
                <MenuItem onClick={() => {
                        window.open(FrontEndDomain + `build_survey?id=${rows?.row?.id}`, '_blank')
                }}>
                    <ListItemIcon sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin:'0px 7px 0 0'
                    }}>
                       <BuildCircleIcon style={{ fill: "#111827" }} />

                    </ListItemIcon>
                    {directions != 'rtl' ? "build" : 'بناء'}
                </MenuItem>

                </DropDownGrid>
            ),
        },
    ]


    const fetchData = async (pageNumber = 1) => {
        setloading(true)
        try {
            const result=await axios({
                url: Host + "surveys?page="+pageNumber,
                method: "get",
                headers: {
                "Authorization" : `Bearer ${token}`,
                    Accept:"application/json",
                    'X-localization':language,
                }
        
            })
            if(result && result.status == 200)
            {
                const surveys=result.data ;
                
                console.log(surveys);
                setData({
                    surveys:surveys 
                });
            } else if (result.data.status === false) {
                toast.error("Error")
            }
        } catch (error) {
            const responseResult=error.response            
            if (responseResult && responseResult.status === 400) {
                if(typeof responseResult.data.data === "object")
                {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key)=>{
                    
                        responseResult.data.data[key].map((message)=>{
                            toast.error(message)
                        })
                    })
                }
                else{
                    toast.error(responseResult.data.data)
                }
                
            }
            else if(responseResult && responseResult.status === 401) {
                toast.error(responseResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else
            {
                toast.error("network error")
            }
        }
        setloading(false)
                   
    };
    const updateObject = async (object) => {
      
        setselectedSurvey(object)
        setshowSurveyForm(true)
    };
    
    
    const deleteObject = async (object) => {
        if (!window.confirm("Delete the item?")) {
            return
        }
        setloading(true)
        try {
            const result=await axios({
                url: Host + "surveys/"+object.id,
                method: "delete",
                headers: {
                "Authorization" : `Bearer ${token}`,
                    Accept:"application/json",
                    'X-localization':language,
                }
        
            })
            if(result && result.status == 200)
            {
                toast.success(result.data.data)
                fetchData();
            } else if (result.data.status === false) {
                toast.error("Error")
            }
        } catch (error) {
            const responseResult=error.response            
            if (responseResult && responseResult.status === 400) {
                if(typeof responseResult.data.data === "object")
                {
                    const errorMessagesKeys = Object.keys(responseResult.data.data);
                    errorMessagesKeys.map((key)=>{
                    
                        responseResult.data.data[key].map((message)=>{
                            toast.error(message)
                        })
                    })
                }
                else{
                    toast.error(responseResult.data.data)
                }
                
            }
            else if(responseResult && responseResult.status === 401) {
                toast.error(responseResult.data.data)
                cookies.remove("token");
                window.location.href = "/"
            }
            else
            {
                toast.error("network error")
            }
        }
        setloading(false)
    };
  
    const createObject = async (object) => {
        setselectedSurvey(null)
        setshowSurveyForm(true)
    };
    const buildObject = async (object) => {
        setselectedSurvey(null)
        setshowSurveyForm(true)
    };

   
    const handleSetshowSurveyForm = (openStatus,needsReload) => {
      if(needsReload)
      fetchData();
      setshowSurveyForm(openStatus)
    };

    const handleSetshowSurveyView = (openStatus,needsReload) => {
        if(needsReload)
            fetchData();
        setshowSurveyView(openStatus)
    };

    const viewObject = async (object) => {
        setselectedSurvey(object)
        console.log(showSurveyView);
        setshowSurveyView(true)
    }
   
    const handlePageChange = (newPage) => {
        setPageNumber(newPage + 1)
    }

    useEffect(() => {        
        fetchData(pageNumber);
    }, [pageNumber])

    useEffect(() => {
        if (state && state.surveys&&state.surveys?.data?.length > 0) {
            setRows(state?.surveys?.data);
            setSurveysTotal(state?.surveys?.total)
            setSurveyNumberOfPage(state?.surveys?.last_page)
        }else{
            setRows([]);
            setSurveysTotal(0)
            setSurveyNumberOfPage(0)
        }
    }, [state])


    const check=helpers.checkDarknessOrLightnessForColor(MainTheme?.paperTextColor)
    

    const styleTextField={
        backgroundColor:!check?'rgb(255,255,255,0.7)':'rgb(0,0,0,0.4)',
        color:MainTheme?.paperTextColor,
    }

    const styleSelect={
        backgroundColor:!check?'rgb(255,255,255,0.7)':'rgb(0,0,0,0.4)',
        color:MainTheme?.paperTextColor,
    }

    // useEffect(() => {
    //     fetchData();
    // }, [])
    
    return (
        <>
            {showSurveyForm?
                <SurveyForm survey={selectedSurvey} showSurveyForm={showSurveyForm} setshowSurveyForm={handleSetshowSurveyForm} />
            :null}
            {showSurveyView?
                <SurveyView survey={selectedSurvey} color={MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor} bgCOlor={!helpers.checkDarknessOrLightnessForColor(MainTheme?.paperTextColor)?'rgb(255,255,255,0.7)':'rgb(0,0,0,0.4)'}   styleSurvey={styleTextField} showSurveyView={showSurveyView} setshowSurveyView={handleSetshowSurveyView} />
            :null}
          

            <Grid
              rows={rows}
              columns={columns}
              rowTotals={surveysToatal}
              pageSize={pageSize}
              setPageSize={setPageSize}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              loading={loading}
              handlePageChange={handlePageChange}
              NumberOfPage={SurveysNumberOfPage}
              directions={directions}
              hasCreate={true}
              createObject={createObject}
              title={t('SURVEY.HEADER_TITLE')}
            //   title_ar="قائمة الاستبيانات"
              gridHeight={{
                height:rows?.length>5?pageSize==5?400:pageSize==10?650:950:400,
              }}
            />

        </>
    );
}

export default Surveys;




{/* <div className="container">
<div className="row justify-content-center">
    <div className="col-md-12">
        <div className="card">
            <div className="card-header">Survey List<Button style={{float:'right'}} onClick={()=>{createObject()}}>create</Button></div>
            <div className="card-body">
            <table>
                <thead>
                    <tr>
                        <th>1</th>
                        <th>Name</th>
                        <th>Point Status</th>
                        <th>Point Name</th>
                        <th>View</th>
                        <th>Build</th>
                        <th>edit</th>
                        <th>delete</th>
                    </tr>
                </thead>
                <tbody>
                {   
                    state?.surveys?.data ? 
                        state?.surveys?.data?.map((object) => (
                                <tr key={object?.id}>
                                    <td>{object?.id}</td>
                                    <td>{object?.name}</td>
                                    
                                    <td>{object?.point_type?.name}</td>
                                    <td>{object?.point?.name}</td>
                                    <td><Button onClick={()=>{viewObject(object)}}>View</Button></td>
                                    <td><Button onClick={()=>{updateObject(object)}}>update</Button></td>
                                    <td><Button style={{backgroundColor:'red',color:'white'}} onClick={()=>{deleteObject(object)}}>delete</Button></td>
                                    <td><Button onClick={()=>{
                                        
                                        window.open(FrontEndDomain + `build_survey?id=${object?.id}`, '_blank')
                                    }}>Build</Button></td>
                                </tr>
                        )) : "Loading..."
                }
                </tbody>
            </table>
                <div>
                    <Pagination
                        activePage={state?.surveys?.current_page ? state?.surveys?.current_page : 0}
                        itemsCountPerPage={state?.surveys?.per_page ? state?.surveys?.per_page : 0 }
                        totalItemsCount={state?.surveys?.total ? state?.surveys?.total : 0}
                        onChange={(pageNumber) => {
                            fetchData(pageNumber)
                        }}
                        pageRangeDisplayed={8}
                        itemClass="page-item"
                        linkClass="page-link"
                        firstPageText="First Page"
                        lastPageText="Last Lage"
                    />
                </div>
            </div>
        </div>
    </div>
</div>
</div> */}